import { Messages } from 'primereact/messages';
import React from 'react';
import { GenericResponse } from '../api-ts-bindings/Generic';

export function checkResponse(
	response: GenericResponse,
	messagesProp?:
		| Messages
		| React.RefObject<Messages | null | undefined>
		| React.MutableRefObject<Messages | null | undefined>
		| null
): boolean {
	// If messages are not given then just check
	//TODO Find a more elegant way to do this
	if (!messagesProp || !(messagesProp as any).current)
		return !(response && response.code === 0);

	//Check if there is no error and if so return false
	if (response && response.code === 0) return false;

	const messages: Messages =
		'current' in messagesProp ? messagesProp.current! : messagesProp;

	//If the response failed then show a can not connect to server message
	if (!response)
		messages.show({
			severity: 'error',
			summary: 'Não foi possivel contactar os serviços do Numo!',
			life: 10000
		});
	else {
		//Build the error message from nothing
		const middle: JSX.Element[] = [];
		if (response.messages)
			response.messages.forEach((v) => {
				const m = '\u2022' + v.key + ':' + v.value;
				middle.push(
					<div key={v.key} style={{ marginLeft: '2em' }}>
						{m}
					</div>
				);
			});
		const detail = <div>{middle}</div>;
		if (messages.show)
			messages.show({
				severity: response.code === 101 ? 'warn' : 'error',
				summary: `${response.description}`,
				detail: detail,
				life: 15000
			});
	}
	return true;
}

type FormatDateType =
	| ((date: string, extended?: boolean, table?: boolean) => JSX.Element)
	| ((
			date: string,
			extended?: boolean,
			table?: boolean,
			bodyless?: true
	  ) => string);
/**
 * Formats a date that is given in the iso format
 * @param date
 * @param extended
 * @param table an element
 * @param bodyless returns a pure string
 * @returns
 */
export const formatDate: FormatDateType = function (
	date: string,
	extended = true,
	table = false,
	bodyless = false
) {
	if (!date || !date.match(/^(\d{4})-(\d{2})-(\d{2})T([\d:]{8}).*/))
		return bodyless ? '' : <span></span>;
	const matched = date.match(/^(\d{4})-(\d{2})-(\d{2})T([\d:]{8}).*/)!;
	const formated =
		matched[3] +
		'-' +
		matched[2] +
		'-' +
		matched[1] +
		(extended ? ' ' + matched[4] : ''); //dateformat(date, 'dd-mm-yyyy' + (extended ? ' HH:MM:ss': ''))
	return bodyless ? (
		formated
	) : !table ? (
		<span
			style={{
				verticalAlign: 'center',
				paddingTop: '.5em',
				paddingBottom: '.5m'
			}}
		>
			{date ? formated : ''}
		</span>
	) : (
		<p style={{ margin: '0px', textAlign: 'right' }}>{date ? formated : ''}</p>
	);
} as any;

/**
 * @function g_openwindowdialog creates a new window on the centered in the screen and with a onClose callback function
 * @param {string} url url
 * @param {string} name name of the window, for multiple windows '_blank'
 * @param {string} [extra] features
 * @param {number} [height] height of the new window
 * @param {number} [width] width of the new window
 * @param {string} [resizable] 'on' or 'off'(default)
 * @param {function} onClose onClose
 */
export function openWindow(
	url: string,
	name = '_blank',
	width = -1,
	height = -1,
	resizable: 'off' | 'on' = 'off',
	extra = '',
	onClose: () => void = () => null,
	windowF: (w: Window) => void = () => null
) {
	width = width < 0 ? window.screen.width : width;
	height = height < 0 ? window.screen.height : height;
	const x = window.screen.width / 2 - width / 2;
	const y = window.screen.height / 2 - height / 2;
	const w = window.open(
		url,
		name,
		'scrollbars=off,location=off,menubar=off,width=' +
			width +
			',height=' +
			height +
			',left=' +
			x +
			',top=' +
			y +
			',resizable=' +
			resizable +
			',' +
			extra
	);
	if (!w) return;
	if (windowF) windowF(w);
	if (onClose)
		w.onbeforeunload = () => {
			onClose();
		};
	return w;
}

/**
 * @function treats dates to the correct format(ISO FORMAT - timezone)
 * @returns the treated date
 */
export function treatDate(
	date: string,
	widthTime = false,
	end = false
): string {
	if (!date) return '';
	const d = new Date(date);
	if (end)
		return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
			2,
			'0'
		)}-${String(d.getDate()).padStart(2, '0')}T23:59:59.000`;
	if (!widthTime)
		return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
			2,
			'0'
		)}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000`;
	return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
		2,
		'0'
	)}-${String(d.getDate()).padStart(2, '0')}T${String(d.getHours()).padStart(
		2,
		'0'
	)}:${String(d.getMinutes()).padStart(2, '0')}:00.000`;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}
