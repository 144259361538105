import React from 'react';
import { LS, g_checkLoginACL, g_hideSearch, g_genericRequired, g_template_dataTable, g_getHeaderTemplate, g_getData, g_entities, g_checkAlc, g_entityFilter } from '../GenericFunctions';
import { Messages } from 'primereact/messages';
import PayroolService from '../../service/PayrollSerive';
import * as BatchsEdit from './BatchsEdit';

export default class Batchs extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'batchs#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, payrollControls: [] };
                data.filters = f[0];
                return PayroolService.controlList(data);
            },
            'payrollControls'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('batchs', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '/#/batchsedit?type=1', 'createnewbatchs', this.getData, 1500, 750, e.c === 1),
                    [
                        { type: 'd', data: 'month', filter: true, sortable: true },
                        { type: 'd', data: 'year', filter: true, sortable: true },
                        { type: 'd', data: 'consignatorName', c: String(g_entities()).indexOf(',') !== -1 || g_checkAlc(5, 'consignator-management#acessall') },
                        { type: 'date', data: 'dateCreation' },
                        { type: 'state', data: 'payrollControlStatus', states: BatchsEdit.states },
                        { type: 'url', url: raw => `/#/batchsedit?type=1&id=${raw.idUUID}`, width: 1500, height: 750, callback: this.getData, c: e.d === 1 || e.u === 1 },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
