import { Messages, Message } from 'primereact/messages';
import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import {
	GenericListRequest,
	GenericListResponseBaseG,
	GenericListResponseG
} from '../api-ts-bindings';
import { g_treatDate } from '../components/GenericFunctions';
import { BuilderType, StateIcon, StringEmpty } from './Generic';
import { SelectorType } from './Selector';
import { TableArgumentElement, TableHeaderProps } from './Table';
import { StateItems, Translator } from './TypeDefinitions';
import { formatDate, treatDate, noop } from './Utils';

//eslint-disable-next-line
export type KnownAny = any;

export type StringIndexed = Record<string, KnownAny>;

interface FormProps<T extends StringIndexed = any> {
	onSubmit?: React.FormEventHandler<HTMLFormElement>;
	children?: ReactElement<any, any>[] | ReactElement | null;
	t?: string;
	obj?: T;
	setObj?: (e: any, extra: useDataObjectExtra<T>) => void;
	disabled?: boolean;
	fclass?: string;
	lclass?: string;
	req?: boolean;
	group?: boolean;
	class?: string;
	style?: React.CSSProperties;
	formRef?: (e: HTMLFormElement | null) => void;
}

interface GenericElementProps {
	isGenericFormElement?: boolean;
	isGenericInputFormElement?: boolean;
	//Type
	t?: string;
}
export const GenericElement: React.FC<GenericElementProps> = () => null;
GenericElement.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: false
};

export const G = GenericElement;

//Others
interface GViewProps<T extends StringIndexed = StringIndexed>
	extends GenericElementProps {
	t?: 'v';
	d: keyof T & string;
	l?: string;
	//inputClass = fclass
	fClass?: string;
	//labelClass = lClass
	lClass?: string;
	h?: boolean;
	extended?: boolean;
}
export const GView: React.FC<GViewProps> = (_props: GViewProps) => null;
GView.defaultProps = {
	isGenericFormElement: true,
	t: 'v'
};

interface GStateProps<T extends StringIndexed = StringIndexed>
	extends GenericElementProps {
	t?: 'state';
	d: keyof T & string;
	l?: string;
	//inputClass = fclass
	fClass?: string;
	//labelClass = lClass
	lClass?: string;
	h?: boolean;

	custom: StateItems;
	small?: boolean;
	onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
	style?: React.CSSProperties;
}
export const GState: React.FC<GStateProps> = (_props: GStateProps) => null;
GState.defaultProps = {
	isGenericFormElement: true,
	t: 'state'
};

interface GCopyProps<T extends StringIndexed = StringIndexed>
	extends GenericElementProps {
	t?: 'copy';
	d: keyof T & string;
	l?: string;
	//inputClass = fclass
	fClass?: string;
	//labelClass = lClass
	lClass?: string;
	h?: boolean;
	copyMessage?: string;
}
export const GCopy: React.FC<GCopyProps> = (_props: GCopyProps) => null;
GCopy.defaultProps = {
	isGenericFormElement: true,
	t: 'copy'
};

interface GMessagesProps extends GenericElementProps {
	t?: 'messages';
	refM?: React.MutableRefObject<Messages | null>;
	onRemove?: (message: Message) => void;
}
export const GMessages: React.FC<GMessagesProps> = () => null;
GMessages.defaultProps = {
	isGenericFormElement: true,
	t: 'messages'
};

//Generic Inputs
interface GenericInputElementProps<T extends StringIndexed = StringIndexed>
	extends GenericElementProps {
	//Data
	d: keyof T & string;
	//Auto default auto form label selector with d as input
	l?: string;
	//OnChange
	onChange?: (e: any) => void;
	//Extra
	extra?: useDataObjectExtra<T>;

	req?: boolean;
	disabled?: boolean;
	//inputClass = fclass
	fClass?: string;
	//labelClass = lClass
	lClass?: string;
	//hidden = h
	h?: boolean;
}
export const GenericInputElement: React.FC<GenericInputElementProps> = <
	T extends StringIndexed
>(
	props: GenericInputElementProps<T>
) => null;
GenericInputElement.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true
};

interface GInputProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 't';
	//id: string; = d
	//label: string; = l;
	//required = req
	//labelClass = lClass
	//inputClass = fclass
	//Auto default auto form label selector with d as input
	//onChange = onChange
	//Default auto from obj
	value?: string;
	type?: string;
	tooltip?: string;
}
export const GInput: React.FC<GInputProps> = () => null;
GInput.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 't'
};

interface GMaskProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 'mask';
	//id: string; d
	//label?: string; l
	//labelClass?: string; lClass
	//hidden?: boolean; h
	//disabled?: boolean;
	//fieldClass?: string; fClass
	placeholder?: string;
	tooltip?: string;
	//value?: any; auto
	//onChange: (e: any) => void;
	//required?: boolean; req
	mask?: string;
	style?: React.CSSProperties;
	//gridless?: boolean;
	autoClear?: boolean;
	unmask?: boolean;
}
export const GMask: React.FC<GMaskProps> = () => null;
GMask.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'mask'
};

interface GAreaProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 'area';
	//id: string;
	//label?: string;
	//required = req
	//labelClass = lClass
	//inputClass = fclass
	//Default auto from obj
	value?: string;
	style?: React.CSSProperties;
	tooltip?: string;
	onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}
export const GArea: React.FC<GAreaProps> = () => null;
GArea.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'area'
};

interface GEditorProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 'editor';

	style?: React.CSSProperties;
}
export const GEditor: React.FC<GEditorProps> = () => null;
GEditor.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'editor'
};

interface GDateInputProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 'date';

	selectionMode?: 'range';
	showTime?: boolean;
	timeOnly?: boolean;
	minDate?: Date;
	maxDate?: Date;
	value?: Date;

	onChange?: (e: {
		originalEvent: Event;
		value: Date | Date[];
		target: {
			name: string;
			id: string;
			value: Date | Date[];
		};
	}) => void;
}
export const GDateInput: React.FC<GDateInputProps> = <T extends StringIndexed>(
	props: GDateInputProps<T>
) => null;
GDateInput.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'date'
};

export interface GDropdownProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 'drop';
	//id: string; d
	options: { value: string; label: string }[];
	//label?: string; l
	extra?: useDataObjectExtra<T>;
	req?: boolean;
	disabled?: boolean;
	fClass?: string;
	lClass?: string;
	h?: boolean;
	itemTemplate?: (option: T) => React.ReactNode;
	filter?: boolean;
	placeholder?: string;
	value?: string;
	onChange?: (e: {
		originalEvent: Event;
		value: any;
		target: { name: string; id: string; value: any };
	}) => void;
	showClear?: boolean;

	workWithNumbers?: boolean;
}
export const GDropdown: React.FC<GDropdownProps> = () => null;
GDropdown.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'drop'
};

export interface GSwitchProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	//id: string; d
	//label: string; l
	onChange?: (e: {
		originalEvent: Event;
		value: boolean;
		target: { name: string; id: string; value: boolean };
	}) => void;
	req?: false; //Underling commpent does not have the required tag
	//disabled?: boolean;
	//fClass?: string;
	//lClass?: string;
	//hidden: boolean; h
	checked?: boolean;
	tooltip?: string;
}
export const GSwitch: React.FC<GSwitchProps> = () => null;
GSwitch.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'switch',
	req: false
};

interface GNumberProps<T extends StringIndexed = StringIndexed>
	extends GenericInputElementProps<T> {
	t?: 'number';
	//id: string; d
	//value?: string;
	//label?: string;
	//disabled?: boolean;
	//hidden?: boolean; h
	//required?: boolean; req
	//inputClass?: string; fClass
	//labelClass?: string; lClass
	type?: string;
	tooltip?: string;
	//gridless?: boolean; auto
	suffix?: string;
	prefix?: string;
	currency?: string | boolean;
	minFractionDigits?: number;
	maxFractionDigits?: number;
	min?: number;
	max?: number;
	//mode?: 'decimal' | 'currency';
	onChange?: (e: {
		originalEvent: Event;
		value: any;
		target: {
			name: string;
			id: string;
			value: any;
		};
	}) => void;
	locale?: string | boolean;
}
export const GNumber: React.FC<GNumberProps> = () => null;
GNumber.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'number'
};

interface GSelectorProps<
	T extends StringIndexed = StringIndexed,
	Ta extends StringIndexed = StringIndexed,
	R extends string = string,
	F extends string = string,
	O extends string = string
> extends GenericInputElementProps<T> {
	t?: 'selector';
	dDesc: keyof T & string;

	onChange?: (e: KnownAny) => void;

	tableChildren: (
		set: (id: string, desc: string) => void
	) => TableArgumentElement<Ta, F, O>[] | TableArgumentElement<Ta, F, O>;

	/**
	 * Please use useCallback on this function
	 */
	request: (
		query: GenericListRequest<F, O>
	) => GenericListResponseG<R, Ta> | GenericListResponseBaseG<R, Ta>;
	result: R;

	header?: React.ReactElement<TableHeaderProps<Ta, F, O>>;
}
export const GSelector = <
	T extends StringIndexed = StringIndexed,
	Ta extends StringIndexed = StringIndexed,
	R extends string = string,
	F extends string = string,
	O extends string = string
	//eslint-disable-next-line
>(
	_props: GSelectorProps<T, Ta, R, F, O>
): null => null;
GSelector.defaultProps = {
	isGenericFormElement: true,
	isGenericInputFormElement: true,
	t: 'selector'
};

export const GE = GenericInputElement;

export const CreateSetObj = <T extends StringIndexed>(
	obj: T,
	setObjState: (obj: T) => void
) => {
	return (e: any, extra?: useDataObjectExtra<T>) => {
		if (e?.preventDefault) e.preventDefault();
		if (extra?.setObjStateOveride) {
			console.log('set:', extra.setObjStateOveride.toString());
			setObjState = extra?.setObjStateOveride;
		}
		const target = e?.target;
		let value = (target?.value ?? '') + '';
		let id: string = target?.id ?? '';
		if (extra?.editor) {
			id = extra?.id ?? '';
			value = e.htmlValue;
		}
		if (!id) return;
		const toSet: StringIndexed = obj;
		if (extra?.checkBox) {
			toSet[id] = toSet[id] === 1 ? 0 : 1;
			setObjState({ ...toSet } as T);
			return;
		} else if (extra?.switch) {
			//Value was forced to be a string
			toSet[id] = value === 'true' ? 1 : 0;
			setObjState({ ...toSet } as T);
			return;
		} else if (extra?.multiple) {
			value = e.value;
			toSet[id] = value;
			setObjState({ ...toSet } as T);
			return;
		} else if (extra?.date) {
			value = treatDate(value);
		}

		if (extra?.replace)
			value = value.replace(extra.replace.filter, extra.replace.value);
		if (
			extra?.regex &&
			(value.match(extra.regex) === null ||
				(value.match(extra.regex) ?? [])[0] !== value)
		)
			return;
		if (extra?.number) {
			toSet[id] = Number(value ?? '0');
		} else {
			toSet[id] = value;
		}
		setObjState({ ...toSet } as T);
	};
};

interface useDataObjectExtra<T> {
	regex?: string | RegExp;
	replace?: { filter: string | RegExp; value: string };
	switch?: boolean;
	checkBox?: boolean;
	multiple?: boolean;
	editor?: boolean;
	date?: boolean;
	id?: string;
	number?: boolean;
	object?: boolean;
	setObjStateOveride?: (data: T) => void;
}
export function useDataObject<T extends StringIndexed>(initial: T) {
	const [obj, setObjState] = useState(initial);
	const clear = useCallback(() => {
		setObjState(initial);
	}, [initial]);
	const setObj = useCallback(CreateSetObj(obj, setObjState), [obj]);

	return { obj, setObj, clear, setObjState };
}

export function useDataObjectA<T extends StringIndexed>(
	initial: T
): [T, ReturnType<typeof CreateSetObj<T>>, (set: T) => void, () => void] {
	const [obj, setObjState] = useState(initial);
	const clear = useCallback(() => {
		setObjState(initial);
	}, [initial]);
	const setObj = useCallback(CreateSetObj(obj, setObjState), [obj]);

	return [obj, setObj, setObjState, clear];
}

export const setFunction = <T extends StringIndexed>(
	updateFunction: (obj: T) => void,
	obj: T
) => {
	return (e: any, extra?: useDataObjectExtra<T>) => {
		if (e.preventDefault) e.preventDefault();
		const target = e?.target;
		let value = (target?.value ?? '') + '';
		let id: string = target?.id ?? '';
		if (extra?.editor) {
			id = extra?.id ?? '';
			value = e.htmlValue;
		}
		if (!id) return;
		const toSet: StringIndexed = obj;
		if (extra?.object) {
			toSet[id] = e.value;
			updateFunction({ ...toSet } as T);
			return;
		}
		if (extra?.checkBox) {
			toSet[id] = toSet[id] === 1 ? 0 : 1;
			updateFunction({ ...toSet } as T);
			return;
		} else if (extra?.switch) {
			//Value was forced to be a string
			toSet[id] = value === 'true' ? 1 : 0;
			updateFunction({ ...toSet } as T);
			return;
		} else if (extra?.multiple) {
			value = e.value;
			toSet[id] = value;
			updateFunction({ ...toSet } as T);
			return;
		} else if (extra?.date) {
			value = g_treatDate(value);
		}

		if (extra?.replace)
			value = value.replace(extra.replace.filter, extra.replace.value);
		if (
			extra?.regex &&
			(value.match(extra.regex) === null ||
				(value.match(extra.regex) ?? [])[0] !== value)
		)
			return;
		if (extra?.number) {
			toSet[id] = Number(value ?? '0');
		} else {
			toSet[id] = value;
		}
		updateFunction({ ...toSet } as T);
	};
};

//Div
interface GDivProps<T extends StringIndexed = any> extends GenericElementProps {
	class?: string;
	style?: React.CSSProperties;
	children?: ReactElement[] | ReactElement | null;
	overload?: FormProps<T>;
	hidden?: boolean;
	grid?: boolean;
	extra?: FormProps;
	group?: boolean;
	onClick?: (e: MouseEvent<HTMLDivElement>) => void;
	obj?: T;
	setObj?: (e: any, extra: useDataObjectExtra<T>) => void;
}

export type FormBuilderType = {
	Form: <T extends StringIndexed>(props: FormProps<T>) => JSX.Element;
	GDiv: <T extends StringIndexed>(props: GDivProps<T>) => JSX.Element;
};

export const FormBuilder = (
	ls: Translator<string | undefined>,
	config: { locale: string; currency: string },
	{
		Input,
		TextArea,
		Editor,
		DateInput,
		Dropdown,
		InputMask,
		InputNumber,
		InputSwitch,
		Wraper,
		Copy
	}: BuilderType,
	Selector?: SelectorType
): FormBuilderType => {
	//Function that processes the form children
	const processChildren = <T extends StringIndexed>(
		ai: ReactElement,
		props: FormProps<T>
	): ReactElement => {
		const obj: T = props.obj ?? ({} as T);
		const { setObj = noop } = props;
		if (ai.props && ai.props.isGenericFormElement) {
			const gen: ReactElement<GenericElementProps> = ai as any;
			const t = gen.props.t ?? props.t ?? '';
			if (gen.props.isGenericInputFormElement) {
				const iGen: ReactElement<GenericInputElementProps<T>> = gen as any;

				const d = iGen.props.d as string;

				const value = obj[d];
				const label = ls(
					iGen.props.l ?? '',
					'_',
					iGen.props.l ?? ls(iGen.props.d)
				);
				const onChange: (e: any, extra?: useDataObjectExtra<T>) => void =
					iGen.props.onChange ??
					((e: any, extra?: useDataObjectExtra<T>) =>
						setObj(e, { ...extra, ...(iGen.props.extra ?? {}) }));
				const disabled = iGen.props.disabled ?? props.disabled ?? false;
				const required = iGen.props.req ?? props.req ?? false;
				const inputClass = iGen.props.fClass ?? props.fclass ?? 'p-col';
				const labelClass = iGen.props.lClass ?? props.lclass ?? 'p-col-3';
				const hidden = iGen.props.h ?? false;
				const extra = iGen.props.extra ?? {};

				//Types
				if (t === 't') {
					const iGenI: ReactElement<GInputProps<T>> = iGen as any;
					const type = iGenI.props.type;
					const tooltip = iGenI.props.tooltip;

					return (
						<Input
							id={d}
							disabled={disabled}
							hidden={hidden}
							required={required}
							inputClass={inputClass}
							labelClass={labelClass}
							type={type}
							tooltip={tooltip}
							value={value}
							label={label}
							onChange={onChange}
							gridless={props.group}
						/>
					);
				} else if (t === 'area') {
					const iGenI: ReactElement<GAreaProps<T>> = iGen as any;
					const tooltip = iGenI.props.tooltip;
					const style = iGenI.props.style;
					return (
						<TextArea
							id={d}
							disabled={disabled}
							hidden={hidden}
							required={required}
							inputClass={inputClass}
							labelClass={labelClass}
							tooltip={tooltip}
							value={value}
							label={label}
							onChange={onChange}
							gridless={props.group}
							style={style}
						/>
					);
				} else if (t === 'editor') {
					const iGenI: ReactElement<GEditorProps<T>> = iGen as any;
					const style = iGenI.props.style;

					return (
						<Editor
							id={d}
							disabled={disabled}
							onChange={(e) => onChange(e, { id: d, editor: true, ...extra })}
							label={label}
							gridless={props.group}
							style={style}
							hidden={hidden}
							value={value}
							fieldClass={inputClass}
							labelClass={labelClass}
						/>
					);
				} else if (t === 'date') {
					const iGenI: ReactElement<GDateInputProps<T>> = iGen as any;
					const showTime = iGenI.props.showTime;
					const maxDate = iGenI.props.maxDate;
					const minDate = iGenI.props.minDate;
					const selectionMode = iGenI.props.selectionMode;
					const timeOnly = iGenI.props.timeOnly;
					return (
						<DateInput
							id={d}
							showTime={showTime}
							hidden={hidden}
							disabled={disabled}
							gridless={props.group}
							inputClass={inputClass}
							label={label}
							labelClass={labelClass}
							maxDate={maxDate}
							minDate={minDate}
							selectionMode={selectionMode}
							timeOnly={timeOnly}
							value={selectionMode !== undefined ? value : new Date(value)}
							onChange={(e) =>
								onChange(e, {
									multiple: selectionMode !== undefined,
									date: true
								})
							}
							required={required}
						/>
					);
				} else if (t === 'drop') {
					const iGenI: ReactElement<GDropdownProps<T>> = iGen as any;
					return (
						<Dropdown<T>
							id={d}
							onChange={(e) =>
								onChange(e, { number: iGenI.props.workWithNumbers, ...extra })
							}
							hidden={hidden}
							disabled={disabled}
							gridless={props.group}
							fClass={inputClass}
							label={label}
							lClass={labelClass}
							options={iGenI.props.options}
							filter={iGenI.props.filter}
							itemTemplate={iGenI.props.itemTemplate}
							placeholder={iGenI.props.placeholder}
							required={required}
							showClear={iGenI.props.showClear}
							value={iGenI.props.workWithNumbers ? String(value) : value}
						/>
					);
				} else if (t === 'mask') {
					const iGenI: ReactElement<GMaskProps<T>> = iGen as any;

					return (
						<InputMask
							id={d}
							disabled={disabled}
							required={required}
							onChange={onChange}
							autoClear={iGenI.props.autoClear ?? false}
							fieldClass={inputClass}
							gridless={props.group}
							hidden={hidden}
							label={label}
							labelClass={labelClass}
							mask={iGenI.props.mask}
							placeholder={iGenI.props.placeholder}
							style={iGenI.props.style}
							tooltip={iGenI.props.tooltip}
							unmask={iGenI.props.unmask ?? true}
							value={value}
						/>
					);
				} else if (t === 'number') {
					const iGenI: ReactElement<GNumberProps<T>> = iGen as any;

					const propsF: GNumberProps<T> = iGenI.props;

					let currency: string | undefined = undefined;
					const minFractionDigits = propsF.minFractionDigits;
					let locale: string | undefined = undefined;

					if (typeof propsF.locale === 'boolean') {
						if (propsF.locale) {
							locale = config.locale;
						}
					} else {
						locale = propsF.locale as string;
					}

					if (typeof propsF.currency === 'boolean') {
						if (propsF.currency) {
							currency = config.currency;
							//minFractionDigits = minFractionDigits ?? 2;
							locale = locale ?? config.locale;
						}
					} else {
						currency = propsF.currency as string;
					}

					return (
						<InputNumber
							id={d}
							currency={currency}
							disabled={disabled}
							gridless={props.group}
							inputClass={inputClass}
							labelClass={labelClass}
							hidden={hidden}
							label={label}
							maxFractionDigits={propsF.maxFractionDigits}
							minFractionDigits={minFractionDigits}
							mode={StringEmpty(currency) ? 'decimal' : 'currency'}
							prefix={propsF.prefix}
							suffix={propsF.suffix}
							onChange={onChange}
							required={required}
							tooltip={propsF.tooltip}
							type={propsF.type}
							value={value}
							locale={locale}
							min={propsF.min}
							max={propsF.max}
						/>
					);
				} else if (t === 'switch') {
					const iGenI: ReactElement<GSwitchProps<T>> = iGen as any;
					const propsF: GSwitchProps<T> = iGenI.props;
					return (
						<InputSwitch
							id={d}
							checked={String(value) === '1' ? true : false}
							tooltip={propsF.tooltip}
							disabled={disabled}
							fClass={inputClass}
							lClass={labelClass}
							gridless={props.group}
							hidden={hidden}
							label={label}
							onChange={(e) => onChange(e, { ...extra, switch: true })}
						/>
					);
				} else if (t === 'selector') {
					const iGenI: ReactElement<GSelectorProps<T>> = iGen as KnownAny;
					const propsF: GSelectorProps<T> = iGenI.props;

					if (!Selector)
						throw new Error('Selector can not be used on this form/Gdiv');

					const label = ls(
						iGen.props.l ?? '',
						'_',
						iGen.props.l ?? ls(propsF.dDesc)
					);

					return (
						<Selector
							id={d}
							descId={propsF.dDesc}
							descValue={obj[propsF.dDesc]}
							label={label}
							hidden={hidden}
							disabled={disabled}
							tableChildren={propsF.tableChildren}
							result={propsF.result}
							request={propsF.request}
							header={propsF.header}
							fclass={inputClass}
							lclass={labelClass}
							onChange={(e) => onChange(e, extra)}
						/>
					);
				}
			} else if (gen.props.t === 'copy') {
				const sGen: ReactElement<GCopyProps<T>> = gen as any;

				const d = sGen.props.d as string;

				const label = ls(
					sGen.props.l,
					'_',
					sGen.props.l ?? ls(sGen.props.d, '_', ls('status', 'generic'))
				);

				const value = obj[d];
				console.log(value, obj);
				const cpMessage = sGen.props.copyMessage ?? '';
				const lClass = sGen.props.lClass ?? props.lclass ?? 'p-col-3';
				const fClass = sGen.props.fClass ?? props.fclass ?? 'p-col-3';

				return (
					<Copy
						id={d}
						copyMessage={cpMessage}
						value={value}
						hidden={sGen.props.h}
						inputClass={lClass}
						gridless={props.group}
						label={label}
						labelClass={fClass}
					></Copy>
				);
			} else if (gen.props.t === 'state') {
				const sGen: ReactElement<GStateProps<T>> = gen as any;

				const d = sGen.props.d as string;

				const label = ls(
					sGen.props.l,
					'_',
					sGen.props.l ?? ls(sGen.props.d, '_', ls('status', 'generic'))
				);

				const value = obj[d];
				const small = sGen.props.small;
				const stateItems = sGen.props.custom;
				const onClick = sGen.props.onClick;
				const style = sGen.props.style;
				const lClass = sGen.props.lClass ?? props.lclass ?? 'p-col-3';

				return (
					<Wraper
						id={d}
						label={label}
						hidden={sGen.props.h}
						class={lClass}
						gridless={props.group}
					>
						<div
							className={sGen.props.fClass}
							style={{ marginTop: '0.5em', lineHeight: 0 }}
						>
							<StateIcon
								custom={stateItems}
								state={value}
								small={small}
								onClick={onClick}
								style={style}
							/>
						</div>
					</Wraper>
				);
			} else if (gen.props.t === 'v') {
				const vGen: ReactElement<GViewProps<T>> = gen as KnownAny;
				const d = vGen.props.d as string;
				const value = obj[d];
				const isDate = value
					? String(value).match(/^[\d-]{10}T[\d:]{8}.*/)
					: false;
				const valueO = isDate
					? formatDate(value, vGen.props.extended, false, true)
					: value;
				const label =
					ls(vGen.props.l ?? '') === 'LabelInfenranceFailed'
						? vGen.props.l ?? ls(vGen.props.d)
						: ls(vGen.props.l ?? '');
				const hidden = vGen.props.h ?? false;
				const inputClass = vGen.props.fClass ?? props.fclass ?? 'p-col';
				const labelClass = vGen.props.lClass ?? props.lclass ?? 'p-col-3';

				return (
					<Wraper
						id={d}
						label={label}
						hidden={hidden}
						class={labelClass}
						gridless={props.group}
					>
						<div className={inputClass}>{valueO}</div>
					</Wraper>
				);
			} else if (gen.props.t === 'div') {
				const dGen: ReactElement<GDivProps<T>> = gen as any;

				if (dGen.props.hidden) return <></>;

				const className = dGen.props.class ?? (dGen.props.group ? '' : 'p-col');

				let nProps = {
					...props,
					...dGen.props.extra,
					obj: dGen.props.obj ?? props.obj,
					setObj: dGen.props.setObj ?? props.setObj
				};
				if (dGen.props.group !== undefined)
					nProps = { ...nProps, group: dGen.props.group };

				const pChildren = dGen.props.children
					? Array.isArray(dGen.props.children)
						? React.Children.map(dGen.props.children, (e) =>
								processChildren(e, nProps)
						  )
						: processChildren(dGen.props.children, nProps)
					: null;

				return (
					<div
						className={
							(dGen.props.grid || dGen.props.group ? 'p-grid p-fluid ' : '') +
							(dGen.props.group ? 'm-0' : '') +
							className
						}
						style={dGen.props.style}
						onClick={dGen.props.onClick}
					>
						{pChildren}
					</div>
				);
			} else if (gen.props.t === 'messages') {
				const dGen: ReactElement<GMessagesProps> = gen as any;

				return (
					<div className="p-col-12">
						<Messages
							ref={(e) => {
								if (dGen.props.refM && e) dGen.props.refM.current = e;
							}}
							onRemove={dGen.props.onRemove}
						/>
					</div>
				);
			}
			return <></>;
		} else {
			return ai;
		}
	};

	function Form<T extends StringIndexed>(props: FormProps<T>) {
		const { onSubmit, children } = props;

		let pChildren: ReactElement[] | ReactElement = [];

		if (children) {
			if (Array.isArray(pChildren))
				pChildren = React.Children.map(children, (e) =>
					processChildren(e, props)
				);
			else pChildren = processChildren(children as ReactElement, props);
		}

		return (
			<form
				style={{ width: '100%', height: '100%', ...props.style }}
				onSubmit={onSubmit}
				ref={(e) => (props.formRef ?? noop)(e)}
			>
				<div className={props.class ?? 'p-fluid'}>{pChildren}</div>
			</form>
		);
	}
	Form.defaultProps = {
		t: 't'
	};

	const GDiv = <T extends StringIndexed>(props: GDivProps<T>) => {
		const { children } = props;
		const className = props.class ?? (props.group ? '' : 'p-col');
		let pChildren: ReactElement[] | ReactElement = [];

		const pProps = { ...props.extra, setObj: props.setObj, obj: props.obj };

		if (children) {
			if (Array.isArray(pChildren))
				pChildren = React.Children.map(children, (e: ReactElement) =>
					processChildren(e, pProps)
				);
			else pChildren = processChildren(children as ReactElement, pProps);
		}

		return (
			<div
				className={
					(props.grid || props.group ? 'p-grid p-fluid ' : '') +
					(props.group ? 'm-0' : '') +
					className
				}
				style={props.style}
				hidden={props.hidden}
				onClick={props.onClick}
			>
				{pChildren}
			</div>
		);
	};
	GDiv.defaultProps = {
		isGenericFormElement: true,
		isGenericInputFormElement: false,
		t: 'div',
		children: null
	};

	return { Form, GDiv };
};
