import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { TabPanel, TabView } from 'primereact/tabview';
import React from 'react';
import Config from '../../../config/config';
import ContractService from '../../../service/ContractService';
import Attachment from '../../attachment/Attachment';
import { Button, DialogPromt } from '../../Generic';
import { TemplateFromGenerator, TemplateFromGeneratorPage } from '../../generic/templateFormGenerator';
import {
    g_checkLoginACL,
    g_displayMessageError,
    g_genericRequired,
    g_getData,
    g_getDataFields,
    g_getDateTemplate,
    g_getHeaderTemplateNull,
    g_hideSearch,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dataTable,
    g_template_dropdown,
    LabelSelector,
    lb,
    ListPage,
    ls,
    pair,
    pairF,
    S,
} from '../../GenericFunctions';
import { MedicalCertificate, MedicalCertificateList } from './MedicalCertificate';
import { MedicalExam, MedicalExamList } from './MedicalExam';
import { OldCallSimple } from './oldcallsimple';
import { Prescription, PrescriptionList } from './Prescription';
import './videocall.css';
import { EndCallBtt, UTI, videoCallgetData } from './videocallnew';
import {Requests} from '../../../Requests';
import { DocumentType } from '../../../api-ts-bindings';

const { RTCPeerConnection, RTCSessionDescription } = window;

export default class VideoCall extends React.Component {
    constructor() {
        super();
        this.state = {
            tabI2: null,
            zoom: false,
            mode: true,
            idUUID: null,
            loaded: false,
            data: {},
            consignee: {},
            anamnesis: '',
            conclusion: '',
            problems: '',
            time: '00:00',
            tab2Data: [],

            //URI Related
            showUCIChangeDialog: false,
            showUCIChangeIndex: 0,

            //Cid Related
            cidSugestions: [],
            cidToAdd: null,
            alreadyClosed: false,
        };
    }

    mediaRecorderLocal = undefined;
    mediaRecorderLocalChunks = [];

    mediaRecorderRemote = undefined;
    mediaRecorderRemoteChunks = [];

    createPc() {
        const pc = new RTCPeerConnection({ iceServers: [{ urls: 'turn:usenumo.com.br:3478', username: 'vcprt', credential: 'pVc2020ProtetorXxX' }] });
        this.pc = pc;
    }

    async componentDidMount() {
        this.createPc();
        let items = [
            { label: LabelSelector('anamnesis', 'call') },
            { label: LabelSelector('cid', 'call') },
            { label: LabelSelector('prescription', 'call') },
            { label: LabelSelector('exams', 'call') },
            { label: LabelSelector('medicalCertificate', 'call') },
            { label: LabelSelector('listProblems', 'call') },
            { label: LabelSelector('history', 'call') },
            { label: LabelSelector('documents', 'call') },
        ];
        this.getNavigationDevices();
        g_getDataFields(this, S.utils.gender, 'genders', 'description', 'id', 'genders');
        //TODO: CHANGE
        this.setState({ items: items, mode: false, idUUID: this.props.storedInfo.referenceIdUUID, loaded: true, hasLocalStream: false }, () => this.getData());
    }

    componentWillUnmount() {
        if (this.sendofferTimeout) clearTimeout(this.sendofferTimeout);
        if (this.clockTimeout) clearInterval(this.clockTimeout);
    }

    createSaveFunction (name, documentType, getdata) {
        return async () => {
            if (!this.configs.record) return;
            var blob = new Blob(getdata());
            await Requests.contract.document.upload(blob, this.state.idUUID, documentType, name + ".webm", {
                'background-request': true,
                'request-message': 'Estamos a gravar e assinar electronicamente todos documentos associados a este atendimento. Mediante a velocidade da sua internet pode demorar um pouco. Por favor aguarde …'
            })
        };
    }

    setupLocalRecorded = async (s) => {
        let rec = new MediaRecorder(s);
        rec.ondataavailable = (ev) => {
            if (ev.data.size > 0) {
                this.mediaRecorderLocalChunks.push(ev.data);
            }   
        }
        rec.onstop = this.createSaveFunction('medico', DocumentType.doctor_video, () => this.mediaRecorderLocalChunks);
        rec.start();
        this.mediaRecorderLocal = rec;
    }

    getNavigationDevices = async () => {
        let stream = null;
        let pc = this.pc;
        pc.ontrack = ev => {
            let stream = new MediaStream();
            let rec = new MediaRecorder(stream);
            rec.ondataavailable = (ev) => {
                 if (ev.data.size > 0) this.mediaRecorderRemoteChunks.push(ev.data);
            }
            rec.onstop = this.createSaveFunction('utente', DocumentType.pacient_video, () => this.mediaRecorderRemoteChunks);
            ev.streams.forEach(a => {
                if (document.getElementById('remote-video')) document.getElementById('remote-video').srcObject = a;
                a.getTracks().forEach(t => stream.addTrack(t));
            });
            if (!this.mediaRecorderRemote) {
                rec.start();
                this.mediaRecorderRemote = rec;
            }
        };
        pc.oniceconnectionstatechange = e => {
            if (pc.iceConnectionState === 'checking') {
                this.checking = true;
            } else if (pc.iceConnectionState === 'connected') {
                this.checking = false;
            } else if (pc.iceConnectionState === 'disconnected' && this.checking) {
                this.checking = false;
                this.sendoffer();
            } else if (pc.iceConnectionState === 'disconnected' || pc.iceConnectionState === 'closed') {
                this.closeNavigationDevices();
                this.setState({ ended: true });
            } else this.setState({ ended: false });
        };
        //Set navigator to get mediaDevices
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        //get Media devices
        if (typeof navigator.mediaDevices === 'undefined') {
            navigator.getUserMedia(
                { video: true, audio: true },
                s => {
                    stream = s;
                    const localVideo = document.getElementById('local-video');
                    if (localVideo) localVideo.srcObject = stream;
                    stream.getTracks().forEach(track => {
                        pc.addTrack(track, stream);
                    });
                    this.setupLocalRecorded(s);
                },
                error => {
                    console.warn(error.message);
                }
            );
        } else {
            const getMedia = async () => {
                try {
                    stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                    const localVideo = document.getElementById('local-video');
                    if (localVideo) localVideo.srcObject = stream;
                    stream.getTracks().forEach(track => {
                        pc.addTrack(track, stream);
                    });
                    this.setupLocalRecorded(stream);
                } catch (err) {
                    console.warn(err.message);
                }
            }
            await getMedia();
        }
        //Set to recive tracks
        this.setState({ localStream: stream, hasLocalStream: stream !== null && stream !== undefined });
        this.sendoffer();
    };

    sendoffer = async () => {
        let offer = await this.pc.createOffer();
        await this.pc.setLocalDescription(new RTCSessionDescription(offer));
        if (!this.props.storedInfo) return;
        const message = {
            type: 'OFFER-PHASE1-REQ',
            recipient: this.props.storedInfo.recipient,
            callIdUUID: this.props.storedInfo.callIdUUID,
            tID: this.props.storedInfo.tID,
            value: JSON.stringify(offer),
        };
        try {
            window.stompC.send('/app/handshake', {}, JSON.stringify(message));
        } catch (e) {
            this.sendofferTimeout = setTimeout(this.sendoffer, 1000);
        }
    };

    count = 0;
    clockTimeout = null;
    signalStartCallTimeOut = null;
    signalStartCall = () => {
        if (!JSON.parse(localStorage.getItem('loggedUserN'))) return;
        let uuid = JSON.parse(localStorage.getItem('loggedUserN')).uuid;
        if (!this.state.contractOBJ) {
            if (this.signalStartCallTimeOut) return;
            this.signalStartCallTimeOut = setTimeout(() => {
                this.signalStartCallTimeOut = null;
                this.signalStartCall();
            }, 500);
            return;
        }
        let data = this.state.contractOBJ;
        data.workingEntityIdUUID = uuid;
        ContractService.updateWorkingEntity({ contractIdUUID: this.state.contractOBJ.idUUID, contractStatus: 1, workingEntityIdUUID: uuid }, true);
        this.clockTimeout = setInterval(() => {
            this.count++;
            let minutes = Math.floor(this.count / 60);
            let seconds = this.count % 60;
            this.setState({
                time: String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0'),
            });
        }, 1000);
    };

    wsEvent = async e => {
        console.log('wsEvent');
        if (e.type === 'OFFER-PHASE1-RESP') {
            console.log('got phase 1');
            await this.pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(e.value)));
            let offer = await this.pc.createOffer();
            await this.pc.setLocalDescription(new RTCSessionDescription(offer));
            const message = { type: 'OFFER-PHASE2-REQ', recipient: e.recipient, callIdUUID: e.callIdUUID, tID: e.tID, value: JSON.stringify(offer) };
            window.stompC.send('/app/handshake', {}, JSON.stringify(message));
        } else if (e.type === 'OFFER-PHASE2-RESP') {
            console.log('got phase 2');
            await this.pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(e.value)));
            this.signalStartCall();
        }
    };

    saveNote = async (callback = () => {}) => {
        await (async () => {
            if (!this.state.idUUID) return;
            let td = this.templateGeneratorsData;
            let ts = this.templateGeneratorsSaved;
            let c = Object.keys(td).filter(a => td[a]);
            let data = [
                ...Object.keys(ts)
                    .filter(a => ts[a] && c.indexOf(a) === -1)
                    .map(a => ts[a]),
                ...c.map(a => {
                    let c = this.state.tab2Data.filter(b => String(b.page) === a);
                    if (c[0]?.uti) return { uti: JSON.stringify(td[a].obj) };
                    return td[a].state.data;
                }),
            ].reduce((a, e) => ({ ...a, ...e }));
            data = Object.keys(data)
                .filter(a => a !== 'anamnese' && a !== 'diagnostic' && a !== 'problem_list')
                .map(e => ({ fieldName: e, fieldValue: data[e] }));
            //let data = Object.keys(this.templateGeneratorsData).map(a => this.templateGeneratorsData[a].state.data)
            //    .reduce((a, e) => [...a, ...e]);
            let r = await ContractService.fieldPut(
                {
                    contractComplementaryFields: [...data],
                    contractIdUUID: this.state.idUUID,
                },
                true
            );
            g_displayMessageError(this.messages, r);
        })();
        await (async () => {
            let data = this.templateGenerator.state.data;
            let objs = Object.keys(data);
            if (objs.length === 0) return;
            let r = await ContractService.fieldPut(
                {
                    contractComplementaryFields: objs.filter(e => data[e]).map(e => ({ fieldName: e, fieldValue: data[e] })),
                    contractIdUUID: this.templateGenerator.state.contractIdUUID,
                },
                true
            );
            g_displayMessageError(this.messages, r);
        })();
        await (async () => {
            let consignee = { ...this.state.consignee.consigneeObj, gender: this.state.consignee.gender };
            await S.consignee.update({
                consignee,
            });
        })();
        if (typeof callback === 'function') callback();
    };

    changedCallback = null;
    changed = () => {
        if (this.changedCallback) clearTimeout(this.changedCallback);
        this.changedCallback = setTimeout(() => {
            this.saveNote();
            this.changedCallback = null;
        }, 5000);
    };

    getData = () => videoCallgetData(this);

    close = (callClose = true) => {
        if (!this.state.alreadyClosed) {
            try {
                this.mediaRecorderRemote.stop();
            } catch { console.log("failed to stop media recorder")}
            try {
                this.mediaRecorderLocal.stop();
            } catch { console.log("failed to stop media recorder")}
        }
        this.closeNavigationDevices();
        this.props.close(callClose, this.state.alreadyClosed);
        if (!callClose) {
            this.setState({ alreadyClosed: true });
        }
    };

    closeNavigationDevices = () => {
        document.getElementById('local-video').srcObject = null;
        if (this.state.localStream) {
            this.state.localStream.getTracks().forEach(t => t.stop());
            this.state.localStream.getAudioTracks().forEach(t => t.stop());
            this.state.localStream.getVideoTracks().forEach(t => t.stop());
        }
        this.setState({ localStream: null, ended: true });
        this.pc.close();
    };

    configs = {
        triagePage: undefined,
        triageAcl: undefined,
        gotTriageAcl: undefined,
        triage: false,
        record: false,
    };
    templateGeneratorsData = {};
    templateGeneratorsSaved = {};
    render() {
        return g_checkLoginACL(e => {
            if (!this.state.idUUID && this.state.loaded) {
                this.props.invalidCall();
                return null;
            }

            if (this.state.timeoutcount && this.state.timeoutcount >= 0) {
                this.setState({ timeoutcount: -1 });
                return null;
            }

            if (!this.state.mode && e.u !== 1) this.setState({ mode: true });

            let z = this.state.zoom;

            //Generated TABS
            let generateTab2 = () =>
                this.state.tab2Data.length === 0 ? null : (
                    <>
                        <DialogPromt
                            hiddenBtt
                            show={this.state.showUCIChangeDialog}
                            onConfirm={() => this.setState({ showUCIChangeDialog: false, tab2: this.state.showUCIChangeIndex, showUCIChangeIndex: 0 })}
                            onDeny={() => this.setState({ showUCIChangeDialog: false, showUCIChangeIndex: 0 })}
                            closeOut={() => this.setState({ showUCIChangeDialog: false, showUCIChangeIndex: 0 })}
                            text="videocall_uti"
                        ></DialogPromt>
                        <TabView
                            activeIndex={this.state.tab2}
                            onTabChange={e => {
                                if (
                                    this.state.tab2Data[this.state.tab2] &&
                                    this.state.tab2Data[this.state.tab2].uti &&
                                    this.templateGeneratorsData[this.state.tab2Data[this.state.tab2].page] &&
                                    this.templateGeneratorsData[this.state.tab2Data[this.state.tab2].page].obj &&
                                    Object.keys(this.templateGeneratorsData[this.state.tab2Data[this.state.tab2].page].obj).length > 0 &&
                                    !this.templateGeneratorsData[this.state.tab2Data[this.state.tab2].page].obj.sign
                                ) {
                                    this.setState({ showUCIChangeDialog: true, showUCIChangeIndex: e.index });
                                    console.log(e.index, this.templateGeneratorsData[this.state.tab2Data[this.state.tab2].page].obj);
                                    return;
                                }
                                this.setState({ tab2: e.index });
                            }}
                        >
                            {this.state.tab2Data.map(a => (
                                <TabPanel contentStyle={{ height: '55vh', overflow: 'auto' }} header={a.title}>
                                    {(() => {
                                        if (a.uti)
                                            return (
                                                <UTI
                                                    ref={e => (this.templateGeneratorsData[a.page] = e)}
                                                    value={this.templateGeneratorsSaved[a.page].uti ?? ''}
                                                    changeValue={() => (this.templateGeneratorsSaved[a.page] = { uti: JSON.stringify(this.templateGeneratorsData[a.page].obj) })}
                                                    save={this.saveNote}
                                                    contractIdUUID={this.state.idUUID}
                                                />
                                            );
                                        return (
                                            <TemplateFromGeneratorPage
                                                vValue={this.templateGeneratorsSaved[a.page]}
                                                heigth="55vh"
                                                ref={e => (this.templateGeneratorsData[a.page] = e)}
                                                template={() => a.contractFields}
                                                save={b => (this.templateGeneratorsSaved[a.page] = b.state.data)}
                                            />
                                        );
                                    })()}
                                </TabPanel>
                            ))}
                        </TabView>
                    </>
                );

            return (
                <div style={{ minWidth: '100%', minHeight: '100%' }}>
                    <Growl ref={e => (this.messages = e)} />
                    <Dialog
                        visible={this.state.oldcall}
                        header={this.state.oldcallheader}
                        style={{ minWidth: '80vw' }}
                        onHide={() => this.setState({ oldcall: false, oldcallheader: '', oldcalliduuid: '' })}
                    >
                        <div style={{ height: 'calc((100vh - 50px - 66px - 16px - 0.7em) * 0.9)', minHeight: 'calc((100vh - 50px - 66px - 16px - 0.7em) * 0.9)', width: '80%' }}>
                            <OldCallSimple idUUID={this.state.oldcalliduuid} />
                        </div>
                    </Dialog>
                    <div style={{ position: 'relative', minWidth: '100%', minHeight: 'calc(100vh - 50px - 66px - 16px - 0.7em)' }}>
                        {/* OTHER */}
                        <div style={{ width: '24%', height: '5%', zIndex: '100', position: 'absolute', top: '95%', left: '75%' }}>
                            <div className="p-grid p-fluid">
                                {g_template_button(lb('save'), 'pi pi-save', false, () => this.saveNote(), false, 'p-col-6', '', '', { fontSize: '1.5em' })}
                                <EndCallBtt
                                    endCall={() => this.saveNote(this.close)}
                                    isTriage={this.configs.gotTriageAcl}
                                    endCallNoExit={() => {
                                        this.saveNote(() => this.close(false));
                                    }}
                                    skipCallDialog={this.state.alreadyClosed}
                                    idUUID={this.state.idUUID ?? ''}
                                />
                            </div>
                        </div>

                        {/*TABS*/}
                        <div className="p-grid p-fluid" style={{ left: '0.5%', top: '0.5%', width: '100%', position: 'absolute' }}>
                            {g_tabViewRenderOptions(
                                this,
                                [
                                    <div className="p-grid p-fluid" style={{ width: '100%', height: '100%' }}>
                                        <div className="p-col-12">
                                            <h1>{ls('problemsTitle', 'call')}</h1>
                                            {!this.props.mode ? (
                                                <form
                                                    onSubmit={async e => {
                                                        e.preventDefault();
                                                        let data = {};
                                                        if (typeof this.state.symptomToAdd === 'string') {
                                                            if (!this.state.symptomToAdd) return;
                                                            data = {
                                                                symptomContract: {
                                                                    contractIdUUID: this.state.idUUID ?? '',
                                                                    description: this.state.symptomToAdd ?? '',
                                                                },
                                                            };
                                                        } else {
                                                            data = {
                                                                symptomContract: {
                                                                    contractIdUUID: this.state.idUUID ?? '',
                                                                    symptomIdUUID: this.state.symptomToAdd?.idUUID ?? '',
                                                                },
                                                            };
                                                        }
                                                        let r = await S.health.symptom.contract.add(data);
                                                        if (g_displayMessageError(null, r)) return;
                                                        this.setState({ symptomToAdd: null });
                                                        this.symptomList.getData();
                                                    }}
                                                >
                                                    <div className="p-col p-grid">
                                                        <div className="p-col-11">
                                                            <AutoComplete
                                                                value={this.state.symptomToAdd}
                                                                onChange={e => this.setState({ symptomToAdd: e.value })}
                                                                suggestions={this.state.symptomSugestions}
                                                                field="description"
                                                                completeMethod={async e => {
                                                                    let q = e.query;
                                                                    this.querySymptomCompleting = q;
                                                                    let r = await S.health.symptom.list({ filters: [pair('fulltextlike', q.replace(/ /g, '%'))] }, true);
                                                                    if (g_displayMessageError(null, r) || q !== this.querySymptomCompleting) return;
                                                                    console.log(r.symptoms);
                                                                    this.setState({ symptomSugestions: r.symptoms });
                                                                }}
                                                            />
                                                        </div>
                                                        <Button label="" icon="pi pi-plus" type="submit" bttClass="p-button-success" style={{ fontSize: '1.2em', height: '80%' }} />
                                                    </div>
                                                </form>
                                            ) : null}
                                            <ListPage
                                                checkAcl={false}
                                                header={() => undefined}
                                                ref={e => (this.symptomList = e)}
                                                table={() => [
                                                    { type: 'd', data: 'id', title: ls('icdCode') },
                                                    { type: 'd', data: 'description' },
                                                    {
                                                        c: !this.props.mode,
                                                        type: 'btt',
                                                        tooltip: ls('remove', 'tooltip'),
                                                        icon: 'pi pi-trash',
                                                        class: 'p-button-danger',
                                                        click: async (raw, e) => {
                                                            e.preventDefault();
                                                            /* let _ = */
                                                            await S.health.symptom.contract.status({
                                                                symptomContractIdUUID: raw.idUUID,
                                                                status: 2,
                                                            });
                                                        },
                                                    },
                                                ]}
                                                getData={c =>
                                                    !this.state.idUUID
                                                        ? null
                                                        : g_getData(c, S.health.symptom.contract.list, 'symptomContracts', {
                                                              filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID ?? ''), pair('status', 1)],
                                                              pageSize: 20,
                                                          })
                                                }
                                            />
                                        </div>
                                        <div className="p-col-12">{generateTab2()}</div>
                                    </div>,

                                    //CID
                                    <div className="p-col-12">
                                        <form
                                            onSubmit={async e => {
                                                e.preventDefault();
                                                if (typeof this.state.cidToAdd === 'string' || this.state.cidToAdd === null || this.state.cidToAdd === undefined) return;
                                                let data = {
                                                    cidContract: {
                                                        cidIdUUID: this.state.cidToAdd.idUUID,
                                                        contractIdUUID: this.state.idUUID,
                                                        type: this.state.cidToAdd.type,
                                                    },
                                                };
                                                let r = await S.health.cid.contract.add(data);
                                                if (g_displayMessageError(null, r)) return;
                                                this.setState({ cidToAdd: null });
                                                this.cidList.getData();
                                            }}
                                        >
                                            <div className="p-col p-grid">
                                                <div className="p-col-11">
                                                    <AutoComplete
                                                        value={this.state.cidToAdd}
                                                        onChange={e => this.setState({ cidToAdd: e.value })}
                                                        suggestions={this.state.cidSugestions}
                                                        field="description"
                                                        completeMethod={async e => {
                                                            let q = e.query;
                                                            this.querySidCompleting = q;
                                                            let r = await S.health.cid.list({ filters: [pair('fulltextlike', q.replace(/ /g, '%'))] }, true);
                                                            if (g_displayMessageError(null, r) || q !== this.querySidCompleting) return;
                                                            this.setState({ cidSugestions: r.cids });
                                                        }}
                                                    />
                                                </div>
                                                <Button label="" bttClass="p-button-success" icon="pi pi-plus" type="submit" style={{ fontSize: '1.2em', height: '80%' }} />
                                            </div>
                                        </form>
                                        <ListPage
                                            ref={e => (this.cidList = e)}
                                            table={() => [
                                                { type: 'd', data: 'cidId', title: ls('icdCode') },
                                                { type: 'd', data: 'description' },
                                                {
                                                    type: 'btt',
                                                    tooltip: ls('remove', 'tooltip'),
                                                    icon: 'pi pi-trash',
                                                    class: 'p-button-danger',
                                                    click: async (raw, e) => {
                                                        e.preventDefault();
                                                        await S.health.cid.contract.status({
                                                            cidContractIdUUID: raw.idUUID,
                                                            status: 2,
                                                        });
                                                    },
                                                },
                                            ]}
                                            getData={c =>
                                                g_getData(c, S.health.cid.contract.list, 'cidContracts', {
                                                    filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID), pair('status', 1)],
                                                })
                                            }
                                        />
                                        <ListPage
                                            title={ls('past', 'titles')}
                                            table={() => [
                                                { type: 'd', data: 'cidId', title: ls('icdCode') },
                                                { type: 'd', data: 'description' },
                                                { type: 'date', data: 'dateCreation' },
                                            ]}
                                            getData={c =>
                                                g_getData(c, S.health.cid.contract.list, 'cidContracts', {
                                                    filters: [
                                                        ...c.state.filter,
                                                        pair('consigneeIdUUID', this.state.consignee.consigneeObj.idUUID),
                                                        pair('notincontractiduuid', this.state.idUUID),
                                                        pair('status', 1),
                                                    ],
                                                })
                                            }
                                        />
                                    </div>,

                                    //PRESCRICAO
                                    <div className="p-col-12">
                                        <PrescriptionList
                                            header={() => (
                                                <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                                    <Button
                                                        icon="pi pi-plus"
                                                        bttClass="p-button-success"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({ prescriptionVisible: true });
                                                        }}
                                                        wraperClass=""
                                                        style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                                    />
                                                </div>
                                            )}
                                            extra={[
                                                {
                                                    t: 'btt',
                                                    icon: 'pi pi-pencil',
                                                    class: 'p-button-success',
                                                    extra: 'p-col-12',
                                                    label: lb('edit'),
                                                    click: row => this.setState({ prescriptionIdUUID: row.idUUID, prescriptionVisible: true }),
                                                },
                                            ]}
                                            extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID)] })}
                                            refTable={e => (this.prescriptionTable = e)}
                                        />
                                        <Dialog
                                            style={{ minWidth: '90vw', minHeight: '90vh' }}
                                            visible={this.state.prescriptionVisible}
                                            onHide={() => this.setState({ prescriptionVisible: false, prescriptionIdUUID: null }, this.prescriptionTable.getData)}
                                            contentStyle={{ height: '90vh' }}
                                        >
                                            <Prescription
                                                createOnIdUUIDEmpty
                                                contractIdUUID={this.state.idUUID}
                                                idUUID={this.state.prescriptionIdUUID ?? ''}
                                                saved={idUUID => {
                                                    this.setState({ prescriptionIdUUID: idUUID });
                                                }}
                                                exit={() => this.setState({ prescriptionVisible: false, prescriptionIdUUID: null })}
                                                consignee={this.state.consignee.consigneeObj}
                                            />
                                        </Dialog>
                                    </div>,

                                    //EXAMES
                                    <div className="p-col-12">
                                        <MedicalExamList
                                            header={() => (
                                                <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                                    <Button
                                                        icon="pi pi-plus"
                                                        bttClass="p-button-success"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({ medicalExamVisible: true });
                                                        }}
                                                        wraperClass=""
                                                        style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                                    />
                                                </div>
                                            )}
                                            extra={[
                                                {
                                                    t: 'btt',
                                                    icon: 'pi pi-pencil',
                                                    class: 'p-button-success',
                                                    extra: 'p-col-12',
                                                    label: lb('edit'),
                                                    click: row => this.setState({ medicalExamIdUUID: row.idUUID, medicalExamVisible: true }),
                                                },
                                            ]}
                                            extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID)] })}
                                            refTable={e => (this.medicalExamTable = e)}
                                        />
                                        <Dialog
                                            style={{ minWidth: '90vw', minHeight: '90vh' }}
                                            visible={this.state.medicalExamVisible}
                                            onHide={() => this.setState({ medicalExamVisible: false, medicalExamIdUUID: null }, this.medicalExamTable.getData)}
                                            contentStyle={{ height: '90vh' }}
                                        >
                                            <MedicalExam
                                                createOnIdUUIDEmpty
                                                contractIdUUID={this.state.idUUID}
                                                idUUID={this.state.medicalExamIdUUID ?? ''}
                                                saved={idUUID => {
                                                    this.setState({ medicalExamIdUUID: idUUID });
                                                }}
                                                exit={() => this.setState({ medicalExamVisible: false, medicalExamIdUUID: null })}
                                                consignee={this.state.consignee.consigneeObj}
                                            />
                                        </Dialog>
                                    </div>,

                                    //Atestado
                                    <div className="p-col-12">
                                        <MedicalCertificateList
                                            header={() => (
                                                <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                                    <Button
                                                        icon="pi pi-plus"
                                                        bttClass="p-button-success"
                                                        wraperClass=""
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({ medicalCertificateVisible: true });
                                                        }}
                                                        style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                                    />
                                                </div>
                                            )}
                                            extra={[
                                                {
                                                    t: 'btt',
                                                    icon: 'pi pi-pencil',
                                                    class: 'p-button-success',
                                                    extra: 'p-col-12',
                                                    label: lb('edit'),
                                                    click: row => this.setState({ medicalCertificateIdUUID: row.idUUID, medicalCertificateVisible: true }),
                                                },
                                            ]}
                                            extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID)] })}
                                            refTable={e => (this.prescriptionTable = e)}
                                        />
                                        <Dialog
                                            style={{ minWidth: '90vw', minHeight: '90vh' }}
                                            visible={this.state.medicalCertificateVisible}
                                            onHide={() => this.setState({ medicalCertificateVisible: false, medicalCertificateIdUUID: null }, this.prescriptionTable.getData)}
                                            contentStyle={{ height: '90vh' }}
                                        >
                                            <MedicalCertificate
                                                consignee={this.state.consignee.consigneeObj}
                                                contractIdUUID={this.state.idUUID}
                                                idUUID={this.state.medicalCertificateIdUUID ?? ''}
                                                saved={idUUID => {
                                                    this.setState({ medicalCertificateIdUUID: idUUID });
                                                }}
                                                exit={() => this.setState({ medicalCertificateIdUUID: null, medicalCertificateVisible: false })}
                                            />
                                        </Dialog>
                                    </div>,

                                    //HISTORICO
                                    <div className="p-col-12">
                                        <ListPage
                                            checkAcl={false}
                                            header={null}
                                            table={() => [
                                                { type: 'd', data: 'id', filter: true, title: ls('icdCode') },
                                                { type: 'd', data: 'description' },
                                                { type: 'date', data: 'dateCreation' },
                                            ]}
                                            getData={c =>
                                                g_getData(c, S.health.historical.list, 'historicals', {
                                                    filters: [...c.state.filter, pair('consigneeIdUUID', this.state.consignee.consigneeObj.idUUID)],
                                                })
                                            }
                                        />
                                    </div>,

                                    //OLD CALS
                                    <div className="p-col-12">
                                        <CallFromUser idUUID={this.state.idUUID} context={() => this} />
                                    </div>,

                                    //DOCS
                                    //! Note: filterOptions was decided to be hardcoded
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-12">
                                            <Attachment
                                                idUUID={this.state.idUUID}
                                                documentContextType={4}
                                                documentTypeId={'8'}
                                                service={'contract'}
                                                update={this.state.activeItem}
                                                filterOptions={[pair('ids', '8,599')]}
                                            />
                                        </div>
                                        <div className="p-col-12">
                                            <h1>{ls('all', 'generic')}</h1>
                                            <Attachment
                                                add={false}
                                                documentContextType={4}
                                                documentTypeId={'8'}
                                                service={'contract'}
                                                update={this.state.activeItem}
                                                filters={pairF('consigneeIdUUIDOnContract', this.state.consignee?.consigneeObj?.idUUID, this.state.consignee?.consigneeObj?.idUUID)}
                                            />
                                        </div>
                                    </div>,
                                ],
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                { width: '74%' }
                            )}
                        </div>

                        {/*Side panel*/}
                        <div className="p-grid p-fluid" style={{ width: '25%', height: '40%', position: 'absolute', left: '75%' }}>
                            <div className="p-col-10" style={{ height: '10px' }}></div>
                            <div className="p-col-2" style={{ height: '20px', fontSize: '1.5em' }}>
                                {this.state.localStream !== null ? this.state.time : ''}
                            </div>
                            <div className="p-col-4" style={{ textAlign: 'center' }}>
                                <img
                                    style={{ width: '105px', height: '105px' }}
                                    src={this.state.consignee.photo ? Config.PHOTO_URL + this.state.consignee.photo : window.location.origin + '/assets/images/person.png'}
                                    alt=""
                                />
                            </div>
                            <div className="p-col-8 p-gird p-fluid">
                                <div className="p-col-12">{this.state.consignee.fullname}</div>
                                <div className="p-col-12">
                                    {LabelSelector('dateBirth', 'call')}: {this.state.consignee.dateBirth} ({this.state.consignee.age})
                                </div>
                                <div className="p-col-12">
                                    {g_template_dropdown(
                                        this,
                                        'gender',
                                        this.state.consignee.gender,
                                        ls('sex', 'call'),
                                        this.state.genders,
                                        false,
                                        false,
                                        false,
                                        'p-col-5',
                                        undefined,
                                        false,
                                        '',
                                        { data: 'consignee' }
                                    )}
                                </div>
                                <div className="p-col-12">
                                    {ls('bmi', 'call')}:{' '}
                                    {(() => {
                                        if (!this.templateGenerator) return '-';
                                        if (!this.templateGenerator.state?.data?.altura || !this.templateGenerator.state?.data?.peso) return;
                                        let heigth = Number(this.templateGenerator.state?.data?.altura);
                                        let weight = Number(this.templateGenerator.state?.data?.peso);
                                        if (isNaN(heigth) || isNaN(weight)) return '-';
                                        //Heigth is in cm, so we need to convert it to m
                                        heigth = heigth / 100;
                                        let BMI = String(weight / Math.pow(heigth, 2));
                                        BMI = BMI.split('.')[0] + ',' + (BMI.split('.')[1] ?? '').substr(0, 2);
                                        return BMI;
                                    })()}
                                </div>
                            </div>
                            <div className="p-col-1"></div>
                            <div style={{ height: '60%', overflowY: 'scroll' }} className="p-col-12">
                                <div className="p-col-12" >
                                    <TemplateFromGenerator
                                        dfLabelSize={4}
                                        ref={e => (this.templateGenerator = e)}
                                        template={() => (!localStorage.getItem('BASEMEDICALFORM') ? null : JSON.parse(localStorage.getItem('BASEMEDICALFORM')))}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Video feeds*/}
                        <div
                            style={{
                                background: '#000',
                                position: 'absolute',
                                width: z ? '80%' : '25%',
                                height: z ? '80%' : '25%',
                                left: z ? '10%' : '75%',
                                top: z ? '10%' : '69%',
                            }}
                        >
                            <video hidden={this.state.ended} style={{ width: '100%', height: '100%' }} autoPlay id="remote-video" />
                        </div>
                        <div
                            hidden={this.state.hasLocalStream}
                            style={{
                                background: '#000',
                                position: 'absolute',
                                width: z ? '73%' : '14%',
                                height: z ? '20%' : '10%',
                                left: z ? '17%' : '81%',
                                top: z ? '44%' : '80%',
                            }}
                        >
                            <h1 hidden={this.state.hasLocalStream} style={{ color: 'white', fontSize: z ? '5em' : '1em' }}>
                                {LabelSelector('premission', 'call')}
                            </h1>
                        </div>
                        <div
                            hidden={!this.state.ended}
                            style={{
                                background: '#000',
                                position: 'absolute',
                                width: z ? '73%' : '14%',
                                height: z ? '20%' : '10%',
                                left: z ? '17%' : '81%',
                                top: z ? '44%' : '80%',
                            }}
                        >
                            <h1 hidden={!this.state.ended} style={{ color: 'white', fontSize: z ? '5em' : '1em' }}>
                                {LabelSelector('ended', 'call')}
                            </h1>
                        </div>
                        <div className="p-grid" style={{ position: 'absolute', width: z ? '14%' : '7%', left: z ? '13%' : '76%', top: z ? '83%' : '90%', height: '1%' }}>
                            {g_template_button(
                                '',
                                `pi ${document.getElementById('local-video')?.srcObject?.getAudioTracks()[0]?.enabled ? 'pi-volume-up' : 'pi-volume-down'}`,
                                false,
                                () => {
                                    let a = document.getElementById('local-video')?.srcObject?.getAudioTracks()[0].enabled;
                                    if (a !== undefined) {
                                        document.getElementById('local-video').srcObject.getAudioTracks()[0].enabled = !a;
                                    }
                                },
                                false,
                                'p-col-2',
                                `p-button-${document.getElementById('local-video')?.srcObject?.getAudioTracks()[0].enabled ? 'success' : 'danger'}`,
                                '',
                                { fontSize: z ? '2em' : '1.0em' }
                            )}
                            {g_template_button(
                                '',
                                `pi pi-video`,
                                false,
                                () => {
                                    let a = document.getElementById('local-video')?.srcObject?.getVideoTracks()[0].enabled;
                                    if (a !== undefined) {
                                        document.getElementById('local-video').srcObject.getVideoTracks()[0].enabled = !a;
                                    }
                                },
                                false,
                                'p-col-2',
                                `p-button-${document.getElementById('local-video')?.srcObject?.getVideoTracks()[0].enabled ? 'success' : 'danger'}`,
                                '',
                                { fontSize: z ? '2em' : '1.0em' }
                            )}
                            {g_template_button(
                                '',
                                `pi pi-window-${this.state.zoom ? 'minimize' : 'maximize'}`,
                                false,
                                () => this.setState({ zoom: !this.state.zoom }),
                                false,
                                'p-col-2',
                                `p-button-${this.state.zoom ? 'danger' : 'success'}`,
                                '',
                                { fontSize: z ? '2em' : '1.0em' }
                            )}
                        </div>
                        <div
                            style={{
                                background: '#000',
                                color: 'white',
                                boder: '1px solid white ',
                                borderRadius: '5px',
                                position: 'absolute',
                                left: z ? '70%' : '92%',
                                top: z ? '68%' : '85%',
                                width: z ? '20%' : '7.5%',
                                height: z ? '20%' : '7.5%',
                            }}
                        >
                            <video muted style={{ width: '100%', height: '100%' }} hidden={!this.state.hasLocalStream} autoPlay id="local-video" />
                        </div>
                    </div>
                </div>
            );
        });
    }
}

export class CallFromUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired() };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = !this.props.context().state?.consignee?.consigneeObj
        ? () => {}
        : () =>
              g_getData(this, S.call.list, 'callRecords', {
                  filters: [
                      ...this.state.filter,
                      pair('originiduuid', this.props.context().state.consignee.consigneeObj.userIdUUID),
                      ...pairF('notcontractiduuid', this.props.idUUID, this.props.idUUID),
                  ],
              });
    formatTime = sec => {
        sec = Math.round(sec);
        let h = Math.floor(sec / 60 / 60);
        if (h > 0) sec = sec - h * 60 * 60;
        let min = Math.floor(sec / 60);
        if (min > 0) sec = sec - min * 60;
        return `${h > 0 ? h + ':' : ''}${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    };
    render() {
        return g_checkLoginACL(
            _ =>
                g_template_dataTable(
                    this,
                    g_getHeaderTemplateNull(this, this.getData),
                    [
                        { type: 'd', data: 'destinationName', sortable: true, filter: true },
                        { type: 'd', data: 'contractInfo', sortable: true, filter: true },
                        { type: 'date', title: 'Data Inicio', data: 'dateStartCall', sortable: true, filter: true },
                        { type: 'date', title: 'Data Fim', data: 'dateEndCall', sortable: true, filter: true },
                        {
                            type: 'di',
                            title: 'Duração',
                            di: raw =>
                                raw.dateStartCall && raw.dateEndCall ? this.formatTime((new Date(raw.dateEndCall).getTime() - new Date(raw.dateStartCall).getTime()) / 1000) : '',
                        },
                        { type: 'd', title: 'Estado', data: 'callRecordStatusDescription', sortable: true },
                        {
                            type: 'button',
                            icon: 'pi pi-eye',
                            click: raw =>
                                !raw.contractIdUUID
                                    ? null
                                    : this.props.context().setState({
                                          oldcall: true,
                                          oldcallheader: (
                                              <>
                                                  {raw.destinationName} - {raw.contractInfo} ({g_getDateTemplate(raw.dateCreation, true)})
                                              </>
                                          ),
                                          oldcalliduuid: raw.contractIdUUID,
                                      }),
                        },
                    ],
                    this.getData
                ),
            true,
            'calls'
        );
    }
}
