import type { GenericListResponseG } from '../../Generic';
import type { GenericListRequest, RequestsClass } from '../../Requests';
import type { ShoppingCart } from '../ShoppingCart';

export type Filters =
	| 'status'
	| 'requesterIdUUID'
	| 'showPriceForCampaignIdUUID';

export const list =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters>
	): GenericListResponseG<'shoppingCarts', ShoppingCart> =>
		req.axios.post(`${baseUrl}/list`, data);
