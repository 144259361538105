import { RequestsClass } from '../../Requests';
import { profile } from '../profile';
import { list } from './list';

const baseUrl = '/user';

export const UserApi = (req: RequestsClass) => ({
	list: list(req, baseUrl),
	profile: profile(req, baseUrl)
});
