//TODO: transfer to tsx
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import config, * as Config from '../../../config/config';
import UserService from '../../../service/UserService';
import GPhotoUpload from '../../file/G_photoFileUpload/PhotoUpload';
import { StateIcon } from '../../Generic';
import {
    g_ApiUserResponse,
    g_buttonTemplate,
    g_ChangeStatus,
    g_checkAlc,
    g_checkLoginACL,
    g_dialogTemplate,
    g_displayMessageError,
    g_getDateTemplate,
    g_getWraper,
    g_handleChange,
    g_inputMaskTemplate,
    g_inputTemplate,
    g_openWindowDialog,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_dialog,
    g_template_dropdown,
    g_wraper,
    lb,
    LS,
    ls,
} from '../../GenericFunctions';
import Log from '../../logs/Log';
import AssociationProfile from './AssosiationProfile';

export default class User extends Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiUserResponse(1),
            idUUID: null,
            items: [{ label: 'Utilizador' }],
            holderphoto: null,
            onSubmit: this.onSubmit,
            mode: false,
        };
    }

    changePasswordClick = e => {
        e.preventDefault();
        g_openWindowDialog('#/change-password?type=1', 'changepassword', 420, 220);
    };

    handlerResetClick = async e => {
        e.preventDefault();
        const input = {
            idUUID: this.state.data.idUUID,
            userType: 1,
            email: this.state.data.email,
            notificationChannel: 1,
        };
        const response = await UserService.resetPassword(input);
        if (response && (response.code === '1202' || response.code === '1203')) this.messages.show({ severity: 'error', summary: LS('userNotFound', 'messages') });
        if (response && response.code === 0) this.messages.show({ severity: 'success', summary: LS('passwordResetSuccess', 'messages') });
    };

    updateStatus = (e, s) =>
        g_ChangeStatus(
            this,
            () => UserService.updateStatus({ idUUID: this.state.idUUID, userStatus: s, userType: 1 }),
            this.getData,
            LS('simpleStatus', 'messages'),
            this.state.messages,
            e
        );
    updateStatusClick = e => this.updateStatus(e, this.state.data.userStatus === 1 ? 2 : 1);
    blockStatus = e => this.updateStatus(e, 3);

    componentDidMount() {
        let id;
        if (this.props.location) {
            let query = new URLSearchParams(this.props.location.search);
            id = query.get('id');
        }
        if (this.props.idUUID) {
            id = this.props.idUUID;
        }
        if (this.props.create) {
            id = null;
        }
        this.setState(
            {
                idUUID: id,
                checkACL: this.props.checkACL !== null ? this.props.checkACL : true,
                onSubmit: this.props.onSubmit ? this.props.onSubmit : this.onSubmit,
                mode: false,
            },
            this.getData
        );
    }

    getData = async () => {
        if (this.state.idUUID) {
            let data = {
                pageSize: 1,
                pageNumber: 1,
                userType: 1,
                filters: [{ key: 'idUUID', value: this.state.idUUID }],
            };
            let response = await UserService.listUsers(data);
            if (response && response.code === 0) {
                let user = response.users[0];
                //user.userStatus = String(user.userStatus);
                this.setState({
                    data: user,
                    items: !this.props.mode
                        ? [
                              { label: LS('user', 'tab') },
                              ...(g_checkAlc(4, `user#associationprofileuser`) ? [{ label: LS('profile', 'tab'), icon: 'pi pi-tags' }] : []),
                              { label: LS('log', 'tab'), icon: 'pi pi-list' },
                          ]
                        : [{ label: LS('user', 'tab') }],
                });
            }
        }
    };

    onSubmit = async event => {
        event.preventDefault();
        if (this.state.idUUID !== null) {
            let data = this.state.data;
            data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : '';
            data = { user: data };
            let response = await UserService.updateUser(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let data = this.state.data;
            data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : '';
            data = { user: data };
            let response = await UserService.createUser(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            if (this.state.holderphoto !== null) {
                await this.upload(this.state.holderphoto, 1, response.user.idUUID);
                this.setState({ holderphoto: null });
            }
            this.setState({ idUUID: response.user.idUUID }, this.getData);
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) {
            this.setState({ holderphoto: formData });
            return;
        } else {
            this.upload(formData, data.documentTypeId, this.state.idUUID);
        }
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'sucess', summary: LS('simpleUploadStart', 'messages') });
        var response = await UserService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN.uuid === this.state.data.idUUID) {
            loggedUserN.photo = response.photo;
            localStorage.setItem('loggedUserN', JSON.stringify(loggedUserN));
            window.location.reload(false);
        }
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col-12" onSubmit={e => this.state.onSubmit(e, this)}>
                            <div className="p-grid p-fuild">
                                <div
                                    className={
                                        this.idUUID !== null
                                            ? 'p-col-12 p-sm-7 p-lx-7 p-lg-7 p-md-7 p-fluid contact-form'
                                            : 'p-col-12 p-sm-12 p-lx-12 p-lg-12 p-md-12 p-fluid contact-form'
                                    }
                                >
                                    <Panel>
                                        {g_inputTemplate(
                                            'username',
                                            this.state.data.username,
                                            LS('username'),
                                            this,
                                            true,
                                            this.props.mode ?? this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-5',
                                            'p-col-2 p-sm-3'
                                        )}
                                        {g_inputTemplate(
                                            'fullname',
                                            this.state.data.fullname,
                                            LS('fullname'),
                                            this,
                                            true,
                                            this.props.mode ?? this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-9',
                                            'p-col-2 p-sm-3'
                                        )}
                                        {g_inputTemplate(
                                            'email',
                                            this.state.data.email,
                                            LS('email'),
                                            this,
                                            true,
                                            this.props.mode ?? this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-8',
                                            'p-col-2 p-sm-3'
                                        )}
                                        {g_inputMaskTemplate(
                                            'phoneMobile',
                                            this.state.data.phoneMobile,
                                            LS('phoneMobile'),
                                            this,
                                            LS('phoneMobile', 'mask'),
                                            '',
                                            false,
                                            this.props.mode ?? this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-3',
                                            'p-col-2 p-sm-3'
                                        )}
                                        {g_template_dropdown(
                                            this,
                                            'languageId',
                                            this.state.data.languageId,
                                            LS('languageId'),
                                            Config.languages,
                                            this.props.mode ?? this.state.mode,
                                            0,
                                            0,
                                            'p-col-2',
                                            'p-col-3'
                                        )}
                                        {g_wraper(
                                            'enroll',
                                            LS('mobileEnrollmentStatus'),
                                            [
                                                g_template_checkBox(this, 'mobileEnrollmentStatus', this.state.data.mobileEnrollmentStatus, null, true, false, 'p-col-1'),
                                                g_getDateTemplate(this.state.data.dateMobileEnrollment),
                                            ],
                                            !this.state.idUUID,
                                            'p-col-3'
                                        )}
                                        {g_wraper(
                                            'terms',
                                            LS('legalAcceptanceStatus'),
                                            [
                                                g_template_checkBox(this, 'legalAcceptanceStatus', this.state.data.legalAcceptanceStatus, null, true, false, 'p-col-1'),
                                                g_getDateTemplate(this.state.data.dateLegalAcceptance),
                                            ],
                                            !this.state.idUUID,
                                            'p-col-3'
                                        )}
                                    </Panel>
                                </div>
                                <div style={{ display: this.state.idUUID === null ? 'none' : '' }} className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon small state={this.state.data.userStatus} custom={userStates} />
                                            </div>,
                                            this.state.idUUID == null,
                                            'p-col-5'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID === null, 'p-col-6')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID === null, 'p-col-6')}
                                        {g_getWraper(null, LS('dateLastLogin'), g_getDateTemplate(this.state.data.dateLastLogin), this.state.idUUID === null, 'p-col-6')}
                                        {g_getWraper(null, LS('dateLastUserLock'), g_getDateTemplate(this.state.data.dateLastUserLock), this.state.idUUID === null, 'p-col-6')}
                                        {g_getWraper(
                                            null,
                                            LS('dateLastPasswordChange'),
                                            g_getDateTemplate(this.state.data.dateLastPasswordChange),
                                            this.state.idUUID === null,
                                            'p-col-6'
                                        )}
                                        {g_getWraper(
                                            null,
                                            LS('passwordRetries'),
                                            <span className="p-col">{this.state.data.passwordRetries}</span>,
                                            this.state.idUUID === null,
                                            'p-col-6'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-2 p-md-2 p-lg-2">
                                    <GPhotoUpload
                                        style={{ width: '105px', height: '105px' }}
                                        disabled={this.props.LUser ? !this.props.LUser : this.props.mode ?? this.state.mode}
                                        handlePhotoUpload={this.onUpload}
                                        photo={this.state.data.photo}
                                        documentTypeId={1}
                                        prefixPath={config.PHOTO_URL}
                                        id={this.state.idUUID}
                                    />
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', this.state.data.userStatus === 3, null, this.state.mode, 'p-col-1')}
                                {g_buttonTemplate(
                                    LS('resetPassword', 'btt'),
                                    '',
                                    '',
                                    !this.state.idUUID || this.state.data.userStatus === 3 || (this.props.mode ?? this.state.mode),
                                    this.handlerResetClick,
                                    this.props.mode,
                                    'p-col-2'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.userStatus === 1 ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'bq',
                                    this.updateStatusClick,
                                    LS(this.state.data.userStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                    this.state.data.userStatus === 3 || !this.state.idUUID || (this.props.mode ?? this.state.mode),
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-warning'
                                )}
                                {g_dialogTemplate(
                                    LS('askBlockP', 'messages'),
                                    'accessItemStatusBloquear',
                                    this,
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    LS('yes'),
                                    LS('no'),
                                    '',
                                    'p-button-danger',
                                    this.state.idUUID == null || (this.props.mode ?? this.state.mode) || e.d !== 1 || this.state.data.userStatus === 3,
                                    (this.props.mode ?? this.state.mode) || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'auto',
                                    'p-col-2'
                                )}
                                {g_buttonTemplate(
                                    LS('changePassword', 'btt'),
                                    '',
                                    '',
                                    !this.state.idUUID || this.state.data.profileStatus === '3' || !this.props.mode,
                                    this.changePasswordClick,
                                    !this.props.mode,
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                        ...(g_checkAlc(4, `user#associationprofileuser`) ? [<AssociationProfile idUUID={this.state.idUUID} userType={1} />] : []),
                        <Log idUUID={this.state.idUUID} logContextType={1} />,
                    ])}
                </div>
            );
        }, this.state.checkACL);
    }
}

export const userStates = {
    1: { color: 'green', class: 'pi-check', tooltip: ls('active', 'tooltip') },
    2: { color: 'red', class: 'pi-times', tooltip: ls('disabled', 'tooltip') },
    3: { color: 'red', class: 'pi-ban', tooltip: ls('blocked', 'tooltip') },
};
