import type { RequestsClass } from '../../Requests';
import { report } from '../report';
import {
	getdashboardpersonal,
	getdashboardtotal,
	getdashboardtotalcategory,
	getslalist,
	listevolutions,
	listgroupcategory,
	listgroupstate
} from './list';

const baseUrl = '/sla';

export const SLAApi = (req: RequestsClass) => ({
	listgroupstate: listgroupstate(req, baseUrl),
	listgroupcategory: listgroupcategory(req, baseUrl),
	getslalist: getslalist(req, baseUrl),
	listevolutions: listevolutions(req, baseUrl),
	getdashboardpersonal: getdashboardpersonal(req, baseUrl),
	getdashboardtotal: getdashboardtotal(req, baseUrl),
	getdashboardtotalcategory: getdashboardtotalcategory(req, baseUrl),
	report: report(req, baseUrl)
});
