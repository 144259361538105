import React from 'react';
import { g_getData, pair } from '../Generic';
import { g_treatDate, ls, S } from '../GenericFunctions';
import { TablePage } from '../generic/DataTable';

export interface ReportBillModel {
    consignatorIdUUID: string;
    consignatorName: string;
    contractInstallmentCount: number;
    contractInstallmentValue: number;
    contractTemplateIdUUID: string;
    contractTemplateTitle: string;
    dateContractMonth: number;
    dateContractYear: number;
    productIdUUID: string;
    productName: string;
}

interface ReportBillListProps { }
export const ReportBillList: React.FC<ReportBillListProps> = (_props: ReportBillListProps) => {
    let today = new Date();
    let startMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let endMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return (
        <TablePage<ReportBillModel>
            stateE={{
                filter: [pair('dateStart', g_treatDate(startMonth)), pair('dateEnd', g_treatDate(endMonth))],
                rangeDates: {
                    date: [startMonth, endMonth],
                },
                search: true,
            }}
            rg={[pair('dateContractYear', 'desc'), pair('dateContractMonth', 'desc')]}
            getData={c => g_getData(c, S.contract.report.bill, 'billInfos')}
            table={() => [
                { t: 'd', data: 'consignatorName' },
                { t: 'd', data: 'contractTemplateTitle', title: ls('productName'), filter: true, sortable: true },
                { t: 'd', data: 'dateContractMonth', sortable: true },
                { t: 'd', data: 'dateContractYear', sortable: true },
                { t: 'd', data: 'contractInstallmentCount' },
                { t: 'd', data: 'contractInstallmentValue' },

                { t: 'spacer', space: '7' },
                { t: 'date', data: 'date', onlyFilter: true, filter: true },
            ]}
            header={null}
            ad={{ enabled: true, noPaging: true, id: 'search' }}
        />
    );
};
