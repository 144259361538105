import { Messages } from 'primereact/messages';
import React, { useCallback, useEffect, useRef } from 'react';
import { config } from '../../../config/config';
import { MFile, ProviderModel, UserModel, UserType } from '../../../types';
import GPhotoUpload from '../../file/G_photoFileUpload/PhotoUpload';
import { Button, checkLoginACL, noDataMessage, pair, StringEmpty } from '../../Generic';
import { Form, GArea, GDiv, GDropdown, GE, GMask, GMessages, GState, GView, useDataObject } from '../../generic/Form';
import { g_convertList, g_displayMessageError, g_openWindowDialog, lm, ls, S } from '../../GenericFunctions';
import { chatModes, schedulingModes } from '../../provider/lists';
import { states as ProviderStates } from '../../provider/Provider';
import User from './User';

let ltolltip = (s: string) => ls(s, 'tooltip');
export const LUser: React.FC = () => {
    let { error, loggeduser } = checkLoginACL({ checkPageAcl: false });
    let { obj: user, setObj: setUser, setObjState: setUserState } = useDataObject<UserModel>({} as any);
    let { obj: provider, setObj: setProvider, setObjState: setProviderState } = useDataObject<ProviderModel>({} as any);

    let messagesRef: React.MutableRefObject<Messages | null> = useRef(null);

    let getData = useCallback(async () => {
        //If there is an error or there are more than one or 0 providers do nothing
        if (!loggeduser || loggeduser!.providerList.length !== 1 || error) return;
        //get user
        let rUser = await S.user.list({ userType: UserType.BO, filters: [pair('iduuid', loggeduser.uuid)] });
        if (g_displayMessageError(messagesRef.current, rUser)) return;
        if (rUser.users.length === 0) {
            noDataMessage(messagesRef.current);
            return;
        }
        setUserState({ ...rUser.users[0] });

        try {
            let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
            if (loggedUserN.uuid === rUser.users[0].idUUID && loggedUserN.photo !== rUser.users[0].photo) {
                loggedUserN.photo = rUser.users[0].photo;
                localStorage.setItem('loggedUserN', JSON.stringify(loggedUserN));
                window.location.reload();
            }
        } catch (_) { }

        //get protetor;
        let rProvider = await S.provider.list({ filters: [pair('idUUID', loggeduser.providerList[0])] });
        if (g_displayMessageError(messagesRef.current, rProvider)) return;
        if (rProvider.providers.length === 0) {
            noDataMessage(messagesRef.current);
            return;
        }
        setProviderState({ ...rProvider.providers[0] });
    }, [loggeduser, error, setProviderState, setUserState]);

    let upload = async (data: { file: MFile; documentTypeId: string }) => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (!user.idUUID || !provider.idUUID) return;
        if (messagesRef.current) messagesRef.current.show({ severity: 'success', summary: ls('simpleUploadStart', 'messages') });

        //Update the provider photo
        var response = await S.provider.documentUpload(formData, 1, provider.idUUID);
        if (g_displayMessageError(messagesRef.current, response)) return;

        //Update use photo
        response = await S.user.documentUpload(formData, '1', user.idUUID);
        if (g_displayMessageError(messagesRef.current, response)) return;

        getData();
    };

    let submit = async () => {
        if (!user.idUUID || !provider.idUUID) return;
        let userData: { user: UserModel } = {
            user: {
                ...user,
                fullname: provider.fantasyName,
                phoneMobile: provider.phoneMobile,
            },
        };
        //Update user
        let rUser = await S.user.update(userData);
        if (g_displayMessageError(messagesRef.current, rUser)) return;

        let rProvider = await S.provider.update({ provider });
        if (g_displayMessageError(messagesRef.current, rProvider)) return;

        if (messagesRef.current) messagesRef.current.show({ severity: 'success', summary: lm('simpleSave') });

        getData();
    };

    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If error then exit here
    if (error) return error;
    // If there none or more than 1 providers use the default user config
    if (loggeduser!.providerList.length !== 1) return <User idUUID={JSON.parse(localStorage.getItem('loggedUserN') ?? '').uuid} mode LUser checkACL={false} />;
    return (
        <>
            <div className="card">
                <Form
                    obj={provider}
                    setObj={setProvider}
                    onSubmit={e => {
                        e.preventDefault();
                        submit();
                    }}
                    fclass="p-col-3"
                    lclass="p-col-2"
                >
                    <GMessages refM={messagesRef} />
                    <GDiv grid class="p-col-12">
                        <GDiv class="p-col">
                            <GDiv class="p-col-12" extra={{ setObj: setUser, obj: user }}>
                                <GE d="username" disabled fClass="p-col-7" />
                            </GDiv>
                            <GDiv class="p-col-12">
                                <GE d="fantasyName" l="fullname" disabled fClass="p-col-8" />
                                <GMask d="vatNumber" mask="vatNumberC" disabled />
                                <GE d="shortDescription" disabled fClass="p-col-8" />
                                <GArea d="longDescription" lClass="p-col" fClass="p-col-12" disabled />
                                <GMask d="phoneMobile" mask="phoneMobile" />
                                <GE d="email" req fClass="p-col-8" />
                                <GE d="erpCode" req />
                                <GDiv class="" grid group>
                                    <GDropdown d="chatMode" options={g_convertList(chatModes)} fClass="p-col" />
                                    <GDropdown d="schedulingMode" options={g_convertList(schedulingModes)} fClass="p-col" />
                                </GDiv>
                            </GDiv>
                        </GDiv>
                        <GDiv class="p-col-3" style={{ borderLeft: '1px solid lightgrey' }} extra={{ lclass: 'p-col-5', fclass: 'p-col' }} hidden={StringEmpty(user.idUUID)}>
                            <GDiv class="" extra={{ obj: user, setObj: setUser }}>
                                <GState
                                    d="userStatus"
                                    custom={{
                                        1: { color: 'green', class: 'pi-check', tooltip: ltolltip('active') },
                                        2: { color: 'red', class: 'pi-times', tooltip: ltolltip('disabled') },
                                        3: { color: 'red', class: 'pi-ban', tooltip: ltolltip('blocked') },
                                    }}
                                />
                            </GDiv>
                            <GState d="providerStatus" l={ls('providerStatus', 'luser')} custom={ProviderStates} />
                            <GView d="dateUpdate" />
                            <GView d="dateCreation" />
                            <GDiv class="" extra={{ obj: user, setObj: setUser }}>
                                <GView d="dateLastLogin" />
                            </GDiv>
                        </GDiv>
                        <GDiv grid class="p-col-2" hidden={StringEmpty(user.idUUID) || StringEmpty(provider.idUUID)}>
                            <GPhotoUpload
                                style={{ width: '105px', height: '105px' }}
                                handlePhotoUpload={upload}
                                photo={provider.photo}
                                documentTypeId={'1'}
                                prefixPath={config.PHOTO_URL}
                                id={provider.idUUID}
                            />
                        </GDiv>
                    </GDiv>
                    {/* Btts */}
                    <GDiv grid class="p-col-12">
                        <Button label="save" />
                        <Button
                            type="button"
                            label="changePassword"
                            wraperClass="p-col-2"
                            onClick={e => {
                                e.preventDefault();
                                g_openWindowDialog('#/change-password?type=1', 'changepassword', 420, 220);
                            }}
                        />
                    </GDiv>
                </Form>
            </div>
        </>
    );
};
