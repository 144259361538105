import axios from './AxiosService';

class AssetService {

    add = data => axios.request('POST', '/asset/add', data) 
    list = data => axios.request('POST', '/asset/list', data) 
    update = data => axios.request('PUT', '/asset/update', data) 
    updateStatus = data => axios.request('PUT', '/asset/update/status', data) 
    types = data => axios.request('POST', '/asset/assetTypes', data) 
    catagories = data => axios.request('POST', '/asset/assetCategories', data) 

}

export default new AssetService();
