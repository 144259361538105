import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ConfigService from '../../service/ConfigService';
import {
    LS,
    pair,
    g_checkLoginACL,
    g_checkMode,
    g_displayMessageError,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_inputArea,
    g_inputAreaTemplate,
    g_inputTemplate,
    g_getWraper,
    g_dropdownTemplate,
    g_buttonTemplate,
    g_dialogTemplate,
    lm,
    lt,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class ConfigContext extends Component {
    constructor() {
        super();
        this.state = {
            items: [{ label: LS('systemVariable', 'tab') }],
            id: null,
            context: null,
            data: {
                configType: 0,
                context: '',
                dateCreation: '',
                dataUpdate: '',
                id: '',
                tag: '',
                value: '',
                idUUID: '',
            },
            configTypes: [
                { label: LS('0', 'configTypes'), value: `0` },
                { label: LS('1', 'configTypes'), value: `1` },
                { label: LS('2', 'configTypes'), value: `2` },
                { label: LS('3', 'configTypes'), value: `3` },
            ],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let context = query.get('context');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, context: context, mode: mode }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID || !this.state.context) return;
        let data = {
            filters: [pair(`context`, this.state.context), pair(`idUUID`, this.state.idUUID)],
            pageSize: 1,
            pageNumber: 1,
        };
        let response = await ConfigService.getConfigList(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.configs[0];
        if (rdata.configType === 1 || rdata.configType === 2) rdata.value = '';
        rdata.configType = String(rdata.configType);
        this.setState({
            data: rdata,
            items: [{ label: LS('systemVariable', 'tab') }, { label: lt('log'), icon: 'pi pi-list' }],
        });
    };

    onSubmit = async e => {
        e.preventDefault();
        if (!this.state.context) return;
        if (this.state.idUUID) {
            let data = { config: this.state.data };
            let response = await ConfigService.update(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: lm('simpleSave', 'messages') });
            this.getData();
        } else {
            let data = this.state.data;
            data.context = this.state.context;
            let response = await ConfigService.add({ config: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: lm('simpleCreate') });
            this.setState({ id: response.config.id }, this.getData);
        }
    };

    editorChange = e => {
        let data = this.state.data;
        data.value = e.htmlValue;
        this.setState({ data: data });
    };

    deleteE = async e => {
        e.preventDefault();
        if (!this.state.idUUID) return;
        let response = await ConfigService.deleteConfig(this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        window.close();
    };

    render() {
        return g_checkLoginACL(e =>
            g_checkMode(this, e, this.state.idUUID, e => {
                let editor = null;
                if (this.state.data.configType === `0` || this.state.data.configType === `1` || this.state.data.configType === `2`)
                    editor = g_inputAreaTemplate(`value`, this.state.data.value, LS('value'), this, true, this.state.mode, false, g_handleChange, {}, '', `p-col-12`, `p-col-3`, {
                        minHeight: '20em',
                    });
                else if (this.state.data.configType === `3`)
                    editor = g_getWraper(
                        `value`,
                        LS('value'),
                        <Editor
                            className="p-col-12"
                            style={{ height: '20em' }}
                            disabled={this.state.mode}
                            required={true}
                            value={this.state.data.value}
                            onTextChange={e => this.editorChange(e)}
                            id="value"
                        />,
                        false,
                        `p-col-2`
                    );
                return (
                    <div>
                        {g_tabViewRenderOptions(
                            this,
                            [
                                <div className="card">
                                    <form className="p-col" onSubmit={this.onSubmit}>
                                        <div className="p-grid p-fuild">
                                            <div style={{ maxHeight: '83vh', overflowY: 'auto' }} className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                                {g_inputTemplate(
                                                    `context`,
                                                    this.state.data.context ? this.state.data.context : this.state.context,
                                                    LS('context'),
                                                    this,
                                                    true,
                                                    true,
                                                    false,
                                                    () => {},
                                                    {},
                                                    '',
                                                    `p-col-4`,
                                                    `p-col-2`
                                                )}
                                                {g_inputTemplate(
                                                    `tag`,
                                                    this.state.data.tag,
                                                    LS('tag'),
                                                    this,
                                                    true,
                                                    this.state.mode,
                                                    false,
                                                    g_handleChange,
                                                    {},
                                                    ``,
                                                    `p-col-4`,
                                                    `p-col-2`
                                                )}
                                                {g_dropdownTemplate(
                                                    'configType',
                                                    this.state.data.configType,
                                                    LS('configType'),
                                                    this,
                                                    this.state.configTypes,
                                                    ``,
                                                    true,
                                                    this.state.idUUID && this.state.configType,
                                                    false,
                                                    g_handleChange,
                                                    {},
                                                    'p-col-4',
                                                    `p-col-2`
                                                )}
                                                {editor}
                                                {g_template_inputArea(this, 'help', this.state.data.help, LS('help'), this.state.mode, false, false, 'p-col-12', 'p-col-3', '', {
                                                    minHeight: '5em',
                                                })}
                                            </div>
                                            <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                                <Panel style={{ borderLeft: this.state.zipCode !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                                    {g_getWraper(
                                                        null,
                                                        LS('dateCreation'),
                                                        g_getDateTemplate(this.state.data.dateCreation),
                                                        !(this.state.idUUID && this.state.context),
                                                        'p-col'
                                                    )}
                                                    {g_getWraper(
                                                        null,
                                                        LS('dateUpdate'),
                                                        g_getDateTemplate(this.state.data.dateUpdate),
                                                        !(this.state.idUUID && this.state.context),
                                                        'p-col'
                                                    )}
                                                </Panel>
                                            </div>
                                        </div>
                                        <div className="p-grid p-fluid">
                                            {g_buttonTemplate(
                                                LS(this.state.idUUID && this.state.context ? 'save' : `create`, 'btt'),
                                                ``,
                                                ``,
                                                false,
                                                null,
                                                this.state.mode,
                                                `p-col-2`
                                            )}
                                            {g_dialogTemplate(
                                                LS('askDelte', 'messages'),
                                                'delete',
                                                this,
                                                this.deleteE,
                                                LS('delete', 'btt'),
                                                LS('yes'),
                                                LS('no'),
                                                '',
                                                'p-button-danger',
                                                !this.state.id,
                                                this.state.mode || e.d !== 1,
                                                'p-button-danger',
                                                '',
                                                '',
                                                '',
                                                'auto',
                                                'p-col-2'
                                            )}
                                        </div>
                                    </form>
                                </div>,
                                <Log idUUID={this.state.idUUID} logContextType={14} />,
                            ],
                            null
                        )}
                    </div>
                );
            })
        );
    }
}
