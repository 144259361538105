import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef } from 'react';
import { DRMOZUUID, PROTETORIDUUID } from '../../../config/config';
import { Requests } from '../../../Requests';
import { Button, checkAcl, g_getData, pair, StringEmpty } from '../../Generic';
import { Form, GArea, GDateInput, GDiv, GDropdown, GE, GInput, GNumber, GSwitch, StringIndexed, useDataObject } from '../../generic/Form';
import { g_displayMessageError, ls, S } from '../../GenericFunctions';
import VideoCall from './videocall';

interface endCallBttProps {
    isTriage: boolean;
    endCall: () => void;
    endCallNoExit: () => void;
    skipCallDialog?: boolean;
    idUUID: string;
}
export const EndCallBtt: React.FC<endCallBttProps> = ({ isTriage, endCall, endCallNoExit, skipCallDialog, idUUID }) => {
    let { obj, setObj, setObjState } = useDataObject<{
        visible: boolean;
        selected: string;
        msg: string;
    }>({
        visible: false,
        selected: '0',
        msg: '',
    });
    return (
        <>
            <Button
                label="exit"
                onClick={e => {
                    e.preventDefault();
                    if (skipCallDialog) {
                        endCallNoExit();
                        return;
                    } else setObjState({ ...obj, visible: true });
                }}
                style={{ fontSize: '1.5em' }}
                icon="pi pi-sign-out"
                wraperClass="p-col-6"
                bttClass="p-button-danger"
            />
            <Dialog contentStyle={{ minHeight: '20vh' } as React.CSSProperties} visible={obj.visible} onHide={() => setObjState({ ...obj, visible: false })}>
                <Form
                    onSubmit={async e => {
                        e.preventDefault();
                        //let _ =
                        await Requests.contract.status({
                            contractStatus: obj.selected === '0' ? 100 : 2,
                            contractStatusMessage: {
                                '0': ls(isTriage ? 'endOptionsTrigage' : 'endOptionsDoctor', 'call'),
                                '1': ls('endOptionsAudioVideoError', 'call'),
                                '2': ls('endOptionsConnFailPay', 'call'),
                                '3': ls('endOptionsConnFail', 'call'),
                                '4': ls('endOptionsOther', 'call'),
                            }[obj.selected],
                            extraLogEntry: obj.msg,
                            idUUID: idUUID,
                        });
                        endCall();
                    }}
                    obj={obj}
                    setObj={setObj}
                >
                    <GDiv grid class="p-col-12">
                        <GDiv class="p-col-12">{ls(isTriage ? 'triageEndMessage' : 'doctorEndMessage', 'call')}</GDiv>
                        <GDropdown
                            l=""
                            d="selected"
                            fClass="p-col-12"
                            options={[
                                { label: ls(isTriage ? 'endOptionsTrigage' : 'endOptionsDoctor', 'call'), value: '0' },
                                { label: ls('endOptionsAudioVideoError', 'call'), value: '1' },
                                { label: ls('endOptionsConnFailPay', 'call'), value: '2' },
                                { label: ls('endOptionsConnFail', 'call'), value: '3' },
                                { label: ls('endOptionsOther', 'call'), value: '4' },
                            ]}
                        />
                        <GDiv class="p-col-12" hidden={obj.selected === '0'}>
                            {ls('description')}
                        </GDiv>
                        <GArea l="" d="msg" h={obj.selected === '0'} fClass="p-col-12" />
                        <GDiv class="p-col-12" grid>
                            <Button label="endAndExit" wraperClass="p-col-6" bttClass="p-button-danger" />
                            <Button
                                label="endCall"
                                onClick={async e => {
                                    e.preventDefault();
                                    await Requests.contract.status({
                                        contractStatus: obj.selected === '0' ? 100 : 2,
                                        extraLogEntry:
                                            {
                                                '0': ls(isTriage ? 'endOptionsTrigage' : 'endOptionsDoctor', 'call'),
                                                '1': ls('endOptionsAudioVideoError', 'call'),
                                                '2': ls('endOptionsConnFailPay', 'call'),
                                                '3': ls('endOptionsConnFail', 'call'),
                                                '4': ls('endOptionsOther', 'call'),
                                            }[obj.selected] + obj.msg,
                                        idUUID: idUUID,
                                    });
                                    endCallNoExit();
                                    setObjState({ ...obj, visible: false });
                                }}
                                wraperClass="p-col-6"
                            />
                            <Button hidden={!isTriage} label="transfer" wraperClass="p-col-6" />
                        </GDiv>
                    </GDiv>
                </Form>
            </Dialog>
        </>
    );
};

export const videoCallgetData = (c: VideoCall) => {
    g_getData(
        //TODO: remove as any
        c as any,
        //TODO: remove as any
        () => S.contract.get((c as any).state.idUUID, true),
        responseC => {
            if (responseC.contract) {
                c.setState({ contractOBJ: responseC.contract, data: { ...c.state.data, note: responseC.contract.obs }, updateNote: responseC.contract.obs });
                g_getData(
                    //TODO: remove as any
                    c as any,
                    () => S.consignee.get(responseC.contract.consigneeIdUUID, 1, 1, false),
                    async response => {
                        if (response?.consignee) {
                            //Get Age
                            let d2 = new Date(response.consignee.dateBirth);
                            let d3 = new Date(new Date().getTime() - d2.getTime());
                            //TODO: check why
                            let age = d3.getUTCFullYear() - 1970;

                            c.setState(
                                {
                                    consignee: {
                                        photo: response.consignee.photo,
                                        dateBirth: `${d2.getDate()}-${d2.getMonth() + 1}-${d2.getFullYear()}`,
                                        fullname: response.consignee.fullname,
                                        age: age,
                                        gender: String(response.consignee.gender),
                                        consigneeObj: response.consignee,
                                    },
                                } /*this.calllist.getData*/
                            );

                            let consignee = response.consignee;

                            if (!localStorage.getItem('BASEMEDICALFORM')) return;

                            //Get sidebar form

                            let idUUID = JSON.parse(localStorage.getItem('BASEMEDICALFORM') ?? '').idUUID;

                            let rSideBarContract = await S.contract.list(
                                {
                                    filters: [pair('contractTemplateIdUUID', idUUID), pair('consigneeIdUUID', consignee.idUUID)],
                                    orderBy: [pair('dateCreation', 'desc')],
                                },
                                true
                            );

                            if (g_displayMessageError(null, rSideBarContract)) return;

                            //If the contract does not exist then create a new one
                            if (rSideBarContract.contracts.length === 0) {
                                let response1 = await S.contract.add(
                                    {
                                        contract: {
                                            contractTemplateIdUUID: idUUID,
                                            consigneeIdUUID: consignee.idUUID,
                                            consignatorIdUUID: process.env.REACT_APP_PORT === '4100' ? DRMOZUUID : PROTETORIDUUID,
                                        },
                                    },
                                    true
                                );

                                if (g_displayMessageError(null, response1)) return;
                                let mapped: StringIndexed = {};

                                if (response1.contract.contractComplementaryFields)
                                    response1.contract.contractComplementaryFields.forEach(e => (mapped[e.fieldName] = e.fieldValue));

                                // load the side bar
                                //TODO: remove as any
                                (c as any).templateGenerator.setState({ data: mapped, contractIdUUID: response1.contract.idUUID });
                            } else {
                                // get data from contract and load the side bar
                                let idUUID = rSideBarContract.contracts[0].idUUID;
                                let response2 = await S.contract.get(idUUID, true);
                                if (g_displayMessageError(null, response2)) return;
                                let mapped: StringIndexed = {};
                                console.log(response2);
                                if (response2.contract.contractComplementaryFields)
                                    response2.contract.contractComplementaryFields.forEach(e => (mapped[e.fieldName] = e.fieldValue));
                                //TODO: remove as any
                                try {
                                    (c as any).templateGenerator.setState({ contractIdUUID: idUUID, data: mapped });
                                } catch (_) {}
                            }
                        }
                    }
                );
                //Get contract template list
                g_getData(
                    //TODO: remove as any
                    c as any,
                    () => S.contract.template.form.list({ filters: [pair('contractTemplateIdUUID', responseC.contract.contractTemplateIdUUID)] }),
                    r => {
                        //get config page

                        let configPage = r.contractTemplateForms.filter(a => a.page === -1);
                        let configs: {
                            triagePage?: number;
                            triageAcl?: string;
                            gotTriageAcl?: boolean;
                            triage: boolean;
                            record: boolean;
                        } = { triage: false, record: false };

                        let showTriage = true;

                        if (configPage.length !== 0) {
                            //Got configpage
                            let cP = configPage[0];
                            cP.contractFields.forEach(a => {
                                if (a.fieldName === 'triagePage') {
                                    let parsedN = Number(a.title);
                                    if (isNaN(parsedN)) return;
                                    configs.triagePage = parsedN;
                                } else if (a.fieldName === 'triageAcl') {
                                    configs.triageAcl = a.title;
                                } else if (a.fieldName === 'videoRecording' && Number(a.title) === 1) {
                                    configs.record = true;
                                }
                            });

                            //If triageAcl e triagePage is set enable triage and check for triageacl
                            if (configs.triageAcl !== undefined && configs.triagePage !== undefined) {
                                configs.triage = true;
                                configs.gotTriageAcl = checkAcl(configs.triageAcl, 5) !== undefined;
                                try {
                                    //Get all the filed names that are used by the triage
                                    let triageFields = r.contractTemplateForms.filter(a => a.page === configs.triagePage)[0].contractFields.map(a => a.fieldName);
                                    (responseC.contract?.contractComplementaryFields ?? []).forEach(a => {
                                        if (!StringEmpty(a.fieldValue) && triageFields.includes(a.fieldName)) {
                                            triageFields.splice(triageFields.indexOf(a.fieldName), 1);
                                        }
                                    });
                                    showTriage = triageFields.length === 0;
                                } catch (_) {}
                            }
                        }

                        let usablePages = r.contractTemplateForms.filter(a => {
                            //Igonre config page
                            if (a.page === -1) return false;

                            //If triage acl is set then only show this page
                            if (configs.triage && configs.gotTriageAcl) {
                                return a.page === configs.triagePage;
                            } else if (!showTriage && a.page === configs.triagePage) {
                                return false;
                            }

                            return true;
                        });

                        let pages = usablePages
                            .filter(a => a.page !== 0 && a.contractTemplateFormStatus === 1)
                            .sort((a, b) => a.page - b.page)
                            .map(a => ({ ...a, uti: a.title === 'UTI' }));
                        let maped: StringIndexed = {};
                        let mapedArray = usablePages
                            .filter(a => a.page !== 9 && a.contractTemplateFormStatus === 1)
                            .map(a => {
                                let ma: StringIndexed[] = a.contractFields.map(a => ({ [a.fieldName]: '' }));
                                let m: StringIndexed = {};

                                if (ma.length === 0) m = {};
                                else m = ma.reduce((a, e) => ({ ...a, ...e }));

                                return { page: a.page, c: m };
                            });

                        if (mapedArray.length !== 0) maped = mapedArray.map(a => ({ [String(a.page)]: a.c })).reduce((a, e) => ({ ...a, ...e }));

                        (responseC.contract.contractComplementaryFields ?? []).forEach(a => {
                            switch (a.fieldName) {
                                case 'anamnese':
                                    c.setState({ anamnesis: a.fieldValue });
                                    break;
                                case 'problem_list':
                                    c.setState({ problems: a.fieldValue });
                                    break;
                                case 'diagnostic':
                                    c.setState({ conclusion: a.fieldValue });
                                    break;
                                default:
                                    Object.keys(maped).forEach(b => {
                                        if (Object.keys(maped[b]).indexOf(a.fieldName) !== -1) {
                                            maped[b][a.fieldName] = a.fieldValue;
                                        }
                                    });
                                    break;
                            }
                        });
                        c.templateGeneratorsSaved = maped;
                        //TODO: remove as any
                        (c.configs as any) = configs;
                        c.setState({ tab2Data: pages, clear: true });
                    }
                );
            }
        }
    );
};

export interface UTIProps {
    value: string;
    //changeValue: (s: string) => void;
    changeValue: () => void;
    close: () => void;
    save: () => void | Promise<void>;
    disable: boolean;
    contractIdUUID: string;
}
export interface UTIRef {
    obj: any; //TODO: Change
}

export enum ynna {
    y = 1,
    n = 0,
    na = 2,
}
const ynnaOptions = [
    { label: ls('yes'), value: String(ynna.y) },
    { label: ls('no'), value: String(ynna.n) },
    { label: 'NA', value: String(ynna.na) },
];

enum nb {
    true = 1,
    false = 0,
}
const ynOptions = [
    { label: ls('yes'), value: String(nb.true) },
    { label: ls('no'), value: String(nb.false) },
];

enum re22 {
    PCV = 1,
    VCV = 2,
    PSV = 3,
    PCBIPAP = 4,
}

const re22Op = [
    { label: 'PCV', value: String(re22.PCV) },
    { label: 'VCV', value: String(re22.VCV) },
    { label: 'PSV', value: String(re22.PSV) },
    { label: 'PCBIPAP', value: String(re22.PCBIPAP) },
];

enum Ren12 {
    SLED = 1,
    CVVHDF = 2,
    Convencional = 3,
}

const Ren12OP = [
    { label: 'SLED', value: String(Ren12.SLED) },
    { label: 'CVVHDF', value: String(Ren12.CVVHDF) },
    { label: 'Convencional', value: String(Ren12.Convencional) },
];

enum HI22 {
    n = 0,
    t = 1,
    p = 2,
}

const HI22OP = [
    { label: 'Terapêutico', value: String(HI22.t) },
    { label: 'Profilático', value: String(HI22.p) },
    { label: 'Não', value: String(HI22.n) },
];

interface UTIOBJNe {
    sedation_status: ynna;
    sedation_midazolam: nb;
    sedation_propofol: nb;
    sedation_precedex: nb;
    sedation_other: string;
    sedation_rass: string;
    sedation_ecg: string;

    analegisia_status: ynna;
    analegisia_morfina: nb;
    analegisia_fentanil: nb;
    analegisia_PCA: nb;
    analegisia_other: string;
    analegisia_SA: ynna;

    delirium_status: ynna;
    delirium_seroquel: nb;
    delirium_rispidona: nb;
    delirium_cm: nb;
}

export interface UTIObj extends UTIOBJNe {
    dva_1: nb;
    dva_2: nb;
    dva_3: nb;
    dva_4: nb;
    dva_5: string;

    ca22: number;
    ca23: number;
    ca24: number;

    re11: nb;
    re121: nb;
    re122: number;
    re131: nb;
    re132: number;
    re133: number;
    re141: nb;
    re142: number;
    re143: number;
    re21: nb;
    re22: re22;
    re231: number;
    re232: number;
    re233: number;
    re24: ynna;
    re3: nb;

    TG21: string;
    D31: string;
    D32: string;
    D33: string;
    D34: string;
    D35: string;
    D36: string;
    HI24: string;

    sign: boolean;
    url: string;
}

export const UTI = React.forwardRef<UTIRef, UTIProps>((props, ref) => {
    let { obj, setObj, setObjState } = useDataObject<UTIObj>({
        /*TG21: g_treatDate(new Date(), true, false)*/
    } as any); //UTIObj);

    let ftime: React.MutableRefObject<boolean> = useRef(true);

    if (ref) {
        if (typeof ref === 'function') {
            ref({ obj });
        } else {
            ref.current = { obj };
        }
    }

    useEffect(() => {
        if (ftime.current) {
            try {
                setObjState(JSON.parse(props.value));
            } catch (_) {}
            ftime.current = false;
        }
        return () => {
            if (props.changeValue) props.changeValue();
            if (props.close) props.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(obj.TG21);

    return (
        <Form obj={obj} setObj={setObj} disabled={props.disable}>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>NEUROLÓGICO</h1>
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="sedation_status" fClass="p-col-4" options={ynnaOptions} />
                    <GSwitch d="sedation_midazolam" />
                    <GSwitch d="sedation_propofol" />
                    <GSwitch d="sedation_precedex" l="Precedex" />
                    <GInput d="sedation_other" />
                    <GDiv class="p-col-12" group>
                        <GInput d="sedation_rass" lClass="p-col-2" fClass="p-col-4" />
                        <GInput d="sedation_ecg" lClass="p-col-2" fClass="p-col-4" />
                    </GDiv>
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="analegisia_status" fClass="p-col-4" options={ynnaOptions} />
                    <GSwitch d="analegisia_morfina" />
                    <GSwitch d="analegisia_fentanil" l="Fentanil" />
                    <GSwitch d="analegisia_pca" l="PCA" />
                    <GInput d="analegisia_other" />
                    <GDropdown d="analegisia_SA" fClass="p-col-4" options={ynOptions} />
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="delirium_status" fClass="p-col-4" options={ynnaOptions} />
                    <GSwitch d="delirium_seroquel" />
                    <GSwitch d="delirium_rispidona" />
                    <GDropdown d="delirium_cm" fClass="p-col-4" options={ynOptions} />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>CARDIOVASCULAR</h1>
                </GDiv>
                <GDiv class="p-col-8">
                    <GDiv group>
                        <GDropdown d="dva_1" fClass="p-col-4" options={ynOptions} />
                        <div>Dose</div>
                    </GDiv>
                    <GDiv group>
                        <GSwitch d="dva_2" fClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="ca22" l="" fClass="p-col-7" suffix=" mcg/kg/min" suffixOutside />
                    </GDiv>
                    <GDiv group>
                        <GSwitch d="dva_3" fClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="ca23" l="" fClass="p-col-7" suffix=" mcg/kg/min" suffixOutside />
                    </GDiv>
                    <GDiv group>
                        <GSwitch d="dva_4" fClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="ca24" l="" fClass="p-col-7" suffix=" UI/min" suffixOutside />
                    </GDiv>
                    <GInput d="dva_5" />
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="ca31" fClass="p-col-4" options={ynnaOptions} />
                    <GNumber minFractionDigits={2} d="ca32" fClass="p-col-8" suffix=" mmHg" suffixOutside />
                    <GInput d="ca33" />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>RESPIRATÓRIO</h1>
                </GDiv>
                <GDiv class="p-col-6">
                    <GDropdown d="re11" fClass="p-col-2" options={ynOptions} />
                    <GDiv group>
                        <GSwitch d="re121" lClass="p-col-2" fClass="p-col-1" />
                        <GNumber d="re122" l="" fClass="p-col-2" suffixOutside minFractionDigits={2} suffix="L/min" />
                    </GDiv>
                    <GDiv group>
                        <GSwitch d="re131" lClass="p-col-2" fClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="re132" l="" fClass="p-col-2" suffixOutside suffix="%" />
                        <GNumber minFractionDigits={2} d="re133" l="" fClass="p-col-2" suffixOutside suffix="L/min" />
                    </GDiv>
                    <GDiv group>
                        <GSwitch d="re141" lClass="p-col-2" fClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="re142" l="" fClass="p-col-2" suffixOutside suffix="%" />
                        <GNumber minFractionDigits={2} d="re143" l="" fClass="p-col-2" suffixOutside suffix="EPAP" />
                        <div className="p-col-1" />
                        <GNumber minFractionDigits={2} d="re144" l="" fClass="p-col-2" suffixOutside suffix="IPAP" />
                    </GDiv>
                </GDiv>
                <GDiv class="p-col-6">
                    <GDropdown d="re21" fClass="p-col-2" options={ynOptions} />
                    <GDropdown d="re22" options={re22Op} />
                    <GDiv group>
                        <GNumber minFractionDigits={2} d="re231" lClass="p-col-1" suffixOutside suffix="%" />
                        <GNumber minFractionDigits={2} d="re232" lClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="re233" lClass="p-col-1" />
                    </GDiv>
                    <GDropdown d="re24" fClass="p-col-2" options={ynnaOptions} />
                </GDiv>
                <GDiv class="p-col-12">
                    <GDropdown d="re3" l="Mobilizar fora do leito?" fClass="p-col-1" options={ynOptions} />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>TRATO GASTROINTESTINAL</h1>
                </GDiv>
                <GDiv class="p-col-12">
                    <GDiv group>
                        <GSwitch d="TG11" l="Dieta: Oral" fClass="p-col-1" lClass="p-col-1" />
                        <GSwitch d="TG12" l="Enteral" fClass="p-col-1" lClass="p-col-1" />
                        <GSwitch d="TG13" l="Parenteral" fClass="p-col-1" lClass="p-col-1" />
                        <GSwitch d="TG14" l="Jejum" fClass="p-col-1" lClass="p-col-1" />
                    </GDiv>
                </GDiv>
                <GDiv class="p-col">
                    <GDateInput d="TG21" value={obj.TG21 ? new Date(obj.TG21) : new Date()} showTime l="Data da Última evacuação:" fClass="p-col-3" extra={{ dateZ: true }} />
                    <GDropdown d="TG22" fClass="p-col-2" l="Controle Glicemico adequado?" options={ynOptions} />
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="TG31" fClass="p-col-2" l="Dieta na Meta?" options={ynOptions} />
                    <GDropdown d="TG32" fClass="p-col-2" l="Possibilidade de progressão de dieta?" options={ynOptions} />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>RENAL</h1>
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="ren11" l="TRS?" fClass="p-col-2" options={ynOptions} />
                    <GDropdown d="ren12" l="Modo:" fClass="p-col-4" options={Ren12OP} />
                </GDiv>
                <GDiv class="p-col">
                    <GDiv group>
                        <GInput d="ren211" l="Diurese 24h" fClass="p-col-2" lClass="p-col-2" />
                        <GInput d="ren212" l="Outras Perdas" fClass="p-col-2" lClass="p-col-2" />
                        <GInput d="ren213" l="BH 24h" fClass="p-col-2" lClass="p-col-2" />
                    </GDiv>
                    <GDiv group>
                        <GInput d="ren221" l="Ur" fClass="p-col-2" lClass="p-col-2" />
                        <GInput d="ren222" l="Cr" fClass="p-col-2" lClass="p-col-2" />
                    </GDiv>
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>HEMATOLÓGICO / INFECCIOSO</h1>
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="HI11" l="Hb Estável ?" fClass="p-col-2" options={ynOptions} />
                    <GDropdown d="HI12" l="Coagulopatia?" fClass="p-col-2" options={ynOptions} />
                    <GDiv group>
                        <GNumber d="HI131" l="Plaquetas" lClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="HI132" l="TTPA" lClass="p-col-1" />
                        <GNumber minFractionDigits={2} d="HI133" l="INR" lClass="p-col-1" />
                        <GNumber d="HI134" l="FIB" lClass="p-col-1" />
                    </GDiv>
                    <GDropdown d="HI14" l="Anticoagulação Plena?" fClass="p-col-2" options={ynOptions} />
                    <GSwitch d="HI15" l="HNF BIC " />
                    <GSwitch d="HI16" l="Clexane" />
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="HI21" l="Febre?" fClass="p-col-2" options={ynOptions} />
                    <GDropdown d="HI22" l="Antibiótico?" fClass="p-col-3" options={HI22OP} />
                    <GInput d="HI23" l="Qual" />
                    <GDateInput d="HI24" value={obj.HI24 ? new Date(obj.HI24) : new Date()} l="Dia de inicio" fClass="p-col-3" extra={{ dateZ: true }} />
                    <GInput d="HI25" l="Culturas" />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>DISPOSITIVOS</h1>
                </GDiv>
                <GDiv class="p-col">
                    <div>
                        <h1>Tipo</h1>
                    </div>
                    <GDiv style={{ paddingBottom: '0.05em' }}>
                        <GSwitch d="D11" l="CVC" />
                    </GDiv>
                    <GDiv style={{ paddingBottom: '0.05em' }}>
                        <GSwitch d="D12" l="PAI" />
                    </GDiv>
                    <GDiv style={{ paddingBottom: '0.05em' }}>
                        <GSwitch d="D13" l="CHD" />
                    </GDiv>
                    <GDiv style={{ paddingBottom: '0.05em' }}>
                        <GSwitch d="D14" l="SVD" />
                    </GDiv>
                    <GDiv style={{ paddingBottom: '0.05em' }}>
                        <GSwitch d="D15" l="Dreno" />
                    </GDiv>
                    <GDiv style={{ paddingBottom: '0.05em' }}>
                        <GSwitch d="D16" l="Outros" />
                    </GDiv>
                </GDiv>
                <GDiv class="p-col">
                    <div>
                        <h1>Sítio</h1>
                    </div>
                    <GInput d="D21" l="" />
                    <GInput d="D22" l="" />
                    <GInput d="D23" l="" />
                    <GInput d="D24" l="" />
                    <GInput d="D25" l="" />
                    <GInput d="D26" l="" />
                </GDiv>
                <GDiv class="p-col">
                    <div>
                        <h1>Data</h1>
                    </div>
                    <GDateInput extra={{ dateZ: true }} value={obj.D31 ? new Date(obj.D31) : new Date()} d="D31" l="" />
                    <GDateInput extra={{ dateZ: true }} value={obj.D32 ? new Date(obj.D32) : new Date()} d="D32" l="" />
                    <GDateInput extra={{ dateZ: true }} value={obj.D33 ? new Date(obj.D33) : new Date()} d="D33" l="" />
                    <GDateInput extra={{ dateZ: true }} value={obj.D34 ? new Date(obj.D34) : new Date()} d="D34" l="" />
                    <GDateInput extra={{ dateZ: true }} value={obj.D35 ? new Date(obj.D35) : new Date()} d="D35" l="" />
                    <GDateInput extra={{ dateZ: true }} value={obj.D36 ? new Date(obj.D36) : new Date()} d="D36" l="" />
                </GDiv>
                <GDiv class="p-col">
                    <div>
                        <h1>Possibilidade de retirada?</h1>
                    </div>
                    <GDropdown d="D41" l="" fClass="p-col-3" options={ynOptions} />
                    <GDropdown d="D42" l="" fClass="p-col-3" options={ynOptions} />
                    <GDropdown d="D43" l="" fClass="p-col-3" options={ynOptions} />
                    <GDropdown d="D44" l="" fClass="p-col-3" options={ynOptions} />
                    <GDropdown d="D45" l="" fClass="p-col-3" options={ynOptions} />
                    <GDropdown d="D46" l="" fClass="p-col-3" options={ynOptions} />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col-12">
                    <h1>PROFILAXIAS E AVALIAÇÕES</h1>
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="PA11" l="Úlcera de Stress" fClass="p-col-2" options={ynOptions} />
                    <GDropdown d="PA12" l="Mudança de decúbito" fClass="p-col-2" options={ynOptions} />
                    <GDropdown d="PA13" l="TEV" fClass="p-col-2" options={ynnaOptions} />
                    <GDiv group>
                        <GSwitch d="PA141" l="Botas" />
                        <GSwitch d="PA142" l="HPBM" />
                        <GSwitch d="PA143" l="HNF" />
                    </GDiv>
                    <GInput d="PA15" l="Outro" />
                </GDiv>
                <GDiv class="p-col">
                    <GDropdown d="PA21" l="Parecer especialista? " fClass="p-col-2" options={ynOptions} />
                    <GInput d="PA22" l="Qual:" />
                    <GDropdown d="PA23" l="Reconciliação medicamentosa?" fClass="p-col-2" options={ynOptions} />
                </GDiv>
            </GDiv>
            <GDiv grid>
                <GDiv class="p-col">
                    <GArea d="obs" lClass="p-col-12" fClass="p-col-12" />
                </GDiv>
            </GDiv>

            <GDiv grid>
                <OPT
                    hidden={obj.sign}
                    onSubmit={async (otp, messages) => {
                        if (!props.contractIdUUID) return false;

                        props.changeValue();
                        await props.save();

                        let r = await Requests.health.UCI.generate.sign({
                            idUUID: props.contractIdUUID,
                            otp,
                            systemCertifier: '',
                        });

                        if (g_displayMessageError(messages, r)) return false;

                        setObjState({ ...obj, sign: true, url: r.url });

                        return true;
                    }}
                />
            </GDiv>
        </Form>
    );
});

interface OPTProps {
    onClose?: () => void;
    onSubmit: (otp: string, messasges: Messages | null) => boolean | Promise<boolean>;
    hidden?: boolean;
}

export const OPT = ({ onClose, onSubmit, hidden }: OPTProps) => {
    let { obj, setObj, setObjState } = useDataObject<{ visible: boolean; otp: string }>({ visible: false, otp: '' });
    let messages = useRef<Messages | null>(null);
    return (
        <>
            <Button hidden={hidden} label="sign" type="button" onClick={() => setObjState({ ...obj, visible: true })} />
            <Dialog
                style={{ minHeight: '20vh', minWidth: '20vw' }}
                contentStyle={{ minHeight: '20vh', minWidth: '20vw' }}
                visible={obj.visible}
                onHide={() => {
                    setObjState({ ...obj, visible: false });
                    if (onClose) onClose();
                }}
            >
                <Form
                    obj={obj}
                    setObj={setObj}
                    notiRef={e => (messages.current = e)}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (await onSubmit(obj.otp, messages.current)) {
                            setObjState({ ...obj, visible: false, otp: '' });

                            if (onClose) onClose();
                        }
                    }}
                >
                    <GE
                        d="otp"
                        extra={{
                            regex: /\d{0,6}/g,
                        }}
                        req
                    />
                    <Button label="advance" wraperClass="p-col-4 p-sm-8" />
                </Form>
            </Dialog>
        </>
    );
};
