import { GenericListResponseG } from '../../../Generic';
import { GenericListRequest, RequestsClass } from '../../../Requests';
import { UserType } from '../../User';
import { ProfileModel } from '../Profile';

export type Filters = 'profiletype' | 'profilestatus' | 'useriduuid';

export type OrderBy = 'dateCreation';

export const profileList =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy> & {
			idUUID: string;
			userType: UserType;
		}
	): GenericListResponseG<'profiles', ProfileModel> =>
		req.axios.post(`${baseUrl}/profile/list`, data);
