import { GenericListResponseG } from '../../Generic';
import { GenericListRequest, RequestsClass } from '../../Requests';

export type Filters = string;
export type OrderBy = string;

export interface IncidentTypeModel {
	id: number;
	description: string;
	languageTag: string;
	imageIcon: string;
}

export const incidenttype =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidentTypes', IncidentTypeModel> =>
		req.axios.post(`${baseUrl}/types`, data);
