import { GenericListRequest, hasPermissionFunction, permissionFilter, RequestsClass, returnEmptyPromise } from "../../Requests";

export const helperFunctions = (req: RequestsClass) => ({
  template: {
    withConsignators: (
        data: GenericListRequest, 
        consignators: string[],  
        pf: hasPermissionFunction,
      ) => {
        let filters = permissionFilter(data.filters ?? [], pf('contract-management#totalaccess', 5), 'consignatorsIdUUID', 'consignatorsIdUUID', consignators);
        if (!filters) return returnEmptyPromise('contractTemplates');
        data.filters = filters;
        return req.contract.template.list(data);
    }
  }
})
