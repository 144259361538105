import { GenericListResponseG, StringDate, UUID } from '../../Generic';
import { GenericListRequest, RequestsClass } from '../../Requests';

export type Filters = 'incidentIdUUID';

export type OrderBy = 'incidentOrder' | 'dateCreation';

export interface IncidentSLAModel {
	idUUID: UUID;
	incidentIdUUID: UUID;
	slaDuration: number;
	upperInterval: number;
	lowerInterval: number;
	incidentStatus: number;
	incidentStatusDescription: string;
	slaIdUUID: UUID;
	slaDescription: string;
	slaImageIcon: StringDate;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	dateLastEntry: StringDate;
	dateLastExit: StringDate;
	isCurrentState: number;
	incidentOrder: number;
}

export const incidentslalist =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidentSlas', IncidentSLAModel> =>
		req.axios.post(`${baseUrl}/sla-states`, data);
