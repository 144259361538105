import React from 'react';
import ProductService from '../../service/ProductService';
import { pair, ListPage, LabelSelector, g_getData, g_getHeaderTemplate } from '../GenericFunctions';

let url = (iduuid, mode) => `#/product?type=1${iduuid ? '&id=' + iduuid : ''}${mode ? '&mode=' + mode : ''}`;
let size = [1000, 600];

export const productManagement = {
    render: mode => (
        <ListPage
            title={LabelSelector(`product${mode ?? ''}`, 'titles')}
            header={(c, e) => g_getHeaderTemplate(c, url(null, mode ?? ''), 'new product', c.getData, size[0], size[1], e.c)}
            getData={c =>
                g_getData(c, ProductService.getProducts, 'products', {
                    filters: [...c.state.filter, pair('productMode', mode ?? '')],
                })
            }
            update={c => (c.state.mode !== mode ? c.setState({ mode: mode }, c.getData) : null)}
            table={(c, e) => [
                { type: 'd', data: 'name', sortable: true, filter: true },
                { type: 'd', data: 'shortDescription', sortable: true, filter: true },
                { type: 'd', data: 'productTypeDescription', sortable: true, filter: true },
                { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                {
                    type: 'state',
                    data: 'productStatus',
                    states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                },
                { type: 'url', c: e.u === 1 || e.d === 1, title: 'Estados', url: raw => url(raw.idUUID), width: size[0], height: size[1], callback: c.getData },
            ]}
        />
    ),
    url: url,
    size: size,
};
