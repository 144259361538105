import type { Group, GroupElement } from '../..';
import type { GenericListResponseG, GenericResponseG } from '../../Generic';
import type {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../Requests';

export type Filters =
	| 'idUUID'
	| 'notIdUUID'
	| 'name'
	| 'referenceOwnerIdUUID'
	| 'referenceOwnersIdUUID'
	| FilterDateType<'dateCreation'>
	| 'description'
	| 'groupStatus'
	| 'groupType';
export type OrderBy =
	| 'dateCreation'
	| 'idUUID'
	| 'description'
	| 'groupType'
	| 'groupsStatus'
	| 'name';

export const list =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'groups', Group> =>
		req.axios.post(`${baseUrl}/list`, data);

export const elementList =
	(req: RequestsClass, baseUrl: string) =>
	(
		idUUID: string,
		data: GenericListRequest<'referenceName'>
	): GenericListResponseG<'groupElements', GroupElement> =>
		req.axios.post(`${baseUrl}/element/list/${idUUID}`, data);

export const get =
	(req: RequestsClass, baseUrl: string) =>
	(
		idUUID: string,
		loadEntities: 0 | 1 = 1
	): Promise<GenericResponseG<'group', Group>> =>
		req.axios.get(`${baseUrl}/${idUUID}/${loadEntities}`);
