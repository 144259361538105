import { StringIndexed } from '../components/Generic';
import {
    g_ChangeStatus,
    g_checkAlc,
    g_consignators,
    g_entityFilter,
    g_getData,
    g_getDataFields,
    g_getDataHook,
    g_hideSearch,
    g_providers,
    pair,
} from '../components/GenericFunctions';
import { CallRecordModel } from '../components/protetor/calls/CallInfoNew';
import { MedicalCertificateModel, MedicalCertificateTemplateModel, MedicalCertificateTemplateModelCreate } from '../components/protetor/calls/MedicalCertificate';
import { ExamModel, MedicalExamExamCreateModel, MedicalExamExamModel, MedicalExamModel } from '../components/protetor/calls/MedicalExam';
import { MedecineModel, PrescriptionMedicineModel, PrescriptionModel } from '../components/protetor/calls/Prescription';
import { ReportBillModel } from '../components/reports/ReportBill';
import type {
    AddressCreateModel,
    AddressModel,
    CampaignItemCreateModel,
    CampaignItemListModel,
    CampaignItemModel,
    CIDContractModel,
    ConsigneeGet,
    ConsigneeModel,
    ContractGet,
    ContractInstalmentsModel,
    ContractModel,
    ContractModelCreate,
    ContractTemplateFormsModel,
    GenericListResponse,
    GenericQuery,
    GenericResponse,
    getDataContext,
    ProviderModel,
    UserModel,
    UserType,
} from '../types';
import { CallRecordsByProviderModel } from '../types/call';
import axios from './AxiosService';

function backR(back: boolean) {
    return back ? { 'background-request': true } : {};
}

class Services {
    call = {
        list: (data: GenericQuery): Promise<GenericListResponse & { callRecords: CallRecordModel[] }> => axios.request('POST', '/call/list', data),
        get: (idUUID: string): Promise<{ callRecord: CallRecordModel; code: 0; description: 'string' }> => axios.request('GET', `/call/${idUUID}`),
        groupByProvider: (data: GenericQuery): Promise<GenericListResponse & { callRecordsByProviders: CallRecordsByProviderModel[] }> =>
            axios.request('POST', 'call/groupByProvider/list', data),
    };
    health = {
        cid: {
            subcategory: {
                //TODO: remove to any
                list: (data: any, back = true) => axios.request('POST', 'health/cid/subcategory/list', data, back ? { 'background-request': true } : {}),
            },
            contract: {
                //TODO: remove to any
                add: (data: any, back = true) => axios.request('POST', 'health/cid/contract/add', data, back ? { 'background-request': true } : {}),

                list: (data: GenericQuery, back = true): Promise<GenericListResponse & { cidContracts: CIDContractModel[] }> =>
                    axios.request('POST', 'health/cid/contract/list', data, back ? { 'background-request': true } : {}),

                //TODO: remove to any
                status: (data: any, back = true) => axios.request('PUT', 'health/cid/contract/update/status', data, back ? { 'background-request': true } : {}),
            },
            //TODO: remove to any
            list: (data: any, back = true) => axios.request('POST', 'health/cid/list', data, back ? { 'background-request': true } : {}),
        },
        symptom: {
            //TODO: remove to any
            list: (data: any, back = true) => axios.request('POST', 'health/symptom/list', data, back ? { 'background-request': true } : {}),
            contract: {
                //TODO: remove to any
                list: (data: any, back = true) => axios.request('POST', 'health/symptom/contract/list', data, back ? { 'background-request': true } : {}),
                //TODO: remove to any
                status: (data: any, back = true) => axios.request('PUT', 'health/symptom/contract/update/status', data, back ? { 'background-request': true } : {}),
                //TODO: remove to any
                add: (data: any, back = true) => axios.request('POST', 'health/symptom/contract/add', data, back ? { 'background-request': true } : {}),
            },
        },
        historical: {
            //TODO: remove to any
            list: (data: any, back = false) => axios.request('POST', 'health/historical/list', data, back ? { 'background-request': true } : {}),
        },
        prescription: {
            list: (query: GenericQuery): Promise<GenericListResponse & { prescriptions: PrescriptionModel[] }> => axios.request('POST', 'health/prescription/list', query),
            add: (query: { prescription: PrescriptionModel }): Promise<GenericListResponse & { prescription: PrescriptionModel }> =>
                axios.request('POST', 'health/prescription/add', query),
            update: (query: { prescription: PrescriptionModel }): Promise<GenericListResponse & { prescription: PrescriptionModel }> =>
                axios.request('PUT', 'health/prescription/update', query),
            medicine: {
                add: (query: { prescriptionMedicine: PrescriptionMedicineModel }) => axios.request('POST', 'health/prescription/medicine/add', query),
                list: (query: GenericQuery): Promise<GenericListResponse & { prescriptionMedicines: PrescriptionMedicineModel[] }> =>
                    axios.request('POST', 'health/prescription/medicine/list', query),
                status: (query: { idUUID: string; status: number }): Promise<GenericResponse> => axios.request('PUT', 'health/prescription/medicine/update/status', query),
            },
            sign: (query: {
                idUUID: string;
                otp: string;
                systemCertifier: string;
                forceTwoWays?: boolean;
            }): Promise<
                GenericResponse & {
                    prescription: PrescriptionModel;
                }
            > => axios.request('PUT', 'health/prescription/generate/sign', query),

            email: (query: { email: string; idUUID: string }): Promise<GenericResponse> => axios.request('PUT', 'health/prescription/email/send', query),
        },
        medicalCertificate: {
            list: (query: GenericQuery, back: boolean = false): Promise<GenericListResponse & { medicalCertificates: MedicalCertificateModel[] }> =>
                axios.request('POST', 'health/medicalCertificate/list', query, backR(back)),
            sign: (query: { idUUID: string; otp: string; systemCertifier: string }): Promise<GenericResponse & { medicalCertificate: MedicalCertificateModel }> =>
                axios.request('PUT', 'health/medicalCertificate/generate/sign', query),
            add: (query: { medicalCertificate: MedicalCertificateModel }): Promise<GenericResponse & { medicalCertificate: MedicalCertificateModel }> =>
                axios.request('POST', 'health/medicalCertificate/add', query),
            update: (query: { medicalCertificate: MedicalCertificateModel }): Promise<GenericResponse & { medicalCertificate: MedicalCertificateModel }> =>
                axios.request('PUT', 'health/medicalCertificate/update', query),
            email: (query: { email: string; idUUID: string }): Promise<GenericResponse> => axios.request('PUT', 'health/medicalCertificate/email/send', query),
        },
        medice: {
            list: (query: GenericQuery & { systemBase: string }, back: boolean = true): Promise<GenericListResponse & { medecine: MedecineModel[] }> =>
                axios.request('POST', 'health/medicine/list', query, backR(back)),
        },
        medicalCertificateTemplate: {
            add: (query: {
                medicalCertificateTemplate: MedicalCertificateTemplateModelCreate;
            }): Promise<GenericResponse & { medicalCertificateTemplate: MedicalCertificateTemplateModel }> => axios.request('POST', 'health/medicalCertificateTemplate/add', query),

            list: (query: GenericQuery): Promise<GenericListResponse & { medicalCertificatesTemplates: MedicalCertificateTemplateModel[] }> =>
                axios.request('POST', 'health/medicalCertificateTemplate/list', query),

            update: (query: {
                medicalCertificateTemplate: MedicalCertificateTemplateModelCreate;
            }): Promise<GenericResponse & { medicalCertificateTemplate: MedicalCertificateTemplateModel }> =>
                axios.request('PUT', 'health/medicalCertificateTemplate/update', query),

            status: (query: { idUUID: string; status: number }): Promise<GenericResponse> => axios.request('PUT', 'health/medicalCertificateTemplate/update/status', query),
        },
        medicalExam: {
            email: (data: { email: string; idUUID: string }): Promise<GenericResponse> => axios.request('PUT', 'health/medicalExam/email/send', data),
            list: (data: GenericQuery<'iduuid'>): Promise<GenericListResponse & { medicalExams: MedicalExamModel[] }> => axios.request('POST', 'health/medicalExam/list', data),
            add: (data: { medicalExam: MedicalExamModel }): Promise<GenericResponse & { medicalExam: MedicalExamModel }> => axios.request('POST', 'health/medicalExam/add', data),
            update: (data: { medicalExam: MedicalExamModel }): Promise<GenericResponse & { medicalExam: MedicalExamModel }> =>
                axios.request('PUT', 'health/medicalExam/update', data),
            exam: {
                list: (data: GenericQuery<'medicalexamiduuid' | 'status'>): Promise<GenericListResponse & { medicalExamExams: MedicalExamExamModel[] }> =>
                    axios.request('POST', 'health/medicalExam/exam/list', data),
                add: (data: { medicalExamExam: MedicalExamExamCreateModel }): Promise<GenericResponse & { medicalExamExam: MedicalExamExamModel }> =>
                    axios.request('POST', 'health/medicalExam/exam/add', data),
                status: (data: { idUUID: string; status: number }): Promise<GenericResponse> => axios.request('PUT', 'medicalExam/exam/update/status', data),
            },
            sign: (query: { idUUID: string; otp: string; systemCertifier: string }): Promise<GenericResponse & { medicalCertificate: MedicalCertificateModel }> =>
                axios.request('PUT', 'health/medicalExam/generate/sign', query),
        },
        exam: {
            list: (data: GenericQuery<'description'>): Promise<GenericListResponse & { exams: ExamModel[] }> => axios.request('POST', 'health/exam/list', data),
        },
    };
    card = {
        //TODO: remove to any
        add: (data: any) => axios.request('POST', '/card/add', data),
        //TODO: remove to any
        list: (data: any) => axios.request('POST', '/card/list', data),
        //TODO: remove to any
        update: (data: any) => axios.request('PUT', '/card/update', data),
        //TODO: remove to any
        status: (data: any) => axios.request('PUT', '/card/update/status', data),
        //TODO: remove to any
        payments: (data: any) => axios.request('POST', '/card/payment/list', data),
    };
    config = {
        language: {
            //TODO: remove to any
            list: (data: any) => axios.request('POST', `/config/language/list`, data),
            //TODO: remove to any
            add: (data: any) => axios.request('POST', `/config/language/add`, data),
            //TODO: remove to any
            update: (data: any) => axios.request('PUT', `/config/language/update`, data),
            delete: (idUUID: string) => axios.request('DELETE', `/config/language/${idUUID}`),
        },
    };
    boleto = {
        //TODO: remove to any
        list: (data: any) => axios.request('POST', `/bankingbillet/list`, data),
        barcode: (barcode: string) => axios.request('GET', `/bankingbillet/barcode/${barcode}`),
    };
    group = {
        processed: {
            list: (context: getDataContext<string, string>, overide: any) =>
                g_getData<any, string, string>(
                    context,
                    async data => {
                        let f = g_entityFilter(data.filters, 'referenceOwerIdUUID', 'referenceOwersIdUUID', 5, 'groups#acessall');
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
                        data.filters = f[0];
                        context.setState({ showMore: f[1] }, () => {
                            if (context.state.showMore !== context.state.oldShowMore) {
                                context.setState({ oldShowMore: context.state.showMore }, g_hideSearch);
                            }
                        });
                        return this.group.list(data);
                    },
                    'groups',
                    overide
                ),
            //TODO: remove any
            //TODO: remove any
            types: (context: any, process = () => {}) => g_getDataFields(context, this.group.types as any, 'groupTypes', 'description', 'id', process),
            //TODO: remove any
            //TODO: remove any
            //TODO: remove any
            status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                g_ChangeStatus(context, (() => this.group.status({ idUUID: idUUID, groupStatus: status })) as any, callback, message, messages, e),
        },
        //TODO: remove any
        add: (data: any) => axios.request('POST', `/group/add`, data),
        //TODO: remove any
        types: (data: any) => axios.request('POST', `/group/groupTypes`, data),
        //TODO: remove any
        list: (data: any) => axios.request('POST', `/group/list`, data),
        get: (iduuid: string, loadEntities: string) => axios.request('GET', `/group/${iduuid}/${loadEntities}`, {}),
        //TODO: remove any
        element: (iduuid: string, data: any) => axios.request('POST', `/group/element/list/${iduuid}`, data),
        //TODO: remove any
        update: (data: any) => axios.request('PUT', `/group/update`, data),
        //TODO: remove any
        status: (data: any) => axios.request('PUT', `/group/update/status`, data),
    };
    product = {
        processed: {
            //TODO: remove as any
            //TODO: remove as any
            status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                g_ChangeStatus(context, (() => this.product.status({ idUUID: idUUID, productStatus: status })) as any, callback, message, messages, e),
        },
        //TODO: remove as any
        list: (data: any) => axios.request('POST', '/product/list', data),
        //TODO: remove as any
        status: (data: any) => axios.request('PUT', `/product/update/status`, data),
    };
    consignee = {
        processed: {
            //TODO: remove as any
            list: (context: getDataContext<string, string>, overide: any) =>
                g_getData(
                    context,
                    async data => {
                        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall', g_consignators());
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
                        data.filters = f[0];
                        context.setState({ showMore: f[1] }, () => {
                            if (context.state.showMore !== context.state.oldShowMore) {
                                context.setState({ oldShowMore: context.state.showMore }, g_hideSearch);
                            }
                        });
                        return this.consignee.list(data);
                    },
                    'consignees',
                    overide
                ),
            staging: {
                //TODO: remove as any
                list: (c: getDataContext<string, string>, overide: any) =>
                    g_getData(
                        c,
                        async data => {
                            let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall', g_consignators());
                            if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
                            data.filters = f[0];
                            c.setState({ showMore: f[1] }, () => {
                                if (c.state.showMore !== c.state.oldShowMore) {
                                    c.setState({ oldShowMore: c.state.showMore }, g_hideSearch);
                                }
                            });
                            return this.consignee.staging.list(data);
                        },
                        'consignees',
                        overide
                    ),
            },
        },
        staging: {
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/consignee/staging/list`, data),
            //TODO: remove as any
            add: (data: any) => axios.request('POST', `/consignee/staging/add`, data),
        },
        group: {
            add: (consigneeIdUUID: string, groupIdUUID: string) => axios.request('POST', `/consignee/group/${consigneeIdUUID}/${groupIdUUID}`, {}),
            remove: (consigneeIdUUID: string, groupIdUUID: string) => axios.request('DELETE', `/consignee/group/${consigneeIdUUID}/${groupIdUUID}`, {}),
            //TODO: remove as any
            list: (consigneeIdUUID: string, data: any) => axios.request('POST', `/consignee/group/${consigneeIdUUID}`, data),
        },
        payroll: {
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/consignee/payroll/list`, data),
            get: (year: number, month: number, consigneeIdUUID: string) => axios.request('GET', `/consignee/payroll/${year}/${month}/${consigneeIdUUID}`, {}),
        },
        address: {
            put: (data: { address: AddressCreateModel; consigneeIdUUID?: string }): Promise<GenericResponse & { address: AddressModel }> =>
                axios.request('POST', '/consignee/address/put', data),
        },
        list: (data: GenericQuery): Promise<GenericListResponse & { consignees: ConsigneeModel[] }> => axios.request('POST', `/consignee/list`, data),
        //TODO: remove as any
        status: (data: any) => axios.request('PUT', `/consignee/update/status`, data),
        //TODO: remove as any
        update: (data: any) => axios.request('PUT', `/consignee/update`, data),
        //TODO: remove as any
        add: (data: any) => axios.request('POST', `/consignee/add`, data),

        /**
         * @deprecated use consignee.consignee
         * @param idUUID
         * @param loadAddress
         * @param loadContacts
         * @param back
         * @returns
         */
        get: (idUUID: string, loadAddress = 1, loadContacts = 1, back = false): Promise<ConsigneeGet> =>
            axios.request('GET', `/consignee/${idUUID}/${loadAddress}/${loadContacts}/0`, {}, back ? { 'background-request': true } : {}),
        consignee: (idUUID: string, loadAddress = 1, loadContacts = 1, loadParameters = 0, back = false): Promise<ConsigneeGet> =>
            axios.request('GET', `/consignee/${idUUID}/${loadAddress}/${loadContacts}/${loadParameters}`, {}, back ? { 'background-request': true } : {}),
        //TODO: remove as any
        documentUpload: (formData: any, documentTypeId: number | string, userUUID: string) => {
            const config = {
                'content-type': 'multipart/form-data',
                'background-request': true,
            };

            return axios.request('POST', `/consignee/document/upload/${documentTypeId}/${userUUID}`, formData, config);
        },

        //TODO: remove as any
        subscriptions: (data: GenericQuery): Promise<GenericListResponse & { subscriptionCampaigns: any[] }> => axios.request('POST', 'consignee/subscriptions', data),
    };
    contract = {
        template: {
            form: {
                list: (
                    data: GenericQuery
                ): Promise<
                    GenericListResponse & {
                        contractTemplateForms: ContractTemplateFormsModel[];
                    }
                > => axios.request('POST', `/contract/template/form/list`, data),
            },
            margin: {
                processed: {
                    //TODO: remove as any
                    //TODO: remove as any
                    //TODO: remove as any
                    status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                        g_ChangeStatus(
                            context,
                            //TODO: remove as any
                            (() => this.contract.template.margin.status({ idUUID: idUUID, contractTemplateMarginStatus: status })) as any,
                            callback,
                            message,
                            messages,
                            e
                        ),
                },
                //TODO: remove as any
                list: (data: any) => axios.request('POST', `/contract/template/margin/list`, data),
                //TODO: remove as any
                add: (data: any) => axios.request('POST', `/contract/template/margin/add`, data),
                //TODO: remove as any
                update: (data: any) => axios.request('PUT', `/contract/template/margin/update`, data),
                //TODO: remove as any
                status: (data: any) => axios.request('PUT', `/contract/template/margin/update/status`, data),
            },
            processed: {
                //TODO: remove as any
                list: (c: getDataContext<string, string>, extra: any) =>
                    g_checkAlc(5, 'contract-management#totalaccess')
                        ? g_getData(c, this.contract.template.list, 'contractTemplates', extra)
                        : g_getData(
                              c,
                              async data => {
                                  let f = g_entityFilter(data.filters, 'consignatorsIdUUID', 'consignatorsIdUUID', 5, 'contract-management#totalaccess');
                                  if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                                  data.filters = f[0];
                                  return this.contract.template.list(data);
                              },
                              'contractTemplates',
                              extra
                          ),
            },
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/contract/template/list`, data),
            get: (iduuid: string) => axios.request('GET', `/contract/template/${iduuid}`),
            //TODO: remove as any
            status: (data: any) => axios.request('PUT', `/contract/update/status`, data),
            //TODO: remove as any
            add: (data: any, back = false) => axios.request('POST', '/contract/add', data, back ? { 'background-request': true } : {}),
            //TODO: remove as any
            update: (data: any, back = false) => axios.request('PUT', `/contract/update`, data, back ? { 'background-request': true } : {}),
            field: {
                //TODO: remove as any
                put: (data: any, back = false) => axios.request('POST', `/contract/field/put`, data, back ? { 'background-request': true } : {}),
            },
        },
        field: {
            //TODO: remove as any
            put: (data: any, back = false) => axios.request('POST', `/contract/field/put`, data, back ? { 'background-request': true } : {}),
        },
        report: {
            bill: (query: GenericQuery): Promise<GenericListResponse & { billInfos: ReportBillModel[] }> => axios.request('POST', '/contract/report/bill', query),
        },
        //TODO: remove as any
        ratting: (data: any) => axios.request('POST', '/contract/ratting', data),
        //TODO: remove as any
        groupByContractTemplate: (data: any) => axios.request('POST', `/contract/groupByContractTemplate/list`, data),
        //TODO: remove as any
        groupByContractProductType: (data: any) => axios.request('POST', `/contract/groupByProductType/list`, data),

        add: (data: { contract: ContractModelCreate }, back = false): Promise<GenericResponse & { installments: ContractInstalmentsModel[]; contract: ContractModel }> =>
            axios.request('POST', '/contract/add', data, back ? { 'background-request': true } : {}),
        list: (data: GenericQuery & { loadContractFields?: number }, back = false): Promise<GenericListResponse & { contracts: ContractModel[] }> =>
            axios.request('POST', '/contract/list', data, back ? { 'background-request': true } : {}),
        get: (idUUID: string, back = false): Promise<ContractGet> => axios.request('GET', `/contract/${idUUID}/2`, {}, back ? { 'background-request': true } : {}),
    };
    provider = {
        processed: {
            list: (context: getDataContext<string, string>, other: StringIndexed) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'organizationiduuid', 'organizationsiduuid', 5, 'provider#accessall', g_consignators());
                        let f1 = g_entityFilter(data.filters, 'iduuid', 'iduuids', 5, 'provider#accessall', g_providers());
                        if (!f && !f1)
                            return {
                                code: 0,
                                pageTotal: 0,
                                recordsTotal: 0,
                                providers: [],
                                pageNumber: data.pageNumber,
                                pageSize: data.pageSize,
                                description: '',
                                messages: [],
                            } as GenericListResponse & { providers: ProviderModel[] };
                        data.filters = [];
                        if (f) data.filters = f[0];
                        if (f1) data.filters = [...(data.filters ?? []), ...f1[0]];
                        return this.provider.list(data);
                    },
                    'providers',
                    other
                ),

            //TODO: remove as any
            listhook: (state: any, other = {}) =>
                //TODO: remove as any
                //TODO: remove as any
                //TODO: remove as any
                g_getDataHook(
                    state,
                    ((data: any) => {
                        let f = g_entityFilter(data.filters, 'organizationiduuid', 'organizationsiduuid', 5, 'provider#accessall', g_consignators());
                        let f1 = g_entityFilter(data.filters, 'iduuid', 'iduuids', 5, 'provider#accessall', g_providers());
                        if (!f && !f1) return { code: 0, pageTotal: 0, recordsTotal: 0, consignators: [] };
                        if (f) data.filters = f[0];
                        if (f1) data.filters = [...data.filters, ...f1[0]];
                        return this.provider.list(data);
                    }) as any,
                    'providers' as any,
                    other
                ),

            //TODO: remove as any
            //TODO: remove as any
            //TODO: remove as any
            status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                g_ChangeStatus(context, (() => this.provider.status({ idUUID: idUUID, providerStatus: status })) as any, callback, message, messages, e),
        },
        address: {
            delete: (idUUID: string) => axios.request('DELETE', `/provider/address/${idUUID}`),
        },
        searchAreaServiceType: {
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/provider/searchAreaServiceTypes`, data),
            delete: (providerUUID: string, searchAreaServiceType: string) =>
                axios.request('DELETE', `/provider/searchAreaServiceType/${providerUUID}/${searchAreaServiceType}`, {}),
            //TODO: remove as any
            add: (data: any) => axios.request('POST', `/provider/searchAreaServiceType/add`, data),
        },
        get: (idUUID: string, loadAddress = 0) => axios.request('GET', `/provider/${idUUID}/${loadAddress}/0/0`),

        list: (data: GenericQuery, back = false): Promise<GenericListResponse & { providers: ProviderModel[] }> =>
            axios.request('POST', '/provider/list', data, back ? { 'background-request': true } : {}),

        //TODO: remove as any
        status: (data: any) => axios.request('PUT', `/provider/update/status`, data),
        //TODO: remove as any
        add: (data: any) => axios.request('POST', `/provider/add`, data),
        //TODO: remove as any
        listLogs: (data: any) => axios.request('POST', `/provider/log/list`, data),

        update: (data: { provider: ProviderModel }): Promise<GenericResponse & { provider: ProviderModel }> => axios.request('PUT', `/provider/update`, data),

        //TODO: remove as any
        listTypes: (data: any) => axios.request('POST', `/provider/providerTypes`, data),
        //TODO: remove as any
        documentUpload: (formData: any, documentTypeId: number | string, userUUID: string) => {
            const config = {
                'content-type': 'multipart/form-data',
                'background-request': true,
            };
            return axios.request('POST', `/provider/document/upload/${documentTypeId}/${userUUID}`, formData, config);
        },
    };
    user = {
        //TODO: remove as any
        resetPassword: (data: any) => axios.request('POST', `/user/reset`, data),

        verify: (app: string, userType: string | number, user: string) => axios.request('GET', `/user/verify/${app}/${userType}/${user}`),
        list: (data: GenericQuery & { userType?: UserType }): Promise<GenericListResponse & { users: UserModel[] }> => axios.request('POST', `/user/list`, data),

        update: (data: { dateBirthDate?: string; user: UserModel }): Promise<GenericResponse & { user: UserModel }> => axios.request('PUT', `/user/update`, data),

        documentUpload(formData: FormData, documentTypeId: string, userUUID: string) {
            const config = { 'content-type': 'multipart/form-data', 'background-request': true };
            return axios.request('POST', `/user/document/upload/${documentTypeId}/1/${userUUID}`, formData, config);
        },
    };
    search = {
        serviceType: {
            entityProduct: {
                remove: (id: string | number) => axios.request('DELETE', `/search/serviceType/entity/product/${id}`),
                add: (id: string | number, ctid = null, cid = null) =>
                    axios.request('POST', '/search/serviceType/entity/product/add', {
                        ...(ctid ? { contractTemplateIdUUID: ctid } : {}),
                        ...(cid ? { campaignIdUUID: cid } : {}),
                        entitySearchAreaServiceTypeIdUUID: id,
                    }),
            },
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/search/serviceTypes`, data),
            //TODO: remove as any
            put: (data: any) => axios.request('POST', `/search/serviceType/put`, data),
            delete: (id: string | number) => axios.request('DELETE', `/search/serviceType/${id}`, {}),
        },
    };

    scheduling = {
        processed: {
            //TODO: remove as any
            //TODO: remove as any
            //TODO: remove as any
            //TODO: remove as any
            status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                g_ChangeStatus(context, (() => this.scheduling.status({ idUUID: idUUID, schedulingStatus: status })) as any, callback, message, messages, e),
        },
        //TODO: remove as any
        add: (data: any) => axios.request('POST', `/scheduling/add`, data),
        //TODO: remove as any
        update: (data: any, hidden = false) => axios.request('PUT', `/scheduling/update`, data, { 'background-request': hidden }),
        //TODO: remove as any
        status: (data: any) => axios.request('PUT', `/scheduling/update/status`, data),
        //TODO: remove as any
        list: (data: any, hidden = false) => axios.request('POST', `/scheduling/list`, data, { 'background-request': hidden }),
        //TODO: remove as any
        autoGenerate: (data: any) => axios.request('POST', `/scheduling/autogeneration`, data),
    };

    businessHours = {
        //TODO: remove as any
        add: (data: any) => axios.request('POST', `/businessHours/add`, data),
        //TODO: remove as any
        list: (data: any) => axios.request('POST', `/businessHours/list`, data),
        //TODO: remove as any
        types: (data: any) => axios.request('POST', `/businessHours/businessHoursTypes`, data),
        //TODO: remove as any
        categories: (data: any) => axios.request('POST', `/businessHours/businessHoursCategories`, data),
        //TODO: remove as any
        update: (data: any) => axios.request('PUT', `/businessHours/update`, data),
        //TODO: remove as any
        delete: (id: string | number) => axios.request('DELETE', `/businessHours/${id}`),
    };

    parameter = {
        //TODO: remove as any
        list: (data: any) => axios.request('POST', `/consignee/parameter/list`, data),
        //TODO: remove as any
        put: (data: any) => axios.request('POST', `/consignee/parameter/put`, data),
    };

    consignator = {
        processed: {
            //TODO: remove as any
            list: (context: getDataContext<string, string>, other: any) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'idUUID', 'idUUIDs', 5, 'consignator-management#accessall');
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                        data.filters = f[0];
                        return this.consignator.list(data);
                    },
                    'consignators',
                    other
                ),
            //TODO: remove as any
            //TODO: remove as any
            //TODO: remove as any
            status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                g_ChangeStatus(context, (() => this.consignator.status({ idUUID: idUUID, consignatorStatus: status })) as any, callback, message, messages, e),
        },
        clocking: {
            //TODO: remove as any
            add: (data: any) => axios.request('POST', `/consignator/clocking/location/add`, data),
            //TODO: remove as any
            update: (data: any) => axios.request('PUT', `/consignator/clocking/location/update`, data),
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/consignator/clocking/location/list`, data),
            get: (idUUID: string) => axios.request('GET', `/consignator/clocking/location/${idUUID}`),
            //TODO: remove as any
            status: (data: any) => axios.request('PUT', `/consignator/clocking/location/updateStatus`, data),
        },
        //TODO: remove as any
        list: (data: any) => axios.request('POST', `/consignator/list`, data),
        //TODO: remove as any
        status: (data: any) => axios.request('PUT', `/consignator/update/status`, data),
    };

    subscription = {
        //TODO: remove as any
        list: (data: any) => axios.request('POST', '/subscription/list', data),
        //TODO: remove as any
        listCycles: (data: any) => axios.request('POST', '/subscription/cycle/list', data),
        //TODO: remove as any
        add: (data: any) => axios.request('POST', '/subscription/add', data),
        get: (idUUID: string) => axios.request('GET', `/subscription​/${idUUID}`),
        //TODO: remove as any
        campaign: (data: any) => axios.request('POST', '/subscription/campaign/list', data),
    };

    loyalty = {
        //TODO: remove as any
        authorize: (data: any, provider: string) => axios.request('PUT', `/loyalty/card/authorize/${provider}`, data),
    };

    campaign = {
        processed: {
            list: (c: getDataContext, campaignType: string | number, filter = c.state.filter) =>
                g_getData(
                    c,
                    d => {
                        let f = g_entityFilter(d.filters, 'consignatorsidUUID', 'consignatorsidUUIDs', 5, 'consignator-management#accessall');
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                        d.filters = f[0];
                        return this.campaign.list(d);
                    },
                    'campaigns',
                    { filters: [{ key: 'campaignType', value: campaignType }, ...filter] }
                ),
        },
        //TODO: remove as any
        list: (data: any) => axios.request('POST', '/campaign/list', data),

        item: {
            add: (data: { campaignItem: CampaignItemCreateModel }): Promise<GenericResponse & { campaignItem: CampaignItemModel }> =>
                axios.request('POST', `/campaign/item/add`, data),
            list: (data: GenericQuery & { campaignIdUUID?: string }): Promise<GenericListResponse & { campaignItens: CampaignItemListModel[] }> =>
                axios.request('POST', `/campaign/item/list/`, data),
            update: (data: { campaignItem: CampaignItemCreateModel }): Promise<GenericResponse & { campaignItem: CampaignItemModel }> =>
                axios.request('PUT', `/campaign/item/update`, data),
        },
    };

    utils = {
        //TODO: remove as any
        getContactRelationshipTypes: (data: any) => axios.request('POST', 'utils/contactRelationshipTypes', data),
        //TODO: remove as any
        gender: (data: any) => axios.request('POST', '/utils/genderTypes', data),
        //TODO: remove as any
        listMaritalStatus: (data: any) => axios.request('POST', '/utils/civilStatus', data),
        //TODO: remove as any
        listHabilitation: (data: any) => axios.request('POST', '/utils/hablitations', data),
        //TODO: remove as any
        listEntityType: (data: any) => axios.request('POST', `/utils/entityTypes`, data),
        //TODO: remove as any
        listDocumentType: (data: any) => axios.request('POST', '/utils/documentTypes', data),
        //TODO: remove as any
        getTimestamp: () => axios.request('GET', '/utils/timestamp'),
        timestamp: () => axios.request('GET', '/utils/timestamp'),
        country: () => axios.request('POST', '/utils/country/list'),
        zipCode: {
            //TODO: remove as any
            list: (data: any) => axios.request('POST', '/utils/zipCode/list', data),
            //TODO: remove as any
            add: (data: any) => axios.request('POST', '/utils/zipCode/add', data),
            //TODO: remove as any
            update: (data: any) => axios.request('POST', '/utils/zipCode/update', data),
        },

        ratting: {
            processed: {
                //TODO: remove as any
                //TODO: remove as any
                //TODO: remove as any
                //TODO: remove as any
                status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                    g_ChangeStatus(context, (() => this.utils.ratting.status({ idUUID: idUUID, rattingCategoryStatus: status })) as any, callback, message, messages, e),
            },
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/utils/rattingCategory/list`, data),
            //TODO: remove as any
            status: (data: any) => axios.request('PUT', `/utils/rattingCategory/update/status`, data),
            //TODO: remove as any
            add: (data: any) => axios.request('POST', `/utils/rattingCategory/add`, data),
            //TODO: remove as any
            update: (data: any) => axios.request('PUT', `/utils/rattingCategory/update`, data),
        },
    };

    //TODO: remove as any
    listDocuments = (data: any, service: string) => axios.request('POST', `/${service}/document/list`, data);

    //TODO: remove as any
    listLogs = (data: any) => axios.request('POST', '/log/log/list', data);

    help = {
        processed: {
            //TODO: remove as any
            list: (context: getDataContext, other: any) =>
                g_getData(
                    context,
                    data => {
                        let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorIdUUIDS', 5, 'consignator-management#accessall', g_consignators());
                        if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                        data.filters = f[0];
                        if (g_checkAlc(5, 'consignator-management#accessall')) data.filters = [...(data.filters ?? []), pair('showAll', 'true')];
                        if (f[1]) context.setState({ showConsignator: true });
                        return this.help.list(data);
                    },
                    'helps',
                    other
                ),
        },
        //TODO: remove as any
        list: (data: any) => axios.request('POST', `/help/list`, data),
    };

    payroll = {
        processed: {
            //TODO: remove as any
            //TODO: remove as any
            //TODO: remove as any
            status: (context: any, idUUID: string, status: any, callback = () => {}, message = '', messages = context.messages, e: any) =>
                g_ChangeStatus(context, (() => this.payroll.status({ idUUID: idUUID, payrollStatus: status })) as any, callback, message, messages, e),
        },
        file: {
            status: (batchIdUUID: string, newStatusId: string) => axios.request('PUT', `/payroll/file/${batchIdUUID}/update/status/${newStatusId}`),
        },
        //TODO: remove as any
        status: (data: any) => axios.request('PUT', `/payroll/update/status`, data),
        //TODO: remove as any
        list: (data: any) => axios.request('POST', `/payroll/list`, data),
        get: (idUUID: string) => axios.request('GET', `/payroll/${idUUID}`),
        control: {
            //TODO: remove as any
            list: (data: any) => axios.request('POST', `/payroll/control/list`, data),
            get: (idUUID: string, back: boolean) => axios.request('GET', `/payroll/control/${idUUID}`, {}, back ? { 'background-request': true } : {}),
            //TODO: remove as any
            update: (data: any) => axios.request('PUT', `/payroll/control/update/status`, data),
            //TODO: remove as any
            add: (data: any) => axios.request('POST', `/payroll/control/add`, data),
            //TODO: remove as any
            controlAddBatch: (formData: any, batchServiceIDUUID: string, payrollControlIDUUID: string, productIDUUID: string, layoutId: string) => {
                const config = {
                    'content-type': 'multipart/form-data',
                    'background-request': true,
                };

                return axios.request('POST', `/payroll/upload/${batchServiceIDUUID}/${payrollControlIDUUID}/${productIDUUID}/${layoutId}`, formData, config);
            },
        },
    };
}

export default new Services();
