import { Messages } from 'primereact/messages';
import React from 'react';
import CardService from '../../service/CardService';
import { ls, g_entityFilter, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import { statesPayment } from './Card';

export class PaymentsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        g_getData(
            this,
            data => {
                if (this.props.refIdUUID) data.filters.push({ referenceIdUUID: this.props.refIdUUID });
                let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'card-management#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, cards: [] };
                data.filters = f[0];
                return CardService.payments(data);
            },
            'payments'
        );

    render() {
        return (
            <div className="card">
                <h1>Gestão de Movimentos</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, 0, 0, false),
                    [
                        { type: 'd', data: 'merchantName', sortable: true, filter: true },
                        { type: 'di', di: raw => ls(raw.paymentType, 'paymentType'), data: 'paymentType', title: ls('paymentType'), sortable: true },
                        { type: 'd', data: 'value', sortable: true, filter: true },
                        { type: 'd', data: 'acquireAuthorizationCode', sortable: true, filter: true },
                        { type: 'd', data: 'brandReturnCode', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'paymentStatus', states: statesPayment },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
