import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import config from '../../../config/config';
import { ConsigneeModel, DataTableArgumentElement, TablePagePropsHeader } from '../../../types';
import { Button, Form, GArea, GDiv, GE, GState, GView, g_getData, noDataMessage, pair, StringEmpty, useDataObject, Wraper } from '../../Generic';
import Address from '../../generic/Address';
import { GDropdown, GInput, GMessages, GSwitch } from '../../generic/Form';
import { g_displayMessageError, ls, S, TablePage } from '../../GenericFunctions';

export type PrescriptionModel = {
    contractIdUUID: string;
    dateCreation: string;
    dateUpdate: string;
    description: string;
    idUUID: string;
    medicines: MedecineModel[];
    status: number;
    url: string;
};
export type MedecineModel = {
    composition: {
        id: number;
        name: string;
    };
    concentration: string;
    ean: string;
    howToTakeDescription: string;
    howToTakeId: number;
    laboratotyName: string;
    name: string;
    pharmacyForm: string;
    presentation: string;
    quantityPerPackage: number;
    requestQuantity: number;
    unit: string;
    flag: string;
};
export interface PrescriptionMedicineModel {
    dateCreation: string;
    dateUpdate: string;
    howToTake: string;
    idUUID: string;
    medicineDescription: string;
    prescriptionIdUUID: string;
    quantity: number;
    status: number;
    unit: string;
    flag: string;
    ean: string;
    medicinePresentation: string;
}
interface prescriptionListProps {
    header: TablePagePropsHeader;
    extra?: DataTableArgumentElement<PrescriptionModel>[];
    extraRequest?: (c: TablePage<PrescriptionModel, any>) => {
        [key: string]: any;
    };
    refTable?: (e: TablePage<PrescriptionModel, any> | null) => void;
    checkAcl?: boolean;
    fakename?: string;
}
export const PrescriptionList: React.FunctionComponent<prescriptionListProps> = (props: prescriptionListProps) => {
    let { header, extra = [], extraRequest = () => ({}), refTable: ref, fakename, checkAcl } = props;
    return (
        <TablePage<PrescriptionModel, any>
            fakename={fakename}
            checkAcl={checkAcl}
            header={header}
            getData={(c: TablePage) => g_getData(c, S.health.prescription.list, 'prescriptions', extraRequest(c))}
            table={() => [{ t: 'd', data: 'description', title: ls('descriptionPrescription'), filter: true, sortable: true }, ...extra]}
            ref={ref ?? (() => {})}
        />
    );
};
interface PrescriptionProps {
    idUUID: string;
    contractIdUUID: string;
    saved?: (e: string) => void;
    disabled?: boolean;
    createOnIdUUIDEmpty?: boolean;
    exit?: () => void;
    consignee: ConsigneeModel;
}
export const Prescription: React.FunctionComponent<PrescriptionProps> = ({ idUUID, contractIdUUID, createOnIdUUIDEmpty, saved, disabled: diabledProp, exit, consignee }) => {
    let { obj, setObj, setObjState } = useDataObject<PrescriptionModel>({} as any);
    let {
        obj: search,
        setObj: setSearch,
        setObjState: setSearchState,
    } = useDataObject<{
        autoUpdate: string;
        medecineList: MedecineModel[];
        selected: (MedecineModel & { dname: string }) | null;
        quantity: string;
        howToTake: string;
        prescriptionsMedicineList: PrescriptionMedicineModel[];
        otp: string;
        dialogOtpVisible: boolean;
        dialogNewVisible: boolean;
        unit: string;
        forceTwoWays: number;
        needsAddress: boolean;
    }>({
        autoUpdate: '',
        medecineList: [],
        selected: null,
        quantity: '',
        howToTake: '',
        prescriptionsMedicineList: [],
        otp: '',
        dialogOtpVisible: false,
        dialogNewVisible: false,
        unit: ls('boxes', 'prescription'),
        forceTwoWays: 0,
        needsAddress: false,
    });
    let { obj: email, setObjState: setEmailState, setObj: setEmail } = useDataObject<{ visible: boolean; email: string }>({ visible: false, email: consignee.email });
    let [count, setCount] = useState(0);
    let messagesRef: React.MutableRefObject<Messages | null> = useRef(null);
    let prescriptionMedicineRef: React.MutableRefObject<TablePage<PrescriptionMedicineModel> | null> = useRef(null);
    let medicineFormRef: React.MutableRefObject<HTMLFormElement | null> = useRef(null);
    let preSearchRef: React.MutableRefObject<String> = useRef('');
    let createdNoIdUUIDRef: React.MutableRefObject<boolean> = useRef(false);

    let hasFlag: React.MutableRefObject<boolean> = useRef(false);
    let hasSearched: React.MutableRefObject<boolean> = useRef(false);

    let hasSearchedAddress: React.MutableRefObject<boolean> = useRef(false);

    let addressRef: React.MutableRefObject<Address | null> = useRef(null);

    let disabled: boolean = (diabledProp || obj.url) as boolean;

    let getData = async () => {
        let r = await S.health.prescription.list({ filters: [pair('idUUID', idUUID)] });
        if (g_displayMessageError(messagesRef.current, r)) return;
        if (r.prescriptions.length === 0) {
            noDataMessage(messagesRef.current);
            return;
        }
        setObjState(r.prescriptions[0]);
    };

    let create = async () => {
        let date = new Date();
        let r = await S.health.prescription.add({
            prescription: {
                ...obj,
                contractIdUUID: contractIdUUID,
                description: StringEmpty(obj.description)
                    ? `Prescrição eletronica ${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(
                          date.getHours()
                      ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
                    : obj.description,
            },
        });
        if (g_displayMessageError(messagesRef.current, r)) return;
        setObjState({ ...r.prescription });
        if (saved) saved(r.prescription.idUUID);
        if (createOnIdUUIDEmpty && StringEmpty(idUUID)) createdNoIdUUIDRef.current = true;
    };

    useEffect(() => {
        if (!StringEmpty(idUUID)) {
            if (prescriptionMedicineRef.current) prescriptionMedicineRef.current.getData();
            getData();
        }
        //TODO: improve
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idUUID]);

    useEffect(() => {
        if (!StringEmpty(idUUID)) getData();
        else if (createOnIdUUIDEmpty && !createdNoIdUUIDRef.current) {
            create();
        }
        //! Not needed because its only supposed to be run once
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (search.dialogOtpVisible && search.forceTwoWays && !hasSearchedAddress.current) {
            if (!consignee.address || consignee.address.length === 0) setSearchState({ ...search, needsAddress: true });
            hasSearchedAddress.current = true;
        }

        if (search.dialogOtpVisible && !hasFlag.current && !hasSearched.current) {
            (async () => {
                let r = await S.health.prescription.medicine.list({
                    filters: [pair('prescriptionIdUUID', idUUID), pair('status', '1')],
                });
                if (g_displayMessageError(messagesRef.current, r)) return;
                hasSearched.current = true;
                for (let a of r.prescriptionMedicines) {
                    if (a.flag) {
                        hasFlag.current = true;
                        setSearchState({ ...search, forceTwoWays: 1 });
                        break;
                    }
                }
            })();
        } else if (hasFlag.current && !search.dialogOtpVisible) {
            hasFlag.current = false;
            hasSearched.current = false;
            setSearchState({ ...search, forceTwoWays: 0 });
        }

        if (!search.dialogOtpVisible) {
            hasSearched.current = false;
            hasSearchedAddress.current = false;
        }
    }, [search.dialogOtpVisible, idUUID, setSearchState, search, consignee]);

    const checkNull = (a: string) => {
        if (a === 'NULL') return '';
        return a;
    };
    const first = (a: string) => {
        if (StringEmpty(a)) return '';
        return a[0].toUpperCase() + a.substr(1).toLocaleLowerCase();
    };
    const firstAfterSpace = (a: string) => {
        return a
            .split(' ')
            .map(a => first(a))
            .join(' ');
    };
    const itemTemplate = (b: MedecineModel) => {
        return (
            <div style={{ display: 'flex' }}>
                {(() => {
                    if (b.flag) {
                        return (
                            <div
                                style={{
                                    color: 'red',
                                    fontSize: '25px',
                                    paddingTop: '4px',
                                    paddingLeft: '2.5px',
                                    marginRight: '3px',
                                    borderRadius: '50%',
                                    backgroundColor: 'lightgrey',
                                    height: '30px',
                                    width: '30px',
                                }}
                                className="pi pi-minus"
                            ></div>
                        );
                    }
                    return (
                        <div
                            style={{
                                marginRight: '3px',
                                borderRadius: '50%',
                                backgroundColor: 'lightgrey',
                                height: '30px',
                                width: '30px',
                            }}
                        ></div>
                    );
                })()}
                <div></div>
                <div>
                    <div>
                        <b>
                            {first(checkNull(b.name))} {checkNull(b.concentration ?? '')}
                            {checkNull(b.unit ?? '')}
                        </b>
                        , {first(checkNull(b.pharmacyForm))}({b.quantityPerPackage ?? ''}un)
                    </div>
                    <div style={{ fontSize: '0.9em', color: 'gray' }}>
                        {firstAfterSpace(checkNull(b.composition.name))} {b.concentration ?? ''}
                        {checkNull(b.unit ?? '')}
                    </div>
                </div>
                <div style={{ flexGrow: 2 }}></div>
                <div>{checkNull(b.laboratotyName ?? '')}</div>
            </div>
        );
    };

    return (
        <>
            <Form
                disabled={disabled}
                class="p-grid p-col-12"
                obj={obj}
                setObj={setObj}
                fclass={'p-col-9'}
                onSubmit={async e => {
                    e.preventDefault();
                    if (idUUID) {
                        //Update
                        let r = await S.health.prescription.update({
                            prescription: obj,
                        });
                        if (g_displayMessageError(messagesRef.current, r)) return;
                        if (exit) exit();
                    } else create();
                }}
            >
                <GMessages refM={messagesRef} />
                <GDiv style={{ minHeight: '80%' }} class="p-col-9">
                    <GE d="description" />
                    {ls('medicine')}
                    <TablePage<PrescriptionMedicineModel, {}>
                        checkAcl={false}
                        header={
                            StringEmpty(idUUID) || !StringEmpty(obj.url)
                                ? null
                                : () => (
                                      <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                          <Button
                                              icon="pi pi-plus"
                                              bttClass="p-button-success"
                                              wraperClass=""
                                              onClick={e => {
                                                  e.preventDefault();
                                                  setSearchState({ ...search, dialogNewVisible: true });
                                              }}
                                              style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                          />
                                      </div>
                                  )
                        }
                        ref={e => {
                            prescriptionMedicineRef.current = e;
                            setCount(prescriptionMedicineRef.current?.state.data.length ?? 0);
                        }}
                        getData={c => {
                            if (!StringEmpty(idUUID))
                                g_getData(c, S.health.prescription.medicine.list, 'prescriptionMedicines', {
                                    filters: [pair('prescriptionIdUUID', idUUID), pair('status', '1')],
                                });
                        }}
                        table={() => [
                            { t: 'd', data: 'medicineDescription' },
                            {
                                t: 'di',
                                data: 'flag',
                                title: ls('flagPrescription'),
                                align: 'center',
                                di: e =>
                                    e.flag ? (
                                        <span
                                            className="pi pi-minus"
                                            style={{
                                                color: 'red',
                                                fontSize: '25px',
                                                paddingTop: '4px',
                                                marginRight: '3px',
                                                borderRadius: '50%',
                                                backgroundColor: 'lightgrey',
                                                height: '30px',
                                                width: '30px',
                                            }}
                                        />
                                    ) : null,
                            },
                            { t: 'd', data: 'howToTake' },
                            { t: 'd', data: 'quantity' },
                            { t: 'd', data: 'unit' },
                            { t: 'date', data: 'dateCreation' },
                            {
                                t: 'btt',
                                icon: 'pi pi-trash',
                                class: 'p-button-danger',
                                click: async (row: PrescriptionMedicineModel, e) => {
                                    e.preventDefault();
                                    let r = await S.health.prescription.medicine.status({
                                        idUUID: row.idUUID,
                                        status: 2,
                                    });
                                    if (g_displayMessageError(messagesRef.current, r)) return;
                                    prescriptionMedicineRef.current?.getData();
                                },
                                c: disabled,
                            },
                        ]}
                    />
                </GDiv>
                <GDiv class="p-col-3" hidden={StringEmpty(idUUID)}>
                    <GState
                        d="status"
                        custom={{
                            1: { class: 'pi-check', color: 'green' },
                            2: { class: 'pi-times', color: 'red' },
                        }}
                    />
                    <GView d="dateUpdate" />
                    <GView d="dateCreation" />
                </GDiv>
                <div className="p-grid p-col-12">
                    <Button label={idUUID ? 'save' : 'create'} hidden={(idUUID && obj.url && true) as boolean} />
                    <Button
                        label="sign"
                        disabled={count /*(prescriptionMedicineRef.current?.state.data.length ?? 0)*/ === 0}
                        hidden={(!idUUID || obj.url) as boolean}
                        onClick={e => {
                            e.preventDefault();
                            setSearchState({ ...search, dialogOtpVisible: true });
                        }}
                    />
                    <Button
                        label="print"
                        hidden={!obj.url}
                        onClick={e => {
                            e.preventDefault();
                            window.open(config.HEALTH + obj.url);
                        }}
                    />
                    <Button
                        label="sendEmail"
                        hidden={!obj.url}
                        onClick={e => {
                            e.preventDefault();
                            setEmailState({ ...email, visible: true });
                        }}
                    />
                </div>
            </Form>
            <Dialog
                style={{ minHeight: '20vh', minWidth: '20vw' }}
                contentStyle={{ minHeight: '20vh', minWidth: '20vw' }}
                visible={search.dialogOtpVisible}
                onHide={() => setSearchState({ ...search, dialogOtpVisible: false })}
            >
                <>
                    <Form
                        obj={search}
                        setObj={setSearch}
                        onSubmit={async e => {
                            e.preventDefault();
                            if (!idUUID) return;
                            let r = await S.health.prescription.sign({
                                idUUID,
                                systemCertifier: '',
                                otp: search.otp,
                                forceTwoWays: search.forceTwoWays === 1,
                            });
                            setSearchState({ ...search, dialogOtpVisible: false });
                            if (g_displayMessageError(messagesRef.current, r)) return;
                            getData();
                        }}
                    >
                        <GE
                            d="otp"
                            extra={{
                                regex: /\d{0,6}/g,
                            }}
                        />
                        <GSwitch d="forceTwoWays" disabled={hasFlag.current} />
                        <Button label="advance" wraperClass="p-col-4 p-sm-8" />
                    </Form>

                    <Dialog
                        visible={search.needsAddress}
                        onHide={() => {
                            if (search.needsAddress) {
                                setSearchState({ ...search, dialogOtpVisible: false, needsAddress: false });
                            }
                        }}
                        footer={
                            <div style={{ float: 'right' }}>
                                <Button
                                    type="button"
                                    label="continue"
                                    onClick={async e => {
                                        e.preventDefault();
                                        if (addressRef.current) {
                                            //TODO: improve message
                                            if (!addressRef.current.state.data.street || !addressRef.current.state.data.city) return;
                                            //TODO: improve a lot
                                            let r = await S.consignee.address.put({
                                                address: {
                                                    ...addressRef.current.state.data,
                                                    country: addressRef.current.state.data.country as any,
                                                    zipCode: Number((String(addressRef.current.state.data.zipCode) ?? '').replace(/[^\d]/g, '')),
                                                    referenceIdUUID: consignee.idUUID,
                                                    addressType: 1,
                                                    latitude: 0,
                                                    longitude: 0,
                                                },
                                                consigneeIdUUID: consignee.idUUID,
                                            });
                                            if (g_displayMessageError(messagesRef.current, r)) return;
                                            setSearchState({ ...search, needsAddress: false });
                                            //TODO: reload the consignee info
                                        }
                                    }}
                                />
                            </div>
                        }
                    >
                        <div>
                            <Address ref={e => (addressRef.current = e)} c={{ 0: true, 5: true }} />
                        </div>
                    </Dialog>
                </>
            </Dialog>

            <Dialog
                contentStyle={{ minHeight: '50vh', minWidth: '60vw' } as React.CSSProperties}
                onHide={() => setSearchState({ ...search, dialogNewVisible: false })}
                visible={search.dialogNewVisible}
            >
                <Form setObj={setSearch} obj={search} formRef={e => (medicineFormRef.current = e)}>
                    <Wraper id="medicine" class="p-col-3">
                        <div className="p-col">
                            <AutoComplete
                                id="medicine"
                                itemTemplate={itemTemplate}
                                value={search.selected}
                                field="dname"
                                suggestions={search.medecineList}
                                onChange={e => setSearchState({ ...search, selected: e.value })}
                                completeMethod={e => {
                                    let query = e.query;
                                    if (query.length < 3) {
                                        setSearchState({
                                            ...search,
                                            medecineList: [],
                                        });
                                        return;
                                    }
                                    preSearchRef.current = e.query;
                                    ((rSearch: string) => async () => {
                                        //Search for medicines
                                        let r = await S.health.medice.list({
                                            systemBase: '',
                                            filters: [pair('description', rSearch)],
                                        });
                                        let list = r.medecine.map(a => ({
                                            ...a,
                                            dname: `${first(checkNull(a.name))} ${checkNull(a.concentration ?? '')}${checkNull(a.unit ?? '')}, ${first(
                                                checkNull(a.pharmacyForm)
                                            )}(${a.quantityPerPackage ?? ''}un)`,
                                        }));
                                        if (g_displayMessageError(messagesRef.current, r) || rSearch !== preSearchRef.current) return;
                                        setSearchState({ ...search, medecineList: list });
                                    })(query)();
                                }}
                            />
                        </div>
                    </Wraper>
                    <GDiv grid group>
                        <GE
                            d="quantity"
                            extra={{
                                regex: /\d*/g,
                            }}
                            fClass="p-col-3"
                        />
                        <GDropdown
                            d="unit"
                            l=""
                            options={[
                                { label: ls('boxes', 'prescription'), value: ls('boxes', 'prescription') },
                                { label: ls('nounit', 'prescription'), value: '' },
                            ]}
                            fClass="p-col-3"
                        />
                    </GDiv>
                    <GArea d="howToTake" style={{ minHeight: '8em' }} />
                    <Button
                        type="submit"
                        label="add"
                        wraperClass="p-col-2"
                        onClick={async e => {
                            e.preventDefault();
                            if (!medicineFormRef.current?.reportValidity()) return;
                            let r;
                            if (typeof search.selected === 'string') {
                                r = await S.health.prescription.medicine.add({
                                    prescriptionMedicine: {
                                        dateCreation: '',
                                        dateUpdate: '',
                                        idUUID: '',
                                        status: 1,
                                        howToTake: search.howToTake,
                                        medicineDescription: search.selected,
                                        prescriptionIdUUID: idUUID ?? '',
                                        quantity: Number(search.quantity),
                                        unit: search.unit,
                                        ean: '',
                                        flag: '',
                                        medicinePresentation: '',
                                    },
                                });
                            } else {
                                r = await S.health.prescription.medicine.add({
                                    prescriptionMedicine: {
                                        dateCreation: '',
                                        dateUpdate: '',
                                        idUUID: '',
                                        status: 1,
                                        howToTake: search.howToTake,
                                        medicineDescription: search.selected?.dname ?? '',
                                        prescriptionIdUUID: idUUID ?? '',
                                        quantity: Number(search.quantity),
                                        unit: search.unit,
                                        ean: search.selected?.ean ?? '',
                                        flag: search.selected?.flag ?? '',
                                        medicinePresentation: search.selected?.presentation ?? '',
                                    },
                                });
                            }
                            // (search.selected?.name ?? '') + ' ' + (search.selected?.presentation ?? ''),
                            if (g_displayMessageError(messagesRef.current, r)) return;
                            setSearchState({
                                ...search,
                                quantity: '',
                                unit: ls('boxes', 'prescription'),
                                howToTake: '',
                                medecineList: [],
                                selected: null,
                                autoUpdate: '',
                                dialogNewVisible: false,
                            });
                            prescriptionMedicineRef.current?.getData();
                        }}
                    />
                </Form>
            </Dialog>
            <Dialog
                contentStyle={{ minHeight: '40vh', minWidth: '40vw' } as React.CSSProperties}
                onHide={() => setEmailState({ ...email, visible: false })}
                visible={email.visible}
            >
                <Form
                    setObj={setEmail}
                    obj={email}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (StringEmpty(obj.idUUID)) return;
                        let r = await S.health.prescription.email({
                            idUUID: obj.idUUID,
                            email: email.email,
                        });
                        g_displayMessageError(messagesRef.current, r);
                        setEmailState({ ...email, visible: false, email: consignee.email });
                    }}
                >
                    <GInput d="email" type="email" fClass="p-col-3" req />
                    <Button type="submit" label="send" wraperClass="p-col-2" />
                </Form>
            </Dialog>
        </>
    );
};
