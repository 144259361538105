import React, { Component } from 'react';
import { S, StateIcon, g_checkLoginACL, g_genericRequired, g_template_dataTable, g_hideSearch, g_getData, g_template_button, g_displayMessageError, g_openWindowDialog } from '../GenericFunctions';

export default class Payrool extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () => (this.props.context.state.idUUID ? g_getData(this, S.payroll.list, 'payrolls', {}, this.props.context.messages) : null);

    openDownload = async raw => {
        let response = await S.consignee.payroll.get(raw.year, raw.month, this.props.context.idUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        g_openWindowDialog(response.url, 'paycheck', 1000, 500);
    };

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        null,
                        [
                            { type: 'd', data: 'month' },
                            { type: 'd', data: 'year' },
                            { type: 'd', data: 'totalIncomigs' },
                            { type: 'd', data: 'totalDiscounts' },
                            { type: 'd', data: 'salaryNet' },
                            { type: 'date', data: 'dateCreation' },
                            {
                                type: 'di',
                                title: '',
                                align: 'center',
                                di: raw =>
                                    raw.payrollStatus === 1 ? (
                                        g_template_button('', 'pi pi-download', false, () => this.openDownload(raw), false, '')
                                    ) : (
                                        <StateIcon
                                            state={raw.payrollStatus}
                                            custom={{
                                                0: { class: 'pi-spin pi-spinner', color: 'grey' },
                                                2: { class: 'pi-cross', color: 'red' },
                                                3: { class: 'pi-circle-on', color: 'red' },
                                            }}
                                        />
                                    ),
                            },
                            { type: 'url', c: e.d === 1 || e.u === 1, url: raw => `#/payroll?type=1&id=${raw.idUUID}`, width: 1000, height: 500, callback: this.getData },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'consignee#payroll'
        );
    }
}
