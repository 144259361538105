/**
 * This file deals with the information about past calls
 * Acls used here are:
 *  - provider;
 *      -When this is called from the provider page
 *  - callinfo-management
 *      - When it's called from the it's page
 *      - Note: The delete and update flags control the edit button
 *
 */

import React from 'react';
import { CallRecord, CallRecordListFilters } from '../../../api-ts-bindings/Call';
import { pair, pairT } from '../../../api-ts-bindings/Generic';
import { Table, TableHeader, useAcl } from '../../../Generics';
import { Requests } from '../../../Requests';
import { ls } from '../../GenericFunctions';
import { states as CPstates } from '../contract-protector/ContractP';

/**
 * This is a function that formats the time
 * @param sec
 * @returns
 */
const formatTime = (sec: number) => {
    sec = Math.round(sec);
    let h = Math.floor(sec / 60 / 60);
    if (h > 0) sec = sec - h * 60 * 60;
    let min = Math.floor(sec / 60);
    if (min > 0) sec = sec - min * 60;
    return `${h > 0 ? h + ':' : ''}${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
};

interface CallInfoManagementProps {
    filters?: pairT<string, CallRecordListFilters>[];
}
/**
 * This is the component that has the table that contains the past calls
 *  - filters let's you pass some filters for the call
 *      - if no filter is passed then it automatically filters by workingEntityIdUUID and by the user idUUID
 * @param param0 {@see CallInfoManagementProps}
 * @returns
 */
export const CallInfoManagement = ({ filters }: CallInfoManagementProps) => {
    let { error, user, profile: p } = useAcl({});

    return (
        error ?? (
            <Table<CallRecord, CallRecordListFilters, string, 'callRecords'>
                result="callRecords"
                request={data => {
                    if (filters) data.filters = [...(data.filters ?? []), ...filters];
                    else data.filters = [pair('destinationIdUUID', user!.uuid)];
                    return Requests.call.list(data);
                }}
                header={<TableHeader />}
                title="callsInfo"
                children={[
                    { t: 'd', data: 'originName', sort: true, filter: true },
                    { t: 'd', data: 'destinationName', sort: true, filter: true },
                    { t: 'date', data: 'dateCreation', sort: true, filter: true },
                    { t: 'date', data: 'dateStartCall', sort: true, filter: true },
                    { t: 'date', data: 'dateEndCall', sort: true, filter: true },
                    {
                        t: 'di',
                        data: 'duration',
                        di: raw => (raw.dateStartCall && raw.dateEndCall ? formatTime((new Date(raw.dateEndCall).getTime() - new Date(raw.dateStartCall).getTime()) / 1000) : ''),
                    },
                    {
                        t: 'di',
                        data: 'waitTime',
                        di: raw =>
                            raw.dateStartQueueWait && raw.dateEndQueueWait
                                ? formatTime((new Date(raw.dateEndQueueWait).getTime() - new Date(raw.dateStartQueueWait).getTime()) / 1000)
                                : '',
                    },
                    { t: 'd', title: ls('state', 'dataTableGeneric'), data: 'callRecordStatusDescription' },
                    { t: 'state', title: ls('contractState', 'dataTableGeneric'), data: 'contractStatus', states: () => CPstates },
                    {
                        t: 'url',
                        icon: 'pi pi-file-o',
                        title: ls('medicalRecord'),
                        class: '',
                        url: raw => `#/oldCall?type=1&id=${raw.contractIdUUID}`,
                        show: (p!.ub || p!.db) && !filters,
                    },
                ]}
            />
        )
    );
};
