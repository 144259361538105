export * from './Entity';

export interface Country {
	code: number;
	emojiFlag: string;
	internationalCode: number;
	internetPrefix: string;
	name: string;
	prefix: string;
}

export enum EntityType {
	cpf = 1,
	company = 2
}
