import React from 'react';
import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import GSelector from '../generic/GSelector';
import HelpService from '../../service/HelpService';
import {
    S,
    lt,
    lm,
    ls,
    lb,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_input,
    g_wraper,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class Help extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                dateCreation: '',
                dateUpdate: '',
                description: '',
                title: '',
                languageTag: '',
                idUUID: '',
            },
            items: [{ label: lt('help') }],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ idUUID: id, mode: mode }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
        };
        let response = await S.help.list(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.helps[0]) {
            g_nullarrayresponse(this);
            return;
        }
        this.setState({
            data: response.helps[0],
            items: [{ label: lt('help') }, { label: lt('log'), icon: 'pi pi-list' }],
            activeItem: null,
        });
    };

    deleteM = async e => {
        e.preventDefault();
        if (!this.state.idUUID) return;
        let response = await HelpService.remove(this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        window.close();
    };

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.idUUID) {
            let response = await HelpService.update({ help: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: lm('simpleSave') });
            this.getData();
        } else {
            let response = await HelpService.add({ help: this.state.data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: lm('simpleCreate') });
            this.setState({ idUUID: response.help.idUUID }, this.getData);
        }
    };

    editorChange = e => {
        let data = this.state.data;
        data.description = e.htmlValue;
        this.setState({ data: data });
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        {g_template_input(this, 'title', this.state.data.title, ls('title'), this.state.mode, 0, 1, 'p-col-6', 'p-col-2')}
                                        <GSelector
                                            labelwraper="p-col-2"
                                            label={ls('consignatorName')}
                                            ref={e => (this.selector0 = e)}
                                            getData={S.consignator.processed.list}
                                            tableBody={[
                                                { type: 'd', data: 'name', sortable: true, filter: true },
                                                {
                                                    type: 'btt',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector0.setState({ selected: raw.name, visible: false });
                                                        this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                                    },
                                                },
                                            ]}
                                            inputtextwarper="p-col-5"
                                            disabled={this.state.mode}
                                            startV={this.state.data.consignatorName}
                                        />
                                        {g_wraper(
                                            `description`,
                                            ls('description'),
                                            <Editor
                                                className="p-col-12"
                                                style={{ height: '20em' }}
                                                disabled={this.state.mode}
                                                required={true}
                                                value={this.state.data.description}
                                                onTextChange={e => this.editorChange(e)}
                                                id="description"
                                            />,
                                            false,
                                            `p-col-2`
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_wraper(null, ls('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_wraper(null, ls('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', 0, null, this.state.mode, 'p-col-1')}
                                {g_template_dialog(
                                    this,
                                    lm('askBlockP'),
                                    'delete',
                                    this.deleteM,
                                    lb('block'),
                                    !this.state.idUUID,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2'
                                )}
                            </div>
                        </form>,
                        <Log idUUID={this.state.idUUID} logContextType={16} />,
                    ])}
                </div>
            );
        });
    }
}
