import React, { Component } from 'react';
import ConfigService from '../../service/ConfigService';
import { Messages } from 'primereact/messages';
import { LS, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable, g_template_button } from '../GenericFunctions';

export default class ConfigContextManagement extends Component {
    constructor() {
        super();
        this.state = { ...g_genericRequired([{ key: 'tag', value: 'desc' }]), id: null };
    }
    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        g_hideSearch();
        this.setState({ id: id }, this.getData);
    }
    componentDidUpdate() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        if (this.state.id !== id) this.setState({ id: id, pageNumber: 1, recordsTotal: 0 }, this.getData);
    }
    getData = () => (this.state.id ? g_getData(this, ConfigService.getConfigList, 'configs', { filters: [...this.state.filter, { key: 'context', value: this.state.id }] }) : null);
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>
                    {LS('configContextManagement', 'titles')} {this.state.id}
                </h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, `#/config-contex?type=1&context=${this.state.id}`, 'addnewconfigcontex', this.getData, 1100, 600, e.c === 1),
                    [
                        { type: 'd', data: 'tag', sortable: true, filter: true },
                        { type: 'd', data: 'value', filter: true },
                        {
                            type: 'di',
                            align: 'center',
                            title: 'Ajuda',
                            di: raw => (!raw.help ? null : g_template_button('', 'pi pi-question', false, () => { }, false, '', 'p-button-rounded', raw.help)),
                        },
                        { type: 'url', url: raw => `#/config-contex?type=1&id=${raw.idUUID}&context=${raw.context}`, width: 1100, height: 600, callback: this.getData, c: e.d === 1 || e.u === 1 },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
