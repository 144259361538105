import type { RequestsClass } from '../../Requests';
import { list } from './list';
import { list as pstList } from './productSubType';
import { ProductApiProductType } from './ProductType';

export * from './list';

const baseUrl = '/product';

export const ProductApi = (req: RequestsClass) => ({
	list: list(req, baseUrl),

	productSubType: {
		list: pstList(req, baseUrl)
	},

	productType: ProductApiProductType(req, baseUrl)
});
