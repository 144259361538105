import type { StringDate, UUID } from '../Generic';
import type { FilterDateType } from '../Requests';

export interface UserAssociatedEntity {
	dateCreation: string;
	dateUpdate: string;
	entityIdUUID: string;
	entityName: string;
	entityUserStatus: number;
	entityUserType: number;
	idUUID: string;
	userIdUUID: string;
	username: string;
}

export enum UserType {
	APP = 0,
	BO = 1,
	WS = 2
}

export interface ACLItem {
	c: number;
	d: number;
	r: number;
	tag: string;
	type: number;
	u: number;
}

export type UserListFilters =
	| 'entitiesIdUUID'
	| 'consignatorIdUUID'
	| 'consignatorsIdUUID'
	| 'fullname'
	| 'iduuid'
	| FilterDateType<'dateCreation'>;

export interface UserCreate {
	userType: UserType;
	applicationId: string;

	username?: string;
	email?: string;
	fullname?: string;
	phoneMobile?: string;
	phone?: string;
}

export interface UserModel extends UserCreate {
	appVersion: string;
	associatedEntities: UserAssociatedEntity[];
	dateCreation: string;
	dateLastLogin: string;
	dateLastPasswordChange: string;
	dateLastUserLock: string;
	dateLegalAcceptance: string;
	dateMobileEnrollment: string;
	dateUpdate: string;
	deviceBrand: string;
	deviceId: string;
	deviceModel: string;
	deviceType: string;
	idUUID: string;
	languageId: string;
	legalAcceptanceStatus: number;
	mobileEnrollmentStatus: number;
	passwordRetries: number;
	profileACLs: ACLItem[];
	userStatus: number;

	email: string;
	username: string;
	fullname: string;
	phoneMobile: string;
	photo: string;
}

/*{
	"username": "string",
	"fullname": "string",
	"email": "string",
	"phoneMobile": "string",
	"legalAcceptanceStatus": 0,
	"mobileEnrollmentStatus": 0,
	"deviceBrand": "string",
	"deviceType": "string",
	"deviceModel": "string",
	"deviceId": "string",
	"appVersion": "string",
	"languageId": "string",
  },*/

export interface UserSSO {
	//TODO: check this
	provider: string;
	token: string;
}

export type UserCreateRequest =
	| {
			user: UserCreate;

			// This must be encrypted
			password: string;
			// This must be encrypted
			pin: string;

			//TODO: check this
			notificationChannel?: null;
	  }
	| {
			sso: UserSSO;
	  };

export enum AuthenticationMethod {
	password = 0,
	pin = 1
}

export type UserLoginRequest = {
	username: string;

	appVersion?: string;
	applicationId?: string;
	authenticationMethod?: AuthenticationMethod;
	languageId?: string;
	password?: string;
	tokenExpiration?: number;

	userType: UserType;

	deviceBrand?: string;
	deviceId?: string;
	deviceModel?: string;
	deviceType?: string;
};

export interface UserUpdateRequest {
	user: {
		idUUID: UUID;
		username?: string;
		fullname?: string;
		email?: string;
		phoneMobile?: string;
		userType?: UserType;
		deviceBrand?: string;
		deviceType?: string;
		deviceModel?: string;
		deviceId?: string;
		appVersion?: string;
		languageId?: string;
		applicationId?: string;
	};
	dateBirthDate?: StringDate;
}
