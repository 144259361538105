import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import BatchService from '../../service/BatchService';
import ConsignatorService from '../../service/ConsignatorService';
import PayrollService from '../../service/PayrollSerive';
import ProductService from '../../service/ProductService';
import GSelector from '../generic/GSelector';
import {
    pair,
    StateIcon,
    LS,
    g_template_dropdown,
    g_template_button,
    g_openWindowDialog,
    g_genericRequired,
    g_hideSearch,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_ChangeStatus,
    g_checkLoginACL,
    g_displayMessageError,
    g_entityFilter,
    g_getData,
    g_tabViewRenderOptions,
    g_template_form,
    g_checkAlc,
} from '../GenericFunctions';
import Log from '../logs/Log';
import { PayrollControlBatchs, PayrollList, Movements } from './payrollControlBatchs';

export const states = {
    0: { class: 'pi-step-forward', color: 'orange' },
    1: { class: 'pi-user', color: 'orange' },
    2: { class: 'pi-user', color: 'blue' },
    3: { class: 'pi-dollar', color: 'orange' },
    4: { class: 'pi-dollar', color: 'blue' },
    5: { class: 'pi-align-justify', color: 'orange' },
    6: { class: 'pi-align-justify', color: 'blue' },
    7: { class: 'pi-file-o', color: 'orange' },
    8: { class: 'pi-file-o', color: 'blue' },
    9: { class: 'pi-check', color: 'green' },
    10: { class: 'pi-trash', color: 'red' },
};

export default class BatchsEdit extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                consignatorIdUUID: '',
                consignatorName: '',
                dateCreation: '',
                dateUpdate: '',
                idUUID: '',
                month: new Date().getMonth() + 1,
                payrollControlBatchs: [],
                payrollControlStatus: 0,
                year: new Date().getFullYear(),
            },
            items: [{ label: LS('batchsEdit', 'tab') }],
            idUUID: null,
            productIdUUID: null,
            registerCountTotal: 0,
            registerCountSucess: 0,
            marginCountTotal: 0,
            marginCountSucess: 0,
            movementsCountTotal: 0,
            movementsCountSucess: 0,
            payrollCountTotal: 0,
            payrollCountSucess: 0,
            skipedregister: true,
            skipedmargin: true,
            marginType: '0',
            tbs: [],
        };
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        this.setState(
            {
                items: [
                    { label: LS('batchsEdit', 'tab') },
                    ...(idUUID
                        ? [
                              ...(g_checkAlc(4, 'batchs#payroll') ? [{ label: LS('payroll', 'tab') }] : []),
                              ...(g_checkAlc(4, 'batchs#payroll') ? [{ label: LS('movements', 'tab') }] : []),
                              { label: LS('generatedArchives', 'tab') },
                              { label: LS('attachments', 'tab') },
                              { label: LS('log', 'tab'), icon: 'pi pi-list' },
                          ]
                        : []),
                ],
                mode: query.get('type') !== '1',
                idUUID: idUUID,
            },
            this.getData
        );
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(() => ([2, 4, 6, 8].indexOf(this.state.data.payrollControlStatus) === -1 ? null : this.getData(true)), 30000);
    }

    getStepModel = () => [
        { label: LS('0', 'batchsEditSteps') },
        { label: LS('1', 'batchsEditSteps') },
        { label: LS('2', 'batchsEditSteps') },
        { label: LS('3', 'batchsEditSteps') },
        { label: LS('4', 'batchsEditSteps') },
        { label: LS('5', 'batchsEditSteps') },
        { label: LS('6', 'batchsEditSteps') },
        { label: LS('7', 'batchsEditSteps') },
        { label: LS('8', 'batchsEditSteps') },
        { label: LS('9', 'batchsEditSteps') },
    ];

    gchangeStatus = status =>
        g_ChangeStatus(this, () => PayrollService.controlUpdate({ idUUID: this.state.idUUID, payrollControlStatus: status }), this.getData, 'Estado alterado com sucesso');

    click = e => {
        e.preventDefault();
        document.getElementById('file').click();
    };

    addMovemnts = e => {
        e.preventDefault();
        if (this.state.productIdUUID) document.getElementById('file').click();
    };

    addMargins = e => {
        e.preventDefault();
        if (this.state.marginType) document.getElementById('file').click();
    };

    addRegister = e => {
        e.preventDefault();
        if (this.state.marginType) document.getElementById('file').click();
    };

    getProducts = context => g_getData(context, ProductService.getProducts, 'products');

    block = e => {
        e.preventdefault();
        this.gchangeStatus(10);
    };

    skipMargin = e => {
        e.preventDefault();
        if (this.state.data.payrollControlStatus === 3) this.gchangeStatus(5);
    };

    closeMovements = e => {
        e.preventDefault();
        if (this.state.data.payrollControlStatus === 5) this.gchangeStatus(6);
    };

    skipRegister = e => {
        e.preventDefault();
        if (this.state.data.payrollControlStatus === 1) this.gchangeStatus(3);
    };

    indexMapper = () => this.state.data.payrollControlStatus;

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.idUUID) return;
        if (!this.state.data.consignatorIdUUID) return;
        let data = { payrollControl: this.state.data };
        let r = await PayrollService.controlAdd(data);
        if (g_displayMessageError(this.messages, r)) return;
        window.location.href = `/#/batchsedit?type=1&id=${r.payrollControl.idUUID}`;
        window.location.reload();
    };

    getData = async (back = false) => {
        if (!this.state.idUUID) return;
        const response = await PayrollService.getControl(this.state.idUUID, back);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.payrollControl;
        let registerCountTotal = 0;
        let registerCountSucess = 0;
        let marginCountTotal = 0;
        let marginCountSucess = 0;
        let movementsCountTotal = 0;
        let movementsCountSucess = 0;
        let payrollCountTotal = 0;
        let payrollCountSucess = 0;
        let skipedregister = true;
        let skipedmargin = true;
        let errorRegister = false;
        let errorMargin = false;
        let errorMovements = false;
        let errorPayroll = false;
        rdata.payrollControlBatchs.forEach(e => {
            if (e.batchServiceIdUUID === '5edc7e99-ce5c-68aa-97e1-6f1699550c89') {
                errorRegister = e.batchStatus === 3 || e.batchStatus === 4;
                skipedregister = false;
                registerCountTotal = e.recordProcessedCount;
                registerCountSucess = e.recordProcessedCount - e.recordErrorCount;
            } else if (e.batchServiceIdUUID === '4f491300-f859-f49e-c8a0-db59764ea41c') {
                errorMargin = e.batchStatus === 3 || e.batchStatus === 4;
                skipedmargin = false;
                marginCountTotal += e.recordProcessedCount;
                marginCountSucess += e.recordProcessedCount - e.recordErrorCount;
            } else if (e.batchServiceIdUUID === '2b81a56a-780b-bf8f-b00d-038f01b88c1a') {
                errorMovements = e.batchStatus === 3 || e.batchStatus === 4;
                movementsCountTotal = e.recordProcessedCount;
                movementsCountSucess = e.recordProcessedCount - e.recordErrorCount;
            } else if (e.batchServiceIdUUID === '4663be4a-3f28-e780-5275-9e9b29c9cef6') {
                errorPayroll = e.batchStatus === 3 || e.batchStatus === 4;
                payrollCountTotal = e.recordProcessedCount;
                payrollCountSucess = e.recordProcessedCount - e.recordErrorCount;
            }
        });
        this.setState({
            data: rdata,
            registerCountTotal: registerCountTotal,
            registerCountSucess: registerCountSucess,
            marginCountTotal: marginCountTotal,
            marginCountSucess: marginCountSucess,
            movementsCountTotal: movementsCountTotal,
            movementsCountSucess: movementsCountSucess,
            payrollCountTotal: payrollCountTotal,
            payrollCountSucess: payrollCountSucess,
            skipedregister: skipedregister,
            skipedmargin: skipedmargin,
            errorMargin: errorMargin,
            errorMovements: errorMovements,
            errorPayroll: errorPayroll,
            errorRegister: errorRegister,
        });
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadHandler = async file => {
        if (!this.state.data.consignatorIdUUID) return;
        if (!this.state.idUUID) return;
        const formData = new FormData();
        formData.append('file', file);
        let batchIdUUID = '';
        switch (this.state.data.payrollControlStatus) {
            case 1:
                batchIdUUID = '5edc7e99-ce5c-68aa-97e1-6f1699550c89';
                break;
            case 3:
                batchIdUUID = '4f491300-f859-f49e-c8a0-db59764ea41c';
                break;
            case 5:
                batchIdUUID = '2b81a56a-780b-bf8f-b00d-038f01b88c1a';
                break;
            case 7:
                batchIdUUID = '4663be4a-3f28-e780-5275-9e9b29c9cef6';
                break;
            default:
                return;
        }
        var response = await PayrollService.controlAddBatch(formData, batchIdUUID, this.state.idUUID, this.state.productIdUUID ?? '-', this.state.marginType ?? 0);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        if (this.state.productIdUUID) {
            this.setState({ productIdUUID: null });
            this.selector1.setState({ selected: '' });
        }
        if (this.state.marginType) this.setState({ marginType: '0' });
        this.messages.show({ severity: 'success', summary: LS('simpleUpload', 'messages') });
        this.setState(
            {
                dialog_state_addMargins: false,
                dialog_state_addMovements: false,
                activeItem: null,
            },
            this.getData
        );
        document.getElementById('file').value = '';
    };

    getConsignators = context =>
        g_getData(
            context,
            data => {
                let f = g_entityFilter(context.state.filter, 'idUUID', 'idUUIDs', 6, 'batchs#accessall');
                if (!f) return { consignators: [], code: 0, recordsTotal: 0, pageTotal: 0 };
                data.filters = f[0];
                return ConsignatorService.listConsignator(data);
            },
            'consignators'
        );

    generateMovementArchives = async e => {
        if (!this.state.idUUID) return;
        e.preventDefault();
        let value = 1;
        const p1 = await ConsignatorService.parameterList({ filters: [pair('referenceIdUUID', this.state.data.consignatorIdUUID), pair('name', 'VERSION_MOVEMENT_OUT')] });
        if (!g_displayMessageError(null, p1)) value = p1.parameters[0]?.parameterValue ?? value;
        let response = await BatchService.add({
            batch: {
                contextFiller: `${this.state.idUUID}:${value}`,
                executeFillerA: this.state.data.consignatorIdUUID,
                executeFillerB: null,
                batchServiceIdUUID: '5e453768-d731-b2bd-417d-670be3b9b49c',
                controlIdUUID: this.state.idUUID,
            },
        });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', message: LS('generateArchives', 'messages') });
    };

    note = () => {
        let text = {
            1: LS('1', 'batchesNote'),
            5: LS('5', 'batchesNote'),
        };
        let r = { t: 'view', cc: 8, d: text[this.state.data.payrollControlStatus] };
        return [1, 5].indexOf(this.state.data.payrollControlStatus) !== -1 ? r : null;
    };

    dataTable = header => {
        if (!this.state[header]) return null;
        switch (header) {
            case 'Cadrasto':
                return <PayrollControlBatchs bIDUUID="5edc7e99-ce5c-68aa-97e1-6f1699550c89" context={this} />;
            case 'Margem':
                return <PayrollControlBatchs bIDUUID="4f491300-f859-f49e-c8a0-db59764ea41c" context={this} />;
            case 'Movimentos':
                return <PayrollControlBatchs bIDUUID="2b81a56a-780b-bf8f-b00d-038f01b88c1a" context={this} />;
            case 'Folha':
                return <PayrollControlBatchs bIDUUID="4663be4a-3f28-e780-5275-9e9b29c9cef6" context={this} />;
            default:
                return null;
        }
    };

    infocard = (label, val1, val2, state, display) =>
        display ? null : (
            <div className="p-grid">
                <div style={{ fontSize: '2em' }} className="p-col-3">
                    {label}
                </div>
                <div style={{ fontSize: '2em' }} className="p-col-2">
                    {val1 < 0 ? 0 : val1}
                </div>
                <div style={{ fontSize: '2em' }} className="p-col-2">
                    /
                </div>
                <div style={{ fontSize: '2em' }} className="p-col-2">
                    {val2 < 0 ? 0 : val2}
                </div>
                <div className="p-col-1">
                    <StateIcon
                        state={state}
                        custom={{
                            0: { class: 'pi-spinner pi-spin', color: 'gray' },
                            1: { class: 'pi-check', color: 'green' },
                            2: { class: 'pi-step-forward', color: 'grey' },
                            3: { class: 'pi-upload', color: 'orange' },
                            4: { class: 'pi-times', color: 'red' },
                            5: { class: 'pi-check', color: 'orange' },
                        }}
                    />
                </div>
                <div className="p-col-1">
                    <StateIcon
                        state={this.state[label] ? 1 : 0}
                        custom={{ 0: { class: 'pi pi-eye', color: 'blue' }, 1: { class: 'pi-eye-slash', color: 'blue' } }}
                        onClick={e => {
                            if (this.state.mode) return;
                            e.preventDefault();
                            let state = {};
                            state[label] = !this.state[label];
                            this.setState(state);
                        }}
                    />
                </div>
            </div>
        );

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            let footer = (
                <div>
                    {g_template_button(LS('upload', 'btt'), '', 0, null, 0, '', 'p-button-danger')}
                    {g_template_button(
                        LS('cancel', 'btt'),
                        '',
                        0,
                        e => {
                            e.preventDefault();
                            this.setState({ dialog_state_addMovements: false, dialog_state_addMargins: false });
                        },
                        0,
                        ''
                    )}
                </div>
            );
            return (
                <div className="card card-w-title">
                    <input id="file" type="file" accept=".csv,.dat,.xls,.xlsx,.txt" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                <GSelector
                                    labelwraper="p-col-1"
                                    label={LS('consignatorName')}
                                    ref={e => (this.selector0 = e)}
                                    getData={this.getConsignators}
                                    gobtt={g_checkAlc(4, 'consignator') && this.state.data.consignatorIdUUID}
                                    jump={() => g_openWindowDialog(`#/consignator?type=1&id=${this.state.data.consignatorIdUUID}`, this.state.data.consignatorIdUUID, 900, 600)}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector0.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                            },
                                            icon: 'pi pi-plus',
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    req={true}
                                    disabled={this.state.mode || this.state.idUUID}
                                    startV={this.state.data.consignatorName}
                                    tToF={{ 0: 'name' }}
                                />,
                                { t: 'array', origin: this.state.data, filter: [['month', 'year']], dTypeF: this.state.idUUID ? 'view' : 't', cc: 1 },
                                { t: 'sep' },
                                this.infocard(
                                    'Cadrasto',
                                    this.state.registerCountSucess,
                                    this.state.registerCountTotal,
                                    this.state.data.payrollControlStatus === 1
                                        ? 3
                                        : this.state.data.payrollControlStatus === 2
                                        ? 0
                                        : this.state.errorRegister
                                        ? 4
                                        : this.state.skipedregister
                                        ? 2
                                        : this.state.registerCountSucess !== this.state.registerCountTotal
                                        ? 5
                                        : 1,
                                    this.state.data.payrollControlStatus < 1
                                ),
                                this.dataTable('Cadrasto'),
                                this.infocard(
                                    'Margem',
                                    this.state.marginCountSucess,
                                    this.state.marginCountTotal,
                                    this.state.data.payrollControlStatus === 3
                                        ? 3
                                        : this.state.data.payrollControlStatus === 4
                                        ? 0
                                        : this.state.errorMargin
                                        ? 4
                                        : this.state.skipedmargin
                                        ? 2
                                        : this.state.marginCountSucess !== this.state.marginCountTotal
                                        ? 5
                                        : 1,
                                    this.state.data.payrollControlStatus < 3
                                ),
                                this.dataTable('Margem'),
                                this.infocard(
                                    'Movimentos',
                                    this.state.movementsCountSucess,
                                    this.state.movementsCountTotal,
                                    this.state.data.payrollControlStatus === 5
                                        ? 3
                                        : this.state.data.payrollControlStatus === 6
                                        ? 0
                                        : this.state.errorMovements
                                        ? 4
                                        : this.state.movementsCountSucess !== this.state.movementsCountTotal
                                        ? 5
                                        : 1,
                                    this.state.data.payrollControlStatus < 5
                                ),
                                this.dataTable('Movimentos'),
                                this.infocard(
                                    'Folha',
                                    this.state.payrollCountSucess,
                                    this.state.payrollCountTotal,
                                    this.state.data.payrollControlStatus === 7
                                        ? 3
                                        : this.state.data.payrollControlStatus === 8
                                        ? 0
                                        : this.state.errorPayroll
                                        ? 4
                                        : this.state.payrollCountSucess !== this.state.payrollCountTotal
                                        ? 5
                                        : 1,
                                    this.state.data.payrollControlStatus < 7
                                ),
                                this.dataTable('Folha'),
                                { t: 'sep' },
                                this.note(),
                            ],
                            mode => [
                                { t: 'b', l: LS('create', 'btt'), click: null, c: this.state.idUUID },
                                {
                                    t: 'b',
                                    class: 2,
                                    l: LS('addRegister', 'btt'),
                                    click: () => this.setState({ dialog_state_addRegister: true, marginType: '0' }),
                                    c: this.state.data.payrollControlStatus !== 1,
                                },
                                {
                                    t: 'dil',
                                    bttclass: 'p-button-warning',
                                    c: this.state.data.payrollControlStatus !== 1,
                                    text: LS('skipRegister', 'messages'),
                                    id: 'skipRegisterDil',
                                    onConfirm: this.skipRegister,
                                    l: LS('skipRegister', 'btt'),
                                    class: 2,
                                },
                                {
                                    t: 'b',
                                    class: 2,
                                    l: LS('addMargin', 'btt'),
                                    click: () => this.setState({ dialog_state_addMargins: true, marginType: '0' }),
                                    c: this.state.data.payrollControlStatus !== 3,
                                },
                                {
                                    t: 'dil',
                                    bttclass: 'p-button-warning',
                                    c: this.state.data.payrollControlStatus !== 3,
                                    text: LS('skipMargin', 'messages'),
                                    id: 'skipMarginDil',
                                    onConfirm: this.skipMargin,
                                    l: LS('skipMargin', 'btt'),
                                    class: 2,
                                },
                                {
                                    t: 'b',
                                    class: 2,
                                    l: LS('addMovements', 'btt'),
                                    click: () => this.setState({ dialog_state_addMovements: true, productIdUUID: null }, () => this.selector1.setState({ selected: '' })),
                                    c: this.state.data.payrollControlStatus !== 5,
                                },
                                {
                                    t: 'b',
                                    class: 2,
                                    extra: 'p-button-warning',
                                    l: LS('skipMovements', 'btt'),
                                    click: this.closeMovemnts,
                                    c: this.state.data.payrollControlStatus !== 5,
                                },
                                { t: 'b', class: 2, l: LS('addSheet', 'btt'), click: this.addFolha, c: this.state.data.payrollControlStatus !== 7 },
                                { t: 'b', class: 2, l: LS('genererateArchives', 'btt'), click: this.generateMovementArchives, c: !this.state.idUUID },
                                {
                                    t: 'dil',
                                    c: this.state.idUUID === null,
                                    disabled: mode || e.d !== 1,
                                    text: LS('askRemoveSheet', 'messages'),
                                    id: 'accessItemStatusBloquear',
                                    onConfirm: this.block,
                                    l: LS('eliminate', 'btt'),
                                    bttclass: 'p-button-danger',
                                },
                            ],
                            () => [
                                [
                                    {
                                        t: 'array',
                                        origin: this.state.data,
                                        filter: ['payrollControlStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        ddless: true,
                                        class: 'l',
                                        states: { 0: states },
                                    },
                                ],
                                [],
                                this.state.idUUID ? [<Steps model={this.getStepModel()} activeIndex={this.indexMapper()} />] : [],
                            ],
                            e => this.onSubmit(e),
                            1,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '67vh',
                            e => (this.f = e)
                        ),
                        ...(g_checkAlc(4, 'batchs#payroll') ? [<PayrollList context={this} />] : []),
                        ...(g_checkAlc(4, 'batchs#payroll') ? [<Movements context={this} />] : []),
                        <GenetedBatchs context={this} />,
                        <PayrollControlBatchs context={this} />,
                        <Log idUUID={this.state.idUUID} logContextType={20} />,
                    ])}
                    <form onSubmit={this.addMovemnts}>
                        <Dialog
                            visible={this.state.dialog_state_addMovements}
                            onHide={() => {
                                let toSet = {};
                                toSet['dialog_state_addMovements'] = false;
                                this.setState(toSet);
                            }}
                            footer={footer}
                            style={{ width: '60vw', height: '20vh', overflowX: 'none' }}
                            header={LS('add', 'titles')}
                        >
                            <div className="p-grid p-fluid p-col-12" style={{ overflowX: 'none', minWidth: '55vw', minHeight: '10vh' }}>
                                <div className="p-col-12 p-grid p-fluid">
                                    <GSelector
                                        style={{ width: '100%' }}
                                        labelwraper="p-col-2"
                                        label={LS('productName')}
                                        ref={e => (this.selector1 = e)}
                                        getData={this.getProducts}
                                        gobtt={g_checkAlc(4, 'product') && this.state.productIdUUID}
                                        jump={() => g_openWindowDialog(`#/product?type=1&id=${this.state.productIdUUID}`, this.state.productIdUUID, 1200, 600)}
                                        tableBody={[
                                            { type: 'd', data: 'name', sortable: true, filter: true },
                                            {
                                                type: 'button',
                                                icon: 'pi pi-plus',
                                                click: (raw, e) => {
                                                    e.preventDefault();
                                                    this.selector1.setState({ selected: raw.name, visible: false });
                                                    this.setState({ productIdUUID: raw.idUUID });
                                                },
                                            },
                                        ]}
                                        inputtextwarper="p-col"
                                        divinitclassname={'p-col-12'}
                                        buttonwarper={'p-col-2'}
                                        req={this.state.data.payrollControlStatus === 5}
                                        disabled={this.state.mode}
                                        startV={this.state.data.productName}
                                        tToF={{ 0: 'name' }}
                                        hidden={this.state.data.payrollControlStatus !== 5}
                                    />
                                </div>
                            </div>
                        </Dialog>
                    </form>
                    <form onSubmit={this.addMargins}>
                        <Dialog
                            visible={this.state.dialog_state_addMargins}
                            onHide={() => {
                                let toSet = {};
                                toSet['dialog_state_addMargins'] = false;
                                this.setState(toSet);
                            }}
                            footer={footer}
                            style={{ width: '60vw', minheight: '20vh', overflowX: 'none' }}
                            header={LS('add', 'titles')}
                        >
                            <div className="p-fluid" style={{ overflowX: 'none', minWidth: '55vw', minHeight: '20vh' }}>
                                {g_template_dropdown(
                                    this,
                                    'marginType',
                                    this.state.marginType,
                                    LS('marginType'),
                                    [
                                        { label: LS('0', 'marginTypes'), value: '0' },
                                        { label: LS('1', 'marginTypes'), value: '1' },
                                    ],
                                    false,
                                    false,
                                    true,
                                    'p-col-5',
                                    'p-col-1',
                                    false,
                                    '',
                                    { data: 'marginType' }
                                )}
                            </div>
                        </Dialog>
                    </form>
                    <form onSubmit={this.addRegister}>
                        <Dialog
                            visible={this.state.dialog_state_addRegister}
                            onHide={() => {
                                let toSet = {};
                                toSet['dialog_state_addRegister'] = false;
                                this.setState(toSet);
                            }}
                            footer={footer}
                            style={{ width: '60vw', minheight: '20vh', overflowX: 'none' }}
                            header={LS('add', 'titles')}
                        >
                            <div className="p-fluid" style={{ overflowX: 'none', minWidth: '55vw', minHeight: '20vh' }}>
                                {g_template_dropdown(
                                    this,
                                    'marginType',
                                    this.state.marginType,
                                    LS('marginType'),
                                    [
                                        { label: LS('0', 'marginTypes'), value: '0' },
                                        { label: LS('1', 'marginTypes'), value: '1' },
                                    ],
                                    false,
                                    false,
                                    true,
                                    'p-col-5',
                                    'p-col-1',
                                    false,
                                    '',
                                    { data: 'marginType' }
                                )}
                            </div>
                        </Dialog>
                    </form>
                </div>
            );
        });
    }
}

export class GenetedBatchs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), pageSize: this.props.bIDUUID ? 50 : 10 };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, BatchService.batchList, 'batchs', {
                  filters: [
                      ...this.state.filter,
                      { key: 'controlIdUUID', value: this.props.context.state.idUUID },
                      { key: 'batchServiceIdUUID', value: '5e453768-d731-b2bd-417d-670be3b9b49c' },
                  ],
              })
            : null;

    render() {
        return g_checkLoginACL(
            () => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        this.props.bIDUUID ? '' : g_getHeaderTemplatecF(this, () => {}, this.getData, false),
                        [
                            { type: 'd', data: 'originalFilename', sortable: true, filter: true },
                            { type: 'd', data: 'userFullname' },
                            { type: 'date', data: 'dateExecution', sortable: true, filter: true },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                            { type: 'state', data: 'batchStatus', states: states },
                        ],
                        this.getData
                    )}
                </div>
            ),
            false
        );
    }
}
