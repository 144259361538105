import type {
	CampaignListFilters,
	CampaignStatus,
	ConsigneeDocumentType,
	UserListFilters,
	UserLoginRequest,
	UserModel,
	UserType,
	UserResetPinRequest,
	ContractTemplateField,
	ContractCreateModel,
	ContractInstallments,
	ContractModel,
	ContractTemplateFormsModel,
	ContractTemplateFormsListFiltersList,
	ContractTemplateModel,
	ContractGroupByContractTemplate,
	ContractGroupByWorkingEntity,
	DocumentModel,
	DocumentModelStatus,
	ConsigneeStatingAdd,
	UserCreateRequest,
	UserEntityAddRequest,
	UserEntity,
	ShiftDumpRequest,
	ShiftResponse,
	AddressModel,
	UserUpdateRequest,
	EntityContractTemplate,
	ContractCreatePaymentData
} from '.';
import { GroupsApi, ProductApi, TicketApi, UserApi } from '.';
import type { BusinessHoursModel } from './BusinessHours';
import type { CallRecord, CallRecordListFilters } from './Call';
import type { CampaignModel } from './Campaigns';
import type { CardModel } from './Cards';
import type { ConfigModel } from './Configs';
import type { ClockingLocationModel, ConsignatorModel } from './Consignator';
import type {
	ClockingModel,
	ClockingModelStatus,
	ClockingMotiveTypes,
	ConsigneeBiometricStatus,
	ConsigneeCreateRequestModel,
	ConsigneeModel,
	ConsigneeNotification,
	ConsigneeNotificationListRequest,
	ConsigneeSubscriptionCampaignsListFilters,
	ConsigneeSubscriptionCampaignsModel,
	ReferFriend,
	ReferFriendCreateRequest,
	ReferFriendCreateResponse
} from './Consignee';
import type {
	ClockingDashboardManagerRequest,
	ClockingDashboardModel,
	ControlRoomModel,
	HealthDashboardManagerRequest
} from './Dashboard';
import {
	GenericListResponse,
	GenericListResponseBaseG,
	GenericListResponseG,
	GenericResponseG,
	GenericResponse,
	pair,
	pairT,
	UUID
} from './Generic';
import type { MedicalCertificateModel } from './health/MedicalCertificate';
import type { PrescriptionModel } from './health/Prescription';
import type { HelpModel } from './Help';
import type { NewsModel } from './News';
import type {
	CommercialContactCreateModel,
	NotificationCreateModel,
	NotificationModel,
	NotificationModelStatus,
	NotificationViewUpdate,
	NotificationVisibilityUpdate
} from './Notification';
import type { ParameterModel, ParameterModelCreate } from './Parameter';
import type { PayrollModel } from './Payroll';
import type { Promotion, PromotionRequestFilter } from '.';
import type {
	EntityContractTemplateCreate,
	ProviderCreate,
	ProviderModel,
	ProviderPublicAdd,
	ProviderUpdate
} from './Provider';
import type { Schedule, ScheduleListFilters, ScheduleStatus } from './Schedule';
import type {
	SearchAreaCountersModel,
	SearchAreaListFilters,
	SearchAreaListOrders,
	SearchAreaModel,
	SearchAreaServiceTypeListFilters,
	SearchAreaServiceTypeModel,
	SearchAreaTypeModel,
	SearchItemModel
} from './search/SearchArea';
import type {
	SubscriptionConsigneeModel,
	SubscriptionCreateModel,
	SubscriptionDependentCreateModel,
	SubscriptionDependentModel,
	SubscriptionModel
} from './Subscription';
import type { UrlToken } from './Health';
import type { Country } from './util';
import type { ContactAdd, ProviderCreateContactRequest } from './Contact';
import type { GenderType } from './util/Gender';
import type { User_LegalAcceptance_Check_Request } from './User/LegalAcceptance';
import type { EnrollmentRequest } from './User/Enrollment';
import { ShoppingCartApi } from './Shoping-Cart';
import { helperFunctions as contractHelperFunctions } from './Contract/api';
import { groupByContext } from './Provider/api/contract-template/groupByContext';
import type { Payment } from './payment';
import { SLAApi } from './SLA';
import { DashboardApi } from './Dashboards';
import { ReportAPI } from './Reports';
import { UtilsApi } from './Utils';
import { BlogsApi } from './Blogs/api';

export enum ContextType {
	consignee = 2,
	consignator = 3,
	provider = 7,
	financier = 8
}

export interface GenericListRequest<
	K extends string = string,
	O extends string = string
> {
	filters?: pairT<string, K>[];
	orderBy?: pairT<'desc' | 'asc', O>[];
	pageNumber?: number;
	pageSize?: number;
}

export const returnEmpty = <R extends string>(
	name: R
): GenericListResponseBaseG<R, any> =>
	({
		code: 0,
		pageNumber: 1,
		recordsTotal: 0,
		pageSize: 0,
		pageTotal: 0,
		messages: [],
		description: '',
		[name as R]: []
	} as any);

export const returnEmptyPromise = <R extends string>(
	name: R
): GenericListResponseG<R, any> =>
	Promise.resolve({
		code: 0,
		pageNumber: 1,
		recordsTotal: 0,
		pageSize: 0,
		pageTotal: 0,
		messages: [],
		description: '',
		[name as R]: []
	} as any);

export type DocumentUploadResponse = GenericListResponse & {
	document: DocumentModel;
};

export type hasPermissionFunction = (name: string, type: number) => boolean;

export const permissionFilter = <Filters extends string = string>(
	filters: pairT<string, Filters>[],
	hasPermission: boolean,
	single: Filters,
	multiple: Filters,
	value: string[]
): pairT<string, Filters>[] | undefined => {
	if (hasPermission) return filters;
	if (value.length === 0) return undefined;
	return [
		...filters,
		pair(value.length > 1 ? multiple : single, value.join(','))
	];
};

export type FilterDateType<T extends string = ''> = `${T}${'Start' | 'End'}`;

export type StringIndexed = {
	[key: string]: any;
};

interface axiosRequester {
	post: (url: string, data?: any, headers?: any) => Promise<any>;
	request: (
		reqType: 'POST' | 'DELETE' | 'PUT' | 'GET',
		url: string,
		data?: any,
		headers?: any
	) => Promise<any>;
	put: (url: string, data?: any, headers?: any) => Promise<any>;
	delete: (url: string, headers?: any) => Promise<any>;
	get: (url: string, headers?: any) => Promise<any>;
	documentUpload?: (
		service: string,
		file: File,
		documentTypeId: string | number,
		idUUID: string
	) => Promise<any>;
}

export type FilterParamConsigneeClockingList =
	| 'consignatorsiduuid'
	| 'consigneeiduuid'
	| 'clockingstatus';

export type FilterParamConsigneeList =
	| 'fullname'
	| 'mobilephone'
	| 'vatnumber'
	| 'username'
	| 'usernameequals'
	| 'applicationid'
	| 'applicationidlikes'
	| 'biometricstatus'
	| 'consignatoriduuid'
	| 'consignatorsiduuid'
	| 'notingroup'
	| 'loadaddress'
	| 'useriduuid';

export type FilterParamNotificationList =
	| 'companiesuuid'
	| 'destinationiduuid'
	| 'description'
	| FilterDateType<'dateCreation'>
	| 'notnotificationBroadcastType'
	| 'notificationBroadcastType';

export class RequestsClass {
	axios: axiosRequester;

	constructor(axios: axiosRequester) {
		this.axios = axios;
	}

	businessHours = {
		list: (
			data: GenericListRequest
		): Promise<
			GenericListResponse & { businessHourss: BusinessHoursModel[] }
		> => this.axios.request('POST', `/businessHours/list`, data)
	};

	call = {
		list: (
			data: GenericListRequest<CallRecordListFilters>
		): Promise<GenericListResponse & { callRecords: CallRecord[] }> =>
			this.axios.post('call/list', data)
	};

	contract = {
		list: (
			data: GenericListRequest & { loadContractFields?: number }
		): GenericListResponseG<'contracts', ContractModel> =>
			this.axios.post('contract/list', data),

		add: (data: {
			contract: ContractCreateModel;
			schedulingIdUUID?: string;
			subscriptionCampaignIdUUID?: string;
			syncCall?: number;
			paymentData?: ContractCreatePaymentData;
		}): Promise<
			GenericResponse & {
				contract: ContractModel;
				installments: ContractInstallments;
			}
		> => this.axios.post('contract/add', data),

		get: (
			idUUID: string,
			option?: number | string
		): Promise<GenericResponse & { contract: ContractModel }> =>
			this.axios.request(
				'GET',
				option ? `contract/${idUUID}/${option}` : `contract/${idUUID}`
			),

		status: (data: {
			contractStatus: number;
			contractStatusMessage?: string;
			extraLogEntry?: string;
			idUUID: string;
		}): Promise<GenericResponse> =>
			this.axios.put('contract/update/status', data),

		ratting: (data: {
			contractIdUUID: string;
			observation?: string;
			rattingCategoryIdUUID: string;
			rate: number;
		}): Promise<GenericResponse & { contract: ContractModel }> =>
			this.axios.post('contract/ratting', data),

		field: {
			put: (data: {
				contractComplementaryFields: {
					contractTemplateFieldIdUUID?: string;
					contractTemplateFieldType?: number;
					fieldName: string;
					fieldValue: string;
					title?: string;
				}[];
				contractIdUUID: string;
			}) => this.axios.post(`/contract/field/put`, data)
		},

		groupBy: {
			contractTemplate: (
				data: GenericListRequest
			): GenericListResponseG<
				'contractGroupByContractTemplate',
				ContractGroupByContractTemplate
			> =>
				this.axios.request(
					'POST',
					`/contract/groupByContractTemplate/list`,
					data
				),

			workingEntity: (
				query: GenericListRequest
			): GenericListResponseG<
				'contractGroupByWorkingEntity',
				ContractGroupByWorkingEntity
			> => this.axios.post(`/contract/groupByWorkingEntity/list`, query)
		},

		payment: {
			get: (uuid: string): Promise<GenericResponseG<'payment', Payment>> =>
				this.axios.get(`/contract/payment/${uuid}`)
		},

		template: {
			list: (
				data: GenericListRequest
			): GenericListResponseG<'contractTemplates', ContractTemplateModel> =>
				this.axios.post('/contract/template/list', data),

			field: {
				get: (
					uuid: string
				): Promise<
					GenericResponse & { contractTemplateField: ContractTemplateField }
				> => this.axios.get(`contract/template/field/${uuid}`)
			},

			form: {
				list: (
					query: GenericListRequest<ContractTemplateFormsListFiltersList>
				): Promise<
					GenericListResponse & {
						contractTemplateForms: ContractTemplateFormsModel[];
					}
				> => this.axios.post('contract/template/form/list', query)
			},

			by_tag: (
				tag: string
			): Promise<
				GenericResponse & { contractTemplate: ContractTemplateModel }
			> => this.axios.request('GET', `contract/template/tag/${tag}`)
		},

		document: {
			list: (
				data: GenericListRequest<'consigneeiduuidoncontract'> & {
					documentContextType: number;
				}
			): Promise<GenericListResponse & { documents: DocumentModel[] }> =>
				this.axios.post('contract/document/list', data),

			upload: (
				file: any,
				idUUID: string,
				documentType: DocumentType,
				filename: string | undefined = undefined
			) => {
				const formData = new FormData();
				formData.append('file', file, filename);
				const config = {
					'content-type': 'multipart/form-data',
					'background-request': true
				};
				return this.axios.post(
					`/contract/document/upload/${documentType}/${idUUID}`,
					formData,
					config
				);
			}
		},

		helperFunctions: contractHelperFunctions(this)
	};

	blog = BlogsApi(this);

	shoppingCart = ShoppingCartApi(this);

	groups = GroupsApi(this);

	tickets = TicketApi(this);

	users = UserApi(this);

	slas = SLAApi(this);

	reports = ReportAPI(this);

	dashboards = DashboardApi(this);

	utils = UtilsApi(this);
	/*

		  Contact related requests

  */
	contact = {
		// TODO: finish
		add: (data: { contact: ContactAdd }): Promise<GenericResponse> =>
			this.axios.post('/consignee/contact/add', data)
	};

	/*
	Consignator related requests
  */
	consignator = {
		list: (
			query: GenericListRequest
		): GenericListResponseG<'consignators', ConsignatorModel> =>
			this.axios.post('consignator/list', query),

		document: {
			delete: (idUUID: string) =>
				this.axios.delete(`consignator/document/${idUUID}`)
		},

		clockingLocation: {
			list: (
				data: GenericListRequest<'locationErpId' | 'consignatorIdUUID'>
			): Promise<
				GenericListResponse & { clockingLocations: ClockingLocationModel[] }
			> => this.axios.post('consignator/clocking/location/list', data)
		}
	};

	/**
	 *
	 * Related to consignee
	 *
	 */
	consignee = {
		get: (
			idUUID: string,
			loadAddress?: 1 | 0,
			loadContacts?: 1 | 0,
			loadParameters?: 1 | 0
		): Promise<GenericResponse & { consignee: ConsigneeModel }> =>
			this.axios.request(
				'GET',
				`consignee/${idUUID}/${loadAddress ?? 0}/${loadContacts ?? 0}/${
					loadParameters ?? 0
				}`
			),

		list: (
			data: GenericListRequest<FilterParamConsigneeList>
		): GenericListResponseG<'consignees', ConsigneeModel> =>
			this.axios.post('/consignee/list', data),
		add: (
			data: ConsigneeCreateRequestModel
		): Promise<
			GenericResponse & {
				consignees: ConsigneeModel[];
				user: UserModel;
			}
		> => this.axios.post('consignee/add', data),

		subscriptions: (
			query: GenericListRequest<ConsigneeSubscriptionCampaignsListFilters> & {
				consigneeIdUUID?: string;
			}
		): Promise<
			GenericListResponse & {
				subscriptionCampaigns: ConsigneeSubscriptionCampaignsModel[];
			}
		> => this.axios.post('consignee/subscriptions', query),

		update: (query: {
			batchIdUUID?: string;
			consignee: ConsigneeModel;
		}): Promise<GenericResponse & { consignee: ConsigneeModel }> =>
			this.axios.put('consignee/update', query),

		group: {
			remove: (idUUID: string, groupIdUUID: string): Promise<GenericResponse> =>
				this.axios.delete(`/consingnee/group/${idUUID}/${groupIdUUID}`),
			add: (idUUID: string, groupIdUUID: string): Promise<GenericResponse> =>
				this.axios.post(`/consingnee/group/${idUUID}/${groupIdUUID}`)
		},

		biometric: {
			status: (data: {
				biometricStatus: ConsigneeBiometricStatus;
				consigneeIdUUID: string;
			}): Promise<GenericResponse> =>
				this.axios.put('consignee/biometric/update/status', data)
		},
		document: {
			list: (
				query: GenericListRequest<
					'documentstatus' | 'documenttype' | 'referenceiduuid',
					'datecreation'
				> & { documentContextType: number }
			): Promise<GenericListRequest & { documents: DocumentModel[] }> =>
				this.axios.post('consignee/document/list', query),

			upload: (
				f: File,
				documentType: ConsigneeDocumentType,
				consigneeIdUUID: string
			): Promise<DocumentUploadResponse> => {
				const formData = new FormData();
				formData.append('file', f);
				const config = {
					'content-type': 'multipart/form-data',
					'background-request': true
				};
				return this.axios.post(
					`/user/document/upload/${documentType}/${consigneeIdUUID}`,
					formData,
					config
				);
			}
		},

		clocking: {
			shift: {
				dump: (data: ShiftDumpRequest): Promise<ShiftResponse> =>
					this.axios.put('/consignee/clocking/shift/dump', data)
			},

			list: (
				data: GenericListRequest<FilterParamConsigneeClockingList>
			): Promise<GenericListResponse & { clockings: ClockingModel[] }> =>
				this.axios.post('consignee/clocking/list', data),

			status: (data: {
				clockingStatus: ClockingModelStatus;
				description?: string;
				idUUID: string;
			}): Promise<GenericResponse> =>
				this.axios.put('consignee/clocking/update/status', data),

			face: {
				reprocess: (
					clockingIdUUID: string
				): Promise<GenericResponse & { clocking: ClockingModel }> =>
					this.axios.put(`consignee/clocking/face/reprocess/${clockingIdUUID}`),
				include: (
					clockingIdUUID: string
				): Promise<GenericResponse & { clocking: ClockingModel }> =>
					this.axios.put(`consignee/clocking/face/include/${clockingIdUUID}`)
			},

			motiveTypes: (
				data: GenericListRequest
			): Promise<
				GenericListResponse & { clockingMotiveTypes: ClockingMotiveTypes[] }
			> => this.axios.post(`consignee/clocking/motiveTypes`, data)
		},

		staging: {
			update: (
				query: { batchIdUUID: string } | { consignee: ConsigneeModel }
			): Promise<
				GenericResponse & {
					consignee: ConsigneeModel[];
					user: UserModel[];
				}
			> => this.axios.put('consignee/staging/update', query),

			create: (
				query: ConsigneeStatingAdd
			): Promise<
				GenericResponse & {
					consignee: ConsigneeModel[];
					user: UserModel[];
				}
			> => this.axios.post('consignee/staging/add', query),

			updateStatus: (query: {
				consigneeStatus: number;
				idUUID: string;
			}): Promise<GenericResponse> =>
				this.axios.put('consignee/staging/update/status', query)
		},

		/**
		 * requestFriend for consingnee
		 */
		referFriend: {
			add: (
				data: ReferFriendCreateRequest
			): Promise<ReferFriendCreateResponse> =>
				this.axios.post('/consignee/refer-friend/add', data),

			get: (
				uuid: string
			): Promise<GenericResponseG<'referFriend', ReferFriend>> =>
				this.axios.get(`/consignee/refer-friend/${uuid}`)
		},
		// End of consignee/referFriend

		/**
		 * Parameter for consingnee
		 */
		parameter: {
			list: (
				query: GenericListRequest<'referenceiduuid'>
			): Promise<GenericListResponse & { parameters: ParameterModel[] }> =>
				this.axios.post('consignee/parameter/list', query),

			post: (data: ParameterModelCreate | ParameterModel) =>
				this.axios.post(`/consignee/parameter/put`, { parameter: data })
		},
		// End for consignee/parameter

		/*
		 * Notifications for consignees
		 */
		notifications: {
			list: (
				data: ConsigneeNotificationListRequest
			): GenericListResponseG<'notifications', ConsigneeNotification> =>
				this.axios.post(`/consignee/notifications/list`, data)
		},

		helperFunctions: {
			fromUser: async (
				userUUID: string
			): Promise<undefined | ConsigneeModel> => {
				const r = await this.consignee.list({
					orderBy: [pair('dateCreation', 'asc')],
					filters: [pair('useriduuid', userUUID), pair('loadaddress', '1')]
				});
				if (!r || r.code !== 0 || r.consignees.length !== 1) return undefined;
				return r.consignees[0];
			}
		}
	};
	config = {
		list: (
			query: GenericListRequest
		): Promise<GenericListResponse & { configs: ConfigModel[] }> =>
			this.axios.post('config/list', query)
	};
	campaign = {
		/**
		 * List campaings
		 */
		list: (
			data: GenericListRequest<CampaignListFilters> & { loadProducts: 0 | 1 }
		): Promise<GenericListResponse & { campaigns: CampaignModel[] }> =>
			this.axios.post('campaign/list', data),

		/**
		 * Get campaing
		 */
		get: (
			uuid: string
		): Promise<GenericResponse & { campaign: CampaignModel }> =>
			this.axios.get(`campaign/${uuid}`),

		/**
		 * Update status
		 */
		status: (query: {
			campaignStatus: CampaignStatus;
			idUUID: string;
		}): Promise<GenericResponse> =>
			this.axios.put('campaign/update/status', query)
	};
	document = {
		status: (query: { documentStatus: DocumentModelStatus; idUUID: string }) =>
			this.axios.put('document/update/status', query),

		upload: (
			service: string,
			file: File,
			documentTypeId: string | number,
			idUUID: string
		) => {
			const formData = new FormData();
			formData.append('file', file);
			const config = {
				'content-type': 'multipart/form-data',
				'background-request': true
			};
			return this.axios.post(
				`/${service}/document/upload/${documentTypeId}/${idUUID}`,
				formData,
				config
			);
		}
	};
	dashboard = {
		clocking: {
			dash: {
				manager: (
					data: ClockingDashboardManagerRequest
				): Promise<GenericResponse & ClockingDashboardModel> =>
					this.axios.post('dashboard/clocking/dash/manager', data)
			}
		},
		health: {
			dash: {
				controlroom: (
					data: HealthDashboardManagerRequest
				): Promise<
					GenericListResponse & { controlRoomSts: ControlRoomModel[] }
				> => this.axios.post('dashboard/health/dash/controlroom', data)
			}
		}
	};

	/*

		  User Related requests

  */
	user = {
		// Modifying the user
		add: (
			data: UserCreateRequest
		): Promise<GenericResponseG<'user', UserModel>> =>
			this.axios.post('/user/add', data),

		update: (
			data: UserUpdateRequest
		): Promise<GenericResponseG<'user', UserModel>> =>
			this.axios.put('/user/update', data),

		// Other related

		document: {
			upload: (
				f: File,
				documentType: string | number,
				userType: UserType,
				userIdUUID: string
			): Promise<DocumentUploadResponse> => {
				const formData = new FormData();
				formData.append('file', f);
				const config = {
					'content-type': 'multipart/form-data',
					'background-request': true
				};
				return this.axios.post(
					`/user/document/upload/${documentType}/${userType}/${userIdUUID}`,
					formData,
					config
				);
			}
		},

		/*
			Requests related to adding entities
	*/
		entity: {
			add: (
				data: UserEntityAddRequest
			): Promise<GenericResponseG<'userEntity', UserEntity>> =>
				this.axios.post('/user/entity/add', data)
		},

		enrollment: (data: EnrollmentRequest): Promise<GenericResponse> =>
			this.axios.put('/user/enrollment', data),

		legalAcceptance: {
			get: (user_uuid: UUID, entity_uuid: UUID): Promise<GenericResponse> =>
				this.axios.get(`/user/legalAcceptance/${user_uuid}/${entity_uuid}`),

			check: (
				data: User_LegalAcceptance_Check_Request
			): Promise<GenericResponse> =>
				this.axios.put('/user/legalAcceptance/check', data)
		},

		list: (
			query: GenericListRequest<UserListFilters> & { userType: UserType }
		): Promise<GenericListResponse & { users: UserModel[] }> =>
			this.axios.post('/user/list', query),

		login: (
			data: UserLoginRequest
		): Promise<
			GenericResponse & {
				expireDate: string;
				token: string;
				user: UserModel;
			}
		> => this.axios.post('user/login', data),

		pin: {
			reset: (data: UserResetPinRequest): Promise<GenericResponse> =>
				this.axios.post('user/pin/reset', data),

			change: (data: {
				applicationId: string;
				newPin: string;
				oldPin: string;
				userType: number;
				username: string;
			}): Promise<GenericResponse> =>
				this.axios.post('user/pin/changePin', data)
		},

		photo: {
			delete: (
				userTypeId: number | string,
				userUUID: string
			): Promise<GenericResponse & { document: DocumentModel }> =>
				this.axios.delete(`user/photo/${userTypeId}/${userUUID}`)
		},

		verify: (
			app: string,
			userType: string | number,
			user: string
		): Promise<GenericListResponse> =>
			this.axios.request('GET', `/user/verify/${app}/${userType}/${user}`)
	};

	util = {
		zipCode: {
			list: (
				data: GenericListRequest
			): GenericListResponseG<'zipcodes', AddressModel> =>
				this.axios.post('/utils/zipCode/list', data),

			get: (
				zipcode: string
			): Promise<GenericResponse & Record<'zipcode', AddressModel>> =>
				this.axios.get(`/utils/zipCode/${zipcode}`)
		},

		contactRelationShip: (
			data: GenericListRequest
		): Promise<
			GenericListResponse & {
				contactRelationships: {
					description: string;
					id: number;
					languageTag: string;
				}[];
			}
		> => this.axios.post('utils/contactRelationshipTypes', data),

		timestamp: (): Promise<GenericResponse & { timestamp: string }> =>
			this.axios.request('GET', '/utils/timestamp'),

		getDocuments: (
			data: GenericListRequest<
				'documentStatus' | 'documentType' | 'referenceIdUUID'
			> & { documentContextType: number },
			service: 'provider'
		): Promise<GenericListResponse & { documents: DocumentModel[] }> =>
			this.axios.request('POST', `/${service}/document/list`, data),

		country: (
			data: GenericListRequest
		): Promise<GenericListResponseG<'countries', Country>> =>
			this.axios.post('utils/country/list', data),

		gender: (
			data: GenericListRequest = {}
		): GenericListResponseG<'genders', GenderType> =>
			this.axios.post('/utils/genderTypes', data)
	};
	subscription = {
		dependent: {
			add: (data: {
				subscriptionDependent: SubscriptionDependentCreateModel;
			}): Promise<GenericResponse> =>
				this.axios.post('subscription/dependent/add', data),
			list: (
				data: GenericListRequest<
					| 'subscriptiondependentstatus'
					| 'dependentiduuid'
					| 'subscriptioniduuid'
				>
			): Promise<
				GenericResponse & {
					subscriptionDependents: SubscriptionDependentModel[];
				}
			> => this.axios.post('subscription/dependent/list', data),
			status: (data: {
				idUUID: string;
				subscriptionDepententStatus: number;
			}): Promise<GenericResponse> =>
				this.axios.put('subscription/dependent/update/status', data)
		},
		cancel: (idUUID: string): Promise<GenericResponse> =>
			this.axios.put(`subscription/cancel/${idUUID}`),
		consignee: {
			list: (
				query: GenericListRequest<'consigneeiduuid' | 'campaignstatus'>
			): Promise<
				GenericListResponse & {
					subscriptionConsignees: SubscriptionConsigneeModel[];
				}
			> => this.axios.post('subscription/consignee/list', query)
		},
		add: (query: {
			subscription: SubscriptionCreateModel;
		}): Promise<GenericResponse & { subscription: SubscriptionModel }> =>
			this.axios.post('subscription/add', query),
		getSubConsignee: (
			subscription: string,
			consignee: string
		): Promise<GenericResponse & { subscription: SubscriptionModel }> =>
			this.axios.request('GET', `subscription/${subscription}/${consignee}`)
	};
	help = {
		list: (
			data: GenericListRequest<'consignatoriduuid'>
		): Promise<GenericListResponse & { helps: HelpModel[] }> =>
			this.axios.post('help/list', data)
	};
	card = {
		list: (
			data: GenericListRequest<'referenceiduuid' | 'cardstatus'>
		): Promise<GenericResponse & { cards: CardModel[] }> =>
			this.axios.post('card/list', data),

		default: (idUUID: string): Promise<GenericResponse> =>
			this.axios.put('card/update/default', { idUUID }),

		add: (data: {
			card: CardModel;
		}): Promise<GenericResponse & { card: CardModel }> =>
			this.axios.post('card/add', data),

		status: (data: {
			cardStatus: number;
			idUUID: string;
		}): Promise<GenericResponse> =>
			this.axios.request('PUT', 'card/update/status', data)
	};
	news = {
		list: (
			data: GenericListRequest<'consignatorsiduuid' | 'newsstatus'>
		): Promise<GenericListResponse & { news: NewsModel[] }> =>
			this.axios.post('/news/list', data, {}),
		image: {
			link: {
				list: (
					data: GenericListRequest & { organizationIdUUID?: string }
				): Promise<GenericListResponse & { newsImages: DocumentModel[] }> =>
					this.axios.post('news/image/link/list', data),
				upload: (
					organizationIdUUID: string,
					shortDescription: string,
					formData: any
				): Promise<{
					body: any;
					statusCode: string;
					statusCodeValue: number;
				}> => {
					const config = {
						'content-type': 'multipart/form-data',
						'background-request': true
					};
					return this.axios.post(
						`news/image/link/upload/${organizationIdUUID}/${shortDescription}`,
						formData,
						config
					);
				}
			},

			list: (
				data: GenericListRequest
			): GenericListResponseG<'newsImages', DocumentModel> =>
				this.axios.post(`news/image/list`, data),

			delete: (idUUID: string): Promise<GenericResponse> =>
				this.axios.delete(`news/image/${idUUID}`)
		}
	};

	/**
	 *
	 *
	 */
	notification = {
		list: (
			query: GenericListRequest<FilterParamNotificationList>
		): Promise<GenericListResponse & { notifications: NotificationModel[] }> =>
			this.axios.post(`notification/list`, query),
		add: (data: {
			notification: NotificationCreateModel;
		}): Promise<GenericResponse & { notification: NotificationModel }> =>
			this.axios.post('notification/add', data),
		status: (data: {
			idUUID: string;
			notificationStatus: NotificationModelStatus;
		}): Promise<GenericResponse> =>
			this.axios.put('notification/update/status', data),

		/**
		 *
		 * Update related to notifications
		 *
		 */
		update: {
			/**
			 * @function Change if the notification was view
			 */
			view: (data: NotificationViewUpdate): Promise<GenericResponse> =>
				this.axios.put(`/notification/update/view`, data),

			/**
			 * @function Change if the message is to be shown
			 */
			visibility: (
				data: NotificationVisibilityUpdate
			): Promise<GenericResponse> =>
				this.axios.put(`/notification/update/visibility`, data)
		},

		newsletter: (
			uuid: string,
			email: string,
			applicationId: string
		): Promise<GenericResponse> =>
			this.axios.put(
				`notification/newsletter/${uuid}/${email}/${applicationId}`,
				{}
			),

		commercialContact: {
			add: (query: { commercialContact: CommercialContactCreateModel }) =>
				this.axios.put(`notification/commercialContact/add`, query)
		}
	};

	health = {
		medicalCertificate: {
			list: (
				data: GenericListRequest<'consigneeiduuid', 'datecreation'>
			): Promise<
				GenericListResponse & { medicalCertificates: MedicalCertificateModel[] }
			> => this.axios.post('health/medicalCertificate/list', data)
		},

		prescription: {
			list: (
				data: GenericListRequest<'consigneeiduuid', 'datecreation'>
			): Promise<
				GenericListResponse & { prescriptions: PrescriptionModel[] }
			> => this.axios.post('health/prescription/list', data)
		},
		UCI: {
			generate: {
				sign: (query: {
					idUUID: string;
					otp: string;
					systemCertifier: string;
				}): Promise<GenericResponse & { url: string }> =>
					this.axios.put('health/UCI/generate/sign', query)
			}
		},
		url: {
			token: {
				open: (query: {
					urlToken: string;
				}): Promise<GenericResponse & { urlToken: UrlToken }> =>
					this.axios.post('health/url/token/open', query)
			}
		}
	};
	payroll = {
		list: (
			data: GenericListRequest<'consigneeiduuid'>
		): Promise<GenericListResponse & { payrolls: PayrollModel[] }> =>
			this.axios.post('payroll/list', data)
	};

	/*
	  Provider Related Requests
  */
	provider = {
		get: (
			provider: string,
			loadAddress: 1 | 0 = 0,
			loadContacts: 1 | 0 = 0,
			loadParameters: 1 | 0 = 0
		): Promise<GenericResponse & { provider: ProviderModel }> =>
			this.axios.get(
				`provider/${provider}/${loadAddress}/${loadContacts}/${loadParameters}`
			),

		add: (data: {
			provider: ProviderCreate;
		}): Promise<GenericResponse & { provider: ProviderModel }> =>
			this.axios.post('provider/add', data),

		update: (data: {
			provider: ProviderUpdate;
		}): Promise<GenericResponseG<'provider', ProviderModel>> =>
			this.axios.put('provider/update', data),

		list: (
			data: GenericListRequest<'useriduuid' | 'idUUID' | 'iduuids'>
		): Promise<GenericListResponse & { providers: ProviderModel[] }> =>
			this.axios.post('provider/list', data),

		searchAreaServiceType: {
			list: (
				data: GenericListRequest<SearchAreaServiceTypeListFilters> & {
					providerIdUUID: string;
				}
			): Promise<
				GenericListResponse & {
					searchAreaServiceTypes: SearchAreaServiceTypeModel[];
				}
			> => this.axios.post('/provider/searchAreaServiceTypes', data)
		},

		public: {
			add: (data: ProviderPublicAdd) =>
				this.axios.post('/provider/public/add', data)
		},

		contact: {
			add: (data: ProviderCreateContactRequest) =>
				this.axios.post('/provider/contact/add', data)
		},

		contract_templates: {
			list: (
				data: GenericListRequest
			): GenericListResponseG<
				'entityContractTemplates',
				EntityContractTemplate
			> => this.axios.post('provider/contract-templates', data),

			add: (data: { entityContractTemplate: EntityContractTemplateCreate }) =>
				this.axios.post('provider/contract-template/add', data),

			delete: (uuid: string) =>
				this.axios.delete(`provider/contract-template/${uuid}`),

			groupByContext: groupByContext(this, 'provider/contract-template')
		}
	};

	product = ProductApi(this);

	search = {
		counters: (
			data: GenericListRequest<'languageId' | 'consignatorsIdUUID', 'showOrder'>
		): Promise<
			GenericListResponse & { searchAreaCounters: SearchAreaCountersModel[] }
		> => this.axios.post('search/counters', data),

		execute: <P extends boolean>(
			data: GenericListRequest,
			parameters: P
		): Promise<GenericListResponse & { searchItems: SearchItemModel<P>[] }> =>
			this.axios.post('search/execute', {
				...data,
				filters: [
					...(data.filters ?? []),
					pair('loadparamters', parameters ? '1' : '0')
				]
			}),

		types: {
			list: (
				data: GenericListRequest<'incategory'>
			): Promise<
				GenericListResponse & { searchAreaTypes: SearchAreaTypeModel[] }
			> => this.axios.post('search/types', data)
		},

		serviceTypes: {
			list: (
				data: GenericListRequest
			): GenericListResponseG<'searchAreaServiceTypes', SearchAreaTypeModel> =>
				this.axios.post('search/serviceTypes', data)
		},

		list: (
			data: GenericListRequest<SearchAreaListFilters, SearchAreaListOrders>
		): Promise<GenericListResponse & { searchAreas: SearchAreaModel[] }> =>
			this.axios.post('search/list', data)
	};

	survery = {
		dump: (data: {
			contractTemplateIdUUID: string;
			dateEnd: string;
			dateStart: string;
			fileType?: number;
		}): Promise<GenericResponse & { url: string }> =>
			this.axios.put('survey/dump', data)
	};

	promotion = {
		list: (
			query: GenericListRequest<PromotionRequestFilter>
		): Promise<GenericListResponseG<'promotions', Promotion>> =>
			this.axios.post('promotion/list', query)
	};

	// Deals with the scheduling part of the api
	scheduling = {
		// Gets a list of all schedulings
		list: (
			query: GenericListRequest<ScheduleListFilters>,
			header?: any
		): Promise<GenericListResponse & { schedulings: Schedule[] }> =>
			this.axios.post('scheduling/list', query, header),

		status: (query: {
			idUUID: string;
			schedulingStatus: ScheduleStatus;
		}): Promise<GenericResponse> =>
			this.axios.put('scheduling/update/status', query),

		update: (query: { scheduling: Schedule }): Promise<GenericResponse> =>
			this.axios.put('scheduling/update', query)
	};
}
