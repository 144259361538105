import React from 'react';
import { g_getData } from '../Generic';
import { TablePage } from '../generic/DataTable';
import { S } from '../GenericFunctions';

export const ReportCallByProvider = () => (
    <TablePage
        header={null}
        table={() => [
            { t: 'd', data: 'providerName' },
            { t: 'd', data: 'totalCalls' },
            { t: 'd', data: 'totalRejectedCalls' },
            { t: 'd', data: 'totalSuccessCalls' },
            { t: 'd', data: 'callMeanTime' },
        ]}
        getData={c => g_getData(c, S.call.groupByProvider, 'callRecordsByProviders')}
    />
);
