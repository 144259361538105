/**
 * This file contains the table generic component
 * And the TableHeader Generic Component as well as all the types required by them
 */
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import React, {
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react';
import {
	GenericListResponseBaseG,
	GenericListResponseG,
	pair,
	pairT
} from '../api-ts-bindings/Generic';
import {
	FilterDateType,
	GenericListRequest,
	StringIndexed
} from '../api-ts-bindings/Requests';
import {
	FormBuilderType,
	GDateInput,
	GDropdown,
	GInput,
	useDataObject
} from './Form';
import { BuilderType, KnownAny, StateIcon } from './Generic';
import { StateItems, Translator } from './TypeDefinitions';
import {
	checkResponse,
	formatDate,
	openWindow,
	treatDate,
	noop
} from './Utils';

//TODO: Maybe use a a state to do this giving more per table control
//Used to hide the search components
export function hideSearch() {
	const cf = document.getElementsByClassName('p-filter-column');
	for (let i = 0; i < cf.length; i++) {
		(cf[i] as KnownAny).style.setProperty('display', 'none');
	}
}

//Toggles the fields that have the search components
export function toggleSearch() {
	const cf = document.getElementsByClassName('p-filter-column');
	for (let i = 0; i < cf.length; i++) {
		(cf[i] as KnownAny).style.setProperty(
			'display',
			(cf[i] as KnownAny).style.display === 'none' ? '' : 'none'
		);
	}
}

type TableProps<
	F extends string = string,
	O extends string = string,
	R extends string = string,
	T extends StringIndexed = StringIndexed
> = {
	title?: string | (() => string);

	messagesRefProps?: React.RefObject<Messages>;

	header: React.ReactElement<TableHeaderProps<T, F, O>>;

	/**
	 * Please use useCallback on this function
	 */
	request: (
		query: GenericListRequest<F, O>
	) => GenericListResponseG<R, T> | GenericListResponseBaseG<R, T>;
	result: R;

	//Order
	defaultOrderBy?: pairT<'asc' | 'desc', O>;

	// Paging
	noPaging?: boolean;
	pageSize?: number;

	canRequest?: React.MutableRefObject<(() => void) | undefined>;
	extractMessages?: React.MutableRefObject<Messages | null | undefined>;

	//Body
	//TODO maybe change to this
	//children?: [DataTableArgumentElement<T> | ReactElement][] | DataTableArgumentElement<T> | ReactElement;
	children?: TableArgumentElement<T, F, O>[] | TableArgumentElement<T, F, O>;
};

export type TableHeaderProps<
	T extends StringIndexed = StringIndexed,
	F extends string = string,
	O extends string = string
> = {
	none?: boolean;

	advancedFilter?: boolean;
	filterBtt?: boolean;

	action?: {
		icon: string;
		tooltip?: string;
		className?: string;
		hidden?: boolean;
		action: () => void;
	};

	url?: {
		url: string;
		size: [number, number];

		className?: string;
		icon?: string;
		hidden?: boolean;
		windowName?: string;
	};

	// To be auto filed by the Table Component
	clearData?: () => void;
	getData?: () => void;
	tableBody?: (TableArgumentElement<T, F, O> | undefined)[];
	// update the filter
	updateFilter?: (T: StringIndexed) => void;
};

/**
 *
 * Types Definitions
 *
 */

export interface TableArgument {
	t: string;
}

type TableShow<T extends StringIndexed> = TableArgument & {
	show?: boolean;
	title?: string;
	style?: React.CSSProperties;
	dataMap?: (a: KnownAny, row: T) => React.Component;
};

export type TableArgumentBase<T extends StringIndexed> = TableShow<T> & {
	align?: 'center' | 'right' | 'left';
	html?: boolean;
};
export type TableArgumentSpacer = TableArgument & {
	t: 'spacer';
	space: string;
};

export type TableArgumentDataBase<
	T extends StringIndexed,
	F extends string,
	O extends string
> =
	// For only filters
	| (TableArgumentBase<T> & {
			data: keyof T & F;
			filter: true;
			onlyFilter?: false;
			sort?: false;
	  })
	| (TableArgumentBase<T> & {
			data: F;
			onlyFilter: true;
			sort?: false;
			filter?: false;
	  })
	// Only of orders
	| (TableArgumentBase<T> & {
			data: keyof T & O;
			filter?: false;
			onlyFilter?: false;
			sort: true;
	  })
	//For Order and Sorting
	| (TableArgumentBase<T> & {
			data: keyof T & O & F;
			filter?: true;
			onlyFilter?: false;
			sort: true;
	  })
	| (TableArgumentBase<T> & {
			data: O & F;
			filter?: false;
			sort: true;
			onlyFilter: true;
	  });

export type TableArgumentData<
	T extends StringIndexed,
	F extends string,
	O extends string
> =
	| (TableArgumentBase<T> & {
			data: keyof T & string;
			filter?: false;
			onlyFilter?: false;
			sort?: false;
	  })
	| TableArgumentDataBase<T, F, O>;

export type TableArgumentText<
	T extends StringIndexed,
	F extends string,
	O extends string
> = TableArgumentData<T, F, O> & { t: 'd'; dataTrimLimit?: number };

export type TableArgumentState<
	T extends StringIndexed,
	F extends string,
	O extends string
> = TableArgumentData<T, F, O> & {
	t: 'state';
	stateSize?: string;
	states: (row?: T) => StateItems;
};

export type TableArgumentDiData<
	T extends StringIndexed,
	F extends string,
	O extends string
> =
	| (TableArgumentBase<T> & {
			data: string;
			filter?: false;
			onlyFilter?: false;
			sort?: false;
	  })
	| TableArgumentDataBase<T, F, O>;

export type TableArgumentDi<
	T extends StringIndexed,
	F extends string,
	O extends string
> = TableArgumentDiData<T, F, O> & { t: 'di'; di: (a: T) => KnownAny };

// This sets a filter for the type in TableArgumentDateData
// So that we can use for example
// DateCreation as a filter if and only if the filters option on the request has only
// DateCreationStart and DateCreationEnd
type TADDInclude<
	K extends string,
	A extends string,
	C extends string
> = `${K}${A}` extends C ? K : never;
type TADDDataType<
	T extends StringIndexed,
	A extends string,
	C extends string
> = keyof {
	[k in keyof T & string as TADDInclude<k, A, C>]: true;
} &
	string;

// Date {{
export type TableArgumentDateData<
	T extends StringIndexed,
	F extends string,
	O extends string
> =
	| (TableArgumentBase<T> & {
			data: keyof T & string;
			filter?: false;
			onlyFilter?: false;
			sort?: false;
	  })
	// For only filters
	| (TableArgumentBase<T> & {
			data: TADDDataType<T, FilterDateType, F>;
			filter?: true;
			onlyFilter?: false;
			sort?: false;
	  })
	| (TableArgumentBase<T> & {
			data: TADDDataType<T, FilterDateType, F>;
			filter?: false;
			onlyFilter?: true;
			sort?: false;
	  })
	| (TableArgumentBase<T> & {
			data: TADDDataType<T, FilterDateType, F>;
			filter?: true;
			onlyFilter?: true;
			sort?: false;
	  })
	// Only of orders
	| (TableArgumentBase<T> & {
			data: keyof T & O;
			filter?: false;
			onlyFilter?: false;
			sort?: true;
	  })
	//For Order and Sorting
	| (TableArgumentBase<T> & {
			data: TADDDataType<T, FilterDateType, F> & O;
			filter?: true;
			onlyFilter?: false;
			sort?: true;
	  })
	| (TableArgumentBase<T> & {
			data: TADDDataType<T, FilterDateType, F> & O;
			filter?: false;
			onlyFilter?: true;
			sort?: true;
	  })
	| (TableArgumentBase<T> & {
			data: TADDDataType<T, FilterDateType, F> & O;
			filter?: true;
			onlyFilter?: true;
			sort?: true;
	  });
export type TableArgumentDate<
	T extends StringIndexed,
	F extends string,
	O extends string
> = TableArgumentDateData<T, F, O> & {
	t: 'date';
	extended?: boolean;
	di?: (a: KnownAny, row?: T) => KnownAny;
};
// }}

export type TableArgumentButton<T extends StringIndexed> = TableShow<T> & {
	t: 'btt';
	bttHidden?: (raw: T) => boolean;
	icon?: string;
	show?: (row: T) => boolean;
	//TODO: change the argument
	click: (row: T, ev: KnownAny) => void;
	extra?: string;
	class?: string;
	tooltip?: string;
	label?: string;
};

export type TableArgumentMap<
	T extends StringIndexed,
	F extends string,
	O extends string
> = TableArgumentData<T, F, O> & {
	t: 'map';
	objs: { [key: string]: KnownAny };
};

export type TableArgumentDialog<T extends StringIndexed> = TableShow<T> & {
	t: 'dil';
	text: string;
	//TODO Change this
	onConfirm: (raw: T, ev: KnownAny) => void;
	label?: string;
	dialogTitle?: string;
	bttHidden?: (raw: T) => boolean;
	disabled?: boolean;
	classBttConfirm?: string;
	classBttDeny?: string;
	iconBttConfirm?: string;
	iconBttDeny?: string;
	class?: string;
	buttonClassName?: string;
	confirmText?: string;
	denyText?: string;
	icon?: string;
};

export type TableArgumentURL<T extends StringIndexed> = TableShow<T> & {
	t: 'url';
	icon?: string;
	url: (row: T) => string;
	width?: number;
	height?: number;
	callback?: () => void;
	class?: string;
	tooltip?: string;
};

export type TableArgumentElement<
	T extends StringIndexed,
	F extends string,
	O extends string
> =
	| TableArgumentSpacer
	| TableArgumentText<T, F, O>
	| TableArgumentState<T, F, O>
	| TableArgumentDi<T, F, O>
	| TableArgumentDate<T, F, O>
	| TableArgumentButton<T>
	| TableArgumentMap<T, F, O>
	| TableArgumentDialog<T>
	| TableArgumentURL<T>;

interface TableSelectorProps<
	T extends StringIndexed,
	Filters extends string = string,
	OrderBy extends string = string,
	Result extends string = string
> {
	hidden?: boolean;

	wraperClass?: string;

	labelClass?: string;
	label?: string;

	inputClass?: string;

	buttonWraperClass?: string;

	value?: string;
	required?: boolean;

	disabled?: boolean;

	jump?: () => void;
	jumpCondition?: boolean;

	table: (
		change: (t: T) => void
	) =>
		| TableArgumentElement<T, Filters, OrderBy>[]
		| TableArgumentElement<T, Filters, OrderBy>;

	header?: React.ReactElement<TableHeaderProps<T, Filters, OrderBy>>;

	/**
	 * Please use useCallback on this function
	 */
	request: (
		query: GenericListRequest<Filters, OrderBy>
	) => GenericListResponseG<Result, T> | GenericListResponseBaseG<Result, T>;
	result: Result;

	//Order
	defaultOrderBy?: pairT<'asc' | 'desc', OrderBy>;

	// Paging
	noPaging?: boolean;
	pageSize?: number;

	onChange: (t: T) => void;
}

export type TableType = <
	T extends StringIndexed,
	F extends string,
	O extends string,
	R extends string
>(
	props: TableProps<F, O, R, T>
) => JSX.Element;

export const TableBuilder = (
	config: {
		pageSize: number;
	},
	ls: Translator,
	{ Button, DialogPrompt }: BuilderType,
	{ Form, GDiv }: FormBuilderType
): [
	TableType,
	<T extends StringIndexed, F extends string, O extends string>(
		props: TableHeaderProps<T, F, O>
	) => JSX.Element | null,
	<
		T extends StringIndexed,
		F extends string,
		O extends string,
		R extends string
	>(
		props: TableSelectorProps<T, F, O, R>
	) => JSX.Element | null
] => {
	const TableHeader = <
		T extends StringIndexed,
		F extends string,
		O extends string
	>({
		advancedFilter,
		clearData = noop,
		getData = noop,
		none,
		url,
		action,
		tableBody,
		updateFilter
	}: TableHeaderProps<T, F, O>) => {
		const [advancedFilterVisible, setAdvancedFilterVisible] = useState(false);
		const { obj, setObj } = useDataObject<StringIndexed>({});

		const filterTimeoutRef = useRef<number | null>();
		const loaded = useRef<boolean>(false);

		useEffect(() => {
			if (filterTimeoutRef.current) clearTimeout(filterTimeoutRef.current);
			// The as any is necessary because it's of nodejs type of setTimeout
			filterTimeoutRef.current = setTimeout(() => {
				filterTimeoutRef.current = null;
				if (!loaded.current) {
					loaded.current = true;
					return;
				}
				if (updateFilter) updateFilter(obj);
			}, 1000) as KnownAny;
		}, [obj, updateFilter]);

		if (none) return null;

		// The default filter button that just toggles the right default field in the columns
		let filterButton = (
			<Button
				icon="pi pi-search"
				onClick={(e) => {
					e.preventDefault();
					toggleSearch();
				}}
				wraperClass=""
				type="button"
				style={{ float: 'right', marginLeft: '3px' }}
			/>
		);

		let filterForm = null;

		if (advancedFilter) {
			// Set the button to be the more advanced button
			filterButton = (
				<Button
					icon="pi pi-search"
					onClick={(e) => {
						e.preventDefault();
						setAdvancedFilterVisible(!advancedFilterVisible);
					}}
					wraperClass=""
					type="button"
					style={{ float: 'right', marginLeft: '3px' }}
				/>
			);

			if (advancedFilterVisible) {
				// Probably a better way to do this
				const data: ReactElement[] =
					tableBody
						?.filter((e) => {
							//If ignored return nothing
							if (e === undefined) return false;
							if (
								e.t === 'btt' ||
								e.t === 'di' ||
								e.t === 'dil' ||
								e.t === 'url'
							)
								return false;

							return true;
						})
						.map((e) => {
							// Type script needs a hint about the types
							if (e === undefined) return <></>;
							if (
								e.t === 'btt' ||
								e.t === 'di' ||
								e.t === 'dil' ||
								e.t === 'url'
							)
								return <></>;

							console.log(e);

							// If spacer return the spacer
							if (e.t === 'spacer') {
								const className = `p-col-${e.space ?? '1'} p-fluid`;
								console.log(className);
								return <GDiv class={className} />;
							}

							if ((!e.filter && !e.onlyFilter) || (e.show ?? false))
								return <></>;

							const d = e.data ?? '';
							let label = e.title;

							if (e.t === 'state') {
								label =
									label ?? ls('status', 'generic', 'Label not Found') ?? '';
								const className = 'p-col-2 p-fluid';
								const originalStates = e.states();
								const states = Object.keys(originalStates)
									.filter((a) => !isNaN(Number(a)))
									.map((a) => ({
										...originalStates[Number(a)],
										label: originalStates[Number(a)].tooltip ?? a,
										value: a
									}));

								return (
									<GDropdown
										d={d}
										fClass={className}
										options={states}
										l={label}
										itemTemplate={(i: KnownAny) => (
											<div className="p-clearfix">
												<span
													className={'pi ' + i.class}
													style={{ color: i.color, fontSize: '1.2em' }}
												></span>
												<span
													style={{
														margin: '.5em .25em 0 0',
														marginLeft: '.5em'
													}}
												>
													{i.label}
												</span>
											</div>
										)}
									/>
								);
							} else if (e.t === 'date') {
								const className = 'p-col-3 p-fluid';
								return (
									<GDateInput
										d={d}
										fClass={className}
										selectionMode="range"
										l={label}
									/>
								);
							} else if (e.t === 'map') {
								const className = 'p-col-2 p-fluid';
								const states = Object.keys(e.objs).map((a) => ({
									label: e.objs[a] ?? a,
									value: a
								}));

								return (
									<GDropdown
										d={d}
										fClass={className}
										options={states}
										l={label}
									/>
								);
							} else {
								const className = 'p-col-2 p-fluid';
								return <GInput d={d} fClass={className} />;
							}
						}) ?? [];

				if (data.length > 0)
					filterForm = (
						<Form obj={obj} setObj={setObj} lclass="p-col-2">
							<GDiv grid group children={data} />
						</Form>
					);
			}
		}

		let urlButton = null;

		if (url) {
			// This creates a button that opens a new window
			urlButton = (
				<Button
					icon={url.icon ?? 'pi pi-plus'}
					bttClass={url.className}
					hidden={url.hidden}
					type="button"
					onClick={(e) => {
						e.preventDefault();
						openWindow(
							url.url,
							url.windowName ?? '_blank',
							url.size[0],
							url.size[1],
							'off',
							'',
							() => {
								//Ask the table to request data from server
								getData();
							}
						);
					}}
					wraperClass=""
					style={{ float: 'right', marginLeft: '3px' }}
				/>
			);
		}

		let actionButton = null;

		if (action) {
			// This creates a button that runs a function that is passed to it
			actionButton = (
				<Button
					icon={action.icon ?? 'pi pi-plus'}
					bttClass={action.className}
					hidden={action.hidden}
					onClick={(e) => {
						e.preventDefault();
						action.action();
					}}
					wraperClass=""
					type="button"
					tooltip={action.tooltip}
					style={{ float: 'right', marginLeft: '3px' }}
				/>
			);
		}

		return (
			<>
				<div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
					{actionButton}
					{urlButton}
					{filterButton}
					<Button
						icon="pi pi-refresh"
						onClick={(e) => {
							e.preventDefault();

							//Ask the table to clear the data
							if (clearData) clearData();
						}}
						style={{ float: 'right', marginLeft: '3px' }}
						wraperClass=""
					/>
				</div>
				{filterForm}
			</>
		);
	};

	const Table = function <
		T extends StringIndexed,
		F extends string,
		O extends string,
		R extends string
	>({
		title: titleProp,
		messagesRefProps,
		header,
		noPaging,
		pageSize: pageSizeProp,
		children,
		request,
		result,
		canRequest: canRequestHook,
		extractMessages,
		//This is needed as O might not have dateCreation but he still need it here as it is the default
		defaultOrderBy = pair('dateCreation' as KnownAny, 'desc')
	}: TableProps<F, O, R, T>) {
		useEffect(() => {
			hideSearch();
		}, []);

		const tempTitle = typeof titleProp === 'function' ? titleProp() : titleProp;

		const title = ls(tempTitle, 'titles', tempTitle ?? '');

		let titleComponent = null;
		let messages = null;

		const messagesRef = useRef<Messages | null>();

		//Control
		const [canRequest, setCanRequest] = useState(true);

		if (canRequestHook) canRequestHook.current = () => setCanRequest(true);

		//Data
		const [pageNumber, setPageNumber] = useState(1);
		const [data, setData] = useState<T[]>([]);
		const [first, setFirst] = useState(0);
		const [recordsTotal, setRecordsTotal] = useState(0);
		const [pageTotal, setPageTotal] = useState(0);

		const [filters, setFilters] = useState<pairT<string, F>[]>([]);
		const [orderBy, setOrderBy] = useState<pairT<'asc' | 'desc', O>[]>([
			defaultOrderBy
		]);

		//DataTable

		//This filters are not to be used by us they are controlled by the program
		//and are used internally to set the filters in the data table
		//TODO check this type
		const [filtersData, setFiltersData] = useState<KnownAny>({});

		//This meta data is not to be used by us they are controlled by the program
		//and are used internally to set the order in the data table
		//TODO check this type
		const [multiSortMeta, setMultiSorMeta] = useState<
			{ field: string; order: KnownAny }[]
		>([]);

		//This is the target name of the filters
		const [toFilter, setToFilter] = useState<{ [key: number]: F }>({});

		//This controls the timeout
		const filterTimeoutRef = useRef<number | null>(null);

		const pageSize = pageSizeProp ?? config.pageSize;

		if (title) titleComponent = <h1>{title}</h1>;
		if (!messagesRefProps)
			messages = (
				<Messages
					ref={(e) => {
						messagesRef.current = e;
						if (extractMessages) extractMessages.current = e;
					}}
				></Messages>
			);

		const msgRef = messagesRefProps ?? messagesRef;

		useEffect(() => {
			if (pageNumber !== 1 && data.length === 0 && !canRequest)
				setCanRequest(true);
		}, [data.length, pageNumber, canRequest]);

		const calculateBody = useCallback(() => {
			let remove = 0;

			const ch = Array.isArray(children) ? children : [children];

			const toFilter: { [key: number]: F } = {};

			const mapped = ch.map((ie, i) => {
				if (ie === undefined) {
					//We increase remove since we don't use this index to actually create a column
					remove++;
					return null;
				}

				//If you can not find the t then return the object
				//TODO this only makes sense if you let other things than the DataTableArgument pass trough
				if (!ie.t) {
					//Not managed by the system so we need to increase one
					remove++;
					return ie;
				}

				//If it's a spacer then skip
				if (ie.t === 'spacer') {
					remove++;
					return null;
				}

				// This decides when we show columns
				// For us to hide the column we need to check that the variable is not null and not
				// undefined and that it's false
				if (ie.show !== null && ie.show !== undefined && !ie.show) {
					remove++;
					return null;
				}

				let title: string | undefined | null = ie.title;
				const dataMap = ie.dataMap ?? ((a) => a);

				//Deal with things that don't involve filtering
				if (ie.t === 'btt') {
					return (
						<Column
							key={i}
							style={{ width: '5em', ...ie.style }}
							header={title ?? ''}
							body={(raw: T) => (
								<p style={{ margin: '0px', textAlign: 'center' }}>
									{dataMap(
										<Button
											label={ie.label}
											icon={ie.icon}
											hidden={ie.bttHidden ? ie.bttHidden(raw) : false}
											onClick={(e) => ie.click(raw, e)}
											bttClass={ie.class}
											wraperClass={ie.extra}
											tooltip={ie.tooltip}
											type="button"
										/>,
										raw
									)}
								</p>
							)}
						/>
					);
				} else if (ie.t === 'dil') {
					return (
						<Column
							key={i}
							style={{ width: '5em', ...ie.style }}
							header={title ?? ''}
							body={(raw: T) => (
								<p style={{ margin: '0px', textAlign: 'center' }}>
									{dataMap(
										<DialogPrompt
											text={ie.text}
											onConfirm={(e) => ie.onConfirm(raw, e)}
											label={ie.label}
											dialogTitle={ie.dialogTitle}
											hidden={ie.bttHidden ? ie.bttHidden(raw) : false}
											disabled={ie.disabled}
											nobtt={{
												class: ie.classBttDeny,
												icon: ie.iconBttDeny,
												label: ie.denyText
											}}
											yesbtt={{
												class: ie.classBttConfirm,
												icon: ie.iconBttConfirm,
												label: ie.confirmText
											}}
											wraperClass={ie.class}
											bttClass={ie.buttonClassName}
											icon={ie.icon}
										/>,
										raw
									)}
								</p>
							)}
						/>
					);
				} else if (ie.t === 'url') {
					return (
						<Column
							key={i}
							style={{ width: '5em', ...ie.style }}
							header={ie.title ?? ''}
							body={(raw: T) => (
								<p style={{ margin: '0px', textAlign: 'center' }}>
									{dataMap(
										<Button
											icon={ie.icon ?? 'pi pi-pencil'}
											bttClass={ie.class ?? 'p-button-warning'}
											tooltip={ie.tooltip}
											onClick={() =>
												openWindow(
													ie.url(raw),
													raw.idUUID ?? '_blank',
													ie.width,
													ie.height,
													'off',
													'',
													ie.callback ?? (() => setCanRequest(true))
												)
											}
											type="button"
										/>,
										raw
									)}
								</p>
							)}
						/>
					);
				}

				// Deal with things that don't involve filtering

				//Reset the title since now we have data
				title = ie.title ?? ls(ie.data, '_', ie.data);

				//If this is only meant to be a filter then no need to show it
				//And this will only work on advanced filter option
				if (ie.onlyFilter) {
					remove++;
					return null;
				}

				// If the advanced filter is enabled we should not use this filter
				// The header will deal with the filter
				const filter = (ie.filter ?? false) && !header.props.advancedFilter;

				const data = (r: T) => r[ie.data ?? ''];

				// Set the filter for this element
				// If the element is set to do that
				// TODO resolve this
				if (filter && ie.data) toFilter[i - remove] = ie.data as KnownAny;

				if (ie.t === 'd') {
					const trimLimit = ie.dataTrimLimit ?? 200;
					const dataMap = (a: KnownAny, row: T, trim = false) => {
						if (trim) {
							if (a !== null && a !== undefined) {
								a = String(a);
								a = a.length >= trimLimit ? a.substr(0, trimLimit) + '...' : a;
							}
						}
						return (ie.dataMap ?? ((a) => a))(a, row);
					};
					return (
						<Column
							key={i}
							style={ie.style}
							field={ie.data}
							filter={filter}
							sortable={ie.sort}
							header={title}
							body={(row: T) => (
								<p
									style={{
										margin: '0px',
										textAlign:
											ie.align ?? typeof data(row) === 'string'
												? 'left'
												: 'right'
									}}
								>
									{ie.html ? (
										<div
											dangerouslySetInnerHTML={dataMap(data(row), row, true)}
										></div>
									) : (
										dataMap(data(row), row, true)
									)}
								</p>
							)}
						/>
					);
				} else if (ie.t === 'di') {
					return (
						<Column
							key={i}
							style={ie.style}
							field={ie.data}
							filter={filter}
							sortable={ie.sort}
							header={title}
							body={(row: T) => (
								<p style={{ margin: '0px', textAlign: ie.align ?? 'right' }}>
									{dataMap(ie.di(row), row)}
								</p>
							)}
						/>
					);
				} else if (ie.t === 'date') {
					const d = (row: T) => formatDate(data(row), ie.extended, true);
					return (
						<Column
							key={i}
							style={ie.style}
							field={ie.data}
							filter={filter}
							sortable={ie.sort}
							header={title}
							body={(row: T) =>
								dataMap(ie.di ? ie.di(d(row), row) : d(row), row)
							}
						/>
					);
				} else if (ie.t === 'state') {
					return (
						<Column
							key={i}
							style={{ width: '4em', ...ie.style }}
							field={ie.data}
							filter={filter}
							sortable={ie.sort}
							header={ie.title ?? ls('state', 'dataTableGeneric', ls(ie.data))}
							body={(row: T) => (
								<p style={{ margin: '0px', textAlign: 'center' }}>
									{dataMap(
										<StateIcon
											state={Number(data(row))}
											style={{ fontSize: ie.stateSize ?? '1.87em' }}
											custom={ie.states(row)}
										/>,
										row
									)}
								</p>
							)}
						/>
					);
				} else if (ie.t === 'map') {
					const d = (row: T) => ie.objs[data(row)];
					return (
						<Column
							key={i}
							style={ie.style}
							field={ie.data}
							filter={filter}
							sortable={ie.sort}
							header={title}
							body={(row: T) => (
								<p
									style={{
										margin: '0px',
										textAlign:
											ie.align ?? typeof d(row) === 'string' ? 'left' : 'right'
									}}
								>
									{dataMap(d(row), row)}
								</p>
							)}
						/>
					);
				}
				return null;
			});

			setToFilter(toFilter);

			return mapped;
			//NOTE!: this is because of the type definitions
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [children, header.props.advancedFilter]);

		const [calculatedBody, setCalculatedBody] = useState<
			(JSX.Element | null)[]
		>(() => calculateBody());

		//Every time children changes recalculate the body
		useEffect(() => {
			setCalculatedBody(calculateBody());
		}, [children, calculateBody]);

		useEffect(() => {
			if (canRequest) {
				//Only disable
				setCanRequest(false);
				const doRequest = async () => {
					const d = await request({
						pageSize: pageSize,
						filters: filters,
						orderBy: orderBy,
						pageNumber: pageNumber
					});

					if (checkResponse(d, msgRef)) return;

					setPageTotal(d.pageTotal);
					setRecordsTotal(d.recordsTotal);
					setData(d[result]);
				};

				doRequest();
			}
		}, [
			canRequest,
			filters,
			msgRef,
			orderBy,
			pageNumber,
			pageSize,
			request,
			result
		]);

		// This needs to be callback because otherwise inside TableHeader
		// it will be doing a request every time it's rendered
		const updateFilterCallback = useCallback((obj: StringIndexed) => {
			const f: pairT<string, F>[] = [];

			for (const a of Object.keys(obj)) {
				//If it's a date the it will be an array
				if (Array.isArray(obj[a])) {
					// The matching is required because the date selection is done in only one field
					f.push({
						key: (a + 'Start') as F,
						value: treatDate(obj[a][0], true)
					});
					f.push({ key: (a + 'End') as F, value: treatDate(obj[a][1], true) });
				} else {
					if (obj[a] !== '') f.push({ key: a as F, value: obj[a] });
				}
			}

			setFilters(f);
			setCanRequest(true);
			//NOTE!: this is because of the type definitions
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const calculateHeader = useCallback(() => {
			const headerProps = { ...header.props };

			headerProps.clearData = () => {
				setFilters([]);
				setFiltersData({});
				setMultiSorMeta([]);
				setOrderBy([pair(defaultOrderBy.key, defaultOrderBy.value)]);

				setPageNumber(1);
				setPageTotal(0);
				setFirst(0);

				setCanRequest(true);
			};

			headerProps.getData = () => {
				setCanRequest(true);
			};

			headerProps.updateFilter = updateFilterCallback;

			headerProps.tableBody = Array.isArray(children) ? children : [children];

			return headerProps;
		}, [
			children,
			defaultOrderBy.key,
			defaultOrderBy.value,
			updateFilterCallback,
			header.props
		]);

		const [headerProps, setHeaderProps] = useState(() => calculateHeader());

		// Header
		useEffect(() => {
			setHeaderProps(calculateHeader());
		}, [calculateHeader]);

		return (
			<>
				{messages}
				{titleComponent}
				<DataTable
					autoLayout={true}
					header={<TableHeader<T, F, O> {...headerProps} />}
					first={first}
					rows={noPaging ? undefined : pageSize}
					totalRecords={recordsTotal}
					footer={
						//TODO set this is the label selector
						noPaging ? null : recordsTotal === 0 ? (
							<span style={{ color: 'grey', fontSize: '1' }}>
								Sem Resultados
							</span>
						) : null
					}
					onPage={(e) => {
						// Pages are 0 indexed by the table but 1 index by the server
						// So this conversion is necessary

						//TODO check why this works
						// As per documentation this should not work
						// So the event must have some extra variables
						setPageNumber((e as KnownAny).page + 1);

						setFirst(e.first);

						// This sets it so that the request can be made
						setCanRequest(true);
					}}
					//This hides the paginator if there is no pages
					paginator={!noPaging && pageTotal > 1}
					filters={filtersData}
					onFilter={(e) => {
						if (!e) return;
						setFiltersData(e.filters);
						const filters = [];

						// Get all columns
						const d = document.getElementsByClassName('p-filter-column');

						//Loop through all the columns
						for (let i = 0; i < d.length; i++) {
							if (
								d[i].children[0] &&
								d[i].children[0].outerHTML.startsWith('<input') &&
								(d[i].children[0] as KnownAny).value !== ''
							)
								if (toFilter[i])
									filters.push({
										key: toFilter[i],
										value: (d[i].children[0] as KnownAny).value
									});
						}

						setFilters(filters);
						setPageNumber(1);

						//Do the call to the server in 1 second if nothing changes
						if (filterTimeoutRef.current)
							clearTimeout(filterTimeoutRef.current);

						//typescript can not figure out the type due to node js type
						filterTimeoutRef.current = setTimeout(() => {
							setCanRequest(true);
							filterTimeoutRef.current = null;
						}, 1000) as KnownAny;
					}}
					onSort={(e) => {
						const field = e.multiSortMeta[0].field;
						const order = e.multiSortMeta[0].order;

						let update = false;

						const msm = multiSortMeta.map((a) => {
							if (a.field === field) {
								update = true;
								a.order = order;
							}
							return a;
						});

						if (!update) msm.push({ field: field, order: -order });

						//TODO fix this
						const orderBy: pairT<'desc' | 'asc', O>[] = msm.map((a) => ({
							key: a.field as KnownAny,
							value: a.order === 1 ? 'asc' : 'desc'
						}));

						setMultiSorMeta(multiSortMeta);
						setOrderBy(orderBy);
						setCanRequest(true);
					}}
					multiSortMeta={multiSortMeta}
					sortMode="multiple"
					value={data}
					responsive
					lazy
				>
					{calculatedBody}
				</DataTable>
			</>
		);
	};

	const TableSelector = <
		T extends StringIndexed,
		Filters extends string = string,
		OrderBy extends string = string,
		Result extends string = string
	>({
		hidden,
		wraperClass,
		labelClass = 'p-col-3',
		label,
		inputClass = 'p-col-5',
		value,
		required,
		disabled,
		jump,
		jumpCondition,

		result,
		header,
		request,
		table,
		onChange,
		pageSize,
		defaultOrderBy,
		noPaging
	}: TableSelectorProps<T, Filters, OrderBy, Result>) => {
		const [visible, setVisible] = useState(false);

		if (hidden) return null;

		const setDialog = (value: boolean, e?: React.MouseEvent<KnownAny>) => {
			if (e) e.preventDefault();
			setVisible(value);
		};

		const _onChange = (t: T) => {
			setDialog(false);
			onChange(t);
		};

		label = ls(label, '_', label);

		return (
			<div className={wraperClass}>
				<Dialog
					header={ls('select', 'btt')}
					visible={visible}
					style={{ width: '80vw', height: '80vh' }}
					onHide={() => setVisible(false)}
				>
					<Table
						pageSize={pageSize}
						defaultOrderBy={defaultOrderBy}
						result={result}
						header={header ?? <TableHeader />}
						request={request}
						children={table(_onChange)}
						noPaging={noPaging}
					/>
				</Dialog>
				<div className="p-grid p-fluid">
					<div className={labelClass}>{label}</div>
					<div className={inputClass}>
						<InputText
							value={value}
							required={required}
							onClick={(e) => setDialog(true, e)}
							disabled={disabled}
						/>
					</div>
					<Button
						wraperClass="p-col-1"
						label=""
						icon="pi pi-plus"
						onClick={(e) => setDialog(true, e)}
						type="button"
						disabled={disabled}
					/>
					{(() =>
						jump && jumpCondition ? (
							<Button
								wraperClass="p-col-1"
								label=""
								type="button"
								icon="pi pi-pencil"
								bttClass="p-button-warning"
								onClick={(e) => {
									e.preventDefault();
									if (jump) jump();
								}}
							/>
						) : null)()}
				</div>
			</div>
		);
	};

	return [Table, TableHeader, TableSelector];
};
