import {
	GenericListResponseG,
	GenericResponseG,
	StringDate,
	UUID
} from '../../Generic';
import {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../Requests';

export type Filters =
	| 'incidentIdUUID'
	| 'creationUserName'
	| 'queryType'
	| 'description'
	| 'read'
	| 'notifyDestinationStatus'
	| 'status'
	| FilterDateType<'dateCreation'>;

export type OrderBy = 'dateCreation';

export interface IncidentInteractionModel {
	idUUID: UUID;
	incidentIdUUID?: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	queryType: number;
	status: number;
	read: number;
	destinationUserIdUUID: UUID;
	description: string;
	notifyDestinationStatus: number;
	notificationType: number;
	creationUserIdUUID: UUID;
	creationUserName: string;
}

export const incidentinteractionlist =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidentQuery', IncidentInteractionModel> =>
		req.axios.post(`${baseUrl}/queries`, data);

export const incidentinteractionadd =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		incidentQuery: IncidentInteractionModel;
	}): Promise<GenericResponseG<'incidentQuery', IncidentInteractionModel>> =>
		req.axios.post(`${baseUrl}/query`, data);

export const incidentinteractionresponseadd =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		incidentQuery: IncidentInteractionModel;
	}): Promise<GenericResponseG<'incidentQuery', IncidentInteractionModel>> =>
		req.axios.post(`${baseUrl}/query/response`, data);
