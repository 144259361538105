export interface AddressModel extends AddressCreateModel {
	addressTypeDescription: string;
	idUUID: string;
}

export enum AddressType {
	main_address = 1,
	billing_address = 2,
	delivery_address = 3,
	other_address = 4
}

export interface AddressCreateModel {
	addressType: AddressType;

	zipCode: number | string;

	city: string;
	complement: string;
	state: string;
	street: string;
	streetZone: string;
	port: string;

	country?: number;
	latitude?: number;
	longitude?: number;
	addressTypeDescription?: string;
	referenceIdUUID?: string;
}
