import { StringDate, UUID } from '../../Generic';

export enum ProfileType {
	Normal = 1,
	Sistema = 2,
	Grupo = 3
}

export interface ProfileModel {
	idUUID: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	description: string;
	longDescription: string;
	languageTag: string;
	profileStatus: number;
	profileDescription: string;
	profileType: ProfileType;
}
