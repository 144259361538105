import axios from './AxiosService';

class UserService {
    
    async add(data) {
        return await axios.request('POST', `/help/add`, data)
    }
    
    async update(data) {
        return await axios.request('PUT', `/help/update`, data)
    }

    async remove(idUUID) {
        return await axios.request('DELETE', `/help/${idUUID}`)
    }
}

export default new UserService();
