const dev = {
    API_URL: 'https://api-dev.usenumo.com.br/api/v1',
    SOCKET: 'https://api-dev.usenumo.com.br/protector',
    TOKEN: '3Kl7I6mkakqnkfMIzr4aPpwuF318jT0zJfZKH8l7yHzYXjdHTAfYObAXHf9g5mOaIdIKlptEoE3+IEk8M6tXA+4PiaFX0NkXZTF1F2JGENf3y5x7Vu97FOyFPNMUU4IyyWkWM3gUhaFhbhP2E/FQSsF/qsWHrJNOcb8b8akw33AcM1U86XGgnKomM/urWelj6EVWU42RUaRtryOG2snh1ierEHtdsGBXCqs9KEcIFTpnhCTXj6368oQerMcgEcyuldkVPVq2K4iDRUIS1n0hAi1ZJV/pPrdbh7BT6m9qTeTv96WFCBzZzax5jd9yT125+5RP+B67aOt0A+Ev+SkpRI6jq0CAqi+XJJB0Dp3JiWDUJ+9WtiP+6sJM+qtGQt8rHK+IWntzdm/8p5dLZo8IHtRzQPsEwCuhPCwvhEAhtVcTXFAlqIFyuDbyBfC4c8clcHPg9rzlXf+t6f5JB53sLqMWre6JgXeI2AK3F7UPYB5AsNfKM739Q4Xe42dogDCB9I',
    KEY: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0OwokC6hjTOeswZww9/9\ndlIfDHzcG34W/dSJfgyXNSVw16jpMFEOtx6r2VZSe1XIT0kjgnGP++MmQnBnc2v2\n+o7pab8CiV/+T14gjUyoXrF6ycEwsD/YZ9zecJC64chScJ9PoSQi3jqhUXuoQ4Nk\nBjhqJifmPz142g132fNsHWaNMINAeTaXeyvoG3fT2wUD+Ru9K9HTPQ5fdRianJKA\niBnmqvMx5DDjfmUaQbW5GDaf0RkPx8Z1HhU8nggrmMqumCQOy8yQx8+RsY3V0RXP\nv+znxO84ZvL/S7By1lGbGRmx+mtEb/D5GfSXL3dIro6KmGZ9lrD4HhbNfy1ifreA\nu3EZlxL+P2fGv6aGclDB2v+lPlPCU697IYJz012YvpVFOtyw15JL9/7c/tjpHs7R\ntv1sLMT9RTjKVnlleIk2MT6c4YXi2P0SKGWyW+/Rp8uOBO6Aw6T+dJFLsPvg+lBv\nzGQ74xQO96w1jSRqHkEWN6A61D6cYSw8p6/AUZaaY82Tl8DVU1m8XLHfxRx2LkMG\nUBIFQkebvW9S9hy9hV0RpIFU2/rAE91jVX4OyPTNAyrchDuSoqUBSJiVtWYGpoN4\n6udUIa/u3m+D078pzPxlWdB5PjXMOxhuypPFpnF/vOmJRJ6rKx3KN2ju+f/La40Y\nacIqptMFq8harV/rqUmHa7MCAwEAAQ==\n-----END PUBLIC KEY-----',
    PHOTO_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/photo/',
    PHOTO_CONTRACT_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/contract/',
    PHOTO_PRODUCT_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/product/',
    PHOTO_NOTIFICATION_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/notification/',
    PHOTO_CAMPAIGN_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/campaign/',
    PHOTO_PROMOTION_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/promotion/',
    PHOTO_SEARCHAREA_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/searcharea/',
    DOCUMENT_URL: 'https://binary-dev.usenumo.com.br/uploads/documents/',
    BATCH_DOCUMENT_URL: 'https://binary-dev.usenumo.com.br/downloads/batch/',
    BANKINGBILLET_URL: 'https://binary-dev.usenumo.com.br/downloads/bankingbillet/',
    HEALTH: 'https://binary-dev.usenumo.com.br/downloads/health/',
    currency: 'BRL',
    locale: 'pt-BR',
};

const prod = {
    API_URL: 'https://api.drplay.com.br/api/v1',
    SOCKET: 'https://api.drplay.com.br/protector',
    TOKEN: 'FYpoRJuFivtbjp5exi+nPxcRwm47VsPf6Mz3P1u4C0xk74QnkzlcH9WM5uBIXmn3b9z+HCcrvvyFekCtRi3T1tWSxqrCwnzYRr6JPEx/tdEmVA1WUPN64x9ioQN9GLMYwwEPNpaDApCNhZ5p6myA6IFE+twdhBy2UDEk7Yt8BMD0D+0IT6JyJolx9fWTlhKSJs2vA7kUTCIjiC6AM1bHQzRqnrywegJGJHuDUgVHVCiuK0sI0pYlDjUM5h+XGPvGzd4AcC5+A9kNSHc+3OQNqVDUj0VKJMevgcha7tzntxAReYamxqV2cU4vNATbB2z94D/S04eBysMIyO5Dffsuey2SQGFY7VUzwRAtEP5gkId4bXOYgZto4PvX6T2rNTnfsUQpoMPjYMGXNWbNlyumdIPZZfl3QphoTnigWj2bJySttVfjfyvNoiKBjQJ1yyw9qSLmH1MCPBN78lhVoDvXwz4ZDk1If4XeDJU6kLJEKumxfA2nswOyBRi8OvDQQs2Yq4',
    KEY: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3LlKLfm2zRf1adRsXUEj\ntzPATj87p6nLsuJBLs0f9HCQW+H3XRXXJlCj0E52fussdWjExAhp0sECV8BHRVrz\n/6CiVm/YTxhQw1atGefKDDyeCe6qDu2iybtx2g9fhXv4n3shZOC+RZoTpw1CcFro\nGlkqpyx/wGeE2TAVomjLDO9l5Lbrlcbi2DhjWF3KH/YcuNjDDWJcCVxRSnqVnudy\nEZC5LorNqZStOt6Nd13yXqSi1cn/M0sotgSuO8uZ9Fo6tQfoo+I1K/7LqZYHJO6M\nYu8FC7brVCeiXkFWJQHyhr/4LKWzX9rgfNRqdQsT3o4/r2F9TB4qMW9pZ6QUtTE9\nGmcwCDG77zgfPBRbyFn1m0ktMALxLg0nbBihUg2HeySjJMl4TW7DDZtVIHNwFNVW\nSHbYnaBjqu0zvzvNur6YSe+Zd9+Uro9r570A2VcoKpNtjgkMJtUxvO7CuOr6ud4y\n03EV7SnLYvstg4vPMIWhdGi6UE/24BS2USbvosWU/kOIQd8eB1cP4v6Y7pXB8oMm\nqffVYnn8iHnJgwS73OQ6lf4C4PRHJTMrHOP6wJYaHt/A5aZTiXTqqL8qbo6/z5Ic\nCsL1MwyXm1qt/G9VfnctKLTaSGDkSTP2qlponeX6RTbMpskKY9M0hqYDDXgFMMMm\n5xlsU2VVJb6tEdRRZ5AQSmUCAwEAAQ==\n-----END PUBLIC KEY-----',
    PHOTO_URL: 'https://binary.drplay.com.br/uploads/documents/photo/',
    PHOTO_PRODUCT_URL: 'https://binary.drplay.com.br/uploads/documents/product/',
    PHOTO_CONTRACT_URL: 'https://binary.drplay.com.br/uploads/documents/contract/',
    PHOTO_NOTIFICATION_URL: 'https://binary.drplay.com.br/uploads/documents/notification/',
    PHOTO_CAMPAIGN_URL: 'https://binary.drplay.com.br/uploads/documents/campaign/',
    PHOTO_PROMOTION_URL: 'https://binary.drplay.com.br/uploads/documents/promotion/',
    PHOTO_SEARCHAREA_URL: 'https://binary.drplay.com.br/uploads/documents/searcharea/',
    DOCUMENT_URL: 'https://binary.drplay.com.br/uploads/documents/',
    BATCH_DOCUMENT_URL: 'https://binary.drplay.com.br/downloads/batch/',
    BANKINGBILLET_URL: 'https://binary.drplay.com.br/downloads/bankingbillet/',
    HEALTH: 'https://binary.drplay.com.br/downloads/health/',
    currency: 'BRL',
    locale: 'pt-BR',
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export default config;

//VERSION
const patch = process.env.REACT_APP_PATCH_HASH ?? '0';
export const version = `v1.0.10.${patch}`;

//IdUUIDs
export const PROTETORIDUUID = process.env.NODE_ENV === 'development' ? '6320041c-72bf-4c47-a1ba-45bb5731dc61' : '0e7dd573-0ca9-4616-b9bd-2fa619a62db5';
export const DRMOZUUID = process.env.NODE_ENV === 'development' ? '894f2645-75a8-4e0f-9369-df6af91bc2d4' : '';
export const isNUMO = !process.env.REACT_APP_PORT || process.env.REACT_APP_PORT === '3000';
export const isProtetor = process.env.REACT_APP_PORT === '4000';
export const isDrMoz = process.env.REACT_APP_PORT === '4100';

//colors
export function loginBackground() {
    let themes: {
        [key: string]: string;
    } = {
        _: '#E9EDF0',
        '4100': '#E9EDF0',
        '4300': '#dfe5ea',
        //            () => {
        //            ,
        //            document.body.style.cssText += " background: rgb(5,209,126); background: linear-gradient(180deg, rgba(5,209,126,1) 0%, rgba(0,180,116,1) 30%, rgba(1,130,88,1) 55%, rgba(0,70,49,1) 100%) !important;";
        //        }
    };
    return themes[String(process.env.REACT_APP_PORT)] ?? themes._;
}

export function loginImage() {
    let themes: {
        [key: string]: any;
    } = {
        _: { image: 'assets/images/protetor.png' },
        '4100': { image: 'assets/images/drmozbackground.png' },
        '4300': { image: 'assets/images/logoexisto.png' },
    };
    return themes[String(process.env.REACT_APP_PORT)] ?? themes._;
}

export const languages = [
    { label: 'PT', value: 'PT' },
    { label: 'EN', value: 'EN' },
];
