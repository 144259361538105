import type {
	RequestsClass,
	GenericListResponseG,
	GenericListRequest
} from '../..';
import type { Blog } from '..';
import { createDropdownEndpointData } from '../../UtilsTypes';

export type ListOrderBy = 'dateCreation';

export const list =
	(req: RequestsClass, url: string) =>
	(
		data: GenericListRequest<string, ListOrderBy>
	): GenericListResponseG<'blogs', Blog> =>
		req.axios.post(`${url}/list`, data);

export const types = (req: RequestsClass, url: string) =>
	createDropdownEndpointData<'blogTypes'>(req, `${url}/blogTypes`);

export const categories = (req: RequestsClass, url: string) =>
	createDropdownEndpointData<'blogCategories'>(req, `${url}/blogCategories`);
