import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import CampaignService from '../../service/CampaignService';
import { CampaignProductsList } from '../campaign/CampainProductsList';
import { g_checkLoginACL, g_displayMessageError, g_genericRequired, g_getData, g_getHeaderTemplatecF, g_hideSearch, g_template_dataTable, LS } from '../GenericFunctions';

export default class CampaignProductsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        !this.props.context.state.idUUID ? null : g_getData(this, CampaignService.getCampaignItemList, 'campaignItens', { campaignIdUUID: this.props.context.state.idUUID });

    deleteClick = async data => {
        let response = await CampaignService.removeCampaignItem({}, data.campaignItemIdUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(
            e => (
                <div class="p-col">
                    <Messages ref={el => (this.messages = el)} />
                    <CampaignProductsList
                        visible={this.state.visible}
                        onHide={() => this.setState({ visible: false }, this.getData)}
                        campaignIdUUID={this.props.context.state.idUUID}
                    />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(this, () => this.setState({ visible: true }), this.getData, e.c === 1),
                        [
                            { type: 'd', data: 'name', sortable: true, filter: true },
                            { type: 'd', data: 'shortDescription', sortable: true, filter: true },
                            { type: 'd', data: 'productTypeDescription', sortable: true, filter: true },
                            { type: 'btt', extra: 'p-button-secondary', click: this.deleteClick, icon: 'pi pi-trash', tooltip: LS('delete', 'tooltip') },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'campaignProduct#products'
        );
    }
}
