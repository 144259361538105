import React from 'react';
import CardService from '../../service/CardService';
import EncryptPassword from '../utils/EncryptPassword';
import {
    lm,
    lt,
    ls,
    lb,
    S,
    g_ApiCard,
    g_ChangeStatus,
    g_checkLoginACL,
    g_displayMessageError,
    g_tabViewRenderOptions,
    g_template_form,
    g_wraper,
    g_genericRequired,
    g_hideSearch,
    g_getData,
    g_template_dataTable,
} from '../GenericFunctions';
import Log from '../logs/Log';

export const status = {
    1: { class: 'pi pi-check', color: 'green' }, // Active
    2: { class: 'pi pi-times', color: 'red' }, // Deactivated
    3: { class: 'pi pi-trash', color: 'red' }, // Deleted
    4: { class: 'pi pi-clock', color: 'red' }, // Expired
};

export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiCard(), items: [{ label: lt('card') }] };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');

        if (!id) {
            if (!window.consignee) {
                window.close();
                return;
            }
            this.setState({ data: { vatNumberUser: window.consignee.vatNumber } });
        }
        this.setState(
            {
                idUUID: id,
                mode: query.get('type') !== '1',
                items: [
                    { label: lt('card') },
                    ...(id
                        ? [
                              { label: lt('movements'), icon: 'pi pi-list' },
                              { label: lt('log'), icon: 'pi pi-list' },
                          ]
                        : []),
                ],
            },
            this.getData
        );
    }

    updateStatus = (e, status) => g_ChangeStatus(this, () => CardService.updateStatus({ idUUID: this.state.idUUID, cardStatus: status }), this.getData, lm('simpleStatus'), this.messages, e);

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = { pageSize: 1, pageNumber: 1, filters: [{ key: 'idUUID', value: this.state.idUUID }] };
        let response = await CardService.list(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({ data: response.cards[0] });
    };

    handleSubmit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (!this.state.idUUID) {
            data.vatNumber = String(data.vatNumber ?? '').replace(/[^\d]/g, '');
            data.panMasked = String(data.panMasked ?? '').replace(/[^\d]/g, '');
            data.referenceIdUUID = window.consignee.idUUID;
            let date = data.expData.split('/');
            data.pciData = await EncryptPassword.encryptGen(
                ts => `{"pan":"${data.panMasked.replace(/[^\d]/g, '')}", "cvv2":"${data.cvv2}", "dateExpire":"${date[1]}-${date[0]}-10T00:00:00.000Z", "ts":"${ts}"}`
            );
            data.cvv2 = '';
            data.expData = '';
            let r = await S.card.add({ card: data });
            if (g_displayMessageError(this.messages, r)) return;
            window.location.href = `/#/card?type=1&id=${r.card.idUUID}`;
            window.location.reload();
            return;
        }
        let response = await S.card.update({ card: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                {
                                    t: 'array',
                                    dTypeF: 'view',
                                    origin: this.state.data,
                                    nshow: !this.state.idUUID,
                                    cc: 4,
                                    class: 2,
                                    dTypeFs: { 0: 't', 4: 'dElm', 5: 'check' },
                                    dElm: {
                                        4: g_wraper(
                                            'cardType',
                                            ls('cardType'),
                                            <div className="p-col-4">{{ 1: ls('1', 'cardType'), 2: ls('2', 'cardType'), 3: ls('3', 'cardType') }[this.state.data.cardType]}</div>,
                                            false,
                                            'p-col-2'
                                        ),
                                    },
                                    filter: ['description', 'panMasked', 'name', 'brand', 'cardType', 'cardDefault'],
                                    l: { 3: ls('brandCard') },
                                },
                                {
                                    t: 'array',
                                    dTypeFs: { _: 't', 5: 'check', 2: 'mask', 3: 'mask', 4: 'mask', 7: 'mask' },
                                    mask: { 2: ls('panMaked', 'mask'), 3: '999?9', 4: '99/9999', 7: ls('vatNumber', 'mask') },
                                    placeholder: { 2: ls('panMaked', 'placeholder'), 3: '999', 4: 'mm/aaaa', 7: ls('vatNumber', 'placeholder') },
                                    origin: this.state.data,
                                    nshow: this.state.idUUID,
                                    req: true,
                                    cc: 4,
                                    class: 2,
                                    filter: ['name', 'description', 'panMasked', 'cvv2', 'expData', 'cardDefault', 'cardType', 'vatNumber'],
                                },
                            ],
                            mode => [
                                { t: 'b', click: null, l: this.state.idUUID ? lb('save') : lb('create'), c: [3, 4].indexOf(this.state.data.cardStatus) !== -1, class: 1 },
                                {
                                    t: 'dil',
                                    class: 2,
                                    c: !this.state.idUUID || [3, 4].indexOf(this.state.data.cardStatus) !== -1,
                                    text: ls(`ask${this.state.data.cardStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    id: 'cardStatus',
                                    onConfirm: e => this.updateStatus(e, this.state.data.cardStatus === 1 ? 2 : 1),
                                    l: ls(this.state.data.cardStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                },
                                {
                                    t: 'dil',
                                    class: 2,
                                    c: !this.state.idUUID || [3, 4].indexOf(this.state.data.cardStatus) !== -1,
                                    disabled: mode || e.d !== 1,
                                    text: lm('askBlockP'),
                                    id: 'campaignStatusBloquear',
                                    onConfirm: e => this.updateStatus(e, 3),
                                    l: lb('block'),
                                    bttclass: 'p-button-danger',
                                },
                            ],
                            () => [
                                [
                                    {
                                        t: 'array',
                                        origin: this.state.data,
                                        nshow: !this.state.idUUID,
                                        filter: ['cardStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        class: 'l',
                                        states: { 0: status },
                                    },
                                ],
                            ],
                            this.handleSubmit,
                            1,
                            this.state.idUUID,
                            this.state.mode,
                            8,
                            4
                        ),
                        <CardPayments context={this} />,
                        <Log idUUID={this.state.idUUID} logContextType={25} />,
                    ])}
                </div>
            );
        });
    }
}

class CardPayments extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () => g_getData(this, CardService.payments, 'payments', { filters: [{ key: 'cardIdUUID', value: this.props.context.state.data.idUUID }] }, this.props.context.messages);

    render() {
        return (
            <div className="card">
                {g_template_dataTable(
                    this,
                    null,
                    [
                        { type: 'd', data: 'merchantName', sortable: true, filter: true },
                        { type: 'di', di: raw => ls(raw.paymentType, 'paymentType'), data: 'paymentType', sortable: true },
                        { type: 'd', data: 'value', sortable: true, filter: true },
                        { type: 'd', data: 'acquireAuthorizationCode', sortable: true, filter: true },
                        { type: 'd', data: 'brandReturnCode', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'paymentStatus',
                            states: statesPayment,
                        },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}

export const statesPayment = {
    0: { class: 'pi-step-forward', color: 'grey', tooltip: ls('inList', 'tooltip') },
    1: { class: 'pi-check', color: 'orange', tooltip: ls('noConfirmAuth', 'tooltip') },
    2: { class: 'pi-check', color: 'green', tooltip: ls('confirmationAuth', 'tooltip') },
    3: { class: 'pi-ban', color: 'red', tooltip: ls('denyed', 'tooltip') },
    4: { class: 'pi-clock', color: 'red', tooltip: ls('timeout', 'tooltip') },
    10: { class: 'pi-times', color: 'red', tooltip: ls('canceld', 'tooltip') },
    11: { class: 'pi-times', color: 'red', tooltip: ls('canceldAfterConfirmation', 'tooltip') },
    12: { class: 'pi-clock', color: 'orange', tooltip: ls('waitingForConfirmation', 'tooltip') },
    13: { class: 'pi-exclamation-triangle', color: 'red', tooltip: ls('canceldDueToFail', 'tooltip') },
    20: { class: 'pi-calendar', color: 'orange', tooltip: ls('scheduled', 'tooltip') },
    90: { class: 'pi-step-forward', color: 'orange', tooltip: ls('inQueuePreAquisition', 'tooltip') },
};
