import React, { Component } from 'react';
import CampaignService from '../../service/CampaignService';
import { g_checkLoginACL, g_displayMessageError, g_genericRequired, g_getHeaderTemplatecF, g_hideSearch, g_template_dataTable, LS } from '../GenericFunctions';
import { CampaignProductsList } from './CampainProductsList';

export default class CampaignProductsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = async () => {
        if (!this.props.context.state.idUUID) return;
        let filters = {
            campaignIdUUID: this.props.context.state.idUUID,
            filters: this.state.filter,
            orderBy: this.state.orderBy,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
        };
        let response = await CampaignService.getCampaignItemList(filters);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal,
            data: response.campaignItens,
        });
    };

    deleteClick = async data => {
        let response = await CampaignService.removeCampaignItem({}, data.campaignItemIdUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.props.context.messages.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(
            () => (
                <div className="card p-col-12">
                    <CampaignProductsList
                        visible={this.state.visible}
                        onHide={() => this.setState({ visible: false }, this.getData)}
                        campaignIdUUID={this.props.context.state.idUUID}
                    />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(this, () => this.setState({ visible: true }), this.getData),
                        [
                            { type: 'd', data: 'name', sortable: true, filter: true },
                            { type: 'd', data: 'shortDescription', sortable: true, filter: true },
                            { type: 'd', data: 'productTypeDescription', sortable: true, filter: true },
                            { type: 'btt', icon: 'pi pi-times', tooltip: LS('remove', 'btt'), extra: 'p-button-secondary', click: this.deleteClick },
                        ],
                        this.getData
                    )}
                </div>
            ),
            false,
            'campaign#productManagement'
        );
    }
}
