import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import config from '../../config/config';
import CampaignService from '../../service/CampaignService';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import GSelector from '../generic/GSelector';
import {
    S,
    LS,
    lb,
    g_ApiCampaign,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_getStateIcon,
    g_getWraper,
    g_handleChange,
    g_openWindowDialog,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_template_inputArea,
    g_template_numeric,
    g_treatNumric,
    g_treatNumricR,
    g_wraper,
    g_getDataFields,
    g_ChangeStatus,
    g_template_textcopy,
    ls,
} from '../GenericFunctions';
import Log from '../logs/Log';
import { ProductPackageProductsManagement } from './ProductsPackageProductsManagement';

export default class ProductsPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: g_ApiCampaign(3),
            mode: false,
            idUUID: null,
            holderPhotoBig: null,
            holderPhotoSmall: null,
            campaignModes: [],
            consignators: [],
            items: [{ label: LS('package', 'tab') }],
            productPackageRecurrences: [
                { label: LS('1', 'productPackageRecurrences'), value: '1' },
                { label: LS('2', 'productPackageRecurrences'), value: '2' },
                { label: LS('3', 'productPackageRecurrences'), value: '3' },
                { label: LS('4', 'productPackageRecurrences'), value: '4' },
                { label: LS('5', 'productPackageRecurrences'), value: '5' },
                { label: LS('6', 'productPackageRecurrences'), value: '6' },
            ],
            paymentModes: [
                { label: LS('1', 'paymentModes'), value: '1' },
                { label: LS('2', 'paymentModes'), value: '2' },
            ],
            visibilityModes: [
                { label: LS('0', 'visibilityModes'), value: '0' },
                { label: LS('1', 'visibilityModes'), value: '1' },
            ],
        };
    }

    updateStatus = (e, status, message) =>
        g_ChangeStatus(this, CampaignService.updateStatus({ idUUID: this.state.data.idUUID, campaignStatus: status }), this.getData, message, this.messages, e);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        g_getDataFields(this, CampaignService.getCampaignModes, 'campaignModes', 'description', 'id', 'campaignModes');
        this.getInterfacesNames();
        this.setState(
            {
                mode: mode,
                idUUID: idUUID,
                items: [
                    { label: LS('package', 'tab') },
                    { label: LS('products', 'tab') },
                    ...(g_checkAlc(4, 'product#configs') ? [{ label: LS('configs', 'tab') }] : []),
                    { label: LS('log', 'tab'), icon: 'pi pi-list' },
                ],
            },
            this.getData
        );
    }

    async getInterfacesNames() {
        let array = ['SICOOB', 'DRMOZ', 'PREVISUL', 'TENDENCIA', 'TRACK', 'PROTECTOR'];
        array.sort();
        array = ['DEFAULT', 'NUMO', ...array];
        this.setState({ interfaceNames: array.map(a => ({ value: a, label: a })) });
    }

    async getData() {
        if (!this.state.idUUID) return;
        const response = await CampaignService.getCampaign({}, this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.campaign;
        rdata.campaignMode = String(rdata.campaignMode ?? '');
        rdata.productPackageValue = g_treatNumricR(rdata.productPackageValue);
        rdata.productPackageRecurrence = String(rdata.productPackageRecurrence ?? '');
        rdata.packagePaymentMode = String(rdata.packagePaymentMode ?? '');
        rdata.packagePeriod = String(rdata.packagePeriod ?? '');
        rdata.visibility = String(rdata.visibility ?? '');
        rdata.allowMultiplePackage = String(rdata.allowMultiplePackage ?? '');
        this.setState({ data: rdata });
    }

    handleSubmit = async (e, configs) => {
        e.preventDefault();
        let data = this.state.data;
        if (data.dateStart) {
            let d = new Date(data.dateStart);
            data.dateStart = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (data.dateEnd) {
            let d = new Date(data.dateEnd);
            data.dateEnd = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        data.productPackageValue = g_treatNumric(data.productPackageValue);
        data.packageValue = g_treatNumric(data.packageValue);
        if (this.state.idUUID) {
            let response = configs ? await CampaignService.updateCampaignConfigs({ campaign: data }) : await CampaignService.updateCampaign({ campaign: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            let response = await CampaignService.addCampaign({ campaign: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            if (this.state.holderPhotoBig !== null) {
                await this.upload(this.state.holderPhotoBig, 302, response.campaign.idUUID);
                this.setState({ holderPhotoBig: null });
            }
            if (this.state.holderPhotoSmall !== null) {
                await this.upload(this.state.holderPhotoSmall, 301, response.campaign.idUUID);
                this.setState({ holderPhotoSmall: null });
            }
            window.location.href = `/#/packageProduct?type=1&id=${response.campaign.idUUID}`;
            window.location.reload();
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (!this.state.idUUID) {
            if (data.documentTypeId === 302) this.setState({ holderPhotoBig: formData });
            else this.setState({ holderPhotoSmall: formData });
        } else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'success', summary: LS('simpleUploadStart', 'messages') });
        var response = await CampaignService.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    configs = () => {
        if (!g_checkAlc(4, 'product#configs')) return null;
        let mode = this.state.mode || g_checkAlc(4, 'product#configs')[0].u !== 1 || !this.state.idUUID;
        console.log(this.state.data);
        return (
            <form className="p-col" onSubmit={e => this.handleSubmit(e, true)}>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                        {g_template_dropdown(
                            this,
                            'interfaceName',
                            this.state.data.interfaceName,
                            'Interface',
                            this.state.interfaceNames,
                            mode,
                            false,
                            false,
                            'p-col-5',
                            'p-col-2'
                        )}
                        {g_template_input(this, 'parameterA', this.state.data.parameterA, LS('parameterA'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterB', this.state.data.parameterB, LS('parameterB'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterC', this.state.data.parameterC, LS('parameterC'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterD', this.state.data.parameterD, LS('parameterD'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterE', this.state.data.parameterE, LS('parameterE'), mode, false, false, 'p-col-8', 'p-col-2')}
                        {g_template_input(this, 'parameterF', this.state.data.parameterF, LS('parameterF'), mode, false, false, 'p-col-8', 'p-col-2')}
                    </div>
                </div>
                <div className="p-grid p-fluid"> {g_template_button('Salvar', '', this.state.data.productStatus === '3', null, mode, 'p-col-1')} </div>
            </form>
        );
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form id="1" className="p-col" onSubmit={this.handleSubmit}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel className="p-fluid">
                                        {g_template_textcopy(this, 'ID', this.state.data.idUUID, 'idUUID', !this.state.idUUID, 'p-col-2')}
                                        <GSelector
                                            labelwraper="p-col-2"
                                            label={LS('consignatorName')}
                                            ref={e => (this.selector0 = e)}
                                            getData={S.consignator.processed.list}
                                            tableBody={[
                                                { type: 'd', data: 'name', sortable: true, filter: true },
                                                {
                                                    type: 'button',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector0.setState({ selected: raw.name, visible: false });
                                                        this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                                    },
                                                },
                                            ]}
                                            inputtextwarper="p-col-5"
                                            disabled={this.state.mode}
                                            startV={this.state.data.consignatorName}
                                        />
                                        {g_template_dropdown(
                                            this,
                                            'campaignMode',
                                            this.state.data.campaignMode,
                                            LS('campaignMode'),
                                            this.state.campaignModes,
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-6',
                                            'p-col-2'
                                        )}
                                        {g_template_input(this, 'name', this.state.data.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-2')}
                                        {g_template_input(
                                            this,
                                            'shortDescription',
                                            this.state.data.shortDescription,
                                            LS('shortDescription'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-8',
                                            'p-col-2'
                                        )}
                                        {g_template_inputArea(
                                            this,
                                            'longDescription',
                                            this.state.data.longDescription,
                                            LS('longDescription'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-8',
                                            'p-col-2',
                                            '',
                                            {
                                                minHeight: '10em',
                                            }
                                        )}
                                        {g_wraper(
                                            'visibility',
                                            LS('visibility'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'visibility',
                                                    this.state.data.visibility,
                                                    '',
                                                    this.state.visibilityModes,
                                                    this.state.mode,
                                                    false,
                                                    false,
                                                    'p-col-3',
                                                    'p-col-2'
                                                ),
                                                g_wraper(
                                                    'allowsSyncCall',
                                                    LS('allowsSyncCall'),
                                                    g_template_checkBox(this, 'allowsSyncCall', this.state.data.allowsSyncCall, '', this.state.mode, false, 'p-col-3', 'p-col-2'),
                                                    false,
                                                    'p-col-3',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'productPackageValue',
                                            LS('productPackageValue'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'packagePaymentMode',
                                                    this.state.data.packagePaymentMode,
                                                    '',
                                                    this.state.paymentModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2'
                                                ),
                                                g_template_dropdown(
                                                    this,
                                                    'packagePeriod',
                                                    this.state.data.packagePeriod,
                                                    '',
                                                    this.state.productPackageRecurrences,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-3'
                                                ),
                                                g_template_numeric(this, 'packageValue', this.state.data.packageValue, '', this.state.mode, false, true, 'p-col-2'),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'dateStart',
                                            LS('dateStart'),
                                            [
                                                <div className="p-col-2">
                                                    <Calendar
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        yearRange={`${new Date().getFullYear() - 2}:${new Date().getFullYear() + 10}`}
                                                        id="dateStart"
                                                        dateFormat="dd-mm-yy"
                                                        value={new Date(this.state.data.dateStart)}
                                                        onChange={e => g_handleChange(e, {}, this)}
                                                    />
                                                </div>,
                                                g_wraper(
                                                    'dateEnd',
                                                    LS('dateEnd'),
                                                    <div className="p-col-2">
                                                        <Calendar
                                                            id="dateEnd"
                                                            yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateFormat="dd-mm-yy"
                                                            value={new Date(this.state.data.dateEnd)}
                                                            onChange={e => g_handleChange(e, {}, this)}
                                                        />
                                                    </div>,
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_template_input(
                                            this,
                                            'messageSubscription',
                                            this.state.data.messageSubscription,
                                            LS('messageSubscription'),
                                            this.state.mode,
                                            false,
                                            false,
                                            'p-col',
                                            'p-col-4'
                                        )}
                                        {g_template_input(
                                            this,
                                            'messagePaymenyOverdue',
                                            this.state.data.messagePaymenyOverdue,
                                            LS('messagePaymenyOverdue'),
                                            this.state.mode,
                                            false,
                                            false,
                                            'p-col',
                                            'p-col-4'
                                        )}
                                        {g_template_dropdown(
                                            this,
                                            'allowMultiplePackage',
                                            this.state.data.allowMultiplePackage,
                                            LS('allowMultiplePackage'),
                                            [
                                                { label: LS('0', 'allowMultiplePackage'), value: '0' },
                                                { label: LS('1', 'allowMultiplePackage'), value: '1' },
                                                { label: LS('2', 'allowMultiplePackage'), value: '2' },
                                            ],
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-4',
                                            'p-col-4'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(
                                            null,
                                            'Estado',
                                            <div className="p-col">
                                                {g_getStateIcon(this.state.data.campaignStatus, '1.4em', {
                                                    1: { color: 'green', class: 'pi-check' },
                                                    2: { color: 'red', class: 'pi-times' },
                                                    3: { color: 'red', class: 'pi-ban' },
                                                })}
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(
                                            null,
                                            'Foto Principal',
                                            <div className="p-col-12 p-fluid p-grid">
                                                <div></div>
                                                <GPhotoUpload
                                                    style={{ height: '95px', width: '164px' }}
                                                    handlePhotoUpload={this.onUpload}
                                                    photo={this.state.data.campaignMainImage}
                                                    documentTypeId={301}
                                                    id="1"
                                                    prefixPath={config.PHOTO_PRODUCT_URL}
                                                    alt={window.location.origin + '/assets/images/maincampaign.png'}
                                                    disabled={this.state.mode}
                                                />
                                            </div>,
                                            false,
                                            'p-col-12'
                                        )}
                                        {g_getWraper(
                                            null,
                                            'Foto Agregada',
                                            <div className="p-col-12 p-fluid p-grid">
                                                <div></div>
                                                <GPhotoUpload
                                                    style={{ height: '113px', width: '164px' }}
                                                    handlePhotoUpload={this.onUpload}
                                                    photo={this.state.data.campaignAggregatedImage}
                                                    documentTypeId={302}
                                                    id="2"
                                                    prefixPath={config.PHOTO_PRODUCT_URL}
                                                    alt={window.location.origin + '/assets/images/agregadacampaign.png'}
                                                    disabled={this.state.mode}
                                                />
                                            </div>,
                                            false,
                                            'p-col-12'
                                        )}
                                        {g_getWraper(
                                            null,
                                            ls('campaignExtraImage'),
                                            <div className="p-col-12 p-fluid p-grid">
                                                <div></div>
                                                <GPhotoUpload
                                                    style={{ height: '113px', width: '164px' }}
                                                    handlePhotoUpload={this.onUpload}
                                                    photo={this.state.data.campaignExtraImage}
                                                    documentTypeId={303}
                                                    id="3"
                                                    prefixPath={config.PHOTO_PRODUCT_URL}
                                                    alt={window.location.origin + '/assets/images/agregadacampaign.png'}
                                                    disabled={this.state.mode}
                                                />
                                            </div>,
                                            false,
                                            'p-col-12'
                                        )}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.campaignStatus === 3, null, this.state.mode, 'p-col-2')}
                                {g_template_button(
                                    LS('copy', 'btt'),
                                    '',
                                    !this.state.idUUID,
                                    e => {
                                        e.preventDefault();
                                        g_openWindowDialog(`#/copyCampaign?type=1&id=${this.state.data.idUUID}`, `copy_${this.state.idUUID}`, 700, 500);
                                    },
                                    this.state.mode && e.c !== 1,
                                    'p-col-1',
                                    'p-button-warning'
                                )}

                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.userStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    'campaignStatus',
                                    e => this.updateStatus(e, this.state.data.campaignStatus === 1 ? 2 : 1, 'Estado alterado com sucesso!'),
                                    LS(this.state.data.userStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                    !this.state.idUUID || this.state.data.campaign === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlockP'),
                                    'campaignStatusBloquear',
                                    e => this.updateStatus(e, 3, 'Bloqueado com sucesso!'),
                                    lb('block'),
                                    !this.state.idUUID || this.state.data.campaign === 3,
                                    this.state.mode || e.d !== 1,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-2',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                        <ProductPackageProductsManagement idUUID={this.state.idUUID} />,
                        ...(g_checkAlc(4, 'product#configs') ? [this.configs()] : []),
                        <Log idUUID={this.state.idUUID} logContextType={10} />,
                    ])}
                </div>
            );
        });
    }
}
