import axios from 'axios';
import Config from '../config/config';
import { LoggedUserData } from '../types';
import '../App.scss';

class AxiosService {
    constructor() {
        let loggedUserN: LoggedUserData | null = null;
        try {
            loggedUserN = JSON.parse(localStorage.getItem('loggedUserN') ?? '') || {};
        } catch {
            loggedUserN = null;
        }
        axios.interceptors.response.use(undefined, async err => {
            if (err && err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem('loggedUserN');
                    window.location.href = '#/login';
                    document.location.reload();
                }
            }
        });
        axios.defaults.baseURL = Config.API_URL;
        axios.defaults.headers = {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            token: loggedUserN?.token ?? '',
        };
    }

    async request(method: 'POST' | 'PUT' | 'PATCH' | 'GET' | 'DELETE', url: string, data: any = null, headerParams: any = null) {
        let headers = { ...axios.defaults.headers, ...headerParams };

        let storage;
        try {
            storage = await JSON.parse(localStorage.getItem('loggedUserN') ?? '');
        } catch {
            storage = undefined;
        }

        headers.token = storage ? storage.token : Config.TOKEN;

        const config = {
            method,
            url,
            data,
            headers,
            sp_headers: {} as Record<string, any>
        };

        if (config.headers['request-message']) {
            config.sp_headers['request-message'] = config.headers['request-message'];
            delete config.headers['request-message']
        }
        let res = null;
        try {
            res = await axios(config);
            return res.data;
        } catch (err) {
            console.log(res);
            console.log(err);
        }
    }

    post(url: string, data: any = null, headerParams: any = null) {
        return this.request('POST', url, data, headerParams);
    }

    put(url: string, data: any = null, headerParams: any = null) {
        return this.request('PUT', url, data, headerParams);
    }

    delete(url: string, data: any = null, headerParams: any = null) {
        return this.request('DELETE', url, data, headerParams);
    }

    get(url: string, headerParams: any = null) {
        return this.request('GET', url, null, headerParams);
    }
}

export default new AxiosService();
