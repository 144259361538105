import React, { useEffect, useRef } from 'react';
import { pair, pairT } from '../../api-ts-bindings/Generic';
import { FilterParamConsigneeClockingList } from '../../api-ts-bindings/Requests';
import { Requests } from '../../Requests';
import { checkLoginACL, g_getData, StateIcon } from '../Generic';
import { ls, TablePage } from '../GenericFunctions';

interface ClockingMangamentProps {
    extraFilter: pairT<string, FilterParamConsigneeClockingList>[];
    checkConsignator: boolean;
}
export const ClockingMangament: React.FC<ClockingMangamentProps> = ({ extraFilter, checkConsignator }) => {
    let { error, profile, loggeduser } = checkLoginACL({});

    let tablePageRef: React.MutableRefObject<null | TablePage> = useRef(null);

    useEffect(() => {
        tablePageRef.current?.getData();
    }, [extraFilter, checkConsignator]);

    if (error) return error;
    return (
        <TablePage
            header={null}
            ref={e => (tablePageRef.current = e)}
            getData={c =>
                g_getData<any, FilterParamConsigneeClockingList, string>(
                    c,
                    data => {
                        if (checkConsignator && loggeduser!.profileACLs.filter(a => a.tag === 'adminignorefilter' && a.type === 5).length === 0) {
                            if (loggeduser!.consignatorList.length === 0) return { code: 0, clockings: [] };
                            data.filters = [...(data.filters ?? []), pair('consignatorsiduuid', loggeduser!.consignatorList.join(','))];
                        }
                        return Requests.consignee.clocking.list(data);
                    },
                    'clockings',
                    { filters: [...c.state.filter, ...extraFilter] }
                )
            }
            table={c => [
                { t: 'd', data: 'consigneeFullname' },
                { t: 'd', data: 'consigneeVatNumber' },
                {
                    t: 'di',
                    data: 'clockingType',
                    align: 'center',
                    di: raw => (
                        <div>
                            <StateIcon
                                state={raw.clockingActionType}
                                custom={{
                                    1: { class: 'pi-arrow-right', color: 'green' },
                                    2: { class: 'pi-arrow-left', color: 'red' },
                                    3: { class: 'pi-arrow-left', color: 'red' },
                                }}
                            />{' '}
                            <StateIcon state={raw.clockingType} custom={{ 1: { class: 'pi-home', color: 'grey' }, 2: { class: 'pi-pause', color: 'grey' } }} />{' '}
                        </div>
                    ),
                },
                { t: 'date', data: 'dateManualRecording' },
                { t: 'date', data: 'dateSystem' },
                { t: 'date', data: 'dateCreation' },
                {
                    t: 'state',
                    data: 'biometricRecognitionStatus',
                    states: () => ({
                        0: { class: 'pi-minus', color: 'red' },
                        1: { class: 'pi-user', color: 'green' },
                        2: { class: 'pi-volume-up', color: 'green' },
                        3: { class: '', color: 'green', text: '\u261D' },
                        101: { class: 'pi-user', color: 'red' },
                        102: { class: 'pi-volume-off', color: 'red' },
                        103: { class: '', color: 'red', text: '\u261D' },
                    }),
                },
                {
                    t: 'state',
                    data: 'clockingInputMode',
                    title: ls('mode', 'generic'),
                    states: () => ({
                        1: { class: 'pi-user-edit', color: 'greey' },
                        2: { class: 'pi-id-card', color: 'grey' },
                        3: { class: 'pi-mobile', color: 'grey' },
                    }),
                },
                {
                    t: 'state',
                    data: 'clockingStatus',
                    states: ClockingStatus,
                },
                { t: 'url', c: profile!.db || profile!.ub, url: raw => `#/clocking?type=1&id=${raw.idUUID}`, width: 1000, height: 450, callback: c.getData },
            ]}
        ></TablePage>
    );
};

export const ClockingStatus = {
    0: { class: 'pi-spin pi-spinner', color: 'grey' },
    1: { class: 'pi-check', color: 'green' },
    2: { class: 'pi-step-forward', color: 'orange' },
    3: { class: 'pi-ban', color: 'red' },
};
