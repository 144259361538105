import React from 'react';
import { S, g_dataTable, g_hideSearch, g_genericRequired, g_getData, g_checkLoginACL } from '../GenericFunctions';
import { Messages } from 'primereact/messages';

export default class Log extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        this.props.getData
            ? this.props.getData()
            : g_getData(
                  this,
                  data => {
                      if (!this.props.idUUID || !this.props.logContextType) return { code: 0, recordsTotal: 0, pageTotal: 0, logs: [] };
                      data.entityReferenceIdUUID = this.props.idUUID;
                      data.logContextType = this.props.logContextType;
                      return S.listLogs(data);
                  },
                  'logs',
                  (this.props.overideData ?? (() => ({})))(this)
              );

    render() {
        return g_checkLoginACL(() => {
            const style = raw => {
                return { color: raw.logTypeDescription === 'Error' ? 'red' : raw.logTypeDescription === 'Warning' ? '#ffba01' : '' };
            };
            const cline = (t, raw) => <div style={{ padding: '0px', margin: '0px', ...style(raw) }}>{t}</div>;
            return (
                <div className="card">
                    <Messages ref={e => (this.messages = e)} />
                    {g_dataTable(
                        this,
                        null,
                        [
                            { t: 'd', data: 'requestUsername', align: 'center', sortable: true, filter: true, dataMap: cline },
                            { t: 'd', data: 'message', sortable: true, filter: true, dataMap: cline },
                            { t: 'd', data: 'context', align: 'center', sortable: true, filter: true, dataMap: cline },
                            { t: 'd', data: 'logTypeDescription', align: 'center', sortable: true, filter: true, dataMap: cline },
                            { t: 'd', c: this.props.tid ?? false, data: 'tId', align: 'center', sortable: true, filter: true, dataMap: cline },
                            { t: 'date', data: 'dateCreation', align: 'center', sortable: true, filter: true, dataMap: cline },
                        ],
                        this.getData,
                        this.props.ad
                    )}
                </div>
            );
        }, false);
    }
}
