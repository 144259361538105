import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React from 'react';
import { pair } from '../../api-ts-bindings/Generic';
import { Requests } from '../../Requests';
import { checkLoginACL, g_getData } from '../Generic';
import { g_displayMessageError, g_formMaper, g_getHeaderTemplate, g_template_button, g_treatDate, ls, S, TablePage } from '../GenericFunctions';
import { businessHours } from './BusinessHours';

interface BusinessHoursListProps {
    fakename?: string;
    checkPageAcl?: boolean;
    provider: string;
    messages?: Messages;
}
export const BusinessHoursList = ({ fakename = '', checkPageAcl = false, provider: providerIn, messages }: BusinessHoursListProps) => {
    let { error, loggeduser } = checkLoginACL({ fakename, checkPageAcl });

    let provider = providerIn ?? loggeduser?.providerList[0] ?? '';

    if (error) return error;
    return (
        <TablePage
            checkAcl={false}
            rg={[pair('showOrder', 'asc')]}
            header={(c, e) => g_getHeaderTemplate(c, businessHours.url(null, provider), 'new business', c.getData, businessHours.size[0], businessHours.size[1], e.c)}
            table={(c, e) => [
                { t: 'map', data: 'weekday', objs: businessHours.weekDaysArray, sortable: true },
                { t: 'd', data: 'extraDescription', filter: true, sortable: true },
                { t: 'd', data: 'periodOneStart', filter: true, sortable: true },
                { t: 'd', data: 'periodOneEnd', filter: true, sortable: true },
                { t: 'd', data: 'periodTwoStart', filter: true, sortable: true },
                { t: 'd', data: 'periodTwoEnd', filter: true, sortable: true },
                { t: 'url', c: e.d === 1 || e.u === 1, url: r => businessHours.url(r.idUUID), width: businessHours.size[0], height: businessHours.size[1], callback: c.getData },
            ]}
            getData={c =>
                g_getData(
                    c,
                    Requests.businessHours.list,
                    (res, call) =>
                        c.setState({
                            data: res.businessHourss.map(a => ({
                                ...a,
                                periodOneEnd:
                                    a.businessHoursType === 1 && a.businessHoursCategory !== 3
                                        ? String(a.periodOneEnd)
                                              .padStart(4, '0')
                                              .split('')
                                              .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                        : '-',
                                periodOneStart:
                                    a.businessHoursType === 1 && a.businessHoursCategory !== 3
                                        ? String(a.periodOneStart)
                                              .padStart(4, '0')
                                              .split('')
                                              .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                        : '-',
                                periodTwoEnd:
                                    a.businessHoursType === 1 && a.businessHoursCategory === 2
                                        ? String(a.periodTwoEnd)
                                              .padStart(4, '0')
                                              .split('')
                                              .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                        : '-',
                                periodTwoStart:
                                    a.businessHoursType === 1 && a.businessHoursCategory === 2
                                        ? String(a.periodTwoStart)
                                              .padStart(4, '0')
                                              .split('')
                                              .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                        : '-',
                            })),
                            ...call,
                        }),
                    { filters: [...c.state.filter, pair('providerIdUUID', provider)] }
                )
            }
            stateE={{ inputData: { start: '', end: '', mininum: '', period: '' } }}
            addon={c => (
                <>
                    <div className="p-grid p-fluid">
                        <div className="p-col-9" />
                        {g_template_button(ls('automaticFiller', 'btt'), '', false, (() => c.setState({ showNewOne: true })) as any)}
                    </div>
                    <Dialog
                        header={ls('addNewOne', 'titles')}
                        onHide={() => c.setState({ showNewOne: false })}
                        visible={c.state.showNewOne}
                        style={{ minWidth: '70vw', minHeight: '70vh' }}
                    >
                        <Messages ref={e => (c.messages = e)} />
                        <form
                            onSubmit={async e => {
                                console.log(e);
                                e.preventDefault();
                                if (!c.state.inputData.end || !c.state.inputData.start || !c.state.inputData.mininum || !c.state.inputData.period) return;
                                let data = {
                                    dateEnd: g_treatDate(c.state.inputData.end),
                                    dateStart: g_treatDate(c.state.inputData.start),
                                    eventDuration: c.state.inputData.mininum,
                                    eventInterval: c.state.inputData.period,
                                    providerIdUUID: provider,
                                };
                                let a = await S.scheduling.autoGenerate(data);
                                if (g_displayMessageError(c.messages, a)) return;
                                if (messages) messages.show({ severity: 'success', summary: ls('messageSucess', 'dialogBusiness') });
                                c.setState({ showNewOne: false });
                            }}
                        >
                            <div style={{ minWidth: '70vw', height: '60vh' }}>
                                {g_formMaper(
                                    c,
                                    [
                                        {
                                            t: 'array',
                                            origin: c.state.inputData,
                                            dTypeFs: { _: 'date', 2: { 0: 't', 1: 'text' }, 3: { 0: 't', 1: 'text' } },
                                            prefix: { _: 'dialogBusiness' },
                                            handleExtraData: {
                                                _: { data: 'inputData' },
                                                2: { 0: { regex: new RegExp('\\d*'), data: 'inputData' } },
                                                3: { 0: { regex: new RegExp('\\d*'), data: 'inputData' } },
                                            },
                                            filter: ['start', 'end', ['mininum', ls('minutes', 'text')], ['period', ls('minutes', 'text')]],
                                            cc: { 2: { 0: 3 }, 3: { 0: 3, 1: 1 } },
                                        },
                                    ],
                                    false,
                                    3 as any
                                )}
                            </div>
                            <div style={{ minWidth: '70vw', height: '10vh' }}>{g_template_button(ls('generate', 'btt'), '', false, null)}</div>
                        </form>
                    </Dialog>
                </>
            )}
        />
    );
};
