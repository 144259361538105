import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import UserService from '../../service/UserService';
import ProfileService from '../../service/ProfileService';
import ConsignatorService from '../../service/ConsignatorService';
import {
    LS,
    pair,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_entities,
    g_entityFilter,
    g_genericRequired,
    g_getData,
    g_getHeaderTemplatecF,
    g_hideSearch,
    g_template_button,
    g_template_dataTable,
    g_template_dropdown,
} from '../GenericFunctions';
import GAddNew from '../profile/G_AddNew';

export default class AssociationConsignatorUser extends Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), selectedProfile: '' };
    }
    componentDidMount() {
        g_getData(
            this,
            ConsignatorService.parameterList,
            response => this.setState({ profiles: response.parameters[0]?.parameterValue.split('|').map(a => ({ label: a.split(':')[0], value: a.split(':')[1] })) }),
            { filters: [...this.state.filter, { key: 'referenceIdUUID', value: this.props.idUUID }, { key: 'nameequals', value: 'PROFILES' }] }
        );
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.idUUID
            ? g_getData(this, UserService.entityList, 'userEntities', {
                  filters: [pair('entityIdUUID', this.props.idUUID), pair('entityUserStatus', 1), pair('entityUserType', 1), ...this.state.filter],
                  userType: 1,
              })
            : () => {};

    remove = async row => {
        if (!row.idUUID) return;
        const response = await UserService.entityRemove(row.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
        this.getData();
    };

    getAddNewData = context =>
        g_getData(
            context,
            data => {
                let f = g_entityFilter(context.state.filter, 'entitiesIdUUID', 'entitiesIdUUID', 5, 'association-consignator-user#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, users: [] };
                data.filters = f[0];
                data.userType = 1;
                return UserService.listUsers(data);
            },
            'users'
        );

    gAdd = async raw => {
        if (this.props.idUUID === null) return;
        this.setState({ userIdUUID: raw.idUUID });
        let data = {
            userEntity: {
                entityIdUUID: this.props.idUUID,
                entityUserType: 1,
                userIdUUID: raw.idUUID,
            },
        };
        let response = await UserService.entityAdd(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleApp', 'messages') });
    };

    addProfile = async () => {
        if (!this.state.selectedProfile || !this.props.idUUID) return;
        let data = {
            profileUser: {
                profileIdUUID: this.state.selectedProfile,
                userIdUUID: this.state.userIdUUID,
                userType: 1,
            },
        };
        const response = await ProfileService.addProfileUser(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleApp', 'messages') });
        this.setState({ selectProfile: false });
    };

    render() {
        return g_checkLoginACL(
            () =>
                !this.props.idUUID ? (
                    <span></span>
                ) : (
                    <div className="accessItemDescription">
                        <Messages ref={e => (this.messages = e)} />
                        <Dialog style={{ width: '75%', height: '90%' }} visible={this.state.visible} onHide={() => this.setState({ visible: false }, this.getData)}>
                            <GAddNew
                                ref={e => (this.g_add = e)}
                                getData={this.getAddNewData}
                                tableBody={[
                                    { type: 'd', data: 'fullname', sortable: true, filter: true },
                                    { type: 'd', data: 'username', sortable: true, filter: true },
                                    { type: 'button', icon: 'pi pi-plus', click: raw => this.setState({ selectProfile: true }, () => this.gAdd(raw)) },
                                ]}
                                windowAction={window => (window.addProfile = iduuid => this.setState({ selectProfile: true, userIdUUID: iduuid }))}
                                addnewWindowWidth={1000}
                                addnewWindowHeight={400}
                                addnewWindow={
                                    g_checkAlc(5, 'association-consignator-user#accessall')
                                        ? '#/newuserfac?type=1&id=' + this.props.idUUID
                                        : g_entities()
                                        ? g_entities().indexOf(this.props.idUUID) !== -1
                                            ? '#/newuserfac?type=1&id=' + this.props.idUUID
                                            : null
                                        : null
                                }
                            />
                        </Dialog>

                        <Dialog
                            visible={this.state.selectProfile}
                            onHide={() => {
                                this.setState({ selectProfile: false }, this.getData);
                            }}
                        >
                            <div className="card p-grid p-fluid contact-form" style={{ height: '50vh', width: '50vw' }}>
                                <div className="p-fluid" style={{ height: '50vh', width: '50vw' }}>
                                    {g_template_dropdown(
                                        this,
                                        'selectedProfile',
                                        this.state.selectedProfile,
                                        LS('profile'),
                                        this.state.profiles,
                                        false,
                                        false,
                                        false,
                                        'p-col-8',
                                        'p-col-2',
                                        false,
                                        '',
                                        {
                                            data: 'selectedProfile',
                                        }
                                    )}
                                    {g_template_button('', 'pi pi-save', false, this.addProfile)}
                                </div>
                            </div>
                        </Dialog>

                        {g_template_dataTable(
                            this,
                            g_getHeaderTemplatecF(this, () => this.setState({ visible: true }), this.getData),
                            [
                                { type: 'd', data: 'username', sortable: true, filter: true },
                                { type: 'url', width: 1300, height: 500, url: raw => `/#/user?type=1&id=${raw.userIdUUID}`, title: 'User' },
                                { type: 'dil', text: LS('simpleRemove', 'messages'), onConfirm: this.remove, id: raw => raw.idUUID, icon: 'pi pi-trash', buttonClassName: 'p-button-danger' },
                            ],
                            this.getData
                        )}
                    </div>
                ),
            true,
            'consignator#association'
        );
    }
}
