import type { StringIndexed } from './Requests';

export interface GenericResponse {
	code: number;
	description: string;
	messages: pairT<string | number>[];
}

export type GenericResponseG<Name extends string, Type> = GenericResponse &
	Record<Name, Type>;

export interface GenericListResponse extends GenericResponse {
	pageNumber: number;
	pageSize: number;
	pageTotal: number;
	recordsTotal: number;
}

export const createEmptyGenericListResponse = <
	T extends StringIndexed,
	Name extends string
>(
	name: Name
): GenericListResponseBaseG<Name, T> => {
	const b = {
		code: 0,
		description: '',
		messages: [],
		pageNumber: 0,
		pageSize: 0,
		pageTotal: 0,
		recordsTotal: 0
	};

	const r: Record<Name, T[]> = { [name]: [] } as any;
	return { ...b, ...r };
};

export type GenericListResponseBaseG<
	Key extends string,
	T
> = GenericListResponse & Record<Key, T[]>;

export type GenericListResponseG<Key extends string, T> = Promise<
	GenericListResponseBaseG<Key, T>
>;

export type pairT<T, K extends string = string> = {
	key: K;
	value: T;
};

export function pair<T, K extends string>(key: K, value: T): pairT<T, K> {
	return { key, value };
}

export function pairF<T, K extends string>(
	key: K,
	value: T,
	b = true
): pairT<T, K>[] {
	if (b) return [{ key, value }];
	return [];
}

export const dateToIsoString = (date: Date) => {
	const p = (n: number) => String(n).padStart(2, '0');
	return `${date.getFullYear()}-${p(date.getMonth() + 1)}-${p(
		date.getDate()
	)}T${p(date.getHours())}:${p(date.getMinutes())}:${p(date.getSeconds())}.000`;
};

export type UUID = string;
export type StringDate = string;
export type BooleanNumber = 0 | 1;
