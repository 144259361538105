import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import React from 'react';
import Attachment from '../../attachment/Attachment';
import { TemplateFromGeneratorPage } from '../../generic/templateFormGenerator';
import { g_checkLoginACL, g_getData, g_tabViewRenderOptions, ListPage, ls, pair, S } from '../../GenericFunctions';
import { MedicalCertificate, MedicalCertificateList } from './MedicalCertificate';
import { Prescription, PrescriptionList } from './Prescription';
import { UTI, videoCallgetData } from './videocallnew';
export class OldCallSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tabI2: null, zoom: false, mode: true, idUUID: null, data: {}, consignee: {}, anamnesis: '', conclusion: '', problems: '', tab2Data: [], ...props };
    }

    componentDidMount() {
        let items = [
            { label: ls('anamnesis', 'call') },
            { label: ls('cid', 'call') },
            { label: ls('prescription', 'call') },
            { label: ls('exams', 'call') },
            { label: ls('medicalCertificate', 'call') },
        ];
        this.setState({ items: items }, () => this.getData());
    }

    getData = () => videoCallgetData(this, this.props.idUUID);

    templateGeneratorsSaved = {};
    render() {
        return g_checkLoginACL(e => {
            if (this.state.clear) {
                this.setState({ clear: false });
                return null;
            }
            if (this.state.timeoutcount && this.state.timeoutcount >= 0) {
                this.setState({ timeoutcount: -1 });
                return null;
            }
            if (!this.state.mode && e.u !== 1) this.setState({ mode: true });
            let generateTab2 = () =>
                this.state.tab2Data.length === 0 ? null : (
                    <TabView activeIndex={this.state.tab2} onTabChange={e => this.setState({ tab2: e.index })}>
                        {this.state.tab2Data.map(a => (
                            <TabPanel contentStyle={{ height: '50vh' }} header={a.title}>
                                {(() => {
                                    if (a.uti)
                                        return (
                                            <div style={{ overflow: 'scroll', height: '50vh' }}>
                                                <UTI disable value={this.templateGeneratorsSaved[a.page].uti ?? ''} />
                                            </div>
                                        );
                                    return <TemplateFromGeneratorPage mode vValue={this.templateGeneratorsSaved[a.page]} heigth="55vh" template={() => a.contractFields} />;
                                })()}
                            </TabPanel>
                        ))}
                    </TabView>
                );
            return (
                <div>
                    <div className="p-grid p-fluid" style={{ left: '0.5vw', top: '0.5vh', width: '100%', position: 'absolute' }}>
                        {g_tabViewRenderOptions(
                            this,
                            [
                                //Tab amesies
                                <div className="p-grid p-fluid" style={{ width: '75vw' }}>
                                    <div className="p-col-12">
                                        <ListPage
                                            checkAcl={false}
                                            header={() => undefined}
                                            ref={e => (this.symptomList = e)}
                                            table={() => [
                                                { type: 'd', data: 'id', title: ls('icdCode') },
                                                { type: 'd', data: 'description' },
                                            ]}
                                            getData={c =>
                                                g_getData(c, S.health.symptom.contract.list, 'symptomContracts', {
                                                    filters: [...c.state.filter, pair('contractIdUUID', this.props.idUUID ?? ''), pair('status', 1)],
                                                    pageSize: 20,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="p-col-12"> {generateTab2()} </div>
                                </div>,

                                //CID
                                <div className="p-col-12" style={{ width: '74vw' }}>
                                    <ListPage
                                        checkAcl={false}
                                        table={() => [
                                            { type: 'd', data: 'cidId', title: ls('icdCode') },
                                            { type: 'd', data: 'description' },
                                            { type: 'date', data: 'dateCreation' },
                                        ]}
                                        getData={c =>
                                            g_getData(c, S.health.cid.contract.list, 'cidContracts', { filters: [...c.state.filter, pair('contractIdUUID', this.props.idUUID)] })
                                        }
                                    />
                                </div>,

                                //Prescription
                                <div className="p-col-12" style={{ width: '74vw' }}>
                                    <PrescriptionList
                                        checkAcl={false}
                                        header={null}
                                        extra={[{ t: 'btt', icon: 'pi pi-pencil', click: row => this.setState({ prescriptionIdUUID: row.idUUID, prescriptionVisible: true }) }]}
                                        extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.props.idUUID)] })}
                                        refTable={e => (this.prescriptionTable = e)}
                                    />
                                    <Dialog
                                        style={{ minWidth: '90vw', minHeight: '90vh' }}
                                        visible={this.state.prescriptionVisible}
                                        onHide={() => this.setState({ prescriptionVisible: false, prescriptionIdUUID: null }, this.prescriptionTable.getData)}
                                        contentStyle={{ height: '90vh' }}
                                    >
                                        <Prescription
                                            contractIdUUID={this.state.idUUID}
                                            idUUID={this.state.prescriptionIdUUID ?? ''}
                                            saved={idUUID => {
                                                this.setState({ prescriptionIdUUID: idUUID });
                                            }}
                                            consignee={this.state.consignee.consigneeObj}
                                        />
                                    </Dialog>
                                </div>,

                                //Exams
                                <div className="p-col-12" style={{ width: '74vw' }}>
                                    <Attachment
                                        add={false}
                                        idUUID={this.props.idUUID}
                                        documentContextType={4}
                                        documentTypeId={'8'}
                                        service={'contract'}
                                        update={this.state.activeItem}
                                    />
                                </div>,

                                //Atestado
                                <div className="p-col-12" style={{ width: '74vw' }}>
                                    <MedicalCertificateList
                                        header={null}
                                        checkAcl={false}
                                        extra={[
                                            {
                                                t: 'btt',
                                                icon: 'pi pi-pencil',
                                                click: row => this.setState({ medicalCertificateIdUUID: row.idUUID, medicalCertificateVisible: true }),
                                            },
                                        ]}
                                        extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.props.idUUID)] })}
                                        refTable={e => (this.prescriptionTable = e)}
                                    />
                                    <Dialog
                                        style={{ minWidth: '90vw', minHeight: '90vh' }}
                                        visible={this.state.medicalCertificateVisible}
                                        onHide={() => this.setState({ medicalCertificateVisible: false, medicalCertificateIdUUID: null }, this.prescriptionTable.getData)}
                                        contentStyle={{ height: '90vh' }}
                                    >
                                        <MedicalCertificate
                                            contractIdUUID={this.props.idUUID}
                                            idUUID={this.state.medicalCertificateIdUUID ?? ''}
                                            consignee={this.state.consignee.consigneeObj}
                                            saved={idUUID => {
                                                this.setState({ medicalCertificateIdUUID: idUUID });
                                            }}
                                        />
                                    </Dialog>
                                </div>,
                            ],
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            { width: '100%', minHeight: '100%' }
                        )}
                    </div>
                </div>
            );
        }, false);
    }
}
