import type { ContractModel } from '../Contract';

export interface Schedule {
	consigneeIdUUID: string;
	contractIdUUID: string;
	contract: ContractModel;
	creationUserIdUUID: string;
	dateCreation: string;
	dateSchedulingEnd: string;
	dateSchedulingStart: string;
	dateUpdate: string;
	description: string;
	idUUID: string;
	providerIdUUID: string;
	providerFantasyName: string;
	schedulingMode: number;
	schedulingStatus: ScheduleStatus;
	schedulingSyncStatus: number;
	schedulingType: number;
}

export enum ScheduleStatus {
	active = 1,
	booked = 2,
	canceled = 3
}

export type ScheduleListFilters =
	| 'brand'
	| 'description'
	| 'dateCreationStart'
	| 'dateCreationEnd'
	| 'dateSchedulingStartStart'
	| 'dateSchedulingStartEnd'
	| 'dateSchedulingEndStart'
	| 'dateSchedulingEndEnd'
	| 'schedulingStatus'
	| 'schedulingCategory'
	| 'schedulingType'
	| 'idUUID'
	| 'providerIdUUID'
	| 'subscriptionIdUUID'
	| 'contractStatus'
	/**
	 * This is required to make the consigneeIdUUID show up and to allow the consigneeIdUUID filter to show up
	 */
	| 'extended'
	| 'consigneeIdUUID';
