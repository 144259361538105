import React from 'react';
import { Calendar } from 'primereact/calendar';
import AssetService from '../../service/AssetService';
import {
    S,
    lm,
    ls,
    lt,
    lb,
    g_wraper,
    g_handleChange,
    g_ApiAsset,
    g_checkLoginACL,
    g_ChangeStatus,
    g_displayMessageError,
    g_tabViewRenderOptions,
    g_template_form,
    g_getDataFields,
} from '../GenericFunctions';
import Log from '../logs/Log';
import GSelector from '../generic/GSelector';

export const states = {
    1: { class: 'pi pi-check', color: 'green' },
    /* Active */ 2: { class: 'pi pi-times', color: 'red' },
    /* Deactivated */ 3: { class: 'pi pi-trash', color: 'red' },
    /* Deleted */ 4: { class: 'pi pi-dollar', color: 'orange' },
    /* AMORTIZED */ 5: { class: 'pi pi-clock', color: 'red' },
    /* INACTIVATED */ 6: { class: 'pi pi-cog', color: 'red' } /* Expired */,
};

export default class Asset extends React.Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiAsset(),
            categories: [],
            types: [],
            items: [{ label: lt('asset') }],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let def = query.get('def');
        if (def === '1') this.setState({ data: { ...this.state.data, assetCategory: '1', assetType: '1020' } });
        g_getDataFields(this, AssetService.catagories, 'assetCategories', 'description', 'id', 'categories');
        g_getDataFields(this, AssetService.types, 'assetTypes', 'description', 'id', 'types');
        this.setState(
            { idUUID: id, mode: query.get('type') !== '1', items: [{ label: def === '1' ? lt('vehicle') : lt('asset') }, ...(id ? [{ label: lt('log'), icon: 'pi pi-list' }] : [])] },
            this.getData
        );
    }

    updateStatus = (e, status) => g_ChangeStatus(this, () => AssetService.updateStatus({ idUUID: this.state.idUUID, assetStatus: status }), this.getData, lm('simpleStatus'), this.messages, e);

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = { pageSize: 1, pageNumber: 1, filters: [{ key: 'idUUID', value: this.state.idUUID }] };
        let response = await AssetService.list(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.assets[0]) return;
        let rdata = response.assets[0];
        rdata.assetType = rdata.assetType ? String(rdata.assetType) : '';
        rdata.assetCategory = rdata.assetCategory ? String(rdata.assetCategory) : '';
        this.setState({ data: rdata });
    };

    handleSubmit = async e => {
        e.preventDefault();
        let data = this.state.data;
        if (data.dateAsset) {
            let d = new Date(data.dateAsset);
            data.dateAsset = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (!this.state.idUUID) {
            if (!this.state.data.consignatorIdUUID) return;
            let response = await AssetService.add({ asset: data });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/asset?type=1&id=${response.asset.idUUID}`;
            window.location.reload();
            return;
        }
        let response = await AssetService.update({ asset: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                <GSelector
                                    labelwraper="p-col-2"
                                    label={ls('consignatorName')}
                                    ref={e => (this.selector0 = e)}
                                    getData={S.consignator.processed.list}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector0.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    disabled={this.state.mode}
                                    startV={this.state.data.consignatorName}
                                />,
                                g_wraper(
                                    'dateStart',
                                    ls('dateStart'),
                                    <div className="p-col-2">
                                        <Calendar
                                            yearRange={`1900:${new Date().getFullYear() + 10}`}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            id="dateAsset"
                                            dateFormat="dd-mm-yy"
                                            value={new Date(this.state.data.dateAsset)}
                                            onChange={e => g_handleChange(e, {}, this)}
                                        />
                                    </div>,
                                    false,
                                    'p-col-2'
                                ),
                                {
                                    t: 'array',
                                    dTypeF: 't',
                                    origin: this.state.data,
                                    cc: { _: 4, 6: 7 },
                                    class: 2,
                                    dTypeFs: { 0: 'drop', 1: 'drop', 6: 'area' },
                                    options: [this.state.categories, this.state.types],
                                    filter: ['assetCategory', 'assetType', 'brand', 'model', 'serialNumber', 'plateId', 'description'],
                                },
                            ],
                            mode => [
                                { t: 'b', click: null, l: ls(this.state.idUUID ? 'save' : 'create'), c: [2, 3, 5].indexOf(this.state.data.cardStatus) !== -1, class: 1 },
                                {
                                    t: 'dil',
                                    c: !this.state.idUUID || [3, 2].indexOf(this.state.data.assetStatus) !== -1,
                                    text: lm(`ask${this.state.data.assetStatus === 5 ? 'Activate' : 'Deactivate'}`),
                                    id: 'assetStatus',
                                    onConfirm: e => this.updateStatus(e, this.state.data.assetStatus === 1 ? 5 : 1),
                                    l: lb(this.state.data.assetStatus === 5 ? 'activate' : 'deactivate'),
                                },
                                {
                                    t: 'dil',
                                    class: 3,
                                    c: !this.state.idUUID || [3, 5].indexOf(this.state.data.assetStatus) !== -1,
                                    text: lm(`ask${this.state.data.assetStatus === 2 ? 'Activate' : 'TempDeactivate'}`),
                                    id: 'assetStatusTemp',
                                    onConfirm: e => this.updateStatus(e, this.state.data.assetStatus === 1 ? 2 : 1),
                                    l: lb(this.state.data.assetStatus === 5 ? 'activate' : 'deactivateTemp'),
                                },
                                {
                                    t: 'dil',
                                    class: 3,
                                    c: !this.state.idUUID || [3, 2, 5].indexOf(this.state.data.assetStatus) !== -1,
                                    text: lm(this.state.data.assetStatus === 6 ? 'askRemoveToMaintenance' : 'askMoveToMaintenance'),
                                    id: 'assetStatusMatinence',
                                    onConfirm: e => this.updateStatus(e, this.state.data.assetStatus === 1 ? 6 : 1),
                                    l: lb(this.state.data.assetStatus === 6 ? 'removeMaintenance' : 'moveMaintenance'),
                                },
                                {
                                    t: 'dil',
                                    c: !this.state.idUUID || [2, 3, 5].indexOf(this.state.data.assetStatus) !== -1,
                                    disabled: mode || e.d !== 1,
                                    text: lm('askBlockP'),
                                    id: 'assetStatusBloquear',
                                    onConfirm: e => this.updateStatus(e, 3),
                                    l: lb('block'),
                                    bttclass: 'p-button-danger',
                                },
                            ],
                            () => [
                                [
                                    {
                                        cg: !this.state.idUUID,
                                        t: 'array',
                                        origin: this.state.data,
                                        filter: ['assetStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        class: 'l',
                                        states: { 0: states },
                                    },
                                ],
                            ],
                            this.handleSubmit,
                            1,
                            this.state.idUUID,
                            this.state.mode,
                            9,
                            3
                        ),
                        <Log idUUID={this.state.idUUID} logContextType={25} />,
                    ])}
                </div>
            );
        });
    }
}
