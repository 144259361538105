import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ConsignatorService from '../../service/ConsignatorService';
import ContractTemplateService from '../../service/ContractTemplateService';
import ProductService from '../../service/ProductService';
import Services from '../../service/Services';
import GSelector from '../generic/GSelector';
import Log from '../logs/Log';
import ContractTemplateForm from './ContractTemplateForm';
import { contractTemplateMarginList } from './margin/lists';
import {
    LS,
    pair,
    StateIcon,
    ListPage,
    g_ApiContractTemplate,
    g_getHeaderTemplate,
    g_checkAlc,
    g_checkLoginACL,
    g_convertResponse,
    g_displayMessageError,
    g_getData,
    g_getDataFields,
    g_getDateTemplate,
    g_openWindowDialog,
    g_pair,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_copy,
    g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_template_inputArea,
    g_template_numeric,
    g_treatNumric,
    g_treatNumricR,
    g_wraper,
} from '../GenericFunctions';

export default class Contract extends Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiContractTemplate(1),
            forms: { contractForms: [] },
            mode: false,
            contractTemplateTypes: [],
            contractTemplateSignatureModes: [],
            contractTemplateRevenueModes: [],
            workflowTypes: [
                { label: LS('0', 'workflowTypes'), value: '0' },
                { label: LS('1', 'workflowTypes'), value: '1' },
                { label: LS('4', 'workflowTypes'), value: '4' },
                { label: LS('7', 'workflowTypes'), value: '7' },
                { label: LS('5', 'workflowTypes'), value: '5' },
                { label: LS('6', 'workflowTypes'), value: '6' },
                { label: LS('8', 'workflowTypes'), value: '8' },
            ],
            billingTypes: [
                { label: LS('0', 'billingTypes'), value: '1' },
                { label: LS('2', 'billingTypes'), value: '2' },
                { label: LS('3', 'billingTypes'), value: '3' },
                { label: LS('4', 'billingTypes'), value: '4' },
                { label: LS('5', 'billingTypes'), value: '5' },
                { label: LS('6', 'billingTypes'), value: '6' },
                { label: LS('7', 'billingTypes'), value: '7' },
                { label: LS('8', 'billingTypes'), value: '8' },
                { label: LS('9', 'billingTypes'), value: '9' },
                { label: LS('10', 'billingTypes'), value: '10' },
            ],
            liquidationEntityTypes: [
                { label: LS('1', 'liquidationEntityTypes'), value: '1' },
                { label: LS('2', 'liquidationEntityTypes'), value: '2' },
                { label: LS('3', 'liquidationEntityTypes'), value: '3' },
                { label: LS('4', 'liquidationEntityTypes'), value: '4' },
                { label: LS('5', 'liquidationEntityTypes'), value: '5' },
            ],
            loyaltyAngariationTypes: [
                { label: LS('0', 'loyaltyAngariationTypes'), value: '0' },
                { label: LS('1', 'loyaltyAngariationTypes'), value: '1' },
                { label: LS('2', 'loyaltyAngariationTypes'), value: '2' },
                { label: LS('3', 'loyaltyAngariationTypes'), value: '3' },
                { label: LS('4', 'loyaltyAngariationTypes'), value: '4' },
            ],
            idUUID: null,
            items: [{ label: LS('contractTemplate', 'tab') }],
        };
    }

    updateStatusClick = async e => {
        e.preventDefault();
        let input = {
            idUUID: this.state.data.idUUID,
            contractTemplateStatus: this.state.data.contractTemplateStatus === 1 ? 2 : 1,
        };
        let response = await ContractTemplateService.updateContractUpdateStatus(input);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    blockStatus = async e => {
        e.preventDefault();
        let input = { idUUID: this.state.data.idUUID, contractTemplateStatus: 3 };
        let response = await ContractTemplateService.updateContractUpdateStatus(input);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleBlock', 'messages') });
        this.getData();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        this.getContractTemplateTypes();
        this.gerContractSignatureModes();
        this.getContractRevenueModes();
        this.setState({ mode: mode, idUUID: idUUID }, () => {
            this.setItems();
            this.getData();
        });
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let response = await ContractTemplateService.getContractTemplate({}, this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = g_convertResponse(
            [
                'contractSignatureMode',
                'contractTemplateType',
                'consignatorRevenueStartMode',
                'consignatorRevenueInstallmentMode',
                'consignatorRevenueCloseMode',
                'numoRevenueStartMode',
                'numoRevenueInstallmentMode',
                'numoRevenueCloseMode',
                'workflowType',
                'liquidationEntityType',
                'billingToMode',
                'loyaltyAngariationValue',
                'loyaltyAngariationType',
            ],
            response.contractTemplate
        );
        rdata.monthlyTax = g_treatNumricR(rdata.monthlyTax);
        rdata.iofannual = g_treatNumricR(rdata.iofannual);
        rdata.iofadditional = g_treatNumricR(rdata.iofadditional);
        rdata.lenderTax = g_treatNumricR(rdata.lenderTax);
        this.setState({ data: rdata, forms: { contractForms: rdata.contractForms } });
    };

    getContractTemplateTypes = () => g_getDataFields(this, ContractTemplateService.getContractTemplateTypes, 'contractTemplateTypes', 'description', 'id', 'contractTemplateTypes');
    gerContractSignatureModes = () =>
        g_getDataFields(this, ContractTemplateService.getSignatureModes, 'contractTemplateSignatureMode', 'description', 'id', 'contractTemplateSignatureModes');
    getContractRevenueModes = () =>
        g_getDataFields(this, ContractTemplateService.getRevenueModes, 'contractTemplateRevenueMode', 'description', 'id', 'contractTemplateRevenueModes');
    getProducts = context => g_getData(context, ProductService.getProducts, 'products');
    getConsignators = ConsignatorService.processed.list;

    setItems = () =>
        this.setState({
            items: [
                { label: LS('contractTemplate', 'tab') },
                ...(this.state.idUUID
                    ? [
                          ...(g_checkAlc(4, 'contractTemplate#forms') ? [{ label: LS('contractForms', 'tab'), icon: 'pi pi-list' }, { label: LS('terms', 'tab') }] : []),
                          { label: LS('ratting', 'tab') },
                          { label: LS('marginManagement', 'tab') },
                          ...(g_checkAlc(4, 'contractTemplate#jsscript') ? [{ label: LS('jsScript', 'tab'), icon: 'pi pi-ticket' }] : []),
                          { label: LS('log', 'tab'), icon: 'pi pi-list' },
                      ]
                    : []),
            ],
        });

    handleSubmit = async event => {
        event.preventDefault();
        let rdata = this.state.data;
        rdata.minValue = g_treatNumric(rdata.minValue);
        rdata.maxValue = g_treatNumric(rdata.maxValue);
        rdata.priceValue = g_treatNumric(rdata.priceValue);
        rdata.stepValue = g_treatNumric(rdata.stepValue);
        rdata.consignatorMarginCloseMode = g_treatNumric(rdata.consignatorMarginCloseMode);
        rdata.consignatorMarginInstallmentMode = g_treatNumric(rdata.consignatorMarginInstallmentMode);
        rdata.consignatorMarginStartMode = g_treatNumric(rdata.consignatorMarginStartMode);
        rdata.numoMarginCloseMode = g_treatNumric(rdata.numoMarginCloseMode);
        rdata.numoMarginInstallmentMode = g_treatNumric(rdata.numoMarginInstallmentMode);
        rdata.numoMarginStartMode = g_treatNumric(rdata.numoMarginStartMode);
        rdata.monthlyTax = g_treatNumric(rdata.monthlyTax);
        rdata.iofannual = g_treatNumric(rdata.iofannual);
        rdata.iofadditional = g_treatNumric(rdata.iofadditional);
        rdata.lenderTax = g_treatNumric(rdata.lenderTax);
        rdata.loyaltyAngariationValue = g_treatNumric(rdata.loyaltyAngariationValue);
        if (this.state.idUUID) {
            let response = await ContractTemplateService.updateContractTemplate({ contractTemplate: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            let response = await ContractTemplateService.addContractTemplate({ contractTemplate: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            window.location.href = `#/contractTemplate?type=1&id=${response.contractTemplate.idUUID}`;
            window.location.reload();
        }
    };

    getProductValues = async () => {
        let data = { filters: [pair('idUUID', this.state.data.productIdUUID)], pageSize: 1, pageNumber: 1 };
        let response = await ProductService.getProducts(data);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = this.state.data;
        rdata.minValue = response.products[0].minValue;
        rdata.maxValue = response.products[0].maxValue;
        rdata.priceValue = response.products[0].priceValue;
        rdata.stepValue = response.products[0].stepValue;
        rdata.minPeriod = response.products[0].minPeriod;
        rdata.maxPeriod = response.products[0].maxPeriod;
        rdata.periodValue = response.products[0].periodValue;
        rdata.stepPeriod = response.products[0].stepPeriod;
        this.setState({ data: rdata });
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            let vV = ['1', '2', '7'].indexOf(this.state.data.contractTemplateType) === -1;
            let pV = ['2', '4'].indexOf(this.state.data.contractTemplateType) === -1;
            let pvV = ['5', '6', '7'].indexOf(this.state.data.contractTemplateType) !== -1;
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.handleSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                    <Panel style={{ border: 'none !important', overflowY: 'auto', height: '80vh' }}>
                                        {g_template_copy(this, 'Id', this.state.data.idUUID, 'id', false, 'p-col-1', 'p-col-2')}
                                        {g_template_input(this, 'title', this.state.data.title, LS('title'), this.state.mode, false, true, 'p-col-5', 'p-col-2')}
                                        {g_template_input(this, 'tag', this.state.data.tag, LS('tag'), this.state.mode, false, false, 'p-col-5', 'p-col-2')}
                                        {g_template_inputArea(
                                            this,
                                            'description',
                                            this.state.data.description,
                                            LS('description'),
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-12',
                                            'p-col-7',
                                            '',
                                            {
                                                minHeight: '10em',
                                            }
                                        )}
                                        <GSelector
                                            labelwraper="p-col-2"
                                            label={LS('consignatorName')}
                                            ref={e => (this.selector0 = e)}
                                            getData={this.getConsignators}
                                            tableBody={[
                                                { type: 'd', data: 'name', sortable: true, filter: true },
                                                {
                                                    type: 'btt',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector0.setState({ selected: raw.name, visible: false });
                                                        this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                                    },
                                                },
                                            ]}
                                            inputtextwarper="p-col-5"
                                            disabled={this.state.mode}
                                            startV={this.state.data.consignatorName}
                                        />
                                        <GSelector
                                            labelwraper="p-col-2"
                                            label={LS('productName')}
                                            ref={e => (this.selector1 = e)}
                                            getData={this.getProducts}
                                            tableBody={[
                                                { type: 'd', data: 'name', sortable: true, filter: true },
                                                {
                                                    type: 'btt',
                                                    icon: 'pi pi-plus',
                                                    click: (raw, e) => {
                                                        e.preventDefault();
                                                        this.selector1.setState({ selected: raw.name, visible: false });
                                                        this.setState({ data: { ...this.state.data, productIdUUID: raw.idUUID } }, this.getProductValues);
                                                    },
                                                },
                                            ]}
                                            inputtextwarper="p-col-5"
                                            disabled={this.state.mode}
                                            startV={this.state.data.productName}
                                        />
                                        {g_wraper(
                                            'contractSignatureMode',
                                            LS('contractSignatureMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'contractSignatureMode',
                                                    this.state.data.contractSignatureMode,
                                                    '',
                                                    this.state.contractTemplateSignatureModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-3',
                                                    'p-col-2'
                                                ),
                                                g_wraper(
                                                    'workflowType',
                                                    LS('workflowType'),
                                                    g_template_dropdown(
                                                        this,
                                                        'workflowType',
                                                        this.state.data.workflowType,
                                                        '',
                                                        this.state.workflowTypes,
                                                        this.state.mode,
                                                        false,
                                                        false,
                                                        'p-col-4'
                                                    ),
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2    '
                                        )}
                                        {g_wraper(
                                            'billingToMode',
                                            LS('billingToMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'billingToMode',
                                                    this.state.data.billingToMode,
                                                    '',
                                                    this.state.billingTypes,
                                                    this.state.mode,
                                                    false,
                                                    false,
                                                    'p-col-3',
                                                    'p-col-2',
                                                    true
                                                ),
                                                g_wraper(
                                                    'liquidationEntityType',
                                                    LS('liquidationEntityType'),
                                                    g_template_dropdown(
                                                        this,
                                                        'liquidationEntityType',
                                                        this.state.data.liquidationEntityType,
                                                        '',
                                                        this.state.liquidationEntityTypes,
                                                        this.state.mode,
                                                        false,
                                                        false,
                                                        'p-col-4'
                                                    ),
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_template_input(
                                            this,
                                            'contractDocumentBase',
                                            this.state.data.contractDocumentBase,
                                            LS('contractDocumentBase'),
                                            this.state.mode,
                                            false,
                                            false,
                                            'p-col-5',
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'allowToreadeal',
                                            LS('allowToreadeal'),
                                            [
                                                g_template_checkBox(this, 'allowToredeal', this.state.data.allowToredeal, '', this.state.mode, false, 'p-col-2'),
                                                g_wraper(
                                                    'marginControl',
                                                    LS('marginControl'),
                                                    g_template_checkBox(this, 'marginControl', this.state.data.marginControl, '', this.state.mode, 0, 'p-col-2'),
                                                    0,
                                                    'p-col-2',
                                                    true
                                                ),
                                                g_wraper(
                                                    'allowsSyncCall',
                                                    LS('allowsSyncCall'),
                                                    g_template_checkBox(this, 'allowsSyncCall', this.state.data.allowsSyncCall, '', this.state.mode, 0, 'p-col-1'),
                                                    0,
                                                    'p-col-3',
                                                    true
                                                ),
                                            ],
                                            0,
                                            'p-col-2'
                                        )}
                                        <h1> </h1>
                                        <h4>{LS('pricing', 'titles')}</h4>
                                        {g_template_dropdown(
                                            this,
                                            'contractTemplateType',
                                            this.state.data.contractTemplateType,
                                            LS('contractTemplateType'),
                                            this.state.contractTemplateTypes,
                                            this.state.mode,
                                            false,
                                            false,
                                            'p-col-4',
                                            'p-col-2',
                                            true
                                        )}
                                        {/* -- Valor -- */}
                                        {g_wraper(
                                            'priceValue',
                                            LS('priceValue'),
                                            [
                                                g_template_numeric(this, 'priceValue', this.state.data.priceValue, '', this.state.mode, false, false, 'p-col-1', '', '', {}),
                                                g_wraper(
                                                    'minValue',
                                                    LS('minValue'),
                                                    g_template_numeric(this, 'minValue', this.state.data.minValue, '', this.state.mode, false, false, 'p-col-1', 'p-col-3', '', {}),
                                                    vV,
                                                    'p-col-2',
                                                    true
                                                ),
                                                g_wraper(
                                                    'maxValue',
                                                    LS('maxValue'),
                                                    g_template_numeric(this, 'maxValue', this.state.data.maxValue, '', this.state.mode, false, false, 'p-col-1', 'p-col-3', '', {}),
                                                    vV,
                                                    'p-col-2',
                                                    true
                                                ),
                                                g_wraper(
                                                    'stepValue',
                                                    LS('stepValue'),
                                                    g_template_numeric(
                                                        this,
                                                        'stepValue',
                                                        this.state.data.stepValue,
                                                        '',
                                                        this.state.mode,
                                                        false,
                                                        false,
                                                        'p-col-1',
                                                        'p-col-3',
                                                        '',
                                                        {}
                                                    ),
                                                    vV,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {/* -- Periodo -- */}
                                        {g_wraper(
                                            'periodValue',
                                            LS('periodValue'),
                                            [
                                                g_template_numeric(this, 'periodValue', this.state.data.periodValue, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                    regex: new RegExp('\\d*', 'g'),
                                                }),
                                                g_wraper(
                                                    'minPeriod',
                                                    LS('minPeriod'),
                                                    g_template_input(this, 'minPeriod', this.state.data.minPeriod, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                        regex: new RegExp('\\d*', 'g'),
                                                    }),
                                                    pV,
                                                    'p-col-2',
                                                    true
                                                ),
                                                g_wraper(
                                                    'maxPeriod',
                                                    LS('maxPeriod'),
                                                    g_template_input(this, 'maxPeriod', this.state.data.maxPeriod, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                        regex: new RegExp('\\d*', 'g'),
                                                    }),
                                                    pV,
                                                    'p-col-2',
                                                    true
                                                ),
                                                g_wraper(
                                                    'stepPeriod',
                                                    LS('stepPeriod'),
                                                    g_template_input(this, 'stepPeriod', this.state.data.stepPeriod, '', this.state.mode, false, false, 'p-col-1', '', '', {
                                                        regex: new RegExp('\\d*', 'g'),
                                                    }),
                                                    pV,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            pvV,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'iofannual',
                                            LS('iofannual'),
                                            [
                                                g_template_numeric(
                                                    this,
                                                    'iofannual',
                                                    this.state.data.iofannual,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    false,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {},
                                                    '',
                                                    true
                                                ),
                                                g_wraper(
                                                    'iofadditional',
                                                    LS('iofadditional'),
                                                    g_template_numeric(
                                                        this,
                                                        'iofadditional',
                                                        this.state.data.iofadditional,
                                                        '',
                                                        this.state.mode,
                                                        false,
                                                        false,
                                                        'p-col-2',
                                                        '',
                                                        '',
                                                        {},
                                                        '',
                                                        true
                                                    ),
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'lenderTax',
                                            LS('lenderTax'),
                                            [
                                                g_template_numeric(
                                                    this,
                                                    'lenderTax',
                                                    this.state.data.lenderTax,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    false,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {},
                                                    '',
                                                    true
                                                ),
                                                g_wraper(
                                                    'monthlyTax',
                                                    LS('monthlyTax'),
                                                    g_template_numeric(
                                                        this,
                                                        'monthlyTax',
                                                        this.state.data.monthlyTax,
                                                        '',
                                                        this.state.mode,
                                                        false,
                                                        false,
                                                        'p-col-2',
                                                        '',
                                                        '',
                                                        {},
                                                        '',
                                                        true
                                                    ),
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        <h1> </h1>
                                        <h4>{LS('financierFlowConsignator', 'titles')}</h4>
                                        {g_wraper(
                                            'consignatorMarginStartMode',
                                            LS('consignatorMarginStartMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'consignatorRevenueStartMode',
                                                    this.state.data.consignatorRevenueStartMode,
                                                    '',
                                                    this.state.contractTemplateRevenueModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    '  p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'consignatorMarginStartMode',
                                                    this.state.data.consignatorMarginStartMode,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'consignatorMarginInstallmentMode',
                                            LS('consignatorMarginInstallmentMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'consignatorRevenueInstallmentMode',
                                                    this.state.data.consignatorRevenueInstallmentMode,
                                                    '',
                                                    this.state.contractTemplateRevenueModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'consignatorMarginInstallmentMode',
                                                    this.state.data.consignatorMarginInstallmentMode,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'consignatorRevenueCloseMode',
                                            LS('consignatorRevenueCloseMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'consignatorRevenueCloseMode',
                                                    this.state.data.consignatorRevenueCloseMode,
                                                    '',
                                                    this.state.contractTemplateRevenueModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'consignatorMarginCloseMode',
                                                    this.state.data.consignatorMarginCloseMode,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        <h1> </h1>
                                        <h4>{LS('financierFlowNumo', 'titles')}</h4>
                                        {g_wraper(
                                            'numoMarginStartMode',
                                            LS('numoMarginStartMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'numoRevenueStartMode',
                                                    this.state.data.numoRevenueStartMode,
                                                    '',
                                                    this.state.contractTemplateRevenueModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'numoMarginStartMode',
                                                    this.state.data.numoMarginStartMode,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'numoMarginInstallmentMode',
                                            LS('numoMarginInstallmentMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'numoRevenueInstallmentMode',
                                                    this.state.data.numoRevenueInstallmentMode,
                                                    '',
                                                    this.state.contractTemplateRevenueModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'numoMarginInstallmentMode',
                                                    this.state.data.numoMarginInstallmentMode,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        {g_wraper(
                                            'numoRevenueCloseMode',
                                            LS('numoRevenueCloseMode'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'numoRevenueCloseMode',
                                                    this.state.data.numoRevenueCloseMode,
                                                    '',
                                                    this.state.contractTemplateRevenueModes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'numoMarginCloseMode',
                                                    this.state.data.numoMarginCloseMode,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                        <h1> </h1>
                                        <h4>{LS('loyaltyAngariationType', 'titles')}</h4>
                                        {g_wraper(
                                            'loyaltyAngariationType',
                                            LS('loyaltyAngariationType'),
                                            [
                                                g_template_dropdown(
                                                    this,
                                                    'loyaltyAngariationType',
                                                    this.state.data.loyaltyAngariationType,
                                                    '',
                                                    this.state.loyaltyAngariationTypes,
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col-5'
                                                ),
                                                g_template_numeric(
                                                    this,
                                                    'loyaltyAngariationValue',
                                                    this.state.data.loyaltyAngariationValue,
                                                    '',
                                                    this.state.mode,
                                                    false,
                                                    true,
                                                    'p-col- 2',
                                                    '',
                                                    '',
                                                    {}
                                                ),
                                            ],
                                            false,
                                            'p-col-2'
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_wraper(
                                            null,
                                            LS('status', 'generic'),
                                            <div className="p-col">
                                                <StateIcon
                                                    state={this.state.data.contractTemplateStatus}
                                                    small
                                                    custom={{
                                                        1: { color: 'green', class: 'pi-check' },
                                                        2: { color: 'red', class: 'pi-times' },
                                                        3: { color: 'red', class: 'pi-ban' },
                                                    }}
                                                />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-5'
                                        )}
                                        {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col-5')}
                                        {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col-5')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(
                                    LS(this.state.idUUID ? 'save' : 'create', 'btt'),
                                    '',
                                    this.state.data.contractTemplateStatus === '3',
                                    null,
                                    this.state.mode,
                                    'p-col-1'
                                )}
                                {g_template_button(
                                    LS('copy', 'btt'),
                                    '',
                                    !this.state.idUUID,
                                    e => {
                                        e.preventDefault();
                                        g_openWindowDialog(`#/copyContractTemplate?type=1&id=${this.state.data.idUUID}`, `copy_${this.state.idUUID}`, 700, 500);
                                    },
                                    this.state.mode && e.c !== 1,
                                    'p-col-1',
                                    'p-button-warning'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.contractTemplateStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                    'contractTemplateStatus',
                                    this.updateStatusClick,
                                    LS(this.state.data.contractTemplateStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                    !this.state.idUUID || this.state.data.contractTemplateStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-1'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlockP', 'messages'),
                                    'contractTemplateStatusBloquear',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    !this.state.idUUID || this.state.data.contractTemplateStatus === 3,
                                    this.state.mode,
                                    'p-button-danger',
                                    '',
                                    '',
                                    '',
                                    'p-col-1',
                                    'p-button-danger'
                                )}
                            </div>
                        </form>,
                        ...(g_checkAlc(4, 'contractTemplate#forms') ? [<ContractTemplateForm context={this} />] : []),
                        ...(g_checkAlc(4, 'contractTemplate#forms')
                            ? [
                                  g_checkLoginACL(
                                      e => {
                                          let mode = this.state.mode || e.u !== 1;
                                          return (
                                              <form className="p-col" onSubmit={this.handleSubmit}>
                                                  <div className="p-grid p-fluid">
                                                      <div className="p-col-12">
                                                          <Editor
                                                              style={{ height: '70vh' }}
                                                              disabled={mode}
                                                              value={this.state.data.terms}
                                                              onTextChange={e => (mode ? () => {} : this.setState({ data: { ...this.state.data, terms: e.htmlValue } }))}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="p-grid p-fluid">
                                                      {g_template_button(
                                                          LS(this.state.idUUID ? 'save' : 'create', 'btt'),
                                                          '',
                                                          this.state.data.contractTemplateStatus === 3,
                                                          null,
                                                          mode,
                                                          'p-col-1'
                                                      )}
                                                  </div>
                                              </form>
                                          );
                                      },
                                      true,
                                      'contractTemplate#forms'
                                  ),
                              ]
                            : []),
                        Rate(() => this),
                        contractTemplateMarginList.render(this.state.idUUID),
                        ...(g_checkAlc(4, 'contractTemplate#jsscript')
                            ? [
                                  g_checkLoginACL(
                                      e => {
                                          let mode = this.state.mode || e.u !== 1;
                                          return (
                                              <form className="p-col" onSubmit={this.handleSubmit}>
                                                  <div className=" p-grid p-fluid">
                                                      <div className="p-col-12 contact-form">
                                                          {g_template_inputArea(
                                                              this,
                                                              'scriptInstallmentSimulation',
                                                              this.state.data.scriptInstallmentSimulation,
                                                              '',
                                                              mode,
                                                              false,
                                                              false,
                                                              '',
                                                              'p-col-12',
                                                              '',
                                                              { minHeight: '40vh' }
                                                          )}
                                                      </div>
                                                  </div>
                                                  <div className="p-grid p-fluid">
                                                      {g_template_button(
                                                          LS(this.state.idUUID ? 'save' : 'create', 'btt'),
                                                          '',
                                                          this.state.data.contractTemplateStatus === 3,
                                                          null,
                                                          mode,
                                                          'p-col-1'
                                                      )}
                                                  </div>
                                              </form>
                                          );
                                      },
                                      true,
                                      'contractTemplate#jsscript'
                                  ),
                              ]
                            : []),
                        <Log idUUID={this.state.idUUID} logContextType={11} />,
                    ])}
                </div>
            );
        });
    }
}

const Rate = (c = () => {}) => (
    <ListPage
        title=""
        getData={context =>
            c().state.idUUID
                ? g_getData(
                      context,
                      Services.utils.ratting.list,
                      'rattingCategories',
                      { filters: [...context.state.filter, g_pair('contractTemplateIdUUID', c().state.idUUID)] },
                      c().messages
                  )
                : null
        }
        header={(context, e) => g_getHeaderTemplate(context, `#/ratting?type=1&ctid=${c().state.idUUID}`, 'new', context.getData, 1200, 700, e.c)}
        table={(context, e) => [
            { type: 'd', data: 'description', filter: true },
            { type: 'url', c: e.d === 1 || e.u === 1, url: r => `#/ratting?type=1&id=${r.idUUID}`, width: 1200, height: 700, callback: context.getData },
        ]}
    />
);
