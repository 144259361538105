import type {
	RequestsClass,
	GenericListResponseG,
	ShoppingCart
} from '../../..';

export const revalidate =
	(req: RequestsClass, baseUrl: string) =>
	(
		uuid: string,
		campaignUUID: any
	): GenericListResponseG<'shoppingCarts', ShoppingCart> =>
		req.axios.put(`${baseUrl}/${uuid}/revalidate/${campaignUUID}`);
