import { ContractModel } from '../Contract';
import { StringDate, UUID } from '../Generic';

export const ticketdefaultinformation = {
	consignatorIdUUID: 'e0581ab1-dd87-4239-ac4a-d6aaf72fd9df'
};

export enum IncidentStatus {
	PendenteTriagem = 1,
	Fechado = 2,
	Eliminado = 3,
	Cancelado = 4,
	Triagem = 10,
	EmAceitacaoTratamento = 20,
	Tratamento = 30,
	EmRespostaSolicitante = 40
}

export enum Channel {
	Portal = 1,
	Telefone = 3
}

export interface TicketAddress {
	idUUID: UUID;
	referenceIdUUID: UUID;
	zipCode: number;
	street: string;
	port: string;
	complement: string;
	streetZone: string;
	city: string;
	state: string;
	country: number;
	addressType: number;
	addressTypeDescription: string;
	longitude: number;
	latitude: number;
}

export interface TicketModel {
	idUUID: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	dateIncident: StringDate;
	dateClose: StringDate;
	ticketSla: number;
	ticketSlaDescription: string;
	ticketSlaImage: string;
	rate: number;
	incidentCloseStatus: number;
	incidentCloseStatusDescription: string;
	incidentCloseStatusImage: string;
	incidentChannel: Channel;
	incidentChannelDescription: string;
	incidentChannelImage: string;
	incidentType: number;
	incidentTypeDescription: string;
	incidentTypeImage: string;
	incidentStatus: number;
	incidentStatusDescription: string;
	incidentStatusImage: string;
	incidentCategory: 0;
	incidentCategoryDescription: string;
	incidentCategoryImage: string;
	incidentSeverity: 0;
	incidentSeverityDescription: string;
	incidentSeverityImage: string;
	description: string;
	closeNotes: string;
	protocol: string;
	referenceIdUUID: UUID;
	referenceDescription: string;
	requesterName: string;
	requesterEmail: string;
	requesterMobilephone: string;
	requesterAddress: TicketAddress;
	consignatorIdUUID: UUID;
	consignatorName: string;
	contractIdUUID: UUID;
	contractDescription: string;
	distributorIdUUID: UUID;
	distributorName: string;
	solverIdUUID: UUID;
	solverName: string;
	creationUserIdUUID: UUID;
	creationUserName: string;
	latitude: number;
	longitude: number;
	requesterAddressState: string;
	requesterAddressCity: string;
	requestAddressStreet: string;
}

export interface TicketExtendedModel extends TicketModel {
	contract: ContractModel[];
}
