import { Messages } from 'primereact/messages';
import React, { useRef, useState } from 'react';
import CampaignService from '../../service/CampaignService';
import type { CampaignItemListModel } from '../../types';
import { CampaignProductsList } from '../campaign/CampainProductsList';
import { checkLoginACL, StringEmpty } from '../Generic';
import { TablePage } from '../generic/DataTable';
import { g_displayMessageError, g_getData, g_getHeaderTemplatecF, ls, LS } from '../GenericFunctions';

interface ProductPackageProductsManagementProps {
    idUUID: string;
}
export const ProductPackageProductsManagement: React.FC<ProductPackageProductsManagementProps> = ({ idUUID }) => {
    let { error, profile } = checkLoginACL({ fakename: 'campaignProduct#products' });
    let messages = useRef<Messages | null>(null);
    let tablePageRef = useRef<TablePage | null>(null);

    let [visible, setVisible] = useState(false);
    let [editIdUUID, setEditIdUUID] = useState('');

    if (error) return error;

    console.log(editIdUUID);

    return (
        <>
            <Messages ref={el => (messages.current = el)} />
            <CampaignProductsList
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    if (tablePageRef.current) tablePageRef.current.getData();
                }}
                campaignIdUUID={idUUID}
                idUUID={editIdUUID}
            />
            <TablePage<CampaignItemListModel>
                ref={e => (tablePageRef.current = e)}
                header={c => g_getHeaderTemplatecF(this, () => setVisible(true), c.getData, profile!.cb)}
                getData={c => {
                    if (!StringEmpty(idUUID)) g_getData(c, CampaignService.getCampaignItemList, 'campaignItens', { campaignIdUUID: idUUID }, messages.current);
                }}
                table={c => [
                    { t: 'd', data: 'name', filter: true, sortable: true },
                    { t: 'd', data: 'shortDescription', filter: true, sortable: true },
                    { t: 'd', data: 'productTypeDescription', filter: true, sortable: true },
                    {
                        t: 'di',
                        di: raw => ls(raw.campaignItemMode, 'campaignMode', ''),
                        data: 'campaignItemMode',
                    },
                    { t: 'd', data: 'condicionalValue' },
                    { t: 'd', data: 'afterCondicionalValue' },
                    {
                        t: 'btt',
                        icon: 'pi pi-trash',
                        tooltip: LS('delete', 'tooltip'),
                        class: 'p-button-warning',
                        click: async data => {
                            let response = await CampaignService.removeCampaignItem({}, data.campaignItemIdUUID);
                            if (g_displayMessageError(messages.current, response)) return;
                            if (messages.current) messages.current.show({ severity: 'success', summary: LS('simpleRemove', 'messages') });
                            c.getData();
                        },
                    },
                    {
                        t: 'btt',
                        icon: 'pi pi-pencil',
                        tooltip: LS('edit', 'tooltip'),
                        class: 'p-button-warn',
                        click: async data => {
                            console.log('edit click', data);
                            setEditIdUUID(data.campaignItemIdUUID);
                            setVisible(true);
                        },
                    },
                ]}
            />
        </>
    );
};
