import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown as PDropdown } from 'primereact/dropdown';
import { Editor as PEditor } from 'primereact/editor';
import { InputMask as PInputMask } from 'primereact/inputmask';
import { InputNumber as PInputNumber } from 'primereact/inputnumber';
import { InputSwitch as PInputSwitch } from 'primereact/inputswitch';
import { InputText as PInputText } from 'primereact/inputtext';
import { TabPanel as PTabPanel, TabView as PTabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import React, {
	MouseEvent,
	ReactElement,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { Redirect } from 'react-router-dom';
import { GenericListResponse, pairT } from '../api-ts-bindings/Generic';
import { GenericListRequest, StringIndexed } from '../api-ts-bindings/Requests';
import type {
	BasicListResponseData,
	LoggedUserData,
	StateItems
} from '../types';
import { useDataObject } from './Form';
import { Translator } from './TypeDefinitions';
import { checkResponse, noop } from './Utils';

export * from './Form';

export function StringEmpty(s: string | null | undefined): boolean {
	return s === null || s === undefined || s === '';
}

type useAclResult = {
	error?: ReactElement;
	profile?: aclItem;
	user?: LoggedUserData & { hasAcl: (name: string, type: number) => boolean };
};

export function hasAcl(
	user: LoggedUserData,
	tag: string,
	type: number
): aclItem | undefined {
	for (const acl of user.profileACLs) {
		if (acl.tag === tag && acl.type === type) return acl;
	}
	return undefined;
}

export function openWindowDialog(
	url: string,
	name: string,
	width = -1,
	height = -1,
	resizable = 'off',
	extra = '',
	onClose: (() => void) | null = null
): Window | null {
	width = width < 0 ? window.screen.width : width;
	height = height < 0 ? window.screen.height : height;
	const x = window.screen.width / 2 - width / 2;
	const y = window.screen.height / 2 - height / 2;
	const w = window.open(
		url,
		name,
		'scrollbars=off,location=off,menubar=off,width=' +
			width +
			',height=' +
			height +
			',left=' +
			x +
			',top=' +
			y +
			',resizable=' +
			resizable +
			',' +
			extra
	);
	if (!w) return null;
	if (onClose)
		w.onbeforeunload = () => {
			onClose();
		};
	return w;
}

export type getDataContext<
	K extends string = string,
	O extends string = string
> = React.Component<
	any,
	{
		filter: pairT<string, K>[];
		orderBy: pairT<'asc' | 'desc', O>[];
		pageSize: number;
		pageNumber: number;
		[key: string]: any;
	}
> & {
	messages: null | Messages;
};
/**
 * @deprecated use the new table
 * @param context
 * @param apiRequest
 * @param processData
 * @param overideData
 * @param messages
 * @returns
 */
export async function g_getData<T, K extends string, O extends string>(
	context: getDataContext<K, O>,
	apiRequest: (data: GenericListRequest<K, O>) => Promise<T> | T = async () =>
		null as any,
	processData:
		| string
		| ((response: T, callback: BasicListResponseData) => void)
		| { p: string; c: () => void } = noop,
	overideData = {},
	messages: Messages | null = null
): Promise<void> {
	const data: GenericListRequest<K, O> = {
		filters: context.state.filter ?? [],
		orderBy: context.state.orderBy ?? [],
		pageSize: context.state.pageSize ?? 0,
		pageNumber: context.state.pageNumber ?? 0,
		...overideData
	};
	const response = (await apiRequest(data)) as any;
	if (checkResponse(response, messages ?? context.messages ?? null)) return;
	const callback: BasicListResponseData = {
		pageTotal: response.pageTotal,
		recordsTotal: response.recordsTotal,
		pageSize: response.pageSize
	};
	if (typeof processData === 'string')
		context.setState({
			data: response[processData],
			...callback
		});
	else if (typeof processData === 'function') processData(response, callback);
	else if (typeof processData === 'object')
		context.setState(
			{
				data: response[processData.p],
				...callback
			},
			processData.c
		);
}

/**
 * @deprecated use the function
 */
export const returnGenericEmpty: GenericListResponse = {
	code: 0,
	pageNumber: 1,
	recordsTotal: 0,
	pageSize: 0,
	pageTotal: 0,
	messages: [],
	description: ''
};

/**
 * @function checkAcl checks if the stored data contains the acl required
 */
export function checkAcl(acl: string, type: number): aclItem[] | undefined {
	try {
		const loggedUserN: LoggedUserData = JSON.parse(
			localStorage.getItem('loggedUserN') ?? ''
		);
		if (loggedUserN === null) return undefined;
		const profile = loggedUserN.profileACLs
			.filter((v) => v.type === type)
			.filter((v) => v.tag === acl);
		return profile.length !== 0 ? profile : undefined;
	} catch (_) {
		return undefined;
	}
}

export type aclItem = {
	r: 0 | 1;
	u: 0 | 1;
	d: 0 | 1;
	c: 0 | 1;
	rb: boolean;
	ub: boolean;
	cb: boolean;
	db: boolean;
	type?: number;
	tag?: string;
};

export function StateIcon(props: {
	custom: StateItems;
	state: number;
	style?: React.CSSProperties;
	small?: boolean;
	onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
}) {
	const [tooltip, setTooltip] = useState(false),
		{ custom, state, onClick } = props;

	let style = {
		fontSize: props.small ? '1.4em' : '1.87em',
		...(props.style ?? {})
	};

	if (custom && custom[state]) {
		const icon = custom[state];
		style = {
			...style,
			color: icon.color ?? 'black',
			...(onClick ? { cursor: 'pointer' } : {})
		};
		return (
			<span
				onMouseOver={() => setTooltip(true)}
				onMouseLeave={() => setTooltip(false)}
			>
				<div
					style={{
						display: tooltip && icon.tooltip ? 'block' : '',
						marginTop: '0.5em',
						backgroundColor: '#222d',
						color: '#fff',
						maxWidth: '5em'
					}}
					className="p-tooltip"
				>
					{icon.tooltip}
				</div>
				<span onClick={onClick} className={'pi ' + icon.class} style={style}>
					{icon.text ?? ''}
				</span>
			</span>
		);
	}
	return (
		<span
			onClick={onClick}
			className="pi pi-question"
			style={{ color: 'grey', fontSize: '1.83em' }}
		/>
	);
}

export interface ButtonType {
	label?: string;
	icon?: string;
	disabled?: boolean;
	hidden?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	wraperClass?: string;
	wraperStyle?: React.CSSProperties;
	bttClass?: string;
	tooltip?: string;
	style?: React.CSSProperties;
	type?: string;
}

interface InputMaskType {
	id: string;
	label?: string;
	labelClass?: string;
	hidden?: boolean;
	disabled?: boolean;
	fieldClass?: string;
	placeholder?: string;
	tooltip?: string;
	value?: any;
	onChange: (e: any) => void;
	required?: boolean;
	mask?: string;
	style?: React.CSSProperties;
	gridless?: boolean;
	autoClear?: boolean;
	unmask?: boolean;
}

interface EditorType {
	id: string;
	disabled?: boolean;
	label?: string;
	style?: React.CSSProperties;
	fieldClass?: string;
	value?: string;
	labelClass?: string;
	gridless?: boolean;
	hidden?: boolean;
	onChange?: (e: {
		htmlValue: string | null;
		textValue: string;
		delta: any;
		source: string;
	}) => void;
}

export interface InputType {
	id: string;
	value?: string;
	label?: string;
	disabled?: boolean;
	hidden?: boolean;
	required?: boolean;
	inputClass?: string;
	labelClass?: string;
	type?: string;
	tooltip?: string;
	gridless?: boolean;
	onChange?: (e: any) => void;
	onClick?: (e: any) => void;
}

interface DateInputType {
	selectionMode?: any;
	inputClass?: string;
	label?: string;
	id: string;
	labelClass?: string;
	hidden?: boolean;
	gridless?: boolean;
	showTime?: boolean;
	timeOnly?: boolean;
	minDate?: Date;
	maxDate?: Date;
	value?: Date;
	disabled?: boolean;
	required?: boolean;
	onChange?: (e: {
		originalEvent: Event;
		value: Date | Date[];
		target: {
			name: string;
			id: string;
			value: Date | Date[];
		};
	}) => void;
}

interface TabPanelProps {
	name: string;
	hidden?: boolean;
	children?: React.ReactChildren | React.ReactElement | ReactElement[];
}

interface TabViewProps {
	children?:
		| React.ReactElement<TabPanelProps>
		| null
		| (ReactElement<TabPanelProps> | null)[];
}

interface InputNumberProps {
	id: string;
	value?: string;
	label?: string;
	disabled?: boolean;
	hidden?: boolean;
	required?: boolean;
	inputClass?: string;
	labelClass?: string;
	type?: string;
	tooltip?: string;
	gridless?: boolean;
	suffix?: string;
	suffixOutside?: boolean;
	prefix?: string;
	currency?: string;
	minFractionDigits?: number;
	maxFractionDigits?: number;
	mode?: 'decimal' | 'currency';
	locale?: string;
	min?: number;
	max?: number;
	onChange?: (e: {
		originalEvent: Event;
		value: any;
		target: {
			name: string;
			id: string;
			value: any;
		};
	}) => void;
}

interface DialogPromptProps {
	hidden?: boolean;
	yesbtt?: { label?: string; class?: string; icon?: string };
	nobtt?: { label?: string; class?: string; icon?: string };
	onConfirm?: (e: React.MouseEvent<HTMLButtonElement>, text?: string) => void;
	onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	wraperClass?: string;
	label?: string;
	icon?: string;
	bttClass?: string;
	disabled?: boolean;
	id?: string;
	text?: string;
	symbol?: string;
	symbolColor?: string;
	show?: boolean;
	hiddenBtt?: boolean;
	closeOut?: () => void;
	children?: React.ReactChildren | React.ReactElement;
	style?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
	dialogTitle?: string;

	inputArea?: boolean;
	inputAreaLabel?: string;

	conditionMode?: boolean;
	condition?: () => Promise<
		| {
				text: string;
				symbol?: string;
				symbolColor?: string;
				onConfirm?: (
					e: React.MouseEvent<HTMLButtonElement>,
					text?: string
				) => void;
				noBttText?: string | null;
				yesBttText?: string;
		  }
		| undefined
		| false
	>;
}

interface CopyProps {
	id: string;
	label?: string;
	labelClass?: string;
	inputClass?: string;
	hidden?: boolean;
	gridless?: boolean;
	value?: string;
	copyMessage?: string;
}

export interface WraperProps {
	hidden?: boolean;
	label?: string;
	children: ReactElement[] | ReactElement | Element;
	id?: string;
	class?: string;
	gridless?: boolean;
	reversed?: boolean;
}

interface TextAreaProps {
	id: string;
	label?: string;
	labelClass?: string;
	inputClass?: string;
	hidden?: boolean;
	gridless?: boolean;
	style?: React.CSSProperties;
	tooltip?: string;
	value?: string;
	disabled?: boolean;
	required?: boolean;
	onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}

interface DropdownProps<T extends StringIndexed> {
	id: string;
	options: { value: string; label: string }[];
	label?: string;
	lClass?: string;
	hidden?: boolean;
	itemTemplate?: (option: T) => React.ReactNode;
	filter?: boolean;
	placeholder?: string;
	value?: string | number;
	disabled?: boolean;
	onChange: (e: {
		originalEvent: Event;
		value: any;
		target: { name: string; id: string; value: any };
	}) => void;
	required?: boolean;
	showClear?: boolean;
	fClass?: string;
	gridless?: boolean;
}

type SwitchType = (props: {
	id: string;
	checked?: boolean;
	disabled?: boolean;
	tooltip?: string;
	fClass?: string;
	label?: string;
	lClass?: string;
	hidden?: boolean;
	gridless?: boolean;
	onChange?: (e: {
		originalEvent: Event;
		value: boolean;
		target: { name: string; id: string; value: boolean };
	}) => void;
}) => JSX.Element;

type showMessageType = (
	messages: Messages,
	message: string,
	details: {
		severity?: 'warn' | 'error' | 'success' | 'info';
		prefix?: string;
		time?: number;
		detail?: string;
	}
) => void;

export type Functionfy<T> = (props: T) => JSX.Element | null;

const DialogPromptB = (
	ls: Translator<string | undefined>,
	Button: Functionfy<ButtonType>,
	TextArea: Functionfy<TextAreaProps>
): Functionfy<DialogPromptProps> => {
	const DialogPrompt = ({
		hidden,
		yesbtt,
		onConfirm: onConfirmIn,
		nobtt,
		onDeny,
		wraperClass,
		label,
		icon,
		bttClass,
		disabled,
		text: textIn,
		symbol: symbolIn,
		symbolColor: symbolColorIn,
		id,
		show,
		hiddenBtt,
		closeOut = noop,
		children,
		style,
		contentStyle,
		dialogTitle,

		inputArea,
		inputAreaLabel,

		conditionMode,
		condition
	}: DialogPromptProps) => {
		const { obj, setObj, setObjState } = useDataObject<{ text: string }>({
			text: ''
		});

		const [v, setVisible] = useState(false);

		const [text, setText] = useState(() => ls(textIn, 'dialog', textIn));
		useEffect(() => setText(ls(textIn, 'dialog', textIn)), [textIn]);

		const [symbol, setSymbol] = useState(symbolIn);
		useEffect(() => setSymbol(symbolIn), [symbolIn]);

		const [symbolColor, setSymbolColor] = useState(symbolColorIn);
		useEffect(() => setSymbolColor(symbolColorIn), [symbolColorIn]);

		const labelT = useMemo(
			() =>
				ls(
					dialogTitle,
					'dialog_title',
					ls(label, 'dialog_title', ls(label, 'btt', label))
				),
			[label, dialogTitle]
		);

		const [onConfirm, setOnConfirm] = useState(() => onConfirmIn);
		useEffect(() => {
			if (!conditionMode) setOnConfirm(() => onConfirmIn);
		}, [onConfirmIn, conditionMode]);

		const [yesBttText, setYesBttText] = useState<string | undefined>(
			() => yesbtt?.label ?? 'yes'
		);
		const [noBttText, setNoBttText] = useState<string | null>(() =>
			nobtt?.label === null ? null : nobtt?.label ?? 'no'
		);

		const visible = show ?? v;
		if (hidden) return null;
		const footer = (
			<div>
				<Button
					label={yesBttText}
					bttClass={yesbtt?.class ?? 'p-button-success'}
					icon={yesbtt?.icon}
					wraperClass=""
					onClick={async (e) => {
						e.preventDefault();
						setVisible(false);
						if (onConfirm) await onConfirm(e, obj.text);
						setObjState({ text: '' });
						closeOut();
					}}
				/>
				{noBttText !== null ? (
					<Button
						label={noBttText}
						wraperClass=""
						bttClass={nobtt?.class ?? 'p-button-danger'}
						icon={nobtt?.icon}
						onClick={(e) => {
							e.preventDefault();
							setVisible(false);
							closeOut();
							if (onDeny) onDeny(e);
							setObjState({ text: '' });
						}}
					/>
				) : null}
			</div>
		);

		let input = null;
		if (inputArea) {
			input = (
				<>
					<div style={{ marginTop: '10px' }}></div>
					<TextArea
						style={{ minWidth: '400px', height: '80px' }}
						id="text"
						onChange={setObj}
						label={inputAreaLabel}
						labelClass="p-col-12"
						inputClass="p-col-12"
					/>
				</>
			);
		}

		let symbolE = null;
		if (symbol) {
			symbolE = (
				<div
					style={{
						display: 'grid',
						placeItems: 'center',
						fontSize: '50px',
						color: symbolColor
					}}
				>
					<span className={symbol}></span>
				</div>
			);
		}

		return (
			<div className={wraperClass}>
				<Button
					label={label}
					icon={icon}
					wraperClass=""
					bttClass={bttClass}
					hidden={hiddenBtt}
					onClick={async (e) => {
						e.preventDefault();
						if (disabled) return;

						setOnConfirm(() => onConfirmIn);
						setYesBttText(yesbtt?.label ?? 'yes');
						setNoBttText(nobtt?.label === null ? null : nobtt?.label ?? 'no');
						setText(ls(textIn, 'dialog', textIn));

						if (conditionMode) {
							if (!condition)
								throw new Error(
									'Button In condition mode but with no condition'
								);
							const c = await condition();
							if (c === undefined) {
								if (onConfirmIn) onConfirmIn(e);
								return;
							}
							if (c) {
								setText(ls(c.text, 'dialog', c.text));
								setSymbol(c.symbol);
								setSymbolColor(c.symbolColor);
								setYesBttText(c.yesBttText ?? yesbtt?.label ?? 'yes');
								setNoBttText(
									c.noBttText === null
										? null
										: c.noBttText ?? nobtt?.label === null
										? null
										: nobtt?.label ?? 'no'
								);
								//TODO see this as any
								setOnConfirm(() => (c as any).onConfirm);
							}
						}

						setVisible(true);
						setObjState({ text: '' });
					}}
					type="button"
					disabled={disabled}
				/>
				<Dialog
					visible={visible}
					onHide={() => {
						setVisible(false);
						closeOut();
						setObjState({ text: '' });
					}}
					footer={footer}
					style={{ width: '50vw', ...style }}
					contentStyle={contentStyle}
					id={id}
					header={labelT}
				>
					{symbolE}
					{text}
					{children}
					{input}
				</Dialog>
			</div>
		);
	};

	return DialogPrompt;
};

export type BuilderType = {
	showMessage: showMessageType;
	InputSwitch: SwitchType;
	Dropdown: <T extends StringIndexed>(props: DropdownProps<T>) => JSX.Element;
	TextArea: Functionfy<TextAreaProps>;
	Wraper: Functionfy<WraperProps>;
	DialogPrompt: Functionfy<DialogPromptProps>;
	InputNumber: Functionfy<InputNumberProps>;
	DateInput: Functionfy<DateInputType>;
	Editor: Functionfy<EditorType>;
	Input: Functionfy<InputType>;
	InputMask: Functionfy<InputMaskType>;
	Button: Functionfy<ButtonType>;
	TabPanel: Functionfy<TabPanelProps>;
	TabView: Functionfy<TabViewProps>;
	noDataMessage: (messages: Messages | null) => void;
	Copy: Functionfy<CopyProps>;
	useAcl: (p: {
		checkPageAcl?: boolean;
		fake_name?: string | string[];
	}) => useAclResult;
	useAclHookless: (p: {
		checkPageAcl?: boolean;
		fake_name?: string | string[];
	}) => useAclResult;
};

export const Builder = (ls: Translator<string | undefined>): BuilderType => {
	const Wraper = ({
		hidden,
		label,
		children,
		id,
		class: className,
		gridless,
		reversed
	}: WraperProps) => {
		if (hidden) return <></>;
		if ((StringEmpty(label) && StringEmpty(id)) || label === '')
			return <>{children}</>;
		label = label ?? id;
		label = ls(label, '_', label);
		let labelbody = (
			<div key={`${id}divlable`} className={className}>
				<label htmlFor={id}>{label}</label>
			</div>
		);
		if (!id)
			labelbody = (
				<div key={`${id}divlabel`} className={className}>
					{label}
				</div>
			);
		if (gridless && reversed) return <>{[children, labelbody]}</>;
		if (gridless) return <>{[labelbody, children]}</>;
		if (reversed)
			return (
				<div key={`${id}div`} className="p-grid">
					{labelbody} {children}
				</div>
			);
		return (
			<div key={`${id}div${label}`} className="p-grid">
				{labelbody} {children}
			</div>
		);
	};

	const showMessage: showMessageType = (
		messages: Messages,
		message: string,
		{
			detail = '',
			severity = 'success',
			prefix = 'messages',
			time = 5
		}: {
			severity?: 'warn' | 'error' | 'success' | 'info';
			prefix?: string;
			time?: number;
			detail?: string;
		} = {}
	) => {
		message = ls(message, prefix, message) ?? '';
		if (messages.show)
			messages.show({ severity, summary: message, detail, life: time * 1000 });
	};

	const InputSwitch: SwitchType = function ({
		id,
		checked,
		disabled,
		tooltip,
		onChange,
		fClass,
		label,
		lClass,
		hidden,
		gridless
	}) {
		return (
			<Wraper
				label={label}
				id={id}
				class={lClass}
				hidden={hidden}
				gridless={gridless}
			>
				<div className={fClass}>
					<PInputSwitch
						id={id}
						checked={checked}
						disabled={disabled}
						tooltip={tooltip}
						onChange={onChange}
					/>
				</div>
			</Wraper>
		);
	};

	const Dropdown = function <T extends StringIndexed>({
		label,
		id,
		lClass,
		hidden,
		itemTemplate,
		filter,
		options,
		placeholder,
		value,
		disabled,
		onChange,
		required,
		showClear,
		fClass,
		gridless
	}: DropdownProps<T>) {
		return (
			<Wraper
				label={label}
				id={id}
				class={lClass}
				hidden={hidden}
				gridless={gridless}
			>
				<div className={fClass}>
					<PDropdown
						key={`${id}drop`}
						itemTemplate={itemTemplate}
						filter={filter}
						filterBy="label, value"
						options={options}
						placeholder={placeholder}
						value={value}
						onChange={(e) => (disabled ? noop : onChange(e))}
						disabled={disabled}
						id={id}
						required={required}
						showClear={showClear}
					/>
				</div>
			</Wraper>
		);
	};

	const TabPanel = ({ name, hidden, children }: TabPanelProps) => {
		if (hidden) return null;
		return <PTabPanel header={ls(name, 'tab', name)}>{children}</PTabPanel>;
	};

	const TabView = ({ children }: TabViewProps) => {
		children = children ?? [];

		const content = Array.isArray(children) ? children : [children];

		return (
			<PTabView>
				{content
					.filter((a) => a && !a.props.hidden)
					.map((a) => {
						return (
							<PTabPanel header={ls(a!.props.name, 'tab', a!.props.name)}>
								{a!.props.children}
							</PTabPanel>
						);
					})}
			</PTabView>
		);
	};

	const TextArea = ({
		id,
		label,
		labelClass,
		hidden,
		gridless,
		inputClass,
		style,
		tooltip,
		value,
		disabled,
		required,
		onChange = noop
	}: TextAreaProps) => {
		return (
			<Wraper
				id={id}
				label={label}
				hidden={hidden}
				class={labelClass}
				gridless={gridless}
			>
				<div className={inputClass}>
					<InputTextarea
						style={style}
						tooltip={tooltip}
						value={value}
						onChange={(e) => (disabled ? noop : onChange(e))}
						title={'Por favor preencha este campo'}
						id={id}
						disabled={disabled}
						required={required}
					/>
				</div>
			</Wraper>
		);
	};

	function InputNumber({
		hidden,
		id,
		required,
		label,
		labelClass = 'p-col-3',
		inputClass = 'p-col-7',
		disabled,
		tooltip,
		type,
		value,
		onChange,
		gridless,
		suffix,
		suffixOutside,
		prefix,
		mode,
		currency,
		minFractionDigits,
		maxFractionDigits,
		locale,
		min,
		max
	}: InputNumberProps) {
		let valueN: number | undefined = Number(value);
		if (isNaN(valueN)) valueN = undefined;
		return (
			<Wraper
				id={id}
				label={label}
				hidden={hidden}
				class={labelClass}
				gridless={gridless}
			>
				<div className={(suffixOutside ? 'p-grid ' : '') + inputClass}>
					{(() => {
						const a = (
							<PInputNumber
								tooltip={tooltip}
								type={type}
								value={valueN}
								onChange={!disabled && onChange ? onChange : noop}
								id={id}
								disabled={disabled}
								required={required}
								suffix={suffixOutside ? '' : suffix}
								prefix={prefix}
								mode={mode}
								currency={currency}
								minFractionDigits={minFractionDigits}
								maxFractionDigits={maxFractionDigits}
								locale={locale}
								min={min}
								max={max}
							/>
						);
						if (suffixOutside)
							return (
								<>
									<div className="p-col">{a}</div>
									<div className="p-col-1">{suffix ?? ''}</div>
								</>
							);
						return a;
					})()}
				</div>
			</Wraper>
		);
	}

	function DateInput({
		selectionMode,
		inputClass,
		labelClass,
		label,
		id,
		hidden,
		gridless,
		showTime,
		timeOnly,
		minDate,
		maxDate,
		value,
		disabled,
		onChange,
		required
	}: DateInputType) {
		return (
			<Wraper
				id={id}
				label={label}
				hidden={hidden}
				class={labelClass}
				gridless={gridless}
			>
				<div className={inputClass}>
					<Calendar
						selectionMode={selectionMode}
						hourFormat={ls('hourFomart', 'default')}
						showTime={showTime}
						timeOnly={timeOnly}
						minDate={minDate}
						maxDate={maxDate}
						yearRange={`1900:${new Date().getFullYear() + 10}`}
						monthNavigator={true}
						yearNavigator={true}
						id={id}
						dateFormat="dd-mm-yy"
						value={value}
						onChange={(event) => (onChange ?? noop)(event)}
						disabled={disabled}
						required={required}
					/>
				</div>
			</Wraper>
		);
	}

	function Editor({
		disabled,
		id,
		label,
		style,
		fieldClass,
		value,
		labelClass,
		gridless,
		onChange,
		hidden
	}: EditorType) {
		const editor = disabled ? (
			<div
				style={{ border: '1px solid grey', marginBottom: '3px', ...style }}
				className={fieldClass}
				dangerouslySetInnerHTML={{ __html: value ?? '' }}
			/>
		) : (
			<div className={fieldClass}>
				<PEditor
					value={value}
					id={id}
					style={style ?? { minHeight: '7em' }}
					onTextChange={(e) => (onChange ?? noop)(e)}
				/>
			</div>
		);

		return (
			<Wraper
				id={id}
				label={label}
				class={labelClass}
				gridless={gridless}
				hidden={hidden}
			>
				{editor}
			</Wraper>
		);
	}

	function Input({
		hidden,
		id,
		required,
		label,
		labelClass = 'p-col-3',
		inputClass = 'p-col-7',
		disabled,
		tooltip,
		type,
		value,
		onChange,
		gridless,
		onClick
	}: InputType) {
		return (
			<Wraper
				id={id}
				label={label}
				hidden={hidden}
				class={labelClass}
				gridless={gridless}
			>
				<div className={inputClass}>
					<PInputText
						onClick={onClick}
						title={
							disabled !== undefined && !disabled
								? 'Por favor preencha este campo.'
								: ''
						}
						tooltip={tooltip}
						type={type}
						value={value}
						onChange={!disabled && onChange ? onChange : noop}
						id={id}
						disabled={disabled}
						required={required}
					/>
				</div>
			</Wraper>
		);
	}

	function InputMask({
		unmask = true,
		autoClear = false,
		label,
		id,
		gridless = false,
		placeholder,
		labelClass = 'p-col-3',
		fieldClass = 'p-col-7',
		hidden = false,
		disabled = false,
		tooltip,
		value,
		onChange,
		required = false,
		mask,
		style
	}: InputMaskType) {
		mask = ls(mask ?? id, 'mask', mask ?? '');
		placeholder = ls(placeholder ?? id, 'placeholder', placeholder ?? '');
		//title={ls('titleFeild', 'generic')}
		return (
			<Wraper
				id={id}
				label={label}
				hidden={hidden}
				class={labelClass}
				gridless={gridless}
			>
				<div className={fieldClass}>
					<PInputMask
						placeholder={placeholder}
						tooltip={tooltip}
						value={value}
						onChange={(e: any) => (disabled ? noop : onChange(e))}
						id={id}
						disabled={disabled}
						required={required}
						mask={mask}
						style={style}
						autoClear={autoClear}
						unmask={unmask}
					/>
				</div>
			</Wraper>
		);
	}

	function Button(props: ButtonType) {
		const {
			hidden = false,
			type = 'submit',
			style,
			icon,
			bttClass,
			onClick = null,
			disabled = false,
			wraperClass = 'p-col-1',
			wraperStyle
		} = props;
		let { label, tooltip } = props;
		label = ls(label, 'btt', label ?? '');
		tooltip = ls(tooltip, 'tooltip', label);
		if (hidden) return null;
		const body = (
			<PButton
				tooltip={tooltip}
				type={type}
				style={style}
				icon={icon}
				className={bttClass}
				onClick={!onClick || disabled ? undefined : onClick}
				disabled={disabled}
				label={label}
			/>
		);
		if (!wraperClass) return body;
		return (
			<div style={wraperStyle} className={wraperClass}>
				{body}
			</div>
		);
	}

	function Copy(props: CopyProps) {
		const {
			hidden,
			id,
			copyMessage: cpMessage = 'Copiado!',
			labelClass,
			gridless,
			value
		} = props;
		let { label } = props;

		const [insideCopy, setInsideCopy] = useState(false);
		const [copyMessage, setCopyMessage] = useState(false);
		const timeoutRef = useRef<number | null>(null);

		label = ls(label, '_', label);

		if (hidden) return null;

		const span = (
			<span
				onMouseOver={() => setInsideCopy(true)}
				onMouseLeave={() => setInsideCopy(false)}
			>
				<span
					className="pi pi-clone"
					style={{
						display: insideCopy ? '' : 'none',
						fontSize: '1.25em',
						cursor: 'pointer'
					}}
					onClick={() => {
						const e = document.getElementById(id) as any;
						if (!e?.value) {
							if ((document as any).selection) {
								//IE
								const range = (document.body as any).createTextRange();
								range.moveToElementText(e);
								range.select();
								document.execCommand('copy');
								(document as any).selection.empty();
							} else if (window.getSelection) {
								//others
								(window.getSelection() as any).removeAllRanges();
								const range = document.createRange();
								range.selectNode(e);
								(window.getSelection() as any).addRange(range);
								document.execCommand('copy');
								(window.getSelection() as any).removeAllRanges();
							}
						} else {
							e.select();
							e.setSelectionRange(0, 99999);
							document.execCommand('copy');
							/* document.selection.empty(); */
						}
						setCopyMessage(true);
						if (timeoutRef.current) clearTimeout(timeoutRef.current);
						timeoutRef.current = setTimeout(() => {
							setCopyMessage(false);
							timeoutRef.current = null;
						}, 2500) as any;
					}}
				></span>
				<span style={{ display: copyMessage && insideCopy ? '' : 'none' }}>
					{cpMessage}
				</span>
			</span>
		);

		const body = <label id={id}>{value}</label>;

		if (!label)
			return (
				<div
					onMouseOver={() => setInsideCopy(true)}
					onMouseLeave={() => setInsideCopy(false)}
				>
					{body}
					{span}
				</div>
			);

		let labelbody = (
			<div className={labelClass}>
				<label htmlFor={id}>{label}</label>
			</div>
		);
		if (!id) labelbody = <div className={labelClass}>{label}</div>;
		if (gridless)
			return (
				<>
					{[
						labelbody,
						<div
							onMouseOver={() => setInsideCopy(true)}
							onMouseLeave={() => setInsideCopy(false)}
						>
							{body}
						</div>,
						span
					]}
				</>
			);
		return (
			<div
				className="p-grid"
				onMouseOver={() => setInsideCopy(true)}
				onMouseLeave={() => setInsideCopy(false)}
			>
				{labelbody}
				<div className="p-col">
					{body}
					{span}
				</div>
			</div>
		);
	}

	function noDataMessage(messages?: Messages | null) {
		if (!messages) return;
		messages.show({
			severity: 'warn',
			summary: ls('dataNotRecorded', 'messages')
		});
	}

	function useAcl({
		checkPageAcl = true,
		fake_name
	}: {
		checkPageAcl?: boolean;
		fake_name?: string | string[];
	}): useAclResult {
		const [state] = useState<useAclResult>(() => {
			let loggedUserN:
				| (LoggedUserData & { hasAcl: (name: string, type: number) => boolean })
				| null = null;
			try {
				loggedUserN = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
			} catch {
				noop();
			}
			if (loggedUserN === null) return { error: <Redirect to="/login" /> };
			loggedUserN.hasAcl = (name, type) =>
				loggedUserN!.profileACLs.filter(
					(a) => a.tag === name && a.type === type
				).length > 0;
			if (!checkPageAcl)
				return {
					profile: {
						r: 1,
						u: 1,
						d: 1,
						c: 1,
						rb: true,
						ub: true,
						db: true,
						cb: true
					},
					user: loggedUserN
				};
			if (fake_name === undefined || typeof fake_name === 'string') {
				let url = fake_name ?? window.location.hash.substr(2);
				url = url.indexOf('?') === -1 ? url : url.split('?')[0];
				const profile = (loggedUserN.profileACLs as aclItem[])
					.filter((v) => v.type === 4 && v.r === 1)
					.filter((v) => v.tag === url);
				return {
					profile: profile[0],
					user: loggedUserN,
					error:
						profile.length === 0 ? (
							<span>{ls('noPermission', 'generic')}</span>
						) : undefined
				};
			}
			for (const url of fake_name) {
				const profile = (loggedUserN.profileACLs as aclItem[])
					.filter((v) => v.type === 4 && v.r === 1)
					.filter((v) => v.tag === url);
				if (profile[0])
					return { profile: profile[0], user: loggedUserN, error: undefined };
			}
			return { error: <span>{ls('noPermission', 'generic')}</span> };
		});
		return state;
	}

	function useAclHookless({
		checkPageAcl = true,
		fake_name
	}: {
		checkPageAcl?: boolean;
		fake_name?: string | string[];
	}): useAclResult {
		let loggedUserN:
			| (LoggedUserData & { hasAcl: (name: string, type: number) => boolean })
			| null = null;
		try {
			loggedUserN = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
		} catch {
			noop();
		}
		if (loggedUserN === null) return { error: <Redirect to="/login" /> };
		loggedUserN.hasAcl = (name, type) =>
			loggedUserN!.profileACLs.filter((a) => a.tag === name && a.type === type)
				.length > 0;
		if (!checkPageAcl)
			return {
				profile: {
					r: 1,
					u: 1,
					d: 1,
					c: 1,
					rb: true,
					ub: true,
					db: true,
					cb: true
				},
				user: loggedUserN
			};
		if (fake_name === undefined || typeof fake_name === 'string') {
			let url = fake_name ?? window.location.hash.substr(2);
			url = url.indexOf('?') === -1 ? url : url.split('?')[0];
			const profile = (loggedUserN.profileACLs as aclItem[])
				.filter((v) => v.type === 4 && v.r === 1)
				.filter((v) => v.tag === url);
			return {
				profile: profile[0],
				user: loggedUserN,
				error:
					profile.length === 0 ? (
						<span>{ls('noPermission', 'generic')}</span>
					) : undefined
			};
		}
		for (const url of fake_name) {
			const profile = (loggedUserN.profileACLs as aclItem[])
				.filter((v) => v.type === 4 && v.r === 1)
				.filter((v) => v.tag === url);
			if (profile[0])
				return { profile: profile[0], user: loggedUserN, error: undefined };
		}
		return { error: <span>{ls('noPermission', 'generic')}</span> };
	}

	const DialogPrompt = DialogPromptB(ls, Button, TextArea);

	return {
		Copy,
		showMessage,
		InputSwitch,
		Dropdown,
		TextArea,
		Wraper,
		DialogPrompt,
		InputNumber,
		DateInput,
		Editor,
		Input,
		InputMask,
		Button,
		noDataMessage,
		useAcl,
		useAclHookless,
		TabPanel,
		TabView
	};
};
