import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import config from '../../config/config';
import BatchService from '../../service/BatchService';
import { LS, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable, g_entityFilter } from '../GenericFunctions';
import { states } from './BatchEdit';

export default class UploadMargin extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        g_getData(
            this,
            BatchService.serviceList,
            response => {
                this.setState({ batch_idUUID: response.batchServices[0].idUUID }, () => {
                    g_getData(
                        this,
                        data => {
                            let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'batchs#accessall');
                            if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, batchs: [] };
                            data.filters = f[0];
                            return BatchService.batchList(data);
                        },
                        'batchs',
                        { filters: [{ key: 'batchServiceIDUUID', value: this.state.batch_idUUID }] }
                    );
                });
            },
            { pageNumber: 1, pageSize: 1, filters: [{ key: 'interfaceName', value: 'Executor_Margin' }], orderBy: [] }
        );
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('uploadMargin', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/batchedit?type=1&batchid=' + this.state.batch_idUUID, 'createnewbatch', this.getData, 1000, 400, e.c === 1),
                    [
                        { type: 'd', data: 'originalFilename', sortable: true, filter: true },
                        { type: 'd', data: 'userFullname' },
                        { type: 'date', data: 'dateExecution', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'batchStatus', states: states },
                        { type: 'button', click: raw => window.open(config.BATCH_DOCUMENT_URL + raw.processedFilename), icon: 'pi pi-download' },
                        {
                            type: 'url',
                            c: e.u === 1 || e.d === 1,
                            url: raw => `#/batchedit?id=${raw.idUUID}&type=1&batchid=${this.state.batch_idUUID}`,
                            width: 1000,
                            height: 450,
                            callback: this.getData,
                        },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
