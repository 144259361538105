import { GenericResponseG, UUID } from '../../Generic';
import { RequestsClass } from '../../Requests';
import { Channel, TicketModel } from '../Ticket';

export enum TicketUpdateAfterAction {
	'No Action' = 0,
	'Accept Distribution' = 1,
	'Accept Solve' = 2,
	Dispatch = 3,
	Reopen = 4,
	Close = 5
}

export interface TicketCreateModel {
	requesterName: string;
	requesterEmail: string;
	requesterMobilephone: string;
	incidentCategory: number;
	incidentType: number;
	incidentSeverity: number;
	description: string;
	incidentChannel: Channel;
	consignatorIdUUID: UUID;
}

export interface TicketUpdateModel extends Partial<TicketCreateModel> {
	idUUID: UUID;
	closeNotes: string;
}

export const add =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		incident: TicketCreateModel;
	}): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.post(`${baseUrl}/add`, data);

export const update =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		incident: TicketUpdateModel;
		afterAction: TicketUpdateAfterAction;
	}): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/update`, data);
