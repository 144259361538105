import {
	GenericListResponseG,
	GenericResponseG,
	StringDate,
	UUID
} from '../../Generic';
import { GenericListRequest, RequestsClass } from '../../Requests';

export type Filters = 'referenceIdUUID' | 'contextId' | 'documentType';

export type OrderBy = 'dateCreation';

export interface IncidentDocumentsModel {
	idUUID: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	description: string;
	documentType: number;
	documentStatus: number;
	documentTypeDescription: string;
	filename: string;
	filenameOriginal: string;
	referenceIdUUID: UUID;
	contextId: number;
	entityId: number;
	orderView: number;
	creationUserIdUUID: UUID;
}

export const incidentdocumentlist =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'documents', IncidentDocumentsModel> =>
		req.axios.post(`${baseUrl}/documents`, data);

export const incidentdocumentadd =
	(req: RequestsClass, baseUrl: string) =>
	(
		incidentiduuid: UUID,
		documentType: string,
		fileuploadData: FormData
	): Promise<GenericResponseG<'document', IncidentDocumentsModel>> => {
		const config = {
			'content-type': 'multipart/form-data',
			'background-request': true
		};

		return req.axios.post(
			`${baseUrl}/document/upload/${documentType}/${incidentiduuid}/0/0`,
			fileuploadData,
			config
		);
	};
