import React from 'react';
import config from '../../../config/config';
import ChatService from '../../../service/ChatService';
import ConsigneeService from '../../../service/ConsigneeService';
import ContractService from '../../../service/ContractService';
import ContractTemplateService from '../../../service/ContractTemplateService';
import ProductService from '../../../service/ProductService';
import AssetService from '../../../service/AssetService';
import Attachment from '../../attachment/Attachment';
import Chat from '../../generic/Chat';
import GSelector from '../../generic/GSelector';
import Log from '../../logs/Log';
import { generateStarsMap, contractRattings } from '../../contract/Contract';
import {
    S,
    lt,
    ls,
    lm,
    lb,
    g_ApiContract,
    g_template_copy,
    g_buttonTemplate,
    g_template_input,
    g_ChangeStatus,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_getDateTemplate,
    StateIcon,
    g_openWindowDialog,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_inputArea,
    g_treatNumricR,
    g_uuid,
    g_wraper,
} from '../../GenericFunctions';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import { RenderContracts } from '../../contract/Contract';

export default class Contract extends React.Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiContract(),
            mode: false,
            plateId: '',
            documentType: '',
            contractTemplates: [],
            contractTypes: [],
            items: [{ label: lt('contract') }],
            idUUID: null,
        };
    }

    updateStatus = (e, status, message) =>
        g_ChangeStatus(this, () => ContractService.updateStatus({ idUUID: this.state.data.idUUID, contractStatus: status }), this.getData, message, this.messages, e);
    updateStatusClick = e => this.updateStatus(e, this.state.data.contractStatus !== 2 ? 2 : 7, lm('simpleStatus'));
    blockStatus = e => this.updateStatus(e, 3, lm('simpleBlock'));

    handleItems(idUUID = null) {
        let items = [{ label: ls('contract', 'tab') }];
        if (!idUUID) {
            this.setState({ items: items });
            return;
        }
        if (g_checkAlc(4, 'contract#chat')) items.push({ label: ls('contractChat', 'tab'), icon: 'pi pi-envelope' });
        items.push({ label: ls('contractRate', 'tab'), icon: 'pi pi-list' });
        items.push({ label: ls('contractFroms', 'tab'), icon: 'pi pi-id' });
        if (g_checkAlc(4, 'contract#attachment')) items.push({ label: ls('attachments', 'tab'), icon: 'pi pi-paperclip' });
        items.push({ label: ls('log', 'tab'), icon: 'pi pi-list' });
        this.setState({ items: items });
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        this.handleItems(idUUID);
        this.setState({ mode: mode, idUUID: idUUID }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let response = await ContractService.getContract(this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.contract;
        rdata.contractType = String(rdata.contractType);
        rdata.contractInterestsPercentage = g_treatNumricR(rdata.contractInterestsPercentage);
        rdata.contractTotalCapitalValue = g_treatNumricR(rdata.contractTotalCapitalValue);
        rdata.contractTotalInterestsValue = g_treatNumricR(rdata.contractTotalInterestsValue);
        if (rdata.workflowType !== 5 && rdata.workflowType !== 6) {
            window.location.href = `/#/contract?type=1&&id=${rdata.idUUID}`;
            window.location.reload();
            return;
        }
        this.setState({ data: rdata });
        if (rdata.fillerField1 && rdata.workflowType === 5) {
            let response = await AssetService.list({ filters: [{ key: 'idUUID', value: rdata.fillerField1 }] });
            if (g_displayMessageError(this.messages, response)) return;
            if (response.assets[0]) this.selector4.setState({ selected: response.assets[0].plateId });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        let rdata = this.state.data;
        if (this.state.idUUID !== null) {
            let response = await ContractService.updateContract({ contract: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: lm('simpleSave') });
        } else {
            let response = await ContractService.addContract({ contract: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: lm('simpleCreate') });
            window.location.href = `/#/contractP?type=1&id=${response.contract.idUUID}`;
            window.location.reload();
        }
    };

    stepClick = e => {
        let index = e.index;
        let s = this.getSteps()[index];
        if (!s?.aF) return;
        let step = s.aF[this.state.data.contractStatus];
        if (!step) return;
        if (
            !(
                (step.indexOf('contract#steps-numo') !== -1 && g_checkAlc(5, 'contract#steps-numo') && g_checkAlc(5, 'contract#steps-numo')[0]?.u) ||
                (step.indexOf('contract#steps-protetor') !== -1 && g_checkAlc(5, 'contract#steps-protetor') && g_checkAlc(5, 'contract#steps-protetor')[0]?.u)
            )
        )
            return;
        this.setState({ toSelectedMessage: '', toSelected: s.id, dialog_state_stepclick: true });
    };

    onStepConfirm = e => {
        e.preventDefault();
        if (this.state.toSelected === 12 && !this.state.data.fillerField1) {
            this.messages.show({ severity: 'warning', message: lm('vehiclesNeedToBeFilled') });
            return;
        }
        if (this.state.toSelected === 12) {
            this.handleSubmit({ preventDefault: () => {} });
            let data = { contractComplementaryFields: [{ fieldName: 'car', fieldValue: this.state.plateId }], contractIdUUID: this.state.data.idUUID };
            ContractService.fieldPut(data);
        }
        g_ChangeStatus(
            this,
            () => ContractService.updateStatus({ idUUID: this.state.data.idUUID, contractStatus: this.state.toSelected, contractStatusMessage: this.state.toSelectedMessage }),
            () => this.setState({ selectedViacture: '', toSelected: '', dialog_state_stepclick: false }, this.getData),
            lm('simpleStatus')
        );
    };

    getCStep = () => {
        let steps = this.getSteps();
        let ind = -1;
        steps.forEach((e, i) => (ind = e.id === this.state.data.contractStatus ? i : ind));
        return ind;
    };

    getSteps = () => {
        const acls = ['contract#steps-protetor', 'contract#steps-numo'];
        let toSend = [
            { label: ls('0', 'contractPmodel'), id: 0 },
            { label: ls('5', 'contractPmodel'), id: 5, aF: { 200: acls } },
            { label: ls('10', 'contractPmodel'), id: 10, aF: { 12: acls } },
        ];
        return [
            ...toSend,
            { label: ls('12', 'contractPmodel'), id: 12, aF: { 10: acls } },
            { label: ls('1', 'contractPmodel'), id: 1, aF: { 12: acls } },
            { label: ls('100', 'contractPmodel'), id: 100, aF: { 1: acls } },
        ];
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file, this.state.documentType);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadHandler = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        var response = await ProductService.documentUpload(formData, type, this.state.product.idUUID);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleUpload') });
        this.getData();
    };

    getWorkflowType = () =>
        g_getData(
            this,
            () => ContractTemplateService.getContractTemplate({}, this.state.data.contractTemplateIdUUID),
            response => this.setState({ data: { ...this.state.data, workflowType: response.contractTemplate.workflowType, contractSignatureMode: response.contractTemplate.contractSignatureMode } })
        );
    getTemplates = context => g_getData(context, ContractService.getContractTemplate, 'contractTemplates');
    getConsignees = context =>
        this.state.data.consignatorIdUUID
            ? g_getData(context, ConsigneeService.listConsignee, 'consignees', { filters: [...context.state.filter, { key: 'consignatorIdUUID', value: this.state.data.consignatorIdUUID }] })
            : null;
    getProducts = context => g_getData(context, ProductService.getProducts, 'products');
    getAssets = context => g_getData(context, AssetService.list, 'assets', { filters: [...context.state.filter, { key: 'assetStatus', value: '1' }] });
    getConsignators = S.consignator.processed.list;

    isBlock = (e, numosteps) => {
        if (this.state.mode) return false;
        if (e.u !== 1 && this.state.idUUID !== null) {
            this.setState({ mode: true });
            return true;
        }
        if (e.c !== 1 && this.state.idUUID === null) {
            this.setState({ mode: true });
            return true;
        }
        if (this.state.data.contractStatus === 100) return true;
        if (numosteps && [0, 5, 7, 4].indexOf(this.state.data.contractStatus) !== -1) return false;
        return true;
    };

    render() {
        return g_checkLoginACL(
            e => {
                let numosteps = !g_checkAlc(5, 'contract#steps-numo') || (g_checkAlc(5, 'contract#steps-numo') && !g_checkAlc(5, 'contract#steps-numo')[0]?.u);
                let mode = this.isBlock(e, numosteps);
                let footer = (
                    <div>
                        {g_template_button(ls('yes'), '', 0, null, 0, '', 'p-button-danger')}
                        {g_template_button(
                            ls('no'),
                            '',
                            0,
                            e => {
                                e.preventDefault();
                                let toSet = {};
                                toSet['dialog_state_stepclick'] = false;
                                this.setState({ dialog_state_stepclick: false, toSelected: '', toSelectedMessage: '' });
                            },
                            0,
                            ''
                        )}
                    </div>
                );
                return (
                    <div className="card card-w-title">
                        {g_tabViewRenderOptions(
                            this,
                            [
                                <form className="p-col" onSubmit={this.handleSubmit}>
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                            <Panel>
                                                {this.state.idUUID ? g_template_copy(this, 'Id', this.state.data.idUUID, 'id', !this.state.idUUID, 'p-col-2') : null}
                                                <GSelector
                                                    labelwraper="p-col-3"
                                                    label={ls('contractTemplateTitle')}
                                                    ref={e => (this.selector0 = e)}
                                                    getData={this.getTemplates}
                                                    tableBody={[
                                                        { type: 'd', data: 'title', sortable: true, filter: true },
                                                        {
                                                            type: 'button',
                                                            icon: 'pi pi-plus',
                                                            click: (raw, e) => {
                                                                e.preventDefault();
                                                                this.selector0.setState({ selected: raw.title, visible: false });
                                                                this.setState(
                                                                    { data: { ...this.state.data, contractTemplateIdUUID: raw.idUUID, contractTemplateTitle: raw.title } },
                                                                    this.getWorkflowType
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                    inputtextwarper="p-col-5"
                                                    disabled={mode}
                                                    startV={this.state.data.contractTemplateTitle}
                                                />
                                                <GSelector
                                                    label={ls('consigneeFullnameP')}
                                                    ref={e => (this.selector2 = e)}
                                                    getData={this.getConsignees}
                                                    tableBody={[
                                                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                                                        {
                                                            type: 'button',
                                                            icon: 'pi pi-plus',
                                                            click: (raw, e) => {
                                                                e.preventDefault();
                                                                this.selector2.setState({ ...this.selector2.state, selected: raw.fullname, visible: false });
                                                                this.setState({ data: { ...this.state.data, consigneeIdUUID: raw.idUUID } });
                                                            },
                                                        },
                                                    ]}
                                                    inputtextwarper="p-col-5"
                                                    labelwraper="p-col-3"
                                                    disabled={mode}
                                                    startV={this.state.data.consigneeFullname}
                                                />
                                                <GSelector
                                                    label={ls('productName')}
                                                    ref={e => (this.selector3 = e)}
                                                    getData={this.getProducts}
                                                    tableBody={[
                                                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                                                        {
                                                            type: 'button',
                                                            icon: 'pi pi-plus',
                                                            click: (raw, e) => {
                                                                e.preventDefault();
                                                                this.selector3.setState({ ...this.selector3.state, selected: raw.fullname, visible: false });
                                                                this.setState({ data: { ...this.state.data, productIdUUID: raw.idUUID } });
                                                            },
                                                        },
                                                    ]}
                                                    inputtextwarper="p-col-5"
                                                    labelwraper="p-col-3"
                                                    disabled={mode}
                                                    startV={this.state.data.productName}
                                                />
                                                <GSelector
                                                    label={ls('vehicle')}
                                                    ref={e => (this.selector4 = e)}
                                                    getData={this.getAssets}
                                                    hidden={this.state.data.workflowType !== 5}
                                                    tableBody={[
                                                        { type: 'd', data: 'plateId', sortable: true, filter: true },
                                                        {
                                                            type: 'button',
                                                            icon: 'pi pi-plus',
                                                            click: (raw, e) => {
                                                                e.preventDefault();
                                                                this.selector4.setState({ selected: raw.plateId, visible: false });
                                                                this.setState({ plateId: raw.plateId, data: { ...this.state.data, fillerField1: raw.idUUID } });
                                                            },
                                                        },
                                                    ]}
                                                    inputtextwarper="p-col-5"
                                                    labelwraper="p-col-3"
                                                    disabled={this.state.mode || this.state.data.contractStatus === 12}
                                                    startV={this.state.plateId}
                                                />
                                                {g_template_input(this, 'info', this.state.data.info, ls('info'), mode, false, false, 'p-col', 'p-col-3')}
                                                {g_template_inputArea(this, 'obs', this.state.data.obs, ls('obs'), true, false, false, 'p-col-8', 'p-col-12', '', { minHeight: '2em' })}
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-3 p-lg-3">
                                            <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                                {g_wraper(
                                                    null,
                                                    ls('status', 'generic'),
                                                    <div className="p-col">
                                                        <StateIcon style={{ fontSize: '1.4em' }} custom={states} state={this.state.data.contractStatus} />
                                                    </div>,
                                                    !this.state.idUUID,
                                                    'p-col-7'
                                                )}
                                                {g_wraper(null, ls('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                                {g_wraper(null, ls('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                                {g_wraper(null, ls('ratting'), generateStarsMap(this.state.data.ratting), !this.state.idUUID, 'p-col-7')}
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="p-grid p-fluid">
                                        {g_buttonTemplate(
                                            lb(this.state.idUUID ? 'save' : 'create'),
                                            '',
                                            '',
                                            this.state.data.contractStatus === 100 || numosteps || [0, 4, 5, 7].indexOf(this.state.data.contractStatus) === -1,
                                            null,
                                            this.state.mode,
                                            'p-col-2'
                                        )}
                                        {g_template_button(
                                            lb('schedule'),
                                            '',
                                            !this.state.idUUID || this.state.data.contractStatus !== 10,
                                            e => {
                                                e.preventDefault();
                                                this.stepClick({ index: 3 });
                                            },
                                            this.state.mode,
                                            'p-col-2'
                                        )}
                                        {g_template_dialog(
                                            this,
                                            lm(`ask${this.state.data.contractStatus === 2 ? 'Activate' : 'Deactivate'}`),
                                            'toggle',
                                            this.updateStatusClick,
                                            lb(this.state.data.contractStatus === 2 ? 'activate' : 'deactivate'),
                                            !this.state.idUUID | ([3, 100, 200].indexOf(this.state.data.contractStatus) !== -1) || numosteps,
                                            this.state.mode,
                                            'p-button-danger',
                                            '',
                                            '',
                                            '',
                                            'p-col-2'
                                        )}
                                        {g_template_dialog(
                                            this,
                                            lm('askBlockP'),
                                            'delete',
                                            this.blockStatus,
                                            lb('block'),
                                            !this.state.idUUID | ([3, 100, 200].indexOf(this.state.data.contractStatus) !== -1) || numosteps,
                                            this.state.mode,
                                            'p-button-danger',
                                            '',
                                            '',
                                            '',
                                            'p-col-2',
                                            'p-button-danger'
                                        )}
                                    </div>
                                    <Panel style={{ overflowX: 'auto', width: '100%' }}>
                                        <div className="p-grid p-fluid">
                                            {g_wraper(
                                                '',
                                                '',
                                                <Steps model={this.getSteps()} activeIndex={this.getCStep()} className="steps-custom" readOnly={false} onSelect={this.stepClick} />,
                                                [3, 2].indexOf(this.state.data.contractStatus) !== -1
                                            )}
                                        </div>
                                    </Panel>
                                </form>,
                                ...(g_checkAlc(4, 'contract#chat')
                                    ? [
                                          <Chat
                                              context={this}
                                              apiRequestList={data => {
                                                  data.filters = [{ key: 'referenceIdUUID', value: this.state.data.idUUID }];
                                                  return ChatService.messageList(data);
                                              }}
                                              apiRequestAdd={message => {
                                                  let data = {};
                                                  data.messageContent = message;
                                                  data.messageFlow = 2;
                                                  data.messageType = 1;
                                                  data.operatorEntityIdUUID = g_uuid();
                                                  data.referenceIdUUID = this.state.data.idUUID;
                                                  data.mainEntityIdUUID = this.state.data.consigneeIdUUID;
                                                  data.contextId = 4;
                                                  return ChatService.messageAdd({ chatMessage: data });
                                              }}
                                              apiRequestRead={() => ChatService.messageMarkRead(this.state.data.idUUID, 1)}
                                              apiRequestOpenA={link => g_openWindowDialog(config.PHOTO_CONTRACT_URL + link, 500, 500)}
                                              fakename="contract#chat"
                                          />,
                                      ]
                                    : []),
                                contractRattings(() => this),
                                <RenderContracts data={this.state.data} />,
                                ...(g_checkAlc(4, 'contract#attachment') ? [<Attachment idUUID={this.state.idUUID} documentContextType={4} service={'contract'} />] : []),
                                <Log idUUID={this.state.idUUID} logContextType={4} />,
                            ],
                            null
                        )}
                        <form onSubmit={this.onStepConfirm}>
                            <Dialog
                                visible={this.state['dialog_state_stepclick']}
                                onHide={() => {
                                    let toSet = {};
                                    toSet['dialog_state_stepclick'] = false;
                                    this.setState(toSet);
                                }}
                                footer={footer}
                                style={{ width: '50vw' }}
                                header={ls('confirm', 'titles')}
                            >
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">{lm('askChangeStatusContract')}</div>
                                    {![5].indexOf(this.state.toSelected)
                                        ? g_template_inputArea(
                                              this,
                                              'toSelectedMessage',
                                              this.state.toSelectedMessage,
                                              lm('messageToConsignee'),
                                              0,
                                              0,
                                              1,
                                              'p-col-12',
                                              'p-col-12',
                                              '',
                                              { width: '48vw' },
                                              { data: 'toSelectedMessage' }
                                          )
                                        : null}
                                </div>
                            </Dialog>
                        </form>
                    </div>
                );
            },
            true,
            'contract'
        );
    }
}

export const states = {
    0: { color: 'blue', class: 'pi-step-forward', tooltip: ls('emit', 'tooltip') },
    1: { color: 'green', class: 'pi-play', tooltip: ls('inRoute', 'tooltip') },
    2: { color: 'red', class: 'pi-times', tooltip: ls('canceld', 'tooltip') },
    3: { color: 'red', class: 'pi-ban' },
    4: { color: 'grey', class: 'pi-circle-off', tooltip: ls('emitNumo', 'tooltip') },
    5: { color: 'orange', class: 'pi-align-justify', tooltip: ls('dataMissing', 'tooltip') },
    100: { color: 'green', class: 'pi-check', tooltip: ls('finished', 'tooltip') },
    200: { color: 'red', class: 'pi-minus-circle', tooltip: ls('canceld', 'tooltip') },
    999: { color: 'red', class: 'pi-exclamation-triangle', tooltip: ls('error', 'tooltip') },
    998: { color: 'red', class: 'pi-exclamation-triangle', tooltip: ls('errorAnalysing', 'tooltip') },
    300: { color: 'red', class: 'pi-exclamation-triangle', tooltip: ls('delayConsignee', 'tooltip') },
    301: { color: 'red', class: 'pi-exclamation-triangle', tooltip: ls('delayConsignator', 'tooltip') },
    302: { color: 'red', class: 'pi-exclamation-triangle', tooltip: ls('delayFinancier', 'tooltip') },
    303: { color: 'red', class: 'pi-exclamation-triangle', tooltip: ls('delayProvider', 'tooltip') },
    10: { color: 'orange', class: 'pi-home', tooltip: ls('validationProtetor', 'tooltip') },
    12: { color: 'orange', class: 'pi-calendar', tooltip: ls('scheduled', 'tooltip') },
};
