import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Messages } from 'primereact/messages';
import { Redirect } from 'react-router-dom';
import UserService from '../../service/UserService';
import EncryptPassword from '../utils/EncryptPassword';
import { g_displayMessageError, LS } from '../GenericFunctions';
import * as config from '../../config/config';
import './Login.css';

export default class Login extends Component {
    constructor() {
        super();
        this.state = {
            user: { username: '', password: '' },
            logged: localStorage.getItem('loggedUserN') ? true : false,
            redirect: false,
        };
        this.storage = {};
    }

    handleChange = event => {
        const user = { ...this.state.user };
        const { name, value } = event.target;
        user[name] = value;
        this.setState({ user });
    };

    handleSubmit = async event => {
        event.preventDefault();

        var ciphertext64 = await EncryptPassword.encrypt(this.state.user.password);

        const body = {
            userType: 1,
            tokenExpiration: 720,
            username: this.state.user.username,
            password: ciphertext64,
        };
        const response = await UserService.login(body);

        if (response === undefined || response === null) {
            this.messages.show({ severity: 'error', summary: 'Erro ao acessar o servidor!' });
        } else if (response.code === 0) {
            let entities = '';
            let providers = '';
            let consignators = '';
            let financiers = '';
            let consignatorList = [];
            let providerList = [];
            let financierList = [];
            let associatedEntityList = [];
            response.user.associatedEntities.forEach(v => {
                entities += (entities !== '' ? ',' : '') + v.entityIdUUID;
                if (v.entityUserType === 1) {
                    consignators += (consignators !== '' ? ',' : '') + v.entityIdUUID;
                    consignatorList.push(v.entityIdUUID);
                }
                if (v.entityUserType === 2) {
                    financiers += (financiers !== '' ? ',' : '') + v.entityIdUUID;
                    financierList.push(v.entityIdUUID);
                }
                if (v.entityUserType === 3) {
                    providers += (providers !== '' ? ',' : '') + v.entityIdUUID;
                    providerList.push(v.entityIdUUID);
                }
                associatedEntityList.push(v.entityIdUUID);
            });
            this.storage = {
                token: response.token,
                userName: response.user.username,
                name: response.user.fullname,
                LastLoginDate: response.user.lastLoginDate,
                responseCode: response.code,
                profileACLs: response.user.profileACLs.map(a => ({ ...a, ub: a.u === 1, rb: a.r === 1, db: a.d === 1, cb: a.c === 1 })),
                uuid: response.user.idUUID,
                photo: response.user.photo,
                associatedEntities: entities,
                providers: providers,
                consignators: consignators,
                financiers: financiers,
                consignatorList,
                providerList,
                financierList,
                associatedEntityList,
            };
            localStorage.setItem('loggedUserN', JSON.stringify(this.storage));
            localStorage.removeItem('BASEMEDICALFORM');
            this.setState({ logged: true, redirect: true });
            this.props.handlerLogin(this.state.logged);
        } else if (response.code === 9100) {
            this.messages.show({ severity: 'error', summary: 'Password ou utilizador errado.' });
        } else if (response.code === 9101) {
            this.messages.show({ severity: 'error', summary: 'Utilizador bloqueado!' });
        } else {
            g_displayMessageError(this.messages, response);
        }
    };

    componentDidMount() {
        document.body.style.cssText += 'background-image: none !important;';
        let a = config.loginBackground();
        if (typeof a === 'function') a();
        else document.body.style.background = a;
    }
    componentDidUpdate() {
        document.body.style.cssText += 'background-image: none !important;';
        let a = config.loginBackground();
        if (typeof a === 'function') a();
        else document.body.style.background = a;
    }

    render() {
        const loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN !== null) {
            return <Redirect to="/" />;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="login-div">
                    <div className="login-img-div">
                        <img src={config.loginImage().image} width="100%" alt="" />
                    </div>
                    <Messages ref={el => (this.messages = el)} />
                    <div className="login-field-div">
                        <InputText className="login-field" placeholder={LS('username', 'placeholder')} name="username" onChange={this.handleChange} required />
                    </div>
                    <div className="login-field-div p-float-label">
                        <Password className="login-field" placeholder={LS('password', 'placeholder')} name="password" feedback={false} onChange={this.handleChange} required />
                    </div>
                    <input type="submit" value={LS('login', 'btt')} className="btt" />
                    <div className="login-new-div">
                        {LS('preForgot', 'login')}{' '}
                        <a className="login-new-account" href="#/recover-password">
                            {LS('forgot', 'login')}
                        </a>
                    </div>
                </div>
            </form>
        );
    }
}
