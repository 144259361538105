import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import { useAclHookless } from '../../Generics';
import ProfileService from '../../service/ProfileService';
import {
    g_displayMessageError,
    g_getDateTemplate, g_openWindowDialog, g_tabViewRenderOptions,
    g_template_button, g_template_copy, g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_template_inputArea,
    g_wraper, LS,
    StateIcon
} from '../GenericFunctions';
import Log from '../logs/Log';
import Associciation from './Association';
import AssociciationUser from './AssociationUser';

export default class Profile extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        dateCreation: '',
        dateUpdate: '',
        description: '',
        id: '',
        idUUID: '',
        languageTag: '',
        longDescription: '',
        profileDescription: '',
        profileStatus: '',
        profileType: '',
      },
      idUUID: null,
      mode: false,
      items: [{ label: LS('profile', 'tab') }],
    };
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search);
    let id = query.get('id');
    let mode = query.get('type') === '1' ? false : true;
    this.setState({ idUUID: id, mode: mode }, this.getData);
  }

  getData = async () => {
    if (!this.state.idUUID) return;
    let data = {
      filters: [{ key: 'idUUID', value: this.state.idUUID }],
      orderBy: [],
      pageSize: 1,
      pageNumber: 1,
    };
    let response = await ProfileService.list(data);
    if (g_displayMessageError(this.messages, response)) return;
    let rdata = response.profiles[0];
    rdata.profileType = String(rdata.profileType);
    this.setState({
      data: rdata,
      items: [
        { label: LS('profile', 'tab') },
        { label: LS('association', 'tab'), icon: 'pi pi-sitemap' },
        { label: LS('users', 'tab'), icon: 'pi pi-users' },
        { label: LS('log', 'tab'), icon: 'pi pi-list' },
      ],
      activeItem: null,
      mode: this.state.data.profileStatus === 3 ? true : this.state.mode,
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    if (this.state.idUUID !== null) {
      let data = { profile: this.state.data };
      let response = await ProfileService.update(data);
      if (g_displayMessageError(this.messages, response)) return;
      this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
      this.setState({ activeItem: null }, this.getData);
    } else {
      let data = { profile: this.state.data };
      let response = await ProfileService.add(data);
      if (g_displayMessageError(this.messages, response)) return;
      this.setState({ idUUID: response.profile.idUUID }, this.getData);
      this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
    }
  };

  updateStatus = async e => {
    e.preventDefault();
    if (this.state.data.idUUID === null) return;
    let data = {
      idUUID: this.state.data.idUUID,
      profileStatus: this.state.data.profileStatus === 1 ? 2 : 1,
    };
    let response = await ProfileService.updateStatus(data);
    if (g_displayMessageError(this.messages, response)) return;
    this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
    this.getData();
  };

  render() {
    let { error, profile: e } = useAclHookless({});
    if (error) return error;
    if (!this.state.mode && !e.ub && this.state.idUUID !== null) this.setState({ mode: true });
    if (!this.state.mode && !e.cb && this.state.idUUID === null) this.setState({ mode: true });
    return (
      <div className="card card-w-title">
        {g_tabViewRenderOptions(this, [
          <form className="p-col" onSubmit={this.onSubmit}>
            <div className="p-grid p-fuild">
              <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                <Panel>
                  {g_template_copy(
                    this,
                    'Id',
                    this.state.data.idUUID,
                    'idUUID',
                    !this.state.idUUID,
                    'p-col-4'
                  )}
                  {g_template_input(
                    this,
                    'description',
                    this.state.data.description,
                    LS('description'),
                    this.state.mode,
                    0,
                    1,
                    'p-col-7'
                  )}
                  {g_template_dropdown(
                    this,
                    'profileType',
                    this.state.data.profileType,
                    LS('profileType'),
                    [
                      { label: LS('normal', 'generic'), value: '1' },
                      { label: LS('system', 'generic'), value: '2' },
                    ],
                    this.state.mode,
                    0,
                    1
                  )}
                  {g_template_inputArea(
                    this,
                    'longDescription',
                    this.state.data.longDescription,
                    LS('longDescription'),
                    this.state.mode,
                    0,
                    1,
                    'p-col-10',
                    'p-col-3',
                    '',
                    {
                      minHeight: '7em',
                    }
                  )}
                </Panel>
              </div>
              <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                <Panel
                  style={{
                    borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '',
                    height: '100%',
                  }}
                >
                  {g_wraper(
                    null,
                    LS('status', 'generic'),
                    <div className="p-col">
                      <StateIcon
                        small
                        state={this.state.data.profileStatus}
                        custom={{
                          1: { color: 'green', class: 'pi-check' },
                          2: { color: 'red', class: 'pi-times' },
                          3: { color: 'red', class: 'pi-ban' },
                        }}
                      />
                    </div>,
                    this.state.idUUID == null,
                    'p-col-7'
                  )}
                  {g_wraper(
                    null,
                    LS('dateCreation'),
                    g_getDateTemplate(this.state.data.dateCreation),
                    this.state.idUUID == null,
                    'p-col'
                  )}
                  {g_wraper(
                    null,
                    LS('dateUpdate'),
                    g_getDateTemplate(this.state.data.dateUpdate),
                    this.state.idUUID == null,
                    'p-col'
                  )}
                </Panel>
              </div>
            </div>
            <div className="p-grid p-fluid">
              {g_template_button(
                LS('copy', 'btt'),
                '',
                !this.state.idUUID,
                e => {
                  e.preventDefault();
                  g_openWindowDialog(
                    `#/copyProfile?type=1&id=${this.state.data.idUUID}`,
                    `copy_${this.state.idUUID}`,
                    700,
                    500
                  );
                },
                this.state.mode && e.c !== 1,
                'p-col-1',
                'p-button-warning'
              )}
              {g_template_button(
                LS(this.state.idUUID ? 'save' : 'create', 'btt'),
                '',
                this.state.data.profileStatus === 2,
                null,
                this.state.mode,
                'p-col-1'
              )}
              {g_template_dialog(
                this,
                LS(
                  `ask${this.state.data.profileStatus === 2 ? 'Activate' : 'Deactivate'}`,
                  'messages'
                ),
                'profileStatus',
                this.updateStatus,
                LS(this.state.data.profileStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                !this.state.idUUID || this.state.data.profileStatus === 3,
                this.state.mode,
                'p-button-danger',
                '',
                '',
                '',
                'p-col-2',
                'p-button-danger'
              )}
            </div>
          </form>,
          <Associciation idUUID={this.state.idUUID} />,
          <AssociciationUser idUUID={this.state.idUUID} />,
          <Log idUUID={this.state.idUUID} logContextType={15} />,
        ])}
      </div>
    );
  }
}
