import axios from './AxiosService';

class CampaignService {
    async getCampaigns(productFilter) {
        return await axios.request('POST', '/campaign/list', productFilter);
    }

    async getCampaign(data, campaignIdUUID) {
        return await axios.request('GET', `/campaign/${campaignIdUUID}`, data);
    }

    async copy(data) {
        return await axios.request('PUT', `/campaign/copy`, data);
    }

    async addCampaign(data) {
        return await axios.request('POST', `/campaign/add`, data);
    }

    async getLogs(data) {
        return await axios.request('POST', `/campaign/log/list`, data);
    }

    async updateStatus(data) {
        return await axios.request('PUT', `/campaign/update/status`, data);
    }

    async getCampaignTypes(data) {
        return await axios.request('POST', `/campaign/campaignType/list`, data);
    }

    async getCampaignModes(data) {
        return await axios.request('POST', `/campaign/campaignModes`, data);
    }

    async updateCampaign(data) {
        return await axios.request('PUT', `/campaign/update`, data);
    }

    updateCampaignConfigs = data => axios.request('PUT', `/campaign/update/config`, data);

    async documentUpload(formData, documentTypeId, campaignUUID) {
        const config = {
            'content-type': 'multipart/form-data',
            'background-request': true,
        };

        return await axios.request('POST', `/campaign/document/upload/${documentTypeId}/${campaignUUID}`, formData, config);
    }

    /**
     * @deprecated
     * @param {*} data
     * @returns
     */
    async addCampaignItem(data) {
        return await axios.request('POST', `/campaign/item/add`, data);
    }

    /**
     * @deprecated
     * @param {*} data
     * @returns
     */
    async removeCampaignItem(data, ItemIdUUID) {
        return await axios.request('DELETE', `/campaign/item/${ItemIdUUID}`, data);
    }

    /**
     * @deprecated
     * @param {*} data
     * @returns
     */
    async getCampaignItemList(data) {
        return await axios.request('POST', `/campaign/item/list/`, data);
    }
}

export default new CampaignService();
