/**
 * 
 * This document has compoents that contain the acl
 * 
 * 	- dashboard-video-protetor
 * 
 * 
 */
import React, { useEffect, useState } from 'react';
import { CallRecordListFilters } from '../../../../api-ts-bindings/Call';
import { pair } from '../../../../api-ts-bindings/Generic';
import config from '../../../../config/config';
import { useAcl } from '../../../../Generics';
import { Requests } from '../../../../Requests';
import { Button, checkLoginACL, GDiv, g_getData, StringEmpty } from '../../../Generic';
import { g_displayMessageError, ls, TablePage } from '../../../GenericFunctions';
import { states as CPstates } from '../../contract-protector/ContractP';
import { MedicalCertificateList } from '../MedicalCertificate';
import { MedicalExamList } from '../MedicalExam';
import { PrescriptionList } from '../Prescription';
import { UTIObj } from '../videocallnew';

export const CallDocumentList = () => {
	const { error, profile, user } = useAcl({ fake_name: 'dashboard-video-protetor' });

	const formatTime = (sec: number) => {
		sec = Math.round(sec);
		const h = Math.floor(sec / 60 / 60);
		if (h > 0) sec = sec - h * 60 * 60;
		const min = Math.floor(sec / 60);
		if (min > 0) sec = sec - min * 60;
		return `${h > 0 ? h + ':' : ''}${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
	};

	if (error) return error;
	return (
		<TablePage
			checkAcl={false}
			getData={c =>
				g_getData<any, CallRecordListFilters, string>(
					c,
					data => {
						data.filters = [...(data.filters ?? []), pair('useriduuid', user!.uuid)];
						return Requests.call.list(data);
					},
					'callRecords'
				)
			}
			header={null}
			table={c => [
				{ t: 'd', data: 'originName', sortable: true, filter: true },
				{ t: 'd', data: 'destinationName', sortable: true, filter: true },
				{ t: 'date', data: 'dateCreation', sortable: true, filter: true },
				{ t: 'date', data: 'dateStartCall', sortable: true, filter: true },
				{ t: 'date', data: 'dateEndCall', sortable: true, filter: true },
				{
					t: 'di',
					data: 'duration',
					di: raw =>
						raw.dateStartCall && raw.dateEndCall
							? formatTime(
									(new Date(raw.dateEndCall).getTime() - new Date(raw.dateStartCall).getTime()) /
										1000
							  )
							: '',
				},
				{
					t: 'di',
					data: 'waitTime',
					di: raw =>
						raw.dateStartQueueWait && raw.dateEndQueueWait
							? formatTime(
									(new Date(raw.dateEndQueueWait).getTime() -
										new Date(raw.dateStartQueueWait).getTime()) /
										1000
							  )
							: '',
				},
				{ t: 'd', title: ls('state', 'dataTableGeneric'), data: 'callRecordStatusDescription' },
				{
					t: 'state',
					title: ls('contractState', 'dataTableGeneric'),
					data: 'contractStatus',
					states: CPstates,
				},
				{
					t: 'url',
					icon: 'pi pi-file-o',
					title: ls('medicalRecord'),
					class: '',
					url: raw => `#/vandocs?type=1&id=${raw.contractIdUUID}`,
					callback: c.getData,
					c: profile!.ub || profile!.db,
				},
			]}
		/>
	);
};

interface VanDocsProps {
	idUUID: string;
}
export const VanDocs = ({ idUUID }: VanDocsProps) => {
	const { error } = checkLoginACL({ fakename: 'dashboard-video-protetor' });

	const [url, setUrl] = useState<string>('');

	useEffect(() => {
		(async () => {
			const r = await Requests.contract.get(idUUID);
			//TODO: remove null
			if (g_displayMessageError(null, r) || !r.contract) return;

			const uci =
				(r.contract.contractComplementaryFields ?? []).filter(a => a.fieldName.match(/^uti$/gi))[0]
					?.fieldValue ?? '';

			try {
				const j: UTIObj = JSON.parse(uci);
				if (j.sign) {
					setUrl(j.url);
				}
			} catch {
				// empty
			}
		})();
		//! NOTE: this is not need as the iduuid is not bound to change without this component being destroyed
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (error) return error;
	return (
		<>
			<h1>{ls('prescription', 'call')}</h1>
			<PrescriptionList
				header={null}
				checkAcl={false}
				extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', idUUID)] })}
				extra={[
					{
						t: 'btt',
						icon: 'pi pi-download',
						class: 'p-button-success',
						extra: 'p-col-12',
						click: (raw, e) => {
							e.preventDefault();
							window.open(config.HEALTH + raw.url);
						},
						show: raw => StringEmpty(raw.url),
					},
				]}
			/>
			<h1>{ls('medicalCertificate', 'call')}</h1>
			<MedicalCertificateList
				header={null}
				extra={[
					{
						t: 'btt',
						icon: 'pi pi-download',
						class: 'p-button-success',
						extra: 'p-col-12',
						click: (raw, e) => {
							e.preventDefault();
							window.open(config.HEALTH + raw.url);
						},
					},
				]}
				checkAcl={false}
				extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', idUUID)] })}
			/>
			<h1>{ls('exams', 'call')}</h1>
			<MedicalExamList
				header={null}
				extra={[
					{
						t: 'btt',
						icon: 'pi pi-download',
						class: 'p-button-success',
						extra: 'p-col-12',
						click: (raw, e) => {
							e.preventDefault();
							window.open(config.HEALTH + raw.url);
						},
					},
				]}
				checkAcl={false}
				extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', idUUID)] })}
			/>
			{(() => {
				if (!url) return null;
				return (
					<GDiv>
						<Button
							label="UTI"
							type="button"
							onClick={() => {
								window.open(config.HEALTH + url, '_blank');
							}}
						/>
					</GDiv>
				);
			})()}
		</>
	);
};
