import React, { Component } from 'react';
import { Messages } from 'primereact/messages';
import { LS, g_getHeaderTemplate, g_genericRequired, g_hideSearch, g_template_dataTable, g_checkLoginACL } from '../GenericFunctions';
import ContractTemplateFieldService from '../../service/ContractTemplateFieldService';

export default class ContractTemplateFieldManagement extends Component {
    constructor() {
        super();
        this.state = { ...g_genericRequired([{ key: 'fieldPosition', value: 'asc' }]), idUUID: null };
    }
    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        g_hideSearch();
        this.setState({ idUUID: idUUID }, this.getData);
    }
    getData = async () => {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            filters: [...this.state.filter, { key: 'contractTemplateFormIdUUID', value: this.state.idUUID }],
            orderBy: this.state.orderBy,
        };
        let response = await ContractTemplateFieldService.getContractTemplatesFields(data);
        this.setState(() => ({
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal,
            data: response.contractTemplateFields,
        }));
    };
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('contractTemplateField', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/contractTemplateField?type=1&contractTemplateForm=' + this.state.idUUID + '', 'addContractTemplateField', this.getData, 800, 500),
                    [
                        { type: 'd', data: 'fieldPosition', sortable: true, filter: true },
                        { type: 'd', data: 'fieldName', sortable: true, filter: true },
                        { type: 'd', data: 'title', sortable: true, filter: true },
                        { type: 'di', data: 'mandatory', sortable: true, di: raw => (raw.mandatory === 0 ? LS('no') : LS('yes')) },
                        {
                            type: 'state',
                            data: 'contractTemplateFieldStatus',
                            states: {
                                1: { color: 'green', class: 'pi-check' },
                                2: { color: 'red', class: 'pi-times' },
                                3: { color: 'red', class: 'pi-ban' },
                            },
                        },
                        {
                            type: 'url',
                            c: e.u === 1 || e.d === 1,
                            url: raw => `#/contractTemplateField?type=1&id=${raw.idUUID}&contractTemplateForm=${this.state.idUUID}`,
                            width: 800,
                            height: 500,
                            callback: this.getData,
                        },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
