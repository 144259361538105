import 'bootstrap-icons/font/bootstrap-icons.css';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { pair } from '../../api-ts-bindings/Generic';
import { SearchAreaServiceTypeModel } from '../../api-ts-bindings/search/SearchArea';
import * as Config from '../../config/config';
import { Requests } from '../../Requests';
import { Button, Input, InputMask, useDataObject } from '../Generic';
import { g_displayMessageError, g_providers, g_wraper, lm, ls, S } from '../GenericFunctions';
import { videoCallMessage, videoCallMessageCallFail, videoCallMessageInit, videoCallMessageOffer, videoCallMessagesType } from '../GenericType';
import { VanDocs } from '../protetor/calls/van/docs';
import EncryptPassword from '../utils/EncryptPassword';
import './videodash.css';

const { RTCPeerConnection, RTCSessionDescription } = window;

export function VideocallDashbord() {
    //Connection
    let [tempUserData, setTempUserData] = useState(null as any);
    let [canConnect, setCanConnect] = useState(false);
    let [inCall, setInCall] = useState(false);
    let [inPreOffer, setPreOffer] = useState(false);
    let [inWait, setInWait] = useState(false);
    let [lastMessageBeforeCall, setLastMessageBeforeCall] = useState(null as videoCallMessage | null);
    let [ratting, setRatting] = useState(false);
    let [behaviourIdUUID, setBehaviourIdUUID] = useState('');
    let [contractTemplateIdUUID, setContractTemplateIdUUID] = useState('');
    let [askForConfirmMessage, setAskForConfirmMessage] = useState('');
    let [askForConfirm, setAskForConfirm] = useState(false);
    //let [searchAreaServiceType, setSearchAreaServiceType] = useState('');
    let [isCallPossible, setIsCallPossible] = useState(false);

    let [video, setVideo] = useState(true);
    let [mute, setMute] = useState(false);
    let [expanded, setExpanded] = useState(false);

    let streamLocal = useRef(null as MediaStream | null);

    let stomp = useRef<any>(null);
    let socket = useRef<any>(null);

    let localVideo = useRef(null as any as HTMLVideoElement);
    let remoteVideo = useRef(null as any as HTMLVideoElement);
    let inPreCallR = useRef(false);
    let lastCallContractIdUUID = useRef('');
    let lastCallIdUUID = useRef('');
    let subTeleProductR = useRef(null as any);
    let messages = useRef(null as any as Messages);
    let buttonCreateActive = useRef(false);
    let inPreCall = inPreCallR.current;
    let subTeleProduct = subTeleProductR.current;

    let formRef: React.MutableRefObject<HTMLFormElement | null> = useRef(null);

    //timeouts
    let getProviderTimeout = useRef(null as any as number);

    let [provider, setProvider] = useState(null as any);

    let consigneeRef = useRef({} as { idUUID: string; consignatorIdUUID: string });

    let [login, setLogin] = useState(true);

    let { obj, setObj, setObjState, clear } = useDataObject({
        vatNumber: '',
        name: '',
        dateBirth: '',
        email: '',
        phoneMobile: '',
    });

    let pc = useRef<RTCPeerConnection | null>(null);

    let socketConnect = () => {
        if ((window as any).SockJS && (window as any).Stomp) {
            const SockJS = (window as any).SockJS;
            const Stomp = (window as any).Stomp;
            let socketTemp = new SockJS(Config.default.SOCKET);
            let stompTemp = Stomp.over(socketTemp);
            stomp.current = stompTemp;
            socket.current = socketTemp;
            stompTemp.debug = null;
            stompTemp.connect({ token: tempUserData.token }, () => {
                setCanConnect(true);
                connect(true);
            });
        }
    };

    //onMount
    useEffect(() => {
        async function onMount() {
            if (!localStorage.getItem('loggedUserN')) {
                localStorage.removeItem('loggedUserN');
                window.location.href = '/#/login';
                window.location.reload();
                return;
            }
            let tempUserData = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
            setTempUserData(tempUserData);

            let subsR = await Requests.provider.searchAreaServiceType.list({ providerIdUUID: g_providers(), filters: [pair('loadContractTemplates', '1')] });
            if (g_displayMessageError(messages.current, subsR)) return;

            let subIdUUID = '';
            let subP: SearchAreaServiceTypeModel | undefined = undefined;

            bigloop: for (let sub of subsR.searchAreaServiceTypes) {
                for (let subProduct of sub.entitySearchAreaServiceTypeProducts) {
                    if (subProduct.relationType === 2) {
                        subIdUUID = subProduct.contractTemplateIdUUID;
                        subP = sub;
                        break bigloop;
                    }
                }
            }

            let subExtra = await Requests.consignee.subscriptions({ filters: [pair('campaignIdUUID', subIdUUID), pair('campaignStatus', '1')] });
            if (g_displayMessageError(null, subExtra) || subExtra.subscriptionCampaigns.length === 0) return;

            let sub = subExtra.subscriptionCampaigns[0];
            let behaviourIdUUID = '';
            let contractTemplate = '';
            //let searchAreaServiceType = '';

            for (let product of sub.products) {
                if (product.workflowTypeId === 6) {
                    behaviourIdUUID = sub.idUUID + '|' + (subP?.id ?? 0);
                    contractTemplate = product.contractTemplateIdUUID;
                    //searchAreaServiceType = `${product.searchAreaServiceType}`;
                    subTeleProductR.current = product;
                    break;
                }
            }

            if (!behaviourIdUUID || !contractTemplate) {
                messages.current.show({ severity: 'error', summary: lm('warnNoVideoCallSeriveAvalivable') });
                return;
            }

            setBehaviourIdUUID(behaviourIdUUID);
            setContractTemplateIdUUID(contractTemplate);
            //            setSearchAreaServiceType(searchAreaServiceType);
            setIsCallPossible(true);
        }
        onMount();

        //On unmount
        return () => {
            if (getProviderTimeout.current) clearTimeout(getProviderTimeout.current);
        };
    }, []);

    useEffect(() => {
        (async () => {
            let { vatNumber: vatP, dateBirth: date } = obj;
            vatP = vatP.replace(/[^\d]/g, '').replace(/^0*/, '');

            if (!vatP || !date) return;

            let exists = await S.user.verify('Protetor', 0, vatP);
            if (exists.code !== 9210) {
                //Get user id
                let userR = await S.user.list({ filters: [pair('username', vatP)] });
                if (g_displayMessageError(messages.current, userR) && userR.users.length === 0) return;
                let consigneeR = await S.consignee.list({ filters: [pair('userIdUUID', userR.users[0].idUUID)] });
                if (g_displayMessageError(messages.current, consigneeR)) return;
                if (consigneeR.consignees.length === 0 || consigneeR.consignees[0].dateBirth.substr(0, 9) !== date.substr(0, 9)) {
                    return;
                }
                setObjState({ ...obj, phoneMobile: consigneeR.consignees[0].phoneMobile, email: consigneeR.consignees[0].email, name: consigneeR.consignees[0].fullname });
                return;
            }
        })();
        //TODO: Improve
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [obj.vatNumber, obj.dateBirth]);

    async function connect(/*force = false,*/ con = false) {
        if (!login || !tempUserData) return;
        if ((!stomp || !canConnect) && !con) {
            socketConnect();
            return;
        }
        await stomp.current.subscribe(`/user/${tempUserData.uuid}/queue/messages`, onSubscription);
        inPreCall = true;
        /*if (force) {
            startCall(true);
        }*/
        if (!streamLocal.current) {
            getNavigationDevices();
        }
    }

    function startCall(/*force = false*/) {
        let message: videoCallMessageInit = {
            type: videoCallMessagesType.init,
            //behaviour: force ? 3 : 2,
            //behaviourIdUUID: force ? searchAreaServiceType : behaviourIdUUID,
            behaviour: 4,
            behaviourIdUUID: behaviourIdUUID,
            contract: {
                consigneeIdUUID: consigneeRef.current.idUUID,
                consignatorIdUUID: consigneeRef.current.consignatorIdUUID,
                contractTemplateIdUUID: contractTemplateIdUUID,
            },
        };
        setProvider(null);
        stomp.current.send('/app/handshake', {}, JSON.stringify(message));
    }

    function endCall() {
        let message: videoCallMessage | null = null;
        setProvider(null);
        if ((inCall || inPreOffer) && lastMessageBeforeCall) {
            message = {
                type: videoCallMessagesType.endcall,
                referenceIdUUID: lastMessageBeforeCall.referenceIdUUID,
                recipient: lastMessageBeforeCall.recipient,
                callIdUUID: lastMessageBeforeCall.callIdUUID,
            };
        } else {
            message = {
                ...lastMessageBeforeCall,
                type: videoCallMessagesType.initcancel,
            };
        }
        if (stomp.current) stomp.current.send('/app/handshake', {}, JSON.stringify(message));
    }

    async function getProvider() {
        let r = await S.contract.get(lastCallContractIdUUID.current, true);
        if (g_displayMessageError(null, r)) return;
        let r2 = await S.provider.list({ filters: [pair('useriduuid', r.contract.workingEntityIdUUID)] }, true);
        if (g_displayMessageError(null, r2) || r2.providers.length === 0) return;
        setProvider(r2.providers[0]);
        getProviderTimeout.current = null as any as number;
    }

    async function onSubscription(e: any) {
        if (!inPreCall) return;
        if (e.command === 'MESSAGE') {
            let body = JSON.parse(e.body);
            if (body.context === 'app/handshake') {
                let handshake = body.handshake as videoCallMessage;
                if (handshake.type === videoCallMessagesType.initok) {
                    setInWait(true);
                    setPreOffer(true);
                    setLastMessageBeforeCall(handshake);
                    console.log('set');
                    lastCallContractIdUUID.current = handshake.referenceIdUUID ?? '';
                    lastCallIdUUID.current = handshake.callIdUUID ?? '';
                } else if (handshake.type === videoCallMessagesType.initwait) {
                    setInWait(true);
                    console.log('reset');
                    setPreOffer(false);
                    setLastMessageBeforeCall(handshake);
                    lastCallContractIdUUID.current = '';
                    lastCallIdUUID.current = '';
                } else if (handshake.type === videoCallMessagesType.initfail) {
                    let fail = handshake as videoCallMessageCallFail;
                    setInCall(false);
                    setInWait(false);
                    inPreCall = false;
                    setPreOffer(false);
                    setLogin(true);
                    let askForConfirmMessage = '';
                    if (fail.messages === null) {
                        streamLocal.current = null;
                        closeNavigationDevices();
                        messages.current.show({
                            severity: 'error',
                            summary: 'Erro ao iniciar a chamada',
                        });
                        return;
                    }
                    for (let message of fail.messages) {
                        if (message.key === 205) {
                            askForConfirmMessage = 'Este produto não se encontra disponível na sua subscrição.';
                        } else if (message.key === 201) {
                            askForConfirmMessage = 'Voce não possui subscrição ativa para esse produto.';
                        } else if (message.key === 202) {
                            askForConfirmMessage = 'A sua subscrição encontra-se cancelada para esse produto.';
                        } else if (message.key === 203) {
                            askForConfirmMessage = 'A sua subscrição encontra-se expirada para esse produto.';
                        } else if (message.key === 206) {
                            messages.current.show({
                                severity: 'error',
                                summary: 'Voce não possui nenhum cartao cadastrado. Devera cadastrar o seu cartao na opção de cartões e tentar novamente.',
                            });
                        } else if (message.key === 499) {
                            messages.current.show({ severity: 'error', summary: 'O seu cartão cadastrado não foi autorizado.' });
                        }
                    }
                    if (askForConfirmMessage !== '') {
                        setAskForConfirm(true);
                        let { billingToMode: bi, price } = subTeleProduct;
                        if (bi === 5 || bi === 8) {
                            askForConfirmMessage += ` Será cobrando no seu cartão cadastrado o valor de ${price}R$. Deseja continuar ?`;
                        } else if (bi === 1) {
                            askForConfirmMessage += ` Sera cobrado o valor de ${price}R$ no seu boleto mensal. Deseja continuar ?`;
                        } else if (bi === 3) {
                            askForConfirmMessage += ` Sera cobrado na sua folha o valor de ${price}R$. Deseja continuar ?`;
                        } else {
                            setAskForConfirm(false);
                            askForConfirmMessage = '';
                        }
                    }
                    setAskForConfirmMessage(askForConfirmMessage);
                } else if (handshake.type === videoCallMessagesType.offerphase1req) {
                    setInCall(true);
                    let offer = handshake as videoCallMessageOffer;

                    await pc.current?.setRemoteDescription(new RTCSessionDescription(JSON.parse(offer.value)));
                    let rTCoffer = await pc.current?.createAnswer();
                    await pc.current?.setLocalDescription(rTCoffer);

                    let message: videoCallMessageOffer = {
                        ...offer,
                        type: videoCallMessagesType.offerphase1resp,
                        value: JSON.stringify(rTCoffer),
                    };

                    stomp.current.send('/app/handshake', {}, JSON.stringify(message));
                } else if (handshake.type === videoCallMessagesType.offerphase2req) {
                    let offer = handshake as videoCallMessageOffer;

                    await pc.current?.setRemoteDescription(new RTCSessionDescription(JSON.parse(offer.value)));
                    let rTCoffer = await pc.current?.createAnswer();
                    await pc.current?.setLocalDescription(rTCoffer);

                    setLastMessageBeforeCall(offer);
                    getProviderTimeout.current = setTimeout(getProvider, 5000) as any as number;

                    let message: videoCallMessageOffer = {
                        ...offer,
                        type: videoCallMessagesType.offerphase2resp,
                        value: JSON.stringify(rTCoffer),
                    };

                    stomp.current.send('/app/handshake', {}, JSON.stringify(message));
                } else if (
                    handshake.type === videoCallMessagesType.hangup ||
                    handshake.type === videoCallMessagesType.endcallok ||
                    handshake.type === videoCallMessagesType.initcancelok
                ) {
                    if (handshake.type === videoCallMessagesType.hangup || handshake.type === videoCallMessagesType.endcallok) {
                        if (handshake.callIdUUID !== lastCallIdUUID.current) return;
                        closeNavigationDevices();
                        setRatting(true);
                    }
                    setInCall(false);
                    setInWait(false);
                    streamLocal.current = null;
                    inPreCall = false;
                    setPreOffer(false);
                    setLogin(true);
                    closeNavigationDevices();
                } else {
                    console.log(handshake);
                }
            }
        }
    }

    async function closeNavigationDevices() {
        let lV = localVideo.current as any as HTMLVideoElement;
        let rV = remoteVideo.current as any as HTMLVideoElement;
        if (lV) {
            lV.pause();
            lV.srcObject = null;
        }
        pc.current?.close();
        socket.current.close();
        if (rV && rV.srcObject) {
            try {
                (rV.srcObject as MediaStream).getTracks().forEach(t => t.stop());
            } catch (_) {}
        }
        if (streamLocal.current) {
            streamLocal.current.getTracks().forEach(t => t.stop());
            streamLocal.current.getAudioTracks().forEach(t => t.stop());
            streamLocal.current.getVideoTracks().forEach(t => t.stop());
        }
        setCanConnect(false);
        streamLocal.current = null;
        setMute(false);
        setVideo(true);
        setExpanded(false);
        localVideo.current = null as any;
        remoteVideo.current = null as any;
        stomp.current = null;
        setTimeout(() => {
            socket.current = null;
            pc.current = null;
        });
    }

    async function getNavigationDevices() {
        pc.current = new RTCPeerConnection({
            iceServers: [{ urls: 'turn:usenumo.com.br:3478', username: 'vcprt', credential: 'pVc2020ProtetorXxX' }],
        });
        let stream: MediaStream = null as any as MediaStream;
        let lV = localVideo.current as any as HTMLVideoElement;
        let rV = remoteVideo.current as any as HTMLVideoElement;
        //Set to recive tracks
        pc.current.ontrack = ev => {
            ev.streams.forEach(a => (rV.srcObject = a));
        };
        pc.current.oniceconnectionstatechange = e => {
            console.log(e, pc.current, pc.current?.iceConnectionState);
            // if (pc.iceConnectionState === 'disconnected' || pc.iceConnectionState === 'closed') ended = true;
            // else ended = false;
        };
        //Set navigator to get mediaDevices
        (navigator as any).getUserMedia =
            (navigator as any).getUserMedia || (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia || (navigator as any).msGetUserMedia;
        //get Media devices
        if (typeof navigator.mediaDevices === 'undefined') {
            (navigator as any).getUserMedia(
                { video: true, audio: true },
                (s: any) => {
                    stream = s;
                    if (localVideo) lV.srcObject = stream;
                    streamLocal.current = stream;
                    stream.getTracks().forEach((track: any) => {
                        pc.current?.addTrack(track, stream);
                    });
                    lV.play();
                },
                (error: any) => {
                    console.warn(error.message);
                }
            );
        } else {
            let getMedia = async () => {
                try {
                    stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                    streamLocal.current = stream;
                    if (localVideo) lV.srcObject = stream;
                    stream.getTracks().forEach((track: any) => {
                        pc.current?.addTrack(track, stream);
                    });
                } catch (err) {
                    console.warn(err);
                }
            };
            await getMedia();
        }

        startCall();
    }

    let list: ReactElement | null = null;

    if (login && lastCallContractIdUUID.current) list = <VanDocs idUUID={lastCallContractIdUUID.current} />;

    if (login) {
        return (
            <>
                <form
                    ref={e => (formRef.current = e)}
                    onSubmit={async e => {
                        e.preventDefault();
                        buttonCreateActive.current = false;
                        let { vatNumber: vatP, dateBirth: date, email, phoneMobile: phone, name } = obj;

                        vatP = vatP.replace(/[^\d]/g, '').replace(/^0*/, '');

                        if (vatP === '') {
                            setObjState({ ...obj, vatNumber: '' });
                            setTimeout(() => {
                                if (formRef.current) formRef.current.reportValidity();
                                if (messages.current) messages.current.show({ severity: 'warn', summary: lm('invalidVatNumber') });
                            });
                            buttonCreateActive.current = true;
                            return;
                        }

                        let exists = await S.user.verify('Protetor', 0, vatP);
                        if (exists.code !== 9210) {
                            //Get user id
                            let userR = await S.user.list({ filters: [pair('username', vatP)] });
                            if (g_displayMessageError(messages.current, userR) && userR.users.length === 0) {
                                buttonCreateActive.current = true;
                                return;
                            }
                            let consigneeR = await S.consignee.list({ filters: [pair('userIdUUID', userR.users[0].idUUID)] });
                            if (g_displayMessageError(messages.current, consigneeR)) return;
                            if (consigneeR.consignees.length === 0 || consigneeR.consignees[0].dateBirth.substr(0, 23) !== date) {
                                messages.current.show({ severity: 'error', summary: lm('dataNotRecorded') });
                                buttonCreateActive.current = true;
                                return;
                            }
                            consigneeRef.current = consigneeR.consignees[0];
                            connect();
                            setLogin(false);
                            buttonCreateActive.current = true;
                            return;
                        }

                        //Create user
                        let encpin = await EncryptPassword.encryptGen((ts: string) => `${Math.round(Math.random() * 1000000)}{ts:${ts}}`);
                        let passwordpin = await EncryptPassword.encryptGen((ts: string) => `${vatP.padStart(11, '0')}${new Date(date).getTime()}{ts:${String(ts)}}`);
                        let data = {
                            applicationId: 'Protetor',
                            consignee: {
                                email,
                                username: vatP,
                                phoneMobile: phone,
                                fullname: name,
                                dateBirth: date,
                                consigneeType: 0,
                                entityType: 0,
                                vatNumber: vatP,
                                consigneeStatus: 1,
                                consignatorIdUUID: Config.PROTETORIDUUID,
                            },
                            password: passwordpin,
                            pin: encpin,
                        };
                        let consignee_add = await S.consignee.add(data);
                        if (g_displayMessageError(messages.current, consignee_add)) {
                            buttonCreateActive.current = true;
                            return;
                        }

                        consigneeRef.current = consignee_add.consignees[0];
                        connect();
                        setLogin(false);
                        buttonCreateActive.current = true;
                    }}
                >
                    <Messages ref={messages} />
                    <div className="p-card p-grid p-fluid" style={{ display: 'grid', placeItems: 'center' }}>
                        <div style={{ padding: '5px', marginBottom: '2em' }}>
                            <h1>{ls('dataForPacient', 'titles')}</h1>
                        </div>
                        <div style={{ width: '50%' }}>
                            <InputMask fieldClass="p-col-4" required id="vatNumber" value={obj.vatNumber} onChange={setObj} />
                            {g_wraper(
                                'dateBirth',
                                ls('dateBirth'),
                                <div className="p-col-3">
                                    <Calendar
                                        required
                                        hourFormat={ls('hourFomart', 'default')}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={`1900:${new Date().getFullYear()}`}
                                        id="dateBirth"
                                        dateFormat="dd/mm/yy"
                                        placeholder="dd/mm/yyyy"
                                        value={new Date(obj.dateBirth)}
                                        onChange={async e => {
                                            setObj(e, { date: true });
                                        }}
                                    />
                                </div>,
                                false,
                                'p-col-3'
                            )}
                            <Input required id="name" value={obj.name} onChange={setObj} />
                            <Input required id="email" value={obj.email} onChange={setObj} />
                            <InputMask fieldClass="p-col-4" required id="phoneMobile" value={obj.phoneMobile} onChange={setObj} />
                            <div className="p-grid" style={{ justifyContent: 'space-around' }}>
                                <Button type="submit" wraperClass="p-col-3" label="startMedicineCall" disabled={!isCallPossible || !buttonCreateActive} />
                                <Button
                                    type="reset"
                                    wraperClass="p-col-3"
                                    label="clearData"
                                    onClick={() => {
                                        clear();
                                        lastCallContractIdUUID.current = '';
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {list}
                </form>

                <Dialog
                    visible={askForConfirm}
                    footer={
                        <div>
                            <Button
                                label="Ok"
                                wraperClass=""
                                onClick={() => {
                                    setAskForConfirm(false);
                                    closeNavigationDevices();
                                    //connect(true);
                                }}
                            />
                            {/*
                            <Button
                                label="no"
                                wraperClass=""
                                bttClass="p-button-danger"
                                onClick={() => {
                                    setAskForConfirm(false);
                                    closeNavigationDevices();
                                }}
                            />*/}
                        </div>
                    }
                    onHide={() => {
                        setAskForConfirm(false);
                        closeNavigationDevices();
                    }}
                >
                    {askForConfirmMessage}
                </Dialog>
                <Rating
                    idUUID={lastCallContractIdUUID.current}
                    visible={ratting}
                    onHide={() => {
                        setRatting(false);
                    }}
                    onSubmited={() => setRatting(false)}
                />
            </>
        );
    }

    return (
        <>
            <div className="videocall">
                <div className={`remote ${!inCall ? 'small' : ''} ${expanded ? 'expanded' : ''}`}>
                    {(() => {
                        if (!streamLocal.current) {
                            return <div className="bigText">{lm('enableCamera')}</div>;
                        }
                        if (!inCall) {
                            return <div className="bigText">{lm('callOnWait')}</div>;
                        }
                        return <></>;
                    })()}
                    <video autoPlay ref={remoteVideo}></video>
                </div>
                <div className={`local ${!inCall ? 'bigger' : ''}`}>
                    <video muted autoPlay ref={localVideo}></video>
                </div>
                <div className="btts">
                    <div>
                        <button
                            hidden={!inCall}
                            className={`controlBtt ${mute ? 'redbg' : 'greenbg'}`}
                            onClick={() => {
                                let a = (localVideo.current?.srcObject as any)?.getAudioTracks()[0].enabled;
                                if (a !== undefined) {
                                    setMute(a);
                                    (localVideo.current?.srcObject as any).getAudioTracks()[0].enabled = !a;
                                }
                            }}
                        >
                            <i className={`bi bi-mic-${mute ? 'mute-fill' : 'fill'}`} />
                        </button>
                        <button
                            hidden={!inCall}
                            className={`controlBtt ${!video ? 'redbg' : 'greenbg'}`}
                            onClick={() => {
                                let a = ((localVideo?.current.srcObject as any)?.getVideoTracks() ?? [])[0]?.enabled;
                                if (a !== undefined) {
                                    setVideo(!a);
                                    (localVideo.current.srcObject as any).getVideoTracks()[0].enabled = !a;
                                }
                            }}
                        >
                            <i className={`bi bi-camera-video-${video ? 'fill' : 'off-fill'}`} />
                        </button>
                        <button hidden={!inCall} className={`controlBtt greenbg`} onClick={() => setExpanded(!expanded)}>
                            <i className={`bi bi-${!expanded ? 'arrows-fullscreen' : 'fullscreen-exit'}`} />
                        </button>
                        <button
                            hidden={!inWait}
                            className="controlBtt redbg"
                            onClick={() => {
                                endCall();
                                closeNavigationDevices();
                            }}
                        >
                            <i className="bi bi-telephone-x-fill" />
                        </button>
                    </div>
                </div>
                <div className="provider">
                    {(() => {
                        if (provider?.photo) return <img src={`${Config.default.PHOTO_URL}${provider.photo}`} alt="" />;
                        else return <></>;
                    })()}
                    <div>{provider?.fantasyName ?? ''}</div>
                    <div>{provider?.erpCode ?? ''}</div>
                </div>
            </div>
        </>
    );
}

function Rating(props: { onHide: () => void; idUUID: string; onSubmited?: () => void; visible?: boolean }) {
    let { visible = false, onSubmited = () => {}, onHide, idUUID } = props;

    let messages = useRef(null as any as Messages);

    let [submited, setSubmited] = useState(false);
    let [value, setValue] = useState(0);
    let [text, setText] = useState('');

    useEffect(() => {
        if (!props.visible) {
            setSubmited(false);
            setValue(0);
            setText('');
        }
    }, [props.visible]);

    async function submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!idUUID) {
            onHide();
            return;
        }

        let r = await S.contract.ratting({
            contractIdUUID: idUUID,
            observation: text,
            rate: value,
            rattingCategoryIdUUID: null,
        });

        if (g_displayMessageError(messages.current, r)) return;

        setSubmited(true);
        onSubmited();
    }

    return (
        <Dialog visible={visible} onHide={onHide}>
            <form onSubmit={e => submit(e)}>
                <div className="rate-dialog">
                    <Messages ref={messages} />
                    <h5>Como foi a sua consulta?</h5>
                    <h2>Sua avaliação é importante para nós.</h2>
                    <Rate onSelect={e => setValue(e)} selector />
                    <InputTextarea value={text} onChange={e => setText(e.currentTarget.value)}></InputTextarea>
                    <Button label="Enviar Avaliação" disabled={submited} type="submit" />
                </div>
            </form>
        </Dialog>
    );
}

function Rate(props: { value?: number; max?: number; empty?: string; fill?: string; selector?: boolean; onSelect?: (e: number) => void }) {
    let { value: valueProp, max = 5, empty = 'bi-star', fill = 'bi-star-fill', selector = false, onSelect = () => {} } = props;

    let [selected, setSelected] = useState(false);
    let [value, setValue] = useState(valueProp ?? 0);
    let [valueBak, setValueBak] = useState(0);

    useEffect(() => {
        setValue(valueProp ?? 0);
    }, [valueProp]);

    function leave() {
        if (!selected) setValue(valueBak);
        setSelected(false);
    }

    function over(i: number) {
        if (selector) setValue(i);
    }

    function select(i: number) {
        if (selector) {
            onSelect(i);
            setSelected(true);
            setValueBak(i);
        }
    }

    let fillStars = ''
        .padStart(value, '0')
        .split('')
        .map((_, i) => <i className={`c p bi ${fill} ${selector ? 'selector' : ''}`} onMouseOver={() => over(1 + i)} onClick={() => select(1 + i)} />);

    let emptyStars = ''
        .padStart(max - value, '0')
        .split('')
        .map((_, i) => <i className={`c bi ${empty} ${selector ? 'selector' : ''}`} onMouseOver={() => over(1 + value + i)} onClick={() => select(1 + value + i)} />);

    return (
        <div onMouseLeave={leave}>
            {fillStars}
            {emptyStars}
        </div>
    );
}
