import type { GenericListResponse, StringDate, UUID } from '..';

export enum ShiftDumpRequestFileType {
	CSV = 0,
	XLS = 1,
	SURVER_RECORDS_OBJECT = 2,
	SURVER_RECORDS_JSON = 3
}

export interface ShiftDumpRequest {
	consignatorIdUUID: UUID;
	dateStart: StringDate;
	dateEnd: StringDate;
	clockingLocationId?: number;
	fileType: ShiftDumpRequestFileType;
}

export interface Shift {
	consigneeIdUUID: UUID;
	dateShift: StringDate;
	name: string;
	erpCode: string;
	startClockingLocationId: number;
	startClockingLocation: string;
	endClockingLocationId: number;
	endClockingLocation: string;
	jobId: number;
	job: string;
	dateStart: StringDate;
	dateEnd: StringDate;
	workingHours: number;
	period: string;
	startClockingUUID: UUID;
	endClockingUUID: UUID;
	//TODO enum
	shiftStatus: number;
	shiftStatusNotes: string;
}

export type ShiftResponse = GenericListResponse & {
	shifts: Shift[];
	shiftsJson: 'string';
	url: 'string';
};
