import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown as PDropdown } from 'primereact/dropdown';
import { Editor as PEditor } from 'primereact/editor';
import { InputMask as PInputMask } from 'primereact/inputmask';
import { InputNumber as PInputNumber } from 'primereact/inputnumber';
import { InputSwitch as PInputSwitch } from 'primereact/inputswitch';
import { InputText as PInputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import React, { MouseEvent, ReactElement, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { GenericListResponse, pairT } from '../api-ts-bindings/Generic';
import { GenericListRequest } from '../api-ts-bindings/Requests';
import type { BasicListResponseData, LoggedUserData, StateItems } from '../types';
import { g_displayMessageError, g_wraper, lb, lm, ls } from './GenericFunctions';
import { useDataObject } from './generic/Form';

export * from './generic/Form';

/**
 * @deprecated change to api-bindings
 * @param key
 * @param value
 * @returns
 */
export function pair<T>(key: string, value: T): pairT<T> {
    return { key, value };
}

export function pairF<T>(key: string, value: T, filter: boolean = false): pairT<T>[] {
    if (filter) return [{ key, value }];
    return [];
}

export function StringEmpty(s: string | null | undefined): boolean {
    return s === null || s === undefined || s === '';
}

export function checkLoginACL({ checkPageAcl = true, fakename }: { checkPageAcl?: boolean; fakename?: string }): {
    error?: ReactElement;
    profile?: aclItem;
    loggeduser?: LoggedUserData;
} {
    let loggedUserN: LoggedUserData | null = null;
    try {
        loggedUserN = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
    } catch (_) {}
    if (loggedUserN === null) return { error: <Redirect to="/login" /> };
    if (!checkPageAcl) return { profile: { r: 1, u: 1, d: 1, c: 1, rb: true, ub: true, db: true, cb: true }, loggeduser: loggedUserN };
    let url = fakename ?? window.location.hash.substr(2);
    url = url.indexOf('?') === -1 ? url : url.split('?')[0];
    let profile = (loggedUserN.profileACLs as aclItem[]).filter(v => v.type === 4 && v.r === 1).filter(v => v.tag === url);
    return { profile: profile[0], loggeduser: loggedUserN, error: profile.length === 0 ? <span>{ls('noPermission', 'generic')}</span> : undefined };
}

export type getDataContext<K extends string = string, O extends string = string> = React.Component<
    any,
    {
        filter: pairT<string, K>[];
        orderBy: pairT<'asc' | 'desc', O>[];
        pageSize: number;
        pageNumber: number;
        [key: string]: any;
    }
> & {
    messages: null | Messages;
};
export async function g_getData<T, K extends string, O extends string>(
    context: getDataContext<K, O>,
    apiRequest: (data: GenericListRequest<K, O>) => Promise<T> | T = async () => null as any,
    processData: string | ((response: T, callback: BasicListResponseData) => void) | { p: string; c: () => void } = () => {},
    overideData = {},
    messages: Messages | null = null
): Promise<void> {
    let data: GenericListRequest<K, O> = {
        filters: context.state.filter ?? [],
        orderBy: context.state.orderBy ?? [],
        pageSize: context.state.pageSize ?? 0,
        pageNumber: context.state.pageNumber ?? 0,
        ...overideData,
    };
    let response = (await apiRequest(data)) as any;
    if (g_displayMessageError(messages ?? context.messages ?? null, response)) return;
    let callback: BasicListResponseData = {
        pageTotal: response.pageTotal,
        recordsTotal: response.recordsTotal,
        pageSize: response.pageSize,
    };
    if (typeof processData === 'string')
        context.setState({
            data: response[processData],
            ...callback,
        });
    else if (typeof processData === 'function') processData(response, callback);
    else if (typeof processData === 'object')
        context.setState(
            {
                data: response[processData.p],
                ...callback,
            },
            processData.c
        );
}

export const returnGenericEmpty: GenericListResponse = { code: 0, pageNumber: 1, recordsTotal: 0, pageSize: 0, pageTotal: 0, messages: [], description: '' };

/**
 * @function checkAcl checks if the stored data contains the acl required
 */
export function checkAcl(acl: string, type: number): aclItem[] | undefined {
    try {
        let loggedUserN: LoggedUserData = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
        if (loggedUserN === null) return undefined;
        let profile = loggedUserN.profileACLs.filter(v => v.type === type).filter(v => v.tag === acl);
        return profile.length !== 0 ? profile : undefined;
    } catch (_) {
        return undefined;
    }
}

export function noDataMessage(messages?: Messages | null) {
    if (!messages) return;
    messages.show({ severity: 'warn', summary: lm('dataNotRecorded') });
}

export type aclItem = { r: 0 | 1; u: 0 | 1; d: 0 | 1; c: 0 | 1; rb: boolean; ub: boolean; cb: boolean; db: boolean; type?: number; tag?: string };

export function StateIcon(props: { custom: StateItems; state: number; style?: React.CSSProperties; small?: boolean; onClick?: (e: MouseEvent<HTMLSpanElement>) => void }) {
    let [tooltip, setTooltip] = useState(false),
        { custom, state, onClick } = props,
        style = { fontSize: props.small ? '1.4em' : '1.87em', ...(props.style ?? {}) };

    if (custom && custom[state]) {
        let icon = custom[state];
        style = { ...style, color: icon.color ?? 'black', ...(onClick ? { cursor: 'pointer' } : {}) };
        return (
            <span onMouseOver={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)}>
                <div
                    style={{
                        display: tooltip && icon.tooltip ? 'block' : '',
                        marginTop: '0.5em',
                        backgroundColor: '#222d',
                        color: '#fff',
                        maxWidth: '5em',
                    }}
                    className="p-tooltip"
                >
                    {icon.tooltip}
                </div>
                <span onClick={onClick} className={'pi ' + icon.class} style={style}>
                    {icon.text ?? ''}
                </span>
            </span>
        );
    }
    return <span onClick={onClick} className="pi pi-question" style={{ color: 'grey', fontSize: '1.83em' }} />;
}
export function Button(props: {
    label?: string;
    icon?: string;
    disabled?: boolean;
    hidden?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    wraperClass?: string;
    wraperStyle?: React.CSSProperties;
    bttClass?: string;
    tooltip?: string;
    style?: React.CSSProperties;
    type?: string;
}) {
    let { label, hidden = false, type = 'submit', tooltip, style, icon, bttClass, onClick = null, disabled = false, wraperClass = 'p-col-1', wraperStyle } = props;
    label = lb(label) === 'LabelInfenranceFailed' ? label : lb(label);
    if (hidden) return null;
    let body = (
        <PButton
            tooltip={tooltip}
            type={type}
            style={style}
            icon={icon}
            className={bttClass}
            onClick={!onClick || disabled ? undefined : onClick}
            disabled={disabled}
            label={label}
        />
    );
    if (!wraperClass) return body;
    return (
        <div style={wraperStyle} className={wraperClass}>
            {body}
        </div>
    );
}
export function InputMask(props: {
    id: string;
    label?: string;
    labelClass?: string;
    hidden?: boolean;
    disabled?: boolean;
    fieldClass?: string;
    placeholder?: string;
    tooltip?: string;
    value?: any;
    onChange: (e: any) => void;
    required?: boolean;
    mask?: string;
    style?: React.CSSProperties;
    gridless?: boolean;
    autoClear?: boolean;
    unmask?: boolean;
}) {
    let {
        unmask = true,
        autoClear = false,
        label,
        id,
        gridless = false,
        placeholder,
        labelClass = 'p-col-3',
        fieldClass = 'p-col-7',
        hidden = false,
        disabled = false,
        tooltip,
        value,
        onChange,
        required = false,
        mask,
        style,
    } = props;
    label = label ?? id;
    placeholder = placeholder ?? id;
    mask = mask ?? id;
    label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);
    mask = ls(mask, 'mask') === 'LabelInfenranceFailed' ? mask : ls(mask, 'mask');
    placeholder = ls(placeholder, 'placeholder') === 'LabelInfenranceFailed' ? placeholder : ls(placeholder, 'placeholder');
    //title={ls('titleFeild', 'generic')}
    return g_wraper(
        id,
        label,
        <div className={fieldClass}>
            <PInputMask
                placeholder={placeholder}
                tooltip={tooltip}
                value={value}
                onChange={(e: any) => (disabled ? () => {} : onChange(e))}
                id={id}
                disabled={disabled}
                required={required}
                mask={mask}
                style={style}
                autoClear={autoClear}
                unmask={unmask}
            />
        </div>,
        hidden,
        labelClass,
        gridless
    );
}
export function Editor(props: {
    id: string;
    disabled?: boolean;
    label?: string;
    style?: React.CSSProperties;
    fieldClass?: string;
    value?: string;
    labelClass?: string;
    gridless?: boolean;
    hidden?: boolean;
    onChange?: (e: { htmlValue: string | null; textValue: string; delta: any; source: string }) => void;
}) {
    let { disabled, id, label, style, fieldClass, value, labelClass, gridless, onChange, hidden } = props;
    if (hidden) return null;
    label = label ?? id;
    label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);
    if (disabled)
        return g_wraper(
            id,
            label,
            <div style={{ border: '1px solid grey', marginBottom: '3px', ...style }} className={fieldClass} dangerouslySetInnerHTML={{ __html: value ?? '' }} />,
            false,
            labelClass,
            gridless
        );
    return g_wraper(
        id,
        label,
        <div className={fieldClass}>
            <PEditor value={value} id={id} style={style ?? { minHeight: '7em' }} onTextChange={e => (onChange ?? (() => {}))(e)} />
        </div>,
        false,
        labelClass,
        gridless
    );
}
export function Input(props: {
    id: string;
    value?: string;
    label?: string;
    disabled?: boolean;
    hidden?: boolean;
    required?: boolean;
    inputClass?: string;
    labelClass?: string;
    type?: string;
    tooltip?: string;
    gridless?: boolean;
    onChange?: (e: any) => void;
}) {
    let { hidden, id, required, label, labelClass = 'p-col-3', inputClass = 'p-col-7', disabled, tooltip, type, value, onChange, gridless } = props;
    label = label ?? id;
    label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);
    return g_wraper(
        id,
        label,
        <div className={inputClass}>
            <PInputText
                title={disabled !== undefined && !disabled ? 'Por favor preencha este campo.' : ''}
                tooltip={tooltip}
                type={type}
                value={value}
                onChange={!disabled && onChange ? onChange : () => {}}
                id={id}
                disabled={disabled}
                required={required}
            />
        </div>,
        hidden,
        labelClass,
        gridless
    );
}
export function DateInput({
    selectionMode,
    inputClass,
    labelClass,
    label,
    id,
    hidden,
    gridless,
    showTime,
    timeOnly,
    minDate,
    maxDate,
    value,
    disabled,
    onChange,
}: {
    selectionMode?: any;
    inputClass?: string;
    label?: string;
    id: string;
    labelClass?: string;
    hidden?: boolean;
    gridless?: boolean;
    showTime?: boolean;
    timeOnly?: boolean;
    minDate?: Date;
    maxDate?: Date;
    value?: Date;
    disabled?: boolean;
    onChange?: (e: {
        originalEvent: Event;
        value: Date | Date[];
        target: {
            name: string;
            id: string;
            value: Date | Date[];
        };
    }) => void;
}) {
    label = label ?? id;
    label = ls(label, '_', label);
    return g_wraper(
        id,
        label,
        <div className={inputClass}>
            <Calendar
                selectionMode={selectionMode}
                hourFormat={ls('hourFomart', 'default')}
                showTime={showTime}
                timeOnly={timeOnly}
                minDate={minDate}
                maxDate={maxDate}
                yearRange={`1900:${new Date().getFullYear() + 10}`}
                monthNavigator={true}
                yearNavigator={true}
                id={id}
                dateFormat="dd-mm-yy"
                value={value}
                onChange={event => (onChange ?? (() => {}))(event)}
                disabled={disabled}
            />
        </div>,
        hidden,
        labelClass,
        gridless
    );
}
export function InputNumber(props: {
    id: string;
    value?: string;
    label?: string;
    disabled?: boolean;
    hidden?: boolean;
    required?: boolean;
    inputClass?: string;
    labelClass?: string;
    type?: string;
    tooltip?: string;
    gridless?: boolean;
    suffix?: string;
    suffixOutside?: boolean;
    prefix?: string;
    currency?: string;
    minFractionDigits?: number;
    maxFractionDigits?: number;
    mode?: 'decimal' | 'currency';
    locale?: string;
    onChange?: (e: {
        originalEvent: Event;
        value: any;
        target: {
            name: string;
            id: string;
            value: any;
        };
    }) => void;
}) {
    let {
        hidden,
        id,
        required,
        label,
        labelClass = 'p-col-3',
        inputClass = 'p-col-7',
        disabled,
        tooltip,
        type,
        value,
        onChange,
        gridless,
        suffix,
        suffixOutside,
        prefix,
        mode,
        currency,
        minFractionDigits,
        maxFractionDigits,
        locale,
    } = props;
    label = label ?? id;
    label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);
    let valueN: number | undefined = Number(value);
    if (isNaN(valueN)) valueN = undefined;
    return g_wraper(
        id,
        label,
        <div className={(suffixOutside ? 'p-grid ' : '') + inputClass}>
            {(() => {
                let a = (
                    <PInputNumber
                        tooltip={tooltip}
                        type={type}
                        value={valueN}
                        onChange={!disabled && onChange ? onChange : () => {}}
                        id={id}
                        disabled={disabled}
                        required={required}
                        suffix={suffixOutside ? '' : suffix}
                        prefix={prefix}
                        mode={mode}
                        currency={currency}
                        minFractionDigits={minFractionDigits}
                        maxFractionDigits={maxFractionDigits}
                        locale={locale}
                    />
                );
                if (suffixOutside)
                    return (
                        <>
                            <div className="p-col">{a}</div>
                            <div className="p-col-1">{suffix ?? ''}</div>
                        </>
                    );
                return a;
            })()}
        </div>,
        hidden,
        labelClass,
        gridless
    );
}

interface DialogPromtProps {
    hidden?: boolean;
    yesbtt?: { label?: string; class?: string; icon?: string };
    nobtt?: { label?: string; class?: string; icon?: string };
    onConfirm?: (e: React.MouseEvent<HTMLButtonElement>, text?: string) => void;
    onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    wraperClass?: string;
    label?: string;
    icon?: string;
    bttClass?: string;
    disabled?: boolean;
    id?: string;
    text?: string;
    show?: boolean;
    hiddenBtt?: boolean;
    closeOut?: () => void;
    children?: React.ReactChildren;
    style?: React.CSSProperties;
    contentStyle?: React.CSSProperties;

    inputArea?: boolean;
    inputAreaLabel?: string;
}
export const DialogPromt: React.FC<DialogPromtProps> = ({
    hidden,
    yesbtt,
    onConfirm,
    nobtt,
    onDeny,
    wraperClass,
    label,
    icon,
    bttClass,
    disabled,
    text: textIn,
    id,
    show,
    hiddenBtt,
    closeOut = () => {},
    children,
    style,
    contentStyle,

    inputArea,
    inputAreaLabel,
}) => {
    let { obj, setObj, setObjState } = useDataObject<{ text: string }>({ text: '' });
    let [v, setVisible] = useState(false);
    let visible = show ?? v;
    if (hidden) return null;
    //TODO: deal with this `null as any`
    let text = ls(textIn, 'dialog', null as any) ?? textIn;
    let footer = (
        <div>
            <Button
                label={yesbtt?.label ?? 'yes'}
                bttClass={yesbtt?.class ?? 'p-button-success'}
                icon={yesbtt?.icon}
                wraperClass=""
                onClick={e => {
                    e.preventDefault();
                    setVisible(false);
                    closeOut();
                    if (onConfirm) onConfirm(e, obj.text);
                    setObjState({ text: '' });
                }}
            />
            <Button
                label={nobtt?.label ?? 'no'}
                wraperClass=""
                bttClass={nobtt?.class ?? 'p-button-danger'}
                icon={nobtt?.icon}
                onClick={e => {
                    e.preventDefault();
                    setVisible(false);
                    closeOut();
                    if (onDeny) onDeny(e);
                    setObjState({ text: '' });
                }}
            />
        </div>
    );

    let input = null;
    if (inputArea) {
        input = (
            <>
                <div style={{ marginTop: '10px' }}></div>
                <TextArea style={{ minWidth: '400px', height: '80px' }} id="text" onChange={setObj} label={inputAreaLabel} labelClass="p-col-12" />
            </>
        );
    }

    return (
        <div className={wraperClass}>
            <Button
                label={label}
                icon={icon}
                wraperClass=""
                bttClass={bttClass}
                hidden={hiddenBtt}
                onClick={e => {
                    e.preventDefault();
                    if (disabled) return;
                    setVisible(true);
                    setObjState({ text: '' });
                }}
                type="button"
                disabled={disabled}
            />
            <Dialog
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    closeOut();
                    setObjState({ text: '' });
                }}
                footer={footer}
                style={{ width: '50vw', ...style }}
                contentStyle={contentStyle}
                id={id}
                header={lb(label) === 'LabelInfenranceFailed' ? label : lb(label)}
            >
                {text}
                {children}
                {input}
            </Dialog>
        </div>
    );
};

interface WraperProps {
    hidden?: boolean;
    label?: string;
    children: ReactElement[] | ReactElement | Element;
    id?: string;
    class?: string;
    gridless?: boolean;
    reversed?: boolean;
}
export const Wraper: React.FC<WraperProps> = ({ hidden, label, children, id, class: className, gridless, reversed }: WraperProps) => {
    if (hidden) return <></>;
    if ((StringEmpty(label) && StringEmpty(id)) || label === '') return <>{children}</>;
    label = label ?? id;
    label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);
    let labelbody = (
        <div key={`${id}divlable`} className={className}>
            <label htmlFor={id}>{label}</label>
        </div>
    );
    if (!id)
        labelbody = (
            <div key={`${id}divlabel`} className={className}>
                {label}
            </div>
        );
    if (gridless && reversed) return <>{[children, labelbody]}</>;
    if (gridless) return <>{[labelbody, children]}</>;
    if (reversed)
        return (
            <div key={`${id}div`} className="p-grid">
                {labelbody} {children}
            </div>
        );
    return (
        <div key={`${id}div${label}`} className="p-grid">
            {' '}
            {labelbody} {children}{' '}
        </div>
    );
};
interface TextAreaProps {
    id: string;
    label?: string;
    labelClass?: string;
    inputClass?: string;
    hidden?: boolean;
    gridless?: boolean;
    style?: React.CSSProperties;
    tooltip?: string;
    value?: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}
export const TextArea: React.FC<TextAreaProps> = ({
    id,
    label,
    labelClass,
    hidden,
    gridless,
    inputClass,
    style,
    tooltip,
    value,
    disabled,
    required,
    onChange = () => {},
}: TextAreaProps) => {
    label = label ?? id;
    label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);
    return g_wraper(
        id,
        label,
        <div className={inputClass}>
            <InputTextarea
                style={style}
                tooltip={tooltip}
                value={value}
                onChange={e => (disabled ? () => {} : onChange(e))}
                title={'Por favor preencha este campo'}
                id={id}
                disabled={disabled}
                required={required}
            />
        </div>,
        hidden,
        labelClass,
        gridless
    );
};

interface DropdownProps<T> {
    id: string;
    options: { value: string; label: string }[];
    label?: string;
    lClass?: string;
    hidden?: boolean;
    itemTemplate?: (option: T) => React.ReactNode;
    filter?: boolean;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    onChange: (e: { originalEvent: Event; value: any; target: { name: string; id: string; value: any } }) => void;
    required?: boolean;
    showClear?: boolean;
    fClass?: string;
    gridless?: boolean;
}
export const Dropdown = function <T>({
    label,
    id,
    lClass,
    hidden,
    itemTemplate,
    filter,
    options,
    placeholder,
    value,
    disabled,
    onChange,
    required,
    showClear,
    fClass,
    gridless,
}: DropdownProps<T>) {
    return (
        <Wraper label={label} id={id} class={lClass} hidden={hidden} gridless={gridless}>
            <div className={fClass}>
                <PDropdown
                    key={`${id}drop`}
                    itemTemplate={itemTemplate}
                    filter={filter}
                    filterBy="label, value"
                    options={options}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => (disabled ? () => {} : onChange(e))}
                    disabled={disabled}
                    id={id}
                    required={required}
                    showClear={showClear}
                />
            </div>
        </Wraper>
    );
};

interface SwitchProps {
    id: string;
    checked?: boolean;
    disabled?: boolean;
    tooltip?: string;

    fClass?: string;
    label?: string;
    lClass?: string;
    hidden?: boolean;
    gridless?: boolean;

    onChange?: (e: { originalEvent: Event; value: boolean; target: { name: string; id: string; value: boolean } }) => void;
}
export const InputSwitch = function ({ id, checked, disabled, tooltip, onChange, fClass, label, lClass, hidden, gridless }: SwitchProps) {
    return (
        <Wraper label={label} id={id} class={lClass} hidden={hidden} gridless={gridless}>
            <div className={fClass}>
                <PInputSwitch id={id} checked={checked} disabled={disabled} tooltip={tooltip} onChange={onChange} />
            </div>
        </Wraper>
    );
};
