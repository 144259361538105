import type { StringDate, UUID } from '../Generic';

export enum ShoppingCartStatus {
	active = 1,
	deleted = 2,
	purchased = 3,
	invalid = 4
}

export interface ShoppingCart {
	idUUID: UUID;
	status: ShoppingCartStatus;
	requesterIdUUID: UUID;
	contractTemplateIdUUID: UUID;
	quantity: number;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	productName: StringDate;
	productValue: number;
	productDiscountValue: number;
	providerIdUUID: UUID;
	schedulingIdUUID: UUID;
	deliveryAddress: string;
	productDescription: string;
	productTypeDescription: string;
	productSubTypeDescription: string;
	dateSchedule: StringDate;
	productSubType: number;
	providerName: string;
	providerPhoto: string;
	productValueForCampaign: number;
}
