import React, { useState } from 'react';
import { useCallback } from 'react';
import { MFile } from '../../../types';
import './GenericPhotoUpload.css';

interface GPhotoUploadProps {
    disabled?: boolean;
    style?: React.CSSProperties;
    alt?: string;
    photo?: string;
    prefixPath?: string;
    documentTypeId: string;
    id: string;
    handlePhotoUpload: (e: { file: MFile; documentTypeId: string }) => void;
}

export const GPhotoUpload: React.FC<GPhotoUploadProps> = ({ disabled, style, alt, photo, prefixPath, documentTypeId, id, handlePhotoUpload }) => {
    let [imagePreviewUrl, setImagePreviewUrl] = useState<string | ArrayBuffer | null | undefined>(null);

    let handleImageChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            let reader = new FileReader();
            let readerArray = new FileReader();

            if (!e.target.files || !e.target.files[0]) return;

            let file: MFile = e.target.files[0];
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            readerArray.onloadend = () => {
                file.arrayByte = readerArray.result;
                handlePhotoUpload({
                    file: file,
                    documentTypeId: documentTypeId,
                });
            };
            readerArray.readAsArrayBuffer(file);
        },
        [documentTypeId, handlePhotoUpload]
    );

    console.log(photo, imagePreviewUrl, alt, photo ? prefixPath + photo : imagePreviewUrl !== '' ? (imagePreviewUrl as string | undefined) ?? '' : alt);

    let $imagePreview = (
        <img
            style={{ cursor: !disabled ? 'pointer' : '', ...style }}
            src={photo ? prefixPath + photo : imagePreviewUrl ? (imagePreviewUrl as string | undefined) ?? '' : alt}
            alt=""
        />
    );
    let input = disabled ? null : <input style={{ display: 'none' }} id={documentTypeId + id ?? ''} onChange={e => handleImageChange(e)} disabled={disabled} type="file" />;
    return (
        <div className="p-grid p-justify-center">
            <div className="p-col">
                <div style={{ height: parseInt((style?.height as any) ?? '') + 2 + 'px', width: parseInt((style?.width as any) ?? '') + 2 + 'px' }} className="imgPreview3">
                    <label htmlFor={documentTypeId + id ?? ''}>{$imagePreview}</label>
                    {input}
                </div>
            </div>
        </div>
    );
};
GPhotoUpload.defaultProps = {
    alt: window.location.origin + '/assets/images/person.png',
};

//TODO: remove default
export default GPhotoUpload;
