import type { StringDate, UUID } from '../Generic';

export interface ContractComplementaryFieldsCreate {
	contractTemplateFieldType?: number;
	fieldName: string;
	fieldValue: string;
	title?: string;
}
export interface ContractComplementaryFields
	extends ContractComplementaryFieldsCreate {
	contractTemplateFieldIdUUID: string;
	contractTemplateFieldType: number;
	title: string;
}
export interface ContractInstallments {
	capital: number;
	contractIDUUID: UUID;
	dateCreation: StringDate;
	dateInstallment: StringDate;
	datePayed: StringDate;
	dateUpdate: StringDate;
	idUUID: UUID;
	installmentNumber: number;
	installmentStatus: number;
	installmentValue: number;
	interests: number;
}
export interface Rattings {
	dateCreation: string;
	dateUpdate: string;
	rate: number;
	rattingCategoryDescription: string;
	rattingCategoryIdUUID: string;
}
export interface ContractModel {
	allowToredeal: number;
	callbackUrl: string;
	consignatorIdUUID: string;
	consignatorName: string;
	consignatorPhoto: string;
	consigneeFullname: string;
	consigneeIdUUID: string;
	consigneePhoto: string;
	consigneeVatNumber: string;
	contractComplementaryFields: ContractComplementaryFields[];
	contractExternalReferenceId: string;
	contractInterestsPercentage: number;
	contractSignatureMode: number;
	contractStatus: number;
	contractStatusDescription: string;
	contractStatusMessage: string;
	contractTemplateBase: string;
	contractTemplateIdUUID: string;
	contractTemplateTitle: string;
	contractTemplateType: number;
	contractTemplateTypeDescription: string;
	contractTotalCapitalValue: number;
	contractTotalInterestsValue: number;
	contractTotalValue: number;
	contractType: number;
	dateContractEnd: string;
	dateContractStart: string;
	dateCreation: string;
	dateUpdate: string;
	documentDigitalized: string;
	fillerField1: string;
	idUUID: string;
	info: string;
	installmentTotal: number;
	installments: ContractInstallments[];
	obs: string;
	parentContractIdUUID: string;
	productIdUUID: string;
	productInterfaceName: string;
	productName: string;
	productSubTypeDescription: string;
	productPriceValue: number;
	providerIdUUID: string;
	providerName: string;
	providerPhoto: string;
	rateObservation: string;
	ratting: number;
	rattings: Rattings[];
	schedulingIdUUID: string;
	subscriptionCycleIdUUID: string;
	workflowType: WorkflowType;
	workingEntityIdUUID: string;
	workingEntityName: string;
	workingEntityPhoto: string;
	searchAreaServiceType: number;
	paymentIdUUID: UUID;
}

export interface ContractModelWithSchedule extends ContractModel {
	dateScheduling: StringDate;
}

export enum WorkflowType {
	emit = 0,
	emit_us_financier = 1,
	emit_us_provider = 4,
	emit_company = 7,
	request_vehicle = 5,
	video_call = 6,
	freight = 8
}

export type ContractCreatePaymentData =
	| {
			paymentType: number;
			token?: string;
			installments?: number;
			vatNumber?: string;
			consignatorIdUUID?: UUID;
			cardIdUUID?: UUID;
			aux?: string;
	  }
	| {
			paymentType: 3;
			token?: string;
			installments?: number;
			vatNumber?: string;
			consignatorIdUUID?: UUID;
			cardIdUUID: UUID;
			aux?: string;
	  };

export interface ContractCreateModel {
	allowToredeal?: number;
	callbackUrl?: string;
	consignatorName?: string;
	consignatorPhoto?: string;
	consigneeFullname?: string;
	consigneePhoto?: string;
	consigneeVatNumber?: string;
	contractComplementaryFields?: ContractComplementaryFieldsCreate[];
	contractExternalReferenceId?: string;
	contractInterestsPercentage?: number;
	contractSignatureMode?: number;
	contractStatusDescription?: string;
	contractStatusMessage?: string;
	contractTemplateBase?: string;
	contractTemplateTitle?: string;
	contractTemplateType?: number;
	contractTemplateTypeDescription?: string;
	contractTotalCapitalValue?: number;
	contractTotalInterestsValue?: number;
	contractTotalValue?: number;
	contractType?: number;
	dateContractEnd?: string;
	dateContractStart?: string;
	dateCreation?: string;
	dateUpdate?: string;
	documentDigitalized?: string;
	fillerField1?: string;
	idUUID?: string;
	info?: string;
	installmentTotal?: number;
	installments?: ContractInstallments[];
	obs?: string;
	parentContractIdUUID?: string;
	productIdUUID?: string;
	productInterfaceName?: string;
	productName?: string;
	productPriceValue?: number;
	providerIdUUID?: string;
	providerName?: string;
	providerPhoto?: string;
	rateObservation?: string;
	ratting?: number;
	rattings?: Rattings[];
	schedulingIdUUID?: string;
	subscriptionCycleIdUUID?: string;
	workflowType?: number;
	workingEntityIdUUID?: string;
	workingEntityName?: string;
	workingEntityPhoto?: string;
	searchAreaServiceType?: number;
	contractTemplateIdUUID: string;
	consigneeIdUUID: string;
	consignatorIdUUID: string;

	// The only status that can be used in the creation step
	contractStatus?: 899;
}

export interface ContractTemplateFieldsModel {
	contractFormMode: number;
	contractFormModeValue: string;
	contractTemplateFieldStatus: ContractTemplateFieldStatus;
	contractTemplateFormIdUUID: string;
	dateCreation: string;
	dateUpdate: string;
	fieldHeight: string;
	fieldListValues: string;
	fieldMaxSize: number;
	fieldName: string;
	fieldPosition: number;
	fieldType: number;
	fieldValue: string;
	fieldWidth: string;
	help: string;
	idUUID: string;
	mandatory: number;
	title: string;
}

export enum ContractTemplateFieldStatus {
	active = 1,
	canceled = 2,
	deleted = 3
}

export interface ContractTemplateFormsModel {
	contractFields: ContractTemplateFieldsModel[];
	contractFormMode: number;
	contractFormModeValue: string;
	contractTemplateFormStatus: ContractTemplateFormStatus;
	contractTemplateFormType: number;
	contractTemplateIdUUID: string;
	dateCreation: string;
	dateUpdate: string;
	help: string;
	idUUID: string;
	page: number;
	title: string;
}

export enum ContractTemplateFormStatus {
	active = 1,
	inactive = 0
}

export type ContractTemplateFormsListFiltersList =
	| 'contracttemplateiduuid'
	| 'tag';

export interface ContractTemplateModel {
	idUUID: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	title: string;
	description: string;
	tag: string;
	templateVersion: string;
	consignatorIdUUID: UUID;
	consignatorName: string;
	productInterfaceName: string;
	productIdUUID: UUID;
	productSubType: number;
	productName: string;
	contractTemplateType: number;
	contractTemplateTypeDescription: string;
	contractDocumentBase: string;
	contractTemplateStatus: number;
	contractSignatureMode: number;
	contractSignatureModeDescription: string;
	consignatorRevenueStartMode: number;
	consignatorRevenueCloseMode: number;
	consignatorRevenueInstallmentMode: number;
	consignatorMarginStartMode: number;
	consignatorMarginCloseMode: number;
	consignatorMarginInstallmentMode: number;
	numoRevenueStartMode: number;
	numoRevenueCloseMode: number;
	numoRevenueInstallmentMode: number;
	numoMarginStartMode: number;
	numoMarginCloseMode: number;
	numoMarginInstallmentMode: number;
	priceValue: number;
	minValue: number;
	maxValue: number;
	stepValue: number;
	periodValue: number;
	minPeriod: number;
	maxPeriod: number;
	stepPeriod: number;
	workflowType: number;
	liquidationEntityType: number;
	billingToMode: number;
	terms: string;
	lenderTax: number;
	monthlyTax: number;
	scriptInstallmentSimulation: string;
	allowToredeal: number;
	marginControl: number;
	loyaltyAngariationType: number;
	loyaltyAngariationValue: number;
	allowsSyncCall: number;
	contextType: number;
	contextTypeDescription: string;
	contractForms: ContractTemplateFormsModel[];
	iofadditional: number;
	iofannual: number;
}

export interface ContractGroupByContractTemplate {
	count: number;
	idUUID: string;
	name: string;
	value: number;
}

export interface ContractGroupByWorkingEntity
	extends ContractGroupByContractTemplate {
	workingEntity: string;
}

export enum ContractStatus {
	scheduled = 12
}
