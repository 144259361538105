import type { ProductSubscription } from '..';
import type { AddressModel } from '..';

interface ConsigneeBankAccountsModel {
	accountDigit: string;
	accountNumber: string;
	bankCode: number;
	branchDigit: string;
	branchNumber: string;
}

export interface ConsigneeParametersModel {
	logContextType?: number;
	name: string;
	parameterValue: string;
	referenceIdUUID?: string;
}

export enum ConsigneeBiometricStatus {
	not_set = 0,
	captured = 1,
	system_accepted = 2,
	bo_validated = 3,
	denyed = 4
}

export enum ConsigneeDocumentType {
	photo = 1
}

export interface ConsigneeModel {
	biometricStatus: ConsigneeBiometricStatus;
	userStatus: number;
	userType: number;
	address: AddressModel[];
	appVersion: string;
	bankAccounts: ConsigneeBankAccountsModel[];
	civilStatus: number;
	consignatorFantasyName: string;
	consignatorIdUUID: string;
	consigneeStatus: number;
	consigneeType: number;
	dateBirth: string;
	dateCreation: string;
	dateUpdate: string;
	dependentNumber: number;
	deviceBrand: string;
	deviceId: string;
	deviceModel: string;
	deviceType: string;
	email: string;
	employeeGovId: string;
	employeeId: string;
	entityType: number;
	erpCode: string;
	fatherName: string;
	fullname: string;
	gender: number;
	hablitation: number;
	idUUID: string;
	loyaltyPointsBalance: number;
	motherName: string;
	parameters: ConsigneeParametersModel[];
	phoneCommercial: string;
	phoneHome: string;
	phoneMobile: string;
	photo: string;
	photoConsignator: string;
	rate: number;
	registerId: string;
	totalMargin: number;
	usedMargin: number;
	userIdUUID: string;
	username: string;
	vatNumber: string;
}
export interface ConsigneeCreateModel {
	address?: AddressModel[];
	appVersion?: string;
	bankAccounts?: ConsigneeBankAccountsModel[];
	civilStatus?: number;
	consignatorIdUUID: string;
	consigneeStatus?: number;
	consigneeType?: number;
	dateBirth: string;
	dependentNumber?: number;
	deviceBrand?: string;
	deviceId?: string;
	deviceModel?: string;
	deviceType?: string;
	email: string;
	employeeGovId?: string;
	employeeId?: string;
	entityType?: number;
	erpCode?: string;
	fatherName?: string;
	fullname: string;
	gender?: number;
	hablitation?: number;
	loyaltyPointsBalance?: number;
	motherName?: string;
	parameters?: ConsigneeParametersModel[];
	phoneCommercial?: string;
	phoneHome?: string;
	phoneMobile: string;
	photo?: string;
	photoConsignator?: string;
	rate?: number;
	registerId?: string;
	totalMargin?: number;
	usedMargin?: number;
	userIdUUID?: string;
	userStatus?: number;
	userType?: number;
	username: string;
	vatNumber: string;
}
export interface ConsigneeCreateRequestModel {
	applicationId: string;
	batchIdUUID?: string;
	consignee: ConsigneeCreateModel;
	password: string;
	pin: string;
}
export interface ConsigneeSubscriptionCampaignsModel {
	allowMultiplePackage: number;
	allowsSyncCall: number;
	campaignAggregatedImage: string;
	campaignExtraImage: string;
	campaignMainImage: string;
	campaignMode: number;
	campaignModeDescription: string;
	campaignStatus: number;
	campaignType: number;
	consignatorIdUUID: string;
	consignatorName: string;
	dateCreation: string;
	dateEnd: string;
	dateStart: string;
	dateUpdate: string;
	idUUID: string;
	interfaceName: string;
	longDescription: string;
	messagePaymentOverdue: string;
	messageSubscription: string;
	name: string;
	packagePaymentMode: number;
	packagePeriod: number;
	packageValue: number;
	parameterA: string;
	parameterB: string;
	parameterC: string;
	parameterD: string;
	parameterE: string;
	parameterF: string;
	products: ProductSubscription[];
	shortDescription: string;
	showOrder: number;
	visibility: number;
}

export type ConsigneeSubscriptionCampaignsListFilters =
	| 'campaignstatus'
	| 'campaignStatus'
	| 'campaignIdUUID';

export interface ClockingCoords {
	accuracy: number;
	altitude: number;
	altitudeAccuracy: number;
	heading: number;
	latitude: number;
	longitude: number;
	mocked: boolean;
	pointOrder: number;
	speed: number;
}

export enum ClockingModelStatus {
	processing = 0,
	active = 1,
	pending_validation = 2,
	deleted = 3,
	not_accepted = 4,
	refused_by_bo = 5,
	sent_to_payroll = 6,
	failed_to_send_to_payroll = 7,
	requesting_justification = 8,
	justification_answerd = 9
}

export enum ClockingModelInputMode {
	manual_app = 1,
	manual_bo = 2,
	gps = 3,
	offline = 4
}

export enum ClockingModelClockingType {
	enter = 1,
	exit = 2
}

export enum ClockingModelBiometricRecognitionStatus {
	none = 0,
	success_with_image = 1,
	success_with_voice = 2,
	success_with_fingerprint = 3,
	failed_with_image = 101,
	failed_with_voice = 102,
	failed_with_fingerprint = 103,
	failed_location = 104,
	faield_location_photo = 105
}
export interface ClockingModel {
	address: AddressModel;
	biometricConfidence: number;
	biometricRecognitionStatus: ClockingModelBiometricRecognitionStatus;
	clockingActionType: number;
	clockingInputMode: ClockingModelInputMode;
	clockingMotiveType: number;
	clockingStatus: ClockingModelStatus;
	clockingType: ClockingModelClockingType;
	consigneeIdUUID: string;
	coords: ClockingCoords;
	dateCreation: string;
	dateManualRecording: string;
	dateSystem: string;
	dateUpdate: string;
	idUUID: string;
	motiveInfo: string;
	positions: ClockingCoords[];
	consigneeFullname: string;
	obs: string;
	cargoLinkDescription: string;
	cargoLinkId: string;
	consigneeVatNumber: string;
	contractLinkDescription: string;
	contractLinkId: string;
	erpId: string;
	externalIdentificationId: string;
	externalLinkId: string;
	plateId: string;
	positionLinkDescription: string;
	positionLinkId: string;
	biometricRetries: number;
}

export interface ClockingMotiveTypes {
	description: string;
	id: number;
	languageTag: string;
	prefix: string;
}

export interface ConsigneeStatingAdd {
	applicationId?: string;
	batchIdUUID?: string;
	consignee: ConsigneeModel;
	password?: string;
	pin?: string;
}
