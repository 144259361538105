import React from 'react';
import { ls, g_getData, g_entityFilter } from '../GenericFunctions';
import { g_getHeaderTemplate } from '../generic/DataTable';
import AssetService from './../../service/AssetService';
import { states } from './Asset';
import ListPage from '../generic/ListPage';

const table = (context, e) => [
    { type: 'd', data: 'description', filter: true, sortable: true },
    { type: 'd', data: 'brand', filter: true, sortable: true },
    { type: 'd', data: 'model', filter: true, sortable: true },
    { type: 'd', data: 'serialNumber', filter: true, sortable: true },
    { type: 'd', data: 'plateId', filter: true, sortable: true },
    { type: 'date', data: 'dateCreation', filter: true, sortable: true },
    { type: 'state', data: 'assetStatus', states: states },
    { type: 'url', url: raw => `#/asset?def=1&type=1&id=${raw.idUUID}`, width: 1400, height: 600, callback: context.getData, c: e.u === 1 || e.d === 1 },
];

const header = (context, e) => g_getHeaderTemplate(context, '/#/news?type=1', 'new noticias', context.getData, 1400, 700, e.c);

const getData = (context, filter = []) =>
    g_getData(
        context,
        data => {
            let f = g_entityFilter(context.state.filter, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall');
            if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, consignees: [] };
            data.filters = f[0];
            return AssetService.list(data);
        },
        'assets',
        { filters: [...filter, ...context.state.filter] }
    );

const Ambulances = <ListPage title={ls('vehicles', 'titles')} header={header} table={table} getData={context => getData(context)} />;
const Equipments = t => <ListPage title={ls(t === '1' ? 'trucks' : 'warehouses', 'titles')} header={header} table={table} getData={getData} />;

export function ActivesManagement(props) {
    let query = new URLSearchParams(props.location.search);
    let themes = {
        _: Ambulances,
        4200: Equipments(query.get('t')),
    };
    if (process.env.REACT_APP_PORT) {
        let stheme = themes[String(process.env.REACT_APP_PORT)];
        if (stheme) return stheme;
    }
    return themes._;
}
