// TODO: Move everthing in this file into new files
export * from './Notification.old';

export * from './update';

export enum NotificationView {
	not_viewed = 0,
	viewed = 1
}

export enum NotificationVisibility {
	visible = 1,
	hide = 2
}
