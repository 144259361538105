import { Messages } from 'primereact/messages';
import React from 'react';
import ConsigneeService from '../../../service/ConsigneeService';
import { ls, g_checkLoginACL, g_entityFilter, g_genericRequired, g_getData, g_hideSearch, g_template_dataTable } from '../../GenericFunctions';

export default class StagingConsigneeManagement extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        g_getData(
            this,
            data => {
                let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'consignee-management#acessall');
                if (!f) return { code: 0, consignees: [], pageTotal: 0, recordsTotal: 0 };
                data.filters = f[0];
                this.setState({ showMore: f[1] }, () => {
                    if (this.state.showMore !== this.state.oldShowMore) this.setState({ oldShowMore: this.state.showMore }, g_hideSearch);
                });
                return ConsigneeService.listStaging(data);
            },
            'consignees'
        );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{ls('stagingConsigneeManagement', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    null,
                    [
                        { type: 'd', c: this.state.showMore, data: 'consignatorFantasyName', filter: true },
                        { type: 'd', data: 'fullname', sortable: true, filter: true },
                        { type: 'd', data: 'email', sortable: true, filter: true },
                        { type: 'd', align: 'right', data: 'phoneMobile', sortable: true, filter: true },
                        { type: 'd', align: 'right', data: 'vatNumber', sortable: true, filter: true },
                        { type: 'd', align: 'right', data: 'employeeId', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'consigneeStatus',
                            states: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' }, 3: { color: 'red', class: 'pi-ban' } },
                        },
                        { type: 'url', c: e.d === 1 || e.u === 1, url: raw => `#/staging-consignee?type=1&id=${raw.idUUID}`, width: 1000, heigth: 500, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
