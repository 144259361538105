import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef } from 'react';
import ProductService from '../../service/ProductService';
import S from '../../service/Services';
import { CampaignItemCreateModel, CampaignItemModel } from '../../types';
import { Button, noDataMessage, pair, StringEmpty } from '../Generic';
import { Form, GDiv, GDropdown, GNumber, useDataObject } from '../generic/Form';
import GSelector from '../generic/GSelector';
import { g_displayMessageError, g_getData, LS } from '../GenericFunctions';

interface CampaignProductsListProps {
    visible: boolean;
    onHide: () => void;
    campaignIdUUID: string;
    idUUID?: string;
}

//TODO: check for the show all
export const CampaignProductsList: React.FC<CampaignProductsListProps> = ({ visible, onHide, campaignIdUUID, idUUID }) => {
    let messages = useRef<Messages | null>(null);
    let { obj, setObj, setObjState } = useDataObject<CampaignItemModel>({} as any);

    const modeOptions = [
        { label: LS('1', 'campaignMode'), value: '1' },
        { label: LS('2', 'campaignMode'), value: '2' },
        { label: LS('3', 'campaignMode'), value: '3' },
    ];

    useEffect(() => {
        if (StringEmpty(idUUID)) return;
        (async () => {
            let r = await S.campaign.item.list({
                campaignIdUUID,
                filters: [pair('campaignItemIdUUID', idUUID ?? '')],
            });
            if (g_displayMessageError(messages.current, r)) return;
            if (r.campaignItens.length !== 1) {
                noDataMessage(messages.current);
                return;
            }
            let o = r.campaignItens[0];
            setObjState({
                ...o,
                idUUID: o.campaignItemIdUUID,
                campaignIdUUID,
                productName: o.name,
            });
        })();
    }, [idUUID, setObjState, campaignIdUUID]);

    //TODO: remove anys
    return (
        <Dialog style={{ height: '80vh', width: '80vw' }} visible={visible} onHide={onHide}>
            <Messages ref={e => (messages.current = e)} />
            <div style={{ overflowX: 'hidden' }} className="p-col p-fluid contact-form">
                <Form
                    obj={obj}
                    setObj={setObj}
                    onSubmit={async e => {
                        //TODO: add edition
                        e.preventDefault();
                        const item: { campaignItem: CampaignItemCreateModel } = {
                            campaignItem: {
                                ...obj,
                                campaignIdUUID,
                            },
                        };
                        if (!StringEmpty(idUUID)) {
                            item.campaignItem.idUUID = idUUID;
                            let response = await S.campaign.item.update(item);
                            if (g_displayMessageError(messages.current, response)) return;
                            if (messages.current) messages.current.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
                            return;
                        }
                        let response = await S.campaign.item.add(item);
                        if (g_displayMessageError(messages.current, response)) return;
                        if (messages.current) messages.current.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
                        onHide();
                    }}
                >
                    <GDropdown d="campaignItemMode" options={modeOptions} req fClass="p-col-4" />
                    <GDiv group grid class="" extra={{ lclass: 'p-col-2' }}>
                        <GNumber d="condicionalValue" req lClass="p-col-3" fClass="p-col-3" />
                        <GNumber d="afterCondicionalValue" currency req fClass="p-col-3" />
                    </GDiv>
                    <GNumber d="afterCancelationValue" currency req fClass="p-col-3" />
                    <GNumber d="beforeCondicionalValue" currency req fClass="p-col-3" />
                    <GSelector
                        req
                        labelwraper="p-col-3"
                        label={LS('searchAreaServiceType')}
                        getData={(c: any) => g_getData(c, S.search.serviceType.list, 'searchAreaServiceTypes')}
                        tableBody={(c: any) => [
                            { type: 'd', data: 'description', sortable: true, filter: true },
                            {
                                type: 'button',
                                icon: 'pi pi-plus',
                                click: (raw: any, e: any) => {
                                    e.preventDefault();
                                    setObjState({ ...obj, searchAreaServiceTypeDescription: raw.description, searchAreaServiceType: raw.id });
                                    c.setState({ visible: false });
                                },
                            },
                        ]}
                        inputtextwarper="p-col-5"
                        startV={obj.searchAreaServiceTypeDescription}
                    />
                    <GSelector
                        req
                        labelwraper="p-col-3"
                        label={LS('productName')}
                        getData={(c: any) => g_getData(c, ProductService.getProducts, 'products')}
                        tableBody={(c: any) => [
                            { type: 'd', data: 'name', filter: true },
                            {
                                type: 'button',
                                icon: 'pi pi-plus',
                                click: (raw: any, e: any) => {
                                    e.preventDefault();
                                    setObjState({ ...obj, productName: raw.name, productIdUUID: raw.idUUID });
                                    c.setState({ visible: false });
                                },
                            },
                        ]}
                        inputtextwarper="p-col-5"
                        startV={obj.productName}
                    />
                    <Button label="add" wraperClass="p-col-2" />
                </Form>
            </div>
        </Dialog>
    );
};
