import type { GenericResponse, StringDate, UUID, ContextType } from '../..';

export enum ReferFriendStatus {
	created = 0,
	new_ref_based_on_link = 1,
	product_purchased_by_new_reference = 2,
	done = 3
}

export type ReferFriendCreate =
	| {
			name: string;
			phone: string;
			referenceFrom: UUID;
			contextType: ContextType;
	  }
	| {
			idUUID: UUID;
			id: number;
			status: ReferFriendStatus;
			name: string;
			phone: string;
			referenceTo: UUID;
			referenceFrom: UUID;
			contextType: ContextType;
			dateCreation: StringDate;
			dateUpdate: StringDate;
	  };

export interface ReferFriend {
	idUUID: UUID;
	id: number;
	status: ReferFriendStatus;
	name: string;
	phone: string;
	referenceTo: UUID;
	referenceFrom: UUID;
	nameFrom: string;
	contextType: ContextType;
	dateCreation: StringDate;
	dateUpdate: StringDate;
}

export type ReferFriendCreateRequest = Record<'referFriend', ReferFriendCreate>;

export type ReferFriendCreateResponse = GenericResponse & {
	referFriendUUID: UUID;
	referFriendCode: number;
};
