import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import {
    S,
    LS,
    lb,
    pair,
    ListPage,
    LabelSelector,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_hideSearch,
    g_ApiContract,
    g_ChangeStatus,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_getDateTemplate,
    StateIcon,
    g_handleChange,
    g_openWindowDialog,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_checkBox,
    g_template_dialog,
    g_template_dropdown,
    g_template_input,
    g_template_inputArea,
    g_template_numeric,
    g_treatNumric,
    g_treatNumricR,
    g_uuid,
    g_wraper,
    g_genericRequired,
    g_getDataHook,
    g_template_copy,
} from '../GenericFunctions';
import React, { useEffect, useState } from 'react';
import config, { isProtetor, isDrMoz } from '../../config/config';
import ChatService from '../../service/ChatService';
import Services from '../../service/Services';
import ConsigneeService from '../../service/ConsigneeService';
import ContractService from '../../service/ContractService';
import ContractTemplateService from '../../service/ContractTemplateService';
import ProductService from '../../service/ProductService';
import Attachment from '../attachment/Attachment';
import Chat from '../generic/Chat';
import GSelector from '../generic/GSelector';
import Log from '../logs/Log';
import { contractManagement } from './ContractManagement';

export const generateStarsMap = rate => {
    let r = [];
    for (let i = 1; i <= 5; i++) r.push(<span key={i} className={rate < i ? 'pi pi-star-o' : 'pi pi-star'} style={{ color: 'grey', fontSize: '1.87em' }}></span>);
    return <div className="p-col">{r}</div>;
};

export default class Contract extends React.Component {
    constructor() {
        super();
        this.state = {
            data: g_ApiContract(),
            items: [{ label: LS('contract', 'tab') }],
            mode: false,
            documentType: '',
            contractTemplates: [],
            contractTypes: [],
            idUUID: null,
        };
    }

    handleItems(idUUID = null) {
        let id = idUUID !== null ? idUUID : this.state.idUUID;
        let items = [{ label: LabelSelector('contract', 'tab') }];
        if (!id) {
            this.setState({ items: items });
            return;
        }
        if (g_checkAlc(4, 'contract#chat')) items.push({ label: LabelSelector('contractChat', 'tab'), icon: 'pi pi-envelope' });
        items.push({ label: LabelSelector('contractQuota', 'tab'), icon: 'pi pi-list' });
        items.push({ label: LabelSelector('contractRate', 'tab'), icon: 'pi pi-list' });
        items.push({ label: LabelSelector('contractFroms', 'tab'), icon: 'pi pi-id' });
        if (g_checkAlc(4, 'contract#attachment')) items.push({ label: LabelSelector('attachments', 'tab'), icon: 'pi pi-paperclip' });
        items.push({ label: LabelSelector('log', 'tab'), icon: 'pi pi-list' });
        this.setState({ items: items });
    }

    blockStatus = e => this.updateStatus(3, e, LS('simpleBlock', 'messages'));
    updateStatusClick = e => this.updateStatus(this.state.data.contractStatus !== 2 ? 2 : 7, e, LS('simpleStatus', 'messages'));
    onStepConfirm = e =>
        g_ChangeStatus(
            this,
            () => ContractService.updateStatus({ idUUID: this.state.data.idUUID, contractStatus: this.state.toSelected, contractStatusMessage: this.state.toSelectedMessage }),
            () => this.setState({ toSelected: '', dialog_state_stepclick: false }, this.getData),
            LS('simpleStatus', 'messages'),
            this.state.messages,
            e
        );
    negateStatus = e =>
        g_ChangeStatus(
            this,
            () => ContractService.updateStatus({ idUUID: this.state.data.idUUID, contractStatus: 200, contractStatusMessage: this.state.negationMessage }),
            () => this.setState({ negationMessage: '', dialog_state_negateStatus: false }, this.getData),
            LS('simpleStatus', 'messages'),
            this.state.messages,
            e
        );

    updateStatus = (s, e, message) => g_ChangeStatus(this, () => ContractService.updateStatus({ idUUID: this.state.data.idUUID, contractStatus: s }), this.getData, message, this.state.messages, e);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        this.handleItems(idUUID);
        this.getContractTypes();
        this.setState({ mode, idUUID }, this.getData);
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let response = await ContractService.getContract(this.state.idUUID);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.contract;
        rdata.contractType = String(rdata.contractType);
        rdata.contractInterestsPercentage = g_treatNumricR(rdata.contractInterestsPercentage);
        rdata.contractTotalCapitalValue = g_treatNumricR(rdata.contractTotalCapitalValue);
        rdata.contractTotalInterestsValue = g_treatNumricR(rdata.contractTotalInterestsValue);
        if (rdata.workflowType === 5 || rdata.workflowType === 6) {
            window.location.href = `/#/contractP?type=1&&id=${rdata.idUUID}`;
            window.location.reload();
            return;
        }
        this.setState({ data: rdata });
    };

    getContractTypes = async () => {
        let response = await ContractService.getContractTypes({});
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({
            contractTypes: response.contractTypes.map(contractType => ({ label: contractType.description, value: String(contractType.id) })),
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        let rdata = this.state.data;
        rdata.contractInterestsPercentage = g_treatNumric(rdata.contractInterestsPercentage);
        rdata.contractTotalCapitalValue = g_treatNumric(rdata.contractTotalCapitalValue);
        rdata.contractTotalInterestsValue = g_treatNumric(rdata.contractTotalInterestsValue);
        if (rdata.dateContractStart) {
            let d = new Date(rdata.dateContractStart);
            rdata.dateContractStart = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (rdata.dateContractEnd) {
            let d = new Date(rdata.dateContractEnd);
            rdata.dateContractEnd = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000Z`;
        }
        if (this.state.idUUID !== null) {
            let response = await ContractService.updateContract({ contract: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
        } else {
            let response = await ContractService.addContract({ contract: rdata });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            window.location.href = contractManagement.url(response.contract.idUUID);
            window.reload();
        }
    };

    stepClick = e => {
        let index = e.index;
        let s = this.getSteps()[index];
        let step = s?.aF[this.state.data.contractStatus];
        if (!step) return;
        if (
            !(
                (step.indexOf('contract#steps-numo') !== -1 && g_checkAlc(5, 'contract#steps-numo') && g_checkAlc(5, 'contract#steps-numo')[0]?.u) ||
                (step.indexOf('contract#steps-provider') !== -1 && g_checkAlc(5, 'contract#steps-provider') && g_checkAlc(5, 'contract#steps-provider')[0]?.u) ||
                (step.indexOf('contract#steps-financier') !== -1 && g_checkAlc(5, 'contract#steps-financier') && g_checkAlc(5, 'contract#steps-financier')[0]?.u) ||
                (step.indexOf('contract#steps-consignator') !== -1 && g_checkAlc(5, 'contract#steps-consignator') && g_checkAlc(5, 'contract#steps-consignator')[0]?.u)
            )
        )
            return false;
        this.setState({ toSelectedMessage: '', toSelected: s.id, dialog_state_stepclick: true });
    };

    checkNext = () => {
        let s = this.getSteps()[this.getCStep() + 1];
        let step = s?.aF[this.state.data.contractStatus];
        if (!step) return false;
        if (
            !(
                (step.indexOf('contract#steps-numo') !== -1 && g_checkAlc(5, 'contract#steps-numo') && g_checkAlc(5, 'contract#steps-numo')[0]?.u) ||
                (step.indexOf('contract#steps-provider') !== -1 && g_checkAlc(5, 'contract#steps-provider') && g_checkAlc(5, 'contract#steps-provider')[0]?.u) ||
                (step.indexOf('contract#steps-financier') !== -1 && g_checkAlc(5, 'contract#steps-financier') && g_checkAlc(5, 'contract#steps-financier')[0]?.u) ||
                (step.indexOf('contract#steps-consignator') !== -1 && g_checkAlc(5, 'contract#steps-consignator') && g_checkAlc(5, 'contract#steps-consignator')[0]?.u)
            )
        )
            return false;
        return true;
    };

    getCStep = () => {
        let steps = this.getSteps();
        let ind = -1;
        steps.forEach((e, i) => (ind = e.id === this.state.data.contractStatus ? i : ind));
        return ind;
    };

    getSteps = () => {
        let L = t => LS(t, 'contractSteps');
        var acls = (f = false, p = false, c = false) => [
            'contract#steps-numo',
            ...(p ? ['contract#steps-provider'] : []),
            ...(f ? ['contract#steps-financier'] : []),
            ...(c ? ['contract#steps-consignator'] : []),
        ];

        let toSend = [
            { label: L('emit'), id: 0, aF: [] },
            { label: L('completeData'), id: 5, aF: { 0: acls(), 7: acls(), 8: acls(1), 10: acls(0, 1) } },
        ];
        let ISFINANCIER = [1, 2].indexOf(this.state.data.workflowType) !== -1;
        let ISPROVIDER = [3, 4].indexOf(this.state.data.workflowType) !== -1;
        let ISCONSIGNATOR = [7].indexOf(this.state.data.workflowType) !== -1;
        let NOTNUMO = [7].indexOf(this.state.data.workflowType) !== -1;
        let SIGNATURE = [3].indexOf(this.state.data.contractSignatureMode) !== -1;
        if (!NOTNUMO)
            toSend = [
                ...toSend,
                { label: L('needNumoValidation'), id: 7, aF: { 5: acls(), 4: acls() } },
                {
                    label: L('needNumoEmission'),
                    id: 4,
                    aF: {
                        7: acls(),
                        ...(ISFINANCIER ? { 8: acls(1) } : {}),
                        ...(!ISFINANCIER && ISPROVIDER ? { 10: acls(0, 1) } : {}),
                        ...(!ISFINANCIER && !ISPROVIDER && SIGNATURE ? { 6: acls() } : {}),
                    },
                },
            ];
        //IS Financier
        if (ISFINANCIER)
            toSend = [
                ...toSend,
                { label: L('needFinancierValidation'), id: 8, aF: { 5: NOTNUMO ? acls() : [], 4: acls(), 9: acls(1) } },
                { label: L('needFinancierEmission'), id: 9, aF: { 8: acls(1), ...(ISPROVIDER ? { 10: acls(1) } : {}), ...(!ISPROVIDER && SIGNATURE ? { 6: acls() } : {}) } },
            ];
        //IS Provider
        if (ISPROVIDER)
            toSend = [
                ...toSend,
                { label: L('needProviderValidation'), id: 10, aF: { 5: !ISFINANCIER && NOTNUMO ? acls() : [], 11: acls(0, 1), 9: acls(1), ...(!ISFINANCIER ? { 4: acls(0, 1) } : {}) } },
                { label: L('needProviderEmission'), id: 11, aF: { 10: acls(0, 1), 6: !ISCONSIGNATOR ? acls() : [] } },
            ];
        //IS Consignator
        if (ISCONSIGNATOR)
            toSend = [
                ...toSend,
                {
                    label: L('needConsignatorValidation'),
                    id: 13,
                    aF: { 5: !ISFINANCIER && !ISPROVIDER && NOTNUMO ? acls() : [], ...(!ISFINANCIER && !ISPROVIDER ? { 4: acls(0, 1) } : {}), 14: acls(0, 0, 1) },
                },
                { label: L('needConsignatorEmission'), id: 14, aF: { 13: acls(0, 0, 1), 6: acls() } },
            ];
        //Signature needed
        if (SIGNATURE)
            toSend = [
                ...toSend,
                {
                    label: L('needSignature'),
                    id: 6,
                    aF: {
                        ...(ISCONSIGNATOR ? { 14: acls(0, 0, 1) } : {}),
                        ...(!ISCONSIGNATOR && ISPROVIDER ? { 10: acls(0, 1) } : {}),
                        ...(!ISCONSIGNATOR && !ISPROVIDER && ISFINANCIER ? { 9: acls(1) } : {}),
                        ...(!ISCONSIGNATOR && !ISFINANCIER && !ISPROVIDER && !NOTNUMO ? { 4: acls() } : {}),
                    },
                },
            ];
        toSend = [
            ...toSend,
            {
                label: L('active'),
                id: 1,
                aF: {
                    6: acls(),
                    ...(ISCONSIGNATOR && !SIGNATURE ? { 14: acls(0, 0, 1) } : {}),
                    ...(ISPROVIDER && !ISCONSIGNATOR && !SIGNATURE ? { 11: acls(0, 1) } : {}),
                    ...(!ISPROVIDER && !SIGNATURE && !ISCONSIGNATOR && ISFINANCIER ? { 9: acls(1) } : {}),
                    ...(!SIGNATURE && !ISFINANCIER && !ISPROVIDER && !ISCONSIGNATOR && !NOTNUMO ? { 4: acls() } : {}),
                },
            },
        ];
        return toSend;
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file, this.state.documentType);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadHandler = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        var response = await ProductService.documentUpload(formData, type, this.state.product.idUUID);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleUpload') });
        this.getData();
    };

    getWorkflowType = () =>
        g_getData(
            this,
            () => ContractTemplateService.getContractTemplate({}, this.state.data.contractTemplateIdUUID),
            response => this.setState({ data: { ...this.state.data, workflowType: response.contractTemplate.workflowType, contractSignatureMode: response.contractTemplate.contractSignatureMode } })
        );

    getTemplates = context => g_getData(context, ContractService.getContractTemplate, 'contractTemplates');

    getConsignators = S.consignator.processed.list;

    getConsignees = context =>
        this.state.data.consignatorIdUUID
            ? g_getData(context, ConsigneeService.listConsignee, 'consignees', { filters: [...context.state.filter, { key: 'consignatorIdUUID', value: this.state.data.consignatorIdUUID }] })
            : null;

    getProducts = context => g_getData(context, ProductService.getProducts, 'products');

    isBlock = (e, numosteps) => {
        if (this.state.mode) return false;
        if ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null)) {
            this.setState({ mode: true });
            return true;
        }
        if (this.state.data.contractStatus === 100) return true;
        if (numosteps && [0, 5, 7, 4].indexOf(this.state.data.contractStatus) !== -1) return false;
        return true;
    };

    render() {
        return g_checkLoginACL(e => {
            let numoSuperviser = g_checkAlc(5, 'contract#steps-numo-super');
            let numosteps = !g_checkAlc(5, 'contract#steps-numo') || !g_checkAlc(5, 'contract#steps-numo')[0]?.u;
            let s = this.state.data.contractStatus;
            let negate =
                (g_checkAlc(5, 'contract#steps-numo') && g_checkAlc(5, 'contract#steps-numo')[0]?.u && s === 7) ||
                (g_checkAlc(5, 'contract#steps-financier') && g_checkAlc(5, 'contract#steps-financier')[0]?.u && s === 8) ||
                (g_checkAlc(5, 'contract#steps-provider') && g_checkAlc(5, 'contract#steps-provider')[0]?.u && s === 10) ||
                (g_checkAlc(5, 'contract#steps-consignator') && g_checkAlc(5, 'contract#steps-consignator')[0]?.u && (s === 13 || s === 14));
            let mode = this.isBlock(e, numosteps);
            let footer = (
                <div>
                    {g_template_button(LS('yes'), '', 0, null, 0, '', 'p-button-danger')}
                    {g_template_button(
                        LS('no'),
                        '',
                        0,
                        e => {
                            e.preventDefault();
                            this.setState({ dialog_state_stepclick: false, dialog_state_negateStatus: false, toSelected: '', toSelectedMessage: '' });
                        },
                        0,
                        ''
                    )}
                </div>
            );
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(
                        this,
                        [
                            <form className="p-col" onSubmit={this.handleSubmit}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                        <Panel>
                                            {this.state.idUUID ? g_template_copy(this, 'ID', this.state.data.idUUID, 'id', !this.state.idUUID, 'p-col-2') : null}
                                            <GSelector
                                                labelwraper="p-col-3"
                                                label={LS('contractTemplate')}
                                                ref={e => (this.selector0 = e)}
                                                getData={this.getTemplates}
                                                gobtt={g_checkAlc(4, 'contractTemplate') && this.state.data.contractTemplateIdUUID}
                                                jump={() =>
                                                    g_openWindowDialog(`#/contractTemplate?type=1&id=${this.state.data.contractTemplateIdUUID}`, this.state.data.contractTemplateIdUUID, 1200, 700)
                                                }
                                                tableBody={[
                                                    { type: 'd', data: 'title', sortable: true, filter: true },
                                                    {
                                                        type: 'btt',
                                                        icon: 'pi pi-plus',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector0.setState({ selected: raw.title, visible: false });
                                                            this.setState({ data: { ...this.state.data, contractTemplateIdUUID: raw.idUUID, contractTemplateTitle: raw.title } }, this.getWorkflowType);
                                                        },
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                disabled={mode}
                                                startV={this.state.data.contractTemplateTitle}
                                            />
                                            {g_template_dropdown(this, 'contractType', this.state.data.contractType, LS('contractType'), this.state.contractTypes, mode, false, true, 'p-col-5')}
                                            <GSelector
                                                labelwraper="p-col-3"
                                                label={LS('consignatorName')}
                                                ref={e => (this.selector1 = e)}
                                                getData={this.getConsignators}
                                                gobtt={g_checkAlc(4, 'consignator') && this.state.data.consignatorIdUUID}
                                                jump={() => g_openWindowDialog(`#/consignator?type=1&id=${this.state.data.consignatorIdUUID}`, this.state.data.consignatorIdUUID, 900, 600)}
                                                tableBody={[
                                                    { type: 'd', data: 'name', sortable: true, filter: true },
                                                    {
                                                        type: 'btt',
                                                        icon: 'pi pi-plus',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector1.setState({ selected: raw.name, visible: false });
                                                            this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                                        },
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                disabled={mode}
                                                startV={this.state.data.consignatorName}
                                            />
                                            <GSelector
                                                label={LS('consigneeName')}
                                                ref={e => (this.selector2 = e)}
                                                getData={this.getConsignees}
                                                gobtt={g_checkAlc(4, 'consignee') && this.state.data.consigneeIdUUID}
                                                jump={() => g_openWindowDialog(`#/consignee?type=1&id=${this.state.data.consigneeIdUUID}`, this.state.data.consigneeIdUUID, 1200, 600)}
                                                tableBody={[
                                                    { type: 'd', data: 'fullname', sortable: true, filter: true },
                                                    {
                                                        type: 'btt',
                                                        icon: 'pi pi-plus',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector2.setState({ ...this.selector2.state, selected: raw.fullname, visible: false });
                                                            this.setState({ data: { ...this.state.data, consigneeIdUUID: raw.idUUID } });
                                                        },
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                labelwraper="p-col-3"
                                                disabled={mode}
                                                startV={this.state.data.consigneeFullname}
                                            />
                                            <GSelector
                                                label={LS('product')}
                                                ref={e => (this.selector3 = e)}
                                                getData={this.getProducts}
                                                gobtt={g_checkAlc(4, 'product') && this.state.data.productIdUUID}
                                                jump={() => g_openWindowDialog(`#/product?type=1&id=${this.state.data.productIdUUID}`, this.state.data.productIdUUID, 1200, 600)}
                                                tableBody={[
                                                    { type: 'd', data: 'fullname', sortable: true, filter: true },
                                                    {
                                                        type: 'btt',
                                                        icon: 'pi pi-plus',
                                                        click: (raw, e) => {
                                                            e.preventDefault();
                                                            this.selector3.setState({ ...this.selector3.state, selected: raw.fullname, visible: false });
                                                            this.setState({ data: { ...this.state.data, productIdUUID: raw.idUUID } });
                                                        },
                                                    },
                                                ]}
                                                inputtextwarper="p-col-5"
                                                labelwraper="p-col-3"
                                                disabled={mode}
                                                startV={this.state.data.productName}
                                            />
                                            {g_wraper(
                                                'dateContractStart',
                                                LS('dateContractStart'),
                                                [
                                                    <div className="p-col-2">
                                                        <Calendar
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            yearRange={`${new Date().getFullYear() - 2}:${new Date().getFullYear() + 10}`}
                                                            id="dateContractStart"
                                                            dateFormat="dd-mm-yy"
                                                            disabled={mode}
                                                            value={new Date(this.state.data.dateContractStart)}
                                                            onChange={e => (mode ? g_handleChange(e, {}, this) : null)}
                                                        />
                                                    </div>,
                                                    g_wraper(
                                                        'dateContractEnd',
                                                        LS('dateContractEnd'),
                                                        <div className="p-col-2">
                                                            <Calendar
                                                                id="dateContractEnd"
                                                                yearRange={`${new Date().getFullYear()}:${new Date().getFullYear() + 20}`}
                                                                monthNavigator={true}
                                                                disabled={mode}
                                                                yearNavigator={true}
                                                                dateFormat="dd-mm-yy"
                                                                value={new Date(this.state.data.dateContractEnd)}
                                                                onChange={e => (mode ? g_handleChange(e, {}, this) : null)}
                                                            />
                                                        </div>,
                                                        false,
                                                        'p-col-2',
                                                        true
                                                    ),
                                                ],
                                                false,
                                                'p-col-3'
                                            )}
                                            {g_wraper(
                                                'contractTotalValue',
                                                LS('contractTotalValue'),
                                                [
                                                    g_template_input(this, 'contractTotalValue', this.state.data.contractTotalValue, '', mode, false, false, 'p-col-2', '', '', {
                                                        regex: new RegExp('\\d*', 'g'),
                                                    }),
                                                    g_wraper(
                                                        'installmentTotal',
                                                        LS('installmentTotal'),
                                                        g_template_input(this, 'installmentTotal', this.state.data.installmentTotal, '', mode, false, false, 'p-col-2', '', '', {
                                                            regex: new RegExp('\\d*', 'g'),
                                                        }),
                                                        false,
                                                        'p-col-2',
                                                        true
                                                    ),
                                                ],
                                                false,
                                                'p-col-3'
                                            )}
                                            {g_wraper(
                                                'contractTotalCapitalValue',
                                                LS('contractTotalCapitalValue'),
                                                [
                                                    g_template_numeric(this, 'contractTotalCapitalValue', this.state.data.contractTotalCapitalValue, '', mode, false, false, 'p-col-2'),
                                                    g_wraper(
                                                        'contractInterestsPrecentage',
                                                        LS('contractInterestsPrecentage'),
                                                        g_template_numeric(this, 'contractInterestsPrecentage', this.state.data.contractInterestsPercentage, '', mode, false, false, 'p-col-2'),
                                                        false,
                                                        'p-col-1',
                                                        true
                                                    ),
                                                    g_wraper(
                                                        'contractTotalInterestsValue',
                                                        LS('contractTotalInterestsValue'),
                                                        g_template_numeric(this, 'contractTotalInterestsValue', this.state.data.contractTotalInterestsValue, '', mode, false, false, 'p-col-2'),
                                                        false,
                                                        'p-col-2',
                                                        true
                                                    ),
                                                ],
                                                false,
                                                'p-col-3'
                                            )}
                                            {g_template_input(this, 'info', this.state.data.info, LS('info'), mode, false, false, 'p-col', 'p-col-3')}
                                            {g_template_inputArea(this, 'obs', this.state.data.obs, LS('obs'), true, false, false, 'p-col-8', 'p-col-12', '', { minHeight: '2em' })}
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-lg-3">
                                        <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                            {g_wraper(
                                                null,
                                                LS('state'),
                                                <div className="p-col">
                                                    <StateIcon small custom={states} state={this.state.data.contractStatus} />
                                                </div>,
                                                !this.state.idUUID,
                                                'p-col-7'
                                            )}
                                            {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                            {g_wraper(null, LS('dataUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                            {g_wraper(null, LS('ratting'), generateStarsMap(this.state.data.ratting), !this.state.idUUID, 'p-col-7')}
                                        </Panel>
                                    </div>
                                </div>
                                <div className="p-grid p-fluid">
                                    {g_template_button(
                                        LS(this.state.idUUID ? 'save' : 'create'),
                                        '',
                                        this.state.data.contractStatus === 100 || numosteps || [0, 4, 5, 7].indexOf(this.state.data.contractStatus) === -1,
                                        null,
                                        this.state.mode,
                                        'p-col-2'
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS(`ask${this.state.data.contractStatus === 2 ? 'Activate' : 'Deactivate'}`, 'messages'),
                                        'Tem a certeza que quer ' + (this.state.data.contractStatus === 2 ? 'ativar' : 'desativar') + '?',
                                        'toggle',
                                        this.updateStatusClick,
                                        LS(this.state.data.contractStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                        !this.state.idUUID || [3, 100, 200].indexOf(this.state.data.contractStatus) !== -1 || numosteps,
                                        this.state.mode,
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'p-col-2'
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('askForceEmission', 'messages'),
                                        'forceEmission',
                                        e => this.updateStatus(9001, e, LS('simpleUpload', 'messages')),
                                        LS('forceEmission', 'btt'),
                                        !this.state.idUUID || ![14].includes(this.state.data.contractStatus) || !numoSuperviser,
                                        this.state.mode,
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'p-col-2',
                                        'p-button-success'
                                    )}
                                    {g_template_button(
                                        [8, 10, 13, 7].indexOf(this.state.data.contractStatus) !== -1
                                            ? LS('validate', 'btt')
                                            : [9, 11, 14, 4].indexOf(this.state.data.contractStatus) !== -1
                                            ? LS('emitir', 'btt')
                                            : LS('advance', 'btt'),
                                        '',
                                        !this.state.idUUID || [3, 100, 200].indexOf(this.state.data.contractStatus) !== -1 || !this.checkNext(),
                                        () => this.stepClick({ index: this.getCStep() + 1 }),
                                        this.state.mode,
                                        'p-col-2',
                                        'p-button-success'
                                    )}
                                    {g_template_button(
                                        lb('deny'),
                                        '',
                                        !this.state.idUUID | ([3, 100, 200].indexOf(this.state.data.contractStatus) !== -1) || !negate,
                                        () => this.setState({ negationMessage: '', dialog_state_negateStatus: true }),
                                        this.state.mode,
                                        'p-col-2',
                                        'p-button-danger'
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('askBlockP', 'messages'),
                                        'delete',
                                        this.blockStatus,
                                        lb('block'),
                                        !this.state.idUUID | ([3, 100, 200].indexOf(this.state.data.contractStatus) !== -1) || numosteps,
                                        this.state.mode,
                                        'p-button-danger',
                                        '',
                                        '',
                                        '',
                                        'p-col-2',
                                        'p-button-danger'
                                    )}
                                </div>
                                <Panel style={{ overflowX: 'auto', width: '100%' }}>
                                    <div className="p-grid p-fluid">
                                        {g_wraper(
                                            '',
                                            '',
                                            <Steps model={this.getSteps()} activeIndex={this.getCStep()} className="steps-custom" readOnly={false} onSelect={this.stepClick} />,
                                            [3, 2].indexOf(this.state.data.contractStatus) !== -1
                                        )}
                                    </div>
                                </Panel>
                            </form>,
                            ...(g_checkAlc(4, 'contract#chat')
                                ? [
                                      <Chat
                                          context={this}
                                          apiRequestList={data => {
                                              data.filters = [{ key: 'referenceIdUUID', value: this.state.data.idUUID }];
                                              return ChatService.messageList(data);
                                          }}
                                          apiRequestAdd={message => {
                                              let data = {};
                                              data.messageContent = message;
                                              data.messageFlow = 2;
                                              data.messageType = 1;
                                              data.operatorEntityIdUUID = g_uuid();
                                              data.referenceIdUUID = this.state.data.idUUID;
                                              data.mainEntityIdUUID = this.state.data.consigneeIdUUID;
                                              data.contextId = 4;
                                              return ChatService.messageAdd({ chatMessage: data });
                                          }}
                                          apiRequestRead={() => ChatService.messageMarkRead(this.state.data.idUUID, 1)}
                                          apiRequestOpenA={link => g_openWindowDialog(config.PHOTO_CONTRACT_URL + link, 500, 500)}
                                          fakename="contract#chat"
                                      />,
                                  ]
                                : []),
                            <ContractInstalments
                                context={() => this}
                                getData={context =>
                                    g_getData(
                                        context,
                                        data => {
                                            data.filters = [...data.filters, { key: 'contractIDUUID', value: this.state.idUUID }];
                                            return ContractService.getInstallments(data);
                                        },
                                        'contractInstallments'
                                    )
                                }
                            />,
                            contractRattings(() => this),
                            <RenderContracts data={this.state.data} />,
                            ...(g_checkAlc(4, 'contract#attachment') ? [<Attachment idUUID={this.state.idUUID} documentContextType={4} service={'contract'} />] : []),
                            <Log idUUID={this.state.idUUID} logContextType={4} />,
                        ],
                        null
                    )}
                    <form onSubmit={this.onStepConfirm}>
                        <Dialog
                            visible={this.state['dialog_state_stepclick']}
                            onHide={() => {
                                let toSet = {};
                                toSet['dialog_state_stepclick'] = false;
                                this.setState(toSet);
                            }}
                            footer={footer}
                            style={{ width: '52vw' }}
                            header={LS('confirm', 'titles')}
                        >
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">Tem a certeza que quer alterar o estado do contracto?</div>
                                {![5].indexOf(this.state.toSelected)
                                    ? g_template_inputArea(
                                          this,
                                          'toSelectedMessage',
                                          this.state.toSelectedMessage,
                                          LS('askMessagesToConsignee'),
                                          0,
                                          0,
                                          1,
                                          'p-col-12',
                                          'p-col-12',
                                          '',
                                          { width: '48vw' },
                                          { data: 'toSelectedMessage' }
                                      )
                                    : null}
                            </div>
                        </Dialog>
                    </form>
                    <form onSubmit={this.negateStatus}>
                        <Dialog
                            visible={this.state['dialog_state_negateStatus']}
                            onHide={() => {
                                let toSet = {};
                                toSet['dialog_state_negateStatus'] = false;
                                this.setState(toSet);
                            }}
                            footer={footer}
                            style={{ width: '50vw', overflowX: 'none' }}
                            header={LS('confirm', 'titles')}
                        >
                            <div className="p-grid p-fluid" style={{ overflowX: 'none', width: '40vw' }}>
                                <div className="p-col-12">Tem a certeza que quer Negar?</div>
                                {g_template_inputArea(
                                    this,
                                    'negationMessage',
                                    this.state.negationMessage,
                                    LS('reason', 'generic'),
                                    0,
                                    0,
                                    1,
                                    'p-col-12',
                                    'p-col-12',
                                    '',
                                    { width: '48vw' },
                                    { data: 'negationMessage' }
                                )}
                            </div>
                        </Dialog>
                    </form>
                </div>
            );
        });
    }
}

export const contractRattings = contract => (
    <ListPage
        checkAcl={false}
        header={c => g_getHeaderTemplatecF(c, () => null, c.getData, false)}
        table={() => [
            { type: 'd', data: 'rattingCategoryDescription' },
            { type: 'di', di: raw => generateStarsMap(raw.rate), data: 'rate', align: 'center' },
        ]}
        getData={c => {
            let rattings = contract().state.data.rattings ?? [];
            c.setState({
                data: rattings,
                pageSize: rattings.length,
                recordsTotal: rattings.length,
            });
        }}
        addon={() => <div className="p-grid p-fluid">{g_wraper(null, LS('rate'), generateStarsMap(contract().state.data.ratting), false, 'p-col-4')}</div>}
    />
);

class ContractInstalments extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.props.getData(this);
    }

    changeStatus = async () => {
        if (!this.state.idUUID) return;
        const response = await ContractService.installments.updateStatus({ contractInstallmentStatus: 2, idUUID: this.state.idUUID, contractInstallmentStatusMessage: this.state.message });
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.getData(this);
    };

    render() {
        let footer = (
            <div>
                {g_template_button(LS('yes'), '', 0, null, 0, '', 'p-button-danger')}
                {g_template_button(
                    LS('no'),
                    '',
                    0,
                    e => {
                        e.preventDefault();
                        this.setState({ dialog_state_status: false, message: '' });
                    },
                    0,
                    ''
                )}
            </div>
        );
        return (
            <>
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        null,
                        [
                            { type: 'd', data: 'installmentNumber' },
                            { type: 'd', data: 'installmentValue' },
                            { type: 'd', data: 'capital' },
                            { type: 'd', data: 'interests' },
                            { type: 'date', data: 'dateInstallment' },
                            { type: 'date', data: 'datePayment' },
                            { type: 'date', data: 'datePayed' },
                            { type: 'date', data: 'dateCreation' },
                            {
                                type: 'state',
                                data: 'installmentStatus',
                                states: {
                                    1: { color: 'orange', class: 'pi-check', tooltip: LS('open') },
                                    2: { color: 'green', class: 'pi-check', tooltip: LS('payed') },
                                    3: { color: 'red', class: 'pi-times', tooltip: LS('canceld') },
                                    4: { color: 'orange', class: 'pi-clock', tooltip: LS('canceld') },
                                },
                            },
                            {
                                type: 'button',
                                icon: 'pi pi-check',
                                class: 'p-button-success',
                                show: raw => raw.installmentStatus !== 1,
                                click: async raw => this.setState({ dialog_state_status: true, message: '', idUUID: raw.idUUID }),
                            },
                        ],
                        () => this.props.getData(this)
                    )}
                </div>
                <form onSubmit={this.changeStatus}>
                    <Dialog
                        visible={this.state['dialog_state_status']}
                        onHide={() => {
                            let toSet = {};
                            toSet['dialog_state_status'] = false;
                            this.setState(toSet);
                        }}
                        footer={footer}
                        style={{ width: '50vw', overflowX: 'none' }}
                        header={LS('confirm', 'titles')}
                    >
                        <div className="p-grid p-fluid" style={{ overflowX: 'none', width: '40vw' }}>
                            <div className="p-col-12">{LS('askChangeToPayed')}</div>
                            {g_template_inputArea(this, 'message', this.state.message, LS('reason', 'generic'), 0, 0, 1, 'p-col-12', 'p-col-12', '', { width: '48vw' }, { data: 'message' })}
                        </div>
                    </Dialog>
                </form>
            </>
        );
    }
}

export function RenderContracts(props) {
    let [contractData, setContractData] = useState(null);
    useEffect(() => {
        if (contractData || !props.data?.contractComplementaryFields || !props.data?.contractTemplateIdUUID) return;
        g_getDataHook(
            [{}, () => null],
            Services.contract.template.form.list,
            {
                t: 'contractTemplateForms',
                f: data => {
                    if (data.length === 0) return;
                    let pdata = null;
                    if (data.length === 1) pdata = data[0].contractFields;
                    else data.reduce((a, e) => [...(a.contractFields ? a.contractFields : a), ...e.contractFields]);
                    if (!pdata || pdata.length === 0) return;
                    let r = {};
                    for (let e of pdata) {
                        if (e.fieldListValues) {
                            r[e.fieldName] = e.fieldListValues.split('|').map(a => {
                                let r = {};
                                r[a.split(':')[0]] = a.split(':')[1];
                                return r;
                            });
                            if (r[e.fieldName].length !== 0) r[e.fieldName] = r[e.fieldName].reduce((a, e) => ({ ...a, ...e }));
                        }
                    }
                    setContractData(r);
                },
            },
            { filters: [pair('contractTemplateIdUUID', props.data.contractTemplateIdUUID)] }
        );
    });
    if (!props.data?.contractComplementaryFields || !props.data?.contractTemplateIdUUID) return null;
    return props.data.contractComplementaryFields.map((e, i, a) => (
        <div className="p-col-12">
            {(() => {
                let value;
                switch (e.contractTemplateFieldType) {
                    case 12:
                    case 6:
                        return g_template_checkBox(this, '', e.fieldValue === 1, e.title, 0, 0, 'p-col-2');
                    case 4:
                    case 5:
                        return g_wraper('', e.title, <div className="p-col-10">{((contractData ?? {})[e.fieldName] ?? {})[e.fieldValue] ?? e.filedValue}</div>, 0, 'p-col-2');
                    case 14:
                    case 10:
                    case 2:
                    case 1:
                    case 9:
                    case 7:
                    case 13:
                    case 17:
                        return g_wraper('', e.title, <div className="p-col-10">{e.fieldValue}</div>, 0, 'p-col-2');
                    case 11:
                        value = JSON.parse(e.fieldValue);
                        return g_wraper('', e.title, <div className="p-col-10">{value.listField}</div>, 0, 'p-col-2');
                    case 3:
                        return g_wraper('', e.title, <div className="p-col-10">{g_getDateTemplate(e.fieldValue, 0, false, true)}</div>, 0, 'p-col-2');
                    case 15:
                        return g_wraper('', e.title, <div className="p-col-10">{g_getDateTemplate(e.fieldValue, 1, false, true)}</div>, 0, 'p-col-2');
                    case 16:
                        return g_wraper('', e.title, <div className="p-col-10" dangerouslySetInnerHTML={{ __html: e.fieldValue }}></div>, 0, 'p-col-2');
                    case 8:
                        value = JSON.parse(e.fieldValue);
                        return (
                            <div>
                                {i !== 0 ? <h1> </h1> : null}
                                <h4>{e.title}</h4>
                                {g_wraper('', LS('zipCode'), <div className="p-col-10">{value.zipCode}</div>, 0, 'p-col-2')}
                                {g_wraper('', LS('street'), <div className="p-col-10">{value.street}</div>, 0, 'p-col-2')}
                                {g_wraper('', LS('port'), <div className="p-col-10">{value.port}</div>, 0, 'p-col-2')}
                                {g_wraper('', LS('streetZone'), <div className="p-col-10">{value.streetZone}</div>, 0, 'p-col-2')}
                                {g_wraper('', LS('city'), <div className="p-col-10">{value.city}</div>, 0, 'p-col-2')}
                                {g_wraper('', LS('state'), <div className="p-col-10">{value.state}</div>, 0, 'p-col-2')}k {a.length - 1 !== 1 ? <h1> </h1> : null}
                            </div>
                        );
                    default:
                        return (
                            <div>
                                {' '}
                                {e.contractTemplateFieldType} teste
                                <br /> {e.fieldName} : {e.fieldValue}{' '}
                            </div>
                        );
                }
            })()}
        </div>
    ));
}

export const states = {
    0: { color: 'blue', class: 'pi-step-forward', tooltip: LS('emit', 'tooltip') },
    1: { color: 'green', class: 'pi-play', tooltip: LS('active', 'tooltip') },
    2: { color: 'red', class: 'pi-times', tooltip: LS('canceld', 'tooltip') },
    3: { color: 'red', class: 'pi-ban', tooltip: LS('blocked', 'tooltip') },
    4: { color: 'grey', class: 'pi-circle-off', tooltip: LS('emitNumo', 'tooltip') },
    5: { color: 'orange', class: 'pi-align-justify', tooltip: LS('dataMissing', 'tooltip') },
    6: { color: 'orange', class: 'pi-pencil', tooltip: LS('signature', 'tooltip') },
    7: { color: 'orange', class: 'pi-circle-off', tooltip: LS('validationNumo', 'tooltip') },
    8: { color: 'orange', class: 'pi-dollar', tooltip: LS('validationProvider', 'tooltip') },
    9: { color: 'grey', class: 'pi-dollar', tooltip: LS('emitProvider', 'tooltip') },
    10: { color: 'orange', class: 'pi-home', tooltip: LS('validationFinancier', 'tooltip') },
    11: { color: 'grey', class: 'pi-home', tooltip: LS('emitFinancier', 'tooltip') },
    13: { color: 'orange', class: 'pi-home', tooltip: LS('validationConsignator', 'tooltip') },
    14: { color: 'grey', class: 'pi-home', tooltip: LS('emitConsignator', 'tooltip') },
    12: { color: 'orange', class: 'pi-calendar', tooltip: LS('scheduled', 'tooltip') },
    100: { color: 'green', class: 'pi-check', tooltip: LS('finished', 'tooltip') },
    200: { color: 'red', class: 'pi-minus-circle', tooltip: LS('canceld', 'tooltip') },
    999: { color: 'red', class: 'pi-exclamation-triangle', tooltip: LS('error', 'tooltip') },
    998: { color: 'red', class: 'pi-exclamation-triangle', tooltip: LS('errorAnalysing', 'tooltip') },
    300: { color: 'red', class: 'pi-exclamation-triangle', tooltip: LS('delayConsignee', 'tooltip') },
    301: { color: 'red', class: 'pi-exclamation-triangle', tooltip: LS('delayConsignator', 'tooltip') },
    302: { color: 'red', class: 'pi-exclamation-triangle', tooltip: LS('delayFinancier', 'tooltip') },
    303: { color: 'red', class: 'pi-exclamation-triangle', tooltip: LS('delayProvider', 'tooltip') },
    ...(isProtetor || isDrMoz
        ? {
              400: { tooltip: LS('pendingEquimpment', 'tooltip'), color: 'orange', class: 'pi-spinner pi-spin' },
              401: { tooltip: LS('tripScheduled', 'tooltip'), color: 'orange', class: 'pi-arrow-right' },
              402: { tooltip: LS('inTrip', 'tooltip'), color: 'green', class: 'pi-arrow-right' },
              403: { tooltip: LS('inReturn', 'tooltip'), color: 'green', class: 'pi-arrow-left' },
              430: { tooltip: LS('pendingService', 'tooltip'), color: 'orange', class: 'pi-user' },
              431: { tooltip: LS('inService', 'tooltip'), color: 'green', class: 'pi-user' },
          }
        : {}),
};
