import { GenericListResponseG, GenericResponseG } from '../../Generic';
import {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../Requests';
import { TicketModel } from '../Ticket';

export type Filters =
	| 'idUUID'
	| 'protocol'
	| 'requesterName'
	| 'requesterEmail'
	| 'requesterMobilephone'
	| 'consignatoridUUID'
	| 'incidentStatus'
	| 'inincidentStatus'
	| 'distributorName'
	| 'distributorIdUUID'
	| 'solverName'
	| 'solverIdUUID'
	| FilterDateType<'dateUpdate'>
	| FilterDateType<'dateCreation'>
	| 'assignedToMeUUID'
	| 'levelNotIn'
	| 'ticketSlaDescription'
	| 'incidentStatusDescription'
	| 'emailOrPhone';

export type OrderBy =
	| 'dateCreation'
	| 'dateUpdate'
	| 'distributorName'
	| 'solverName'
	| 'ticketSlaDescription'
	| 'incidentStatusDescription'
	| 'protocol'
	| 'requesterName'
	| 'requesterEmail'
	| 'requesterMobilephone';

export const list =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidents', TicketModel> =>
		req.axios.post(`${baseUrl}/list`, data);

export const get =
	(req: RequestsClass, baseUrl: string) =>
	(
		idUUID: string,
		loadAddress: 0 | 1 = 1,
		loadContract: 0 | 1 = 1
	): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.get(`${baseUrl}/${idUUID}/${loadAddress}/${loadContract}`);
