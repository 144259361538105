import type { RequestsClass } from '../../Requests';
import { add, update, status } from './add';
import { list, get, elementList } from './list';
import { types } from './types';

const baseUrl = '/group';

export const GroupsApi = (req: RequestsClass) => ({
	list: list(req, baseUrl),
	types: types(req, baseUrl),
	add: add(req, baseUrl),
	update: update(req, baseUrl),
	status: status(req, baseUrl),
	get: get(req, baseUrl),

	element: {
		list: elementList(req, baseUrl)
	}
});
