import axios from './AxiosService';

class BatchService {

    add = data => axios.request('POST', '/card/add', data) 
    list = data => axios.request('POST', '/card/list', data) 
    update = data => axios.request('PUT', '/card/update', data) 
    updateStatus = data => axios.request('PUT', '/card/update/status', data) 
    payments = data => axios.request('POST', '/card/payment/list', data) 

}

export default new BatchService();
