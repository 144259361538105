import { Dialog } from 'primereact/dialog';
import React from 'react';
import UtilsService from '../../service/UtilsService';
import { g_checkAlc, g_displayMessageError, g_formMaper, g_openWindowDialog, g_template_button, LabelSelector, ls } from '../GenericFunctions';
export const EMPTY_ADDRESS = {
    addressType: 1,
    city: '',
    country: '',
    port: '',
    state: '',
    street: '',
    streetZone: '',
    zipCode: '',
    complement: '',
    idUUID: '',
    latitude: '',
    longitude: '',
};
export const types = { 1: LabelSelector('type1', 'address'), 2: LabelSelector('type2', 'address'), 3: LabelSelector('type3', 'address'), 4: LabelSelector('type4', 'address') };
const google = window.google;

/**
 * @param handleSubmit {function}
 */
export class AddressDialog extends React.Component {
    constructor() {
        super();
        this.state = { data: { ...EMPTY_ADDRESS }, visible: false };
    }
    open = address => {
        this.setState({ data: address, visible: true });
    };
    render() {
        let footer = (
            <div className="ui-dialog-buttonpane p-clearfix">
                {' '}
                {g_template_button(LabelSelector(this.state.data.idUUID ? 'save' : 'create', 'btt'), '', false, null, false, '')}{' '}
            </div>
        );
        let a = !this.state.visible ? null : (
            <Address messages={this.props.messages} context={this.props.context} showType={true} vValue={this.state.data} ref={e => (this.add = e)} />
        );
        return (
            <form onSubmit={e => this.props.handleSubmit(e, this.add.state.data)}>
                <Dialog visible={this.state.visible} onHide={() => this.setState({ data: { ...EMPTY_ADDRESS }, visible: false })} header="Endereço" footer={footer}>
                    {a}
                </Dialog>
            </form>
        );
    }
}
export default class Address extends React.Component {
    constructor() {
        super();
        this.state = { data: { ...EMPTY_ADDRESS } };
    }
    componentDidMount() {
        if (this.props.vValue) this.setState({ data: this.props.vValue });
    }
    changeZipCode = async () => {
        if (!this.state.data.zipCode) {
            this.setState({ data: { ...EMPTY_ADDRESS } });
            return;
        }
        if (this.state.data.zipCode.match(/_/)) return;
        let zipcode = this.state.data.zipCode.replace(/[^\d]/g, '');
        let data = { filters: [{ key: 'zipCode', value: zipcode }], pageSize: 1, pageNumber: 1 };
        let response = await UtilsService.zipCodeList(data);
        if (g_displayMessageError(this.props.context?.messages, response)) return;
        if (!response.zipcodes[0]) return;
        this.setState({ data: { ...response.zipcodes[0], addressType: this.state.data.addressType } });
    };
    onDragEnd = (a, _, window) => {
        let trim = a =>
            String(a)
                .split('.')
                .reduce((a, e, i) => a + '.' + (i !== 1 ? e : e.substr(0, 8)))
                .replace('.', ',');
        this.setState({ data: { ...this.state.data, latitude: trim(a.overlay.position.lat()), longitude: trim(a.overlay.position.lng()) } });
        if (window) window.close();
        this.update(1);
    };
    updateTimeout = null;
    update = a => {
        let d = this.state.data;
        let p = d => {
            this.setState({ data: d });
            if (this.props.updateLat) this.props.updateLat(d);
        };
        if (a === 1) {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    location: {
                        lat: Number(String(d.latitude).replace(',', '.')),
                        lng: Number(String(d.longitude).replace(',', '.')),
                    },
                },
                (a, r) => {
                    if (r === 'OK') {
                        if (a[0]) {
                            a[0].address_components.forEach(e => {
                                if (e.types.indexOf('street_number') !== -1) {
                                    d.port = e.long_name;
                                } else if (e.types.indexOf('route') !== -1) {
                                    d.street = e.long_name;
                                } else if (e.types.indexOf('locality') !== -1) {
                                    d.region = e.long_name;
                                } else if (e.types.indexOf('administrative_area_level_1') !== -1) {
                                    d.city = e.long_name;
                                } else if (e.types.indexOf('country') !== -1) {
                                    //d.country = e.long_name;
                                } else if (e.types.indexOf('postal_code') !== -1) {
                                    d.zipCode = e.long_name.replace(/[^\d]/g, '');
                                }
                            });
                        }
                    } else if (r === 'ZERO_RESULTS' && this.props.messages) this.props.messages.show({ severity: 'warn', summary: LabelSelector('zeroResults', 'messages') });
                    p(d);
                }
            );
        } else if (a === 2) {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    address: d.street + (d.port ? ', ' + d.port : '') + (d.zipCode ? ', ' + d.zipCode : '') + (d.city ? ', ' + d.city : ''),
                },
                (a, r) => {
                    if (r === 'OK') {
                        if (a[0]) {
                            let { lat, lng } = a[0].geometry.location;
                            d.latitude = lat();
                            d.longitude = lng();
                        }
                    } else if (r === 'ZERO_RESULTS' && this.props.messages) this.props.messages.show({ severity: 'warn', summary: LabelSelector('zeroResults', 'messages') });
                    p(d);
                }
            );
        }
    };
    render() {
        let types = [
            { label: LabelSelector('addressType1', 'addressTypeValue'), value: '1' },
            { label: LabelSelector('addressType2', 'addressTypeValue'), value: '2' },
            { label: LabelSelector('addressType3', 'addressTypeValue'), value: '3' },
            { label: LabelSelector('addressType4', 'addressTypeValue'), value: '4' },
        ];
        return (
            <div className="p-fluid">
                {g_formMaper(
                    this,
                    [
                        {
                            t: 'array',
                            mask: { 1: ls('zipCode', 'mask') },
                            options: [types],
                            placeholder: { 1: ls('zipCode', 'placeholder') },
                            c: this.props.c ?? {},
                            cc: { _: 3, 2: [5, 1, 1], 3: 4, 4: [5, 1], 5: [2, 2, 1, 1] },
                            class: { 2: [2, 1, 2], 4: [2, 1], 5: [2, 1, 1, 1] },
                            handleExtraData: { 1: { callback: this.changeZipCode }, 4: [{}, { regex: new RegExp('[A-Z]{0,2}') }] },
                            canNegative: false,
                            filter: ['addressType', 'zipCode', ['street', 'port', 'complement'], 'streetZone', ['city', 'state'], ['latitude', 'longitude', '', '']],
                            dTypeFs: { _: 't', 0: 'drop', 1: 'mask', 5: { _: 'numeric', 2: 'btt', 3: 'btt' } },
                            bigNumeric: true,
                            tooltip: { 5: { 0: '', 1: '', 2: LabelSelector('seeMap', 'tooltip'), 3: LabelSelector('getLngLat', 'tooltip') } },
                            disabled: {
                                5: {
                                    _: false,
                                    2: !(this.state.data.latitude && this.state.data.longitude && g_checkAlc(4, 'map-page')),
                                    3: !(this.state.data.street && this.state.data.port && g_checkAlc(4, 'map-page')),
                                },
                            },
                            l: { 5: { 2: '', 3: '' } },
                            icon: { 5: { 2: 'pi pi-globe', 3: 'pi pi-download' } },
                            style: { 5: { fontSize: '1.5em' } },
                            click: {
                                5: {
                                    2: e => {
                                        e.preventDefault();
                                        g_openWindowDialog(
                                            `#/map-page?type=1&lng=${this.state.data.longitude}&lat=${this.state.data.latitude}`,
                                            'map',
                                            700,
                                            700,
                                            'on',
                                            '',
                                            this.onClose,
                                            window => (window.onDragEnd = this.onDragEnd)
                                        );
                                    },
                                    3: e => {
                                        e.preventDefault();
                                        this.update(2);
                                    },
                                },
                            },
                        },
                    ],
                    this.props.context?.state?.mode,
                    2
                )}
            </div>
        );
    }
}

export class AdvancedAddress extends React.Component {
    constructor() {
        super();
        this.state = { data: { ...EMPTY_ADDRESS } };
    }
    componentDidMount() {
        if (this.props.vValue) this.setState({ data: this.props.vValue });
        if (this.props.getValue) this.props.getValue(() => this.state.data);
    }
    changeZipCode = async () => {
        if (!this.state.data.zipCode) {
            this.setState({ data: { ...EMPTY_ADDRESS } });
            return;
        }
        if (this.state.data.zipCode.match(/_/)) return;
        let zipcode = this.state.data.zipCode.replace(/[^\d]/g, '');
        let data = { filters: [{ key: 'zipCode', value: zipcode }], pageSize: 1, pageNumber: 1 };
        let response = await UtilsService.zipCodeList(data);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        if (!response.zipcodes[0]) return;
        this.setState({ data: { ...response.zipcodes[0], addressType: this.state.data.addressType } });
    };
    onDragEnd = (a, _, window) => {
        let trim = a =>
            String(a)
                .split('.')
                .reduce((a, e, i) => a + '.' + (i !== 1 ? e : e.substr(0, 8)))
                .replace('.', ',');
        this.setState({ data: { ...this.state.data, latitude: trim(a.overlay.position.lat()), longitude: trim(a.overlay.position.lng()) } });
        if (window) window.close();
        this.update(1);
    };
    updateTimeout = null;
    update = a => {
        const google = window.google;
        let d = this.state.data;
        let p = d => this.setState({ data: d });
        if (a === 1) {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    location: {
                        lat: Number(String(d.latitude).replace(',', '.')),
                        lng: Number(String(d.longitude).replace(',', '.')),
                    },
                },
                (a, r) => {
                    if (r === 'OK') {
                        if (a[0]) {
                            a[0].address_components.forEach(e => {
                                if (e.types.indexOf('street_number') !== -1) {
                                    d.port = e.long_name;
                                } else if (e.types.indexOf('route') !== -1) {
                                    d.street = e.long_name;
                                } else if (e.types.indexOf('locality') !== -1) {
                                    d.region = e.long_name;
                                } else if (e.types.indexOf('administrative_area_level_1') !== -1) {
                                    d.city = e.long_name;
                                } else if (e.types.indexOf('country') !== -1) {
                                    //d.country = e.long_name;
                                } else if (e.types.indexOf('postal_code') !== -1) {
                                    d.zipCode = e.long_name.replace(/[^\d]/g, '');
                                }
                            });
                        }
                    } else if (r === 'ZERO_RESULTS' && this.props.messages) this.props.messages.show({ severity: 'warn', summary: LabelSelector('zeroResults', 'messages') });
                    p(d);
                }
            );
        } else if (a === 2) {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    address: d.street + (d.port ? ', ' + d.port : '') + (d.zipCode ? ', ' + d.zipCode : '') + (d.city ? ', ' + d.city : ''),
                },
                (a, r) => {
                    if (r === 'OK') {
                        if (a[0]) {
                            let { lat, lng } = a[0].geometry.location;
                            d.latitude = lat();
                            d.longitude = lng();
                        }
                    } else if (r === 'ZERO_RESULTS' && this.props.messages) this.props.messages.show({ severity: 'warn', summary: LabelSelector('zeroResults', 'messages') });
                    p(d);
                }
            );
        }
    };
    render() {
        let types = [
            { label: LabelSelector('addressType1', 'addressTypeValue'), value: '1' },
            { label: LabelSelector('addressType2', 'addressTypeValue'), value: '2' },
            { label: LabelSelector('addressType3', 'addressTypeValue'), value: '3' },
            { label: LabelSelector('addressType4', 'addressTypeValue'), value: '4' },
        ];
        return (
            <div className="p-fluid">
                {g_formMaper(
                    this,
                    [
                        {
                            t: 'array',
                            mask: { 1: ls('zipCode', 'mask') },
                            options: [types],
                            placeholder: { 1: ls('zipCode', 'placeholder') },
                            cc: { _: 3, 2: [5, 1, 1], 3: 4, 4: [5, 1], 5: [2, 2, 1, 1], ...(this.props.cc ?? {}) },
                            class: { 2: [2, 1, 2], 4: [2, 1], 5: [2, 1, 1, 1] },
                            handleExtraData: { 1: { callback: this.changeZipCode }, 4: [{}, { regex: new RegExp('[A-Z]{0,2}') }] },
                            canNegative: false,
                            req: this.props.req ?? false,
                            filter: ['addressType', 'zipCode', ['street', 'port', 'complement'], 'streetZone', ['city', 'state'], ['latitude', 'longitude', '', '']],
                            dTypeFs: { _: 't', 0: 'drop', 1: 'mask', 5: { _: 'numeric', 2: 'btt', 3: 'btt' } },
                            bigNumeric: true,
                            tooltip: { 5: { 2: LabelSelector('seeMap', 'tooltip'), 3: LabelSelector('getLngLat', 'tooltip') } },
                            disabled: {
                                _: this.props.mode ?? false,
                                5: {
                                    _: this.props.mode ?? false,
                                    2: !(this.state.data.latitude && this.state.data.longitude && g_checkAlc(4, 'map-page')) || (this.props.mode ?? false),
                                    3: !(this.state.data.street && this.state.data.port && g_checkAlc(4, 'map-page')) || (this.props.mode ?? false),
                                },
                            },
                            l: { 5: { 2: '', 3: '' } },
                            icon: { 5: { 2: 'pi pi-globe', 3: 'pi pi-download' } },
                            style: { 5: { fontSize: '1.5em' } },
                            click: {
                                5: {
                                    2: e => {
                                        e.preventDefault();
                                        g_openWindowDialog(
                                            `#/map-page?type=1&lng=${this.state.data.longitude}&lat=${this.state.data.latitude}`,
                                            'map',
                                            700,
                                            700,
                                            'on',
                                            '',
                                            this.onClose,
                                            window => (window.onDragEnd = this.onDragEnd)
                                        );
                                    },
                                    3: e => {
                                        e.preventDefault();
                                        this.update(2);
                                    },
                                },
                            },
                            c: this.props.c ?? {},
                        },
                    ],
                    this.props.context?.state?.mode,
                    2
                )}
            </div>
        );
    }
}
