import type { GenericResponseG, UUID } from '../../Generic';
import type { RequestsClass } from '../../Requests';
import type { ShoppingCart } from '../ShoppingCart';

export interface ShoppingCartCreate {
	shoppingCart: {
		requesterIdUUID: UUID;
		contractTemplateIdUUID: UUID;
		quantity: number;
		providerIdUUID: UUID;
		deliveryAddress?: string;
		schedulingIdUUID?: UUID;
	};
}

export const add =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: ShoppingCartCreate
	): Promise<GenericResponseG<'shoppingCart', ShoppingCart>> =>
		req.axios.post(`${baseUrl}/add`, data);
