import React from 'react';
import { S, ls, g_checkLoginACL, g_getData, g_tabViewRenderOptions, g_template_form, g_wraper } from '../../GenericFunctions';
import Log from '../../logs/Log';

export default class CallInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: {}, items: [{ label: 'Informação de Chamadas' }], idUUID: null, mode: true };
    }
    render() {
        return g_checkLoginACL(this.grender);
    }
    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  () => S.call.get(this.state.idUUID),
                  response => this.setState({ data: response.callRecord })
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        let items = this.state.items;
        if (idUUID) items.push({ label: 'Logs', icon: 'pi pi-list' });
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    formatTime = sec => {
        sec = Math.round(sec);
        let h = Math.floor(sec / 60 / 60);
        if (h > 0) sec = sec - h * 60 * 60;
        let min = Math.floor(sec / 60);
        if (min > 0) sec = sec - min * 60;
        return `${h > 0 ? h + ':' : ''}${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    };

    grender = () => (
        <div className="card card-w-title">
            {g_tabViewRenderOptions(this, [
                g_template_form(
                    this,
                    () => [
                        {
                            t: 'array',
                            dTypeF: 'view',
                            cc: 4,
                            dTypeFs: { 3: 'dElm', 4: 'dElm' },
                            filter: ['callRecordStatusDescription', 'originName', 'destinationName', '', '', 'dateStartCall', 'dateEndCall'],
                            dElm: {
                                3: g_wraper(
                                    'duration',
                                    ls('duration'),
                                    <div className="p-col-4">
                                        {' '}
                                        {this.state.data.dateStartCall && this.state.data.dateEndCall
                                            ? this.formatTime((new Date(this.state.data.dateEndCall).getTime() - new Date(this.state.data.dateStartCall).getTime()) / 1000)
                                            : ''}{' '}
                                    </div>,
                                    false,
                                    'p-col-2'
                                ),
                                4: g_wraper(
                                    'waitTime',
                                    ls('waitTime'),
                                    <div className="p-col-4">
                                        {' '}
                                        {this.state.data.dateCreation && this.state.data.dateStartCall
                                            ? this.formatTime((new Date(this.state.data.dateStartCall).getTime() - new Date(this.state.data.dateCreation).getTime()) / 1000)
                                            : ''}{' '}
                                    </div>,
                                    false,
                                    'p-col-2'
                                ),
                            },
                        },
                    ],
                    () => [],
                    () => [[{ t: 'array', origin: this.state.data, filter: ['dateCreation', 'dateUpdate'], dTypeF: 'view', class: 'l' }]],
                    () => {},
                    2,
                    true,
                    this.state.mode,
                    this.state.idUUID ? 9 : 12,
                    this.state.idUUID ? 3 : -1,
                    '70vh'
                ),
                <Log idUUID={this.state.idUUID} logContextType={28} />,
            ])}
        </div>
    );
}
