import { GenericListResponseG } from '../../../Generic';
import { GenericListRequest, RequestsClass } from '../../../Requests';
import { CityModel } from '../City';

export type Filters = 'country';

export type OrderBy = '';

export const listCities =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'cities', CityModel> =>
		req.axios.post(`${baseUrl}/citySearcher`, data);
