import type {
	Entity,
	StringDate,
	UUID,
	EntityType,
	ContextType,
	AddressModel
} from '..';

export enum ProviderStatus {
	active = 1,
	disabled = 2,
	blocked = 3,
	forApproval = 4,
	denied = 5
}

export interface ProviderCreate {
	fantasyName?: string;
	organizationIdUUID: string;
	name: string;
	vatNumber: string;
}

export interface ProviderModel extends Required<ProviderCreate> {
	idUUID: UUID;
	parentIdUUID: UUID;
	parentName: string;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	organizationIdUUID: UUID;
	organizationName: string;
	providerType: number;
	providerStatus: number;
	searchAreaCategory: number;
	name: string;
	vatNumber: string;
	entityType: EntityType;
	shortDescription: string;
	longDescription: string;
	email: string;
	phoneMobile: string;
	phoneHome: string;
	photo: string;
	chatMode: number;
	schedulingMode: number;
	emailMode: number;
	phoneMode: number;
	addressMode: number;
	rate: number;
	erpCode: string;
	address: AddressModel[];
	id: number;
	entityId: number;
	fantasyName: string;

	siteUrl: string;
	facebookUrl: string;
	instangramUrl: string;
	tweeterUrl: string;
}

export interface ProviderUpdate extends Partial<ProviderModel> {
	idUUID: UUID;
}

export interface ProviderPublicAdd {
	provider: ProviderCreate;
	searchAreaServiceTypes: number[];
	contractTemplateParametersName: string[];

	createUser: 0 | 1;
	userName: string;
	userProfileIdUUID: string;
}

export enum EntityContractTemplateStatus {
	active = 1,
	deleted = 2
}

export interface EntityContractTemplate {
	idUUID: UUID;
	contractTemplateIdUUID: UUID;
	referenceIdUUID: UUID;
	contextType: number;
	contractTemplateContextType: number;
	contractTemplateContextTypeDescription: string;
	status: number;
	contractTemplateName: string;
	referenceName: string;
	priceForProduct: number;
	discountValue: number;
	priceForCampaign: number;
	entity: Entity;
	referenceType: number;
}

export interface EntityContractTemplateCreate {
	contractTemplateIdUUID: UUID;
	referenceIdUUID: UUID;
	contextType: ContextType;
}
