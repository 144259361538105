//import { Dialog } from 'primereact/dialog';
import React from 'react';
import { ListPage, LabelSelector, g_getHeaderTemplate } from '../GenericFunctions';
import S from '../../service/Services';
import { states } from './Provider';

//provider set subscriptiontype to 2
//export var listSubscriptionns = context => <ListPage
//    table={() => [
//        {type: 'd', data: 'campaignName'},
//        {type: 'date', title: 'Data', data: 'dateCreation', sortable: true, filter: true},
//        {type: 'state', title: 'Estado', data: 'campaignStatus', states: {1: {color: 'green', class:"pi-check"}, 2: {color: 'red', class:"pi-times"}, 3: {color:'red', class:'pi-ban'}}},
//        {type: 'url', url: raw => `/#/subscribe?type=1&cid=${this.props.context.state.idUUID}&id=${raw.idUUID}`, width: 1000, height: 500}
//    ]}
//    getData={c => context()?.state?.idUUID ? g_getData(c, S.subscription.list, 'subscriptions', {referenceIdUUID: context().state.idUUID}) : null}
//    header={c => null}
//    addon={c =>
//        <Dialog header='Adicionar um novo' onHide={() => c.setState({showNewOne: false})} visible={c.state.showNewOne} >
//            {listSubscriptionnsAddNew(context)}
//        </Dialog>
//    }
//>

//    remove = async raw => {
//        if (!this.props.context().state.idUUID) return;
//        let response = await ProviderService.deleteSearchAreaServiceType(this.props.context().state.idUUID, raw.id);
//        if (g_displayMessageError(this.props.context().messages, response)) return;
//        this.getData();
//    }
//     {type: 'dil', text: 'Tem a certeza que quer remover?', icon: 'pi pi-trash', buttonClassName: 'p-button-danger', id: raw => raw.id, onConfirm: this.remove },

//const listSubscriptionnsAddNew = context => <ListPage
//    table={c => [
//        {type: 'd', data: 'description', filter:true},
//        {type: 'button', icon: 'pi pi-plus', click: async raw => {
//            if (!context().state.idUUID) return;
//            let data = { searchAreaServiceType: e.id, providerIdUUID: this.state.idUUID };
//            let response = await ProviderService.addSearchAreaServiceType(data);
//            if (g_displayMessageError(this.messages, response)) return;
//            context().messages.show({severity: 'success', summary: 'Adicionado com sucesso!'});
//            c.props.callBackData();
//            c.getData();
//        }}
//    ]}
//    getData={c => context()?.state?.idUUID ? g_getData(c, S.subscription.list, 'subscriptions', {referenceIdUUID: context().state.idUUID}) : null}
//    header={c => null}
///>

let size = [1500, 800];
let url = id => `#/provider?type=1${id ? `&id=${id}` : ''}`;
export const providerList = {
    render: () => (
        <ListPage
            title={LabelSelector('provider', 'titles')}
            header={(c, e) => g_getHeaderTemplate(c, url(), 'New Provider', c.getData, size[0], size[1], e.c === 1)}
            getData={c => S.provider.processed.list(c)}
            table={(c, e) => [
                { type: 'd', data: 'name', sortable: true, filter: true },
                { type: 'd', data: 'fantasyName', sortable: true, filter: true },
                { type: 'd', data: 'email', sortable: true, filter: true },
                { type: 'd', data: 'vatNumber', sortable: true, filter: true },
                { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                { type: 'state', data: 'providerStatus', states: states },
                { type: 'url', c: e.u === 1 || e.d === 1, url: raw => url(raw.idUUID), width: 1200, height: 700, callback: c.getData },
            ]}
        />
    ),
    size: size,
    url: url,
};

export const chatModes = { 0: LabelSelector('chatMode0', 'provider'), 1: LabelSelector('chatMode1', 'provider'), 2: LabelSelector('chatMode2', 'provider') };
export const emailModes = { 0: LabelSelector('emailMode0', 'provider'), 1: LabelSelector('emailMode1', 'provider') };
export const phoneModes = { 0: LabelSelector('phoneMode0', 'provider'), 1: LabelSelector('phoneMode1', 'provider') };
export const schedulingModes = {
    0: LabelSelector('schedulingMode0', 'provider'),
    1: LabelSelector('schedulingMode1', 'provider'),
    2: LabelSelector('schedulingMode2', 'provider'),
};
