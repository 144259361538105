import React from 'react';
import { Messages } from 'primereact/messages';
import { ls, LS, g_checkLoginACL, g_entityFilter, g_getData, g_genericRequired, g_hideSearch, g_template_dataTable, g_getHeaderTemplate } from '../GenericFunctions';
import CardService from '../../service/CardService';
import * as Card from './Card';

export default class CardManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        g_getData(
            this,
            data => {
                if (this.props.refIdUUID) data.filters.push({ key: 'referenceIdUUID', value: this.props.refIdUUID });
                let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'card-management#accessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, cards: [] };
                data.filters = f[0];
                return CardService.list(data);
            },
            'cards'
        );
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <Messages ref={e => (this.messages = e)} />
                {!this.props.refIdUUID ? <h1>{LS('cards', 'titles')}</h1> : null}
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '/#/card?type=1', 'Create new card', this.getData, 1100, 500, true, w => (w.consignee = this.props.context().state.data)),
                    [
                        { type: 'd', data: 'panMasked', sortable: true, filter: true },
                        { type: 'd', data: 'brand', title: ls('brandCard'), sortable: true, filter: true },
                        { type: 'd', data: 'vatNumber', sortable: true, filter: true },
                        { type: 'd', data: 'name', sortable: true, filter: true },
                        { type: 'di', data: 'cardType', di: raw => LS(raw.cardType, 'cardType') },
                        { type: 'd', data: 'description', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', data: 'cardDefault', states: { 0: { color: 'grey', class: 'pi-times' }, 1: { color: 'grep', class: 'pi-check' } } },
                        { type: 'state', data: 'cardStatus', states: () => Card.status },
                        {
                            type: 'url',
                            c: e.d === 1 || e.c === 1,
                            url: raw => `#/card?type=1&id=${raw.idUUID}`,
                            width: 1100,
                            height: 500,
                            callBack: this.getData,
                            icon: 'pi pi-pencil',
                            class: 'pi p-button-warning',
                        },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
