import type { GenericListResponse, Product } from '../..';
import type { GenericListRequest, RequestsClass } from '../../Requests';

export type ProductListFilters =
	| 'idUUID'
	| 'productMode'
	| 'productSubType'
	| 'productType'
	| 'productStatus'
	| 'name';

export const list =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<ProductListFilters> & {
			contractTemplateIdUUID?: string;
		}
	): Promise<GenericListResponse & { products: Product[] }> =>
		req.axios.post(`${baseUrl}/list`, data);
