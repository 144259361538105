import type { RequestsClass } from '../../Requests';
import { list } from './list';
import { status } from './status';
import { add } from './add';
import { remove } from './remove';
import { ShoppingCartApiRequesterUUID } from './requester-uuid';

const baseUrl = '/shopping-cart';

export const ShoppingCartApi = (req: RequestsClass) => ({
	list: list(req, baseUrl),
	status: status(req, baseUrl),
	add: add(req, baseUrl),
	delete: remove(req, baseUrl),

	requesterUUID: ShoppingCartApiRequesterUUID(req, baseUrl)
});
