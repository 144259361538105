import type { FilterDateType } from './Requests';

export interface CallRecord {
	callRecordStatus: number;
	callRecordStatusDescription: string;
	callRecordType: number;
	callRecordTypeDescription: string;
	contractIdUUID: string;
	contractInfo: string;
	contractStatus: number;
	dateCreation: string;
	dateEndCall: string;
	dateEndHandshake: string;
	dateEndQueueWait: string;
	dateStartCall: string;
	dateStartHandshake: string;
	dateStartQueueWait: string;
	dateUpdate: string;
	destinationIdUUID: string;
	destinationName: string;
	idUUID: string;
	originIdUUID: string;
	originName: string;
	queueCyclesCount: number;
	quizContractIdUUID: string;
}

export type CallRecordListFilters =
	| 'destinationName'
	| 'destinationIdUUID'
	| 'originiduuid'
	| 'useriduuid'
	| FilterDateType<'dateCreation'>
	| FilterDateType<'dateStartCall'>
	| FilterDateType<'dateEndCall'>
	| 'workingEntityIdUUID'
	| 'originName';

export enum VideoCallMessagesType {
	init = 'INIT-CALL',
	initwait = 'INIT-CALL-WAIT',
	initok = 'INIT-CALL-OK',
	initfail = 'INIT-CALL-FAIL',
	initcancel = 'INIT-CALL-CANCEL',
	initcancelok = 'INIT-CALL-CANCEL-OK',
	offerphase1req = 'OFFER-PHASE1-REQ',
	offerphase1resp = 'OFFER-PHASE1-RESP',
	offerphase2req = 'OFFER-PHASE2-REQ',
	offerphase2resp = 'OFFER-PHASE2-RESP',
	hangup = 'HANG-UP',
	endcall = 'END-CALL',
	endcallok = 'END-CALL-OK'
}

export interface VideoCallMessageModel {
	type: VideoCallMessagesType;
	referenceIdUUID?: string;
	callIdUUID?: string;
	tID?: number;
	recipient?: string;
}

export interface VideoCallContractModelBase {
	consigneeIdUUID: string;
	consignatorIdUUID: string;
	productIdUUID?: string;
	contractTotalValue?: number;
	installmentTotal?: number;
	fillerField1?: string;
	fillerField2?: string;
	contractComplementaryFields?: any[];
}
export type VideoCallContractModel = VideoCallContractModelBase &
	(
		| {
				contractTemplateIdUUID: string;
		  }
		| { idUUID: string }
	);

export interface VideoCallMessageInitModel extends VideoCallMessageModel {
	type: VideoCallMessagesType.init;
	behaviour: number;
	behaviourIdUUID: string;
	contract: VideoCallContractModel;
}

export interface VideoCallMessageCallFailModel extends VideoCallMessageModel {
	type: VideoCallMessagesType.initfail;
	messages: { key: number; value: string }[];
}

export interface VideoCallMessageOfferModel extends VideoCallMessageModel {
	type:
		| VideoCallMessagesType.offerphase1req
		| VideoCallMessagesType.offerphase1resp
		| VideoCallMessagesType.offerphase2req
		| VideoCallMessagesType.offerphase2resp;
	code: number;
	value: string;
}
