import React from 'react';
import Barcode from 'react-barcode';
import config from '../../config/config';
import { S, ls, lt, StateIcon, g_checkLoginACL, g_getData, g_getDateTemplate, g_tabViewRenderOptions, g_wraper, g_template_form } from '../GenericFunctions';
import Log from '../logs/Log';
import './boleto.css';
import { Steps } from 'primereact/steps';

export default class Boleto extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                aceite: '',
                bankId: 0,
                bankingBilletDocumentType: 0,
                bankingBilletDocumentTypePrefix: '',
                bankingBilletNumber: 0,
                bankingBilletNumberCD: '',
                bankingBilletResponseCode: 0,
                bankingBilletStatus: 0,
                bankingBilletType: 0,
                barcode: '',
                billetDisplayValue: 0,
                billetValue: 0,
                bodyPayerDescription: '',
                bodyRecipientDescription: '',
                callbackUrl: '',
                carteira: '',
                consignatorIdUUID: '',
                consignatorName: '',
                contractIdUUID: '',
                dateCreation: '',
                dateDisplayDue: '',
                dateDue: '',
                dateUpdate: '',
                delayCosts: 0,
                discounts: 0,
                emailAddress: '',
                emailText: '',
                especie: '',
                idUUID: '',
                installmentNumber: 0,
                mecanicLine: '',
                otherCredits: 0,
                otherDeductions: 0,
                payeeName: '',
                payeeVatNumber: '',
                payerAddressCity: '',
                payerAddressState: '',
                payerAddressStreet: '',
                payerAddressStreetZone: '',
                payerAddressZipcode: 0,
                payerIDUUID: '',
                payerName: '',
                payerVatNumber: '',
                paymentLocation: '',
                pdfLayout: '',
                pdfLogo: '',
                recipientAccount: '',
                recipientBranch: '',
                recipientName: '',
                recipientVatNumber: '',
                referenceNumber: '',
                smsMobilePhone: '',
                smsText: '',
            },
            items: [{ label: lt('boleto') }, { label: lt('log') }],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        this.setState({ idUUID: query.get('id') }, this.getData);
    }

    getData = () =>
        this.state.idUUID
            ? g_getData(this, S.boleto.list, response => (response.bankingBillets[0] ? this.setState({ data: response.bankingBillets[0] }) : null), {
                  filters: [{ key: 'idUUID', value: this.state.idUUID }],
              })
            : null;

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [
                                { t: 'copy', text: this.state.idUUID, l: 'IdUUID', id: 'idUUID', cc: 'p-col-1' },
                                { t: 'copy', text: this.state.referenceNumber, l: ls('referenceNumber'), id: 'referenceNumber', cc: 'p-col-2' },
                                <table key="t1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <img alt="Logo" src={config.PHOTO_PRODUCT_URL + this.state.data.pdfLogo} border="0" width="158" height="37" />
                                            </td>
                                            <td style={{ border: 'solid 2px black', fontSize: '20px', textAlign: 'center', fontWeight: 'bold', verticalAlign: 'bottom' }} colSpan="1">
                                                {this.state.data.bankId}
                                            </td>
                                            <td style={{ border: 'solid 2px black', fontSize: '20px', textAlign: 'right', fontWeight: 'bold', verticalAlign: 'bottom' }} colSpan="15">
                                                {this.state.data.mecanicLine}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="16">
                                                <p>{ls('paymentLocation')}</p>
                                                <p className="bold">{this.state.data.paymentLocation}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('dateDue')}</p>
                                                <p className="bold r">{g_getDateTemplate(this.state.data.dateDue, false, false, true)}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="16">
                                                <p>{ls('recipientName')}</p>
                                                <p className="bold">
                                                    {this.state.data.recipientName}/{this.state.data.recipientVatNumber}
                                                </p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('recipientBranch')}</p>
                                                <p className="bold r">
                                                    {this.state.data.recipientBranch}/{this.state.data.recipientAccount}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('dateCreationBoleto')}</p>
                                                <p className="bold">{g_getDateTemplate(this.state.data.dateCreation, false, false, true)}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('installmentNumberBoleto')}</p>
                                                <p className="bold">{this.state.data.installmentNumber}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('bankingBilletDocumentTypePrefix')}</p>
                                                <p className="bold">{this.state.data.bankingBilletDocumentTypePrefix}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="2">
                                                <p>{ls('aceite')}</p>
                                                <p className="bold">{this.state.data.aceite}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="2">
                                                <p>{ls('dateCreationBoleto')}</p>
                                                <p className="bold">{g_getDateTemplate(this.state.data.dateCreation, false, false, true)}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('bankingBilletNumber')}</p>
                                                <p className="bold r">
                                                    {this.state.data.bankingBilletNumber}-{this.state.data.bankingBilletNumberCD}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('useOfBank', 'text')}</p>
                                                <p></p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="2">
                                                <p>{ls('carteira')}</p>
                                                <p className="bold">{this.state.data.carteira}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="2">
                                                <p>{ls('especie')}</p>
                                                <p className="bold">{this.state.data.especie}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="6">
                                                <p>{ls('amount', 'text')}</p>
                                                <p> </p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="2">
                                                <p>{ls('value', 'text')}</p>
                                                <p> </p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('billetValueFirst')}</p>
                                                <p className="bold r">{this.state.data.billetValue}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black', verticalAlign: 'top' }} colSpan="16" rowSpan="4">
                                                {ls('bodyRecipientDescription')}
                                                <br />
                                                {this.state.data.bodyRecipientDescription}
                                                <br />
                                                {ls('bodyPayerDescription')}
                                                <br />
                                                {this.state.data.bodyPayerDescription}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4" rowSpan="1">
                                                <p>{ls('discountsBoleto')}</p>
                                                <p className="bold r">{this.state.data.discounts}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4" rowSpan="1">
                                                <p>{ls('otherDeductions')}</p>
                                                <p className="bold r">{this.state.data.otherDeductions}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4" rowSpan="1">
                                                <p>{ls('delayCosts')}</p>
                                                <p className="bold r">{this.state.data.delayCosts}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black' }} colSpan="16">
                                                <p>{ls('payerName')}</p>
                                                <p className="bold">{`${this.state.data.payerName}${this.state.data.payerName && this.state.data.payerVatNumber ? '/' : ''}${
                                                    this.state.data.payerVatNumber
                                                }`}</p>
                                                {this.state.data.payerAddressCity ||
                                                this.state.data.payerAddressState ||
                                                this.state.data.payerAddressStreet ||
                                                this.state.data.payerAddressStreetZone ||
                                                this.state.data.payerAddressZipcode ? (
                                                    <div>
                                                        {this.state.data.payerAddressStreet} <br />
                                                        {this.state.data.payerAddressStreetZone} <br />
                                                        {this.state.data.payerAddressCity} - {this.state.data.payerAddressState} <br />
                                                        {ls('zipCode')} {this.state.data.payerAddressZipcode}
                                                        <br />
                                                    </div>
                                                ) : null}
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('otherCredits')}</p>
                                                <p className="bold r">{this.state.data.otherCredits}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: 'solid 2px black', borderRight: '0px' }} colSpan="12">
                                                <p>{ls('payeeName')}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black', borderLeft: '0px' }} colSpan="4">
                                                <p className="bold">{`${this.state.data.payeeName}${this.state.data.payeeName && this.state.data.payeeVatNumber ? '/' : ''}${
                                                    this.state.data.payeeVatNumber
                                                }`}</p>
                                            </td>
                                            <td style={{ border: 'solid 2px black' }} colSpan="4">
                                                <p>{ls('billetValue')}</p>
                                                <p className="bold r">
                                                    {this.state.data.billetValue -
                                                        this.state.data.discounts -
                                                        this.state.data.otherDeductions +
                                                        this.state.data.otherCredits +
                                                        this.state.data.delayCosts}
                                                </p>
                                            </td>
                                        </tr>
                                        {this.state.data.barcode ? (
                                            <tr>
                                                <td style={{ border: 'solid 2px black' }} colSpan="20">
                                                    <Barcode value={this.state.data.barcode} format="ITF" />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>,

                                ...(this.state.data.smsMobilePhone || this.state.data.smsText
                                    ? [
                                          { t: 'sep', title: 'sms' },
                                          { t: 'array', dTypeF: 'view', origin: this.state.data, filter: ['smsMobilePhone', 'smsText'], cc: [10, 12] },
                                      ]
                                    : []),
                            ],
                            () => [],
                            () => [
                                [
                                    g_wraper(
                                        null,
                                        ls('status', 'generic'),
                                        <div className="p-col">
                                            <StateIcon
                                                state={this.state.data.bankingBilletStatus}
                                                small
                                                custom={{
                                                    0: { color: 'orange', class: 'pi-step-forward', tooltip: ls('inList') },
                                                    1: { color: 'grey', class: 'pi-spin pi-spinner', tooltip: ls('processing') },
                                                    2: { color: 'green', class: 'pi-sign-in', tooltip: ls('sentToBank') },
                                                    3: { color: 'red', class: 'pi-times', tooltip: ls('rejectedByBank') },
                                                    4: { color: 'green', class: 'pi-check', tooltip: ls('payed') },
                                                    5: { color: 'red', class: 'pi-clock', tooltip: ls('timeout') },
                                                    6: { color: 'red', class: 'pi-trash', tooltip: ls('deleted') },
                                                }}
                                            />
                                        </div>,
                                        !this.state.idUUID,
                                        'p-col-7'
                                    ),
                                    g_wraper('', ls('bankingBilletResponseCode'), <div className="p-col">{this.state.data.bankingBilletResponseCode}</div>, !this.state.idUUID, 'p-col-7'),
                                    g_wraper(null, ls('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col'),
                                    g_wraper(null, ls('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col'),
                                ],
                                [],
                                [
                                    <Steps
                                        model={[{ label: ls('0', 'boletoModel') }, { label: ls('1', 'boletoModel') }, { label: { label: ls('2', 'boletoModel') } }, { label: ls('3', 'boletoModel') }]}
                                        activeIndex={{ 0: 0, 1: 1, 2: 2, 4: 3 }[this.state.data.bankingBilletStatus]}
                                    />,
                                ],
                            ],
                            () => {},
                            2,
                            this.state.idUUID,
                            this.state.mode
                        ),
                        <Log idUUID={this.state.idUUID} logContextType={23} />,
                    ])}
                </div>
            );
        });
    }
}
