import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import React, { Component } from 'react';
import config from '../../config/config';
import ConsignatorService from '../../service/ConsignatorService';
import Attachment from '../attachment/Attachment';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import {
    S,
    LS,
    pair,
    StateIcon,
    g_ApiConsignator,
    g_template_dialog,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_input,
    g_template_inputArea,
    g_template_inputMask,
    g_template_textcopy,
    g_wraper,
    g_getDataFields,
    g_template_dropdown,
    g_genericRequired,
    g_hideSearch,
    g_getData,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_template_button,
    g_inputMaskTemplate,
    g_inputTemplate,
    g_getWraper,
} from '../GenericFunctions';
import Log from '../logs/Log';
import AssociationConsignatorUser from './AssociationConsignatorUser';
import BatchMoneyManagement from './BatchMoneyManagement';
import ClockingLocations from './ClockingLocations';

export const states = {
    1: { color: 'green', class: 'pi-check' },
    2: { color: 'red', class: 'pi-times' },
    3: { color: 'red', class: 'pi-ban' },
    4: { color: 'orange', class: 'pi-circle-on' },
    5: { color: 'red', class: 'pi-circle-on' },
};

export default class Consignator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: g_ApiConsignator(),
            items: [{ label: 'Empresa' }],
            holderphoto: null,
            idUUID: null,
            consignatorPullModes: [],
        };
    }

    updateStatusClick = e => S.consignator.processed.status(this, this.state.data.idUUID, this.state.data.providerStatus === 1 ? 2 : 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);
    askApproveStatus = e => S.consignator.processed.status(this, this.state.data.idUUID, 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);
    updateStatusClick = e =>
        S.consignator.processed.status(this, this.state.data.idUUID, this.state.data.consignatorStatus === 1 ? 2 : 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);
    blockStatus = e => S.consignator.processed.status(this, this.state.data.idUUID, 3, this.getData, LS('simpleStatus', 'messages'), this.messages, e);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        g_getDataFields(this, ConsignatorService.getPullModes, 'consignatorPullModes', 'description', 'id', 'consignatorPullModes');
        this.setState({ idUUID: idUUID, mode: false }, this.getData);
    }

    async getData() {
        if (!this.state.idUUID) return;
        let data = {
            pageSize: 1,
            pageNumber: 1,
            filters: [pair('idUUID', this.state.idUUID)],
        };
        let response = await ConsignatorService.listConsignator(data);
        if (g_displayMessageError(this.messages, response)) return;
        let items = [{ label: LS('consignator', 'tab') }];
        if (g_checkAlc(4, 'consignator#association')) items.push({ label: LS('backoffice', 'tab'), icon: 'pi pi-sitemap' });
        if (g_checkAlc(4, 'consignator#payrollbudgetmanagement')) items.push({ label: LS('payrollbudget', 'tab'), icon: 'pi pi-dollar' });
        if (g_checkAlc(4, 'consignator#clockinglocations')) items.push({ label: LS('clockinglocations', 'tab'), icon: 'pi pi-map-marker' });
        if (g_checkAlc(4, 'consignator#parameters')) items.push({ label: LS('parameters', 'tab'), icon: 'pi pi-cog' });
        items.push({ label: LS('attachments', 'tab'), icon: 'pi pi-paperclip' });
        items.push({ label: LS('log', 'tab'), icon: 'pi pi-list' });
        let rdata = response.consignators[0];
        rdata.vatNumber = rdata.vatNumber ? String(rdata.vatNumber).padStart(14, '0') : '';
        rdata.consignatorPullMode = String(rdata.consignatorPullMode);
        this.setState({ items: items, data: rdata });
    }

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) {
            this.setState({ holderphoto: formData });
            return;
        } else {
            this.upload(formData, data.documentTypeId, this.state.idUUID);
        }
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'sucess', summary: LS('simpleUploadStart', 'messages') });
        var response = await ConsignatorService.documentUpload(formData, typeid, idUUID);
        g_displayMessageError(this.messages, response);
    };

    onSubmit = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.phoneHome = data.phoneHome ? data.phoneHome.replace(/[^\d]/g, '') : '';
        data.vatnumber = data.vatnumber ? data.vatnumber.replace(/[^\d]/g, '') : '';
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, '') : '';
        if (this.state.idUUID !== null) {
            let response = await ConsignatorService.updateConsignator({ consignator: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let response = await ConsignatorService.createConsignator({ consignator: data });
            if (g_displayMessageError(this.messages, response)) return;
            if (this.state.holderphoto !== null) {
                await this.upload(this.state.holderphoto, 1, response.consignator.idUUID);
                this.setState({ holderphoto: null });
            }
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
            window.location.href = `/#/consignator?id=${response.consignator.idUUID}&type=1`;
            window.location.reload();
        }
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            let extra = !(g_checkAlc(4, 'extrainfo') && this.state.idUUID);
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-8 p-lg-8 p-fluid contact-form">
                                    <Panel>
                                        {g_template_textcopy(this, 'UUID', this.state.data.idUUID, 'idUUID', extra, 'p-col-2')}
                                        {g_template_textcopy(this, 'Id', this.state.data.id, 'id', extra, 'p-col-2')}
                                        {g_inputTemplate('name', this.state.data.name, LS('name'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col', 'p-col-2')}
                                        {g_inputTemplate('fantasyName', this.state.data.fantasyName, LS('fantasyName'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col', `p-col-2`)}

                                        {g_template_dropdown(
                                            this,
                                            'consignatorPullMode',
                                            this.state.data.consignatorPullMode,
                                            LS('consignatorPullMode'),
                                            this.state.consignatorPullModes,
                                            this.state.mode,
                                            false,
                                            true,
                                            'p-col-4',
                                            'p-col-2'
                                        )}
                                        <div className="p-grid p-fluid p-col-12" style={{ padding: '0px' }}>
                                            {g_wraper(
                                                `vatNumber`,
                                                LS(`vatNumberC`),
                                                g_template_inputMask(
                                                    this,
                                                    'vatNumber',
                                                    this.state.data.vatNumber,
                                                    null,
                                                    LS(`vatNumberC`, 'mask'),
                                                    true,
                                                    this.state.mode,
                                                    false,
                                                    'p-col-4',
                                                    '',
                                                    '',
                                                    LS(`vatNumberC`, 'placeHolder')
                                                ),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                            {g_wraper(
                                                `erpCode`,
                                                LS(`erpCode`),
                                                g_template_input(this, 'erpCode', this.state.data.erpCode, null, this.state.mode, false, true, 'p-col-4'),
                                                false,
                                                'p-col-2',
                                                true
                                            )}
                                        </div>
                                        {g_inputTemplate('email', this.state.data.email, 'Email', this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col-5', 'p-col-2', 'email')}
                                        <div className="p-grid p-fluid p-col-12" style={{ padding: '0px' }}>
                                            {g_inputMaskTemplate(
                                                `phoneMobile`,
                                                this.state.data.phoneMobile,
                                                LS(`phoneMobile`),
                                                this,
                                                LS(`phoneMobile`, 'mask'),
                                                LS(`phoneMobile`, 'placeholder'),
                                                false,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                '',
                                                'p-col-4',
                                                'p-col-2',
                                                ''
                                            )}
                                            {g_inputMaskTemplate(
                                                `phoneHome`,
                                                this.state.data.phoneHome,
                                                LS(`phoneHome`),
                                                this,
                                                LS(`phoneHome`, 'mask'),
                                                LS(`phoneHome`, 'placeholder'),
                                                false,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                '',
                                                'p-col-4',
                                                'p-col-2',
                                                ''
                                            )}
                                        </div>
                                        {g_template_input(this, 'shortDescription', this.state.data.shortDescription, LS('shortDescription'), this.state.mode, false, true, 'p-col-7', 'p-col-2')}
                                        {g_template_inputArea(this, 'longDescription', this.state.data.longDescription, LS('longDescription'), this.state.mode, false, true, 'p-col-7', 'p-col-2', '', {
                                            minHeight: '7em',
                                        })}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        <div style={{ marginLeft: '5em' }}>
                                            <GPhotoUpload
                                                disabled={this.state.mode}
                                                style={{ width: '80px', height: '80px' }}
                                                handlePhotoUpload={this.onUpload}
                                                photo={this.state.data.photo}
                                                documentTypeId={1}
                                                prefixPath={config.PHOTO_URL}
                                                alt={window.location.origin + '/assets/images/logotemplate.png'}
                                            />
                                        </div>
                                        {g_getWraper(
                                            null,
                                            LS('state'),
                                            <div className="p-col">
                                                <StateIcon custom={states} state={this.state.data.consignatorStatus} small />
                                            </div>,
                                            !this.state.idUUID,
                                            'p-col-7'
                                        )}
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), !this.state.idUUID, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), !this.state.idUUID, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', this.state.data.consignatorStatus === 3, null, this.state.mode)}
                                {g_template_dialog(
                                    this,
                                    LS('askApproveStatus', 'messages'),
                                    'askApproveStatus',
                                    this.askApproveStatus,
                                    LS('approve', 'btt'),
                                    !this.state.idUUID || ![4, 5].includes(this.state.data.providerStatus) || !g_checkAlc(5, 'provider#aprove'),
                                    this.state.mode,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    'p-col-2',
                                    'p-button-success'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askDenyApproveStatus', 'messages'),
                                    'askDenyApproveStatus',
                                    this.askDenyApproveStatus,
                                    LS('deny', 'btt'),
                                    !this.state.idUUID || this.state.data.providerStatus !== 4 || !g_checkAlc(5, 'provider#aprove'),
                                    this.state.mode,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    'p-col-2',
                                    'p-button-warning'
                                )}
                                {g_template_dialog(
                                    this,
                                    LS(`ask${this.state.data.consignatorStatus === 1 ? 'Deactivate' : 'Activate'}`, 'messages'),
                                    'status',
                                    this.updateStatusClick,
                                    LS(this.state.data.consignatorStatus === 1 ? 'deactivate' : 'activate', 'btt'),
                                    !this.state.idUUID || this.state.data.consignatorStatus === 3,
                                    this.state.mode
                                )}
                                {g_template_dialog(
                                    this,
                                    LS('askBlockP', 'messages'),
                                    'block',
                                    this.blockStatus,
                                    LS('block', 'btt'),
                                    !this.state.idUUID || this.state.data.consignatorStatus === 3,
                                    this.state.mode
                                )}
                            </div>
                        </form>,
                        ...(g_checkAlc(4, 'consignator#association') ? [<AssociationConsignatorUser idUUID={this.state.idUUID} />] : []),
                        ...(g_checkAlc(4, 'consignator#payrollbudgetmanagement') ? [<BatchMoneyManagement context={this} />] : []),
                        ...(g_checkAlc(4, 'consignator#clockinglocations') ? [<ClockingLocations context={this} />] : []),
                        ...(g_checkAlc(4, 'consignator#parameters') ? [<Parameters context={this} />] : []),
                        <Attachment idUUID={this.state.idUUID} documentContextType={3} service={'consignator'} />,
                        <Log idUUID={this.state.idUUID} logContextType={3} />,
                    ])}
                </div>
            );
        });
    }
}

class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...g_genericRequired(),
            name: '',
            parameterValue: '',
        };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, ConsignatorService.parameterList, 'parameters', { filters: [...this.state.filter, pair('referenceIdUUID', this.props.context.state.idUUID)] })
            : null;

    save = async () => {
        if (!this.props.context.state.idUUID || !this.state.name || !this.state.parameterValue) return;
        const response = await ConsignatorService.parameterPut({
            parameter: {
                logContextType: 0,
                name: this.state.name,
                parameterValue: this.state.parameterValue,
                referenceIdUUID: this.props.context.state.idUUID,
            },
        });
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({ name: '', parameterValue: '', changeDialog: false }, this.getData);
    };

    render() {
        return (
            <div className="p-col">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ changeDialog: true }), this.getData, this.props.context.state.idUUID),
                    [
                        { type: 'd', data: 'name' },
                        { type: 'd', data: 'parameterValue' },
                        { type: 'button', icon: 'pi pi-pencil', click: raw => this.setState({ name: raw.name, parameterValue: raw.parameterValue, changeDialog: true }) },
                    ],
                    this.getData
                )}
                <Dialog
                    visible={this.state.changeDialog}
                    footer={
                        <Button
                            label={LS('save', 'btt')}
                            onClick={e => {
                                e.preventDefault();
                                this.save();
                            }}
                        />
                    }
                    onHide={() => this.setState({ changeDialog: false })}
                >
                    <div className="card p-grid p-fluid contact-form" style={{ height: '50vh', width: '50vw' }}>
                        <div className="p-fluid" style={{ height: '50vh', width: '50vw' }}>
                            {g_template_input(this, 'name', this.state.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-4', '', { data: 'name' })}
                            {g_template_inputArea(
                                this,
                                'parameterValue',
                                this.state.parameterValue,
                                LS('parameterValue'),
                                this.state.mode,
                                false,
                                true,
                                'p-col-12',
                                'p-col-4',
                                '',
                                { minHeight: '5em' },
                                { data: 'parameterValue' }
                            )}
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
