/**
 *
 *  ACL used in this page are
 *  To access this page:
 *
 *  - full-history-page
 *
 *
 */
import React from 'react';
import { CallRecord, CallRecordListFilters } from '../../../api-ts-bindings';
import { Table, TableHeader, useAcl } from '../../../Generics';
import { Requests } from '../../../Requests';
import { ls } from '../../generic/LabelSelector';
import { states as CPstates } from '../contract-protector/ContractP';

export const FullHistoryList = () => {
	const { error, profile } = useAcl({});

	const formatTime = (sec: number) => {
		sec = Math.round(sec);
		let h = Math.floor(sec / 60 / 60);
		if (h > 0) sec = sec - h * 60 * 60;
		let min = Math.floor(sec / 60);
		if (min > 0) sec = sec - min * 60;
		return `${h > 0 ? h + ':' : ''}${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
	};

	return (
		error ?? (
			<div>
				<Table<CallRecord, CallRecordListFilters, string, 'callRecords'>
					header={<TableHeader />}
					result="callRecords"
					request={data => {
						return Requests.call.list(data);
					}}
					children={[
						{ t: 'd', data: 'originName', filter: true, sort: true },
						{ t: 'd', data: 'destinationName', filter: true, sort: true },
						{ t: 'date', data: 'dateCreation', filter: true, sort: true },
						{ t: 'date', data: 'dateStartCall', filter: true, sort: true },
						{ t: 'date', data: 'dateEndCall', filter: true, sort: true },
						{
							t: 'di',
							data: 'duration',
							di: raw =>
								raw.dateStartCall && raw.dateEndCall
									? formatTime(
											(new Date(raw.dateEndCall).getTime() -
												new Date(raw.dateStartCall).getTime()) /
												1000
									  )
									: '',
						},
						{
							t: 'di',
							data: 'waitTime',
							di: raw =>
								raw.dateStartQueueWait && raw.dateEndQueueWait
									? formatTime(
											(new Date(raw.dateEndQueueWait).getTime() -
												new Date(raw.dateStartQueueWait).getTime()) /
												1000
									  )
									: '',
						},
						{ t: 'd', title: ls('state', 'dataTableGeneric'), data: 'callRecordStatusDescription' },
						{
							t: 'state',
							title: ls('contractState', 'dataTableGeneric'),
							data: 'contractStatus',
							states: () => CPstates,
						},
						{
							t: 'url',
							icon: 'pi pi-file-o',
							title: ls('docs', 'dataTableGeneric'),
							class: '',
							url: raw => `#/vandocs?type=1&id=${raw.contractIdUUID}`,
							show: profile!.ub || profile!.db,
						},
						{
							t: 'url',
							icon: 'pi pi-file-o',
							title: ls('medicalRecord'),
							class: '',
							url: raw => `#/oldCall?type=1&id=${raw.contractIdUUID}`,
							show: profile!.ub || profile!.db,
						},
					]}
				/>
			</div>
		)
	);
};
