import React, { Component } from 'react';
import { LS, g_genericRequired, g_checkLoginACL, g_getHeaderTemplate, g_hideSearch, g_template_dataTable, g_getData } from '../GenericFunctions';
import ConsignatorService from '../../service/ConsignatorService';

export default class BatchMoneyManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, ConsignatorService.payrollBudgetList, 'payrollBudgets', { filters: [{ key: 'consignatorIdUUID', value: this.props.context.state.idUUID }] })
            : null;

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(this, '#/payrollbudget?type=1&cid=' + this.props.context.state.idUUID, 'createNewPayrollbudget', this.getData, 1000, 450, e.c === 1),
                        [
                            { type: 'd', data: 'productName' },
                            { type: 'd', data: 'code', label: LS('verba') },
                            { type: 'd', data: 'description' },
                            { type: 'd', data: 'movementLayout' },
                            {
                                type: 'state',
                                data: 'batchServiceStatus',
                                states: {
                                    1: { color: 'green', class: 'pi-check' },
                                    2: { color: 'red', class: 'pi-times' },
                                    3: { color: 'red', class: 'pi-ban' },
                                },
                            },
                            {
                                type: 'url',
                                c: e.d === 1 || e.u === 1,
                                url: raw => `#/payrollbudget?type=1&id=${raw.idUUID}&cid=${this.props.context.idUUID}`,
                                width: 1000,
                                height: 450,
                                callback: this.getData,
                            },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'consignator#payrollbudgetmanagement'
        );
    }
}
