import type { GenericListResponseG } from '../../Generic';
import type {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../Requests';
import type {
	SLAEvolutionTicketModel,
	SLAModel,
	SLAPersonalTicketModel,
	SLATicketModel,
	SLATotalTicketModel
} from '../SLATicket';

export enum SLAPeriodType {
	this_week = 0,
	this_month = 1,
	this_3_month = 2,
	this_6_month = 3,
	this_year = 4,
	this_other = 5
}

export type FilterDefault = '';
export type OrderByDefault = '';

export type FilterSLA = 'state' | 'notInState';

export type Filters =
	| 'instate'
	| 'notinstate'
	| 'categorytype'
	| FilterDateType<'datecreation'>;

export type OrderBy = '' | 'datecreation';

export const listgroupstate =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'slaStateGroups', SLATicketModel> =>
		req.axios.post(`${baseUrl}/grouping/state/list`, data);

export const listgroupcategory =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'slaCategoryTypeGroupeds', SLATicketModel> =>
		req.axios.post(`${baseUrl}/grouping/category-type/list`, data);

export const listevolutions =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'slaEvolutions', SLAEvolutionTicketModel> =>
		req.axios.post(`${baseUrl}/evolution/list`, data);

export const getdashboardpersonal =
	(req: RequestsClass, baseUrl: string) =>
	(type: number): Promise<SLAPersonalTicketModel> =>
		req.axios.get(`${baseUrl}/dashboard/personal/${type}`);

export const getdashboardtotal =
	(req: RequestsClass, baseUrl: string) => (): Promise<SLATotalTicketModel> =>
		req.axios.get(`${baseUrl}/dashboard/totals`);

export const getdashboardtotalcategory =
	(req: RequestsClass, baseUrl: string) =>
	(categoryId: number): Promise<SLATotalTicketModel> =>
		req.axios.get(`${baseUrl}/dashboard/totals/${categoryId}`);

export const getslalist =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<FilterSLA, OrderByDefault>
	): GenericListResponseG<'slas', SLAModel> =>
		req.axios.post(`${baseUrl}/list`, data);
