import type { Schedule, ProviderModel, ParameterModel } from '..';

export interface SearchAreaCountersModel {
	counter: number;
	description: string;
	idUUID: string;
	imageUrlMain: string;
	imageUrlTeaser: string;
	showOrder: number;
}
export interface EntitySearchAreaServiceTypeProductModel {
	contractTemplateIdUUID: string;
	description: string;
	entitySearchAreaServiceTypeIdUUID: string;
	idUUID: string;
	productIdUUID: string;
	relationType: EntitySearchAreaServiceTypeProductRelationType;
	workflowType: number;
}

export enum EntitySearchAreaServiceTypeProductRelationType {
	product = 1,
	campain = 2
}

export interface SearchAreaServiceTypeModel {
	description: string;
	entitySearchAreaServiceTypeProducts: EntitySearchAreaServiceTypeProductModel[];
	id: number;
	idUUID: string;
	referenceIdUUID: string;
}

export type SearchAreaServiceTypeListFilters = 'loadContractTemplates';

export interface SearchItemModel<hasParameters extends boolean = false> {
	distance: number;
	provider: ProviderModel;
	parameters: hasParameters extends false ? never : ParameterModel[];
	searchAreaServiceTypes: SearchAreaServiceTypeModel[];
	schedulings: Schedule[];
}

export interface SearchAreaTypeModel {
	description: string;
	id: number;
	languageTag: string;
}

export interface SearchAreaModel {
	consignatorIdUUID: string;
	consignatorName: string;
	creationUserIdUUID: string;
	dateCreation: string;
	dateEnd: string;
	dateStart: string;
	dateUpdate: string;
	description: string;
	featuringMode: number;
	htmlMainContent: string;
	htmlTeaserContent: string;
	idUUID: string;
	imageUrlMain: string;
	imageUrlTeaser: string;
	price: number;
	referenceDescription: string;
	referenceIdUUID: string;
	searchAreaCategory: number;
	searchAreaStatus: number;
	searchAreaType: number;
	showOnApp: number;
	showOnSite: number;
	showOrder: number;
	totalClicksOnApp: number;
	totalClicksOnSite: number;
}

export enum SearchAreaStatus {
	CREATING = 1,
	PENDING_APPROVAL = 2,
	PENDING_PAYMENT = 3,
	APPROVED = 4,
	RUNNING = 5,
	FINALIZED = 6,
	DELETED = 100,
	CANCELED = 101,
	PAYMENT_REJECT = 102,
	APPROVAL_REJECT = 103
}

export type SearchAreaListFilters = 'searchsstatus' | 'consignatorsIdUUID';
export type SearchAreaListOrders = 'showOrder';
