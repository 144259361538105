import type { ParameterModel } from '../Parameter';

export * from './ClockingLocation';

export interface ConsignatorModel {
	consignatorPullMode: number;
	consignatorStatus: number;
	consignatorType: number;
	dateCreation: string;
	dateUpdate: string;
	email: string;
	entityId: number;
	entityType: number;
	erpCode: string;
	fantasyName: string;
	id: number;
	idUUID: string;
	longDescription: string;
	name: string;
	parameters: ParameterModel[];
	phoneHome: string;
	phoneMobile: string;
	photo: string;
	shortDescription: string;
	vatNumber: string;
}
