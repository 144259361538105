import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import { S, LabelSelector as LS, g_checkLoginACL, g_entityFilter, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';
import * as UA from './UserApp';

export default class UserManagementApp extends Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () => {
        let f = g_entityFilter(this.state.filter, 'cosignatorIdUUID', `consignatorsIdUUId`, 5, 'admin-user-management-app#acessall');
        if (!f) return;
        g_getData(this, S.user.list, 'users', { userType: 0, filters: f[0] });
    };

    render() {
        return g_checkLoginACL(e => {
            let states = { 0: { color: 'grey', class: 'pi-times' }, 1: { color: 'grey', class: 'pi-check' } };
            return (
                <div className="card">
                    <h1>{LS('userapp', 'titles')}</h1>
                    <Messages ref={el => (this.messages = el)} />
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(this, '#/user-app', 'novoAppUser', this.getData, 1300, 600, e.c === 1),
                        [
                            { type: 'd', data: 'fullname', sortable: true, filter: true },
                            { type: 'd', data: 'username', sortable: true, filter: true },
                            { type: 'd', data: 'email', sortable: true, filter: true },
                            { type: 'd', data: 'phoneMobile', sortable: true, filter: true },
                            { type: 'd', data: 'deviceBrand', sortable: true, filter: true },
                            { type: 'd', align: 'right', data: 'appVersion', sortable: true, filter: true },
                            { type: 'date', data: 'dateLastLogin', sortable: true, filter: true },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                            { type: 'state', data: 'mobileEnrollmentStatus', states: states },
                            { type: 'state', data: 'legalAcceptanceStatus', states: states },
                            { type: 'state', data: 'userStatus', states: UA.states },
                            { type: 'url', c: e.u === 1 || e.d === 1, url: raw => `#/user-app?type=1&id=${raw.idUUID}`, width: 1300, height: 600, callback: this.getData },
                        ],
                        this.getData,
                        { 0: 'fullname', 1: 'username', 2: 'email', 3: 'phoneMobile', 4: 'deviceBrand', 6: 'dateCreation', 5: 'dateLastLogin' },
                        [5, 6],
                        {},
                        { 3: '\\d*' }
                    )}
                </div>
            );
        });
    }
}
