import {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../../Requests';
import { ReportModel } from '../Reports';

export type Filters =
	| FilterDateType<'dateCreation'>
	| 'category'
	| 'incidentState'
	| 'closeState';

export type OrderBy = '';

export const analytic =
	(req: RequestsClass, baseUrl: string) =>
	(data: GenericListRequest<Filters, OrderBy>): Promise<ReportModel> =>
		req.axios.put(`${baseUrl}/analytic`, data);
