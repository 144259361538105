import React, { Component } from 'react';
import { pair, LS, g_checkLoginACL, g_template_dataTable, g_getHeaderTemplate, g_genericRequired, g_getData, g_hideSearch } from '../GenericFunctions';
import ConsignatorService from '../../service/ConsignatorService';

export default class ClockingLocations extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }

    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(
                  this,
                  ConsignatorService.clockingList,
                  response => this.setState({ data: response.clockingLocations }),
                  { filters: [...this.state.filter, pair('consignatorIdUUID', this.props.context.state.idUUID)] },
                  this.props.context.messages
              )
            : null;

    addToBody = raw => {
        return (
            <div>
                <p>
                    {raw.address.street}
                    {raw.address.port ? ',' + raw.address.port : ''}
                </p>
                <p>
                    {raw.address.streetZone ? raw.address.streetZone + ',' : ''}
                    {`${raw.address.city},${raw.address.state}`}
                </p>
            </div>
        );
    };

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplate(
                            this,
                            '#/clockinglocation?type=1&cid=' + this.props.context.state.idUUID,
                            'createNewClockingLocation',
                            this.getData,
                            1000,
                            550,
                            e.c === 1
                        ),
                        [
                            { type: 'd', data: 'description', filter: true },
                            { type: 'di', title: LS('address', 'generic'), di: this.addToBody },
                            { type: 'date', data: 'dateCreation' },
                            {
                                type: 'state',
                                states: {
                                    1: { color: 'green', class: 'pi-check' },
                                    2: { color: 'red', class: 'pi-times' },
                                    3: { color: 'red', class: 'pi-ban' },
                                },
                            },
                            { type: 'url', c: e.d === 1 || e.u === 1, url: raw => `#/clockinglocation?type=1&id=${raw.idUUID}`, width: 1000, height: 700, callback: this.getData },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'consignator#clockinglocations'
        );
    }
}
