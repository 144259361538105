import { Messages } from 'primereact/messages';
import React from 'react';
import Config from '../../../config/config';
import { Requests } from '../../../Requests';
import ConsigneeService from '../../../service/ConsigneeService';
import ContractService from '../../../service/ContractService';
import { checkLoginACL } from '../../Generic';
import {
    g_entities,
    g_genericRequired,
    g_getData,
    g_getHeaderTemplate,
    g_hideSearch,
    g_pair,
    g_template_button,
    g_template_dataTable,
    LabelSelector,
    lb,
    ls,
    S,
} from '../../GenericFunctions';
import VideoCall from './videocall';

export default class Callcenter extends React.Component {
    constructor() {
        super();
        this.state = {
            products: [],
            acceptsList: {},
            noServices: true,
            infocardslkip: '',
            accepts: true,
            connectedFtime: false,
            windowopen: null,
            storedInfo: null,
            consignee: {},
            inCall: false,
            gotInfo: false,
            heartbeatdataList: {},
            heartbeatdata: {
                agentAnswerQueueOnCall: 0,
                agentAnswerQueueOnWait: 0,
                clientWaitingQueueSize: 0,
            },
            p: [],
        };
    }

    async componentDidMount() {
        this.setState({ onComponent: true });
        if (g_entities() === '') return;
        g_getData(
            this,
            Requests.provider.searchAreaServiceType.list,
            r => {
                this.setState({ gotInfo: true, providerIdUUID: r.providerIdUUID });
                r = r.searchAreaServiceTypes
                    .filter(a => a.entitySearchAreaServiceTypeProducts.filter(b => b.workflowType === 6 || b.relationType === 2).length !== 0)
                    .map(a => ({ ...a, items: a.entitySearchAreaServiceTypeProducts.filter(b => b.workflowType === 6 || b.relationType === 2) }));
                let toSave = [];
                for (let a of r) {
                    for (let item of a.items) {
                        toSave.push({ ...item, parent: { ...a, items: null } });
                    }
                }
                if (toSave.length === 0) return;
                this.connectToListening();
                this.setState({ noServices: false, products: r, p: toSave });
            },
            { providerIdUUID: g_entities(), filters: [g_pair('loadcontracttemplates', 1)] },
            this.messages
        );
        window.onclose = this.closeWindow;
    }

    connectToListening = () => {
        if (!window.stompConnect) {
            if (this.timeoutconnect) clearTimeout(this.timeoutconnect);
            this.timeoutconnect = setTimeout(this.connectToListening, 1000);
            return;
        }
        if (this.timeoutconnect) clearTimeout(this.timeoutconnect);
        this.timeoutconnect = null;
        let uuid = JSON.parse(localStorage.getItem('loggedUserN')).uuid;
        window.stompC.subscribe('/user/' + uuid + '/queue/messages', this.reciveQueue);
        let individual = false;
        this.state.p
            .map((a, i) => [a, i])
            .filter((_, i) => this.state.acceptsList[i])
            .forEach(p0 => {
                let [p] = p0;
                individual = true;
                const message = {
                    type: 'REGISTER',
                    behaviourIdUUID: p.relationType === 1 ? p.entitySearchAreaServiceTypeIdUUID : p.parent.id + '|' + p.idUUID,
                    behaviour: p.relationType,
                };
                window.stompC.send('/app/handshake', {}, JSON.stringify(message));
            });
        this.setState({ individual: individual });
        if (!individual) window.stompC.send('/app/handshake', {}, JSON.stringify({ type: 'REGISTER' }));
        g_getData(
            this,
            () => ContractService.getTemplateByTag(`BASEMEDICALFORM${process.env.REACT_APP_PORT === '4100' ? '-DRMOZ' : ''}`, true),
            response => localStorage.setItem('BASEMEDICALFORM', JSON.stringify(response.contractTemplate))
        );
    };

    componentWillUnmount() {
        this.closeWindow();
    }

    closeWindow = () => {
        localStorage.setItem('oncall', false);
        if (this.skipcalltimeout) {
            this.skipcall();
            clearTimeout(this.skipcalltimeout);
            this.skipcalltimeout = null;
        } else {
            this.endcall();
        }
        if (this.timeoutconnect) clearTimeout(this.timeoutconnect);
        if (this.getInfoTimeout) clearTimeout(this.getInfoTimeout);
        if (!window.topicsConected) return;
        if (!JSON.parse(localStorage.getItem('loggedUserN'))) return;
        let uuid = JSON.parse(localStorage.getItem('loggedUserN')).uuid;
        if (!this.state.individual) window.stompC.send('/app/handshake', {}, JSON.stringify({ type: 'UNREGISTER' }));
        if (this.state.p)
            this.state.p
                .map((a, i) => [a, i])
                .filter((_, i) => this.state.acceptsList[i])
                .forEach(pO => {
                    let [p] = pO;
                    const message = {
                        type: 'UNREGISTER',
                        behaviourIdUUID: p.relationType === 1 ? p.parent.idUUID : p.contractTemplateIdUUID + '|' + p.parent.id,
                        behaviour: p.relationType,
                    };
                    window.stompC.send('/app/handshake', {}, JSON.stringify(message));
                });
        window.stompC.unsubscribe('/user/' + uuid + '/queue/messages', {});
        this.reciveQueue = () => {};
        this.setState({ accepts: false });
    };

    pause = () => {
        if (!this.state.individual) window.stompC.send('/app/handshake', {}, JSON.stringify({ type: 'UNREGISTER' }));
        this.state.p
            .map((a, i) => [a, i])
            .filter((_, i) => this.state.acceptsList[i])
            .forEach(po => {
                let [p] = po;
                const message = {
                    type: 'UNREGISTER',
                    behaviourIdUUID: p.relationType === 1 ? p.parent.idUUID : p.contractTemplateIdUUID + '|' + p.parent.id,
                    behaviour: p.relationType,
                };
                window.stompC.send('/app/handshake', {}, JSON.stringify(message));
            });
        this.setState({ accepts: false });
        this.skipcall();
    };

    endcall = (close, alreadyClosed) => {
        if (!this.state.storedInfo) return;
        const message = {
            type: 'END-CALL',
            recipient: this.state.storedInfo.recipient,
            callIdUUID: this.state.storedInfo.callIdUUID,
            behaviour: close ? 0 : alreadyClosed ? 2 : 1,
        };
        try {
            window.stompC.send('/app/handshake', {}, JSON.stringify(message));
        } catch (_) {}
        if (!close && !alreadyClosed) return;
        //if (this.state.storedInfo.referenceIdUUID) ContractService.updateStatus({ contractStatus: 100, idUUID: this.state.storedInfo.referenceIdUUID });
        this.setState({ consignee: { fullname: '', photo: '' } });
        this.setState({ storedInfo: null, ringing: false, inCall: false, handshake: null });
        if (this.skipcalltimeout) {
            clearTimeout(this.skipcalltimeout);
            this.skipcalltimeout = null;
        }
        if (!this.props.menuOpen()) {
            this.props.onToggleMenu();
        }
        this.props.setBtts(true);
    };

    checkOk = () => window.stompC.send('/app/handshake', {}, JSON.stringify({
        type: 'INIT-CALL-CHECK-OK',
    }));

    skipcall = () => {
        if (!this.state.storedInfo) return;
        let message = this.state.storedInfo;
        message.type = 'SKIP-CALL';
        if (window.stompC?.send) window.stompC.send('/app/handshake', {}, JSON.stringify(message));
        this.setState({ storedInfo: null, ringing: false, inCall: false, consignee: { fullname: '', photo: '' } });
        if (this.skipcalltimeout) {
            clearTimeout(this.skipcalltimeout);
            this.skipcalltimeout = null;
        }
    };

    skipcalltimeoutf = (i = 60) => {
        if (this.skipcalltimeout) clearTimeout(this.skipcalltimeout);
        if (i <= 0) {
            this.skipcall();
            return;
        }
        this.setState({ infocardslkip: i });
        this.skipcalltimeout = setTimeout(() => this.skipcalltimeoutf(i - 1), 1000);
    };

    getUtenteData = cId =>
        g_getData(
            this,
            () => ContractService.getContract(cId),
            response => {
                if (response.contract)
                    g_getData(
                        this,
                        () => ConsigneeService.get(response.contract.consigneeIdUUID, 0, 0),
                        response => {
                            if (response.consignee) {
                                this.setState({ consignee: { photo: response.consignee.photo, fullname: response.consignee.fullname } });
                            }
                        }
                    );
            }
        );

    reciveQueue = e => {
        if (!this.state.accepts) return;
        let b = null;
        try {
            b = JSON.parse(e.body);
        } catch (_) {
            return;
        }
        if (!b) return;
        if (b.handshake?.type === 'REGISTER-OK') {
            this.setState({ connectedFtime: true, heartbeatdata: JSON.parse(b.handshake.value) });
            if (!this.getInfoTimeout) this.getInfoTimeout = setTimeout(this.getInfoTimeoutFunction, 30000);
        } else if (b.handshake?.type === 'INIT-CALL') {
            this.setState({ storedInfo: b.handshake, ringing: true });
            this.getUtenteData(b.handshake.referenceIdUUID);
            this.skipcalltimeoutf();
        } else if (b.handshake?.type === 'STS-INFO-OK') {
            let data = JSON.parse(b.handshake.value);
            if (this.restatTimeout) clearTimeout(this.restatTimeout);
            this.setState({ heartbeatdata: data });
        } else if (b.handshake?.type === 'INIT-CALL-CHECK' && !this.state.inCall) {
            this.checkOk();
        } else {
            if (b.handshake?.type === 'HANG-UP' && !this.state.inCall) {
                this.endcall();
            }
            if (this.videoCall) {
                this.videoCall.wsEvent(b.handshake);
            } else {
                this.setState({ handshake: b.handshake });
            }
        }
    };

    accepts = () => {
        if (this.skipcalltimeout) {
            clearTimeout(this.skipcalltimeout);
            this.skipcalltimeout = null;
        }
        this.setState({ inCall: true });
        this.props.onToggleMenu();
        this.props.setBtts(false);
    };

    getInfoTimeout = null;
    getInfoTimeoutFunction = () => {
        if (this.getInfoTimeout) clearTimeout(this.getInfoTimeout);
        if (!window.stompC) {
            this.getInfoTimeout = setTimeout(this.getInfoTimeoutFunction, 30000);
            return;
        }
        const message = { type: 'STS-INFO', recipient: '', value: '' };
        try {
            window.stompC.send('/app/handshake', {}, JSON.stringify(message));
        } catch (_) {}
        this.getInfoTimeout = setTimeout(this.getInfoTimeoutFunction, this.accepts ? 30000 : 60000);
        if (this.accepts) {
            this.restatTimeout = setTimeout(() => {
                window.location.reload();
                this.restatTimeout = undefined;
            }, 15000);
        }
    };

    activivate = () => {
        if (this.state.accepts) return;
        this.setState({ accepts: true });
        if (!this.state.individual) window.stompC.send('/app/handshake', {}, JSON.stringify({ type: 'REGISTER' }));
        this.state.p
            .map((a, i) => [a, i])
            .filter((_, i) => this.state.acceptsList[i])
            .forEach(po => {
                let [p] = po;
                const message = {
                    type: 'REGISTER',
                    behaviourIdUUID: p.relationType === 1 ? p.parent.idUUID : p.contractTemplateIdUUID + '|' + p.parent.id,
                    behaviour: p.relationType,
                };
                window.stompC.send('/app/handshake', {}, JSON.stringify(message));
            });
    };

    connect = i => {
        if (this.state.ringing) return;
        let a = this.state.acceptsList;
        a[i] = true;
        this.setState({ acceptsList: a });
        let p = this.state.p[i];
        const message = {
            type: 'REGISTER',
            behaviourIdUUID: p.relationType === 1 ? p.parent.idUUID : p.contractTemplateIdUUID + '|' + p.parent.id,
            behaviour: p.relationType,
        };
        if (this.state.accepts) window.stompC.send('/app/handshake', {}, JSON.stringify(message));
    };

    disconect = i => {
        if (this.state.ringing) return;
        let a = this.state.acceptsList;
        a[i] = false;
        this.setState({ acceptsList: a });
        let p = this.state.p[i];
        const message = {
            type: 'UNREGISTER',
            behaviourIdUUID: p.relationType === 1 ? p.parent.idUUID : p.contractTemplateIdUUID + '|' + p.parent.id,
            behaviour: p.relationType,
        };
        window.stompC.send('/app/handshake', {}, JSON.stringify(message));
    };

    videoCall;

    render() {
        let { error } = checkLoginACL({});

        if (error) {
            this.closeWindow();
            return error;
        }

        if (!this.state.gotInfo)
            return (
                <div style={{ position: 'relative', color: 'grey', top: '40%', width: '100%', height: '90vh', textAlign: 'center' }}>
                    <span style={{ fontSize: '2.5em', position: 'relative' }}>{LabelSelector('gotInfo', 'callcenter')}</span>
                    <br />
                    <span className="pi pi-spin pi-spinner" style={{ fontSize: '2.5em', position: 'relative' }} />
                </div>
            );
        if (this.state.noServices)
            return (
                <div style={{ position: 'relative', color: 'grey', top: '40%', width: '100%', height: '90vh', textAlign: 'center' }}>
                    <span style={{ fontSize: '2.5em', position: 'relative' }}>{LabelSelector('noServices', 'callcenter')}</span>
                    <br />
                </div>
            );
        if (!window.stompConnect || !this.state.connectedFtime)
            return (
                <div style={{ position: 'relative', color: 'grey', top: '40%', width: '100%', height: '90vh', textAlign: 'center' }}>
                    <span style={{ fontSize: '2.5em', position: 'relative' }}>A conectar com o serviços teleassistencia Protetor</span>
                    <br />
                    <span className="pi pi-spin pi-spinner" style={{ fontSize: '2.5em', position: 'relative' }} />
                </div>
            );

        //VIDEO CALL
        //TODO: ERROR MESSAGE
        if (this.state.inCall)
            return (
                <VideoCall
                    ref={e => {
                        this.videoCall = e;
                        if (this.state.handshake) {
                            e.wsEvent(this.state.handshake);
                            this.setState({ handshake: null });
                        }
                    }}
                    close={this.endcall}
                    invalidCall={this.endcall}
                    storedInfo={this.state.storedInfo}
                />
            );

        this.videoCall = null;

        //CALLCENTER

        let photo = (
            <img
                style={{ width: '105px', height: '105px' }}
                src={this.state.consignee.photo ? Config.PHOTO_URL + this.state.consignee.photo : window.location.origin + '/assets/images/person.png'}
                alt=""
            />
        );

        let boxes = this.state.p
            .map((a, i) => {
                let title = a.parent.description;
                if ([1, 2].includes(a.relationType)) {
                    title += ' - ' + a.description;
                }
                return (
                    <div
                        style={{ border: '1px solid #DDD7D7', borderRadius: '10px', marginBottom: '20px', marginLeft: '20px', minHeight: '20vh' }}
                        className={'p-col-3 p-fluid p-grid p-card'}
                    >
                        {g_template_button(title, '', false, _ => this.connect(i), false, 'p-col-12', 'p-button-warning')}
                        <div className="p-col-12" style={{ textAlign: 'center', color: 'grey', fontSize: '1.5em' }}>
                            {LabelSelector('clickConnect', 'callcenter')}
                        </div>
                    </div>
                );
            })
            .filter((_, i) => !this.state.acceptsList[i]);

        let waiting = this.state.p
            .map((a, i) => {
                return (
                    <div
                        style={{ border: '1px solid #DDD7D7', borderRadius: '10px', marginBottom: '20px', marginLeft: '20px', minHeight: '20vh' }}
                        className={'p-col-3 p-fluid p-grid p-card'}
                    >
                        {g_template_button(
                            a.parent.description + (a.relationType === 2 ? ' - ' + a.description : ''),
                            '',
                            false,
                            _ => this.disconect(i),
                            false,
                            'p-col-12',
                            'p-button-success'
                        )}
                        <div className="p-col-8">Utentes Espera</div>
                        <div className="p-col-3">{this.state.heartbeatdataList[i]?.clientWaitingQueueSize ?? 0}</div>
                        <div className="p-col-8">Atendentes em chamada</div>
                        <div className="p-col-3">{this.state.heartbeatdata[i]?.agentAnswerQueueOnCall ?? 0}</div>
                        <div className="p-col-8">Atendentes em Espera</div>
                        <div className="p-col-3">{this.state.heartbeatdata[i]?.agentAnswerQueueOnWait ?? 0}</div>
                    </div>
                );
            })
            .filter((_, i) => this.state.acceptsList[i]);

        return (
            <>
                <Messages ref={e => (this.messages = e)} />
                <div className="p-grid p-fluid contact-form p-col">
                    {this.state.accepts ? (
                        <>
                            <div className="p-col-2"></div>
                            <div className="p-col-8" style={{ border: '1px solid #DDD7D7', borderRadius: '10px', minHeight: '20vh' }}>
                                {this.state.ringing ? (
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-5"></div>
                                        <div style={{ textAlign: 'center' }} className="p-col-2">
                                            {photo}
                                        </div>
                                        <div className="p-col-5"></div>
                                        <div className="p-col-5"></div>
                                        <div className="p-col-2" style={{ textAlign: 'center' }}>
                                            {this.state.consignee.fullname}
                                        </div>
                                        <div className="p-col-5"></div>
                                        <div className="p-col-4"></div>
                                        {g_template_button('', 'pi pi-video', 0, this.endcall, 0, 'p-col-1', 'p-button-danger', lb('denyCall'), {
                                            fontSize: '2.5em',
                                            width: '100%',
                                        })}
                                        {g_template_button('', 'pi pi-video', 0, this.accepts, 0, 'p-col-2', 'p-button-success', lb('acceptCall'), {
                                            fontSize: '2.5em',
                                            width: '100%',
                                        })}
                                        {g_template_button(this.state.infocardslkip, '', 0, this.skipcall, 0, 'p-col-1', 'p-button-warning', lb('skipCall'), {
                                            fontSize: '1.5em',
                                            width: '100%',
                                        })}
                                    </div>
                                ) : (
                                    <div className="p-grid" style={{ color: '#999', textAlign: 'center', width: '100%', margin: '0px', height: '100%' }} onClick={this.activivate}>
                                        <div
                                            className="pi pi-spinner pi-spin"
                                            style={{ margin: '0px', top: '27%', height: '1em', textAlign: 'center', fontSize: '5em', width: '100%', position: 'relative' }}
                                            onClick={() => this.activivate}
                                        />
                                        <span style={{ width: '100%', textAlign: 'center' }}>Aguardando Ligação</span>
                                    </div>
                                )}
                            </div>
                            <div className="p-col-2"></div>
                        </>
                    ) : null}
                    <div className="p-col-5"></div>
                    {g_template_button(
                        '',
                        `pi pi-power-off`,
                        false,
                        !this.state.accepts ? this.activivate : this.pause,
                        false,
                        'p-col-2',
                        `p-button-${!this.state.accepts ? 'success' : 'danger'}`,
                        lb(!this.state.accepts ? 'placeAvailable' : 'placeUnavailable'),
                        { fontSize: '3em', width: '100%' }
                    )}
                    <div className="p-col-12 p-grid p-fluid" style={{ marginBottom: '2em' }}>
                        {waiting}
                    </div>
                    <div className="p-col-12 p-grid">{boxes}</div>
                    <div className="p-col-5"></div>
                    <div className="card p-col-12 p-grid">
                        <div className="p-col-12">
                            <h1>{ls('calls#today', 'titles')}</h1>
                        </div>
                        <div className="p-col-1"></div>
                        <CallsFromUser />
                    </div>
                </div>
            </>
        );
    }
}

class CallsFromUser extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    tooltipFs = raw => this.state['tt' + raw.idUUID];
    tooltipOver = raw => {
        let toSet = {};
        toSet['tt' + raw.idUUID] = true;
        this.setState(toSet);
    };
    tooltipLeave = raw => {
        let toSet = {};
        toSet['tt' + raw.idUUID] = false;
        this.setState(toSet);
    };
    getData = () => g_getData(this, S.call.list, 'callRecords', { filters: [...this.state.filter, ...this.getFilter()] });

    getFilter = () => {
        let d = new Date();
        let start = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}T`;
        if (!JSON.parse(localStorage.getItem('loggedUserN'))) return;
        let uuid = JSON.parse(localStorage.getItem('loggedUserN')).uuid;
        return [
            { key: 'destinationIdUUID', value: uuid },
            { key: 'dateCreationStart', value: `${start}00:00:00.000` },
            { key: 'dateContractEnd', value: `${start}23:59:59.000` },
        ];
    };

    formatTime = sec => {
        sec = Math.round(sec);
        let h = Math.floor(sec / 60 / 60);
        if (h > 0) sec = sec - h * 60 * 60;
        let min = Math.floor(sec / 60);
        if (min > 0) sec = sec - min * 60;
        return `${h > 0 ? h + ':' : ''}${(String(min) ?? '').padStart(2, '0')}:${(String(sec) ?? '').padStart(2, '0')}`;
    };

    render() {
        let { error, profile: e } = checkLoginACL({});
        if (error) return error;
        return (
            <div className="p-col-10">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, 0, 0, false),
                    [
                        { type: 'd', title: 'Utente', data: 'originName', sortable: true, filter: true },
                        { type: 'date', title: 'Data Inicio', data: 'dateStartCall', sortable: true, filter: true },
                        { type: 'date', title: 'Data Fim', data: 'dateEndCall', sortable: true, filter: true },
                        { type: 'date', title: 'Data', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'di',
                            title: 'Duração',
                            di: raw =>
                                raw.dateStartCall && raw.dateEndCall ? this.formatTime((new Date(raw.dateEndCall).getTime() - new Date(raw.dateStartCall).getTime()) / 1000) : '',
                        },
                        {
                            type: 'di',
                            title: 'Tempo de espera',
                            di: raw =>
                                raw.dateStartQueueWait && raw.dateEndQueueWait
                                    ? this.formatTime((new Date(raw.dateEndQueueWait).getTime() - new Date(raw.dateStartQueueWait).getTime()) / 1000)
                                    : '',
                        },
                        { type: 'd', title: 'Estado', data: 'callRecordStatusDescription', sortable: true },
                        {
                            type: 'url',
                            icon: 'pi pi-file-o',
                            title: 'Contrato',
                            url: raw => `#/contractP?type=1&id=${raw.contractIdUUID}`,
                            width: 1400,
                            height: 600,
                            callback: this.getData,
                            c: e.u === 1 || e.d === 1,
                        },
                        { type: 'url', url: raw => `#/callinfo?type=1&id=${raw.idUUID}`, width: 1400, height: 600, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
