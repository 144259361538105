import {
    S,
    lm,
    ls,
    lb,
    lt,
    g_getHeaderTemplate,
    g_genericRequired,
    g_hideSearch,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_getDataFields,
    g_ApiSearchArea,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dialog,
    g_template_form,
    g_treatDate,
    g_wraper,
    g_pair,
    LabelSelector,
    g_template_input,
    g_template_inputArea,
} from '../GenericFunctions';
import { Editor } from 'primereact/editor';
import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import React from 'react';
import Log from '../logs/Log';
import GSelector from '../generic/GSelector';
import Images from './Images';
import ConsignatorService from '../../service/ConsignatorService';
import ProviderService from '../../service/ProviderService';
import PromotionsService from '../../service/PromotionsService';
import SearchSegmentService from '../../service/searchSegmentService';
import ListPage from '../generic/ListPage';
import SearchSegmentsService from '../../service/searchSegmentService';
import { table as promotionsTable } from '../promotions/promotions-active';

export class SearchArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: g_ApiSearchArea(), items: [{ label: LabelSelector('search', 'tab') }], idUUID: null, mode: true, searchAreaTypes: [], searchAreaCategories: [] };
    }

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  SearchSegmentService.list,
                  response => {
                      let rdata = response.searchAreas[0];
                      rdata.searchAreaType = String(rdata.searchAreaType);
                      rdata.searchAreaCategory = String(rdata.searchAreaCategory);
                      this.setState({ data: rdata });
                  },
                  { filters: [{ key: 'idUUID', value: this.state.idUUID }] }
              )
            : null;

    getModel = () => [
        { label: ls('1', 'searchModel') },
        { label: ls('2', 'searchModel') },
        { label: ls('3', 'searchModel') },
        { label: ls('4', 'searchModel') },
        { label: ls('5', 'searchModel') },
        { label: ls('6', 'searchModel') },
    ];

    getProviders = context => g_getData(context, ProviderService.getProviders, 'providers');

    onSubmit = e => SearchSegmentService.updateStatus(this, this.state.data.idUUID, 2, this.getData, lm('simpleStatus'), this.messages, e);
    onNegate = e =>
        SearchSegmentService.updateStatus(
            this,
            this.state.data.idUUID,
            this.state.data.searchAreaStatus === 2 ? 103 : this.state.data.searchAreaStatus === 3 ? 102 : 101,
            this.getData,
            lm('simpleStatus'),
            this.messages,
            e
        );
    onCancel = e => SearchSegmentService.updateStatus(this, this.state.data.idUUID, 101, this.getData, lm('simpleStatus'), this.messages, e);
    onAprove = e => SearchSegmentService.updateStatus(this, this.state.data.idUUID, this.state.data.searchAreaStatus + 1, this.getData, lm('simpleStatus'), this.messages, e);
    onChange = e => SearchSegmentService.updateStatus(this, this.state.data.idUUID, 1, this.getData, lm('simpleStatus'), this.messages, e);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        let items = this.state.items;
        if (idUUID) {
            items.push({ label: 'Texto' });
            items.push({ label: 'Imagens' });
            items.push({ label: 'Promoções' });
            items.push({ label: 'Estatísticas' });
            items.push({ label: LabelSelector('log', 'tab'), icon: 'pi pi-list' });
        }
        g_getDataFields(this, SearchSegmentService.getSearchTypes, 'searchAreaTypes', 'description', 'id', d => this.setState({ searchAreaTypes: d }));
        g_getDataFields(this, SearchSegmentService.getSearchCategories, 'searchAreaCategories', 'description', 'id', d => this.setState({ searchAreaCategories: d }));
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.dateStart = g_treatDate(data.dateStart);
        data.dateEnd = g_treatDate(data.dateEnd);
        if (!this.state.idUUID) {
            const response = await SearchSegmentService.add({ searchArea: data });
            if (g_displayMessageError(this.messages, response)) return;
            window.location.href = `/#/searcharea?type=1&id=${response.searchArea.idUUID}`;
            window.location.reload();
            return;
        }
        const response = await SearchSegmentService.update({ searchArea: data });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
    };

    getConsignators = S.consignator.processed.list;

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            let pS = this.state.data.searchAreaStatus;
            let cancel = [1, 2, 3, 4, 5].indexOf(pS) === -1;
            let aprove = !([2, 3].indexOf(pS) !== -1 && g_checkAlc(5, 'searcharea#backoffice'));
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                <GSelector
                                    labelwraper="p-col-2"
                                    label={ls('consignatorName')}
                                    ref={e => (this.selector1 = e)}
                                    getData={this.getConsignators}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector1.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, consignatorIdUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    disabled={mode}
                                    startV={this.state.data?.consignatorName}
                                />,

                                {
                                    t: 'array',
                                    filter: ['description', 'dateStart', 'dateEnd', 'searchAreaType', 'searchAreaCategory', 'showOrder'],
                                    dTypeF: 't',
                                    dTypeFs: { 1: 'date', 2: 'date', 3: 'drop', 4: 'drop' },
                                    options: { 3: this.state.searchAreaTypes, 4: this.state.searchAreaCategories },
                                    req: { _: true },
                                    cc: { 3: 4, 4: 2, 5: 2 },
                                    handleExtraData: { 5: { regex: /\d*/g } },
                                },
                            ],
                            mode => [
                                g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', [0, 1].indexOf(pS) === -1, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    lm('askSubmit'),
                                    'submit',
                                    this.onSubmit,
                                    lb('submit'),
                                    pS !== 1,
                                    mode,
                                    'p-button-success',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-success'
                                ),
                                g_template_dialog(
                                    this,
                                    lm('askAprove'),
                                    'aprove',
                                    this.onAprove,
                                    lb('aprove'),
                                    aprove,
                                    mode,
                                    'p-button-success',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-success'
                                ),
                                g_template_dialog(
                                    this,
                                    lm('askReject'),
                                    'negate',
                                    this.onNegate,
                                    lb('reject'),
                                    aprove,
                                    mode,
                                    'p-button-danger',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-danger'
                                ),
                                g_template_dialog(
                                    this,
                                    lm('askChange'),
                                    'change',
                                    this.onChange,
                                    lb('change'),
                                    aprove,
                                    mode,
                                    'p-button-warning',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-warning'
                                ),
                                g_template_dialog(
                                    this,
                                    lm('askBlockP'),
                                    'cancel',
                                    this.onCancel,
                                    lb('block'),
                                    cancel,
                                    mode,
                                    'p-button-danger',
                                    null,
                                    null,
                                    null,
                                    'p-col-2',
                                    'p-button-danger'
                                ),
                            ],
                            () => [
                                [
                                    {
                                        t: 'array',
                                        filter: ['searchAreaStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        class: 'l',
                                        states: { 0: searchAreaStates },
                                    },
                                ],
                                [],
                                this.state.idUUID && this.state.data.searchAreaStatus < 7 ? [<Steps model={this.getModel()} activeIndex={pS - 1} />] : [],
                            ],
                            this.submit,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <DataEditor context={() => this} />,
                        <Images context={() => this} />,
                        promotions(() => this.state.data),
                        <></>,
                        <Log idUUID={this.state.idUUID} logContextType={30} />,
                    ])}
                </div>
            );
        });
    }
}

const promotions = baseObj => (
    <ListPage
        title=""
        getData={context => g_getData(context, PromotionsService.list, 'promotions', { filters: [...context.state.filter, { key: 'referenceiduuid', value: baseObj().idUUID }] })}
        table={(context, e) => [
            ...promotionsTable(context, e),
            // { type: 'dil', text: 'Tem a certeza que quer apagar?', id: raw => `dil${raw.idUUID}`, buttonClassName: 'p-button-danger', onConfirm: console.log, icon: 'pi pi-trash' },
        ]}
        header={context =>
            g_getHeaderTemplate(context, '/#/promotion?type=1&ptype=2', 'new promotion', context.getData, 1400, 700, g_checkAlc(4, 'promotion').c, w => (w.baseObj = baseObj()))
        }
    />
);

class DataEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: '' };
    }
    componentDidMount() {
        this.props
            .context()
            .getData()
            .then(() => this.setState({ data: this.props.context().state.data.htmlMainContent }));
    }

    editorChange = e => this.setState({ data: e.htmlValue });
    preview = () => {};

    save = async () => {
        if (!this.props.context().state.idUUID) return;
        const response = await SearchSegmentService.updateContent({ contentType: 0, htmlContent: this.state.data, idUUID: this.props.context().state.idUUID });
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.props.context().messages.show({ severity: 'success', summary: lm('simpleSave') });
    };

    render() {
        return (
            <div className="p-grid p-col-12 p-fluid">
                {g_wraper(
                    `value`,
                    '',
                    <Editor
                        className="p-col-12"
                        style={{ height: '20em' }}
                        disabled={this.props.context().state.mode}
                        required={true}
                        value={this.state.data}
                        onTextChange={e => this.editorChange(e)}
                        id="value"
                    />,
                    false,
                    `p-col-2`
                )}
                {g_template_button(lb('save'), '', false, this.save, this.props.context().state.mode, 'p-col-2')}
                {g_template_button(lb('preview'), '', false, this.preview, this.props.context().state.mode, 'p-col-2')}
            </div>
        );
    }
}

export const searchAreaStates = {
    1: { color: 'orange', class: 'pi-step-forward' },
    2: { color: 'orange', class: 'pi-circle-on' },
    3: { color: 'orange', class: 'pi-dollar' },
    4: { color: 'green', class: 'pi-circle-on' },
    5: { color: 'green', class: 'pi-play' },
    6: { color: 'green', class: 'pi-check' },
    100: { color: 'red', class: 'pi-trash' },
    101: { color: 'red', class: 'pi-minus' },
    102: { color: 'red', class: 'pi-dollar' },
    103: { color: 'red', class: 'pi-circle-off' },
};

//============================== Categories ==============================

export class SearchCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: { description: '', id: 0, languageTag: '', status: 0 }, items: [{ label: lt('category') }], idUUID: null, mode: true };
    }
    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  SearchSegmentService.category.list,
                  response => this.setState({ data: response.searchAreaCategories[0] }, () => console.log(response.searchAreaCategories)),
                  {
                      filters: [{ key: 'id', value: this.state.idUUID }],
                  }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        console.log(idUUID);
        let mode = query.get('type') !== '1';
        let items = this.state.items;
        if (idUUID) items.push({ label: lt('services') });
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        let data = this.state.data;
        data.dateStart = g_treatDate(data.dateStart);
        data.dateEnd = g_treatDate(data.dateEnd);
        const response = await SearchSegmentService.category.put({ searchAreaCategory: data });
        if (g_displayMessageError(this.messages, response)) return;
        if (!this.state.idUUID) {
            window.location.href = `/#/searchAreaCategory?type=1&id=${response.searchAreaCategory.id}`;
            window.location.reload();
            return;
        }
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [{ t: 'array', filter: ['description'], dTypeF: 't' }],
                            mode => [
                                g_template_button(lb(this.state.idUUID ? 'save' : 'create'), '', this.state.data.status === 2, null, mode, 'p-col-2'),
                                //                    g_template_dialog(this, 'Tem a certeza que quer bloquear premanentemente?', 'cancel', this.onCancel, 'Bloquear', this.state.data.status === 2, mode, 'p-button-danger', null, null, null, 'p-col-2', 'p-button-danger'),
                            ],
                            () => [[{ t: 'array', filter: ['status'], dTypeF: 'state', class: 'l', states: [categoryStates] }]],
                            this.submit,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <CategoryAssociation context={() => this} />,
                    ])}
                </div>
            );
        });
    }
}

export const categoryStates = { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-ban' } };

class CategoryAssociation extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.context().state.id !== null) this.getData();
    }
    getData = () =>
        g_getData(this, SearchSegmentService.category.servicetype.list, 'searchAreaServiceTypes', {
            filters: [{ key: 'category', value: this.props.context().state.data.id }, ...this.state.filter],
        });

    remove = async raw => {
        let response = await SearchSegmentService.category.servicetype.delete(this.props.context().state.data.id, raw.id);
        if (g_displayMessageError(this.messages, response)) return;
        this.getData();
    };

    render() {
        return (
            <div className="card">
                <Messages ref={e => (this.messages = e)} />
                <Dialog header={ls('addNewOne', 'titles')} onHide={() => this.setState({ showNewOne: false })} visible={this.state.showNewOne}>
                    <AddNew idUUID={this.props.context().state.data.id} callBackData={this.getData} />
                </Dialog>
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ showNewOne: !this.state.showNewOne }), this.getData),
                    [
                        { type: 'd', data: 'description', filter: true, sortable: true },
                        { type: 'dil', text: lm('askRemove'), id: raw => `remove${raw.id}`, onConfirm: this.remove, icon: 'pi pi-trash', buttonClassName: 'p-button-danger' },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}

class AddNew extends React.Component {
    constructor() {
        super();
        this.state = { ...g_genericRequired(), pageSize: 6 };
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.idUUID && this.props.callBackData) this.setState({ idUUID: this.props.idUUID, list: this.props.list }, this.getData);
    }
    getData = () =>
        g_getData(this, SearchSegmentService.serviceType.list, 'searchAreaServiceTypes', { filters: [{ key: 'notInCategory', value: this.props.idUUID }, ...this.state.filter] });

    add = async raw => {
        let data = { searchAreaCategoryId: this.props.idUUID, searchAreaServiceTypeId: raw.id };
        let response = await SearchSegmentService.category.servicetype.add(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: lm('simpleAdd') });
        this.getData();
        this.props.callBackData();
    };

    render() {
        return (
            <div className="card">
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, -1, -1, 0),
                    [
                        { type: 'd', data: 'description', filter: true },
                        { type: 'button', icon: 'pi pi-plus', class: 'p-button-sucess', click: this.add },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}

//============================== Services ==============================
export class SearchService extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: { description: '' }, items: [{ label: lt('serviceType') }], idUUID: null, mode: true };
    }

    getData = () =>
        this.state.idUUID
            ? g_getData(
                  this,
                  S.search.serviceType.list,
                  r => {
                      if (r.searchAreaServiceTypes.length >= 1) this.setState({ data: r.searchAreaServiceTypes[0] });
                  },
                  { filters: [g_pair('idUUID', this.state.idUUID)] }
              )
            : null;

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let idUUID = query.get('id');
        let mode = query.get('type') !== '1';
        let items = this.state.items;
        if (idUUID) items.push({ label: lt('parameters') });
        this.setState({ mode: mode, idUUID: idUUID, items: items }, this.getData);
    }

    submit = async e => {
        e.preventDefault();
        if (!this.state.data.description) return;
        let data = { searchAreaServiceType: { description: this.state.data.description, ...(this.state.data.idUUID ? { idUUID: this.state.data.idUUID } : {}) } };
        const response = await SearchSegmentsService.serviceType.put(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (!this.state.idUUID) {
            window.location.href = `/#/searcharea-service?type=1&id=${response.searchAreaServiceType.idUUID}`;
            window.location.reload();
            return;
        }
        this.messages.show({ severity: 'success', summary: lm('simpleSave') });
        this.getData();
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            () => [{ t: 'array', filter: ['description'], dTypeF: 't' }],
                            mode => [
                                g_template_button(this.state.idUUID ? lb('save') : lb('create'), '', false, null, mode, 'p-col-2'),
                                g_template_dialog(
                                    this,
                                    lm('askRemove'),
                                    'delete',
                                    async e => {
                                        e.preventDefault();
                                        if (!this.state.idUUID) return;
                                        const response = await SearchSegmentsService.serviceType.delete(this.state.idUUID);
                                        if (g_displayMessageError(this.messages, response)) this.setState({ showDialog: false, id: '', description: '' });
                                        else this.setState({ showDialog: false, id: '', description: '' }, this.messages.show({ severity: 'sucess', summary: ls('simpleRemove') }));
                                        this.getData();
                                    },
                                    lb('remove'),
                                    !this.state.id,
                                    e.d !== 1,
                                    'p-button-danger',
                                    null,
                                    null,
                                    null,
                                    'p-col-1',
                                    'p-button-danger'
                                ),
                            ],
                            () => [[{ t: 'array', filter: ['status'], dTypeF: 'state', class: 'l', states: [categoryStates] }]],
                            this.submit,
                            2,
                            true,
                            this.state.mode,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            '70vh'
                        ),
                        <Parameters context={this} />,
                    ])}
                </div>
            );
        });
    }
}

class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...g_genericRequired(),
            name: '',
            parameterValue: '',
        };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, ConsignatorService.parameterList, 'parameters', {
                  filters: [...this.state.filter, { key: 'referenceIdUUID', value: this.props.context.state.idUUID }],
              })
            : null;

    save = async () => {
        if (!this.props.context.state.idUUID || !this.state.name || !this.state.parameterValue) return;
        const response = await ConsignatorService.parameterPut({
            parameter: {
                logContextType: 0,
                name: this.state.name,
                parameterValue: this.state.parameterValue,
                referenceIdUUID: this.props.context.state.idUUID,
            },
        });
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({ name: '', parameterValue: '', changeDialog: false }, this.getData);
    };

    render() {
        return (
            <div className="p-col">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ changeDialog: true }), this.getData, this.props.context.state.idUUID),
                    [
                        { type: 'd', data: 'name' },
                        { type: 'd', data: 'parameterValue' },
                        { type: 'button', icon: 'pi pi-pencil', click: raw => this.setState({ name: raw.name, parameterValue: raw.parameterValue, changeDialog: true }) },
                    ],
                    this.getData
                )}
                <Dialog
                    visible={this.state.changeDialog}
                    footer={
                        <Button
                            label={lb('save')}
                            onClick={e => {
                                e.preventDefault();
                                this.save();
                            }}
                        />
                    }
                    onHide={() => this.setState({ changeDialog: false })}
                >
                    <div className="card p-grid p-fluid contact-form" style={{ height: '50vh', width: '50vw' }}>
                        <div className="p-fluid" style={{ height: '50vh', width: '50vw' }}>
                            {g_template_input(this, 'name', this.state.name, ls('name'), this.state.mode, false, true, 'p-col-8', 'p-col-4', '', { data: 'name' })}
                            {g_template_inputArea(
                                this,
                                'parameterValue',
                                this.state.parameterValue,
                                ls('parameterValue'),
                                this.state.mode,
                                false,
                                true,
                                'p-col-12',
                                'p-col-4',
                                '',
                                { minHeight: '5em' },
                                { data: 'parameterValue' }
                            )}
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
