import { GenericListResponseG } from './Generic';
import { GenericListRequest, RequestsClass } from './Requests';

export type GenericDropdownData = {
	id: number;
	description: string;
	languageTag: string;
};

export const createDropdownEndpointData =
	<
		Target extends string,
		Filters extends string = string,
		OrderBy extends string = string
	>(
		req: RequestsClass,
		url: string
	) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<Target, GenericDropdownData> =>
		req.axios.post(url, data);
