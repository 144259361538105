import type {
	GenericListRequest,
	RequestsClass,
	GenericListResponseG
} from '../../../api-ts-bindings';

export type ProductType = {
	id: number;
	description: string;
	languageTag: string;
	orderView: number;
	orderViewShortcut: number;
	photoBig: string;
	photoSmall: string;
	photoShortcut: string;
};

export const ProductApiProductType = (req: RequestsClass, baseUrl: string) => ({
	list: (
		data: GenericListRequest
	): GenericListResponseG<'productTypes', ProductType> =>
		req.axios.post(`${baseUrl}/productType/list`, data)
});
