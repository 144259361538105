import { GenericListResponseG } from '../../../Generic';
import { RequestsClass } from '../../../Requests';
import { DashboardGenericModel, DashboardStatesModel } from '../Dashboard';

export type Filters = 'instate' | 'notinstate' | 'categorytype';

export type OrderBy = '' | 'datecreation';

export const gettotals =
	(req: RequestsClass, baseUrl: string) => (): Promise<DashboardGenericModel> =>
		req.axios.get(`${baseUrl}/totals`);

export const getstatesactives =
	(req: RequestsClass, baseUrl: string) =>
	(): Promise<GenericListResponseG<'states', DashboardStatesModel>> =>
		req.axios.get(`${baseUrl}/states/actives`);

export const getstatesin =
	(req: RequestsClass, baseUrl: string) =>
	(
		states: string
	): Promise<GenericListResponseG<'states', DashboardStatesModel>> =>
		req.axios.get(`${baseUrl}/states/in/${states}`);

export const getstatesinandcategory =
	(req: RequestsClass, baseUrl: string) =>
	(
		states: string,
		categoryid: number
	): Promise<GenericListResponseG<'states', DashboardStatesModel>> =>
		req.axios.get(`${baseUrl}/states/in/${states}/${categoryid}`);

export const getpersonal =
	(req: RequestsClass, baseUrl: string) =>
	(type: number): Promise<DashboardGenericModel> =>
		req.axios.get(`${baseUrl}/personal/${type}`);
