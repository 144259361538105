import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import { FullCalendar } from 'primereact/fullcalendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import React from 'react';
import config from '../../config/config';
import S from '../../service/Services';
import SearchSegmentService from '../../service/searchSegmentService';
import Attachment from '../attachment/Attachment';
import GPhotoUpload from '../file/G_photoFileUpload/PhotoUpload';
import { pair } from '../../api-ts-bindings/Generic';
import {
    LS,
    lm,
    ls,
    g_template_input,
    g_template_inputArea,
    ListPage,
    StateIcon,
    g_getHeaderTemplate,
    g_treatDate,
    g_ApiScheduling,
    g_hideSearch,
    g_template_dataTable,
    g_getHeaderTemplatecF,
    g_genericRequired,
    g_ApiProvider,
    g_getDataFields,
    g_checkAlc,
    g_checkLoginACL,
    g_displayMessageError,
    g_handleChange,
    g_tabViewRenderOptions,
    g_template_dropdown,
    g_getData,
    g_wraper,
    g_template_inputMask,
    g_formMaper,
    g_convertList,
    g_pair,
    g_openWindowDialog,
    g_treatNumric,
    g_treatNumricR,
    g_template_button,
    g_checkMode,
    g_template_dialog,
    g_getDateTemplate,
    g_inputAreaTemplate,
    g_inputMaskTemplate,
    g_inputTemplate,
    g_getWraper,
} from '../GenericFunctions';
import Log from '../logs/Log';
import ProviderUserAssociation from './ProviderUserAssociation';
import { EMPTY_ADDRESS, AddressDialog } from '../generic/Address';
import * as address from '../generic/Address';
import { chatModes, emailModes, phoneModes, schedulingModes } from './lists';
import { calendarEvent } from './CalendarEvent';
import { businessHours } from './BusinessHours';
import { CreateSetObj, GDiv, GDropdown, useAcl } from '../../Generics';
import { CallInfoManagement } from '../protetor/calls/callinfoManagement';
import { Requests } from '../../Requests';

export class Provider extends React.Component {
    constructor() {
        super();
        this.state = { data: g_ApiProvider(), idUUID: null, providerTypes: [], items: [{ label: LS('provider', 'tab') }], mode: false, holderphoto: null };
    }

    blockStatus = e => S.provider.processed.status(this, this.state.data.idUUID, 3, this.getData, LS('simpleRemove', 'messages'), this.messages, e);
    updateStatusClick = e =>
        S.provider.processed.status(this, this.state.data.idUUID, this.state.data.providerStatus === 1 ? 2 : 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);
    askApproveStatus = e => S.provider.processed.status(this, this.state.data.idUUID, 1, this.getData, LS('simpleStatus', 'messages'), this.messages, e);
    askDenyApproveStatus = e => S.provider.processed.status(this, this.state.data.idUUID, 5, this.getData, LS('simpleStatus', 'messages'), this.messages, e);

    componentDidMount() {
        let query = new URLSearchParams(this.props.location?.search ?? '');
        let id = this.props.idUUID ?? query.get('id');
        let mode = query.get('type') !== '1';
        let items = [{ label: LS('provider', 'tab') }];
        if (id && !this.props.luser) {
            items = [
                ...items,
                { label: LS('addresses', 'tab') },
                { label: LS('providerServices', 'tab') },
                { label: LS('providerStock', 'tab') },
                { label: LS('providerBusinessHours', 'tab') },
                { label: LS('providerSchedule', 'tab') },
                { label: LS('calls', 'tab') },
            ];
            if (g_checkAlc(4, 'provider#association')) items = [...items, { label: LS('providerAccess', 'tab'), icon: 'pi pi-sitemap' }];
            if (g_checkAlc(4, 'provider#parameters')) items.push({ label: LS('parameters', 'tab'), icon: 'pi pi-cog' });
            items = [...items, { label: LS('attachments', 'tab'), icon: 'pi pi-paperclip' }, { label: LS('log', 'tab'), icon: 'pi pi-list' }];
        }
        this.setState({ idUUID: id, mode: mode || this.props.luser, items: items }, this.getData);
        g_getDataFields(this, SearchSegmentService.getSearchCategories, 'searchAreaCategories', 'description', 'id', d => this.setState({ searchAreaCategories: d }));
        g_getDataFields(this, S.provider.listTypes, 'providerTypes', 'description', 'id', d => this.setState({ providerTypes: d }));
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let response = await S.provider.get(this.state.idUUID, 1);
        if (g_displayMessageError(this.messages, response)) return;
        let rdata = response.provider;
        rdata.providerType = String(rdata.providerType);
        rdata.searchAreaCategory = String(rdata.searchAreaCategory);
        rdata.chatMode = String(rdata.chatMode);
        rdata.schedulingMode = String(rdata.schedulingMode);
        this.setState({
            data: rdata,
            addresses: rdata.address.map(a => ({
                ...a,
                addressType: String(a.addressType),
                port: !a.port ? '' : String(a.port),
                longitude: g_treatNumricR(a.longitude),
                latitude: g_treatNumricR(a.latitude),
            })),
        });
        if (this.selector1) this.selector1.setState({ selected: rdata.organizationName });
    };

    onSubmit = async e => {
        if (e?.preventDefault) e.preventDefault();
        let data = this.state.data;
        data.phoneHome = data.phoneHome ? data.phoneHome.replace(/[^\d]/g, '') : '';
        data.phoneMobile = data.phoneMobile ? data.phoneMobile.replace(/[^\d]/g, ``) : ``;
        data.vatNumber = data.vatNumber ? data.vatNumber.replace(/[^\d]/g, ``) : ``;
        if (this.state.idUUID) {
            data.address = this.state.addresses.map(a => ({ ...a, latitude: g_treatNumric(a.latitude), longitude: g_treatNumric(a.longitude) }));
            console.log(data);
            let response = await S.provider.update({ provider: data });
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.getData();
        } else {
            let response = await S.provider.add({ provider: data });
            if (g_displayMessageError(this.messages, response)) return;
            if (this.state.holderphoto) {
                await this.upload(this.state.holderphoto, 1, response.provider.idUUID);
                this.setState({ holderphoto: null });
            }
            this.messages.show({ severity: 'success', summary: lm('simpleCreate') });
            window.location.href = `#/provider?type=1&id=${response.provider.idUUID}`;
            window.location.reload();
        }
    };

    onUpload = async data => {
        let formData = new FormData();
        formData.append('file', data.file);
        if (this.state.idUUID === null) this.setState({ holderphoto: formData });
        else this.upload(formData, data.documentTypeId, this.state.idUUID);
    };

    upload = async (formData, typeid, idUUID) => {
        this.messages.show({ severity: 'sucess', summary: LS('simpleUploadStart', 'messages') });
        var response = await S.provider.documentUpload(formData, typeid, idUUID);
        if (g_displayMessageError(this.messages, response)) return;
    };

    render() {
        //Temp as far as this is not moved to the new form object use this as the temporary set obj
        let setObj = CreateSetObj(this.state.data, data => this.setState({ data: data }));

        return g_checkLoginACL(
            e =>
                g_checkMode(this, e, this.state.idUUID, () => (
                    <div className="card card-w-title">
                        {g_tabViewRenderOptions(this, [
                            <form className="p-col" onSubmit={this.onSubmit}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-9 p-lg-9 p-fluid contact-form">
                                        <Panel>
                                            {g_formMaper(
                                                this,
                                                [
                                                    {
                                                        t: 'array',
                                                        cc: { _: 3, 1: 4, 2: 4, 3: 'l', 4: 'l', 5: 8 },
                                                        c: { 2: this.state.data.providerType !== '3' },
                                                        dTypeFs: { 0: 'selector', 1: 'drop', 2: 'selector', _: 't' },
                                                        req: { _: true },
                                                        getData: {
                                                            0: c => S.consignator.processed.list(c, { pageSize: 5 }),
                                                            2: c =>
                                                                g_getData(c, S.provider.list, 'providers', {
                                                                    filters: [
                                                                        ...c.state.filter,
                                                                        g_pair('providerType', 2),
                                                                        g_pair('organizationIdUUID', this.state.data.organizationIdUUID),
                                                                    ],
                                                                }),
                                                        },
                                                        ref: { 0: e => (this.selector1 = e), 2: e => (this.selector2 = e) },
                                                        startV: { 0: this.state.data.organizationName, 2: this.state.data.parentName },
                                                        tableBody: {
                                                            0: [
                                                                {
                                                                    type: 'd',
                                                                    data: (() => (this.state.data.contractTemplateMarginContext === '3' ? 'fullname' : 'name'))(),
                                                                    filter: true,
                                                                },
                                                                {
                                                                    type: 'button',
                                                                    icon: 'pi pi-plus',
                                                                    click: (raw, e) => {
                                                                        e.preventDefault();
                                                                        this.selector1.setState({ selected: raw.name, visible: false });
                                                                        this.setState({ data: { ...this.state.data, organizationIdUUID: raw.idUUID } });
                                                                    },
                                                                },
                                                            ],
                                                            2: [
                                                                { type: 'd', data: 'name', filter: true },
                                                                {
                                                                    type: 'button',
                                                                    icon: 'pi pi-plus',
                                                                    click: (raw, e) => {
                                                                        e.preventDefault();
                                                                        this.selector2.setState({ selected: raw.name, visible: false });
                                                                        this.setState({ data: { ...this.state.data, parentIdUUID: raw.idUUID } });
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                        filter: ['organizationName', 'providerType', 'parentIdUUID', 'name', 'fantasyName', 'shortDescription', 'erpCode'],
                                                        options: { 1: this.state.providerTypes },
                                                    },
                                                ],
                                                this.state.mode
                                            )}
                                            {g_inputAreaTemplate(
                                                `longDescription`,
                                                this.state.data.longDescription,
                                                LS('longDescription'),
                                                this,
                                                true,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                '',
                                                'p-col-12',
                                                'p-col-3',
                                                { minHeight: '5em' }
                                            )}
                                            {g_inputMaskTemplate(
                                                `vatNumber`,
                                                this.state.data.vatNumber,
                                                LS(this.state.data?.entityType === 1 ? 'vatNumber' : 'vatNumberC'),
                                                this,
                                                LS(this.state.data?.entityType === 2 ? 'vatNumberC' : 'vatNumber', 'mask'),
                                                ``,
                                                true,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                ``,
                                                `p-col-2`,
                                                `p-col-3`
                                            )}
                                            {g_inputTemplate(
                                                `email`,
                                                this.state.data.email,
                                                LS('email'),
                                                this,
                                                false,
                                                this.state.mode,
                                                false,
                                                g_handleChange,
                                                {},
                                                ``,
                                                `p-col-6`,
                                                `p-col-3`,
                                                `email`
                                            )}
                                            {g_wraper('phoneMobile', LS('phoneMobile'), [
                                                g_template_inputMask(
                                                    this,
                                                    'phoneMobile',
                                                    this.state.data.phoneMobile,
                                                    '',
                                                    LS('phoneMobile', 'mask'),
                                                    false,
                                                    this.state.mode,
                                                    false,
                                                    'p-col-2',
                                                    '',
                                                    '',
                                                    LS('phoneMobile', 'placeholder')
                                                ),
                                                g_wraper(
                                                    'phoneHome',
                                                    LS('phoneHome'),
                                                    g_template_inputMask(
                                                        this,
                                                        'phoneHome',
                                                        this.state.data.phoneHome,
                                                        '',
                                                        LS('phoneHome', 'mask'),
                                                        false,
                                                        this.state.mode,
                                                        false,
                                                        'p-col-2',
                                                        '',
                                                        '',
                                                        LS('phoneHome', 'placeholder')
                                                    ),
                                                    false,
                                                    'p-col-2',
                                                    true
                                                ),
                                            ])}
                                            {g_template_dropdown(
                                                this,
                                                'searchAreaCategory',
                                                this.state.data.searchAreaCategory,
                                                LS('searchAreaCategory'),
                                                this.state.searchAreaCategories,
                                                this.state.mode,
                                                false,
                                                false
                                            )}
                                            <GDiv class="" extra={{ fclass: 'p-col-3', lclass: 'p-col-3' }} obj={this.state.data} setObj={setObj}>
                                                <GDiv group>
                                                    <GDropdown d="chatMode" options={g_convertList(chatModes)} req />
                                                    <GDropdown lClass="p-col-2" d="schedulingMode" options={g_convertList(schedulingModes)} req />
                                                </GDiv>
                                                <GDiv group>
                                                    <GDropdown d="emailMode" options={g_convertList(emailModes)} req />
                                                    <GDropdown lClass="p-col-2" d="phoneMode" options={g_convertList(phoneModes)} req />
                                                </GDiv>
                                            </GDiv>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-lg-3">
                                        <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                            <GPhotoUpload
                                                style={{ width: '80px', height: '80px' }}
                                                disabled={this.props.luser ? !this.props.luser : this.state.mode}
                                                handlePhotoUpload={this.onUpload}
                                                photo={this.state.data.photo}
                                                documentTypeId={1}
                                                prefixPath={config.PHOTO_URL}
                                                alt={window.location.origin + '/assets/images/logotemplate.png'}
                                                id={this.state.idUUID}
                                            />
                                            {g_getWraper(
                                                null,
                                                LS('status', 'generic'),
                                                <div className="p-col">
                                                    <StateIcon state={this.state.data.providerStatus} small custom={states} />
                                                </div>,
                                                this.state.idUUID == null,
                                                'p-col-7'
                                            )}
                                            {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID === null, 'p-col')}
                                            {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID === null, 'p-col')}
                                        </Panel>
                                    </div>
                                </div>
                                <div style={{ display: this.props.luser ? 'none' : '' }} className="p-grid p-fluid">
                                    {g_template_button(LS(this.state.idUUID ? 'save' : 'create', 'btt'), '', null, null, this.state.mode, 'p-col-2')}
                                    {g_template_dialog(
                                        this,
                                        LS('askApproveStatus', 'messages'),
                                        'askApproveStatus',
                                        this.askApproveStatus,
                                        LS('approve', 'btt'),
                                        !this.state.idUUID || ![4, 5].includes(this.state.data.providerStatus) || !g_checkAlc(5, 'provider#aprove'),
                                        this.state.mode,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        'p-col-2',
                                        'p-button-success'
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('askDenyApproveStatus', 'messages'),
                                        'askDenyApproveStatus',
                                        this.askDenyApproveStatus,
                                        LS('deny', 'btt'),
                                        !this.state.idUUID || this.state.data.providerStatus !== 4 || !g_checkAlc(5, 'provider#aprove'),
                                        this.state.mode,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        'p-col-2',
                                        'p-button-warning'
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('ask' + (this.state.data.providerStatus === 2 ? 'Activate' : 'Deactivate'), 'messages'),
                                        'providerStatus',
                                        this.updateStatusClick,
                                        LS(this.state.data.providerStatus === 2 ? 'activate' : 'deactivate', 'btt'),
                                        !this.state.idUUID || [3, 4, 5].includes(this.state.data.providerStatus) || e.d !== 1,
                                        this.state.mode,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        'p-col-2',
                                        'p-button-warning'
                                    )}
                                    {g_template_dialog(
                                        this,
                                        LS('askBlockP', 'messages'),
                                        'providerStatusBlock',
                                        this.blockStatus,
                                        LS('block', 'btt'),
                                        !this.state.idUUID || [3, 4, 5].includes(this.state.data.providerStatus) || e.d !== 1,
                                        this.state.mode,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        'p-col-2',
                                        'p-button-danger'
                                    )}
                                </div>
                            </form>,
                            <AddressList context={this} gc={() => this} />,
                            <Services context={() => this} />,
                            <Stock context={() => this} />,
                            businessHoursList(() => this),
                            <Schedulue context={() => this} />,
                            <CallInfoManagement fakeName="provider" filters={[pair('providerIdUUID', this.state.idUUID ?? '')]} />,
                            ...(g_checkAlc(4, 'provider#association')
                                ? [<ProviderUserAssociation consignatorIdUUID={this.state.data.organizationIdUUID} idUUID={this.state.idUUID} />]
                                : []),
                            ...(g_checkAlc(4, 'provider#parameters') ? [<Parameters context={this} />] : []),
                            <Attachment idUUID={this.state.idUUID} documentContextType={7} service={'provider'} />,
                            <Log idUUID={this.state.idUUID} logContextType={7} />,
                        ])}
                    </div>
                )),
            !this.props.luser
        );
    }
}

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.context().state.idUUID !== null) this.getData();
    }
    getData = () =>
        this.props.context().state.idUUID
            ? g_getData(this, S.provider.searchAreaServiceType.list, 'searchAreaServiceTypes', { providerIdUUID: this.props.context().state.idUUID }, this.props.context().messages)
            : null;

    remove = async raw => {
        if (!this.props.context().state.idUUID) return;
        let response = await S.provider.searchAreaServiceType.delete(this.props.context().state.idUUID, raw.id);
        if (g_displayMessageError(this.props.context().messages, response)) return;
        this.getData();
    };

    render() {
        return (
            <div className="card">
                <Dialog header={LS('addNewOne', 'titles')} onHide={() => this.setState({ showNewOne: false })} visible={this.state.showNewOne}>
                    <AddNew idUUID={this.props.context().state.idUUID} callBackData={this.getData} />
                </Dialog>
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ showNewOne: !this.state.showNewOne }), this.getData),
                    [
                        { type: 'd', data: 'description', filter: true },
                        {
                            type: 'url',
                            c: g_checkAlc(4, 'provider-service-contract'),
                            url: r => `#/provider-service-contract?type=1&id=${r.idUUID}`,
                            width: 1000,
                            height: 500,
                            callback: this.getData,
                        },
                        { type: 'dil', text: LS('askRemove', 'messages'), icon: 'pi pi-trash', buttonClassName: 'p-button-danger', id: raw => raw.id, onConfirm: this.remove },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
class AddNew extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.idUUID && this.props.callBackData) {
            this.setState({ idUUID: this.props.idUUID, list: this.props.list }, this.getData);
        }
    }
    getData = () =>
        this.state.idUUID
            ? g_getData(this, SearchSegmentService.serviceTypes, 'searchAreaServiceTypes', {
                  filters: [{ key: 'notInProviderIdUUID', value: this.state.idUUID }, ...this.state.filter],
              })
            : null;

    add = async e => {
        if (this.state.idUUID === null) return;
        let data = { searchAreaServiceType: e.id, providerIdUUID: this.state.idUUID };
        let response = await S.provider.searchAreaServiceType.add(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
        this.props.callBackData();
        this.getData();
    };

    render() {
        return (
            <div className="card">
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    null,
                    [
                        { type: 'd', data: 'description', filter: true },
                        { type: 'button', icon: 'pi pi-plus', click: this.add },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
class Stock extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        if (this.props.context().state.idUUID !== null) this.getData();
    }
    getData = () =>
        this.props.context().state.idUUID
            ? g_getData(
                  this,
                  S.product.list,
                  'products',
                  { filters: [...this.state.filter, g_pair('productMode', 2), g_pair('entityProviderIdUUID', this.props.context().state.idUUID)] },
                  this.props.context().messages
              )
            : null;
    render() {
        return (
            <div className="card">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(
                        this,
                        '#/stock?type=1',
                        'new contracttemaplte',
                        this.getData,
                        1200,
                        700,
                        true,
                        window => (window.finicierData = this.props.context().state.data)
                    ),
                    [
                        { type: 'd', data: 'name', filter: true },
                        { type: 'd', data: 'quantity', filter: true },
                        { type: 'd', data: 'priceValue', filter: true },
                        { type: 'url', c: g_checkAlc(4, 'stock'), url: r => `#/stock?type=1&id=${r.idUUID}`, width: 1200, height: 700, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        );
    }
}
/* ================================================== Servicos contract template ================================================== */
export function AssociationServiceContractTemplate(props) {
    if (!props.id) {
        window.close();
        return null;
    }
    let remove = async (raw, c) => {
        let response = await S.search.serviceType.entityProduct.remove(raw.idUUID);
        if (g_displayMessageError(c.messages, response)) return;
        c.getData();
    };
    return (
        <ListPage
            header={(c, e) =>
                g_getHeaderTemplatecF(
                    c,
                    [() => c.setState({ showNewOne: true }), () => c.setState({ showNewTwo: true })],
                    c.getData,
                    e.c,
                    ['pi pi-file-o', 'pi pi-table'],
                    ['Adcionar Produto', 'Adicionar Pacote']
                )
            }
            table={(c, e) => [
                { type: 'd', data: 'description' },
                { type: 'map', data: 'relationType', objs: { 1: LS('product', 'text'), 2: LS('package', 'text') } },
                {
                    type: 'dil',
                    text: LS('askRemove', 'messages'),
                    icon: 'pi pi-trash',
                    buttonClassName: 'p-button-danger',
                    id: raw => raw.idUUID,
                    onConfirm: raw => remove(raw, c),
                    c: e.d,
                },
            ]}
            getData={c =>
                g_getData(
                    c,
                    Requests.provider.searchAreaServiceType.list,
                    r => {
                        if (!r.searchAreaServiceTypes[0]?.entitySearchAreaServiceTypeProducts) return;
                        c.setState({
                            data: r.searchAreaServiceTypes[0].entitySearchAreaServiceTypeProducts,
                            pageSize: r.searchAreaServiceTypes[0].entitySearchAreaServiceTypeProducts.length,
                            recordsTotal: r.searchAreaServiceTypes[0].entitySearchAreaServiceTypeProducts.length,
                        });
                    },
                    { filters: [...c.state.filter, g_pair('idUUID', props.id), g_pair('loadcontracttemplates', 1)] }
                )
            }
            addon={c => (
                <>
                    <Dialog
                        header={ls('addNewOne', 'titles')}
                        onHide={() => c.setState({ showNewOne: false })}
                        visible={c.state.showNewOne}
                        style={{ minHeight: '40vw', minWidth: '50vw' }}
                    >
                        {AddNewAssociationServiceContractTemplate(props.id, c.getData)}
                    </Dialog>
                    <Dialog
                        header={ls('addNewOne', 'titles')}
                        onHide={() => c.setState({ showNewTwo: false })}
                        visible={c.state.showNewTwo}
                        style={{ minHeight: '40vw', minWidth: '50vw' }}
                    >
                        {AddNewAssociationServiceCampainTemplate(props.id, c.getData)}
                    </Dialog>
                </>
            )}
        />
    );
}
const AddNewAssociationServiceCampainTemplate = (idUUID, callback) => (
    <ListPage
        header={() => null}
        table={c => [
            { type: 'd', data: 'name', filter: true },
            { type: 'd', data: 'consignatorName', filter: true },
            {
                type: 'button',
                icon: 'pi pi-plus',
                click: async e => {
                    if (!idUUID) return;
                    let response = await S.search.serviceType.entityProduct.add(idUUID, null, e.idUUID);
                    if (g_displayMessageError(c.messages, response)) return;
                    c.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
                    callback();
                },
            },
        ]}
        getData={c => (idUUID ? S.campaign.processed.list(c, 3) : null)}
    />
);
const AddNewAssociationServiceContractTemplate = (idUUID, callback) => (
    <ListPage
        header={() => null}
        table={c => [
            { type: 'd', data: 'description', filter: true },
            {
                type: 'button',
                icon: 'pi pi-plus',
                click: async e => {
                    if (!idUUID) return;
                    let response = await S.search.serviceType.entityProduct.add(idUUID, e.idUUID);
                    if (g_displayMessageError(c.messages, response)) return;
                    c.messages.show({ severity: 'success', summary: LS('simpleAdd', 'messages') });
                    callback();
                },
            },
        ]}
        getData={c => (idUUID ? S.contract.template.processed.list(c) : null)}
    />
);

/* ================================================== END Servicos contract template ================================================== */
class AddressList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: { type: '' } };
        this.addsL = [];
    }

    handleSubmit = async (e, data) => {
        e.preventDefault();
        let addresses = this.props.gc().state.addresses;
        data.zipCode = data.zipCode ? String(data.zipCode).replace(/[^\d]/g, '') : '';
        if (!data.idUUID) addresses.push(data);
        else addresses = addresses.map(a => (a.idUUID === data.idUUID ? data : a));
        this.props.context.setState({ addresses: addresses }, this.props.context.onSubmit);
        this.addD.setState({ visible: false, data: EMPTY_ADDRESS });
    };

    remove = async idUUID => {
        let response = await S.provider.address.delete(idUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.props.context.getData();
        this.props.context.messages.show({ severity: 'success', summary: LS('addressDelete', 'messages') });
    };

    render() {
        return (
            <div className="p-col">
                <AddressDialog messages={this.props.context.messages} context={this.props.context} ref={e => (this.addD = e)} handleSubmit={this.handleSubmit} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.addD.open(EMPTY_ADDRESS), this.props.context.getData),
                    {
                        data: this.props.gc().state.addresses,
                        body: [
                            { type: 'map', data: 'addressType', objs: address.types },
                            { type: 'd', data: 'zipCode' },
                            { type: 'd', data: 'street' },
                            { type: 'd', data: 'city' },
                            { type: 'd', data: 'state' },
                            { type: 'button', icon: 'pi pi-pencil', click: raw => this.addD.open(raw), class: 'p-button-warning' },
                            { type: 'button', icon: 'pi pi-trash', click: raw => this.remove(raw.idUUID), class: 'p-button-danger' },
                        ],
                    },
                    this.getData
                )}
            </div>
        );
    }
}

export class SchedulueList extends React.Component {
    constructor(props) {
        super(props);
        this.data = {};
        this.lastCreated = null;
        this.state = { events: [], toCreate: [] };
    }
    componentDidMount() {
        this.getData();
    }
    update = async (idUUID = null) => {
        let day = a =>
            `${a.getFullYear()}-${String(a.getMonth() + 1).padStart(2, '0')}-${String(a.getDate()).padStart(2, '0')}T${String(a.getHours()).padStart(2, '0')}:${String(
                a.getMinutes()
            ).padStart(2, '0')}:00`;
        let old = this.state.old;
        let toCreate = this.state.toCreate;
        let toUpdate = this.state.events.filter(a => (idUUID === null ? a.idUUID !== null : a.idUUID === idUUID));
        let recreate = false;
        let { loggeduser, error } = useAcl({ fakename: 'calls' });
        if (error) return;
        if (toCreate.length > 0) {
            recreate = true;
            for (let a of toCreate) {
                const resp = await S.scheduling.add({
                    scheduling: {
                        ...g_ApiScheduling(),
                        dateSchedulingStart: day(new Date(a.start)),
                        dateSchedulingEnd: day(new Date(a.end)),
                        providerIdUUID: loggeduser?.providerList[0] ?? '',
                    },
                });
                if (g_displayMessageError(this.messages, resp)) {
                    this.setState({ events: old, clear: true });
                    return;
                }
            }
        }
        this.setState({ toCreate: [] });
        if (toUpdate.length > 0) {
            for (let a of toUpdate) {
                const resp = await S.scheduling.update(
                    { scheduling: { ...this.data[a.idUUID], dateSchedulingStart: day(new Date(a.start)), dateSchedulingEnd: day(new Date(a.end)) } },
                    false
                );
                if (g_displayMessageError(this.messages, resp)) {
                    this.setState({ events: old, clear: true });
                    return;
                }
            }
        }
        this.getData(true, recreate);
    };
    getData = async (hidden = false, recreateR = false) => {
        let day = a =>
            `${a.getFullYear()}-${String(a.getMonth() + 1).padStart(2, '0')}-${String(a.getDate()).padStart(2, '0')}T${String(a.getHours()).padStart(2, '0')}:${String(
                a.getMinutes()
            ).padStart(2, '0')}:00`;
        let baseDay = new Date(this.calendar.calendar.state.currentDate);
        let endDay = new Date(this.calendar.calendar.state.currentDate);
        baseDay.setDate(baseDay.getDate() - 14);
        endDay.setDate(endDay.getDate() + 14);
        let { loggeduser, error } = useAcl({ fakename: 'calls' });
        if (error) return;
        const resp = await S.scheduling.list(
            {
                pageNumber: 1,
                pageSize: 200,
                filters: [
                    g_pair('providerIdUUID', loggeduser?.providerList[0] ?? ''),
                    g_pair('dateSchedulingStartStart', day(baseDay) + '.00'),
                    g_pair('dateSchedulingStartEnd', day(endDay) + '.00'),
                ],
            },
            hidden
        );
        if (g_displayMessageError(this.messages, resp)) {
            this.setState({ events: [], old: [], clear: true });
            return;
        }
        let resUUIDs = resp.schedulings.map(a => a.idUUID);
        if (resp.schedulings.length === 1)
            this.data = {
                [resp.schedulings[0].idUUID]: resp.schedulings[0],
            };
        else if (resp.schedulings.length > 0)
            this.data = resp.schedulings.reduce((p, c, i) => {
                let base = {};
                if (i === 1) {
                    base[p.idUUID] = p;
                    base[c.idUUID] = c;
                } else {
                    base = p;
                    base[c.idUUID] = c;
                }
                return base;
            });
        let recreate = resp => {
            let evs = resp.schedulings.map(a => ({
                idUUID: a.idUUID,
                start: day(new Date(a.dateSchedulingStart.replace(/-\d{2}:\d{2}$/, ''))),
                end: day(new Date(a.dateSchedulingEnd.replace(/-\d{2}:\d{2}$/, ''))),
                title: a.description,
                backgroundColor: { 1: 'green', 2: 'orange', 3: 'red' }[a.schedulingStatus],
            }));
            this.setState({ events: evs, old: evs, clear: true });
        };
        if (resUUIDs.length < this.state.events || resUUIDs.length > this.state.events) {
            recreate(resp);
            return;
        }
        let n = this.state.events.map(a => a.idUUID);
        resUUIDs.filter(a => {
            if (n.indexOf(a) === -1) return true;
            n.splice(n.indexOf(a), 1);
            return false;
        });
        if (resUUIDs > 0 || !hidden || recreateR) recreate(resp);
    };
    render() {
        let options = {
            plugins: [timeGridPlugin, interactionPlugin],
            allDaySlot: false,
            editable: true,
            slotDuration: '00:15:00',
            slotEventOverlap: false,
            dateClick: e => {
                if (this.lastCreated) return;
                let date = new Date(e.date);
                //                let fil = ({start, end}) => ((start, end) => (start.getTime() < date.getTime() && end.getTime() > date.getTime()) || (start.getTime() < date.getTime() + 3600000  && end.getTime() > date.getTime() + 3600000) )(new Date(start), new Date(end))
                //                let evs = this.state.events.filter(fil);
                if (date.getTime() < new Date().getTime()) {
                    this.messages.show({ severity: 'warn', summary: LS('calendarResize', 'messages') });
                    return;
                }
                //                if (evs.length > 1) {
                //                    this.props.context().messages.show({severity: "warn", summary: LabelSelector('calendarSetSame', 'messages')})
                //                    return;
                //                }
                let day = a =>
                    `${a.getFullYear()}-${String(a.getMonth() + 1).padStart(2, '0')}-${String(a.getDate()).padStart(2, '0')}T${String(a.getHours()).padStart(2, '0')}:${String(
                        a.getMinutes()
                    ).padStart(2, '0')}:00`;
                let start = day(e.date);
                let end = day(new Date(e.date.getTime() + 60 * 60 * 1000));
                let set = [{ idUUID: null, title: LS('emptyCalendarEvent', 'default'), start: start, end: end, backgroundColor: 'green' }];
                this.setState({ toCreate: set }, () => this.update(''));
                this.lastCreated = true;
                setTimeout(() => (this.lastCreated = false), 0.5);
            },
            eventResizeStop: e => {
                setTimeout(() => {
                    let instance = e.event._instance.instanceId;
                    let { start, end } = this.calendar.calendar.state.eventStore.instances[instance].range;
                    let date1 = a => a.toISOString().substr(0, 23);
                    if (start.getTime() < new Date().getTime()) {
                        this.setState({ events: this.state.old, clear: true });
                        this.messages.show({ severity: 'warn', summary: LS('calendarResize', 'messages') });
                        return;
                    }
                    //                if (start.getFullYear() !== end.getFullYear() || start.getMonth() !== end.getMonth() || start.getDate() !== end.getDate()) {
                    //                    this.setState({events: this.state.old, clear: true});
                    //                    this.props.context().messages.show({severity: "warn", summary: LabelSelector('calendarOnlyOneDay', 'messages')})
                    //                   return;
                    //                }
                    let ev = this.state.events.map(a => (a.idUUID === e.event._def.extendedProps.idUUID ? { ...a, start: date1(start), end: date1(end) } : a));
                    this.setState({ events: ev }, () => this.update(e.event._def.extendedProps.idUUID));
                }, 100);
            },
            eventDrop: e => {
                let date1 = a => a.toISOString().substr(0, 23);
                if (e.event._instance.range.start.getTime() < new Date().getTime()) {
                    this.messages.show({ severity: 'warn', summary: LS('calendarResize', 'messages') });
                    this.setState({ events: this.state.old, clear: true });
                    return;
                }
                let ev = this.state.events.map(a =>
                    a.idUUID === e.event._def.extendedProps.idUUID ? { ...a, start: date1(e.event._instance.range.start), end: date1(e.event._instance.range.end) } : a
                );
                this.setState({ events: ev }, () => this.update(e.event._def.extendedProps.idUUID));
            },
            eventClick: e =>
                g_openWindowDialog(
                    calendarEvent.url(e.event._def.extendedProps.idUUID),
                    e.event._def.extendedProps.idUUID,
                    calendarEvent.size[0],
                    calendarEvent.size[1],
                    null,
                    null,
                    this.getData
                ),
            locale: 'PT',
            lang: 'pt',
            isEventsUpdated: true,
            buttonText: { today: LS('calendarEventToday', 'btt') },
        };
        if (this.state.clear) {
            this.calendar.calendar.removeAllEvents();
            this.calendar.calendar.addEventSource(this.state.events);
        }
        return (
            <div className="p-col">
                <Messages ref={e => (this.messages = e)} />
                <FullCalendar ref={e => (this.calendar = e)} events={this.state.events} options={options} />
            </div>
        );
    }
}

class Schedulue extends React.Component {
    constructor(props) {
        super(props);
        this.data = {};
        this.lastCreated = null;
        this.state = { events: [], toCreate: [] };
        this.messages = props.context().messages;
    }
    componentDidMount() {
        this.getData();
    }
    update = async (idUUID = null) => {
        let day = a =>
            `${a.getFullYear()}-${String(a.getMonth() + 1).padStart(2, '0')}-${String(a.getDate()).padStart(2, '0')}T${String(a.getHours()).padStart(2, '0')}:${String(
                a.getMinutes()
            ).padStart(2, '0')}:00`;
        let old = this.state.old;
        let toCreate = this.state.toCreate;
        let toUpdate = this.state.events.filter(a => (idUUID === null ? a.idUUID !== null : a.idUUID === idUUID));
        let recreate = false;
        if (toCreate.length > 0) {
            recreate = true;
            for (let a of toCreate) {
                const resp = await S.scheduling.add({
                    scheduling: {
                        ...g_ApiScheduling(),
                        dateSchedulingStart: day(new Date(a.start)),
                        dateSchedulingEnd: day(new Date(a.end)),
                        providerIdUUID: this.props.context().state.idUUID,
                    },
                });
                if (g_displayMessageError(this.messages, resp)) {
                    this.setState({ events: old, clear: true });
                    return;
                }
            }
        }
        this.setState({ toCreate: [] });
        if (toUpdate.length > 0) {
            for (let a of toUpdate) {
                const resp = await S.scheduling.update(
                    { scheduling: { ...this.data[a.idUUID], dateSchedulingStart: day(new Date(a.start)), dateSchedulingEnd: day(new Date(a.end)) } },
                    false
                );
                if (g_displayMessageError(this.messages, resp)) {
                    this.setState({ events: old, clear: true });
                    return;
                }
            }
        }
        this.getData(true, recreate);
    };
    getData = async (hidden = false, recreateR = false) => {
        let day = a =>
            `${a.getFullYear()}-${String(a.getMonth() + 1).padStart(2, '0')}-${String(a.getDate()).padStart(2, '0')}T${String(a.getHours()).padStart(2, '0')}:${String(
                a.getMinutes()
            ).padStart(2, '0')}:00`;
        let baseDay = new Date(this.calendar.calendar.state.currentDate);
        let endDay = new Date(this.calendar.calendar.state.currentDate);
        baseDay.setDate(baseDay.getDate() - 14);
        endDay.setDate(endDay.getDate() + 14);
        const resp = await S.scheduling.list(
            {
                pageNumber: 1,
                pageSize: 200,
                filters: [
                    g_pair('providerIdUUID', this.props.context().state.idUUID),
                    g_pair('dateSchedulingStartStart', day(baseDay) + '.00'),
                    g_pair('dateSchedulingStartEnd', day(endDay) + '.00'),
                ],
            },
            hidden
        );
        if (g_displayMessageError(this.messages, resp)) {
            this.setState({ events: [], old: [], clear: true });
            return;
        }
        let resUUIDs = resp.schedulings.map(a => a.idUUID);
        if (resp.schedulings.length === 1)
            this.data = {
                [resp.schedulings[0].idUUID]: resp.schedulings[0],
            };
        else if (resp.schedulings.length > 0)
            this.data = resp.schedulings.reduce((p, c, i) => {
                let base = {};
                if (i === 1) {
                    base[p.idUUID] = p;
                    base[c.idUUID] = c;
                } else {
                    base = p;
                    base[c.idUUID] = c;
                }
                return base;
            });
        let recreate = resp => {
            let evs = resp.schedulings.map(a => ({
                idUUID: a.idUUID,
                start: day(new Date(a.dateSchedulingStart.replace(/-\d{2}:\d{2}$/, ''))),
                end: day(new Date(a.dateSchedulingEnd.replace(/-\d{2}:\d{2}$/, ''))),
                title: a.description,
                backgroundColor: { 1: 'green', 2: 'orange', 3: 'red' }[a.schedulingStatus],
            }));
            this.setState({ events: evs, old: evs, clear: true });
        };
        if (resUUIDs.length < this.state.events || resUUIDs.length > this.state.events) {
            recreate(resp);
            return;
        }
        let n = this.state.events.map(a => a.idUUID);
        resUUIDs.filter(a => {
            if (n.indexOf(a) === -1) return true;
            n.splice(n.indexOf(a), 1);
            return false;
        });
        if (resUUIDs > 0 || !hidden || recreateR) recreate(resp);
    };
    render() {
        let options = {
            plugins: [timeGridPlugin, interactionPlugin],
            allDaySlot: false,
            editable: true,
            slotDuration: '00:15:00',
            slotEventOverlap: false,
            dateClick: e => {
                if (this.lastCreated) {
                    return;
                }
                let date = new Date(e.date);
                //                let fil = ({start, end}) => ((start, end) => (start.getTime() < date.getTime() && end.getTime() > date.getTime()) || (start.getTime() < date.getTime() + 3600000  && end.getTime() > date.getTime() + 3600000) )(new Date(start), new Date(end))
                //                let evs = this.state.events.filter(fil);
                if (date.getTime() < new Date().getTime()) {
                    this.props.context().messages.show({ severity: 'warn', summary: LS('calendarResize', 'messages') });
                    return;
                }
                //                if (evs.length > 1) {
                //                    this.props.context().messages.show({severity: "warn", summary: LabelSelector('calendarSetSame', 'messages')})
                //                    return;
                //                }
                let day = a =>
                    `${a.getFullYear()}-${String(a.getMonth() + 1).padStart(2, '0')}-${String(a.getDate()).padStart(2, '0')}T${String(a.getHours()).padStart(2, '0')}:${String(
                        a.getMinutes()
                    ).padStart(2, '0')}:00`;
                let start = day(e.date);
                let end = day(new Date(e.date.getTime() + 60 * 60 * 1000));
                let set = [{ idUUID: null, title: LS('emptyCalendarEvent', 'default'), start: start, end: end, backgroundColor: 'green' }];
                this.setState({ toCreate: set }, () => this.update(''));
                this.lastCreated = true;
                setTimeout(() => (this.lastCreated = false), 0.5);
            },
            eventResizeStop: e => {
                setTimeout(() => {
                    let instance = e.event._instance.instanceId;
                    let { start, end } = this.calendar.calendar.state.eventStore.instances[instance].range;
                    let date1 = a => a.toISOString().substr(0, 23);
                    if (start.getTime() < new Date().getTime()) {
                        this.setState({ events: this.state.old, clear: true });
                        this.props.context().messages.show({ severity: 'warn', summary: LS('calendarResize', 'messages') });
                        return;
                    }
                    //                if (start.getFullYear() !== end.getFullYear() || start.getMonth() !== end.getMonth() || start.getDate() !== end.getDate()) {
                    //                    this.setState({events: this.state.old, clear: true});
                    //                    this.props.context().messages.show({severity: "warn", summary: LabelSelector('calendarOnlyOneDay', 'messages')})
                    //                   return;
                    //                }
                    let ev = this.state.events.map(a => (a.idUUID === e.event._def.extendedProps.idUUID ? { ...a, start: date1(start), end: date1(end) } : a));
                    this.setState({ events: ev }, () => this.update(e.event._def.extendedProps.idUUID));
                }, 100);
            },
            eventDrop: e => {
                let date1 = a => a.toISOString().substr(0, 23);
                if (e.event._instance.range.start.getTime() < new Date().getTime()) {
                    this.props.context().messages.show({ severity: 'warn', summary: LS('calendarResize', 'messages') });
                    this.setState({ events: this.state.old, clear: true });
                    return;
                }
                let ev = this.state.events.map(a =>
                    a.idUUID === e.event._def.extendedProps.idUUID ? { ...a, start: date1(e.event._instance.range.start), end: date1(e.event._instance.range.end) } : a
                );
                this.setState({ events: ev }, () => this.update(e.event._def.extendedProps.idUUID));
            },
            eventClick: e =>
                g_openWindowDialog(
                    calendarEvent.url(e.event._def.extendedProps.idUUID),
                    e.event._def.extendedProps.idUUID,
                    calendarEvent.size[0],
                    calendarEvent.size[1],
                    null,
                    null,
                    this.getData
                ),
            locale: 'PT',
            lang: 'pt',
            isEventsUpdated: true,
            buttonText: { today: LS('calendarEventToday', 'btt') },
        };
        if (this.state.clear) {
            this.calendar.calendar.removeAllEvents();
            this.calendar.calendar.addEventSource(this.state.events);
        }
        return (
            <div className="p-col">
                <FullCalendar ref={e => (this.calendar = e)} events={this.state.events} options={options} />
            </div>
        );
    }
}
const businessHoursList = provider => (
    <ListPage
        header={(c, e) => g_getHeaderTemplate(c, businessHours.url(null, provider().state.idUUID), 'new business', c.getData, businessHours.size[0], businessHours.size[1], e.c)}
        rg={[g_pair('showOrder', 'asc')]}
        table={(c, e) => [
            { type: 'map', data: 'weekday', objs: businessHours.weekDaysArray, sortable: true },
            { type: 'd', data: 'extraDescription', filter: true, sortable: true },
            { type: 'd', data: 'periodOneStart', filter: true, sortable: true },
            { type: 'd', data: 'periodOneEnd', filter: true, sortable: true },
            { type: 'd', data: 'periodTwoStart', filter: true, sortable: true },
            { type: 'd', data: 'periodTwoEnd', filter: true, sortable: true },
            { type: 'url', c: e.d === 1 || e.u === 1, url: r => businessHours.url(r.idUUID), width: businessHours.size[0], height: businessHours.size[1], callback: c.getData },
        ]}
        getData={c =>
            g_getData(
                c,
                S.businessHours.list,
                (res, call) =>
                    c.setState({
                        data: res.businessHourss.map(a => ({
                            ...a,
                            periodOneEnd:
                                a.businessHoursType === 1 && a.businessHoursCategory !== 3
                                    ? String(a.periodOneEnd)
                                          .padStart(4, '0')
                                          .split('')
                                          .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                    : '-',
                            periodOneStart:
                                a.businessHoursType === 1 && a.businessHoursCategory !== 3
                                    ? String(a.periodOneStart)
                                          .padStart(4, '0')
                                          .split('')
                                          .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                    : '-',
                            periodTwoEnd:
                                a.businessHoursType === 1 && a.businessHoursCategory === 2
                                    ? String(a.periodTwoEnd)
                                          .padStart(4, '0')
                                          .split('')
                                          .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                    : '-',
                            periodTwoStart:
                                a.businessHoursType === 1 && a.businessHoursCategory === 2
                                    ? String(a.periodTwoStart)
                                          .padStart(4, '0')
                                          .split('')
                                          .reduce((a, c, i) => a + c + (i === 1 ? ':' : ''))
                                    : '-',
                        })),
                        ...call,
                    }),
                { filters: [...c.state.filter, g_pair('providerIdUUID', provider().state.idUUID)] }
            )
        }
        stateE={{ inputData: { start: '', end: '', mininum: '', period: '' } }}
        addon={c => (
            <>
                <div className="p-grid p-fluid">
                    <div className="p-col-9" />
                    {g_template_button(LS('automaticFiller', 'btt'), '', false, () => c.setState({ showNewOne: true }))}
                </div>
                <Dialog
                    header={LS('addNewOne', 'titles')}
                    onHide={() => c.setState({ showNewOne: false })}
                    visible={c.state.showNewOne}
                    style={{ minWidth: '70vw', minHeight: '70vh' }}
                >
                    <Messages ref={e => (c.messages = e)} />
                    <form
                        onSubmit={async e => {
                            console.log(e);
                            e.preventDefault();
                            if (!c.state.inputData.end || !c.state.inputData.start || !c.state.inputData.mininum || !c.state.inputData.period) return;
                            let data = {
                                dateEnd: g_treatDate(c.state.inputData.end),
                                dateStart: g_treatDate(c.state.inputData.start),
                                eventDuration: c.state.inputData.mininum,
                                eventInterval: c.state.inputData.period,
                                providerIdUUID: provider().state.idUUID,
                            };
                            let a = await S.scheduling.autoGenerate(data);
                            if (g_displayMessageError(c.messages, a)) return;
                            provider().messages.show({ severity: 'success', summary: LS('messageSucess', 'dialogBusiness') });
                            c.setState({ showNewOne: false });
                        }}
                    >
                        <div style={{ minWidth: '70vw', height: '60vh' }}>
                            {g_formMaper(
                                c,
                                [
                                    {
                                        t: 'array',
                                        origin: c.state.inputData,
                                        dTypeFs: { _: 'date', 2: { 0: 't', 1: 'text' }, 3: { 0: 't', 1: 'text' } },
                                        prefix: { _: 'dialogBusiness' },
                                        handleExtraData: {
                                            _: { data: 'inputData' },
                                            2: { 0: { regex: new RegExp('\\d*'), data: 'inputData' } },
                                            3: { 0: { regex: new RegExp('\\d*'), data: 'inputData' } },
                                        },
                                        filter: ['start', 'end', ['mininum', LS('minutes', 'text')], ['period', LS('minutes', 'text')]],
                                        cc: { 2: { 0: 3 }, 3: { 0: 3, 1: 1 } },
                                    },
                                ],
                                false,
                                3
                            )}
                        </div>
                        <div style={{ minWidth: '70vw', height: '10vh' }}>{g_template_button(LS('generate', 'btt'), '', false, null)}</div>
                    </form>
                </Dialog>
            </>
        )}
    />
);
class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...g_genericRequired(),
            name: '',
            parameterValue: '',
        };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, S.parameter.list, 'parameters', { filters: [...this.state.filter, pair('referenceIdUUID', this.props.context.state.idUUID)] })
            : null;

    save = async () => {
        if (!this.props.context.state.idUUID || !this.state.name || !this.state.parameterValue) return;
        const response = await S.parameter.put({
            parameter: {
                logContextType: 0,
                name: this.state.name,
                parameterValue: this.state.parameterValue,
                referenceIdUUID: this.props.context.state.idUUID,
            },
        });
        if (g_displayMessageError(this.props.context.messages, response)) return;
        this.setState({ name: '', parameterValue: '', changeDialog: false }, this.getData);
    };

    render() {
        return (
            <div className="p-col">
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplatecF(this, () => this.setState({ changeDialog: true }), this.getData, this.props.context.state.idUUID),
                    [
                        { type: 'd', data: 'name' },
                        { type: 'd', data: 'parameterValue' },
                        { type: 'button', icon: 'pi pi-pencil', click: raw => this.setState({ name: raw.name, parameterValue: raw.parameterValue, changeDialog: true }) },
                    ],
                    this.getData
                )}
                <Dialog
                    visible={this.state.changeDialog}
                    footer={
                        <Button
                            label={LS('save', 'btt')}
                            onClick={e => {
                                e.preventDefault();
                                this.save();
                            }}
                        />
                    }
                    onHide={() => this.setState({ changeDialog: false })}
                >
                    <div className="card p-grid p-fluid contact-form" style={{ height: '50vh', width: '50vw' }}>
                        <div className="p-fluid" style={{ height: '50vh', width: '50vw' }}>
                            {g_template_input(this, 'name', this.state.name, LS('name'), this.state.mode, false, true, 'p-col-8', 'p-col-4', '', { data: 'name' })}
                            {g_template_inputArea(
                                this,
                                'parameterValue',
                                this.state.parameterValue,
                                LS('parameterValue'),
                                this.state.mode,
                                false,
                                true,
                                'p-col-12',
                                'p-col-4',
                                '',
                                { minHeight: '5em' },
                                { data: 'parameterValue' }
                            )}
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

let ltolltip = s => LS(s, 'tooltip');
export const states = {
    1: { color: 'green', class: 'pi-check', tooltip: ltolltip('active') },
    2: { color: 'red', class: 'pi-times', tooltip: ltolltip('disabled') },
    3: { color: 'red', class: 'pi-ban', tooltip: ltolltip('blocked') },
    4: { color: 'orange', class: 'pi-spin pi-spinner', tooltip: ltolltip('forAproval') },
    5: { color: 'orange', class: 'pi-ban', tooltip: ltolltip('denyed') },
};
