import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import {
    pair,
    LS,
    S,
    TablePage,
    g_checkAlc,
    g_dataTable,
    g_checkLoginACL,
    g_entityFilter,
    g_genericRequired,
    g_getData,
    g_getHeaderTemplate,
    g_hideSearch,
    ls,
} from '../GenericFunctions';
//import { states } from './Contract';
import {states} from '../protetor/contract-protector/ContractP';

let url = (id, workflowType) => `#/contract${workflowType === 5 || workflowType === 6 ? 'P' : ''}?type=1&${id ? 'id=' + id : ''}`;
let size = [1400, 700];
export const contractManagement = {
    url: url,
    size: size,
};

export class ContractManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let t = query.get('t');
        let extraFilter = '-100';
        if (t === '2') {
            if (g_checkAlc(5, 'contract#steps-numo')) extraFilter = '7,4';
            else if (g_checkAlc(5, 'contract#steps-financier')) extraFilter = '8,9';
            else if (g_checkAlc(5, 'contract#steps-provider')) extraFilter = '10,11';
            else if (g_checkAlc(5, 'contract#steps-consignator')) extraFilter = '13,14';
        } else extraFilter = '';
        g_hideSearch();
        this.setState({ extraFilter: extraFilter }, this.getData);
    }

    componentDidUpdate() {
        let query = new URLSearchParams(this.props.location.search);
        let t = query.get('t');
        let extraFilter = '-100';
        if (t === '2') {
            if (g_checkAlc(5, 'contract#steps-numo')) extraFilter = '7,4';
            else if (g_checkAlc(5, 'contract#steps-financier')) extraFilter = '8,9';
            else if (g_checkAlc(5, 'contract#steps-provider')) extraFilter = '10,11';
            else if (g_checkAlc(5, 'contract#steps-consignator')) extraFilter = '13,14';
        } else extraFilter = '';
        if (extraFilter === this.state.extraFilter) return;
        g_hideSearch();
        this.setState({ extraFilter: extraFilter }, this.getData);
    }

    getData = () =>
        g_checkAlc(5, 'contract-management#totalaccess')
            ? g_getData(
                  this,
                  data => {
                      if (this.state.extraFilter !== '') data.filters = [...data.filters, { key: 'contractStatus', value: this.state.extraFilter }];
                      return S.contract.list(data);
                  },
                  'contracts'
              )
            : g_getData(
                  this,
                  data => {
                      let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                      if (!f) return { code: 0, contracts: [], pageTotal: 0, recordsTotal: 0 };
                      data.filters = [...f[0]];
                      if (this.state.extraFilter !== '') data.filters = [...data.filters, { key: 'contractStatus', value: this.state.extraFilter }];
                      data.filters = [
                          ...data.filters,
                          {
                              key: 'filterBehaviour',
                              value: g_checkAlc(5, 'contract-management#financier') ? 1 : 2,
                          },
                      ];
                      return S.contract.list(data);
                  },
                  'contracts'
              );

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('contracts', 'titles')}</h1>
                <Messages ref={el => (this.messages = el)} />
                {g_dataTable(
                    this,
                    g_getHeaderTemplate(this, url(), 'Adicionar Contrato', this.getData, 1400, 700, e.c === 1),
                    [
                        { type: 'd', data: 'consigneeFullname', sortable: true, filter: true },
                        { type: 'd', data: 'consigneeVatNumber', sortable: true, filter: true },
                        { type: 'd', data: 'productName', sortable: true, filter: true },
                        { type: 'd', data: 'contractTotalValue', sortable: true, filter: true },
                        { type: 'd', data: 'installmentTotal', sortable: true, filter: true },
                        { type: 'spacer', space: '3' },
                        { type: 'date', data: 'dateContractStart', sortable: true, filter: true },
                        { type: 'date', data: 'dateContractEnd', sortable: true, filter: true },
                        { type: 'spacer' },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'state', title: ls('status', 'genric'), data: 'contractStatus', states, filter: this.state.extraFilter === '' },
                        { type: 'url', url: raw => url(raw.idUUID, raw.workflowType), width: 1400, height: 700, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData,
                    { enabled: true }
                )}
            </div>
        ));
    }
}

//Group bys

export const contractGroupByProductType = () => (
    <TablePage
        rg={[pair('name', 'desc')]}
        getData={c =>
            g_checkAlc(5, 'contract-management#totalaccess')
                ? g_getData(c, S.contract.groupByContractProductType, 'contractGroupByProductType')
                : g_getData(
                      c,
                      data => {
                          let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                          if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                          data.filters = [
                              ...f[0],
                              {
                                  key: 'filterBehaviour',
                                  value: g_checkAlc(5, 'contract-management#financier') ? 1 : 2,
                              },
                          ];
                          return S.contract.groupByContractProductType(data);
                      },
                      'contractGroupByProductType'
                  )
        }
        fakename="contract-management"
        table={() => [
            { t: 'd', data: 'name', sortable: true, filter: true },
            { t: 'd', data: 'count', sortable: true },
            { t: 'd', data: 'value', title: LS('totalValue'), sortable: true },

            { type: 'd', data: 'consigneeFullname', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'd', data: 'consigneeVatNumber', onlyFilter: true, filter: true },
            { type: 'd', data: 'installmentTotal', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'date', data: 'dateContractStart', onlyFilter: true, filter: true },
            { type: 'date', data: 'dateContractEnd', onlyFilter: true, filter: true },
            { type: 'spacer' },
            { type: 'date', data: 'dateCreation', onlyFilter: true, filter: true },
            { type: 'state', data: 'contractStatus', states, onlyFilter: true, filter: true },
        ]}
        ad={{ enabled: true }}
    />
);

export const contractGroupByTemplate = () => (
    <TablePage
        rg={[pair('name', 'desc')]}
        getData={c =>
            g_checkAlc(5, 'contract-management#totalaccess')
                ? g_getData(c, S.contract.groupByContractTemplate, 'contractGroupByContractTemplate')
                : g_getData(
                      c,
                      data => {
                          let f = g_entityFilter(data.filters, 'entitiesIDUUID', 'entitiesIDUUID', 5, 'contract-management#totalaccess');
                          if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0 };
                          data.filters = [
                              ...f[0],
                              {
                                  key: 'filterBehaviour',
                                  value: g_checkAlc(5, 'contract-management#financier') ? 1 : 2,
                              },
                          ];
                          return S.contract.groupByContractTemplate(data);
                      },
                      'contractGroupByContractTemplate'
                  )
        }
        fakename="contract-management"
        table={() => [
            { t: 'd', data: 'name', sortable: true, filter: true },
            { t: 'd', data: 'count', sortable: true },
            { t: 'd', data: 'value', title: LS('totalValue'), sortable: true },

            { type: 'd', data: 'consigneeFullname', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'd', data: 'consigneeVatNumber', onlyFilter: true, filter: true },
            { type: 'd', data: 'installmentTotal', onlyFilter: true, filter: true },
            { type: 'spacer', space: '3' },
            { type: 'date', data: 'dateContractStart', onlyFilter: true, filter: true },
            { type: 'date', data: 'dateContractEnd', onlyFilter: true, filter: true },
            { type: 'spacer' },
            { type: 'date', data: 'dateCreation', onlyFilter: true, filter: true },
            { type: 'state', data: 'contractStatus', states, onlyFilter: true, filter: true },
        ]}
        ad={{ enabled: true }}
    />
);
