import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import ConsigneeService from '../../service/ConsigneeService';
import { g_checkLoginACL, g_entityFilter, g_genericRequired, g_getData, g_template_button, g_template_dropdown, g_template_inputMask } from '../GenericFunctions';
import { contractRenderPart1, contractRenderPartGraphs, contractRequired, getContractsDataConsignator, contractRenderTimeless, processNumber } from './dashboardtools';

export default class FinancierDashboard extends Component {
    constructor() {
        super();
        this.state = {
            ...g_genericRequired(),
            ...contractRequired(this),
            pageSize: 99999999,
            baseConsignees: 0,
            consignees: 0,
            monthsOption: [
                { label: 'Janeiro', value: '0' },
                { label: 'Fevereiro', value: '1' },
                { label: 'Março', value: '2' },
                { label: 'Abril', value: '3' },
                { label: 'Maio', value: '4' },
                { label: 'Junho', value: '5' },
                { label: 'Julho', value: '6' },
                { label: 'Agosto', value: '7' },
                { label: 'Setembro', value: '8' },
                { label: 'Outubro', value: '9' },
                { label: 'Novembro', value: '10' },
                { label: 'Dezembro', value: '11' },
            ],
            dialogvisable: false,
            dialoglabel: 'def',
            mainsec: '2',
            smainsec: '2',
            secsec: 1,
            ssecsecv: new Date().getMonth() + '',
            secsecv: new Date().getMonth() + '',
        };
    }

    componentDidMount() {
        document.body.style.background = '#edf0f5';
        this.getData();
    }
    getData = () => {
        getContractsDataConsignator(this);
        this.getConsignatorData();
    };
    getConsignatorData = () => {
        g_getData(
            this,
            data => {
                let f = g_entityFilter(this.state.filter, 'entitiesIdUUID', 'entitiesIdUUID', 5, 'admin-user-management#accessall');
                if (!f) return { code: 0, recordsTotal: 0 };
                data.filters = f[0];
                data.userType = 1;
                data.pageSize = 1;
                return ConsigneeService.listConsignee(data);
            },
            response => {
                this.setState({ userCount: response.recordsTotal });
            }
        );
        g_getData(
            this,
            data => {
                let f = g_entityFilter(this.state.filter, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'admin-user-management#accessall');
                if (!f) return { recordsTotal: 0 };
                data.filters = f[0];
                data.userType = 1;
                data.pageSize = 1;
                return ConsigneeService.listStaging(data);
            },
            response => {
                this.setState({ baseCount: response.recordsTotal });
            }
        );
    };

    getLabel = () => {
        if (this.state.smainsec === '0') return 'Diario';
        else if (this.state.smainsec === '1') return 'Semanal';
        else if (this.state.smainsec === '2') return `Mês:${this.state.months[Number(this.state.ssecsecv)]}`;
        else return `Ano:${this.state.ssecsecv}`;
    };

    save = () => {
        this.setState({
            ssecsecv: this.state.secsecv,
            smainsec: this.state.mainsec,
            dialogvisable: false,
        });
    };

    changeMainSec = () => {
        if (this.state.mainsec === '2') {
            this.setState({
                secsecv: '' + new Date().getMonth(),
                secsec: 1,
            });
        } else if (this.state.mainsec === '3') {
            this.setState({
                secsecv: new Date().getFullYear(),
                secsec: 2,
            });
        } else {
            this.setState({
                secsec: null,
            });
        }
    };

    grender = () => {
        return (
            <div>
                <Messages ref={e => (this.messages = e)} />
                <div className="p-grid p-fuild dashboard">
                    <div className="p-col-12 p-lg-5">
                        <div className="card summary">
                            <span className="title">Funcionarios Cadastrados</span>
                            <span className="detail"></span>
                            <span className="count purchases">{processNumber(this.state.userCount)}</span>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-5">
                        <div className="card summary">
                            <span className="title">Funcionarios Base</span>
                            <span className="detail"></span>
                            <span className="count purchases">{processNumber(this.state.baseCount)}</span>
                        </div>
                    </div>
                    {contractRenderTimeless(this)}
                    <div style={{ borderBottom: '2px solid #aaaaaacc' }} className="p-col-12">
                        <h1>{this.getLabel().split(':').join(' ')}</h1>
                    </div>
                    {contractRenderPart1(this)}
                    <div style={{ verticalAlign: 'center' }} className="p-col-12 p-lg-2">
                        <Dialog
                            style={{ height: '32vh', width: '42vw' }}
                            position="right"
                            visible={this.state.dialogvisable}
                            onHide={() => {
                                this.setState({ dialogvisable: false });
                                this.getData();
                            }}
                        >
                            <div className="p-grid p-fluid p-col-12" style={{ minHeight: '27vh', width: '41vw' }}>
                                {g_template_dropdown(
                                    this,
                                    'mainsec',
                                    this.state.mainsec,
                                    null,
                                    this.state.requestType,
                                    false,
                                    false,
                                    false,
                                    this.state.secsec ? 'p-col-5' : 'p-col-11',
                                    '',
                                    false,
                                    '',
                                    { data: 'mainsec' },
                                    this.changeMainSec
                                )}
                                {this.state.secsec === 1
                                    ? g_template_dropdown(this, 'secsecv', this.state.secsecv, null, this.state.monthsOption, false, false, false, 'p-col-6', '', false, '', { data: 'secsecv' })
                                    : this.state.secsec === 2
                                    ? g_template_inputMask(this, 'secsecv', this.state.secsecv, null, '9999', false, false, false, 'p-col-6', 'p-col', '', null, { data: 'secsecv' })
                                    : null}
                                {g_template_button('', 'pi pi-save', false, this.save, false, 'p-col-1')}
                            </div>
                        </Dialog>
                        {g_template_button(
                            this.getLabel(),
                            '',
                            false,
                            () => this.setState({ dialogvisable: true, mainsec: this.state.smainsec, secsecv: this.state.ssecsecv }, this.changeMainSec),
                            false,
                            'p-col-12',
                            'p-col-12'
                        )}
                    </div>
                    {contractRenderPartGraphs(this)}
                </div>
            </div>
        );
    };

    render() {
        return g_checkLoginACL(this.grender, true, 'dashboard-consignator');
    }
}
