import React, { Component } from 'react';
import { AppMenu } from './AppMenu';
import { Redirect } from 'react-router-dom';
import * as config from './config/config';
import { getSideBarMenu } from './config/menus';

export default class SidebarMenu extends Component {
    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            userLogged: localStorage.getItem('loggedUserN') ? true : false,
            menu: [],
        };
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
    }

    componentDidMount() {
        this.createMenu(localStorage.getItem('loggedUserN'));
    }
    componentDidUpdate() {
        if (this.state.old !== localStorage.getItem('loggedUserN')) this.createMenu(localStorage.getItem('loggedUserN'));
    }
    onMenuItemClick(event) {
        if (!event.item.items) this.setState({ overlayMenuActive: false, mobileMenuActive: false });
    }

    async createMenu(storage) {
        if (!storage) {
            this.setState({ menu: [], old: null });
            return;
        }
        if (JSON.parse(storage).profileACLs != null) {
            const profile = JSON.parse(storage).profileACLs;
            let aclData = profile.filter(v => v.type === 4 && v.r === 1).map(v => v.tag);

            let process = v => {
                if (v.skipsAcl) return v;
                else if (v !== null) {
                    if (v.items !== null && v.items !== undefined) {
                        let nitems = [];
                        for (let item of v.items) {
                            let nitem = process(item);
                            if (nitem !== null) nitems = [...nitems, nitem];
                        }
                        if (nitems.length === 0) return null;
                        return { ...v, items: nitems };
                    } else if (v.acl !== null && aclData.indexOf(v.acl) !== -1) return v;
                }
                return null;
            };

            let items = await getSideBarMenu(storage);
            let nitems = [];
            for (let item of items) {
                let nitem = process(item);
                if (nitem !== null) nitems = [...nitems, nitem];
            }

            this.setState({ menu: nitems, old: storage });
        } else this.setState({ menu: [], old: storage });
    }

    render() {
        if (!this.state.userLogged) {
            return <Redirect to="/login" />;
        }
        return (
            <div style={{ width: '100%', height: '96vh' }}>
                <div className="layout-menu-container" style={{ width: '100%', maxHeight: '77vh' }}>
                    <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>
                <div style={{ textAlign: 'center', marginTop: '2vh', width: '100%' }}>{config.version}</div>
            </div>
        );
    }
}
