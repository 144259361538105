import {
	GenericListResponseG,
	GenericResponse,
	GenericResponseG,
	UUID
} from '../../Generic';
import { GenericListRequest, RequestsClass } from '../../Requests';
import { TicketModel } from '../Ticket';

export type Filters = '';
export type OrderBy = 'showOrder';

export type FilterStatusClose = string;
export type OrderByStatusClose = '';

export interface IncidentStatusModel {
	id: number;
	description: string;
	languageTag: string;
	imageIcon: string;
}

export interface IncidentStatusUpdateModel {
	idUUID: UUID;
	incidentStatus: number;
}

export const incidentstatus =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidentStatus', IncidentStatusModel> =>
		req.axios.post(`${baseUrl}/status`, data);

export const incidentstatusgoDistribution =
	(req: RequestsClass, baseUrl: string) =>
	(incidentIDUUID: UUID): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/accept-distribution/${incidentIDUUID}`);

export const incidentstatusgoResolution =
	(req: RequestsClass, baseUrl: string) =>
	(incidentIDUUID: UUID): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/accept-solve/${incidentIDUUID}`);

export const incidentstatusToFoward =
	(req: RequestsClass, baseUrl: string) =>
	(incidentIDUUID: UUID): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/dispatch/${incidentIDUUID}`);

export const incidentstatusFoward =
	(req: RequestsClass, baseUrl: string) =>
	(incidentIDUUID: UUID): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/redispatch/${incidentIDUUID}`);

export const incidentstatusDirectFoward =
	(req: RequestsClass, baseUrl: string) =>
	(
		incidentIDUUID: UUID,
		userIdUUID: UUID
	): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(
			`${baseUrl}/${incidentIDUUID}/direct-dispatch/2/${userIdUUID}`
		);

export const incidentstatusClose =
	(req: RequestsClass, baseUrl: string) =>
	(incidentIDUUID: UUID): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/close/${incidentIDUUID}`);

export const incidentstatusReopen =
	(req: RequestsClass, baseUrl: string) =>
	(incidentIDUUID: UUID): Promise<GenericResponseG<'incident', TicketModel>> =>
		req.axios.put(`${baseUrl}/reopen/${incidentIDUUID}`);

export const incidentstatusUpdate =
	(req: RequestsClass, baseUrl: string) =>
	(incidentupdate: IncidentStatusUpdateModel): Promise<GenericResponse> =>
		req.axios.put(`${baseUrl}/update/status`, incidentupdate);

export const incidentstatusstatusclose =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<FilterStatusClose, OrderByStatusClose>
	): GenericListResponseG<'incidentCloseStatus', IncidentStatusModel> =>
		req.axios.post(`${baseUrl}/close-status`, data);
