export enum DocumentModelStatus {
	active = 1,
	disable = 3
}

export interface DocumentModel {
	contextId: number;
	creationUserIdUUID: string;
	dateCreation: string;
	dateUpdate: string;
	description: string;
	documentStatus: DocumentModelStatus;
	documentType: number;
	documentTypeDescription: string;
	filename: string;
	filenameOriginal: string;
	idUUID: string;
	orderView: number;
	referenceIdUUID: string;
}

export enum DocumentType {
	pacient_video = 21,
	doctor_video = 22
}
