import { revalidate } from './revalidate';
import { pay } from './pay';
import { RequestsClass } from '../../../Requests';

export const ShoppingCartApiRequesterUUID = (
	req: RequestsClass,
	_baseUrl: string
) => {
	const baseUrl = `${_baseUrl}/requester-id-uuid`;

	return {
		revalidate: revalidate(req, baseUrl),
		purchase: pay(req, baseUrl)
	};
};
