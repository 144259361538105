export interface ContractTemplateField {
	contractFormMode: number;
	contractFormModeValue: string;
	contractTemplateFieldStatus: number;
	contractTemplateFormIdUUID: string;
	dateCreation: string;
	dateUpdate: string;
	fieldHeight: string;
	fieldListValues: string;
	fieldMaxSize: number;
	fieldName: string;
	fieldPosition: number;
	fieldType: number;
	fieldValue: string;
	fieldWidth: string;
	help: string;
	idUUID: string;
	mandatory: number;
	pontuationBehaviour: PontuationBehaviour;
	pontuationValue: number;
	title: string;
}

export enum PontuationBehaviour {
	none = 0,
	positive = 1,
	negative = 2,
}
