import React, { useCallback, useRef, useState, useEffect } from 'react';
import { pair } from '../../api-ts-bindings/Generic';
import { GenericListRequest } from '../../api-ts-bindings/Requests';
import { Requests } from '../../Requests';
import { MFile } from '../../types';
import { Button, checkAcl, checkLoginACL, Form, GDiv, GDropdown, GInput, g_getData, returnGenericEmpty, useDataObject } from '../Generic';
import { g_displayMessageError, TablePage } from '../GenericFunctions';

export const ImageLink = () => {
    let { profile, error, loggeduser } = checkLoginACL({});
    let { obj, setObj } = useDataObject<{ description: string; consignatorIdUUID: string }>({ description: '', consignatorIdUUID: '' });
    let form = useRef<null | HTMLFormElement>(null);
    let table = useRef<null | TablePage>(null);
    let input = useRef<null | HTMLInputElement>(null);

    let [options, setOptions] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        (async () => {
            let data: GenericListRequest = { filters: [] };

            let a = !checkAcl('adminignorefilter', 5);
            if (loggeduser!.consignatorList.length === 0 && a) return;
            if (loggeduser!.consignatorList.length > 0 && !a) {
                data.filters!.push(pair('iduuid', loggeduser!.consignatorList.join(',')));
            }
            let r = await Requests.consignator.list(data);
            if (g_displayMessageError(table.current?.messages, r)) return;

            setOptions(r.consignators.map(a => ({ label: a.fantasyName, value: a.idUUID })));
        })();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let handleImageChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            console.log('teste');
            e.preventDefault();
            let reader = new FileReader();
            let readerArray = new FileReader();

            if (!e.target.files || !e.target.files[0]) return;

            let file: MFile = e.target.files[0];
            reader.readAsDataURL(file);
            readerArray.onloadend = async () => {
                file.arrayByte = readerArray.result;
                let formData = new FormData();
                formData.append('file', file);

                var response = await Requests.news.image.link.upload(obj.consignatorIdUUID, obj.description, formData);
                if (g_displayMessageError(table.current?.messages, response)) return;
                table.current?.getData();
                form.current?.reset();
            };
            readerArray.readAsArrayBuffer(file);
        },
        [obj]
    );
    if (error) return error;
    return (
        <>
            <Form obj={obj} setObj={setObj} formRef={e => (form.current = e)}>
                <GDiv grid group>
                    <GInput req d="description" lClass="p-col-1" fClass="p-col-5" />
                    <GDropdown req d="consignatorIdUUID" lClass="p-col-1" fClass="p-col-4" options={options} l="consignatorName" />
                    <input hidden ref={e => (input.current = e)} type="file" onChange={handleImageChange} />
                    <Button
                        type="button"
                        label="upload"
                        onClick={e => {
                            e.preventDefault();
                            if (!form.current?.reportValidity()) return;
                            input.current?.click();
                        }}
                    />
                </GDiv>
            </Form>
            <TablePage
                ref={e => (table.current = e)}
                getData={c =>
                    g_getData(
                        c,
                        (data: GenericListRequest<string, string> & { organizationIdUUID?: string }) => {
                            let a = !checkAcl('adminignorefilter', 5);
                            if (loggeduser!.consignatorList.length === 0 && a) return returnGenericEmpty;
                            if (loggeduser!.consignatorList.length > 0 && !a) {
                                data.organizationIdUUID = loggeduser!.consignatorList.join(',');
                            }
                            data.filters?.push(pair('documentstatus', '1'));
                            return Requests.news.image.link.list(data);
                        },
                        'newsImages'
                    )
                }
                table={c => [
                    { t: 'd', data: 'description', filter: true },
                    { t: 'd', data: 'filename', filter: true },
                    { t: 'date', data: 'dateCreation' },
                    {
                        t: 'btt',
                        icon: 'pi pi-trash',
                        class: 'p-button-danger',
                        c: profile!.ub,
                        click: async (raw, e) => {
                            e.preventDefault();
                            let r = await Requests.news.image.delete(raw.idUUID ?? '');
                            if (g_displayMessageError(c.messages, r)) return;
                            c.getData();
                        },
                    },
                ]}
                header={null}
            />
        </>
    );
};
