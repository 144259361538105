import { GenericListResponseG } from '../../Generic';
import { GenericListRequest, RequestsClass } from '../../Requests';
import { UserModel, UserType } from '../User';

export type Filters = 'idUUID' | 'inprofileiduuid';

export type OrderBy = 'dateCreation';

export const list =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy> & {
			userType: UserType;
		}
	): GenericListResponseG<'users', UserModel> =>
		req.axios.post(`${baseUrl}/list`, data);
