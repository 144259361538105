import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { pair } from '../../../api-ts-bindings/Generic';
import config from '../../../config/config';
import { ConsigneeModel, DataTableArgumentElement } from '../../../types';
import { Button, Form, GArea, GDiv, GE, GInput, GMessages, GState, GView, g_getData, noDataMessage, StringEmpty, useDataObject, Wraper } from '../../Generic';
import { TablePage, TablePagePropsHeader } from '../../generic/DataTableNew';
import { g_displayMessageError, ls, S } from '../../GenericFunctions';

export interface ExamModel {
    description: string;
    tussCode: number;
}

export interface MedicalExamExamCreateModel {
    examDescription: string;
    tussCode?: number;
    howToTake: string;
    medicalExamIdUUID: string;
}

export interface MedicalExamExamModel extends MedicalExamExamCreateModel {
    dateCreation: string;
    dateUpdate: string;
    idUUID: string;
    status: number;
    tussCode: number;
}

export interface MedicalExamModel {
    contractIdUUID: string;
    dateCreation: string;
    dateUpdate: string;
    description: string;
    idUUID: string;
    providerIdUUID: string;
    providerName: string;
    signedDocumentCode: string;
    signedDocumentUrl: string;
    status: number;
    url: string;
}

interface MedicalExamListProps {
    header: TablePagePropsHeader;
    extra?: DataTableArgumentElement<MedicalExamModel>[];
    extraRequest?: (c: TablePage<MedicalExamModel, any>) => {
        [key: string]: any;
    };
    refTable?: (e: TablePage<MedicalExamModel, any> | null) => void;
    checkAcl?: boolean;
    fakename?: string;
}
export const MedicalExamList: React.FunctionComponent<MedicalExamListProps> = (props: MedicalExamListProps) => {
    let { header, extra = [], extraRequest = () => ({}), refTable: ref, fakename, checkAcl } = props;
    return (
        <TablePage<MedicalExamModel, any>
            fakename={fakename}
            checkAcl={checkAcl}
            header={header}
            getData={(c: TablePage) => g_getData(c, S.health.medicalExam.list, 'medicalExams', extraRequest(c))}
            table={() => [{ t: 'd', data: 'description', title: ls('descriptionMedicalExam'), filter: true, sortable: true }, ...extra]}
            ref={ref ?? (() => {})}
        />
    );
};

//MedicalExamExamsModel {

interface MedicalExamProps {
    idUUID: string;
    contractIdUUID: string;
    saved?: (e: string) => void;
    disabled?: boolean;
    createOnIdUUIDEmpty?: boolean;
    exit?: () => void;
    consignee: ConsigneeModel;
}
export const MedicalExam: React.FunctionComponent<MedicalExamProps> = ({ idUUID, contractIdUUID, createOnIdUUIDEmpty, saved, disabled: diabledProp, exit, consignee }) => {
    let { obj, setObj, setObjState } = useDataObject<MedicalExamModel>({} as any);
    let {
        obj: search,
        setObj: setSearch,
        setObjState: setSearchState,
    } = useDataObject<{
        medicalExamExamList: ExamModel[];
        selected: (ExamModel & { dname: string }) | null;
        otp: string;
        dialogOtpVisible: boolean;
        dialogNewVisible: boolean;
        howToTake: string;
    }>({
        howToTake: '',
        selected: null,
        otp: '',
        dialogOtpVisible: false,
        dialogNewVisible: false,
        medicalExamExamList: [],
    });
    let { obj: email, setObjState: setEmailState, setObj: setEmail } = useDataObject<{ visible: boolean; email: string }>({ visible: false, email: consignee.email });
    let [count, setCount] = useState(0);
    let messagesRef: React.MutableRefObject<Messages | null> = useRef(null);
    let examesRef: React.MutableRefObject<TablePage<MedicalExamExamModel> | null> = useRef(null);
    let examFormRef: React.MutableRefObject<HTMLFormElement | null> = useRef(null);
    let preSearchRef: React.MutableRefObject<String> = useRef('');
    let createdNoIdUUIDRef: React.MutableRefObject<boolean> = useRef(false);

    let disabled: boolean = (diabledProp || obj.url) as boolean;

    let getData = async () => {
        let r = await S.health.medicalExam.list({ filters: [pair('iduuid', idUUID)] });
        if (g_displayMessageError(messagesRef.current, r)) return;
        if (r.medicalExams.length === 0) {
            noDataMessage(messagesRef.current);
            return;
        }
        setObjState(r.medicalExams[0]);
    };

    let create = async () => {
        let date = new Date();
        let r = await S.health.medicalExam.add({
            medicalExam: {
                ...obj,
                contractIdUUID: contractIdUUID,
                description: StringEmpty(obj.description)
                    ? `Requisição de Exames ${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(
                          date.getHours()
                      ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
                    : obj.description,
            },
        });
        if (g_displayMessageError(messagesRef.current, r)) return;
        setObjState({ ...r.medicalExam });
        if (saved) saved(r.medicalExam.idUUID);
        if (createOnIdUUIDEmpty && StringEmpty(idUUID)) createdNoIdUUIDRef.current = true;
    };

    useEffect(() => {
        if (!StringEmpty(idUUID)) {
            if (examesRef.current) examesRef.current.getData();
            getData();
        }
        //TODO: improve
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idUUID]);

    useEffect(() => {
        if (!StringEmpty(idUUID)) getData();
        else if (createOnIdUUIDEmpty && !createdNoIdUUIDRef.current) {
            create();
        }
        //! Not needed because its only supposed to be run once
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                disabled={disabled}
                class="p-grid p-col-12"
                obj={obj}
                setObj={setObj}
                fclass={'p-col-9'}
                onSubmit={async e => {
                    e.preventDefault();
                    if (idUUID) {
                        //Update
                        let r = await S.health.medicalExam.update({
                            medicalExam: obj,
                        });
                        if (g_displayMessageError(messagesRef.current, r)) return;
                        if (exit) exit();
                    } else create();
                }}
            >
                <GMessages refM={messagesRef} />
                <GDiv style={{ minHeight: '80%' }} class="p-col-9">
                    <GE d="description" />
                    {ls('medicine')}
                    <TablePage<MedicalExamExamModel, {}>
                        checkAcl={false}
                        header={
                            StringEmpty(idUUID) || !StringEmpty(obj.url)
                                ? null
                                : () => (
                                      <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                          <Button
                                              icon="pi pi-plus"
                                              bttClass="p-button-success"
                                              wraperClass=""
                                              onClick={e => {
                                                  e.preventDefault();
                                                  setSearchState({ ...search, dialogNewVisible: true });
                                              }}
                                              style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                          />
                                      </div>
                                  )
                        }
                        ref={e => {
                            examesRef.current = e;
                            setCount(examesRef.current?.state.data.length ?? 0);
                        }}
                        getData={c => {
                            if (!StringEmpty(idUUID))
                                g_getData(c, S.health.medicalExam.exam.list, 'medicalExamExams', {
                                    filters: [pair('medicalexamiduuid', idUUID), pair('status', '1')],
                                });
                        }}
                        table={() => [
                            { t: 'd', data: 'examDescription' },
                            { t: 'd', data: 'tussCode' },
                            { t: 'date', data: 'dateCreation' },
                            {
                                t: 'btt',
                                icon: 'pi pi-trash',
                                class: 'p-button-danger',
                                click: async (row: MedicalExamExamModel, e) => {
                                    e.preventDefault();
                                    let r = await S.health.medicalExam.exam.status({
                                        idUUID: row.idUUID,
                                        status: 2,
                                    });
                                    if (g_displayMessageError(messagesRef.current, r)) return;
                                    examesRef.current?.getData();
                                },
                                c: disabled,
                            },
                        ]}
                    />
                </GDiv>
                <GDiv class="p-col-3" hidden={StringEmpty(idUUID)}>
                    <GState
                        d="status"
                        custom={{
                            1: { class: 'pi-check', color: 'green' },
                            2: { class: 'pi-times', color: 'red' },
                        }}
                    />
                    <GView d="dateUpdate" />
                    <GView d="dateCreation" />
                </GDiv>
                <div className="p-grid p-col-12">
                    <Button label={idUUID ? 'save' : 'create'} hidden={(idUUID && obj.url && true) as boolean} />
                    <Button
                        label="sign"
                        disabled={count /*(prescriptionMedicineRef.current?.state.data.length ?? 0)*/ === 0}
                        hidden={(!idUUID || obj.url) as boolean}
                        onClick={e => {
                            e.preventDefault();
                            setSearchState({ ...search, dialogOtpVisible: true });
                        }}
                    />
                    <Button
                        label="print"
                        hidden={!obj.url}
                        onClick={e => {
                            e.preventDefault();
                            window.open(config.HEALTH + obj.url);
                        }}
                    />
                    <Button
                        label="sendEmail"
                        hidden={!obj.url}
                        onClick={e => {
                            e.preventDefault();
                            setEmailState({ ...email, visible: true });
                        }}
                    />
                </div>
            </Form>
            <Dialog
                style={{ minHeight: '20vh', minWidth: '20vw' }}
                contentStyle={{ minHeight: '20vh', minWidth: '20vw' }}
                visible={search.dialogOtpVisible}
                onHide={() => setSearchState({ ...search, dialogOtpVisible: false })}
            >
                <Form
                    obj={search}
                    setObj={setSearch}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (!idUUID) return;
                        let r = await S.health.medicalExam.sign({
                            idUUID,
                            systemCertifier: '',
                            otp: search.otp,
                        });
                        setSearchState({ ...search, dialogOtpVisible: false });
                        if (g_displayMessageError(messagesRef.current, r)) return;
                        getData();
                    }}
                >
                    <GE
                        d="otp"
                        extra={{
                            regex: /\d{0,6}/g,
                        }}
                    />
                    <Button label="advance" wraperClass="p-col-4 p-sm-8" />
                </Form>
            </Dialog>

            <Dialog
                contentStyle={{ minHeight: '50vh', minWidth: '60vw' } as React.CSSProperties}
                onHide={() => setSearchState({ ...search, dialogNewVisible: false })}
                visible={search.dialogNewVisible}
            >
                <Form setObj={setSearch} obj={search} formRef={e => (examFormRef.current = e)}>
                    <Wraper id="exam" class="p-col-3">
                        <div className="p-col">
                            <AutoComplete
                                id="exam"
                                value={search.selected}
                                field="dname"
                                suggestions={search.medicalExamExamList}
                                onChange={e => setSearchState({ ...search, selected: e.value })}
                                completeMethod={e => {
                                    let query = e.query;
                                    if (query.length < 3) {
                                        setSearchState({
                                            ...search,
                                            medicalExamExamList: [],
                                        });
                                        return;
                                    }
                                    preSearchRef.current = e.query;
                                    ((rSearch: string) => async () => {
                                        //Search for medicines
                                        let r = await S.health.exam.list({
                                            filters: [pair('description', rSearch)],
                                        });
                                        let list = r.exams.map(a => ({
                                            ...a,
                                            dname: `${a.tussCode} ${a.description}`,
                                        }));
                                        if (g_displayMessageError(messagesRef.current, r) || rSearch !== preSearchRef.current) return;
                                        setSearchState({ ...search, medicalExamExamList: list });
                                    })(query)();
                                }}
                            />
                        </div>
                    </Wraper>
                    <GArea d="howToTake" l="indication" style={{ minHeight: '8em' }} />
                    <Button
                        type="submit"
                        label="add"
                        wraperClass="p-col-2"
                        onClick={async e => {
                            e.preventDefault();
                            if (!examFormRef.current?.reportValidity()) return;
                            let r;
                            if (typeof search.selected === 'string') {
                                r = await S.health.medicalExam.exam.add({
                                    medicalExamExam: {
                                        examDescription: search.selected,
                                        howToTake: search.howToTake,
                                        medicalExamIdUUID: idUUID,
                                    },
                                });
                            } else {
                                r = await S.health.medicalExam.exam.add({
                                    medicalExamExam: {
                                        examDescription: search.selected?.description ?? '',
                                        howToTake: search.howToTake,
                                        tussCode: search.selected?.tussCode ?? 0,
                                        medicalExamIdUUID: idUUID,
                                    },
                                });
                            }
                            // (search.selected?.name ?? '') + ' ' + (search.selected?.presentation ?? ''),
                            if (g_displayMessageError(messagesRef.current, r)) return;
                            setSearchState({
                                ...search,
                                medicalExamExamList: [],
                                selected: null,
                                dialogNewVisible: false,
                                howToTake: '',
                            });
                            examesRef.current?.getData();
                        }}
                    />
                </Form>
            </Dialog>
            <Dialog
                contentStyle={{ minHeight: '40vh', minWidth: '40vw' } as React.CSSProperties}
                onHide={() => setEmailState({ ...email, visible: false })}
                visible={email.visible}
            >
                <Form
                    setObj={setEmail}
                    obj={email}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (StringEmpty(obj.idUUID)) return;
                        let r = await S.health.medicalExam.email({
                            idUUID: obj.idUUID,
                            email: email.email,
                        });
                        g_displayMessageError(messagesRef.current, r);
                        setEmailState({ ...email, visible: false, email: consignee.email });
                    }}
                >
                    <GInput d="email" type="email" fClass="p-col-3" req />
                    <Button type="submit" label="send" wraperClass="p-col-2" />
                </Form>
            </Dialog>
        </>
    );
};
