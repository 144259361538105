import React from 'react';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { TabMenu } from 'primereact/tabmenu';
import { Messages } from 'primereact/messages';
import { Redirect } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import { InputSwitch } from 'primereact/inputswitch';
import { LabelSelector } from './generic/LabelSelector';
import GSelector from './generic/GSelector';
import Services from '../service/Services';

export * from './generic/DataTable';
export * from './generic/ApiResponsesTemplate';
export * from './generic/ListPage';
export { LabelSelector } from './generic/LabelSelector';
export { StateIcon, pair, pairF, g_getData } from './Generic';
export const S = Services;
export const LS = LabelSelector;
export const ls = LabelSelector;
export const lt = a => LabelSelector(a, 'tab');
export const lb = a => LabelSelector(a, 'btt');
export const lm = a => LabelSelector(a, 'messages');

/**
 * @function g_openwindowdialog creates a new window on the centerd in the screen and with a onClose callback function
 * @param {string} url url
 * @param {string} name name of the window, for multiple windows '_blank'
 * @param {string} [exta] features
 * @param {number} [height] height of the new window
 * @param {number} [width] width of the new window
 * @param {string} [resizable] 'on' or 'off'(default)
 * @param {function} onClose onClose
 */
export function g_openWindowDialog(url, name, width = -1, height = -1, resizable = 'off', extra = '', onClose = null, windowF = null) {
    width = width < 0 ? window.screen.width : width;
    height = height < 0 ? window.screen.height : height;
    let x = window.screen.width / 2 - width / 2;
    let y = window.screen.height / 2 - height / 2;
    let w = window.open(
        url,
        name,
        'scrollbars=off,location=off,menubar=off,width=' + width + ',height=' + height + ',left=' + x + ',top=' + y + ',resizable=' + resizable + ',' + extra
    );
    if (!w) return;
    if (windowF) windowF(w);
    if (onClose)
        w.onbeforeunload = () => {
            onClose();
        };
    return w;
}

/**
 * @function get an <span> based on a state
 * @param {number} state 0 = ban, 1 = dot, 2 = tick
 * @param {string} [fontSize] style height
 * @returns <span>
 * @deprecated User StateIcon
 */
export function g_getStateIcon(state, fontSize = '1.87em', custom = null, onClick = null) {
    if (custom && custom[state] === 'none') return null;
    if (custom && custom[state])
        return custom[state].tooltip ? (
            <span onMouseOver={custom.tooltipOver} onMouseLeave={custom.tooltipLeave}>
                <div style={{ display: custom.tooltipFS() ? 'block' : '', marginTop: '0.5em', backgroundColor: '#222d', color: '#fff', maxWidth: '5em' }} className="p-tooltip">
                    {custom[state].tooltip}
                </div>
                <span
                    onClick={onClick}
                    className={'pi ' + custom[state].class}
                    style={{ color: custom[state].color, fontSize: fontSize, ...(onClick ? { cursor: 'pointer' } : {}) }}
                >
                    {custom[state].text ? custom[state].text : ''}
                </span>
            </span>
        ) : (
            <span onClick={onClick} className={'pi ' + custom[state].class} style={{ color: custom[state].color, fontSize: fontSize, ...(onClick ? { cursor: 'pointer' } : {}) }}>
                {custom[state].text ? custom[state].text : ''}
            </span>
        );
    return (
        <span
            onClick={onClick}
            className={'pi ' + (state === 0 ? 'pi-ban' : state === 1 ? 'pi-circle-on' : 'pi-check')}
            style={{ color: state === 0 ? 'red' : state === 1 ? 'grey' : 'green', fontSize: fontSize, ...(onClick ? { cursor: 'pointer' } : {}) }}
        />
    );
}

/**
 * @function gets a JSX wraper
 * @deprecated change to g_wraper
 * @param {string} id for the label to link to if id null does not create <label>
 * @param {string} label label text
 * @param body JSX ELEMENT
 * @param {boolean} hidden hides the wraper
 * @param {string} overideClass replaces the div around label class
 * @returns JSX ELEMENT
 */
export function g_getWraper(id, label, body, hidden = false, overideClass = 'p-col-3', orverideGrid = 'p-grid') {
    if (!orverideGrid) {
        return g_separateWraper(id, label, body, hidden, overideClass);
    }
    if (!id)
        return (
            <div className={orverideGrid} style={{ display: hidden ? 'none' : '' }}>
                <div className={overideClass}>{label}</div>
                {body}
            </div>
        );
    return (
        <div className={orverideGrid} style={{ display: hidden ? 'none' : '' }}>
            <div className={overideClass}>
                <label htmlFor={id}>{label}</label>
            </div>
            {body}
        </div>
    );
}
export function g_separateWraper(id, label, body, hidden = false, overideClass = 'p-col-3') {
    if (!id) return [<div className={overideClass}>{label}</div>, body];
    return [
        <div className={overideClass}>
            <label htmlFor={id}>{label}</label>
        </div>,
        body,
    ];
}

/**
 * @function gets a JSX wraper
 * @param {string} id for the label to link to if id null does not create <label>
 * @param {string | undefined} label label text
 * @param {boolean} hidden hides the wraper
 * @param {string} overideClass replaces the div around label class
 * @returns JSX ELEMENT
 */
export function g_wraper(id, label, body, hidden = false, className = 'p-col-3', gridless = false, reversed = false) {
    if (hidden) return null;
    if (!label) return body;
    let labelbody = (
        <div key={`${id}divlable`} className={className}>
            <label key={`${id}label`} htmlFor={id}>
                {label}
            </label>
        </div>
    );
    if (!id)
        labelbody = (
            <div key={`${id}divlabel`} className={className}>
                {label}
            </div>
        );
    if (gridless && reversed) return [body, labelbody];
    if (gridless) return [labelbody, body];
    if (reversed)
        return (
            <div key={`${id}div`} className="p-grid">
                {' '}
                {labelbody} {body}{' '}
            </div>
        );
    return (
        <div key={`${id}div${label}`} className="p-grid">
            {' '}
            {labelbody} {body}{' '}
        </div>
    );
}

/**
 * @deprecated use g_template_button
 */
export function g_buttonTemplate(
    label,
    icon = '',
    extraclass = 'p-col-1',
    hidden = false,
    onClick = null,
    disabled = false,
    overideClassWraper = 'p-col-3',
    tooltip = '',
    warper = true
) {
    let body = <Button tooltip={tooltip} icon={icon} label={label} className={extraclass} onClick={e => onClick(e)} disabled={disabled} />;
    if (!onClick) body = <Button tooltip={tooltip} icon={icon} label={label} className={extraclass} disabled={disabled} />;
    return warper ? (
        <div style={{ display: hidden ? 'none' : '' }} className={overideClassWraper}>
            {body}
        </div>
    ) : (
        body
    );
}

/**
 * @deprecated use g_template_input
 * @param {string} id for the field
 * @param {any} value to put in the field
 * @param {string} label to be put on the field
 * @param {boolean} req required
 * @param {this} contex this
 * @param {boolean} disabled disabled
 * @param {boolean} hidden hidden
 * @param {Function} handleChange a function to handle the change in this field default g_handleChange
 * @param {any} handleChangeExtraData extra data to send to the handleChange function
 */
export function g_inputTemplate(
    id,
    value,
    label,
    contex,
    req = false,
    disabled = false,
    hidden = false,
    handleChange = g_handleChange,
    handleChangeExtraData = {},
    tooltip = '',
    overideClass = 'p-col-5',
    overideClassWraper = 'p-col-3',
    type = '',
    overideGrid = 'p-grid',
    doubleClick = () => {}
) {
    let body = (
        <div className={overideClass}>
            <InputText
                tooltip={tooltip}
                type={type}
                value={value}
                onChange={e => handleChange(e, handleChangeExtraData, contex)}
                id={id}
                disabled={disabled}
                required={req}
                onDoubleClick={doubleClick}
            />{' '}
        </div>
    );
    return g_getWraper(id, label, body, hidden, overideClassWraper, overideGrid);
}

/**
 * @deprecated use g_template_inputArea
 * @param {string} id for the field
 * @param {any} value to put in the field
 * @param {string} label to be put on the field
 * @param {boolean} req required
 * @param {this} contex this
 * @param {boolean} disabled disabled
 * @param {boolean} hidden hidden
 * @param {Function} handleChange a function to handle the change in this field default g_handleChange
 * @param {any} handleChangeExtraData extra data to send to the handleChange function
 */
export function g_inputAreaTemplate(
    id,
    value,
    label,
    contex,
    req = false,
    disabled = false,
    hidden = false,
    handleChange = g_handleChange,
    handleChangeExtraData = {},
    tooltip = '',
    overideClass = 'p-col-5',
    overideClassWraper = 'p-col-3',
    style = {}
) {
    let body = (
        <div className={overideClass}>
            <InputTextarea
                style={style}
                tooltip={tooltip}
                value={value}
                onChange={e => handleChange(e, handleChangeExtraData, contex)}
                id={id}
                disabled={disabled}
                required={req}
            />{' '}
        </div>
    );
    return g_getWraper(id, label, body, hidden, overideClassWraper);
}

/**
 * @deprecated change to g_template_inputMask
 * @param {string} id for the field
 * @param {any} value to put in the field
 * @param {string} label to be put on the field
 * @param {string} mask mask
 * @param {boolean} req required
 * @param {this} contex this
 * @param {boolean} disabled disabled
 * @param {boolean} hidden hidden
 * @param {Function} handleChange a function to handle the change in this field default g_handleChange
 * @param {any} handleChangeExtraData extra data to send to the handleChange function
 */
export function g_inputMaskTemplate(
    id,
    value,
    label,
    contex,
    mask,
    placeholder = null,
    req = false,
    disabled = false,
    hidden = false,
    handleChange = g_handleChange,
    handleChangeExtraData = {},
    tooltip = '',
    overideClass = 'p-col-5',
    overideClassWraper = 'p-col-3',
    overideGrid = 'p-grid'
) {
    let body = (
        <div className={overideClass}>
            <InputMask
                placeholder={placeholder}
                tooltip={tooltip}
                value={value}
                onChange={e => handleChange(e, handleChangeExtraData, contex)}
                id={id}
                disabled={disabled}
                required={req}
                mask={mask}
                autoClear={false}
            />{' '}
        </div>
    );
    return g_getWraper(id, label, body, hidden, overideClassWraper, overideGrid);
}

/**
 * @function handles changes
 * @param {Event} e event
 * @param {any} handleChangeExtraData {}; ex: {regex: 'xxx'} -> applies regex; {data: 'xxx'} -> makes the value change in state 'xxx'; {replace: {filter: 'xxx', value: 'yyy'}} -> replaces 'xxx' with 'yyy'
 * @param {this} contex
 */
export function g_handleChange(e, extra, context, callback = () => {}) {
    if (e.preventDefault) e.preventDefault();
    callback = extra.callback ?? callback;
    let target = e?.target;
    let value = (target?.value ?? '') + '';
    let id = target?.id ?? '';
    let data = undefined;
    if (extra.editor) {
        id = extra.id;
        value = e.htmlValue;
    }
    if (!id) return;
    if (!extra['data']) extra['data'] = 'data';
    let toSet = {};
    if (extra.checkBox) {
        if (typeof context.state[extra['data']] == 'object') {
            data = context.state[extra['data']];
            if (!data) return;
            data[id] = data[id] === 1 ? 0 : 1;
            toSet[extra['data']] = data;
        } else {
            data[id] = data[id] === 1 ? 0 : 1;
            toSet[extra['data']] = value;
        }
        context.setState(toSet, callback);
        return;
    } else if (extra.switch) {
        if (typeof context.state[extra['data']] == 'object') {
            data = context.state[extra['data']];
            if (!data) return;
            data[id] = value === 'true' ? '1' : '0';
            toSet[extra['data']] = data;
        } else {
            data[id] = value === 'true' ? '1' : '0';
            toSet[extra['data']] = value;
        }
        context.setState(toSet, callback);
        return;
    } else if (extra.multiple) {
        value = e.value;
        if (typeof context.state[extra['data']] == 'object') {
            data = context.state[extra['data']];
            if (!data) return;
            data[id] = value;
            toSet[extra['data']] = data;
        } else {
            toSet[extra['data']] = value;
        }
        context.setState(toSet, callback);
        return;
    }

    if (typeof context.state[extra['data']] == 'object') {
        data = context.state[extra['data']];
        if (!data) return;
        if (extra['replace']) value = value.replace(extra['replace'].filter, extra['replace'].value);
        if (extra['regex'] && (value.match(extra['regex']) === null || value.match(extra['regex'])[0] !== value)) return;
        data[id] = value;
        toSet[extra['data']] = data;
    } else {
        if (extra['replace']) value = value.replace(extra['replace'].filter, extra['replace'].value);
        if (extra['regex'] && (value.match(extra['regex']) === null || value.match(extra['regex'])[0] !== value)) return;
        toSet[extra['data']] = value;
    }
    if (extra['replace']) value = value.replace(extra['replace'].filter, extra['replace'].value);
    if (extra['regex'] && (value.match(extra['regex']) === null || value.match(extra['regex'])[0] !== value)) return;
    context.setState(toSet, callback);
}

/**
 * @function handles changes
 * @param {Event} e event
 * @param {any} handleChangeExtraData {}; ex: {regex: 'xxx'} -> applies regex; {data: 'xxx'} -> makes the value change in state 'xxx'; {replace: {filter: 'xxx', value: 'yyy'}} -> replaces 'xxx' with 'yyy'
 * @param {this} contex
 */
export function g_handleChangeHook(e, extra, [data, setData]) {
    if (e.preventDefault) e.preventDefault();
    let callback = extra.callback ?? (() => {});
    let target = e?.target;
    let value = (target?.value ?? '') + '';
    let id = target?.id ?? '';
    if (extra.editor) {
        id = extra.id;
        value = e.htmlValue;
    }
    if (!id && typeof data == 'object') return;
    if (!extra['data']) extra['data'] = 'data';
    if (extra.checkBox) {
        if (typeof data == 'object') {
            data[id] = data[id] === 1 ? 0 : 1;
        } else {
            data = data === 1 ? 0 : 1;
        }
        setData(data);
        callback();
        return;
    } else if (extra.switch) {
        if (typeof data == 'object') {
            data[id] = value === 'true' ? '1' : '0';
        } else {
            data = value === 'true' ? '1' : '0';
        }
        setData(data);
        callback();
        return;
    }

    if (typeof data == 'object') {
        if (extra['replace']) value = value.replace(extra['replace'].filter, extra['replace'].value);
        if (extra['regex'] && (value.match(extra['regex']) === null || value.match(extra['regex'])[0] !== value)) return;
        data[id] = value;
    } else {
        if (extra['replace']) value = value.replace(extra['replace'].filter, extra['replace'].value);
        if (extra['regex'] && (value.match(extra['regex']) === null || value.match(extra['regex'])[0] !== value)) return;
        data = value;
    }
    if (extra['replace']) value = value.replace(extra['replace'].filter, extra['replace'].value);
    if (extra['regex'] && (value.match(extra['regex']) === null || value.match(extra['regex'])[0] !== value)) return;
    setData(data);
    callback();
}

/**
 * @deprecated use g_template_dropdown
 * @param {string} id for the field
 * @param {any} value to put in the field
 * @param {string} label to be put on the field
 * @param {boolean} req required
 * @param {this} context this
 * @param {*} options list of options for the filed
 * @param {string} placeholder placeholder
 * @param {boolean} req required
 * @param {boolean} disabled disabled
 * @param {boolean} hidden hidden
 * @param {Function} handleChange a function to handle the change in this field default g_handleChange
 * @param {any} handleChangeExtraData extra data to send to the handleChange function
 */
export function g_dropdownTemplate(
    id,
    value,
    label,
    context,
    options = [],
    placeholder = 'selectoption',
    req = false,
    disabled = false,
    hidden = false,
    handleChange = g_handleChange,
    handleChangeExtraData = {},
    overideClass = 'p-col-5',
    overideClassWraper = 'p-col-3',
    enbablefilter = false
) {
    let body = (
        <div className={overideClass}>
            <Dropdown
                filter={enbablefilter}
                filterBy="label, value"
                options={options}
                placeholder={placeholder}
                value={value}
                onChange={e => handleChange(e, handleChangeExtraData, context)}
                id={id}
                disabled={disabled}
                required={req}
            />{' '}
        </div>
    );
    return g_getWraper(id, label, body, hidden, overideClassWraper);
}

/**
 * @function uses dateformat to format dates default format dd-mm-yyyy
 * @param {*} date
 * @param {boolean} extended date format becomes dd-mm-yyyy hh:mm:ss
 * @returns a span with the date inside
 */
export function g_getDateTemplate(date, extended = true, table = false, bodyless = false) {
    if (!date || !date.match(/^[\d-]{10}T[\d:]{8}.*/)) return <span></span>;
    let matched = date.match(/^(\d{4})-(\d{2})-(\d{2})T([\d:]{8}).*/);
    let formated = matched[3] + '-' + matched[2] + '-' + matched[1] + (extended ? ' ' + matched[4] : ''); //dateformat(date, 'dd-mm-yyyy' + (extended ? ' HH:MM:ss': ''))
    return bodyless ? (
        date ? (
            formated
        ) : (
            ''
        )
    ) : !table ? (
        <span style={{ verticalAlign: 'center', paddingTop: '.5em', paddingBottom: '.5m' }}>{date ? formated : ''}</span>
    ) : (
        <p style={{ margin: '0px', textAlign: 'right' }}>{date ? formated : ''}</p>
    );
}

/**
 * @deprecated Use g_template_dialog
 * @function Gets a dilog template
 * @param {string} text text fot the dialog
 * @param {string} id id for the dialog open close control must be unique
 * @param {this} context this
 * @param {Function} onConfirm callback to on confirm
 * @param {string} label Button label
 * @param {string} confirmText text fpr the confirm button
 * @param {string} denyText text fpr the deney button
 * @param {string} icon icon for the button
 * @param {string} extraclass className for the button
 * @param {boolean} hidden hidden
 * @param {boolean} disabled disable
 * @param {string} classBttConfirm className for the confirm button
 * @param {string} classBttDeny className for the deney button
 * @param {string} iconBttConfirm icon for the confirm button
 * @param {string} iconBttDeny icon for the deny button
 * @param {string} height style height
 * @param {string} classWraper className override for the warper
 */
export function g_dialogTemplate(
    text,
    id,
    context,
    onConfirm,
    label,
    confirmText = 'Sim',
    denyText = 'Não',
    icon = '',
    extraclass = 'p-col-5',
    hidden = false,
    disabled = false,
    classBttConfirm = 'p-button-danger',
    classBttDeny = '',
    iconBttConfirm = '',
    iconBttDeny = '',
    height = 'auto',
    classWraper = 'p-col-5'
) {
    let footer = (
        <div>
            <Button
                label={confirmText}
                className={classBttConfirm}
                icon={iconBttConfirm}
                onClick={e => {
                    e.preventDefault();
                    let toSet = {};
                    toSet['dialog_state_' + id] = !context.state['dialog_state_' + id];
                    context.setState(toSet);
                    onConfirm(e);
                }}
            />
            <Button
                label={denyText}
                className={classBttDeny}
                icon={iconBttDeny}
                onClick={e => {
                    e.preventDefault();
                    let toSet = {};
                    toSet['dialog_state_' + id] = !context.state['dialog_state_' + id];
                    context.setState(toSet);
                }}
            />
        </div>
    );
    let body = (
        <div style={{ display: hidden ? 'none' : '' }} className={classWraper}>
            <Button
                label={label}
                icon={icon}
                className={extraclass}
                onClick={e => {
                    e.preventDefault();
                    let toSet = {};
                    toSet['dialog_state_' + id] = true;
                    context.setState(toSet);
                }}
                disabled={disabled}
                style={{ height: height }}
            />
            <Dialog
                visible={context.state['dialog_state_' + id]}
                onHide={() => {
                    let toSet = {};
                    toSet['dialog_state_' + id] = false;
                    context.setState(toSet);
                }}
                footer={footer}
                style={{ width: '50vw' }}
                header={label}
            >
                {text}
            </Dialog>
        </div>
    );
    return body;
}

/**
 * @param {this} context this.state.items for the items
 * @param {*} items
 * @param {*} active
 * @param {*} renders
 * @param {*} between
 */
export function g_tabViewRenderOptions(
    context,
    renders = [],
    between = null,
    onChangeEventCallback = null,
    items = null,
    activeItem = null,
    activeChange = null,
    ref = () => null,
    style = undefined
) {
    let target = 0;
    if (context.state.activeItem) target = context.state.items.indexOf(context.state.activeItem);
    let middle = <Messages ref={e => (context.messages = e)} />;
    if (between) middle = between;
    return (
        <div style={style}>
            <TabMenu
                ref={e => ref(e)}
                model={items ? items : context.state.items}
                activeItem={typeof activeItem === 'function' ? activeItem() : context.state.activeItem}
                onTabChange={e =>
                    typeof activeChange === 'function'
                        ? activeChange(e.value)
                        : context.setState({ activeItem: e.value }, onChangeEventCallback !== null ? () => onChangeEventCallback(e) : () => {})
                }
            />
            {middle}
            {renders[target]}
        </div>
    );
}

/**
 * @deprecated use checkLoginAcl
 * @param {*} generetaterender
 * @param {*} checkAcl
 * @param {*} fakename
 * @returns
 */
export function g_checkLoginACL(generetaterender, checkAcl = true, fakename = null) {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return <Redirect to="/login" />;
    if (!checkAcl) return generetaterender({ r: 1, u: 1, d: 1, c: 1 });
    let url;
    if (fakename !== null && fakename !== '') url = fakename;
    else url = window.location.hash.substr(2);
    url = url.indexOf('?') === -1 ? url : url.split('?')[0];
    let profile = loggedUserN.profileACLs.filter(v => v.type === 4 && v.r === 1).filter(v => v.tag === url);
    return profile.length !== 0 ? generetaterender(profile[0]) : <span>Sem Permissões</span>;
}

/**
 * @function g_checkMode Checks if mode is set to the correct value
 * @param {*} context
 * @param {*} e
 * @param {*} idUUID
 * @param {Function} callBack
 */
export function g_checkMode(context, e, idUUID, callBack = null) {
    if (!context.state.mode && ((e.u !== 1 && idUUID !== null) || (e.c !== 1 && idUUID === null))) context.setState({ mode: true });
    if (callBack !== null) return callBack(e);
}

export function g_getAcls(type, url) {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return [];
    let profile = loggedUserN.profileACLs.filter(v => v.type === type).filter(v => (v.tag.indexOf('#') === -1 ? v.tag : v.tag.split('#')[0]) === url);
    return profile.length !== 0 ? profile : [];
}

export function g_getDashbords() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return [];
    let profile = loggedUserN.profileACLs
        .filter(v => v.type === 4 && v.r === 1)
        .filter(v => v.tag.startsWith('dashboard-'))
        .map(v => v.tag);
    return profile.length !== 0 ? profile : [];
}

export function g_getReports() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return [];
    let profile = loggedUserN.profileACLs
        .filter(v => v.type === 4 && v.r === 1)
        .filter(v => v.tag.startsWith('report-'))
        .map(v => v.tag);
    return profile.length !== 0 ? profile : [];
}

/**
 * @deprecated
 * @param {j} type
 * @param {*} acl
 * @returns
 */
export function g_checkAlc(type, acl) {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return null;
    let profile = [];
    try {
        profile = loggedUserN.profileACLs.filter(v => v.type === type).filter(v => v.tag === acl);
    } catch (_) {
        localStorage.removeItem('loggedUserN');
        window.location.href = '/login';
        window.location.reload();
        return null;
    }
    return profile.length !== 0 ? profile : null;
}

export function g_entities() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return '';
    return loggedUserN.associatedEntities;
}

export function g_consignators() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return '';
    return loggedUserN.consignators;
}

export function g_financiers() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return '';
    return loggedUserN.financiers;
}

export function g_providers() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return '';
    return loggedUserN.providers;
}

export function g_uuid() {
    let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
    if (loggedUserN === null) return '';
    return loggedUserN.uuid;
}

export function g_displayMessageError(messages, response) {
    if (!messages) {
        console.log('no messages');
        return !(response && response.code === 0);
    }
    if (response && response.code === 0) return false;
    if (!response) messages.show({ severity: 'error', summary: 'Não foi possivel contactar os serviços do Numo!', life: 10000 });
    else {
        let middle = [];
        if (response.messages)
            response.messages.forEach(v => {
                let m = '\u2022' + v.key + ':' + v.value;
                middle.push(
                    <div key={v.key} style={{ marginLeft: '2em' }}>
                        {m}
                    </div>
                );
            });
        let detail = <div>{middle}</div>;
        if (messages.show) messages.show({ severity: response.code === 101 ? 'warn' : 'error', summary: `${response.description}`, detail: detail, life: 15000 });
    }
    return true;
}

export function g_entityFilter(inputfilter, keyOne, keyMulti, type, acl, ent = g_entities()) {
    let filter = [];
    let showMore = false;
    if (g_checkAlc(type, acl)) {
        filter = inputfilter;
        showMore = true;
    } else if (ent.indexOf(',') !== -1) {
        filter = [...inputfilter, { key: keyMulti, value: ent }];
        showMore = true;
    } else if (ent) {
        filter = [...inputfilter, { key: keyOne, value: ent }];
    } else return null;
    return [filter, showMore];
}

export function g_nullarrayresponse(context) {
    if (!context.messages) return;
    context.messages.show({ severity: 'warn', summary: lm('dataNotRecorded') });
    context.setState({ mode: true });
}

export function g_getEditColumn(url = () => '', width = 1000, height = 450, show = true, key = 0, callback = () => {}) {
    if (!show) return null;
    return (
        <Column
            key={key}
            style={{ textAlign: 'center' }}
            header=""
            body={raw =>
                g_template_button(
                    '',
                    'pi pi-pencil',
                    false,
                    () => g_openWindowDialog(url(raw), raw.idUUID ? raw.idUUID : '_blank', width, height, 'off', '', callback),
                    false,
                    '',
                    'p-button-warning'
                )
            }
        />
    );
}

export async function g_getDataHook(
    [data = {}, setData = () => {}],
    apiRequest = () => {
        return null;
    },
    processData = () => {},
    overideData = {},
    messages = null
) {
    let indata = {
        filters: data.filter,
        orderBy: data.orderBy,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        ...overideData,
    };
    let response = await apiRequest(indata);
    if (g_displayMessageError(messages, response)) return;
    if (typeof processData === 'object') {
        processData.f(response[processData.t]);
        setData({
            ...data,
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal,
            pageSize: response.pageSize,
        });
    } else if (typeof processData === 'function') {
        processData(response);
        setData({
            ...data,
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal,
            pageSize: response.pageSize,
        });
    } else {
        setData({
            ...data,
            pageTotal: response.pageTotal,
            recordsTotal: response.recordsTotal,
            pageSize: response.pageSize,
            data: response[processData],
        });
    }
}

export async function g_getDataFields(
    context,
    apiRequest = () => {
        return null;
    },
    responseTarget = '',
    idTarget = '',
    idValue = '',
    processData = () => {},
    overideData = {},
    messages = null
) {
    let response = await apiRequest(overideData);
    if (g_displayMessageError(messages, response)) return;
    if (typeof processData === 'string') {
        let state = {};
        state[processData] = response[responseTarget].map(e => {
            return { label: e[idTarget], value: String(e[idValue]) };
        });
        context.setState(state);
    } else
        processData(
            response[responseTarget].map(e => {
                return { label: e[idTarget], value: String(e[idValue]) };
            }),
            response
        );
}

export async function g_ChangeStatus(
    context,
    apiRequest = () => {
        return null;
    },
    callBack = () => {},
    message = '',
    messages = null,
    e = null
) {
    if (e?.preventDefault) e.preventDefault();
    let response = await apiRequest();
    if (g_displayMessageError(messages ?? context.messages, response)) return;
    if (message && (messages ?? context.messages)) (messages ?? context.messages).show({ severity: 'success', summary: message });
    callBack();
}

// g_templates

/**
 * @function Must be gridless
 */
export function g_copy_wrapper(context, id, label, body = [], hidden = false, classNameWarper = 'p-col-3', gridless = false, copyedMessage = 'Copiado!') {
    if (hidden) return null;
    let span = (
        <span
            onMouseOver={() => {
                let state = {};
                state[`copy_${id}`] = true;
                context.setState(state);
            }}
            onMouseLeave={() => {
                let state = {};
                state[`copy_${id}`] = false;
                context.setState(state);
            }}
        >
            <span
                className="pi pi-clone"
                style={{ display: context.state[`copy_${id}`] ? '' : 'none', fontSize: '1.25em', cursor: 'pointer' }}
                onClick={() => {
                    let e = document.getElementById(id);
                    if (!e.value) {
                        if (document.selection) {
                            //IE
                            let range = document.body.createTextRange();
                            range.moveToElementText(e);
                            range.select();
                            document.execCommand('copy');
                            document.selection.empty();
                        } else if (window.getSelection) {
                            //others
                            window.getSelection().removeAllRanges();
                            let range = document.createRange();
                            range.selectNode(e);
                            window.getSelection().addRange(range);
                            document.execCommand('copy');
                            window.getSelection().removeAllRanges();
                        }
                    } else {
                        e.select();
                        e.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                        /* document.selection.empty(); */
                    }
                    let state = {};
                    state[`copy_${id}_message`] = true;
                    context.setState(state);
                    if (context[`copy_${id}_timeout`]) clearTimeout(context[`copy_${id}_timeout`]);
                    context[`copy_${id}_timeout`] = setTimeout(() => {
                        let state = {};
                        state[`copy_${id}_message`] = false;
                        context.setState(state);
                        context[`copy_${id}_timeout`] = null;
                    }, 2500);
                }}
            ></span>
            <span style={{ display: context.state[`copy_${id}_message`] && context.state[`copy_${id}`] ? '' : 'none' }}>{copyedMessage}</span>
        </span>
    );

    if (!label)
        return (
            <div
                onMouseOver={() => {
                    let state = {};
                    state[`copy_${id}`] = true;
                    context.setState(state);
                }}
                onMouseLeave={() => {
                    let state = {};
                    state[`copy_${id}`] = false;
                    context.setState(state);
                }}
            >
                {body}
                {span}
            </div>
        );

    let labelbody = (
        <div className={classNameWarper}>
            <label htmlFor={id}>{label}</label>
        </div>
    );
    if (!id) labelbody = <div className={classNameWarper}>{label}</div>;
    if (gridless)
        return [
            labelbody,
            <div
                onMouseOver={() => {
                    let state = {};
                    state[`copy_${id}`] = true;
                    context.setState(state);
                }}
                onMouseLeave={() => {
                    let state = {};
                    state[`copy_${id}`] = false;
                    context.setState(state);
                }}
            >
                {body}
            </div>,
            span,
        ];
    return (
        <div
            className="p-grid"
            onMouseOver={() => {
                let state = {};
                state[`copy_${id}`] = true;
                context.setState(state);
            }}
            onMouseLeave={() => {
                let state = {};
                state[`copy_${id}`] = false;
                context.setState(state);
            }}
        >
            {labelbody}
            <div className="p-col">
                {body}
                {span}
            </div>
        </div>
    );
}

/**
 * @deprecated use g_template_textcopy
 */
export function g_template_copy(context, label, text, id, hidden = false, classWraper = 'p-col-3', classNameWarper = 'p-col-3', gridless = false) {
    return g_copy_wrapper(context, id, label, [<label id={id}>{text}</label>], hidden, classNameWarper, gridless);
}

export function g_template_textcopy(context, label, text, id, hidden = false, classNameWarper = 'p-col-3', gridless = false) {
    return g_copy_wrapper(context, id, label, [<label id={id}>{text}</label>], hidden, classNameWarper, gridless);
}

export function g_template_checkBox(
    context,
    id,
    value,
    label,
    disabled = false,
    hidden = false,
    classWraper = 'p-col-3',
    className = 'p-col-1',
    handleChangeExtraData = {},
    handleChange = g_handleChange,
    req = false,
    tooltip = ''
) {
    return g_wraper(
        id,
        label,
        <div className={className}>
            <Checkbox
                id={id}
                tooltip={tooltip}
                required={req}
                onChange={disabled ? () => {} : e => handleChange(e, { checkBox: true, ...handleChangeExtraData }, context)}
                disabled={disabled}
                checked={value}
            />
        </div>,
        hidden,
        classWraper
    );
}

/**
 * @function treats dates to the corret format
 * @returns the treated date
 * @deprecated use g_treatDate
 */
export function g_treatData(date) {
    if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000`;
    }
    return '';
}

/**
 * @function treats dates to the corret format
 * @returns the treated date
 */
export function g_treatDate(date, widthTime = false, end = false, dateZ = false) {
    if (!date) return '';
    let d = new Date(date);
    let endT = dateZ ? 'Z' : '';
    if (end) return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T23:59:59.000${endT}`;
    if (!widthTime) return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T00:00:00.000${endT}`;
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T${String(d.getHours()).padStart(2, '0')}:${String(
        d.getMinutes()
    ).padStart(2, '0')}:00.000${endT}`;
}

export function g_treatNumric(numeric) {
    if (numeric === null || numeric === undefined || numeric === '') return '';
    if (!numeric.match) return numeric;
    if (numeric.match(/\d+(,\d{0,9})?/)) {
        return numeric.replace(/,/g, '.');
    }
    return numeric;
}

export function g_treatNumricR(numeric) {
    if (numeric === null || numeric === undefined || numeric === '') return '';
    numeric = String(numeric);
    if (!numeric.match) return numeric;
    if (numeric.match(/\d+(.\d{0,9})?/)) {
        return numeric.replace(/\./g, ',');
    }
    return numeric;
}

export function g_template_numeric(
    context,
    id,
    value,
    label,
    disabled = false,
    hidden = false,
    req = true,
    className = 'p-col-7',
    classNameWarper = 'p-col-3',
    tooltip = '',
    handleChangeExtraData = {},
    type = '',
    bigNumeric = false,
    handleChange = g_handleChange,
    canNegative = false
) {
    let money = new RegExp(`${canNegative ? '-?' : ''}(\\d+(,\\d{0,${bigNumeric ? 9 : 2}})?|\\d*)`, 'g');
    return g_wraper(
        id,
        label,
        <div className={className}>
            <InputText
                tooltip={tooltip}
                type={type}
                value={value}
                onChange={e => (disabled ? () => {} : handleChange(e, { regex: money, ...handleChangeExtraData }, context))}
                title={'Por favor preencha este campo'}
                id={id}
                disabled={disabled}
                required={req}
            />
        </div>,
        hidden,
        classNameWarper
    );
}

/**
 * @deprecated
 * @param {*} context
 * @param {*} id
 * @param {*} value
 * @param {*} label
 * @param {*} disabled
 * @param {*} hidden
 * @param {*} req
 * @param {*} className
 * @param {*} classNameWarper
 * @param {*} tooltip
 * @param {*} handleChangeExtraData
 * @param {*} type
 * @param {*} handleChange
 * @returns
 */
export function g_template_input(
    context,
    id,
    value,
    label,
    disabled = false,
    hidden = false,
    req = true,
    className = 'p-col-7',
    classNameWarper = 'p-col-3',
    tooltip = '',
    handleChangeExtraData = {},
    type = '',
    handleChange = g_handleChange
) {
    return g_wraper(
        id,
        label,
        <div className={className}>
            <InputText
                title={disabled !== undefined && !disabled ? 'Por favor preencha este campo.' : ''}
                tooltip={tooltip}
                type={type}
                value={value}
                onChange={e => (disabled ? () => {} : handleChange(e, handleChangeExtraData, context))}
                id={id}
                disabled={disabled}
                required={req}
            />
        </div>,
        hidden,
        classNameWarper
    );
}

/**
 * @deprecated USE Button
 */
export function g_template_button(label, icon = '', hidden = false, onClick = null, disabled = false, className = 'p-col-3', extraclass = '', tooltip = '', style = {}) {
    if (hidden) return null;
    let body = <Button tooltip={tooltip} style={style} icon={icon} label={label} className={extraclass} onClick={e => onClick(e)} />;
    if (!onClick || disabled) body = <Button style={style} tooltip={tooltip} icon={icon} label={label} className={extraclass} disabled={disabled} />;
    if (!className) return body;
    return <div className={className}>{body}</div>;
}

/**
 * @deprecated change to DropDown
 * @param {*} context
 * @param {*} id
 * @param {*} value
 * @param {*} label
 * @param {*} options
 * @param {*} disabled
 * @param {*} hidden
 * @param {*} req
 * @param {*} className
 * @param {*} classNameWarper
 * @param {*} enbablefilter
 * @param {*} placeholder
 * @param {*} handleChangeExtraData
 * @param {*} callback
 * @param {*} handleChange
 * @param {*} itemTemplate
 * @param {*} showClear
 * @returns
 */
export function g_template_dropdown(
    context,
    id,
    value,
    label,
    options,
    disabled = false,
    hidden = false,
    req = true,
    className = 'p-col-5',
    classNameWarper = 'p-col-3',
    enbablefilter = false,
    placeholder = '',
    handleChangeExtraData = {},
    callback = () => {},
    handleChange = g_handleChange,
    itemTemplate = null,
    showClear = false
) {
    return g_wraper(
        id,
        label,
        <div className={className}>
            <Dropdown
                itemTemplate={itemTemplate}
                key={`${id}drop`}
                filter={enbablefilter}
                title={'Por favor selecione uma opção'}
                filterBy="label, value"
                options={options}
                placeholder={placeholder}
                value={value}
                onChange={e => (disabled ? () => {} : handleChange(e, handleChangeExtraData, context, callback))}
                id={id}
                disabled={disabled}
                required={req}
                showClear={showClear}
            />
        </div>,
        hidden,
        classNameWarper
    );
}

/**
 * @deprecated
 * @param {*} context
 * @param {*} id
 * @param {*} value
 * @param {*} label
 * @param {*} mask
 * @param {*} req
 * @param {*} disabled
 * @param {*} hidden
 * @param {*} className
 * @param {*} classNameWraper
 * @param {*} tooltip
 * @param {*} placeholder
 * @param {*} handleChangeExtraData
 * @param {*} callback
 * @param {*} handleChange
 * @param {*} style
 * @returns
 */
export function g_template_inputMask(
    context,
    id,
    value,
    label,
    mask,
    req = false,
    disabled = false,
    hidden = false,
    className = 'p-col-5',
    classNameWraper = 'p-col-3',
    tooltip = '',
    placeholder = null,
    handleChangeExtraData = {},
    callback = () => {},
    handleChange = g_handleChange,
    style = {}
) {
    return g_wraper(
        id,
        label,
        <div className={className}>
            <InputMask
                placeholder={placeholder}
                title={'Por favor preencha este campo'}
                tooltip={tooltip}
                value={value}
                onChange={e => (disabled ? () => {} : handleChange(e, handleChangeExtraData, context, callback))}
                id={id}
                disabled={disabled}
                required={req}
                mask={mask}
                style={style}
            />
        </div>,
        hidden,
        classNameWraper
    );
}

export function g_template_switch(
    context,
    id,
    value,
    label,
    disabled = false,
    hidden = false,
    req = true,
    className = 'p-col-7',
    classNameWraper = 'p-col-3',
    tooltip = '',
    style = {},
    handleChangeExtraData = {},
    handleChange = g_handleChange
) {
    return g_wraper(
        id,
        label,
        <div className={className}>
            <InputSwitch
                style={style}
                tooltip={tooltip}
                checked={value === '1' ? 1 : 0}
                onChange={e => (disabled ? () => {} : handleChange(e, { ...handleChangeExtraData, switch: true }, context))}
                title={'Por favor preencha este campo'}
                id={id}
                disabled={disabled}
                required={req}
            />
        </div>,
        hidden,
        classNameWraper
    );
}

/**
 * @deprecated
 * @param {*} context
 * @param {*} id
 * @param {*} value
 * @param {*} label
 * @param {*} disabled
 * @param {*} hidden
 * @param {*} req
 * @param {*} className
 * @param {*} classNameWraper
 * @param {*} tooltip
 * @param {*} style
 * @param {*} handleChangeExtraData
 * @param {*} handleChange
 * @returns
 */
export function g_template_inputArea(
    context,
    id,
    value,
    label,
    disabled = false,
    hidden = false,
    req = true,
    className = 'p-col-7',
    classNameWraper = 'p-col-3',
    tooltip = '',
    style = {},
    handleChangeExtraData = {},
    handleChange = g_handleChange
) {
    return g_wraper(
        id,
        label,
        <div className={className}>
            <InputTextarea
                style={style}
                tooltip={tooltip}
                value={value}
                onChange={e => (disabled ? () => {} : handleChange(e, handleChangeExtraData, context))}
                title={'Por favor preencha este campo'}
                id={id}
                disabled={disabled}
                required={req}
            />
        </div>,
        hidden,
        classNameWraper
    );
}

export function g_template_dialog(
    context,
    text,
    id,
    onConfirm,
    label,
    hidden = false,
    disabled = false,
    classBttConfirm = 'p-button-danger',
    classBttDeny = '',
    iconBttConfirm = '',
    iconBttDeny = '',
    classWraper = 'p-col-2',
    buttonClassName = '',
    confirmText = 'Sim',
    denyText = 'Não',
    icon = ''
) {
    if (hidden) return null;
    let footer = (
        <div>
            <Button
                label={confirmText}
                className={classBttConfirm}
                icon={iconBttConfirm}
                onClick={e => {
                    e.preventDefault();
                    let toSet = {};
                    toSet['dialog_state_' + id] = !context.state['dialog_state_' + id];
                    context.setState(toSet);
                    onConfirm(e);
                }}
            />
            <Button
                label={denyText}
                className={classBttDeny}
                icon={iconBttDeny}
                onClick={e => {
                    e.preventDefault();
                    let toSet = {};
                    toSet['dialog_state_' + id] = !context.state['dialog_state_' + id];
                    context.setState(toSet);
                }}
            />
        </div>
    );
    let body = (
        <div className={classWraper}>
            <Button
                label={label}
                id={id}
                icon={icon}
                className={buttonClassName}
                onClick={e => {
                    e.preventDefault();
                    if (disabled) return;
                    let toSet = {};
                    toSet['dialog_state_' + id] = true;
                    context.setState(toSet);
                }}
                disabled={disabled}
            />
            <Dialog
                visible={context.state['dialog_state_' + id]}
                onHide={() => {
                    let toSet = {};
                    toSet['dialog_state_' + id] = false;
                    context.setState(toSet);
                }}
                footer={footer}
                style={{ width: '50vw' }}
                id={id}
                header={label}
            >
                {text}
            </Dialog>
        </div>
    );
    return body;
}

export function g_template_form(
    context,
    body = () => [],
    buttons = () => [],
    part2 = () => [],
    submitForm = () => {},
    labelSize = 2,
    hasPart2 = false,
    mode = false,
    mainSize = 9,
    smallSize = 3,
    bodyHeight = '75vh',
    ref = () => {}
) {
    let dd = (text, className = 'p-col-2') => <div className={className}>{text}</div>;
    let insideCheck = (toCheck, i, y, def, canbeobject = true) =>
        toCheck !== undefined
            ? toCheck[i] !== undefined && toCheck[i] !== null && typeof toCheck[i] === 'object'
                ? toCheck[i][y] !== undefined
                    ? toCheck[i][y]
                    : y === undefined || y === null
                    ? toCheck[i]
                    : toCheck[i]['_'] !== undefined
                    ? toCheck[i]['_']
                    : (typeof toCheck[i] === 'object' && canbeobject) || typeof toCheck[i] !== 'object'
                    ? toCheck[i]
                    : def
                : toCheck[i] !== undefined
                ? toCheck[i]
                : typeof toCheck === 'object'
                ? toCheck['_'] !== undefined
                    ? toCheck['_']
                    : def
                : toCheck
            : def;

    let tArray = (a, e, i, y) => {
        if (!e.origin) e.origin = context.state.data;
        if (!e.origin) e.origin = [];
        let isInsideArray = y !== undefined && y !== null;
        let type = insideCheck(e.dTypeFs, i, y, e.dTypeF);
        let isDate = e.origin[a] ? String(e.origin[a]).match(/^[\d-]{10}T[\d:]{8}.*/) : false;
        if (e.c) if (insideCheck(e.c, i, y, false, false)) return null;
        let label = insideCheck(e.l, i, y, null, false) === null ? LabelSelector(a, insideCheck(e.prefix, i, y, '_', false)) : insideCheck(e.l, i, y, null, false);
        let cc = '';
        if (e.cc && e.cc['_'] && !insideCheck(e.cc, i, y, null)) cc = e.cc['_'] === 'l' ? 'p-col' : 'p-col-' + e.cc['_'];
        else cc = insideCheck(e.cc, i, y, 'l') === 'l' ? 'p-col' : 'p-col-' + insideCheck(e.cc, i, y, 4);
        let cclass = insideCheck(e.class, i, y, labelSize) === 'l' ? 'p-col' : 'p-col-' + insideCheck(e.class, i, y, labelSize);
        let origin = e.origin;
        if (type === 'view') {
            let body = insideCheck(e.ddless, i, y, false)
                ? isDate
                    ? g_getDateTemplate(origin[a], insideCheck(e.extended, i, y, true), false, true)
                    : origin[a]
                : dd(isDate ? g_getDateTemplate(origin[a], insideCheck(e.extended, i, y, true), false, true) : origin[a], cc);
            return g_wraper(null, label, body, false, cclass, isInsideArray);
        } else if (type === 'copy') {
            return g_template_textcopy(context, label, origin[a], a, false, cclass, isInsideArray);
        } else if (type === 't') {
            if (isDate) return 'Dates can not be treated as a text';
            return g_wraper(
                a,
                label,
                g_template_input(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.itype, i, y, '')
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'text') {
            return (
                <div onClick={insideCheck(e.onClick, i, y, () => null)} className={cc} style={insideCheck(e.style, i, y, {}, false)}>
                    {a}
                </div>
            );
        } else if (type === 'btt') {
            return g_template_button(
                label,
                insideCheck(e.icon, i, y, '', false),
                false,
                insideCheck(e.click, i, y, null, false),
                insideCheck(e.disabled, i, y, mode),
                cc,
                insideCheck(e.extra, i, y, ''),
                insideCheck(e.tooltip, i, y, ''),
                insideCheck(e.style, i, y, {})
            );
        } else if (type === 'spacer') {
            return <div className={cc}></div>;
        } else if (type === 'mask') {
            return g_wraper(
                a,
                label,
                g_template_inputMask(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.mask, i, y, ''),
                    insideCheck(e.req, i, y, false),
                    insideCheck(e.disable, i, y, mode),
                    false,
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.placeholder, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.callback, i, y, () => null)
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'numeric') {
            if (isDate) return 'Dates can not be treated as a text';
            return g_wraper(
                a,
                label,
                g_template_numeric(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.itype, i, y, ''),
                    insideCheck(e.bigNumeric, i, y, false),
                    g_handleChange,
                    insideCheck(e.extra, i, y, {}),
                    insideCheck(e.canNegative, i, y, false)
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'drop') {
            return g_wraper(
                a,
                label,
                g_template_dropdown(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.options, i, y, []),
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.enableFilter, i, y, false),
                    insideCheck(e.placeholder, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.callback, i, y, () => {})
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'phone') {
            if (isDate) return 'Dates can not be treated as a text';
            return g_wraper(
                a,
                label,
                g_template_inputMask(
                    context,
                    a,
                    origin[a],
                    '',
                    '(99) 9999-99999',
                    insideCheck(e.req, i, y, false),
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.placeholder, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.callBacks, i, y, () => {})
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'state') {
            return g_wraper(
                '',
                insideCheck(e.l, i, y, 'Estado'),
                <div className="p-col">{g_getStateIcon(origin[a], insideCheck(e.stateSize, i, y, '1.4em'), insideCheck(e.states, i, y, {}))}</div>,
                false,
                'p-col-7'
            );
        } else if (type === 'dElm') return insideCheck(e.dElm, i, y, null);
        else if (type === 'area') {
            return g_wraper(
                a,
                label,
                g_template_inputArea(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.style, i, y, { minHeight: '7em' }),
                    insideCheck(e.handleExtraData, i, y, {})
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'check') {
            return g_wraper(
                a,
                label,
                g_template_checkBox(context, a, origin[a], '', insideCheck(e.disabled, i, y, mode), false, '', cc, insideCheck(e.handleExtraData, i, y, {}), g_handleChange, false),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'checkr') {
            return g_wraper(
                a,
                label,
                g_template_checkBox(context, a, origin[a], '', insideCheck(e.disabled, i, y, mode), false, '', cc, insideCheck(e.handleExtraData, i, y, {}), g_handleChange, false),
                false,
                cclass,
                isInsideArray,
                true
            );
        } else if (type === 'date') {
            let selectionMode = insideCheck(e.selectionMode, i, y, undefined);
            return g_wraper(
                a,
                label,
                <div className="p-col-2">
                    <Calendar
                        selectionMode={selectionMode}
                        hourFormat={LabelSelector('hourFomart', 'default')}
                        showTime={insideCheck(e.showTime, i, y, false)}
                        timeOnly={insideCheck(e.timeOnly, i, y, false)}
                        minDate={insideCheck(e.minDate, i, y, '')}
                        maxDate={insideCheck(e.maxDate, i, y, '')}
                        yearRange={`1900:${new Date().getFullYear() + 10}`}
                        monthNavigator={true}
                        yearNavigator={true}
                        id={a}
                        dateFormat="dd-mm-yy"
                        value={selectionMode !== undefined ? origin[a] : new Date(origin[a])}
                        onChange={event => g_handleChange(event, insideCheck(e.handleExtraData, i, y, { multiple: selectionMode !== undefined }), context)}
                        disabled={insideCheck(e.disabled, i, y, mode)}
                    />
                </div>,
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'selector') {
            return (
                <GSelector
                    label={label}
                    ref={insideCheck(e.ref, i, y, () => {})}
                    labelwraper={cclass}
                    startV={insideCheck(e.startV, i, y, '', false)}
                    getData={insideCheck(e.getData, i, y, () => {})}
                    gobtt={insideCheck(e.gobtt, i, y, false)}
                    jump={insideCheck(e.jump, i, y, () => {})}
                    tableBody={insideCheck(e.tableBody, i, y, [])}
                    inputtextwarper={cc}
                    disabled={insideCheck(e.disabled, i, y, mode)}
                />
            );
        } else if (type === 'editor') {
            if (insideCheck(e.disabled, i, y, mode))
                return g_wraper(
                    a,
                    label,
                    <div
                        style={{ border: '1px solid grey', marginBottom: '3px', ...insideCheck(e.style, i, y, {}) }}
                        className={cc}
                        dangerouslySetInnerHTML={{ __html: origin[a] ?? '' }}
                    />,
                    false,
                    cclass,
                    isInsideArray
                );
            return g_wraper(
                a,
                label,
                <div className={cc}>
                    <Editor
                        value={origin[a]}
                        style={insideCheck(e.style, i, y, { minHeight: '7em' })}
                        onTextChange={e => g_handleChange(e, { id: a, editor: true, ...insideCheck(e.handleExtraData, i, y, {}) }, context)}
                    />
                </div>,
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'switch') {
            return g_wraper(
                a,
                label,
                g_template_switch(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.style, i, y, {}),
                    insideCheck(e.handleExtraData, i, y, {})
                ),
                false,
                cclass,
                isInsideArray
            );
        }
        return null;
    };

    let process = e => {
        if (!e?.t) return <React.Fragment>{e}</React.Fragment>;
        if (e?.nshow) return null;
        if (e.t === 'array' && e.cg !== undefined && e.cg) return null;
        if (e.t !== 'array' && e.c !== undefined && e.c) return null;
        let { t } = e;
        if (t === 'i') {
            if (e.data && e.id) {
                t = 'text';
            }
        }
        let label = e.label === '' ? '' : e.label ? e.label : LabelSelector(e.id);
        let cc = e.cc ? 'p-col-' + e.cc : 'p-col-2';
        let cl = e.class ? e.class : 'p-col-' + labelSize;
        if (t === 'sep') return [<h1 key={`sep_line_${e.title}`}> </h1>, <h4 key={`sep_line_${e.title}`}>{e.title}</h4>];
        else if (t === 'view') {
            return g_wraper('', e.l, dd(e.d, e.cc ? 'p-col-' + e.cc : 'p-col-2'), e.h, e.class ? e.class : 'p-col-' + labelSize, e.gridless);
        } else if (t === 'array') {
            let p = (a, i, e) => {
                let r = [];
                let p1 = a.map((a, y) => tArray(a, e, i, y));
                for (let ob of p1) {
                    if (ob) r = [...r, ob];
                }
                if (r.length === 0) return null;
                return (
                    <div key={`p${i}`} style={{ padding: 0 }} className="p-grid p-col">
                        {r}
                    </div>
                );
            };
            return <div style={{ padding: 0, ...(e.divStyle ?? {}) }}>{e.filter.map((a, i) => (typeof a === 'string' ? tArray(a, e, i) : p(a, i, e)))}</div>;
        } else if (t === 't') {
            return g_template_input(
                context,
                e.id,
                e.data,
                label,
                e.disabled === undefined ? mode : e.disabled,
                false,
                e.req,
                e.cc,
                e.class ? e.class : 'p-col-' + labelSize,
                e.tooltip,
                e.handleExtraData,
                e.type
            );
        } else if (t === 'b') {
            return g_template_button(
                e.l ? e.l : '',
                e.icon,
                false,
                e.click,
                e.disabled === undefined ? mode : e.disabled,
                'p-col-' + (e.class === undefined ? labelSize : e.class),
                e.extra ? e.extra : '',
                e.tooltip,
                e.style
            );
        } else if (t === 'dil') {
            return g_template_dialog(
                context,
                e.text,
                e.id,
                e.onConfirm,
                e.l,
                false,
                e.disabled === undefined ? mode : e.disabled,
                e.classBttConfirm,
                e.classBttDeny,
                e.iconBttConfirm,
                e.iconBttDeny,
                'p-col-' + (e.class === undefined ? labelSize : e.class),
                e.bttclass,
                e.confirmText,
                e.denyText,
                e.icon
            );
        } else if (t === 'copy') {
            return g_template_textcopy(context, e.l, e.text, e.id, false, e.cc);
        } else if (t === 'drop') {
            return g_wraper(
                e.id,
                label,
                g_template_dropdown(
                    context,
                    e.id,
                    e.data,
                    '',
                    e.options,
                    e.disabled === undefined ? mode : e.disabled,
                    false,
                    e.req,
                    cc,
                    '',
                    e.enableFilter,
                    e.placeholder,
                    e.handleExtraData,
                    e.callback
                ),
                false,
                cl
            );
        }
        return '';
    };
    let part2r = part2(mode);
    return (
        <form ref={ref} className="p-col" onSubmit={submitForm}>
            <div className="p-grid p-fluid">
                <div className={`p-col-12 p-md-${mainSize} p-lg-${mainSize} p-sm-${mainSize} p-fluid contact-form`}>
                    <Panel style={{ border: 'none !important', overflowY: 'auto', height: bodyHeight }}>{React.Children.toArray(body(mode).map(process))}</Panel>
                </div>
                {smallSize === -1 ? null : (
                    <div className={`p-col-12 p-md-${smallSize} p-lg-${smallSize} p-sm-${smallSize}`}>
                        <Panel style={{ borderLeft: hasPart2 ? '1px solid lightGrey' : '', height: '100%' }}>{part2r[0].map(process)}</Panel>
                    </div>
                )}
                {part2r[1]?.map(process)}
            </div>
            <div className="p-fluid p-grid">{buttons(mode).map(process)}</div>
            {part2r[2]?.map(process)}
        </form>
    );
}

export function g_formMaper(context, array, mode = false, labelSize = '3') {
    let dd = (text, className = 'p-col-2') => <div className={className}>{text}</div>;
    let insideCheck = (toCheck, i, y, def, canbeobject = true) =>
        toCheck !== undefined
            ? toCheck[i] !== undefined && toCheck[i] !== null && typeof toCheck[i] === 'object'
                ? toCheck[i][y] !== undefined
                    ? toCheck[i][y]
                    : y === undefined || y === null
                    ? toCheck[i]
                    : toCheck[i]['_'] !== undefined
                    ? toCheck[i]['_']
                    : (typeof toCheck[i] === 'object' && canbeobject) || typeof toCheck[i] !== 'object'
                    ? toCheck[i]
                    : def
                : toCheck[i] !== undefined
                ? toCheck[i]
                : typeof toCheck === 'object'
                ? toCheck['_'] !== undefined
                    ? toCheck['_']
                    : def
                : toCheck
            : def;
    let tArray = (a, e, i, y) => {
        if (!e.origin) e.origin = context.state.data;
        if (!e.origin) e.origin = [];
        let isInsideArray = y !== undefined && y !== null;
        let type = insideCheck(e.dTypeFs, i, y, e.dTypeF);
        let isDate = e.origin[a] ? String(e.origin[a]).match(/^[\d-]{10}T[\d:]{8}.*/) : false;
        if (e.c) if (insideCheck(e.c, i, y, false, false)) return null;
        let label = insideCheck(e.l, i, y, null, false) === null ? LabelSelector(a, insideCheck(e.prefix, i, y, '_', false)) : insideCheck(e.l, i, y, null, false);
        let cc = '';
        if (e.cc && e.cc['_'] && !insideCheck(e.cc, i, y, null)) cc = e.cc['_'] === 'l' ? 'p-col' : 'p-col-' + e.cc['_'];
        else cc = insideCheck(e.cc, i, y, 'l') === 'l' ? 'p-col' : 'p-col-' + insideCheck(e.cc, i, y, 4);
        let cclass = insideCheck(e.class, i, y, labelSize, false) === 'l' ? 'p-col' : 'p-col-' + insideCheck(e.class, i, y, labelSize, false);
        let origin = e.origin;

        let handleChange = insideCheck(e.handleChange, i, y, g_handleChange, false);

        if (type === 'view') {
            let body = insideCheck(e.ddless, i, y, false)
                ? isDate
                    ? g_getDateTemplate(origin[a], insideCheck(e.extended, i, y, true), false, true)
                    : origin[a]
                : dd(isDate ? g_getDateTemplate(origin[a], insideCheck(e.extended, i, y, true), false, true) : origin[a], cc);
            return g_wraper(null, label, body, false, cclass, isInsideArray);
        } else if (type === 'copy') {
            return g_template_textcopy(context, label, origin[a], a, false, cclass, isInsideArray);
        } else if (type === 'btt') {
            return g_template_button(
                label,
                insideCheck(e.icon, i, y, ''),
                false,
                insideCheck(e.click, i, y, null, false),
                insideCheck(e.disabled, i, y, mode),
                cc,
                insideCheck(e.extra, i, y, ''),
                insideCheck(e.tooltip, i, y, ''),
                insideCheck(e.style, i, y, {})
            );
        } else if (type === 'spacer') {
            return <div className={cc}></div>;
        } else if (type === 't') {
            if (isDate) return 'Dates can not be treated as a text';
            return g_wraper(
                a,
                label,
                g_template_input(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.itype, i, y, ''),
                    handleChange
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'text') {
            return g_wraper(
                '',
                '',
                <div style={insideCheck(e.style, i, y, {})} className={cc}>
                    {a}
                </div>,
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'mask') {
            return g_wraper(
                a,
                label,
                g_template_inputMask(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.mask, i, y, ''),
                    insideCheck(e.req, i, y, false),
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.placeholder, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.callback, i, y, () => null)
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'numeric') {
            if (isDate) return 'Dates can not be treated as a text';
            return g_wraper(
                a,
                label,
                g_template_numeric(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.itype, i, y, ''),
                    insideCheck(e.bigNumeric, i, y, false),
                    g_handleChange,
                    insideCheck(e.extra, i, y, insideCheck(e.handleExtraData, i, y, {})),
                    insideCheck(e.canNegative, i, y, false)
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'drop') {
            return g_wraper(
                a,
                label,
                g_template_dropdown(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.options, i, y, []),
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.enableFilter, i, y, false),
                    insideCheck(e.placeholder, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.callback, i, y, () => {}),
                    handleChange,
                    insideCheck(e.template, i, y, null),
                    insideCheck(e.showClear, i, y, false, false)
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'phone') {
            if (isDate) return 'Dates can not be treated as a text';
            return g_wraper(
                a,
                label,
                g_template_inputMask(
                    context,
                    a,
                    origin[a],
                    '',
                    '(99) 9999-99999',
                    insideCheck(e.req, i, y, false),
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.placeholder, i, y, ''),
                    insideCheck(e.handleExtraData, i, y, {}),
                    insideCheck(e.callBacks, i, y, () => {})
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'state') {
            return g_wraper(
                '',
                insideCheck(e.l, i, y, 'Estado'),
                <div className="p-col">{g_getStateIcon(origin[a], insideCheck(e.stateSize, i, y, '1.4em'), insideCheck(e.states, i, y, {}))}</div>,
                false,
                'p-col-7'
            );
        } else if (type === 'dElm') return insideCheck(e.dElm, i, y, null);
        else if (type === 'area') {
            return g_wraper(
                a,
                label,
                g_template_inputArea(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.style, i, y, { minHeight: '7em' }),
                    insideCheck(e.handleExtraData, i, y, {})
                ),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'check') {
            return g_wraper(
                a,
                label,
                g_template_checkBox(context, a, origin[a], '', insideCheck(e.disabled, i, y, mode), false, '', cc, insideCheck(e.handleExtraData, i, y, {}), g_handleChange, false),
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'checkr') {
            return g_wraper(
                a,
                label,
                g_template_checkBox(context, a, origin[a], '', insideCheck(e.disabled, i, y, mode), false, '', cc, insideCheck(e.handleExtraData, i, y, {}), g_handleChange, false),
                false,
                cclass,
                isInsideArray,
                true
            );
        } else if (type === 'date') {
            let selectionMode = insideCheck(e.selectionMode, i, y, undefined);
            return g_wraper(
                a,
                label,
                <div className={cc}>
                    <Calendar
                        selectionMode={selectionMode}
                        hourFormat={LabelSelector('hourFomart', 'default')}
                        showTime={insideCheck(e.showTime, i, y, false)}
                        timeOnly={insideCheck(e.timeOnly, i, y, false)}
                        minDate={insideCheck(e.minDate, i, y, '')}
                        maxDate={insideCheck(e.maxDate, i, y, '')}
                        yearRange={`1900:${new Date().getFullYear() + 10}`}
                        monthNavigator={true}
                        yearNavigator={true}
                        id={a}
                        dateFormat="dd-mm-yy"
                        value={selectionMode !== undefined ? origin[a] : new Date(origin[a])}
                        onChange={event => handleChange(event, insideCheck(e.handleExtraData, i, y, { multiple: selectionMode !== undefined }), context)}
                        disabled={insideCheck(e.disabled, i, y, mode)}
                    />
                </div>,
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'selector') {
            return (
                <GSelector
                    label={label}
                    ref={insideCheck(e.ref, i, y, () => {})}
                    labelwraper={cclass}
                    startV={insideCheck(e.startV, i, y, '', false)}
                    getData={insideCheck(e.getData, i, y, () => {})}
                    gobtt={insideCheck(e.gobtt, i, y, false)}
                    jump={insideCheck(e.jump, i, y, () => {})}
                    tableBody={insideCheck(e.tableBody, i, y, [])}
                    inputtextwarper={cc}
                    disabled={insideCheck(e.disabled, i, y, mode)}
                />
            );
        } else if (type === 'editor') {
            if (insideCheck(e.disabled, i, y, mode))
                return g_wraper(
                    a,
                    label,
                    <div
                        style={{ border: '1px solid grey', marginBottom: '3px', ...insideCheck(e.style, i, y, {}) }}
                        className={cc}
                        dangerouslySetInnerHTML={{ __html: origin[a] ?? '' }}
                    />,
                    false,
                    cclass,
                    isInsideArray
                );
            return g_wraper(
                a,
                label,
                <div className={cc}>
                    <Editor
                        value={origin[a]}
                        style={insideCheck(e.style, i, y, { minHeight: '7em' })}
                        onTextChange={e => g_handleChange(e, { id: a, editor: true, ...insideCheck(e.handleExtraData, i, y, {}) }, context)}
                    />
                </div>,
                false,
                cclass,
                isInsideArray
            );
        } else if (type === 'switch') {
            return g_wraper(
                a,
                label,
                g_template_switch(
                    context,
                    a,
                    origin[a],
                    '',
                    insideCheck(e.disabled, i, y, mode),
                    false,
                    insideCheck(e.req, i, y, false),
                    cc,
                    '',
                    insideCheck(e.tooltip, i, y, ''),
                    insideCheck(e.style, i, y, {}),
                    insideCheck(e.handleExtraData, i, y, {})
                ),
                false,
                cclass,
                isInsideArray
            );
        }
        return null;
    };

    let process = e => {
        if (!e?.t) return <React.Fragment>{e}</React.Fragment>;
        if (e?.nshow) return null;
        if (e.t === 'array' && e.cg !== undefined && e.cg) return null;
        if (e.t !== 'array' && e.c !== undefined && e.c) return null;
        let { t } = e;
        if (t === 'i') {
            if (e.data && e.id) {
                t = 'text';
            }
        }
        let label = e.label === '' ? '' : e.label ? e.label : LabelSelector(e.id);
        let cc = e.cc ? 'p-col-' + e.cc : 'p-col-2';
        let cl = e.class ? e.class : 'p-col-' + labelSize;
        if (t === 'sep') return [<h1 key={`sep_line_${e.title}`}> </h1>, <h4 key={`sep_line_${e.title}`}>{e.title}</h4>];
        else if (t === 'view') {
            return g_wraper('', e.l, dd(e.d, e.cc ? 'p-col-' + e.cc : 'p-col-2'), e.h, e.class ? e.class : 'p-col-' + labelSize, e.gridless);
        } else if (t === 'array') {
            let p = (a, i, e) => {
                let r = [];
                let p1 = a.map((a, y) => tArray(a, e, i, y));
                for (let ob of p1) {
                    if (ob) r = [...r, ob];
                }
                if (r.length === 0) return null;
                return (
                    <div key={`array${i}`} style={{ padding: 0 }} className="p-grid p-col">
                        {r}
                    </div>
                );
            };
            return e.filter.map((a, i) => (typeof a === 'string' ? tArray(a, e, i) : p(a, i, e)));
        } else if (t === 't') {
            return g_template_input(
                context,
                e.id,
                e.data,
                label,
                e.disabled === undefined ? mode : e.disabled,
                false,
                e.req,
                e.cc,
                e.class ? e.class : 'p-col-' + labelSize,
                e.tooltip,
                e.handleExtraData,
                e.type
            );
        } else if (t === 'b') {
            return g_template_button(
                e.l ?? '',
                e.icon,
                false,
                e.click,
                e.disabled === undefined ? mode : e.disabled,
                'p-col-' + (e.class === undefined ? labelSize : e.class),
                e.extra ? e.extra : '',
                e.tooltip,
                e.style
            );
        } else if (t === 'dil') {
            return g_template_dialog(
                context,
                e.text,
                e.id,
                e.onConfirm,
                e.l,
                false,
                e.disabled === undefined ? mode : e.disabled,
                e.classBttConfirm,
                e.classBttDeny,
                e.iconBttConfirm,
                e.iconBttDeny,
                'p-col-' + (e.class === undefined ? labelSize : e.class),
                e.bttclass,
                e.confirmText,
                e.denyText,
                e.icon
            );
        } else if (t === 'copy') {
            return g_template_textcopy(context, e.l, e.text, e.id, false, e.cc);
        } else if (t === 'drop') {
            return g_wraper(
                e.id,
                label,
                g_template_dropdown(
                    context,
                    e.id,
                    e.data,
                    '',
                    e.options,
                    e.disabled === undefined ? mode : e.disabled,
                    false,
                    e.req,
                    cc,
                    '',
                    e.enableFilter,
                    e.placeholder,
                    e.handleExtraData,
                    e.callback
                ),
                false,
                cl
            );
        }

        return '';
    };
    return array.map(process);
}

export const g_convertList = e => Object.keys(e).map(a => ({ value: a, label: e[a] }));
export const g_convertResponse = (f, e) => ({
    ...e,
    ...f
        .filter(a => e[a] !== undefined)
        .map(a => ({ [String(a)]: String(e[a]) }))
        .reduce((a, e) => ({ ...a, ...e })),
});
/**
 * @deprecrated
 */
export const g_pair = (key, value, s = 0) => (s === 0 ? { key: key, value: value } : s === false ? [] : [{ key: key, value: value }]);
