export interface CommercialContactCreateModel {
	applicationId?: string;
	contactName?: string;
	phoneContact?: string;

	description: string;
	email: string;
	entityIdUUID: string;
}

export interface CommercialContactModel extends CommercialContactCreateModel {
	applicationId: string;
	commercialContactStatus: number;
	contactName: string;
	dateCreation: string;
	idUUID: string;
	operatorIdUUID: string;
	phoneContact: string;
}

export enum NotificationModelBroadcastType {
	all_users = 1,
	all_entities = 2,
	all_entities_associated_to_destination = 3,
	specific_entity = 4,
	group_destination_uuid = 5,
	specific_consignee_destination_by_app = 6,
	specific_consignee_destination_by_bo = 7
}

export enum NotificationModelCheckMode {
	no_check = 1,
	check = 2
}

export enum NotificationModelStatus {
	queued = 0,
	processed = 1,
	processing = 2,
	error = 3,
	timeout = 4,
	canceled = 5,
	deleted = 6,
	creating = -1
}

export enum NotificationModelType {
	email = 1,
	sms = 2,
	app_push = 3,
	app_notification = 4
}
export interface NotificationCreateModel {
	description: string;
	destinationIDUUID: string;
	longDescription?: string;
	notificationBroadcastType: NotificationModelBroadcastType;
	notificationCheckMode: NotificationModelCheckMode;
	notificationStatus: NotificationModelStatus;
	notificationType: NotificationModelType;
	shortDescription?: string;
}
export interface NotificationModel {
	dateCreation: string;
	dateUpdate: string;
	description: string;
	destinationIdUUID: string;
	idUUID: string;
	longDescription: string;
	notificationBroadcastType: NotificationModelBroadcastType;
	notificationCheckMode: NotificationModelCheckMode;
	notificationStatus: NotificationModelStatus;
	notificationType: NotificationModelType;
	notificationTypeDescription: string;
	photoBig: string;
	photoSmall: string;
	shortDescription: string;
	userFullname: string;
	userIdUUID: string;
}
