import {
    LS,
    StateIcon,
    g_checkLoginACL,
    g_displayMessageError,
    g_genericRequired,
    g_getData,
    g_getHeaderTemplatecF,
    g_hideSearch,
    g_openWindowDialog,
    g_pair,
    g_template_button,
    g_template_dataTable,
} from '../GenericFunctions';
import React from 'react';
import config from '../../config/config';
import BatchService from '../../service/BatchService';
import ConsigneeService from '../../service/ConsigneeService';
import PayrollService from '../../service/PayrollSerive';
import { states } from './BatchEdit';
import Services from '../../service/Services';

export class PayrollControlBatchs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...g_genericRequired(), pageSize: this.props.bIDUUID ? 50 : 10 };
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }

    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, BatchService.batchList, 'batchs', {
                  filters: [
                      ...this.state.filter,
                      g_pair('controlIdUUID', this.props.context.state.idUUID),
                      ...g_pair('batchServiceIdUUID', this.props.bIDUUID, (this.props.bIDUUID ?? false) !== false),
                  ],
              })
            : null;

    type = raw => (LS(raw.batchServiceIdUUID, 'batchesIdUUID') === 'LabelInfenranceFailed' ? '' : LS(raw.batchServiceIdUUID, 'batchesIdUUID'));

    render() {
        return g_checkLoginACL(
            e => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        this.props.bIDUUID ? '' : g_getHeaderTemplatecF(this, () => {}, this.getData, false),
                        [
                            { type: 'd', data: 'originalFilename', sortable: true, filter: true },
                            { type: 'di', c: this.props.bIDUUID, align: 'left', title: LS('type', 'generic'), di: raw => this.type(raw) },
                            { type: 'd', data: 'userFullname' },
                            { type: 'date', data: 'dateExecution', sortable: true, filter: true },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                            { type: 'state', data: 'batchStatus', states: states },
                            {
                                type: 'dil',
                                icon: 'pi pi-caret-down',
                                text: LS('batchesFileEditDown', 'messages'),
                                c: this.props.bIDUUID === '4f491300-f859-f49e-c8a0-db59764ea41c',
                                id: raw => raw.idUUID,
                                show: raw => raw.state === 4,
                                onConfirm: raw => Services.payroll.file.status(raw.idUUID, 4),
                            },
                            { type: 'button', click: raw => window.open(config.BATCH_DOCUMENT_URL + raw.processedFilename), icon: 'pi pi-download' },
                            { type: 'url', url: raw => `#/batchedit?id=${raw.idUUID}&type=1`, width: 1000, height: 450, callback: this.getData, c: e.u === 1 || e.d === 1 },
                        ],
                        this.getData
                    )}
                </div>
            ),
            false
        );
    }
}

export class PayrollList extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(
                  this,
                  PayrollService.list,
                  'payrolls',
                  { filters: [...this.state.filter, { key: 'payrollControlIdUUID', value: this.props.context.state.idUUID }] },
                  this.props.context.messages
              )
            : null;
    openDownload = raw => {
        let response = ConsigneeService.payrollGet(raw.year, raw.month, this.props.context.idUUID);
        if (g_displayMessageError(this.props.context.messages, response)) return;
        g_openWindowDialog(response.url, 'paycheck', 1000, 500);
    };
    render() {
        return g_checkLoginACL(
            () => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(this, () => {}, this.getData, false),
                        [
                            { type: 'd', data: 'vatNumber', filter: true },
                            { type: 'd', data: 'month' },
                            { type: 'd', data: 'year' },
                            { type: 'd', data: 'salaryGross' },
                            { type: 'd', data: 'totalDiscounts', title: LS('discounts') },
                            { type: 'd', data: 'salaryNet' },
                            { type: 'd', data: 'marginValue', title: LS('forcedMargin') },
                            { type: 'date', data: 'dateCreation' },
                            {
                                type: 'dil',
                                icon: 'pi pi-caret-down',
                                text: LS('batchesPersonEditDown', 'messages'),
                                id: raw => raw.idUUID,
                                show: raw => raw.payrollStatus === 4,
                                onConfirm: raw => Services.payroll.processed.status(this, raw.idUUID, 4, undefined, LS('simpleStatus', 'messages')),
                            },
                            {
                                type: 'di',
                                title: '',
                                di: raw =>
                                    raw.payrollStatus === 1 ? (
                                        g_template_button('', 'pi pi-download', false, () => this.openDownload(raw), false, '')
                                    ) : (
                                        <StateIcon
                                            state={raw.payrollStatus}
                                            custom={{
                                                0: { class: 'pi-spin pi-spinner', color: 'grey' },
                                                2: { class: 'pi-cross', color: 'red' },
                                                3: { class: 'pi-circle-on', color: 'red' },
                                                4: { class: 'pi-caret-down', color: 'red' },
                                            }}
                                        />
                                    ),
                            },
                            { type: 'url', url: raw => `#/payroll?type=1&id=${raw.idUUID}`, width: 1000, height: 500, callback: this.getData },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'batchs#payroll'
        );
    }
}

export class Movements extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, ConsigneeService.marginMovementList, 'marginMovements', { filters: [{ key: 'controliduuid', value: this.props.context.state.idUUID }] }, this.props.context.messages)
            : null;

    render() {
        return g_checkLoginACL(
            () => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        g_getHeaderTemplatecF(this, () => {}, this.getData, false),
                        [
                            { type: 'd', data: 'budgetCode', filter: true, sortable: true },
                            { type: 'd', data: 'budgetDescription', filter: true, sortable: true },
                            { type: 'd', data: 'movementValue', filter: true, sortable: true },
                            { type: 'd', data: 'movementDiscountValue', filter: true, sortable: true },
                            { type: 'd', data: 'installmentNumber', filter: true, sortable: true },
                            { type: 'd', data: 'installmentTotal', filter: true, sortable: true },
                            { type: 'date', data: 'dateCreation', filter: true, sortable: true },
                            {
                                type: 'state',
                                data: 'movementMarginStatus',
                                states: { 1: { class: 'pi pi-check', color: 'green' }, 2: { class: 'pi pi-minus', color: 'red' }, 3: { class: 'pi pi-trash', color: 'red' } },
                            },
                        ],
                        this.getData
                    )}
                </div>
            ),
            true,
            'batchs#payroll'
        );
    }
}
