import type {
	RequestsClass,
	GenericResponse,
	UUID,
	ShoppingCartStatus
} from '../..';

export const status =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		idUUID: UUID;
		status: ShoppingCartStatus;
	}): Promise<GenericResponse> =>
		req.axios.put(`${baseUrl}/update/status`, data);
