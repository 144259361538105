import {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../../Requests';
import { ReportModel } from '../Report';

export type Filters =
	| FilterDateType<'dateCreation'>
	| 'category'
	| 'incidentState'
	| 'closeState'
	| 'sla'
	| 'slaState';

export type OrderBy = '';

export const analytic =
	(req: RequestsClass, baseUrl: string) =>
	(data: GenericListRequest<Filters, OrderBy>): Promise<ReportModel> =>
		req.axios.put(`${baseUrl}/analytic`, data);

export const stateanalytic =
	(req: RequestsClass, baseUrl: string) =>
	(data: GenericListRequest<Filters, OrderBy>): Promise<ReportModel> =>
		req.axios.put(`${baseUrl}/states/analytic`, data);
