import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';
import { checkLoginACL, pair, StringEmpty } from '../Generic';
import { g_displayMessageError, ls, S } from '../GenericFunctions';
import { CallRecordModel } from '../protetor/calls/CallInfoNew';

let l = (l: string) => ls(l, 'callDashbord');

function processNumber(n: string | number) {
    return String(n).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

interface Data {
    labels: string[];
    datasets: DataSet[];
}

interface DataSet {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
}

interface MedicDashbordProps { }
export const MedicDashbord: React.FC<MedicDashbordProps> = () => {
    let { error, loggeduser } = checkLoginACL({ fakename: 'dashboard-medic' });

    let [all, setAll] = useState<{
        total: number;
        totalSuccess: number;
        graphData: Data;
    }>({ total: 0, totalSuccess: 0, graphData: { labels: [], datasets: [] } });

    useEffect(() => {
        if (StringEmpty(loggeduser?.uuid)) return;
        (async () => {
            let r = await S.call.list({
                filters: [pair('destinationIdUUID', loggeduser?.uuid ?? '')],
            });

            if (g_displayMessageError(null, r)) return;

            let success = 0;

            let lastMonthDate = new Date();
            lastMonthDate.setDate(-30);
            lastMonthDate.setHours(0, 0);
            lastMonthDate.setMinutes(0);

            let a: CallRecordModel[] = r.callRecords.filter(a => {
                if (a.callRecordStatus === 1) {
                    success++;
                } else if (a.callRecordStatus === 7 || a.callRecordStatus === 2 || a.callRecordStatus === 8 || a.callRecordStatus === 5 || a.callRecordStatus === 6) {
                    //Do nothing
                } else {
                    console.log(a.callRecordStatus, a.callRecordStatusDescription);
                }
                return new Date(a.dateCreation).getTime() >= lastMonthDate.getTime();
            });

            let allDataSet: Data = {
                labels: [],
                datasets: [
                    {
                        label: l('success'),
                        data: [],
                        borderColor: '#00ff0099',
                        backgroundColor: '#00ff0022',
                    },
                    {
                        label: l('endedCallByMedic'),
                        data: [],
                        borderColor: '#0000ff99',
                        backgroundColor: '#0000ff22',
                    },
                    {
                        label: l('canceledByOrigin'),
                        data: [],
                        borderColor: '#99999999',
                        backgroundColor: '#99999922',
                    },
                    {
                        label: l('notAccepted'),
                        data: [],
                        borderColor: '#ff000099',
                        backgroundColor: '#ff000022',
                    },
                    {
                        label: l('calling'),
                        data: [],
                        borderColor: '#FBE87B99',
                        backgroundColor: '#FBE87B22',
                    },
                    {
                        label: l('inCall'),
                        data: [],
                        borderColor: '#8EFA7A99',
                        backgroundColor: '#8EFA7A22',
                    },
                ],
            };


            for (let i = 29; i >= 0; i--) {
                let temp: CallRecordModel[] = [];
                let lastDateStart = new Date();
                lastDateStart.setDate(lastDateStart.getDate() - i);
                lastDateStart.setHours(0, 0);
                lastDateStart.setMinutes(0);
                let lastMonthEnd = new Date(lastDateStart);
                lastMonthEnd.setHours(23, 0);
                lastMonthEnd.setMinutes(59);
                allDataSet.labels.push(String(lastDateStart.getDate()).padStart(2, '0') + '/' + String(lastDateStart.getMonth() + 1).padStart(2, '0'));

                let success = 0;
                let endCallByMedic = 0;
                let endCallByOrigin = 0;
                let notAccepted = 0;
                let calling = 0;
                let inCall = 0;

                a.forEach(a => {
                    let dateTime = new Date(a.dateCreation);
                    if (dateTime.getTime() > lastDateStart.getTime() && dateTime.getTime() < lastMonthEnd.getTime()) {
                        if (a.callRecordStatus === 1) {
                            success++;
                        } else if (a.callRecordStatus === 7) {
                            endCallByMedic++;
                        } else if (a.callRecordStatus === 2) {
                            endCallByOrigin++;
                        } else if (a.callRecordStatus === 8) {
                            notAccepted++;
                        } else if (a.callRecordStatus === 5) {
                            calling++;
                        } else if (a.callRecordStatus === 6) {
                            inCall++;
                        }
                    } else {
                        temp.push(a);
                    }
                });

                allDataSet.datasets[0].data.push(success);
                allDataSet.datasets[1].data.push(endCallByMedic);
                allDataSet.datasets[2].data.push(endCallByOrigin);
                allDataSet.datasets[3].data.push(notAccepted);
                allDataSet.datasets[4].data.push(calling);
                allDataSet.datasets[5].data.push(inCall);

                a = temp;
            }

            setAll({ total: r.recordsTotal, totalSuccess: success, graphData: allDataSet });
        })();
        //! note not nedded as its only run once during
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) return error;
    return (
        <div className="dashboard p-grid p-fluid">
            <div className="p-col-12 p-lg-5">
                <div className="card summary">
                    <span className="title">{l('total')}</span>
                    <span className="detail"></span>
                    <span className="count purchases">{processNumber(all.total)}</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-5">
                <div className="card summary">
                    <span className="title">{l('totalSuccess')}</span>
                    <span className="detail"></span>
                    <span className="count purchases">{processNumber(all.totalSuccess)}</span>
                </div>
            </div>
            <div className="card p-col-12 p-clear">
                <Chart type="line" data={all.graphData} />
            </div>
        </div>
    );
};

/*export default class FinancierDashboard extends Component {

    constructor(props: any) {
        super(props);
        this.state = {
            ...g_genericRequired(),
            ...contractRequired(this),
            pageSize: 999999,
            monthsOption: [
                {label: 'Janeiro', value: '0'},
                {label: 'Fevereiro', value: '1'},
                {label: 'Março', value: '2'},
                {label: 'Abril', value: '3'},
                {label: 'Maio', value: '4'},
                {label: 'Junho', value: '5'},
                {label: 'Julho', value: '6'},
                {label: 'Agosto', value: '7'},
                {label: 'Setembro', value: '8'},
                {label: 'Outubro', value: '9'},
                {label: 'Novembro', value: '10'},
                {label: 'Dezembro', value: '11'},
            ],
            dialogvisable: false,
            dialoglabel: "def",
            mainsec: '2',
            smainsec: '2',
            secsec: 1,
            ssecsecv: new Date().getMonth() + '',
            secsecv: new Date().getMonth() + '',
            pieData: {},
        };
    }

    componentDidMount() { document.body.style.background= '#edf0f5'; this.getData(); }
    getData = () => getContractsDataFinancier(this);
    getLabel = () => {
        if (this.state.smainsec === '0') return 'Diario';
        else if (this.state.smainsec === '1') return 'Semanal';
        else if (this.state.smainsec === '2') return `Mês:${this.state.months[Number(this.state.ssecsecv)]}`;
        else return `Ano:${this.state.ssecsecv}`;
    }
     save = () => {
        this.setState({
            ssecsecv: this.state.secsecv,
            smainsec: this.state.mainsec,
            dialogvisable: false,
        });
    }

    changeMainSec = () => {
        if (this.state.mainsec === '2') {
            this.setState({
                secsecv: '' + new Date().getMonth(),
                secsec: 1,
            });
        } else if (this.state.mainsec === '3') {
            this.setState({
                secsecv: new Date().getFullYear(),
                secsec: 2,
            });
        } else {
            this.setState({
                secsec: null,
            });
        }
    }

    grender = () => {
        return (
            <div>
              <Messages ref={e => this.messages = e }/>
              <div className="p-grid p-fuild dashboard">
                {contractRenderTimeless(this)}
                <div style={{borderBottom: '2px solid #aaaaaacc'}} className="p-col-12"><h1>{this.getLabel().split(':').join(' ')}</h1></div>
                {contractRenderPart1(this)}
                <div style={{verticalAlign: 'center'}} className="p-col-12 p-lg-2">
                  <Dialog style={{height: '32vh', width: '42vw'}} position="right" visible={this.state.dialogvisable} onHide={() => { this.setState({dialogvisable: false}); this.getData();} }>
                    <div className="p-grid p-fluid p-col-12" style={{minHeight: '27vh', width: '41vw'}}>
                      {g_template_dropdown(this, 'mainsec', this.state.mainsec, null, this.state.requestType, false, false, false, this.state.secsec ? 'p-col-5' : 'p-col-11', '', false, '', {data: 'mainsec'}, this.changeMainSec)}
                      {this.state.secsec === 1 ? g_template_dropdown(this, 'secsecv', this.state.secsecv, null, this.state.monthsOption, false, false, false, 'p-col-6', '', false, '', {data: 'secsecv'}) : this.state.secsec === 2 ? g_template_inputMask(this, 'secsecv', this.state.secsecv, null, '9999', false, false, false, 'p-col-6', 'p-col', '', null, {data: 'secsecv'}) : null}
                      {g_template_button('', 'pi pi-save', false, this.save, false, 'p-col-1')}
                    </div>
                  </Dialog>
                  {g_template_button(this.getLabel(), '', false, () => this.setState({dialogvisable: true, mainsec: this.state.smainsec, secsecv: this.state.ssecsecv}, this.changeMainSec), false, 'p-col-12', 'p-col-12')}
                </div>
                {contractRenderPartGraphs(this)}
              </div>
            </div>
        );
    }

    render() { return g_checkLoginACL(this.grender, true, 'dashboard-financier'); }
}*/
