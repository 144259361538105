import type { ProductCampaign, UUID, StringDate } from '.';

export interface CampaignModel {
	idUUID: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	dateStart: StringDate;
	dateEnd: StringDate;
	campaignType: number;
	campaignStatus: number;
	campaignMode: number;
	campaignModeDescription: string;
	name: string;
	shortDescription: string;
	longDescription: string;
	consignatorIdUUID: UUID;
	consignatorName: string;
	campaignMainImage: string;
	campaignAggregatedImage: string;
	campaignExtraImage: string;
	packageValue: number;
	packagePaymentMode: number;
	packagePeriod: number;
	messagePaymentOverdue: string;
	messageSubscription: string;
	allowMultiplePackage: number;
	allowsSyncCall: number;
	visibility: number;
	interfaceName: string;
	parameterA: string;
	parameterB: string;
	parameterC: string;
	parameterD: string;
	parameterE: string;
	parameterF: string;
	showOrder: number;
	businessModel: number;
	transferDefaultPercentage: number;
	productDiscountInternal: number;
	productDiscountExternal: number;
	temporaryDescription: string;
	products: ProductCampaign[]
}

export enum CampaignStatus {
	active = 1,
	blocked = 2,
	deleted = 3,
	temporary_pending = 4
}

export type CampaignListFilters =
	| 'consignatorsIdUUID'
	| 'campaignType'
	| 'visibility'
	| 'campaignStatus'
	| 'loadProducts';
