import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { LS, S } from '../GenericFunctions';
import * as config from '../../config/config';
import '../login/Login.css';

export default class RecoverPassword extends Component {
    constructor() {
        super();
        this.state = {
            user: { userName: '' },
            visible: false,
            logged: false,
            merchant: '',
            merchantSuggestions: null,
            redirect: false,
        };
    }

    componentDidMount() {
        document.body.style.cssText += 'background-image: none !important;';
        let a = config.loginBackground();
        if (typeof a === 'function') a();
        else document.body.style.background = a;
    }
    componentDidUpdate() {
        document.body.style.cssText += 'background-image: none !important;';
        let a = config.loginBackground();
        if (typeof a === 'function') a();
        else document.body.style.background = a;
    }

    handleChange = event => {
        const user = { ...this.state.user };
        const { name, value } = event.target;
        user[name] = value;
        this.setState({ user });
    };

    handleSubmit = async event => {
        event.preventDefault();
        const response = await S.user.resetPassword({ userType: 1, username: this.state.user.userName });
        if (response && response.code === 200) this.messages.show({ severity: 'error', summary: LS('userNotFound', 'messages') });
        if (response && response.code === 0) this.messages.show({ severity: 'success', summary: LS('passwordResetSuccess', 'messages') });
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="login-div">
                    <div className="login-img-div">
                        <img src={config.loginImage().image} width="100%" alt="" />
                    </div>
                    <Messages ref={el => (this.messages = el)} />
                    <div className="login-field-div">
                        <InputText className="login-field" name="userName" placeholder={LS('username', 'placeholder')} onChange={this.handleChange} required />
                    </div>
                    <div style={{ maxHeight: '0px' }}></div>
                    <Button label={LS('resetPassword', 'btt')} className="btt" style={{ justifySelf: 'center' }} />
                    <div className="login-new-div">
                        {LS('goback', 'login')}{' '}
                        <a className="login-new-account" href="#/login">
                            {LS('forgot', 'login')}
                        </a>
                    </div>
                </div>
            </form>
        );
    }
}
