import type {
	GenericListRequest,
	RequestsClass,
	GenericListResponseG,
	UUID
} from '../../../..';

export interface ContractTemplateGroupByContext {
	idUUID: UUID;
	contractTemplateName: string;
	contextType: number;
	contextTypeName: string;
	classificationType: number;
	count: number;
}

export type ListFilters =
	| 'productIdUUID'
	| 'providerStatus'
	| 'productSubTypeContext'
	| 'contractTemplateStatus';

export const groupByContext = (req: RequestsClass, baseUrl: string) => {
	baseUrl = `${baseUrl}/groupByContext`;
	return {
		list: (
			data: GenericListRequest<ListFilters>
		): GenericListResponseG<
			'contractTemplateGroupByContext',
			ContractTemplateGroupByContext
		> => req.axios.post(`${baseUrl}/list`, data)
	};
};
