import { RequestsClass } from '../../Requests';
import { get, list } from './list';
import { incidentcategories } from './IncidentCategories';
import { incidenttype } from './IncidentType';
import { incidentseverity } from './IncidentSeverity';
import {
	incidentstatus,
	incidentstatusClose,
	incidentstatusDirectFoward,
	incidentstatusFoward,
	incidentstatusgoDistribution,
	incidentstatusgoResolution,
	incidentstatusReopen,
	incidentstatusstatusclose,
	incidentstatusToFoward,
	incidentstatusUpdate
} from './IncidentStatus';
import {
	incidentinteractionadd,
	incidentinteractionlist,
	incidentinteractionresponseadd
} from './IncidentInteraction';
import { add, update } from './add';
import { incidentnotesadd, incidentnoteslist } from './IncidentNotes';
import { incidentslalist } from './IncidentSLA';
import {
	incidentdocumentadd,
	incidentdocumentlist
} from './IncidentAttachment';
import { report } from '../report';

const baseUrl = '/incident';

export const TicketApi = (req: RequestsClass) => ({
	add: add(req, baseUrl),
	update: update(req, baseUrl),
	list: list(req, baseUrl),
	get: get(req, baseUrl),
	incidentcategories: incidentcategories(req, baseUrl),
	incidenttype: incidenttype(req, baseUrl),
	incidentseverity: incidentseverity(req, baseUrl),

	//Status
	incidentstatus: incidentstatus(req, baseUrl),
	incidentstatusUpdate: incidentstatusUpdate(req, baseUrl),
	incidentstatusgoDistribution: incidentstatusgoDistribution(req, baseUrl),
	incidentstatusgoResolution: incidentstatusgoResolution(req, baseUrl),
	incidentstatusToFoward: incidentstatusToFoward(req, baseUrl),
	incidentstatusFoward: incidentstatusFoward(req, baseUrl),
	incidentstatusDirectFoward: incidentstatusDirectFoward(req, baseUrl),
	incidentstatusClose: incidentstatusClose(req, baseUrl),
	incidentstatusReopen: incidentstatusReopen(req, baseUrl),
	incidentstatusstatusclose: incidentstatusstatusclose(req, baseUrl),

	//Interaction
	incidentinteractionlist: incidentinteractionlist(req, baseUrl),
	incidentinteractionadd: incidentinteractionadd(req, baseUrl),
	incidentinteractionresponseadd: incidentinteractionresponseadd(req, baseUrl),

	//SLA
	incidentslalist: incidentslalist(req, baseUrl),

	//Notes
	incidentnoteslist: incidentnoteslist(req, baseUrl),
	incidentnotesadd: incidentnotesadd(req, baseUrl),

	//Document
	incidentdocumentlist: incidentdocumentlist(req, baseUrl),
	incidentdocumentadd: incidentdocumentadd(req, baseUrl),
	report: report(req, baseUrl)
});
