import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class AppTopbar extends Component {
    static defaultProps = { onToggleMenu: null };
    static propTypes = { onToggleMenu: PropTypes.func.isRequired, showBtts: PropTypes.bool };

    render() {
        return (
            <div className="layout-topbar clearfix">
                {(() => {
                    if (this.props.showBtts) {
                        return (
                            <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                                <span className="pi pi-bars" />
                            </button>
                        );
                    }
                })()}
                <div
                    className="layout-menu-button clearfix"
                    style={{ paddingLeft: '2em', paddingBottom: '0.5em', color: 'white', display: process.env.NODE_ENV === 'development' ? '' : 'none' }}
                >
                    <h1>DEVELOPMENT</h1>
                </div>
                {(() => {
                    if (this.props.showBtts) {
                        return (
                            <div className="layout-topbar-icons">
                                <button className="p-link" onClick={() => (window.location = '#/LUser')}>
                                    <span className="layout-topbar-item-text">User</span>
                                    <span className="layout-topbar-icon pi pi-user" />
                                </button>
                                <button
                                    className="p-link"
                                    onClick={() => {
                                        localStorage.removeItem('loggedUserN');
                                        this.setState({ redirect: true, logged: false }, () => {
                                            this.props.handlerLogin(this.state.logged);
                                            window.location = '#/login';
                                        });
                                    }}
                                >
                                    <span className="layout-topbar-item-text">Logout</span>
                                    <span className="layout-topbar-icon pi pi-sign-out" />
                                </button>
                            </div>
                        );
                    }
                })()}
            </div>
        );
    }
}
