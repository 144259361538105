import type { GenericListResponseG } from '../../Generic';
import type { GenericListRequest, RequestsClass } from '../../Requests';

interface ProductSubType {
	id: number;
	productType: number;
	description: string;
	languageTag: string;
	orderView: number;
	orderViewShortcut: number;
	photoBig: string;
	photoSmall: string;
	photoShortcut: string;
}

export const list =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest
	): GenericListResponseG<'productSubTypes', ProductSubType> =>
		req.axios.post(`${baseUrl}/productSubType/list`, data);
