import type { GenericListResponseG } from '../../Generic';
import type { GenericListRequest, RequestsClass } from '../../Requests';

export type Filters = string;
export type OrderBy = string;

export interface CategoriesModel {
	id: number;
	description: string;
	languageTag: string;
	imageIcon: string;
}

export const incidentcategories =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidentCategories', CategoriesModel> =>
		req.axios.post(`${baseUrl}/categories`, data);
