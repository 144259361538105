import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './/ScrollToTop';

import './layout/style/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
//import './layout/layout.scss';
import './App.scss';

const ProtetorTheme = React.lazy(() => import('./layoutProtetor'));
const DrMozTheme = React.lazy(() => import('./layoutDrMoz'));
const ExistoTheme = React.lazy(() => import('./layoutExisto'));

let theme = () => {
    let themes = {
        _: <ProtetorTheme />,
        4100: <DrMozTheme />,
        4300: <ExistoTheme />,
    };
    return themes[String(process.env.REACT_APP_PORT)] ?? themes._;
};

const ThemeSelector = ({ children }) => {
    return (
        <>
            <React.Suspense fallback={<></>}>{theme()}</React.Suspense>
            {children}
        </>
    );
};
ReactDOM.render(
    <ThemeSelector>
        <HashRouter>
            <ScrollToTop>
                <App></App>
            </ScrollToTop>
        </HashRouter>
    </ThemeSelector>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
