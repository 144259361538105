import { RequestsClass } from '../../../Requests';
import {
	getpersonal,
	getstatesactives,
	getstatesin,
	getstatesinandcategory,
	gettotals
} from './Incidentdashboard';

const baseUrl = '/incident/dashboard';

export const tickets = (req: RequestsClass) => ({
	gettotals: gettotals(req, baseUrl),
	getstatesactives: getstatesactives(req, baseUrl),
	getstatesin: getstatesin(req, baseUrl),
	getstatesinandcategory: getstatesinandcategory(req, baseUrl),
	getpersonal: getpersonal(req, baseUrl)
});
