import React from 'react';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import UserService from '../../service/UserService';
import EncryptPassword from '../utils/EncryptPassword';
import { LS, lm } from '../GenericFunctions';

export default class ChangePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                newPassword: '',
                oldPassword: '',
            },
            newPassword: '',
            secondPassword: '',
        };
    }

    handleChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.id;
        this.setState({
            data: {
                ...this.state.data,
                [name]: value,
            },
            [name]: value,
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        if (this.checkPassword()) {
            this.data = {
                username: JSON.parse(localStorage.getItem('loggedUserN')).userName,
                newPassword: await EncryptPassword.encrypt(this.state.data.newPassword),
                oldPassword: await EncryptPassword.encrypt(this.state.data.oldPassword),
                userType: 1,
            };
            const response = await UserService.changePassword(this.data);
            if (response && response.code === 0) this.messages.show({ severity: 'success', summary: lm('simpleSave') });
            else this.messages.show({ severity: 'error', summary: 'Erro ao alterar a password' });
        }
    };

    handleChangePass = event => {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            data: {
                ...this.state.data,
            },
            [name]: value,
        });
    };

    checkPassword() {
        var password1 = this.state.newPassword;
        var password2 = this.state.secondPassword;
        // If Not same return False.
        if (password1 !== password2) {
            this.messages.show({ severity: 'error', summary: 'A nova password não combina!' });
            return false;
        }
        return true;
    }

    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid-12 p-fluid">
                    <div className="card card-w-title">
                        <h1>{LS('changePassword', 'titles')}</h1>
                        <Messages ref={el => (this.messages = el)} />
                        <form onSubmit={this.handleSubmit}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-6 p-lg-6 p-fluid contact-form">
                                    <Panel>
                                        <div class="p-grid">
                                            <div class="p-col-4">
                                                <label htmlFor="oldPassword">{LS('oldPassword')}</label>
                                            </div>
                                            <div class="p-col-4">
                                                <Password id="oldPassword" feedback={false} onChange={this.handleChange} required />
                                            </div>
                                        </div>

                                        <div class="p-grid">
                                            <div class="p-col-4">
                                                <label htmlFor="newPassword">{LS('newPassword')}</label>
                                            </div>
                                            <div class="p-col-4">
                                                <Password id="newPassword" onChange={this.handleChange} required />
                                            </div>
                                        </div>

                                        <div class="p-grid">
                                            <div class="p-col-4">
                                                <label htmlFor="secondPassword">{LS('secondPassword')}</label>
                                            </div>
                                            <div class="p-col-4">
                                                <Password id="secondPassword" match="newPassword" onChange={this.handleChangePass} required />
                                            </div>
                                        </div>

                                        <div class="p-grid">
                                            <div class="p-col"></div>
                                        </div>

                                        <div class="p-grid">
                                            <div class="p-col-5">
                                                <Button label={LS('changePassword', 'btt')} />
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
