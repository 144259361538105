import type { StringDate, UUID } from '../Generic';

export enum GroupType {
	organizational = 1,
	mailing = 2
}

export enum GroupStatus {
	active = 1,
	deleted = 2
}

export interface GroupElement {
	groupIdUUID: UUID;
	groupName: string;
	referenceIdUUID: UUID;
	referenceName: string;
	dateCreation: StringDate;
}

export interface Group {
	idUUID: UUID;
	name: string;
	parentGroupIdUUID: UUID;
	parentGroupName: string;
	referenceOwnerIdUUID: UUID;
	referenceOwnerName: string;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	description: string;
	groupType: GroupType;
	groupStatus: GroupStatus;
	groupElements: GroupElement[];
}
