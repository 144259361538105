import { GenericResponseG } from '../../Generic';
import { RequestsClass } from '../../Requests';
import { Blog } from '../Blog';

export const update =
	(req: RequestsClass, url: string) =>
	(blog: Blog): Promise<GenericResponseG<'blog', Blog>> =>
		req.axios.put(`${url}/update`, { blog });

export const create =
	(req: RequestsClass, url: string) =>
	(blog: Blog): Promise<GenericResponseG<'blog', Blog>> =>
		req.axios.post(`${url}/add`, { blog });
