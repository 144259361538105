import React, { Component } from 'react';
import CampaignService from '../../service/CampaignService';
import ConsigneeService from '../../service/ConsigneeService';
import SubscriptionService from '../../service/SubscriptionService';
import GSelector from '../generic/GSelector';
import {
    LS,
    pair,
    g_checkLoginACL,
    g_displayMessageError,
    g_getData,
    g_tabViewRenderOptions,
    g_template_form,
    g_genericRequired,
    g_hideSearch,
    g_template_dataTable,
} from '../GenericFunctions';
import Log from '../logs/Log';

export default class Subscribe extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                campaignIDUUID: '',
                campaignName: '',
                dateCreation: '',
                dateUpdate: '',
                idUUID: '',
                referenceIDUUID: '',
                subscriptionCounters: [],
                subscriptionCurrentCycle: {},
                subscriptionStatus: 0,
                subscriptionType: 0,
            },
            idUUID: null,
            items: [{ label: LS('subscription', 'tab') }],
        };
    }

    block = async e => {
        e.preventDefault();
        let response = await ConsigneeService.subscriptionUpdateStatus({ idUUID: this.state.data.idUUID, consigneeStatus: 2 });
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleStatus', 'messages') });
        this.getData();
    };

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        this.setState(
            {
                idUUID: id,
                cid: query.get('cid'),
                mode: query.get('type') !== '1',
                items: id ? [...this.state.items, { label: LS('reacurenace', 'tab'), icon: 'pi pi-clock' }, { label: LS('log', 'tab'), icon: 'pi pi-list' }] : this.state.items,
            },
            this.getData
        );
    }

    getData = async () => {
        if (!this.state.idUUID) return;
        let r = await SubscriptionService.list({ filters: [pair('idUUID', this.state.idUUID)] });
        if (g_displayMessageError(this.messages, r)) return;
        if (r.subscriptions[0]) this.setState({ data: r.subscriptions[0] });
    };

    submitForm = async e => {
        e.preventDefault();
        if (this.state.idUUID || !this.state.cid || !this.state.data.campaignIDUUID) return;
        let data = this.state.data;
        data.referenceIDUUID = this.state.cid;
        data = { subscription: data };
        let r = await SubscriptionService.add(data);
        if (g_displayMessageError(this.messages, r)) return;
        window.location.href = `/#/subscribe?type=1&id=${r.subscription.idUUID}&cid=${this.state.cid}`;
        window.location.reload();
    };

    getProductPackages = c => g_getData(c, CampaignService.getCampaigns, 'campaigns', { filters: [...c.state.filter, { key: 'campaignType', value: 3 }] });

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.idUUID !== null) || (!this.state.mode && e.c !== 1 && this.state.idUUID === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        g_template_form(
                            this,
                            mode => [
                                { t: 'array', dTypeF: 'copy', filter: ['idUUID'] },
                                <GSelector
                                    labelwraper="p-col-2"
                                    label={LS('package')}
                                    ref={e => (this.selector1 = e)}
                                    getData={this.getProductPackages}
                                    tableBody={[
                                        { type: 'd', data: 'name', sortable: true, filter: true },
                                        {
                                            type: 'button',
                                            icon: 'pi pi-plus',
                                            click: (raw, e) => {
                                                e.preventDefault();
                                                this.selector1.setState({ selected: raw.name, visible: false });
                                                this.setState({ data: { ...this.state.data, campaignIDUUID: raw.idUUID } });
                                            },
                                        },
                                    ]}
                                    inputtextwarper="p-col-5"
                                    disabled={mode}
                                    req={true}
                                    startV={this.state.data.campaignName}
                                />,
                            ],
                            () => [
                                { t: 'b', l: LS('create', 'btt'), c: this.state.idUUID, click: null },
                                { t: 'b', l: LS('cancel', 'btt'), c: !this.state.idUUID || this.state.data.subscriptionStatus === 2, click: this.block },
                            ],
                            () => [
                                [
                                    {
                                        cg: !this.state.idUUID,
                                        t: 'array',
                                        origin: this.state.data,
                                        filter: ['subscriptionStatus', 'dateCreation', 'dateUpdate'],
                                        dTypeF: 'view',
                                        dTypeFs: { 0: 'state' },
                                        ddless: true,
                                        class: 'l',
                                        states: { 0: { 1: { color: 'green', class: 'pi-check' }, 2: { color: 'red', class: 'pi-times' } } },
                                    },
                                ],
                            ],
                            this.submitForm,
                            2,
                            true,
                            this.state.mode || this.state.idUUID || this.state.data.subscriptionStatus === 2,
                            this.state.idUUID ? 9 : 12,
                            this.state.idUUID ? 3 : -1,
                            undefined,
                            e => (this.f = e)
                        ),
                        <ListSubscriptionsCycles context={this} />,
                        <Log idUUID={this.state.idUUID} logContextType={37} />,
                    ])}
                </div>
            );
        });
    }
}

export class ListSubscriptionsCycles extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () =>
        this.props.context.state.idUUID
            ? g_getData(this, SubscriptionService.listCycles, 'subscriptionCycles', {
                  filters: [...this.state.filter, pair('subscriptioniduuid', this.props.context.state.idUUID)],
              })
            : null;
    render() {
        return g_checkLoginACL(
            () => (
                <div className="p-col">
                    {g_template_dataTable(
                        this,
                        null,
                        [
                            { type: 'd', data: 'periodValue', sortable: true, filter: true },
                            { type: 'd', data: 'subscriptionValuePayed', sortable: true, filter: true },
                            { type: 'date', data: 'datePeriodStart', sortable: true, filter: true },
                            { type: 'date', data: 'datePeriodEnd', sortable: true, filter: true },
                            { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        ],
                        this.getData
                    )}
                </div>
            ),
            false,
            'consignee#subscriptions'
        );
    }
}
