export enum videoCallMessagesType {
    init = 'INIT-CALL',
    initwait = 'INIT-CALL-WAIT',
    initok = 'INIT-CALL-OK',
    initfail = 'INIT-CALL-FAIL',
    initcancel = 'INIT-CALL-CANCEL',
    initcancelok = 'INIT-CALL-CANCEL-OK',
    offerphase1req = 'OFFER-PHASE1-REQ',
    offerphase1resp = 'OFFER-PHASE1-RESP',
    offerphase2req = 'OFFER-PHASE2-REQ',
    offerphase2resp = 'OFFER-PHASE2-RESP',
    hangup = 'HANG-UP',
    endcall = 'END-CALL',
    endcallok = 'END-CALL-OK',
}

export interface videoCallMessage {
    type: videoCallMessagesType;
    referenceIdUUID?: string;
    callIdUUID?: string;
    tID?: number;
    recipient?: string;
}

export interface videoCallContract {
    consigneeIdUUID: string;
    contractTemplateIdUUID: string;
    consignatorIdUUID: string;
    productIdUUID?: string;
    contractTotalValue?: number;
    installmentTotal?: number;
    fillerField1?: string;
    fillerField2?: string;
    contractComplementaryFields?: any[];
}

export interface videoCallMessageInit extends videoCallMessage {
    type: videoCallMessagesType.init;
    behaviour: number;
    behaviourIdUUID: string;
    contract: videoCallContract;
}

export interface videoCallMessageCallFail extends videoCallMessage {
    type: videoCallMessagesType.initfail;
    messages: { key: number; value: string }[];
}

export interface videoCallMessageOffer extends videoCallMessage {
    type: videoCallMessagesType.offerphase1req | videoCallMessagesType.offerphase1resp | videoCallMessagesType.offerphase2req | videoCallMessagesType.offerphase2resp;
    code: number;
    value: string;
}
