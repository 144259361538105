import { GenericListResponseG } from '../../Generic';
import { GenericListRequest, RequestsClass } from '../../Requests';
import { ReportModel } from '../Report';

export type Filters = '';
export type OrderBy = 'dateCreation';

export const listreports =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'reports', ReportModel> =>
		req.axios.post(`${baseUrl}/list`, data);
