import { Messages } from 'primereact/messages';
import React, { Component } from 'react';
import Services from '../../service/Services';
import { LS, g_checkLoginACL, g_genericRequired, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../GenericFunctions';

export default class HelpManagement extends Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getData = () => Services.help.processed.list(this);
    componentDidUpdate() {
        if (this.state.showConsignator !== this.state.oldShowConsignator) {
            this.setState({ oldShowConsignator: this.state.showConsignator });
            g_hideSearch();
        }
    }
    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{LS('help', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '#/help?type=1', 'newhelp', this.getData, 1200, 500, e.c === 1),
                    [
                        { type: 'd', data: 'consignatorName', filter: true, sortable: true, c: this.state.showConsignator },
                        { type: 'd', data: 'title', filter: true },
                        { type: 'd', data: 'description', filter: true, style: { width: '75%' } },
                        { type: 'date', data: 'dateCreation', filter: true, sortable: true },
                        { type: 'url', c: e.u === 1 || e.d === 1, url: raw => '#/help?type=1&id=' + raw.idUUID, width: 1200, height: 500, callback: this.getData },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
