import React from 'react';
import { Messages } from 'primereact/messages';
import config from '../../config/config';
import { S, LS, g_checkLoginACL, g_entityFilter, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_openWindowDialog, g_template_dataTable } from '../GenericFunctions';

export default class BoletoManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        this.getData();
    }
    getUrl = barcode =>
        g_getData(
            this,
            () => S.boleto.barcode(barcode),
            response => g_openWindowDialog(config.BANKINGBILLET_URL + response.url, barcode, 1200, 700, false, '', this.getData)
        );

    getData = () =>
        g_getData(
            this,
            data => {
                if (this.props.refIdUUID) data.filters.push({ key: 'payerIdUUID', value: this.props.refIdUUID });
                let f = g_entityFilter(data.filters, 'consignatorIdUUID', 'consignatorsIdUUID', 5, 'boleto-management#acessall');
                if (!f) return { code: 0, pageTotal: 0, recordsTotal: 0, bankingBillets: [] };
                data.filters = f[0];
                return S.boleto.list(data);
            },
            'bankingBillets'
        );

    render() {
        return g_checkLoginACL(() => (
            <div className="card">
                {!this.props.refIdUUID ? <h1>{LS('boletos', 'titles')}</h1> : null}
                <Messages ref={el => (this.messages = el)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, 1000, 500, false),
                    [
                        { type: 'd', data: 'consignatorName', sortable: true, filter: true },
                        { type: 'd', align: 'center', data: 'bankId', sortable: true, filter: true },
                        { type: 'd', data: 'bankingBilletNumber', sortable: true, filter: true },
                        { type: 'd', align: 'right', data: 'payerVatNumber', sortable: true, filter: true },
                        { type: 'd', data: 'billetDisplayValue', sortable: true, filter: true },
                        { type: 'date', data: 'dateDue', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        { type: 'd', data: 'bankingBilletResponseCode', sortable: true, filter: true },
                        {
                            type: 'state',
                            data: 'bankingBilletStatus',
                            states: {
                                0: { color: 'orange', class: 'pi-step-forward', tooltip: LS('inList', 'tooltip') },
                                1: { color: 'grey', class: 'pi-spin pi-spinner', tooltip: LS('processing', 'tooltip') },
                                2: { color: 'green', class: 'pi-sign-in', tooltip: LS('sentToBank', 'tooltip') },
                                3: { color: 'red', class: 'pi-times', tooltip: LS('rejectedByBank', 'tooltip') },
                                4: { color: 'green', class: 'pi-check', tooltip: LS('payed', 'tooltip') },
                                5: { color: 'red', class: 'pi-clock', tooltip: LS('timeout', 'tooltip') },
                                6: { color: 'red', class: 'pi-trash', tooltip: LS('deleted', 'tooltip') },
                            },
                        },
                        { type: 'button', click: raw => this.getUrl(raw.barcode), icon: 'pi-download pi' },
                        { type: 'url', url: raw => '#/boleto?type=1&id=' + raw.idUUID, width: 1200, height: 800, callBack: this.getData, icon: 'pi pi-pencil', class: 'pi p-button-warning' },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
