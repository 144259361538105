import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useCallback, useEffect, useRef } from 'react';
import config from '../../../config/config';
import { ConsigneeModel, DataTableArgumentElement } from '../../../types';
import { Button, checkLoginACL, Dropdown, Form, GDiv, GE, GEditor, GState, GView, g_getData, noDataMessage, pair, StringEmpty, useDataObject, Wraper } from '../../Generic';
import { g_getHeaderTemplate } from '../../generic/DataTable';
import { TablePage, TablePagePropsHeader } from '../../generic/DataTableNew';
import { GDateInput, GInput, GMessages, StringIndexed } from '../../generic/Form';
import { g_displayMessageError, g_treatDate, ls, lt, S } from '../../GenericFunctions';

export interface MedicalCertificateModel {
    contractIdUUID: string;
    dateCreation: string;
    dateEnd: string;
    dateStart: string;
    dateUpdate?: string;
    description: string;
    idUUID: string;
    status: number;
    url: string;
}
interface MedicalCertificateProps {
    contractIdUUID: string;
    idUUID: string;
    saved?: (idUUID: string) => void;
    exit?: () => void;
    consignee: ConsigneeModel;
}
export const MedicalCertificate: React.FC<MedicalCertificateProps> = (props: MedicalCertificateProps) => {
    let { idUUID, contractIdUUID, consignee } = props;
    let messagesRef: React.MutableRefObject<Messages | null> = useRef(null);
    let { obj, setObj, setObjState } = useDataObject({} as MedicalCertificateModel & { dateRange: Date[] });
    let { obj: dialog, setObj: setDialog, setObjState: setDialogState } = useDataObject({ visible: false, otp: '' });
    let { obj: template, setObjState: setTemplate } = useDataObject<{
        suggestions: { label: string; value: string }[];
        selected: string;
    }>({ suggestions: [], selected: '' });

    let { obj: email, setObj: setEmail, setObjState: setEmailState } = useDataObject<{ visible: boolean; email: string }>({ visible: false, email: consignee.email });

    let getData = async () => {
        let r = await S.health.medicalCertificate.list({ filters: [pair('idUUID', idUUID)] });
        if (g_displayMessageError(messagesRef.current, r)) return;
        if (r.medicalCertificates.length === 0) {
            noDataMessage(messagesRef.current);
            return;
        }
        setObjState({ ...r.medicalCertificates[0], dateRange: [new Date(r.medicalCertificates[0].dateStart), new Date(r.medicalCertificates[0].dateEnd)] });
    };

    useEffect(() => {
        if (idUUID) getData();
        //TODO: improve it
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idUUID]);

    useEffect(() => {
        (async () => {
            let r = await S.health.medicalCertificateTemplate.list({});
            if (!g_displayMessageError(null, r)) setTemplate({ ...template, suggestions: r.medicalCertificatesTemplates.map(a => ({ value: a.bodyContent, label: a.name })) });
        })();
        //! Note not needed because this only suposed to be run at the time
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                obj={obj}
                setObj={setObj}
                onSubmit={async e => {
                    e.preventDefault();
                    if (idUUID) {
                        let data: MedicalCertificateModel = { ...obj, dateStart: g_treatDate(obj.dateRange[0]), dateEnd: g_treatDate(obj.dateRange[1]) };
                        (data as any).dateRange = undefined;
                        let r = await S.health.medicalCertificate.update({
                            medicalCertificate: data,
                        });
                        if (g_displayMessageError(messagesRef.current, r)) return;
                        getData();
                        if (props.exit) props.exit();
                    } else {
                        if (!contractIdUUID) return;
                        let data: MedicalCertificateModel = { ...obj, contractIdUUID, dateStart: g_treatDate(obj.dateRange[0]), dateEnd: g_treatDate(obj.dateRange[1]) };
                        (data as any).dateRange = undefined;
                        let r = await S.health.medicalCertificate.add({
                            medicalCertificate: data,
                        });
                        g_displayMessageError(messagesRef.current, r);
                        if (props.saved) props.saved(r.medicalCertificate.idUUID);
                    }
                }}
                disabled={!StringEmpty(obj.url)}
            >
                <GMessages refM={messagesRef} />
                <GDiv grid>
                    <GDiv class="p-col-9">
                        <GDateInput d="dateRange" fClass="p-col-3" selectionMode="range" />
                        <Wraper id="description" class="p-col-3" label="descriptionMedicalCertificate">
                            <Dropdown
                                id="selected"
                                label=""
                                value={template.selected}
                                fClass="p-col-6"
                                options={template.suggestions}
                                filter
                                hidden={!StringEmpty(obj.url)}
                                onChange={async e => {
                                    let tDate = (date?: Date) =>
                                        !date ? null : `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear())}`;

                                    let days = 0;

                                    if (obj.dateRange && obj.dateRange[0]) {
                                        if (!obj.dateRange[1]) {
                                            obj.dateRange[1] = obj.dateRange[0];
                                            setObjState({ ...obj });
                                        }

                                        //The number of milliseconds in days
                                        days = Math.floor((obj.dateRange[1].getTime() - obj.dateRange[0].getTime()) / 86400000) + 1;
                                    }

                                    let semantics: StringIndexed = {
                                        ...(props.consignee ?? {}),
                                        vatNumber: formatCPF(String(props.consignee.vatNumber).padStart(11, '0')),
                                        dateStart: tDate(obj.dateRange ? obj.dateRange[0] : undefined) ?? '',
                                        dateEnd: tDate(obj.dateRange ? obj.dateRange[1] : undefined) ?? '',
                                        dateNow: tDate(new Date()),
                                        days: String(days),
                                    };
                                    //GET cids
                                    let r = await S.health.cid.contract.list({
                                        filters: [pair('contractIdUUID', contractIdUUID), pair('status', 1)],
                                    });

                                    let CIDs = '';
                                    if (!g_displayMessageError(null, r) && r.cidContracts.length > 0) {
                                        CIDs = 'CID ' + r.cidContracts.map(a => a.cidId).reduce((a, b) => a + ',' + b);
                                    }
                                    semantics = { ...semantics, CIDs };
                                    let a = e.target.value;
                                    for (let b of Object.keys(semantics)) {
                                        a = a.replace(new RegExp('{' + b + '}', 'g'), semantics[b]);
                                    }
                                    setObjState({ ...obj, description: a });
                                    setTemplate({ ...template, selected: e.target.value });
                                }}
                            />
                        </Wraper>
                        <GEditor l="" d="description" fClass="p-col-12" />
                    </GDiv>
                    <GDiv class="p-col-3" hidden={!idUUID}>
                        <GState
                            d="status"
                            custom={{
                                1: { class: 'pi-check', color: 'green' },
                                2: { class: 'pi-times', color: 'red' },
                            }}
                        />
                        <GView d="dateUpdate" />
                        <GView d="dateCreation" />
                    </GDiv>
                </GDiv>
                <GDiv grid class="p-col-12">
                    <Button type="submit" label={idUUID ? 'save' : 'create'} hidden={!StringEmpty(obj.url)} />
                    <Button
                        type="button"
                        label={'sign'}
                        hidden={!StringEmpty(obj.url) || StringEmpty(obj.idUUID)}
                        onClick={async e => {
                            e.preventDefault();
                            setDialogState({ ...dialog, visible: true });
                        }}
                    />
                    <Button
                        label="print"
                        hidden={!obj.url}
                        onClick={e => {
                            e.preventDefault();
                            window.open(config.HEALTH + obj.url);
                        }}
                    />
                    <Button
                        label="sendEmail"
                        hidden={!obj.url}
                        onClick={e => {
                            e.preventDefault();
                            setEmailState({ ...email, visible: true });
                        }}
                    />
                </GDiv>
            </Form>
            <Dialog
                style={{ minHeight: '20vh', minWidth: '20vw' }}
                contentStyle={{ minHeight: '20vh', minWidth: '20vw' }}
                visible={dialog.visible}
                onHide={() => setDialogState({ otp: '', visible: false })}
            >
                <Form
                    obj={dialog}
                    setObj={setDialog}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (!idUUID) return;
                        let r = await S.health.medicalCertificate.sign({
                            idUUID,
                            systemCertifier: '',
                            otp: dialog.otp,
                        });
                        if (g_displayMessageError(messagesRef.current, r)) return;
                        setDialogState({ visible: false, otp: '' });
                        getData();
                    }}
                >
                    <GE
                        d="otp"
                        extra={{
                            regex: /\d{0,6}/g,
                        }}
                        fClass="p-col-3"
                        req
                    />
                    <Button label="advance" wraperClass="p-col-4 p-sm-8" />
                </Form>
            </Dialog>
            <Dialog
                contentStyle={{ minHeight: '40vh', minWidth: '40vw' } as React.CSSProperties}
                onHide={() => setEmailState({ ...email, visible: false })}
                visible={email.visible}
            >
                <Form
                    setObj={setEmail}
                    obj={email}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (StringEmpty(obj.idUUID)) return;
                        let r = await S.health.medicalCertificate.email({
                            idUUID: obj.idUUID,
                            email: email.email,
                        });
                        g_displayMessageError(messagesRef.current, r);
                        setEmailState({ ...email, visible: false, email: consignee.email });
                    }}
                >
                    <GInput d="email" type="email" fClass="p-col-3" req />
                    <Button type="submit" label="send" wraperClass="p-col-2" />
                </Form>
            </Dialog>
        </>
    );
};
interface MedicalCertificateListProps {
    header: TablePagePropsHeader;
    extra?: DataTableArgumentElement<MedicalCertificateModel>[];
    extraRequest?: (c: TablePage<MedicalCertificateModel, any>) => {
        [key: string]: any;
    };
    refTable?: (e: TablePage<MedicalCertificateModel, any> | null) => void;
    checkAcl?: boolean;
    fakename?: string;
}
export const MedicalCertificateList: React.FC<MedicalCertificateListProps> = (props: MedicalCertificateListProps) => {
    return (
        <TablePage<MedicalCertificateModel, {}>
            header={props.header}
            getData={c => g_getData(c, S.health.medicalCertificate.list, 'medicalCertificates', (props.extraRequest ?? (() => ({})))(c))}
            table={() => [{ t: 'd', data: 'description', title: ls('descriptionMedicalCertificate'), sortable: true, filter: true }, ...(props.extra ?? [])]}
            ref={props.refTable ?? (() => {})}
            fakename={props.fakename}
            checkAcl={props.checkAcl}
        />
    );
};

//Template
export interface MedicalCertificateTemplateModel {
    bodyContent: string;
    dateCreation: string;
    dateUpdate: string;
    idUUID: string;
    name: string;
    status: number;
}
export interface MedicalCertificateTemplateModelCreate {
    name: string;
    bodyContent: string;
    status?: number;
    dateCreation?: string;
    dateUpdate?: string;
    idUUID?: string;
}
//List
interface MedicalCertificateTemplateListProps {
    bttEvent?: (raw: MedicalCertificateTemplateModel, e: React.MouseEvent<HTMLButtonElement>) => void;
}
export const MedicalCertificateTemplateList: React.FC<MedicalCertificateTemplateListProps> = ({ bttEvent }) => {
    return (
        <TablePage<MedicalCertificateTemplateModel>
            fakename={'medical-certificate-template'}
            checkAcl={bttEvent === undefined}
            header={c => g_getHeaderTemplate(c, '/#/medical-certificate-template?type=1', '_blank', c.getData, 1200, 700)}
            getData={c => g_getData(c, S.health.medicalCertificateTemplate.list, 'medicalCertificatesTemplates')}
            table={(_, e) => [
                { t: 'd', data: 'name', sortable: true, filter: true },
                { t: 'date', data: 'dateCreation', sortable: true, filter: true },
                { t: 'url', c: bttEvent === undefined || (!e.db && !e.ub), url: raw => `/#/medical-certificate-template?type=1&id=${raw.idUUID}`, width: 1200, height: 700 },
                { t: 'btt', c: bttEvent !== undefined, icon: 'pi pi-upload', click: bttEvent ?? (() => {}) },
            ]}
        />
    );
};
//Object
interface MedicalCertificateTemplateProps {
    idUUID: string;
}
export const MedicalCertificateTemplate: React.FC<MedicalCertificateTemplateProps> = ({ idUUID }) => {
    let { error, profile } = checkLoginACL({});
    let messagesRef: React.MutableRefObject<Messages | null> = useRef(null);

    let { obj, setObj, setObjState } = useDataObject({} as MedicalCertificateTemplateModel);

    let getData = useCallback(() => {
        (async () => {
            let r = await S.health.medicalCertificateTemplate.list({
                filters: [pair('iduuid', idUUID)],
            });
            if (g_displayMessageError(messagesRef.current, r)) return;
            if (r.medicalCertificatesTemplates.length === 0) {
                noDataMessage(messagesRef.current);
                return;
            }
            setObjState({ ...r.medicalCertificatesTemplates[0] });
        })();
    }, [idUUID, setObjState]);

    useEffect(() => {
        if (error || StringEmpty(idUUID)) return;
        getData();
    }, [getData, error, idUUID]);

    if (error) return error;
    return (
        <TabView style={{ minHeight: '100%' } as React.CSSProperties}>
            <TabPanel contentStyle={{ minHeight: '100%' }} header={lt('medicalCertificateTemplate')}>
                <Form<MedicalCertificateTemplateModel>
                    obj={obj}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (StringEmpty(idUUID)) {
                            let r = await S.health.medicalCertificateTemplate.add({
                                medicalCertificateTemplate: obj,
                            });
                            if (g_displayMessageError(messagesRef.current, r)) return;
                            window.location.href = `/#/medical-certificate-template?type=1&id=${r.medicalCertificateTemplate.idUUID}`;
                            window.location.reload();
                        } else {
                            let r = await S.health.medicalCertificateTemplate.update({
                                medicalCertificateTemplate: obj,
                            });
                            if (g_displayMessageError(messagesRef.current, r)) return;
                            getData();
                        }
                    }}
                    setObj={setObj}
                >
                    <GMessages refM={messagesRef} />
                    <GDiv grid style={{ height: '80vh' }}>
                        <GDiv class="p-col-9">
                            <GE d="name" req />
                            <GEditor fClass="p-col-12" d="bodyContent" req />
                        </GDiv>
                        <GDiv class="p-col-3" hidden={StringEmpty(idUUID)}>
                            <GView d="dateCreation" />
                            <GView d="dateUpdate" />
                        </GDiv>
                    </GDiv>
                    <GDiv>
                        <Button label="create" hidden={!StringEmpty(idUUID) || !profile?.cb} />
                        <Button label="save" hidden={StringEmpty(idUUID) || !profile?.ub} />
                    </GDiv>
                </Form>
            </TabPanel>
        </TabView>
    );
};
//TODO: make this generic
const formatCPF: (s: string | null | undefined) => string = s => {
    if (s === undefined || s === null) return '';
    s = s.replace(/[^\d]/g, '').substr(0, 11);
    if (s.length >= 10) {
        s = s.replace(/(\d{9})(\d{1,2})/, '$1-$2');
    }
    if (s.length >= 7) {
        s = s.replace(/(\d{6})(\d{1,3})(.*)/, '$1.$2$3');
    }
    if (s.length >= 4) {
        s = s.replace(/(\d{3})(\d{1,3})(.*)/, '$1.$2$3');
    }
    return s;
};
