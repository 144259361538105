import { Dialog } from 'primereact/dialog';
import { InputText as PInputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { StringIndexed } from './api-ts-bindings/Requests';
import { TablePage } from './components/GenericFunctions';
import { ls } from './components/generic/LabelSelector';
import { Builder } from './react-generics/Generic';
import { TableBuilder } from './react-generics/Table';
import { FormBuilder } from './react-generics/Form';
import { aclItem, DataTableArgumentElement, LoggedUserData, TablePagePropsGetData, TablePagePropsGetTable } from './types';
import config from './config/config';

export * from './react-generics/Form';
export * from './react-generics/Generic';

const BuildAll = Builder(ls);

export const { showMessage, InputSwitch, Dropdown, TextArea, Wraper, DialogPrompt, InputNumber, DateInput, Editor, Input, InputMask, Button, noDataMessage, useAcl, useAclHookless } = BuildAll;

const BuildForm = FormBuilder(ls, config, BuildAll);

export const { Form, GDiv } = BuildForm;

export const [Table, TableHeader] = TableBuilder({ pageSize: 10 }, ls as any, BuildAll, BuildForm);

/**
 * @deprecated use {@link useAcl}
 * @param param0
 * @returns
 */
export function checkLoginACL({ checkPageAcl = true, fakename }: { checkPageAcl?: boolean; fakename?: string | string[] }): {
    error?: ReactElement;
    profile?: aclItem;
    loggeduser?: LoggedUserData;
} {
    let loggedUserN: LoggedUserData | null = null;
    try {
        loggedUserN = JSON.parse(localStorage.getItem('loggedUserN') ?? '');
    } catch (_) {}
    if (loggedUserN === null) return { error: <Redirect to="/login" /> };
    if (!checkPageAcl) return { profile: { r: 1, u: 1, d: 1, c: 1, rb: true, ub: true, db: true, cb: true }, loggeduser: loggedUserN };
    if (fakename === undefined || typeof fakename === 'string') {
        let url = fakename ?? window.location.hash.substr(2);
        url = url.indexOf('?') === -1 ? url : url.split('?')[0];
        let profile = (loggedUserN.profileACLs as aclItem[]).filter(v => v.type === 4 && v.r === 1).filter(v => v.tag === url);
        return { profile: profile[0], loggeduser: loggedUserN, error: profile.length === 0 ? <span>{ls('noPermission', 'generic')}</span> : undefined };
    }
    for (let url of fakename) {
        let profile = (loggedUserN.profileACLs as aclItem[]).filter(v => v.type === 4 && v.r === 1).filter(v => v.tag === url);
        if (profile[0]) return { profile: profile[0], loggeduser: loggedUserN, error: undefined };
    }
    return { profile: undefined, loggeduser: loggedUserN, error: <span>{ls('noPermission', 'generic')}</span> };
}

interface SelectorProps<T extends StringIndexed> {
    hidden?: boolean;

    wraperClass?: string;

    labelClass?: string;
    label?: string;

    inputClass?: string;

    buttonWraperClass?: string;

    value?: string;
    required?: boolean;

    disabled?: boolean;

    jump?: () => void;
    jumpCondition: boolean;

    useChildren?: boolean;
    children?: React.ReactChildren;

    getData: TablePagePropsGetData;

    table: (close: () => void, c: TablePage) => DataTableArgumentElement<T>[];
    tableRef: React.RefObject<TablePage<T>>;
}
export const Selector = <T extends StringIndexed>({
    hidden,
    wraperClass,
    useChildren,
    children,
    labelClass = 'p-col-3',
    label,
    inputClass = 'p-col-5',
    value,
    required,
    disabled,
    jump,
    jumpCondition,

    table,
    tableRef: tableRefProps,

    getData,
}: SelectorProps<T>) => {
    let [visible, setVisible] = useState(false);
    let tableRef: React.RefObject<TablePage<T>> = useRef<TablePage<T>>(null);
    tableRefProps = tableRef;

    if (label) label = ls(label) === 'LabelInfenranceFailed' ? label : ls(label);

    if (hidden) return null;

    if (useChildren) {
        return (
            <div className={wraperClass}>
                <Dialog header="Selecionar" visible={visible} style={{ width: '80vw', height: '80vh' }} onHide={() => setVisible(false)}>
                    <SelectorData table={(c: any) => table(() => setVisible(false), c)} tableRef={tableRef} getData={getData} />
                </Dialog>
                <div className="p-grid p-fluid">{children}</div>
            </div>
        );
    }

    let toggleDialog = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        setVisible(true);
    };

    return (
        <div className={wraperClass}>
            <Dialog header="Selecionar" visible={visible} style={{ width: '80vw', height: '80vh' }} onHide={() => setVisible(false)}>
                <SelectorData table={(c: any) => table(() => setVisible(false), c)} tableRef={tableRef} getData={getData} />
            </Dialog>
            <div className="p-grid p-fluid">
                <div className={labelClass}>{label}</div>
                <div className={inputClass}>
                    <PInputText value={value} required={required} onChange={() => {}} onClick={toggleDialog} disabled={disabled} />
                </div>
                <Button wraperClass="p-col-1" label="" icon="pi pi-plus" onClick={e => toggleDialog(e)} disabled={disabled} />
                {(() =>
                    jump && jumpCondition ? (
                        <Button
                            wraperClass="p-col-1"
                            label=""
                            icon="pi pi-pencil"
                            bttClass="p-button-warning"
                            onClick={e => {
                                e.preventDefault();
                                jump!();
                            }}
                        />
                    ) : null)()}
            </div>
        </div>
    );
};

interface SelectorDataProps<T> {
    table: TablePagePropsGetTable<T>;
    getData: TablePagePropsGetData;
    tableRef: React.RefObject<TablePage<T>>;
}

const SelectorData = <T extends StringIndexed>({ getData, table, tableRef }: SelectorDataProps<T>) => {
    return <TablePage header={null} getData={getData} table={table} ref={tableRef} checkAcl={false}></TablePage>;
};
