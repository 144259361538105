import axios from 'axios';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ClockingModelStatus } from './api-ts-bindings/Consignee';
import { pairF } from './api-ts-bindings/Generic';
import { AppProfile } from './AppProfile';
import SidebarMenu from './AppSidebarMenu';
import { AppTopbar } from './AppTopbar';
import { LUser } from './components/admin/backUsers/LUser';
import User from './components/admin/backUsers/User';
import UserManagement from './components/admin/backUsers/UserManagement';
import UserApp from './components/appUsers/UserApp';
import UserManagementApp from './components/appUsers/UserManagementApp';
import Asset from './components/assets/Asset';
import { ActivesManagement } from './components/assets/AssetsManagement';
import BatchEdit from './components/batch/BatchEdit';
import Batchs from './components/batch/Batchs';
import BatchsEdit from './components/batch/BatchsEdit';
import UploadGuarantee from './components/batch/UploadGuarantee';
import UploadMargin from './components/batch/UploadMargin';
import UploadRegister from './components/batch/UploadRegister';
import UploadSheet from './components/batch/UploadSheet';
import Boleto from './components/boleto/Boleto';
import BoletoManagement from './components/boleto/BoletoManagement';
import CacheEdit from './components/cache/CacheEdit';
import CacheManagement from './components/cache/CacheManagement';
import Campaign from './components/campaign/Campaign';
import CampaignManagement from './components/campaign/CampaignManagement';
import CopyCampaign from './components/campaign/CopyCampaign';
import CampaignProduct from './components/campaignProduct/CampaignProduct';
import CampaignProductManagement from './components/campaignProduct/CampaignProductManagement';
import Card from './components/card/Card';
import CardManagement from './components/card/CardManagement';
import { PaymentsManagement } from './components/card/PaymentsManagement';
import ChangePassword from './components/changePassword/ChangePassword';
import { ImageLink } from './components/cms/ImageLink';
import ConfigContext from './components/configContexts/ConfigContext';
import ConfigContextManagement from './components/configContexts/ConfigContextManagement';
import BatchMoney from './components/consignator/BatchMoney';
import ClockingLocation from './components/consignator/ClockingLocation';
import Consignator from './components/consignator/Consignator';
import { consignatorManagement } from './components/consignator/ConsignatorManagement';
import UserCreateConsignator from './components/consignator/UserCreateConsignator';
import Clocking from './components/consignee/Clocking';
import { ClockingMangament } from './components/consignee/Clockings';
import Consignee from './components/consignee/Consignee';
import Contact from './components/consignee/Contact';
import { consigneeManagement } from './components/consignee/lists';
import PayrollDetail from './components/consignee/PayrollDetail';
import Subscribe from './components/consignee/Subscribe';
import Contract from './components/contract/Contract';
import { contractGroupByProductType, contractGroupByTemplate, ContractManagement } from './components/contract/ContractManagement';
import ContractTemplate from './components/contractTemplate/ContractTemplate';
import ContractTemplateField from './components/contractTemplate/ContractTemplateField';
import ContractTemplateFieldManagement from './components/contractTemplate/ContractTemplateFieldManagement';
import ContractTemplateManagement from './components/contractTemplate/ContractTemplateManagement';
import CopyTemplate from './components/contractTemplate/CopyTemplate';
import { Margin } from './components/contractTemplate/margin/Margin';
import { Rating } from './components/contractTemplate/Rating';
import { Dashboard } from './components/Dashboard';
import ConsignatorDashboard from './components/dashboards/consignatorDashboard';
import FinancierDashboard from './components/dashboards/financierDashboard';
import { MedicDashbord } from './components/dashboards/MedicDashbord';
import { ProtetorControlerDashboard } from './components/dashboards/PortetorControlerDashboard';
import { VideocallDashbord } from './components/dashboards/videodashboard';
import Financier from './components/financier/Financier';
import FinancierManagement from './components/financier/FinancierManagement';
import UserCreateFinancier from './components/financier/UserCreateFinancier';
import { mapPage } from './components/generic/Map';
import { g_checkAlc, g_getDashbords, g_getReports } from './components/GenericFunctions';
import { Group } from './components/groups/Group';
import { groups } from './components/groups/lists';
import Help from './components/help/Help';
import HelpManagement from './components/help/HelpManagement';
import Login from './components/login/Login';
import Multilanguage from './components/multilanguage/Multilanguage';
import { HTMLPreview, MultilanguageManagement } from './components/multilanguage/MultilanguageManagement';
import Notification from './components/notification/Notification';
import NotificationManagement from './components/notification/NotificationManagement';
import { Product, Stock } from './components/product/Product';
import { productManagement } from './components/product/ProductManagement';
import ProductsPackage from './components/productpackages/ProductsPackage';
import ProductsPackageManagement from './components/productpackages/ProductsPackageManagement';
import ProductTypeManagement from './components/productTypeManagement/productTypeManagement';
import ControlItem from './components/profile/ControlItem';
import ControlItemManagement from './components/profile/ControlItemManagement';
import CopyProfile from './components/profile/CopyProfile';
import Profile from './components/profile/Profile';
import ProfileManagement from './components/profile/ProfileManagement';
import Promotion from './components/promotions/promotion';
import PromotionsActive from './components/promotions/promotions-active';
import PromotionsPast from './components/promotions/promotions-past';
import AmbulanceActive from './components/protetor/ambulance/ambulance-active';
import AmbulancePast from './components/protetor/ambulance/ambulance-past';
import Calls from './components/protetor/calls/callcenter';
import CallInfo from './components/protetor/calls/callinfo';
import { CallInfoManagement } from './components/protetor/calls/callinfoManagement';
import CallsActive from './components/protetor/calls/calls-active';
import CallsPast from './components/protetor/calls/calls-past';
import { FullHistoryList } from './components/protetor/calls/FullInfoList';
import { MedicalCertificateTemplate, MedicalCertificateTemplateList } from './components/protetor/calls/MedicalCertificate';
import { OldCall } from './components/protetor/calls/oldcall';
import { CallDocumentList, VanDocs } from './components/protetor/calls/van/docs';
import ContractP from './components/protetor/contract-protector/ContractP';
//Protetor
import LifeButtonActive from './components/protetor/life-button/life-button-active';
import LifeButtonPast from './components/protetor/life-button/life-button-past';
import { BusinessHours } from './components/provider/BusinessHours';
import { CalendarEvent } from './components/provider/CalendarEvent';
import { providerList } from './components/provider/lists';
import { AssociationServiceContractTemplate, Provider, SchedulueList } from './components/provider/Provider';
import ProviderCreateUser from './components/provider/ProviderCreateUser';
import { BusinessHoursList } from './components/provider/ProviderNew';
import { Sale } from './components/provider/sale';
import RecoverPassword from './components/recoverPassword/RecoverPassword';
import { ReportBillList } from './components/reports/ReportBill';
import { ReportCallByProvider } from './components/reports/ReportCallByProvider';
import Reportsetl from './components/reports/Reportsetl';
import { SearchArea, SearchCategory, SearchService } from './components/searchSegment/search';
import { SearchAreaActive, SearchAreaServiceCategories, SearchAreaServiceTypes } from './components/searchSegment/search-active';
import SearchAreaPast from './components/searchSegment/search-past';
import { segment } from './components/segment/lists';
import { News } from './components/segment/segment';
import ServiceBatch from './components/serviceBatch/ServiceBatch';
import ServiceBatchManagement from './components/serviceBatch/ServiceBatchManagement';
import UserManagementServ from './components/serviceUsers/UserManagementServ';
import UserServ from './components/serviceUsers/UserServ';
import StagingConsignee from './components/staging/consignee/StagingConsignee';
import StagingConsigneeManagement from './components/staging/consignee/StagingConsigneeManagement';
import Voucher from './components/voucher/Voucher';
import VoucherManagement from './components/voucher/VoucherManagement';
import ZipCode from './components/zipCode/ZipCode';
import ZipCodeManagement from './components/zipCode/ZipCodeManagement';
import config from './config/config';

const ComponentTagMath = {
    'dashboard-financier': FinancierDashboard,
    'dashboard-consignator': ConsignatorDashboard,
    'report-etl': Reportsetl,
    'report-bill': ReportBillList,
    'dashboard-video-protetor': VideocallDashbord,
    'dashboard-medic': MedicDashbord,
    'dashboard-control-protetor': ProtetorControlerDashboard,
    'report-medic': ReportCallByProvider,
};

const SockJS = window.SockJS;
const Stomp = window.Stomp;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            noMenu: false,
            userLogged: localStorage.getItem('loggedUserN') ? true : false,
            showBtt: true,

            messages: []
        };
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.requestCounter = 0;
    }

    messages = []
    componentWillMount() {
        //Disable menu on type === 1
        const url = window.location.href.substr(window.location.href.indexOf('?'));
        const query = new URLSearchParams(url);
        const type = query.get('type');
        if (type === '1') this.setState({ noMenu: true });

        //Added load indicator
        axios.interceptors.request.use(
            config => {
                const isBackground = config?.headers['background-request'];
                const message = config?.sp_headers['request-message'];
                if (isBackground === undefined)  {
                    document.body.classList.add('loading-indicator');
                    this.requestCounter = this.requestCounter + 1;
                }
                if (message)  {
                    this.messages = [...this.messages, message];
                    this.setState({messages: this.messages})
                }
                return config;
            },
            error => Promise.reject(error)
        );

        //remove loadindicator
        axios.interceptors.response.use(
            response => {
                const isBackground = response?.config?.headers['background-request'];
                const message = response?.config?.sp_headers['request-message'];
                if (isBackground === undefined) this.requestCounter = this.requestCounter - 1;

                if (message)  {
                    let found = false;
                    this.messages = this.messages.filter(m => {
                        if (found) return true
                        if (m === message) {
                            found = true;
                            return  false
                        }
                        return true
                    });
                    this.setState({messages: this.messages})
                }

                if (this.requestCounter === 0) {
                    document.body.classList.remove('loading-indicator');
                }
                return response;
            },
            error => Promise.reject(error)
        );

        this.checkWs();
    }

    onWrapperClick() {
        if (!this.menuClick) this.setState({ overlayMenuActive: false, mobileMenuActive: false });
        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;
        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') this.setState({ overlayMenuActive: !this.state.overlayMenuActive });
            else if (this.state.layoutMode === 'static') this.setState({ staticMenuInactive: !this.state.staticMenuInactive });
        } else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({ mobileMenuActive: !mobileMenuActive });
        }
        if (event) event.preventDefault();
    }

    onSidebarClick = () => this.menuClick = true;
    isDesktop = () => window.innerWidth > 1024;
    handlerLogin = data => this.setState({ userLogged: data }, () => this.checkWs());
    getBttShow = () => this.state.showBtt;
    setBttShow = bool => this.setState({ showBtt: bool });
    isMenuOpen = () => this.isDesktop() ? 
        (this.state.layoutMode === 'overlay' ? this.state.overlayMenuActive : 
            (this.state.layoutMode === 'static' ? !this.state.staticMenuInactive : false)  )
        : this.state.mobileMenuActive

    addClass(element, className) {
        if (element.classList) element.classList.add(className);
        else element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    componentDidUpdate() {
        if (JSON.parse(localStorage.getItem('loggedUserN')) !== null) document.body.style.background = '#edf0f5';
        if (this.state.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
        else this.removeClass(document.body, 'body-overflow-hidden');
        this.checkWs();
    }

    socket = null;
    sstomp = null;
    lasttopics = [];
    counter = 0;
    timeout = false;
    checkWs = timeout => {
        if (this.state.noMenu || window.opener !== null) return;
        this.counter++;
        let loggedUserN = JSON.parse(localStorage.getItem('loggedUserN'));
        if (loggedUserN === null) {
            this.lasttopics = [];
            if (this.socket) 
                this.socket.close();
            this.socket = null;
            this.sstomp = null;
            window.stompC = null;
            window.stompConnect = false;
            return;
        }
        let topics = [];
        if (g_checkAlc(4, 'life-button-active'))
            topics.push({
                topic: '/topic/livebuttonrequests',
                url: '/#/life-button-active',
                topname: 'Botao vida',
            });
        if (g_checkAlc(4, 'ambulance-active'))
            topics.push({
                topic: '/topic/ambulancerequests',
                url: '/#/ambulance-active',
                topname: 'Ambulacia',
            });
        if (g_checkAlc(4, 'calls')) topics.push({ topic: 'calls', donot: true, url: null, topname: 'calls' });
        topics = topics.filter(topic => this.lasttopics.indexOf(topic.topic) === -1);
        if (topics.length === 0 && this.socket !== null) return;
        if (this.socket === null) {
            this.socket = new SockJS(config.SOCKET);
            this.socket.addEventListener('close', e => {
                this.socket = null;
                this.sstomp = null;
                window.stompC = null;
                window.stompConnect = false;
                this.lasttopics = [];
                if (e.code === 1003) {
                    localStorage.removeItem('loggedUserN');
                    window.location.href = '/#/login';
                    window.location.reload();
                    return;
                }
                setTimeout(this.checkWs(), 5000);
                return;
            });
            setTimeout(this.checkWs(), 5000);
            return;
        }
        try {
            window.Notification.requestPermission().then();
        } catch (e) {
            return;
        }
        if (!this.socket) 
            return this.socket = null;
        window.stompC = Stomp.over(this.socket);
        window.stompC.debug = null;
        let yyy = this.lasttopics;
        let sstomp = this.sstomp;

        let createNoti = (message, topic) => {
            let canRecive = localStorage.getItem('canRecive');
            let oncall = localStorage.getItem('oncall');
            if (canRecive === 'false' || oncall === 'true') return;
            let noti = new window.Notification(topic.topname, {
                body: JSON.parse(message.body).description,
            });
            noti.onclick = () => {
                window.location.href = topic.url;
                window.location.reload();
            };
        };

        let rtimeout = false;
        if (sstomp) {
            topics.forEach(topic => {
                if (!topic.donot) window.stompC.subscribe(topic.topic, message => createNoti(message, topic));
                yyy.push(topic.topic);
            });
        } else {
            window.stompC.connect({ token: loggedUserN.token }, function () {
                console.log('connected websocket ');
                sstomp = this;
                rtimeout = true;
                window.stompConnect = true;
                topics.forEach(topic => {
                    if (!topic.donot) window.stompC.subscribe(topic.topic, message => createNoti(message, topic));
                    yyy.push(topic.topic);
                });
            });
        }
        if (rtimeout) 
            this.timeout = false;
        this.lasttopics = yyy;
        window.topicsConected = yyy;
        this.sstomp = sstomp;
        if (!this.sstomp && timeout && !this.timeout)
            setTimeout(() => {
                this.timeout = true;
                if (this.counter > 5) {
                    if (this.socket) this.socket.close();
                    this.socket = null;
                    console.log('socket clear');
                    setTimeout(() => this.checkWs(true), 1000);
                    this.counter = 0;
                }
                this.checkWs(true);
            }, 1000);
    };

    getRoutes() {
        let dashbords = [];
        let a = 0;
        g_getDashbords().forEach((v, i) => {
            if (i === 0) dashbords.push(<Route key={a} path="/" exact component={ComponentTagMath[v] ? ComponentTagMath[v] : Dashboard} />);
            else dashbords.push(<Route key={a} path={'/' + v} exact component={ComponentTagMath[v] ? ComponentTagMath[v] : Dashboard} />);
            a++;
        });
        if (dashbords.length === 0) dashbords.push(<Route key="0" path="/" exact component={Dashboard} />);
        let reports = [];
        g_getReports().forEach(v => {
            reports.push(<Route key={a} path={'/' + v} exact component={ComponentTagMath[v] ? ComponentTagMath[v] : Dashboard} />);
            a++;
        });
        return (
            <>
                {dashbords}
                {reports}

                <Route path="/login" exact render={() => <Login handlerLogin={this.handlerLogin} />} />
                <Route path="/recover-password" exact component={RecoverPassword} />

                <Route path="/admin-user-management" component={UserManagement} />
                <Route path="/user" component={User} />
                <Route path="/LUser" component={LUser} />

                <Route path="/admin-user-management-app" component={UserManagementApp} />
                <Route path="/user-app" component={UserApp} />

                <Route path="/admin-user-management-serv" component={UserManagementServ} />
                <Route path="/user-serv" component={UserServ} />

                <Route path="/consignee-management" render={consigneeManagement.render} />
                <Route path="/consignee" component={Consignee} />

                <Route path="/consignee-calendar-plan" render={() => <BusinessHoursList fakename="calls" />} />
                <Route path="/consignee-calendar" render={() => <SchedulueList />} />

                <Route
                    path="/consignee-clock"
                    render={e => (
                        <ClockingMangament
                            checkConsignator
                            extraFilter={pairF('clockingstatus', ClockingModelStatus.pending_validation, new URLSearchParams(e.location.search).get('s') === '1')}
                        />
                    )}
                />
                <Route path="/contact" component={Contact} />
                <Route path="/subscribe" component={Subscribe} />
                <Route path="/payroll" component={PayrollDetail} />
                <Route path="/clocking" component={Clocking} />

                <Route path="/consignator-management" render={() => consignatorManagement.render} />
                <Route path="/consignator" component={Consignator} />
                <Route path="/clockinglocation" component={ClockingLocation} />
                <Route path="/payrollbudget" component={BatchMoney} />
                <Route path="/newuserfac" component={UserCreateConsignator} />

                <Route path="/batch-sheet" component={UploadSheet} />
                <Route path="/batch-guarantee" component={UploadGuarantee} />
                <Route path="/batch-register" component={UploadRegister} />
                <Route path="/batch-margin" component={UploadMargin} />
                <Route path="/batchs" component={Batchs} />
                <Route path="/batchsedit" component={BatchsEdit} />
                <Route path="/batchedit" component={BatchEdit} />

                <Route path="/change-password" exact component={ChangePassword} />

                <Route path="/product-management" render={e => productManagement.render(new URLSearchParams(e.location.search).get('mode'))} />
                <Route path="/product" component={Product} />
                <Route path="/stock" component={Stock} />

                <Route path="/notification-management" component={NotificationManagement} />
                <Route path="/notification" component={Notification} />

                <Route path="/contract-management" component={ContractManagement} />
                <Route path="/contract-managemnet-groupby-template" render={contractGroupByTemplate} />
                <Route path="/contract-managemnet-groupby-product-type" render={contractGroupByProductType} />
                <Route path="/contract" component={Contract} />

                <Route path="/provider-management" render={providerList.render} />
                <Route path="/provider" component={Provider} />
                <Route path="/provider-service-contract" render={e => <AssociationServiceContractTemplate id={new URLSearchParams(e.location.search).get('id')} />} />
                <Route path="/calendar-event" component={CalendarEvent} />
                <Route path="/business-hours" component={BusinessHours} />
                <Route path="/newuserfasp" component={ProviderCreateUser} />

                <Route path="/financier-management" component={FinancierManagement} />
                <Route path="/financier" component={Financier} />
                <Route path="/newuserfaf" component={UserCreateFinancier} />

                <Route path="/campaign-management" component={CampaignManagement} />
                <Route path="/campaign" component={Campaign} />
                <Route path="/copyCampaign" component={CopyCampaign} />

                <Route path="/contractTemplate-management" component={ContractTemplateManagement} />
                <Route path="/contractTemplate" component={ContractTemplate} />
                <Route path="/copyContractTemplate" component={CopyTemplate} />
                <Route path="/margin-control" component={Margin} />
                <Route path="/ratting" component={Rating} />

                <Route path="/campaignProduct-management" component={CampaignProductManagement} />
                <Route path="/campaignProduct" component={CampaignProduct} />

                <Route path="/packageProduct-management" component={ProductsPackageManagement} />
                <Route path="/packageProduct" component={ProductsPackage} />

                <Route path="/product-type-management" component={ProductTypeManagement} />
                <Route path="/zipcode-management" component={ZipCodeManagement} />
                <Route path="/zipcode" component={ZipCode} />

                <Route path="/config-contex-management" component={ConfigContextManagement} />
                <Route path="/config-contex" component={ConfigContext} />

                <Route path="/contractTemplateField-management" component={ContractTemplateFieldManagement} />
                <Route path="/contractTemplateField" component={ContractTemplateField} />

                <Route path="/profile-management" component={ProfileManagement} />
                <Route path="/profile" component={Profile} />
                <Route path="/copyProfile" component={CopyProfile} />
                <Route path="/control-item-management" component={ControlItemManagement} />
                <Route path="/control-item" component={ControlItem} />

                <Route path="/service-batch-management" component={ServiceBatchManagement} />
                <Route path="/service-batch" component={ServiceBatch} />

                <Route path="/multilanguage-management" component={MultilanguageManagement} />
                <Route path="/multilanguage-management-preview" component={HTMLPreview} />
                <Route path="/multilanguage" component={Multilanguage} />

                <Route path="/help-management" component={HelpManagement} />
                <Route path="/help" component={Help} />

                <Route path="/boleto-management" component={BoletoManagement} />
                <Route path="/boleto" component={Boleto} />
                <Route path="/card-management" component={CardManagement} />
                <Route path="/card" component={Card} />
                <Route path="/card-movement-management" component={PaymentsManagement} />

                <Route path="/cache-management" component={CacheManagement} />
                <Route path="/cache" component={CacheEdit} />

                <Route path="/staging-consignee-management" component={StagingConsigneeManagement} />
                <Route path="/staging-consignee" component={StagingConsignee} />

                <Route path="/voucher-management" component={VoucherManagement} />
                <Route path="/voucher" component={Voucher} />

                <Route path="/assets-management" render={ActivesManagement} />
                <Route path="/asset" component={Asset} />

                <Route path="/sale" component={Sale} />

                {/*CMS*/}

                <Route path="/searcharea-active" component={SearchAreaActive} />
                <Route path="/searcharea-past" component={SearchAreaPast} />
                <Route path="/searcharea" component={SearchArea} />
                <Route path="/searcharea-services" render={() => SearchAreaServiceTypes} />
                <Route path="/searcharea-service" component={SearchService} />
                <Route path="/searcharea-categories" render={() => SearchAreaServiceCategories} />
                <Route path="/searchAreaCategory" component={SearchCategory} />

                <Route path="/promotions-active" component={PromotionsActive} />
                <Route path="/promotions-past" component={PromotionsPast} />
                <Route path="/promotion" component={Promotion} />

                <Route path="/segments" render={e => segment.render(new URLSearchParams(e.location.search))} />
                <Route path="/segment" component={News} />
                <Route path="/link-image" render={() => <ImageLink />} />

                <Route path="/groups" render={e => groups.render(new URLSearchParams(e.location.search).get('t'))} />
                <Route path="/group" component={Group} />

                <Route path="/map-page" render={e => mapPage(e)} />

                {/*Protetor*/}

                <Route path="/life-button-past" component={LifeButtonPast} />
                <Route path="/life-button-active" component={LifeButtonActive} />

                <Route path="/ambulance-past" component={AmbulancePast} />
                <Route path="/ambulance-active" component={AmbulanceActive} />

                <Route path="/calls-past" component={CallsPast} />
                <Route path="/calls-active" component={CallsActive} />

                <Route path="/oldcall" component={OldCall} />

                <Route path="/vanlist" component={CallDocumentList} />
                <Route path="/vandocs" render={e => <VanDocs idUUID={new URLSearchParams(e.location.search).get('id')} />} />
                <Route path="/full-history-page" render={() => <FullHistoryList />}/>

                <Route path="/calls" render={_ => <Calls setBtts={this.setBttShow} menuOpen={this.isMenuOpen} onToggleMenu={this.onToggleMenu} />} />
                <Route path="/callinfo" component={CallInfo} />
                <Route path="/callinfo-management" component={CallInfoManagement} />

                <Route path="/contractP" component={ContractP} />

                <Route path="/medical-certificate-template-list" component={MedicalCertificateTemplateList} />
                <Route path="/medical-certificate-template" render={e => <MedicalCertificateTemplate idUUID={new URLSearchParams(e.location.search).get('id')} />} />
            </>
        );
    }

    render() {
        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive,
        });

        const sidebarClassName = classNames('layout-sidebar', {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light',
        });
 
        const messageDialog = <Dialog onHide={() => {}} contentStyle={{padding: '50px'}} visible={this.state.messages.length > 0}>
            {this.state.messages[0]}
            <div style={{placeItems: "center", display: "grid"}}>
                <span className='pi pi-spin pi-spinner' style={{fontSize: "5em"}}></span>
            </div>
        </Dialog>;

        if (!this.state.userLogged)
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                    {messageDialog}
                    {this.getRoutes()}
                </div>
            );
        else if (this.state.noMenu)
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                    <div className={wrapperClass} onClick={this.onWrapperClick}>
                        {messageDialog}
                        {this.getRoutes()}
                    </div>
                </div>
            );
        else {
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                    <AppTopbar showBtts={this.state.showBtt} handlerLogin={this.handlerLogin} onToggleMenu={this.onToggleMenu} />
                    <div ref={el => (this.sidebar = el)} className={sidebarClassName} onClick={this.onSidebarClick}>
                        <div className="layout-logo">{/* <img alt="Logo" src={logo} /> */}</div>
                        <AppProfile handlerLogin={this.handlerLogin} />
                        <Route component={SidebarMenu} />
                    </div>
                    <div className="layout-main"> {messageDialog} {this.getRoutes()} </div>
                </div>
            );
        }
    }
}

export default App;
