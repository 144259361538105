import type { GroupType, Group, GroupStatus } from '../..';
import type { GenericResponse, GenericResponseG, UUID } from '../../Generic';
import type { RequestsClass } from '../../Requests';

export interface GroupCreate {
	name: string;
	parentGroupIdUUID: UUID;
	referenceOwnerIdUUID?: UUID;
	description: string;
	groupType: GroupType;
}

export interface GroupUpdate extends Partial<GroupCreate> {
	idUUID: UUID;
}

export const add =
	(req: RequestsClass, baseUrl: string) =>
	(data: { group: GroupCreate }): Promise<GenericResponseG<'group', Group>> =>
		req.axios.post(`${baseUrl}/add`, data);

export const update =
	(req: RequestsClass, baseUrl: string) =>
	(data: { group: GroupUpdate }): Promise<GenericResponseG<'group', Group>> =>
		req.axios.put(`${baseUrl}/update`, data);

export const status =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		idUUID: UUID;
		groupStatus: GroupStatus;
	}): Promise<GenericResponse> =>
		req.axios.put(`${baseUrl}/update`, data);
