import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import React, { Component } from 'react';
import config from '../../config/config';
import BatchService from '../../service/BatchService';
import ConsignatorService from '../../service/ConsignatorService';
import ProductService from '../../service/ProductService';
import {
    LS,
    StateIcon,
    ListPage,
    g_getHeaderTemplatecF,
    g_getData,
    g_checkLoginACL,
    g_displayMessageError,
    g_entityFilter,
    g_getDateTemplate,
    g_nullarrayresponse,
    g_tabViewRenderOptions,
    g_template_button,
    g_template_dropdown,
    g_template_input,
    g_wraper,
} from '../GenericFunctions';
import Log from '../logs/Log';

export const states = {
    0: { class: 'pi-spinner pi-spin', color: 'grey', tooltip: 'Em Fila' },
    1: { class: 'pi-check', color: 'green', tooltip: 'Sucesso' },
    2: { class: 'pi-spinner pi-spin', color: 'green', tooltip: 'A Processar' },
    3: { class: 'pi-times', color: 'red', tooltip: 'Erro' },
    4: { class: 'pi-clock', color: 'red', tooltip: 'Timeout' },
    5: { class: 'pi-ban', color: 'red', tooltip: 'Apagado' },
    6: { class: 'pi-check', color: 'orange', tooltip: 'A Preparar' },
    7: { class: 'pi-exclamation-circle', color: 'orange', tooltip: 'Sucesso com alertas' },
};

export default class BatchEdit extends Component {
    constructor() {
        super();
        this.state = {
            mode: false,
            consignators: [],
            documentTypeId: '',
            consignatorIdUUID: '',
            executeFiller: '',
            idUUID: '',
            data: {},
            items: [{ label: 'Carga' }],
            steps: [{ label: 'Em Fila' }, { label: 'A preparar' }, { label: 'A Processar' }, { label: 'Processado' }],
            executeFillerAOptions: [
                { label: LS('0', 'marginTypes'), value: '0' },
                { label: LS('1', 'marginTypes'), value: '1' },
            ],
            executeFillerBOptions: [{ label: 'Default NUMO', value: '0' }],
            executeFillerAVisible: true,
            executeFillerBVisible: false,
            executeFillerALabel: LS('type', 'generic'),
            executeFillerBLabel: LS('type', 'generic'),
            executeFillerA: '',
            executeFillerB: '',
        };
    }

    async componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let bid = query.get('batchid');
        let mode = query.get('type') === '1' ? false : true;
        let toSet = {};
        if (bid === '2b81a56a-780b-bf8f-b00d-038f01b88c1a')
            toSet = {
                executeFillerBOptions: [...this.state.executeFillerBOptions, ...(await this.getProducts())],
                executeFillerBLabel: 'Produto',
                executeFillerBVisible: true,
                executeFillerAField: this.get_movimentBatch_filterA,
                executeFillerA: { mm: '', yy: '' },
            };
        this.getConsignators();
        this.setState({ idUUID: id, mode: mode, batch_idUUID: bid, ...toSet }, this.getData);
    }

    get_movimentBatch_filterA = () => {
        return (
            <div className="p-col-12 p-grid p-fluid">
                {g_wraper(
                    'mm',
                    LS('month'),
                    g_template_input(this, 'mm', this.state.executeFillerA.mm, null, false, false, true, 'p-col-1', '', '', { data: 'executeFillerA', regex: new RegExp('^\\d{1,2}$', 'g') }),
                    false,
                    'p-col-1',
                    true
                )}
                {g_wraper(
                    'yy',
                    LS('year'),
                    g_template_input(this, 'yy', this.state.executeFillerA.yy, null, false, false, true, 'p-col-1', '', '', { data: 'executeFillerA', regex: new RegExp('\\d{1,4}$', 'g') }),
                    false,
                    'p-col-1',
                    true
                )}
            </div>
        );
    };

    getData = async () => {
        if (!this.state.idUUID) return;
        let data = {
            filters: [{ key: 'idUUID', value: this.state.idUUID }],
            pageNumber: 1,
            pageSize: 1,
        };
        let response = await BatchService.batchList(data);
        if (g_displayMessageError(this.messages, response)) return;
        if (!response.batchs[0]) return g_nullarrayresponse(this);
        this.setState({
            data: response.batchs[0],
            items: [{ label: LS('carga', 'tab') }, { label: LS('processing', 'tab'), icon: 'pi pi-list' }, { label: LS('log', 'tab'), icon: 'pi pi-list' }],
        });
    };

    getConsignators = async () => {
        let f = g_entityFilter([], 'idUUID', 'idUUIDs', 6, 'notification#accessall');
        if (!f) return;
        let data = { filters: f[0] };
        let response = await ConsignatorService.listConsignator(data);
        if (g_displayMessageError(this.messages, response)) return;
        this.setState({
            consignators: response.consignators.map(consignators => ({
                label: consignators.name,
                value: consignators.idUUID,
            })),
        });
    };

    getProducts = async () => {
        let response = await ProductService.getProducts({});
        if (g_displayMessageError(this.messages, response)) return;
        return response.products.map(consignators => ({
            label: consignators.name,
            value: consignators.idUUID,
        }));
    };

    onCreate = e => {
        e.preventDefault();
        document.getElementById('file').click();
    };

    uploadDocument = e => {
        e.preventDefault();
        document.body.classList.add('loading-indicator');
        let reader = new FileReader();
        let readerArray = new FileReader();
        let file = e.target.files[0];
        reader.readAsDataURL(file);
        readerArray.onloadend = () => {
            file.arrayByte = readerArray.result;
            this.uploadHandler(file);
        };
        readerArray.readAsArrayBuffer(file);
    };

    uploadHandler = async file => {
        if (!this.state.batch_idUUID) return;
        const formData = new FormData();
        formData.append('file', file);
        let fillerA = this.state.executeFillerA;
        let fillerB = this.state.executeFillerB;
        let layout = 0;
        if (this.state.batch_idUUID === '2b81a56a-780b-bf8f-b00d-038f01b88c1a') fillerA = `${fillerA.mm}-${fillerA.yy}`;
        if (this.state.batch_idUUID === '4f491300-f859-f49e-c8a0-db59764ea41c') layout = fillerA;

        if (!fillerA) fillerA = '-';
        if (!fillerB) fillerB = '-';
        var response = await BatchService.documentUpload(formData, this.state.batch_idUUID, this.state.consignatorIdUUID, fillerA, fillerB, layout);
        document.body.classList.remove('loading-indicator');
        if (g_displayMessageError(this.messages, response)) return;
        this.messages.show({ severity: 'success', summary: LS('simpleUpload', 'messages') });
        this.setState({ idUUID: response.batch.idUUID }, this.getData);
    };

    getIndex = () => ({ 6: 1, 0: 0, 2: 2, 1: 3, 7: 3 }[this.state.data.batchStatus]);

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && e.u !== 1 && this.state.idUUID !== null) this.setState({ mode: true });
            if (!this.state.mode && e.c !== 1 && this.state.idUUID === null) this.setState({ mode: true });
            return (
                <div className="card">
                    {g_tabViewRenderOptions(this, [
                        ...(!this.state.idUUID
                            ? [
                                  <div className="p-col">
                                      <Messages ref={e => (this.messages = e)} />
                                      <form onSubmit={this.onCreate}>
                                          <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                              <input id="file" type="file" accept=".csv,.dat,.xls,.xlsx,.txt" onChange={this.uploadDocument} style={{ cursor: 'pointer', display: 'none' }} />
                                              {this.state.executeFillerAField
                                                  ? this.state.executeFillerAField()
                                                  : g_template_dropdown(
                                                        this,
                                                        'executeFillerA',
                                                        this.state.executeFillerA,
                                                        this.state.executeFillerALabel,
                                                        this.state.executeFillerAOptions,
                                                        this.state.mode || e.c !== 1 || !this.state.batch_idUUID,
                                                        !this.state.executeFillerAVisible,
                                                        !this.state.executeFillerAVisible,
                                                        'p-col-5',
                                                        'p-col-1',
                                                        true,
                                                        '',
                                                        { data: 'executeFillerA' }
                                                    )}
                                              {this.state.executeFillerBField
                                                  ? this.state.executeFillerBField()
                                                  : g_template_dropdown(
                                                        this,
                                                        'executeFillerB',
                                                        this.state.executeFillerB,
                                                        this.state.executeFillerBLabel,
                                                        this.state.executeFillerBOptions,
                                                        this.state.mode || e.c !== 1 || !this.state.batch_idUUID,
                                                        !this.state.executeFillerBVisible,
                                                        !this.state.executeFillerBVisible,
                                                        'p-col-5',
                                                        'p-col-1',
                                                        true,
                                                        '',
                                                        { data: 'executeFillerB' }
                                                    )}
                                              {g_template_dropdown(
                                                  this,
                                                  'consignatorIdUUID',
                                                  this.state.consignatorIdUUID,
                                                  LS('consignatorName'),
                                                  this.state.consignators,
                                                  this.state.mode || e.c !== 1 || !this.state.batch_idUUID,
                                                  false,
                                                  true,
                                                  'p-col-5',
                                                  'p-col-1',
                                                  true,
                                                  '',
                                                  { data: 'consignatorIdUUID' }
                                              )}
                                              {g_template_button('', 'pi pi-upload', false, null, e.c !== 1 || !this.state.batch_idUUID || this.state.mode, 'p-col-2')}
                                          </div>
                                      </form>
                                  </div>,
                              ]
                            : []),
                        ...(this.state.idUUID
                            ? [
                                  <div className="card">
                                      <div className="p-grid p-fuild">
                                          <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                              <Panel>
                                                  {g_template_input(this, '', this.state.idUUID, 'ID:', false, false, false, 'p-col-7', 'p-col-2')}
                                                  <div className="p-grid p-fluid">
                                                      {g_wraper(
                                                          '',
                                                          LS('originalFilename'),
                                                          g_template_input(this, '', this.state.data.originalFilename, null, true, false, false, 'p-col-7'),
                                                          false,
                                                          'p-col-2',
                                                          true
                                                      )}
                                                      {g_template_button('', 'pi pi-download', false, () => {
                                                          window.open(config.BATCH_DOCUMENT_URL + this.state.data.processedFilename);
                                                      })}
                                                      <div className="p-col-12">
                                                          <Steps
                                                              style={{ display: [3, 4, 5].indexOf(this.state.data.batchStatus) === -1 ? '' : 'none' }}
                                                              model={this.state.steps}
                                                              activeIndex={this.getIndex()}
                                                          />
                                                      </div>
                                                  </div>
                                              </Panel>
                                          </div>
                                          <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                              <Panel style={{ borderLeft: this.state.idUUID !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                                  {g_wraper(
                                                      null,
                                                      LS('state'),
                                                      <div className="p-col">
                                                          <StateIcon state={this.state.data.batchStatus} small custom={states} />
                                                      </div>,
                                                      !this.state.idUUID,
                                                      'p-col-7'
                                                  )}
                                                  {g_wraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.idUUID == null, 'p-col')}
                                                  {g_wraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.idUUID == null, 'p-col')}
                                                  {g_wraper(null, LS('dateExecution'), g_getDateTemplate(this.state.data.dateExecution), this.state.idUUID == null, 'p-col')}
                                              </Panel>
                                          </div>
                                      </div>
                                  </div>,
                              ]
                            : []),
                        uploadProcessedInfo(() => this),
                        <Log idUUID={this.state.idUUID} logContextType={5} tid={true} />,
                    ])}
                </div>
            );
        });
    }
}

let cline = (text, style = {}) => {
    return <span style={style}>{text}</span>;
};
const uploadProcessedInfo = props => (
    <ListPage
        header={c => g_getHeaderTemplatecF(c, null, c.getData, false)}
        table={() => [
            {
                type: 'di',
                data: 'message',
                align: 'left',
                sortable: true,
                filter: true,
                di: raw => cline(raw.message, { color: raw.logTypeDescription === 'Error' ? 'red' : raw.logTypeDescription === 'Warning' ? '#ffba01' : '' }),
            },
            {
                type: 'di',
                data: 'context',
                align: 'left',
                sortable: true,
                filter: true,
                di: raw => cline(raw.context, { color: raw.logTypeDescription === 'Error' ? 'red' : raw.logTypeDescription === 'Warning' ? '#ffba01' : '' }),
            },
            {
                type: 'di',
                data: 'logTypeDescription',
                align: 'left',
                sortable: true,
                filter: true,
                di: raw => cline(raw.logTypeDescription, { color: raw.logTypeDescription === 'Error' ? 'red' : raw.logTypeDescription === 'Warning' ? '#ffba01' : '' }),
            },
            {
                type: 'date',
                data: 'dateCreation',
                sortable: true,
                filter: true,
                di: (d, raw) => cline(d, { color: raw.logTypeDescription === 'Error' ? 'red' : raw.logTypeDescription === 'Warning' ? '#ffba01' : '' }),
            },
        ]}
        getData={c => (props().state.idUUID ? g_getData(c, BatchService.getLogsProcessed, 'logs', { entityReferenceIdUUID: props().state.idUUID, logContextType: 0 }) : null)}
    />
);
