import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState, useRef } from 'react';
import { g_genericRequired } from '../generic/DataTable';
import {
    ls,
    lb,
    LabelSelector,
    ListPage,
    g_getHeaderTemplateNull,
    g_checkLoginACL,
    g_handleChangeHook,
    g_template_button,
    g_displayMessageError,
    g_template_selector_data_hook,
    g_getDataHook,
    g_pair,
} from '../GenericFunctions';
import S from '../../service/Services';
import Config from '../../config/config';
import { Messages } from 'primereact/messages';

export function Sale() {
    let vat = useState('');
    let value = useState('');
    let code = useState('');
    let [curent, setCurrent] = useState(1);
    let [providerData, setProviderData] = useState({ ...g_genericRequired(), pageSize: 6 });
    let [providerIdUUID, setProviderIdUUID] = useState(null);
    let [request, setResquest] = useState(0);
    let [consignatorData, setConsignatorData] = useState({ ...g_genericRequired(), pageSize: 6 });
    let [consignatorIdUUID, setConsignator] = useState(-1);

    let getProvider = () => setResquest(1);
    let getConsignator = () => setResquest(2);

    let list = null;
    let messages = useRef(null);

    useEffect(() => {
        S.provider.processed.listhook([
            providerData,
            d => {
                if (d.data.length === 0) {
                    window.location.href = '#/';
                } else if (d.data.length === 1) {
                    setProviderIdUUID(d.data[0].idUUID);
                } else {
                    setProviderData(d);
                }
            },
        ]);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (request === 0) return;
        else if (request === 1) S.provider.processed.listhook([providerData, setProviderData]);
        else if (request === 3)
            g_getDataHook(
                [
                    consignatorData,
                    d => {
                        console.log(d);
                        if (d.data.length === 0) {
                            if (messages) messages.current.show({ severity: 'warn', summary: LabelSelector('noVatNumber', 'messages') });
                            vat[1]('');
                            setCurrent(1);
                        } else if (d.data.length === 1) {
                            submit(null, d.data[0].idUUID);
                        } else {
                            setConsignatorData(d);
                        }
                    },
                ],
                S.consignator.list,
                'consignators',
                { filters: [g_pair('inconsigneevatnumber', vat[0].replace(/[^\d]/g, ''))] }
            );
        else if (request === 2)
            g_getDataHook([consignatorData, setConsignatorData], S.consignator.list, 'consignators', { filters: [g_pair('inconsigneevatnumber', vat[0].replace(/[^\d]/g, ''))] });
        setResquest(0);
        //TODO: Check if thats true
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    let add = (a, e, c = null) => {
        if (e?.preventDefault) e.preventDefault();
        let [v, s] = [() => null, ''];
        let extra = {};
        switch (c ?? curent) {
            case 1:
                [v, s] = vat;
                if (a === -1) {
                    if (v.length - 1 === 0) {
                        s('');
                    } else s(v.substr(0, v.length - 1));
                    return;
                }
                if (v.length === 10) {
                    setCurrent(2);
                }
                break;
            case 2:
                [v, s] = value;
                extra = { regex: new RegExp(`(\\d{1,4}(,\\d{0,2})?|\\d{0,4})`, 'g') };
                if (a === -1) {
                    if (v.length - 1 === 0) s('');
                    else if (v.length - 1 < 0) setCurrent(1);
                    else s(v.substr(0, v.length - 1));
                    return;
                }
                if (v.length === 3 && v.indexOf(',') === -1 && a !== ',') setCurrent(3);
                if (v.indexOf(',') !== -1 && v.substr(v.indexOf(',') + 1).length === 1) setCurrent(3);
                break;
            case 3:
                [v, s] = code;
                extra = { regex: new RegExp(`\\d{0,6}`, 'g') };
                if (a === -1) {
                    if (v.length - 1 === 0) s('');
                    else if (v.length - 1 < 0) setCurrent(2);
                    else s(v.substr(0, v.length - 1));
                    return;
                }
                break;
            case 4:
                break;
            default:
                setCurrent(1);
                add(a);
                return;
        }
        e.target = { value: v + a };
        g_handleChangeHook(e, extra, [v, s]);
    };

    let submit = async (e, cid) => {
        if (e?.preventDefault) e.preventDefault();
        if (!providerIdUUID) return;
        if (!vat[0]) return;
        if ((!consignatorIdUUID || consignatorIdUUID === -1) && !cid) {
            setResquest(3);
            return;
        }
        cid = consignatorIdUUID === -1 ? cid : consignatorIdUUID ?? cid;
        let [vat1, setV] = vat,
            [code1, setC] = code,
            [value1, setVa] = value;
        let response = await S.loyalty.authorize({ vatNumber: vat1.replace(/[^\d]/g, ''), pin: code1, amount: value1.replace(',', '.'), consignatorIdUUID: cid }, providerIdUUID);
        if (g_displayMessageError(messages.current, response)) {
            if (response.code === 102) {
                setC('');
                setCurrent(3);
            }
            return;
        }
        if (messages) messages.current.show({ severity: 'success', summary: LabelSelector('authOk', 'messages') });
        if (list) list.getData();
        setV('');
        setC('');
        setVa('');
        setCurrent(1);
    };

    return g_checkLoginACL(() => (
        <>
            <Messages ref={messages} />
            <Dialog
                visible={!providerIdUUID}
                onHide={() => {
                    if (!providerIdUUID) window.location.href = '#';
                }}
                style={{ width: '90vw' }}
            >
                {g_template_selector_data_hook(
                    [providerData, setProviderData],
                    data => (
                        <div
                            key={data.idUUID}
                            onClick={() => setProviderIdUUID(data.idUUID)}
                            className="p-col-3 p-grid p-fluid"
                            style={{ border: '1px solid #edf0f5', backgroundColor: '#edf0f5', borderRadius: '40px', margin: '10px', width: '20vw' }}
                        >
                            <div className="p-col-12" style={{ textAlign: 'center' }}>
                                <img
                                    alt="logo"
                                    src={data.photo ? Config.PHOTO_URL + data.photo : window.location.origin + '/assets/images/logotemplate.png'}
                                    style={{ maxHeight: '200px', maxWidth: '100%' }}
                                />
                            </div>
                            <div className="p-col-2"></div>
                            <div className="p-col-8" style={{ textAlign: 'center' }}>
                                {data.fantasyName}
                            </div>
                            <div className="p-col-2"></div>
                        </div>
                    ),
                    getProvider,
                    'p-grid p-justify-between'
                )}
            </Dialog>

            <Dialog
                visible={!consignatorIdUUID}
                onHide={() => {
                    setConsignator(-1);
                }}
                style={{ width: '90vw' }}
            >
                {g_template_selector_data_hook(
                    [consignatorData, setConsignatorData],
                    data => (
                        <div
                            key={data.idUUID}
                            onClick={() => {
                                submit(null, data.idUUID);
                            }}
                            className="p-col-3 p-grid p-fluid"
                            style={{ border: '1px solid #edf0f5', backgroundColor: '#edf0f5', borderRadius: '40px', margin: '10px', width: '20vw' }}
                        >
                            <div className="p-col-12" style={{ textAlign: 'center' }}>
                                <img
                                    alt="logo"
                                    src={data.photo ? Config.PHOTO_URL + data.photo : window.location.origin + '/assets/images/logotemplate.png'}
                                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                                />
                            </div>
                            <div className="p-col-2"></div>
                            <div className="p-col-8" style={{ textAlign: 'center' }}>
                                {data.fantasyName}
                            </div>
                            <div className="p-col-2"></div>
                        </div>
                    ),
                    getConsignator,
                    'p-grid p-justify-between'
                )}
            </Dialog>

            <form onSubmit={submit}>
                <div className="p-grid p-fluid">
                    <div className="p-col-2"></div>
                    <div className="p-col-4" onClick={() => setCurrent(1)}>
                        <InputMask
                            ref={console.log}
                            autoClear={false}
                            onFocus={() => setCurrent(1)}
                            title={ls('titleField', 'tooltip')}
                            value={vat[0]}
                            onChange={e => g_handleChangeHook(e, {}, vat)}
                            required
                            mask={LabelSelector('vatNumber', 'mask')}
                            placeholder="CPF"
                            style={{ border: 'none', borderBottom: '1px solid black', fontSize: '4em', textAlign: 'center', backgroundColor: '#edf0f5' }}
                        />
                    </div>
                    <div className="p-col-1"></div>
                    {g_template_button('1', '', false, e => add(1, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('2', '', false, e => add(2, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('3', '', false, e => add(3, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    <div className="p-col-1"></div>
                    <div className="p-col-2"></div>
                    <div className="p-col-4">
                        <InputText
                            onSelect={() => setCurrent(2)}
                            placeholder={LabelSelector('priceValue')}
                            value={value[0]}
                            onChange={e => g_handleChangeHook(e, { regex: new RegExp(`(\\d{1,4}(,\\d{0,2})?|\\d{0,4})`, 'g') }, value)}
                            title={ls('titleField', 'tooltip')}
                            required
                            style={{ border: 'none', borderBottom: '1px solid black', fontSize: '4em', textAlign: 'center', backgroundColor: '#edf0f5' }}
                        />
                    </div>
                    <div className="p-col-1"></div>
                    {g_template_button('4', '', false, e => add(4, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('5', '', false, e => add(5, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('6', '', false, e => add(6, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    <div className="p-col-1"></div>
                    <div className="p-col-2"></div>
                    <div className="p-col-4">
                        <InputText
                            onSelect={() => setCurrent(3)}
                            placeholder={LabelSelector('code')}
                            value={code[0]}
                            onChange={e => g_handleChangeHook(e, { regex: new RegExp(`\\d{0,6}`, 'g') }, code)}
                            title={ls('titleField', 'tooltip')}
                            required
                            style={{ border: 'none', borderBottom: '1px solid black', fontSize: '4em', textAlign: 'center', backgroundColor: '#edf0f5' }}
                        />
                    </div>
                    <div className="p-col-1"></div>
                    {g_template_button('7', '', false, e => add(7, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('8', '', false, e => add(8, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('9', '', false, e => add(9, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    <div className="p-col-1"></div>
                    <div className="p-col-7"></div>
                    {g_template_button(',', '', false, e => add(',', e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('0', '', false, e => add(0, e), false, 'p-col-1', '', '', { fontSize: '3em' })}
                    {g_template_button('\u232B', '', false, e => add(-1, e), false, 'p-col-1', 'p-button-danger', '', { fontSize: '3em' })}
                    <div className="p-col-7"></div>
                    {g_template_button(lb('sale'), '', false, null, false, 'p-col-3', 'p-button-success', '', { fontSize: '3em' })}
                </div>
            </form>
            <ListPage
                ref={e => (list = e)}
                title=""
                header={c => g_getHeaderTemplateNull(c, c.getData)}
                table={_ => [
                    { type: 'date', data: 'dateCreation', filter: true, sortable: true },
                    { type: 'd', data: 'saleTypeDescription', filter: true, sortable: true },
                    { type: 'd', data: 'saleValue', filter: true, sortable: true },
                    { type: 'state', states: () => {}, data: 'saleStatus' },
                ]}
                getData={_ => null}
            />
        </>
    ));
}
