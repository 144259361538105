import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import {
    S,
    LS,
    lb,
    g_getDataFields,
    g_checkLoginACL,
    g_displayMessageError,
    g_getDateTemplate,
    g_handleChange,
    g_tabViewRenderOptions,
    g_inputMaskTemplate,
    g_inputTemplate,
    g_getWraper,
    g_buttonTemplate,
    g_dropdownTemplate,
} from '../GenericFunctions';
import UtilsService from '../../service/UtilsService';

export default class ZipCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                city: '',
                country: '',
                countryName: '',
                dateCreation: '',
                dateUpdate: '',
                state: '',
                street: '',
                streetZone: '',
                zipCode: '',
            },
            zipCode: null,
            mode: false,
            items: [{ label: LS('zipCode', 'tab') }],
            countrys: [],
        };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id');
        let mode = query.get('type') === '1' ? false : true;
        this.setState({ zipCode: id, mode: mode }, this.getData);
        g_getDataFields(this, S.utils.country, 'countries', 'name', 'code', 'countrys');
    }

    getData = async () => {
        if (this.state.zipCode) {
            let data = {
                filters: [{ key: 'zipCode', value: this.state.zipCode }],
                pageSize: 1,
                pageNumber: 1,
            };
            let response = await UtilsService.zipCodeList(data);
            if (g_displayMessageError(this.messages, response)) return;
            let rdata = response.zipcodes[0];
            rdata.zipCode = [...''.padStart(8 - String(rdata.zipCode).length, '0'), ...String(rdata.zipCode).split('').slice(0, 5), '-', ...String(rdata.zipCode).split('').slice(5, 8)].join('');
            rdata.country = String(rdata.country);
            this.setState({
                data: rdata,
                activeItem: null,
            });
        }
    };

    onSubmit = async e => {
        e.preventDefault();
        if (this.state.zipCode !== null && this.state.zipCode !== '') {
            let data = { zipcode: this.state.data };
            data.zipcode.zipCode = data.zipcode.zipCode.replace(/^0+-0+|^0+|-/g, '');
            let response = await UtilsService.zipCodeUpdate(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.messages.show({ severity: 'success', summary: LS('simpleSave', 'messages') });
            this.setState({ activeItem: null }, this.getData);
        } else {
            let data = { zipcode: this.state.data };
            data.zipcode.zipCode = data.zipcode.zipCode.replace(/^0+-0+|^0+|-/g, '');
            let response = await UtilsService.zipCodeAdd(data);
            if (g_displayMessageError(this.messages, response)) return;
            this.setState({ zipCode: response.zipcode.zipCode }, this.getData);
            this.messages.show({ severity: 'success', summary: LS('simpleCreate', 'messages') });
        }
    };

    render() {
        return g_checkLoginACL(e => {
            if (!this.state.mode && ((e.u !== 1 && this.state.zipCode !== null) || (e.c !== 1 && this.state.zipCode === null))) this.setState({ mode: true });
            return (
                <div className="card card-w-title">
                    {g_tabViewRenderOptions(this, [
                        <form className="p-col" onSubmit={this.onSubmit}>
                            <div className="p-grid p-fuild">
                                <div className="p-col-12 p-sm-9 p-lg-9 p-md-9 p-fluid contact-form">
                                    <Panel>
                                        {g_inputMaskTemplate(
                                            'zipCode',
                                            this.state.data.zipCode,
                                            LS('zipCode'),
                                            this,
                                            LS('zipCode', 'mask'),
                                            LS('zipCode', 'placeHolder'),
                                            true,
                                            this.state.mode || (this.state.zipCode !== null && this.state.zipCode !== ''),
                                            false,
                                            g_handleChange,
                                            {},
                                            '',
                                            'p-col-3',
                                            'p-col-3'
                                        )}
                                        {g_inputTemplate('street', this.state.data.street, LS('street'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col-8')}
                                        {g_inputTemplate('streetZone', this.state.data.streetZone, LS('streetZone'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col-5')}
                                        {g_inputTemplate('city', this.state.data.city, LS('city'), this, true, this.state.mode, false, g_handleChange, {}, '', 'p-col-5')}
                                        {g_inputTemplate(
                                            'state',
                                            this.state.data.state,
                                            LS('state'),
                                            this,
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            { regex: RegExp('.{0,3}', 'g') },
                                            '',
                                            'p-col-1'
                                        )}
                                        {g_dropdownTemplate(
                                            'country',
                                            this.state.data.country,
                                            LS('country'),
                                            this,
                                            this.state.countrys,
                                            '',
                                            true,
                                            this.state.mode,
                                            false,
                                            g_handleChange,
                                            {},
                                            'p-col-3',
                                            'p-col-3',
                                            true
                                        )}
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3 p-lg-3">
                                    <Panel style={{ borderLeft: this.state.zipCode !== null ? '1px solid lightGrey' : '', height: '100%' }}>
                                        {g_getWraper(null, LS('dateCreation'), g_getDateTemplate(this.state.data.dateCreation), this.state.zipCode == null, 'p-col')}
                                        {g_getWraper(null, LS('dateUpdate'), g_getDateTemplate(this.state.data.dateUpdate), this.state.zipCode == null, 'p-col')}
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-grid p-fluid">{g_buttonTemplate(lb(this.state.zipCode ? 'save' : 'create'), '', '', false, null, this.state.mode, 'p-col-2')}</div>
                        </form>,
                    ])}
                </div>
            );
        });
    }
}
