import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import React from 'react';
import Config from '../../../config/config';
import ContractService from '../../../service/ContractService';
import Attachment from '../../attachment/Attachment';
import { Button, checkLoginACL } from '../../Generic';
import { TemplateFromGenerator, TemplateFromGeneratorPage } from '../../generic/templateFormGenerator';
import { g_displayMessageError, g_getData, g_getDataFields, g_tabViewRenderOptions, ListPage, ls, pair, S } from '../../GenericFunctions';
import { MedicalCertificate, MedicalCertificateList } from './MedicalCertificate';
import { Prescription, PrescriptionList } from './Prescription';
import { UTI, videoCallgetData } from './videocallnew';
export class OldCall extends React.Component {
    constructor() {
        super();
        this.state = { tabI2: null, zoom: false, mode: true, idUUID: null, data: {}, consignee: {}, anamnesis: '', conclusion: '', problems: '', tab2Data: [], genders: [] };
    }

    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        let items = [
            { label: ls('anamnesis', 'call') },
            { label: ls('cid', 'call') },
            { label: ls('prescription', 'call') },
            { label: ls('exams', 'call') },
            { label: ls('medicalCertificate', 'call') },
            { label: ls('listProblems', 'call') },
        ];
        this.setState({ items: items, mode: query.get('type') !== '1', idUUID: query.get('id') }, () => this.getData());
        g_getDataFields(this, S.utils.gender, 'genders', 'description', 'id', 'genders');
    }

    getData = () => videoCallgetData(this);

    saveNote = async (callback = () => {}) => {
        (async () => {
            if (!this.state.idUUID) return;
            let td = this.templateGeneratorsData;
            let ts = this.templateGeneratorsSaved;
            let c = Object.keys(td).filter(a => td[a]);
            let data = [
                ...Object.keys(ts)
                    .filter(a => ts[a] && c.indexOf(a) === -1)
                    .map(a => ts[a]),
                ...c.map(a => {
                    let c = this.state.tab2Data.filter(b => String(b.page) === a);
                    if (c[0]?.uti) return { uti: JSON.stringify(td[a].obj) };
                    return td[a].state.data;
                }),
            ].reduce((a, e) => ({ ...a, ...e }));
            data = Object.keys(data)
                .filter(a => a !== 'anamnese' && a !== 'diagnostic' && a !== 'problem_list')
                .map(e => ({ fieldName: e, fieldValue: data[e] }));
            //let data = Object.keys(this.templateGeneratorsData).map(a => this.templateGeneratorsData[a].state.data)
            //    .reduce((a, e) => [...a, ...e]);
            let r = await ContractService.fieldPut(
                {
                    contractComplementaryFields: [...data],
                    contractIdUUID: this.state.idUUID,
                },
                true
            );
            g_displayMessageError(this.messages, r);
        })();
        (async () => {
            let data = this.templateGenerator.state.data;
            let objs = Object.keys(data);
            if (objs.length === 0) return;
            let r = await ContractService.fieldPut(
                {
                    contractComplementaryFields: objs.filter(e => data[e]).map(e => ({ fieldName: e, fieldValue: data[e] })),
                    contractIdUUID: this.templateGenerator.state.contractIdUUID,
                },
                true
            );
            g_displayMessageError(this.messages, r);
        })();
        (async () => {
            let consignee = { ...this.state.consignee.consigneeObj, gender: this.state.consignee.gender };
            await S.consignee.update({
                consignee,
            });
        })();
        if (typeof callback === 'function') callback();
    };

    templateGeneratorsData = {};
    templateGeneratorsSaved = {};
    render() {
        let { error, profile: e, loggeduser } = checkLoginACL({ fakename: 'call' });

        let mode = true;

        if (this.state.contractOBJ?.workingEntityIdUUID === loggeduser.uuid) {
            mode = false;
        }

        if (error) return error;
        if (this.state.clear) {
            this.setState({ clear: false });
            return null;
        }
        if (this.state.timeoutcount && this.state.timeoutcount >= 0) {
            this.setState({ timeoutcount: -1 });
            return null;
        }
        if (!this.state.mode && e.u !== 1) this.setState({ mode: true });
        let generateTab2 = () =>
            this.state.tab2Data.length === 0 ? null : (
                <TabView activeIndex={this.state.tab2} onTabChange={e => this.setState({ tab2: e.index })}>
                    {this.state.tab2Data.map(a => (
                        <TabPanel contentStyle={{ height: '50vh' }} header={a.title}>
                            {(() => {
                                if (a.uti)
                                    return (
                                        <div style={{ overflow: 'scroll', height: '50vh' }}>
                                            <UTI
                                                ref={e => (this.templateGeneratorsData[a.page] = e)}
                                                value={this.templateGeneratorsSaved[a.page].uti ?? ''}
                                                changeValue={() => (this.templateGeneratorsSaved[a.page] = { uti: JSON.stringify(this.templateGeneratorsData[a.page].obj) })}
                                                save={this.saveNote}
                                                contractIdUUID={this.state.idUUID}
                                            />
                                        </div>
                                    );
                                return (
                                    <TemplateFromGeneratorPage
                                        vValue={this.templateGeneratorsSaved[a.page]}
                                        heigth="55vh"
                                        ref={e => (this.templateGeneratorsData[a.page] = e)}
                                        template={() => a.contractFields}
                                        save={b => (this.templateGeneratorsSaved[a.page] = b.state.data)}
                                    />
                                );
                            })()}
                        </TabPanel>
                    ))}
                </TabView>
            );
        return (
            <div>
                {/* OTHER */}
                <div style={{ width: '25vw', height: '4vh', zIndex: '100', position: 'absolute', top: '90vh', left: '75vw' }}>
                    <div className="p-grid p-fluid">
                        <Button label="save" icon="pi pi-save" onClick={this.saveNote} style={{ fontSize: '1.5em' }} wraperClass="p-col-6" />
                    </div>
                </div>
                <div className="p-grid p-fluid" style={{ left: '1vw', top: '1vh', width: '75vw', position: 'absolute' }}>
                    {g_tabViewRenderOptions(this, [
                        //Tab amesies
                        <div className="p-grid p-fluid" style={{ width: '75vw' }}>
                            {!this.state.idUUID ? null : (
                                <div className="p-col-12">
                                    <h1>{ls('problemsTitle', 'call')}</h1>
                                    {!mode ? (
                                        <form
                                            onSubmit={async e => {
                                                e.preventDefault();
                                                let data = {};
                                                if (typeof this.state.symptomToAdd === 'string') {
                                                    data = {
                                                        symptomContract: {
                                                            contractIdUUID: this.state.idUUID ?? '',
                                                            description: this.state.symptomToAdd ?? '',
                                                        },
                                                    };
                                                } else {
                                                    data = {
                                                        symptomContract: {
                                                            contractIdUUID: this.state.idUUID ?? '',
                                                            symptomIdUUID: this.state.symptomToAdd?.idUUID ?? '',
                                                        },
                                                    };
                                                }
                                                let r = await S.health.symptom.contract.add(data);
                                                if (g_displayMessageError(null, r)) return;
                                                this.setState({ symptomToAdd: null });
                                                this.symptomList.getData();
                                            }}
                                        >
                                            <div className="p-col p-grid">
                                                <div className="p-col-11">
                                                    <AutoComplete
                                                        value={this.state.symptomToAdd}
                                                        onChange={e => this.setState({ symptomToAdd: e.value })}
                                                        suggestions={this.state.symptomSugestions}
                                                        field="description"
                                                        completeMethod={async e => {
                                                            let q = e.query;
                                                            this.querySymptomCompleting = q;
                                                            let r = await S.health.symptom.list({ filters: [pair('fulltextlike', q.replace(/ /g, '%'))] }, true);
                                                            if (g_displayMessageError(null, r) || q !== this.querySymptomCompleting) return;
                                                            console.log(r.symptoms);
                                                            this.setState({ symptomSugestions: r.symptoms });
                                                        }}
                                                    />
                                                </div>
                                                <Button label="" icon="pi pi-plus" type="submit" bttClass="p-button-success" style={{ fontSize: '1.2em', height: '80%' }} />
                                            </div>
                                        </form>
                                    ) : null}
                                    <ListPage
                                        checkAcl={false}
                                        header={() => undefined}
                                        ref={e => (this.symptomList = e)}
                                        table={() => [
                                            { type: 'd', data: 'id', title: ls('icdCode') },
                                            { type: 'd', data: 'description' },
                                        ]}
                                        getData={c =>
                                            g_getData(c, S.health.symptom.contract.list, 'symptomContracts', {
                                                filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID ?? ''), pair('status', 1)],
                                                pageSize: 20,
                                            })
                                        }
                                    />
                                </div>
                            )}
                            <div className="p-col-12"> {generateTab2()} </div>
                        </div>,

                        //CID
                        <div className="p-col-12" style={{ width: '74vw' }}>
                            {!mode ? (
                                <form
                                    onSubmit={async e => {
                                        e.preventDefault();
                                        console.log('teste', this.state.cidToAdd);
                                        if (typeof this.state.cidToAdd === 'string') return;
                                        let data = {
                                            cidContract: {
                                                cidIdUUID: this.state.cidToAdd.idUUID,
                                                contractIdUUID: this.state.idUUID,
                                                type: this.state.cidToAdd.type,
                                            },
                                        };
                                        let r = await S.health.cid.contract.add(data);
                                        if (g_displayMessageError(null, r)) return;
                                        this.setState({ cidToAdd: null });
                                        this.cidList.getData();
                                    }}
                                >
                                    <div className="p-col p-grid">
                                        <div className="p-col-11">
                                            <AutoComplete
                                                value={this.state.cidToAdd}
                                                onChange={e => this.setState({ cidToAdd: e.value })}
                                                suggestions={this.state.cidSugestions}
                                                field="description"
                                                completeMethod={async e => {
                                                    let q = e.query;
                                                    this.querySidCompleting = q;
                                                    let r = await S.health.cid.list({ filters: [pair('fulltextlike', q.replace(/ /g, '%'))] }, true);
                                                    if (g_displayMessageError(null, r) || q !== this.querySidCompleting) return;
                                                    this.setState({ cidSugestions: r.cids });
                                                }}
                                            />
                                        </div>
                                        <Button label="" bttClass="p-button-success" icon="pi pi-plus" type="submit" style={{ fontSize: '1.2em', height: '80%' }} />
                                    </div>
                                </form>
                            ) : null}
                            <ListPage
                                checkAcl={false}
                                ref={e => (this.cidList = e)}
                                table={() => [
                                    { type: 'd', data: 'cidId', title: ls('icdCode') },
                                    { type: 'd', data: 'description' },
                                    { type: 'date', data: 'dateCreation' },
                                ]}
                                getData={c => g_getData(c, S.health.cid.contract.list, 'cidContracts', { filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID)] })}
                            />
                        </div>,

                        //Prescription
                        <div className="p-col-12" style={{ width: '74vw' }}>
                            <PrescriptionList
                                checkAcl={false}
                                header={() =>
                                    mode ? null : (
                                        <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                            <Button
                                                icon="pi pi-plus"
                                                bttClass="p-button-success"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ prescriptionVisible: true });
                                                }}
                                                wraperClass=""
                                                style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                            />
                                        </div>
                                    )
                                }
                                extra={[{ t: 'btt', icon: 'pi pi-pencil', click: row => this.setState({ prescriptionIdUUID: row.idUUID, prescriptionVisible: true }) }]}
                                extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID)] })}
                                refTable={e => (this.prescriptionTable = e)}
                            />
                            <Dialog
                                style={{ minWidth: '90vw', minHeight: '90vh' }}
                                visible={this.state.prescriptionVisible}
                                onHide={() => this.setState({ prescriptionVisible: false, prescriptionIdUUID: null }, this.prescriptionTable.getData)}
                                contentStyle={{ height: '90vh' }}
                            >
                                <Prescription
                                    contractIdUUID={this.state.idUUID}
                                    idUUID={this.state.prescriptionIdUUID ?? ''}
                                    saved={idUUID => {
                                        this.setState({ prescriptionIdUUID: idUUID });
                                    }}
                                    consignee={this.state.consignee.consigneeObj}
                                />
                            </Dialog>
                        </div>,

                        //Exams
                        <div className="p-col-12" style={{ width: '74vw' }}>
                            <Attachment add={!mode} idUUID={this.state.idUUID} documentContextType={4} documentTypeId={'8'} service={'contract'} update={this.state.activeItem} />
                        </div>,

                        //Atestado
                        <div className="p-col-12" style={{ width: '74vw' }}>
                            <MedicalCertificateList
                                header={() =>
                                    mode ? null : (
                                        <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
                                            <Button
                                                icon="pi pi-plus"
                                                bttClass="p-button-success"
                                                wraperClass=""
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ medicalCertificateVisible: true });
                                                }}
                                                style={{ float: 'right', marginLeft: '3px', fontSize: '1.2em' }}
                                            />
                                        </div>
                                    )
                                }
                                checkAcl={false}
                                extra={[
                                    {
                                        t: 'btt',
                                        icon: 'pi pi-pencil',
                                        click: row => this.setState({ medicalCertificateIdUUID: row.idUUID, medicalCertificateVisible: true }),
                                    },
                                ]}
                                extraRequest={c => ({ filters: [...c.state.filter, pair('contractIdUUID', this.state.idUUID)] })}
                                refTable={e => (this.prescriptionTable = e)}
                            />
                            <Dialog
                                style={{ minWidth: '90vw', minHeight: '90vh' }}
                                visible={this.state.medicalCertificateVisible}
                                onHide={() => this.setState({ medicalCertificateVisible: false, medicalCertificateIdUUID: null }, this.prescriptionTable.getData)}
                                contentStyle={{ height: '90vh' }}
                            >
                                <MedicalCertificate
                                    contractIdUUID={this.state.idUUID}
                                    idUUID={this.state.medicalCertificateIdUUID ?? ''}
                                    saved={idUUID => {
                                        this.setState({ medicalCertificateIdUUID: idUUID });
                                    }}
                                    consignee={this.state.consignee.consigneeObj}
                                />
                            </Dialog>
                        </div>,

                        //History
                        <div className="p-col-12" style={{ width: '74vw' }}>
                            <ListPage
                                checkAcl={false}
                                table={() => [
                                    { type: 'd', data: 'id', title: ls('icdCode') },
                                    { type: 'd', data: 'description' },
                                    { type: 'date', data: 'dateCreation' },
                                ]}
                                getData={c =>
                                    g_getData(c, S.health.historical.list, 'historicals', {
                                        filters: [...c.state.filter, pair('consigneeIdUUID', this.state.consignee.consigneeObj.idUUID)],
                                    })
                                }
                            />
                        </div>,
                    ])}
                </div>
                {/*Side panel*/}
                <div className="p-grid p-fluid" style={{ width: '25vw', height: '40vh', position: 'absolute', left: '75vw' }}>
                    <div className="p-col-12" style={{ height: '10px' }}></div>
                    <div className="p-col-4" style={{ textAlign: 'center' }}>
                        <img
                            style={{ width: '105px', height: '105px' }}
                            src={this.state.consignee.photo ? Config.PHOTO_URL + this.state.consignee.photo : window.location.origin + '/assets/images/person.png'}
                            alt=""
                        />
                    </div>
                    <div className="p-col-8 p-gird p-fluid">
                        <div className="p-col-12">{this.state.consignee.fullname}</div>
                        <div className="p-col-12">
                            {ls('dateBirth', 'call')}: {this.state.consignee.dateBirth} ({this.state.consignee.age})
                        </div>
                        <div className="p-col-12">
                            {ls('sex', 'call')}: {this.state.genders.filter(a => a.value === this.state.consignee.gender)[0]?.label ?? '-'}
                        </div>
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-12" style={{ height: '60vh' }}>
                        <TemplateFromGenerator
                            mode
                            ref={e => (this.templateGenerator = e)}
                            height="60vh"
                            template={() => (!localStorage.getItem('BASEMEDICALFORM') ? null : JSON.parse(localStorage.getItem('BASEMEDICALFORM')))}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
