import type { RequestsClass } from '../..';
import { list, types, categories } from './list';
import { update, create } from './update';

export const BlogsApi = (req: RequestsClass) => {
	const url = '/blog';

	return {
		list: list(req, url),
		types: types(req, url),
		categories: categories(req, url),
		update: update(req, url),
		create: create(req, url)
	};
};
