import { ContractCreatePaymentData } from '../../../Contract';
import { GenericListResponseBaseG, UUID } from '../../../Generic';
import { RequestsClass } from '../../../Requests';
import { ShoppingCart } from '../../ShoppingCart';

export type ShoppingCartPay = {
	shoppingCartItems: UUID[];
	paymentDataRequest: ContractCreatePaymentData;
	campaignIdUUID: UUID;
};

export const pay =
	(req: RequestsClass, baseUrl: string) =>
	(
		uuid: string,
		data: ShoppingCartPay
	): Promise<
		GenericListResponseBaseG<'shoppingCarts', ShoppingCart> & {
			paymentIdUUID: UUID;
		}
	> =>
		req.axios.put(`${baseUrl}/${uuid}/purchase`, data);
