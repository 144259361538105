import { LabelSelector as LS } from '../components/generic/LabelSelector';
import { ls } from '../components/GenericFunctions';
import ConfigService from '../service/ConfigService';

function createItem(icon, windowLocation = null, items = null, costumCommandfunction = null, additives = null, id = '', prefix = '', acl = null) {
    let label = id ? LS(id, (prefix ?? '') + 'menu') : LS(windowLocation, (prefix ?? '') + 'menu');
    icon = 'pi pi-fw pi-' + icon;
    if (items !== null) return { label: label, icon: icon, items: items };
    if (windowLocation !== null && costumCommandfunction !== null)
        return { label: label, icon: icon, windowLocation: windowLocation, acl: acl ?? windowLocation ?? '', command: costumCommandfunction };
    if (windowLocation !== null)
        return {
            label,
            icon,
            windowLocation,
            acl: acl ?? windowLocation ?? '',
            command: () => {
                window.location = '/#/' + windowLocation + (additives ?? '');
            },
        };
    return { label: 'erro na criação do menuItem!;' };
}

/*function createItemBi(icon, windowLocation = null, items = null, costumCommandfunction = null, additives = null, id = '', prefix = '', acl = null) {
    prefix = prefix ?? '';
    let label = id ? LS(id, prefix + 'menu') : LS(windowLocation, prefix + 'menu');
    icon = 'bi bi-' + icon;
    if (items !== null) return { label: label, icon: icon, items: items };
    if (windowLocation !== null && costumCommandfunction !== null)
        return { label: label, icon: icon, windowLocation: windowLocation, acl: acl ?? windowLocation ?? '', command: costumCommandfunction };
    if (windowLocation !== null)
        return {
            label,
            icon,
            windowLocation,
            acl: acl ?? windowLocation ?? '',
            command: () => {
                window.location = '/#/' + windowLocation + (additives ?? '');
            },
        };
    return { label: 'erro na criação do menuItem!;' };
}*/

/*function createItemsBi(icon, windowLocation = '', additives = null, id = '', prefix = '', acl = []) {
    return acl.map(a => createItemBi(icon, windowLocation, null, null, additives, id, prefix, a));
}*/

/**
 * @deprecated
 * @param {*} label
 * @param {*} icon
 * @param {*} windowLocation
 * @param {*} items
 * @param {*} costumCommandfunction
 * @param {*} additives
 * @returns
 */
function createMenuItem(label, icon, windowLocation = null, items = null, costumCommandfunction = null, additives = null) {
    if (items !== null) return { label: label, icon: icon, items: items };
    if (windowLocation !== null && costumCommandfunction !== null)
        return { label: label, icon: icon, windowLocation: windowLocation, acl: windowLocation ?? '', command: costumCommandfunction };
    if (windowLocation !== null)
        return {
            label: label,
            icon: icon,
            windowLocation,
            acl: windowLocation ?? '',
            command: () => {
                window.location = '/#/' + windowLocation + (additives ? additives : '');
            },
        };
    return { label: 'erro na criação do menuItem!;' };
}

let cms = [
    createItem(
        'minus',
        null,
        [
            createItem(
                'ticket',
                null,
                [createItem('circle-off', 'promotions-active'), createItem('clock', 'promotions-past'), createItem('dollar', 'promotions-prices')],
                null,
                null,
                'segmentPrmotions'
            ),
            createItem(
                'search',
                null,
                [
                    createItem('circle-off', 'searcharea-active'),
                    createItem('clock', 'searcharea-past'),
                    createItem('dollar', 'searcharea-prices'),
                    createItem('cog', 'searcharea-services'),
                    createItem('cog', 'searcharea-categories'),
                ],
                null,
                null,
                'segmentSearch'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=1&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=1&a=0', 'all')],
                null,
                null,
                'segmentNews'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=2&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=2&a=0', 'all')],
                null,
                null,
                'segmentInformation'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=3&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=3&a=0', 'all')],
                null,
                null,
                'segmentWitness'
            ),
            createItem(
                'file',
                null,
                [createItem('circle-off', 'segments', null, null, '?st=4&a=1', 'active'), createItem('circle-off', 'segments', null, null, '?st=4&a=0', 'all')],
                null,
                null,
                'segmentServices'
            ),
            createItem('file', 'link-image'),
        ],
        null,
        null,
        'cms'
    ),
];
/*const consignee = [
    createItem(
        'id-card',
        null,
        [createItem('mobile', 'consignee-management'), createItem('minus', 'staging-consignee-management'), createItem('mobile', 'groups')],
        null,
        null,
        'consignee'
    ),
];*/
/*let notification = [
    createItem(
        'bell',
        null,
        [
            createItem('upload', 'notification-management'),
            createItem('mobile', 'notification-management', null, null, '?app=1', 'notification-management#1'),
            createItem('bell', 'groups', null, null, '?t=2'),
        ],
        null,
        null,
        'notification'
    ),
];*/
let marketplace = [
    createItem(
        'shopping-cart',
        null,
        [
            createItem('mobile', 'campaign-management'),
            createItem('id-card', 'product-management', null, null, '?mode=1', 'productServices'),
            createItem('id-card', 'product-management', null, null, '?mode=2', 'productProduct'),
            createItem('id-card', 'campaignproduct-management'),
            createItem('folder', 'packageProduct-management'),
        ],
        null,
        null,
        'marketplace'
    ),
];
let protetorMenu = (otherDashboard, reportsMenu, configToAdd) => [
    otherDashboard,
    createItem('file', 'vanlist', null, null, null, 'vanlist', null, 'dashboard-video-protetor'),
    createItem('user', 'consignee-management', null, null, null, 'consignee-management-drmoz'),
    createItem(
        'briefcase',
        null,
        [
            createItem('file', 'contract-management', null, null, '?t=2', 'contract-management#2'),
            createItem('file', 'contract-management'),
            createItem('file-o', 'contractTemplate-management'),
        ],
        null,
        null,
        'contract'
    ),
    createItem('globe', 'consignator-management'),
    createItem('ticket', 'provider-management'),
    createItem(
        'circle-on',
        null,
        [
            createItem('circle-off', 'life-button-active', null, null, '?today=1', 'life-button-active#1'),
            createItem('circle-off', 'life-button-active'),
            createItem('clock', 'life-button-past'),
        ],
        null,
        null,
        'lifebtt'
    ),
    createItem(
        'compass',
        null,
        [
            createItem('circle-off', 'ambulance-active', null, null, '?today=1', 'ambulance-active#1'),
            createItem('circle-off', 'ambulance-active'),
            createItem('clock', 'ambulance-past'),
            createItem('calendar-plus', 'assets-management'),
        ],
        null,
        null,
        'ambulanceRequest'
    ),
    createItem(
        'file',
        null,
        [
            createItem('file-o', 'calls'),
            createItem('file-o', 'calls-active', null, null, '?today=1', 'calls-active#1'),
            createItem('file-o', 'calls-active'),
            createItem('clock', 'calls-past'),
            createItem('file-o', 'callinfo-management'),
            createItem('file-o', 'full-history-page'),
        ],
        null,
        null,
        'videoAssistence'
    ),
    createItem(
        'calendar',
        null,
        [
            createItem('calendar-plus', 'consignee-calendar-plan', null, null, null, undefined, undefined, 'calls'),
            createItem('calendar-plus', 'consignee-calendar', null, null, null, null, undefined, 'calls'),
        ],
        null,
        null,
        'calendar'
    ),

    createItem('minus', 'medical-certificate-template-list', null, null, null, undefined, undefined, 'medical-certificate-template'),

    reportsMenu,
    ...marketplace,
    ...cms,

    createItem(
        'sliders-h',
        null,
        [
            createItem(
                'unlock',
                null,
                [
                    createItem('mobile', 'admin-user-management-app'),
                    createItem('user', 'admin-user-management'),
                    createItem('globe', 'admin-user-management-serv'),
                    createItem('key', null, [createItem('th-large', 'control-item-management'), createItem('tags', 'profile-management')], null, null, 'acl'),
                ],
                null,
                null,
                'access'
            ),
            createItem(
                'cog',
                null,
                [
                    createItem('question', 'help-management'),
                    createItem('inbox', 'cache-management'),
                    createItem('home', 'zipcode-management'),
                    createItem('globe', 'multilanguage-management'),
                    createItem('th-large', 'product-type-management'),
                    createItem('key', null, [...configToAdd], null, null, 'systemVars'),
                    createMenuItem('bars', 'service-batch-management'),
                ],
                null,
                null,
                'configuration'
            ),

            createItem('bars', 'service-batch-management'),
        ],
        null,
        null,
        'administraion'
    ),
];
export async function getSideBarMenu(storage) {
    let profile = JSON.parse(storage).profileACLs;
    let aclData = profile.filter(v => v.type === 4 && v.r === 1).map(v => v.tag);
    let dashboards = aclData.filter(v => v.startsWith('dashboard-'));
    let reports = aclData.filter(v => v.startsWith('report-'));
    let configToAdd = [],
        otherDashboard = {};
    //TODO API SIDE
    let replaceD = {
        'dashboard-admin': 'Administração',
        'dashboard-financier': 'Financeiro',
        'dashboard-backoffice': 'Backoffice',
        'dashboard-consignator': 'Empresa',
        'dashboard-icargo-admin': 'ICargo Administração',
        'dashboard-icargo-operation': 'ICargo Operação',
        'dashboard-video-protetor': ls('videoCall', 'menu'),
        'dashboard-control-protetor': ls('dashboard-internal-medic', 'menu'),
    };
    if (dashboards.length > 1) {
        otherDashboard = createMenuItem('Dashboards', 'pi pi-fw pi-chart-line', null, [], null);
        otherDashboard.skipsAcl = true;
        for (let i = 0; i < dashboards.length; i++) {
            otherDashboard.items.push(createMenuItem(replaceD[dashboards[i]] ? replaceD[dashboards[i]] : dashboards[i], 'pi pi-fw pi-minus', i === 0 ? '' : dashboards[i]));
        }
    }
    if (!otherDashboard.label)
        otherDashboard = {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-chart-line',
            command: () => {
                window.location = '#';
            },
            skipsAcl: true,
        };
    let reportsMenu = {};
    if (reports.length > 0) {
        reportsMenu = createItem('clone', null, [], null, null, 'reports');
        //reportsMenu.skipsAcl = true;
        for (let i = 0; i < reports.length; i++) {
            if (LS(reports[i], 'reports#menu') !== 'LabelInfenranceFailed') reportsMenu.items.push(createItem('minus', reports[i], null, null, null, null, 'reports#'));
        }

        reportsMenu.items.push(createItem('dollar', 'report-bill'));
    }
    if (aclData.indexOf('config-contex-management') !== -1) {
        let response = await ConfigService.getConfigContextsList({ filters: [], orderBy: [{ key: 'description', value: 'asc' }] });
        if (response && response.code === 0) {
            let f = response.configContexts;
            for (let i = 0; i < f.length; i++) {
                configToAdd.push(
                    createMenuItem(f[i].id, 'pi pi-fw pi-minus', 'config-contex-management', null, e => (window.location = '#/config-contex-management?id=' + e.item.label))
                );
            }
        }
    }
    //SelectMenu
    return protetorMenu(otherDashboard, reportsMenu, configToAdd);
}
