import {
	GenericListResponseG,
	GenericResponseG,
	StringDate,
	UUID
} from '../../Generic';
import {
	FilterDateType,
	GenericListRequest,
	RequestsClass
} from '../../Requests';

export type Filters =
	| 'incidentIdUUID'
	| 'creationUserName'
	| 'description'
	| FilterDateType<'dateCreation'>
	| 'status';

export type OrderBy = 'dateCreation';

export interface IncidentNotesModel {
	idUUID: UUID;
	incidentIdUUID?: UUID;
	dateCreation: StringDate;
	dateUpdate: StringDate;
	noteType: number;
	status: number;
	description: string;
	creationUserIdUUID: UUID;
	creationUserName: string;
}

export const incidentnoteslist =
	(req: RequestsClass, baseUrl: string) =>
	(
		data: GenericListRequest<Filters, OrderBy>
	): GenericListResponseG<'incidentNotes', IncidentNotesModel> =>
		req.axios.post(`${baseUrl}/notes`, data);

export const incidentnotesadd =
	(req: RequestsClass, baseUrl: string) =>
	(data: {
		incidentNote: IncidentNotesModel;
	}): Promise<GenericResponseG<'incidentNote', IncidentNotesModel>> =>
		req.axios.post(`${baseUrl}/note`, data);
