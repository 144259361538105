import type { StringDate, UUID } from '../Generic';

export enum EntityUserType {
	company = 1,
	financier = 2,
	provider = 3
}

export enum EntityUserStatus {
	associated = 1,
	blocked = 2,
	deleted = 3
}

export type UserEntityAdd = {
	userIdUUID: UUID;
	entityIdUUID: UUID;
	entityUserType: EntityUserType;
};

export interface UserEntity extends UserEntityAdd {
	readonly idUUID: UUID;
	readonly dateCreation: StringDate;
	readonly dateUpdate: StringDate;
	readonly entityName: string;
	readonly username: string;
	readonly entityUserStatus: EntityUserStatus;

	// userIdUUID: UUID;
	// entityIdUUID: UUID;
	// entityUserType: EntityUserType;
}

export interface UserEntityAddRequest {
	userEntity: UserEntityAdd;
}
