import { Messages } from 'primereact/messages';
import React from 'react';
import ContractService from '../../../service/ContractService';
import { ls, g_checkLoginACL, g_genericRequired, g_getData, g_getHeaderTemplate, g_hideSearch, g_template_dataTable } from '../../GenericFunctions';
import { states } from '../contract-protector/ContractP';

export default class LifeButtonPast extends React.Component {
    constructor() {
        super();
        this.state = g_genericRequired();
    }
    componentDidMount() {
        g_hideSearch();
        let query = new URLSearchParams(this.props.location.search);
        this.setState({ today: query.get('today') === '1' }, this.getData);
    }
    componentDidUpdate() {
        let query = new URLSearchParams(this.props.location.search);
        if ((query.get('today') === '1') !== this.state.today) this.setState({ today: query.get('today') === '1' }, this.getData);
    }
    getData = () =>
        g_getData(this, ContractService.list, 'contracts', {
            filters: [
                ...this.state.filter,
                ...this.getFilter(),
                { key: 'interfaceName', value: 'PROTECTOR' },
                { key: 'parameterA', value: 'AMBULANCIA' },
                { key: 'contractStatus', value: '0,1,5,10,12' },
            ],
        });

    getFilter = () => {
        if (!this.state.today) return [];
        let d = new Date();
        let start = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T`;
        return [
            { key: 'dateCreationStart', value: `${start}00:00:00.000` },
            { key: 'dateContractEnd', value: `${start}23:59:59.000` },
        ];
    };

    render() {
        return g_checkLoginACL(e => (
            <div className="card">
                <h1>{ls('ambulance#Active', 'titles')}</h1>
                <Messages ref={e => (this.messages = e)} />
                {g_template_dataTable(
                    this,
                    g_getHeaderTemplate(this, '', '', this.getData, 0, 0, false),
                    [
                        { type: 'd', title: ls('consigneeFullnameP'), data: 'consigneeFullname', sortable: true, filter: true },
                        { type: 'd', data: 'consigneeVatNumber', sortable: true, filter: true },
                        { type: 'd', data: 'productName', sortable: true, filter: true },
                        { type: 'd', data: 'contractTotalValue', sortable: true, filter: true },
                        { type: 'date', data: 'dateContractStart', sortable: true, filter: true },
                        { type: 'date', data: 'dateContractEnd', sortable: true, filter: true },
                        { type: 'date', data: 'dateCreation', sortable: true, filter: true },
                        {
                            type: 'di',
                            align: 'center',
                            style: { width: '3em' },
                            data: 'fillerField1',
                            di: raw => (raw.fillerField1 ? <span className="pi pi-check" style={{ color: 'green', fontSize: '1.6em' }} /> : null),
                        },
                        { type: 'state', data: 'contractStatus', states: states },
                        { type: 'url', url: raw => `#/contractP?type=1&id=${raw.idUUID}`, width: 1400, height: 600, callback: this.getData, c: e.u === 1 || e.d === 1 },
                    ],
                    this.getData
                )}
            </div>
        ));
    }
}
